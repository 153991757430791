import HttpError from 'standard-http-error'

import { isDefined, isHttpError } from '../../helpers'
import { Format, Transform } from '../../types/core'
import { formatAuthIdentifierValue } from '../../utils'
import { AddIdentifierData } from '../login/types'
import { AuthIdentifierNotVerifiedPayload, AuthIdentifierVerificationPayload } from './types'

export const otpErrorViewData: Transform<Error, [string, string]> = payload =>
  isHttpError(payload) && payload.code === HttpError.TOO_MANY_REQUESTS
    ? ['Too many OTP tries', 'You entered invalid OTP code too many times. Please try again in 30 minutes.']
    : ['Incorrect code', 'Login failed, please try again!']

export const isAuthIdentifierNotVerified = (
  payload: AuthIdentifierVerificationPayload,
): payload is AuthIdentifierNotVerifiedPayload => isDefined((payload as AuthIdentifierNotVerifiedPayload).error)

export const otpCodeResentMessage: Format<AddIdentifierData> = ({ identifier }) =>
  `Code resent to ${formatAuthIdentifierValue(identifier)}`
