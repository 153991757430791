import { assign, defaults, isUndefined } from 'lodash/fp'

import { Identity } from '../types/core'

const buildConfig = <C extends Record<string, any>>(feature: string) => {
  let config: C

  return {
    getConfig: (): C => {
      if (isUndefined(config)) throw new Error(`${feature} config not set`)

      return config
    },
    setConfig: (c: C) => {
      config = defaults(c)(config)
    },
    setConfigKey: <T extends keyof C>(key: T, callback: Identity<C[T]>) => {
      const value = callback(config && config[key])

      config = assign(config)({ [key]: value })
    },
  }
}

export default buildConfig
