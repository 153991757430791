import { action, failureAction, payloadAction } from '../../action-factory'
import apiAction, { ActionBundle } from '../../api-action'
import { StatelessPayloadThunk } from '../../types/thunk'
import { getCollectorProfileApi, getCollectorReviewsApi } from './api'
import {
  CollectorProfile,
  GET_COLLECTOR_PROFILE_FAILURE,
  GET_COLLECTOR_PROFILE_START,
  GET_COLLECTOR_PROFILE_SUCCESS,
  GET_COLLECTOR_REVIEWS_FAILURE,
  GET_COLLECTOR_REVIEWS_START,
  GET_COLLECTOR_REVIEWS_SUCCESS,
  GetCollectorProfileFailure,
  GetCollectorProfileResult,
  GetCollectorProfileStart,
  GetCollectorProfileSuccess,
  GetCollectorReviewsData,
  GetCollectorReviewsFailure,
  GetCollectorReviewsPayload,
  GetCollectorReviewsResult,
  GetCollectorReviewsStart,
  GetCollectorReviewsSuccess,
} from './types'

export const getCollectorProfileActionBundle: ActionBundle<
  GetCollectorProfileStart,
  GetCollectorProfileSuccess,
  GetCollectorProfileFailure,
  CollectorProfile
> = [
  action(GET_COLLECTOR_PROFILE_START),
  payloadAction(GET_COLLECTOR_PROFILE_SUCCESS),
  failureAction(GET_COLLECTOR_PROFILE_FAILURE),
]

export const getCollectorProfile: StatelessPayloadThunk<string, GetCollectorProfileResult> = url => dispatch =>
  dispatch(apiAction(getCollectorProfileActionBundle)(getCollectorProfileApi(url)))

export const getCollectorReviewsActionBundle: ActionBundle<
  GetCollectorReviewsStart,
  GetCollectorReviewsSuccess,
  GetCollectorReviewsFailure,
  GetCollectorReviewsData
> = [
  action(GET_COLLECTOR_REVIEWS_START),
  payloadAction(GET_COLLECTOR_REVIEWS_SUCCESS),
  failureAction(GET_COLLECTOR_REVIEWS_FAILURE),
]

export const getCollectorReviews: StatelessPayloadThunk<GetCollectorReviewsPayload, GetCollectorReviewsResult> =
  ({ pageSize, url }) =>
  dispatch =>
    dispatch(apiAction(getCollectorReviewsActionBundle)(getCollectorReviewsApi(url, { pageSize })))
