import { flow } from 'lodash/fp'

import Reducer from '../../types/reducer'
import { ACCEPT_COUNTER_OFFER_SUCCESS, ACCEPT_JUNK_LOVER_OFFER_SUCCESS } from '../customer-advert/types/actions'
import { addCardErrorLens, configLens, inProgressLens, paymentPostSetupLens as postSetupLens } from './lens'
import State, { paymentDefaultState } from './state'
import {
  CLEAR_ADD_CARD_ERROR,
  COMPLETE_SETUP_PAYMENT_FLOW,
  CUSTOMER_CARD_SETUP_FAILURE,
  CUSTOMER_CARD_SETUP_START,
  CUSTOMER_CARD_SETUP_SUCCESS,
  INIT_SETUP_PAYMENT_FLOW,
  SET_POST_SETUP,
} from './types/actions'

const reducer: Reducer<State> = (state = paymentDefaultState(), action) => {
  switch (action.type) {
    case SET_POST_SETUP:
      return postSetupLens(state).set(action.payload)
    case ACCEPT_COUNTER_OFFER_SUCCESS:
    case ACCEPT_JUNK_LOVER_OFFER_SUCCESS:
      return postSetupLens(state).set(null)
    case INIT_SETUP_PAYMENT_FLOW:
      return flow(
        configLens,
        l => l.set(action.payload),
        inProgressLens,
        l => l.set(true),
      )(state)
    case COMPLETE_SETUP_PAYMENT_FLOW:
      return flow(
        configLens,
        l => l.set(null),
        inProgressLens,
        l => l.set(false),
      )(state)
    case CUSTOMER_CARD_SETUP_START:
      return flow(
        addCardErrorLens,
        l => l.set(null),
        inProgressLens,
        l => l.set(true),
      )(state)
    case CUSTOMER_CARD_SETUP_FAILURE:
      return flow(
        addCardErrorLens,
        l => l.set(action.payload.message),
        inProgressLens,
        l => l.set(true),
      )(state)
    case CUSTOMER_CARD_SETUP_SUCCESS:
      return inProgressLens(state).set(false)
    case CLEAR_ADD_CARD_ERROR:
      return addCardErrorLens(state).set(null)
    default:
      return state
  }
}

export default reducer
