import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

const useRecaptcha = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  return (action: string) =>
    new Promise<string>((resolve, reject) =>
      executeRecaptcha ? executeRecaptcha(action).then(resolve) : reject(new Error('`executeRecaptcha` not present')),
    ).catch(() => 'dummy-token')
}

export default useRecaptcha
