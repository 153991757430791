import { NoOp, Transform } from '@lovejunk/core'
import { noop } from 'lodash/fp'
import React, { CSSProperties, forwardRef, ForwardRefRenderFunction, ReactNode } from 'react'
import { styled } from 'styled'
import { classNames } from 'utils/css'

interface ChildProps {
  disabled?: boolean
  style?: CSSProperties
  shapeStyle?: CSSProperties
}

interface OwnProps {
  children: Transform<ChildProps, ReactNode>
}

export interface ButtonProps extends ChildProps {
  onPress: NoOp

  className?: string
  id?: string
}

type Props = ButtonProps & OwnProps

export type ExternalProps<P = {}> = ButtonProps & P
export type ShapeProps<P = {}> = ChildProps & P

const GenericButton: ForwardRefRenderFunction<HTMLButtonElement, Props> = (
  { children, className, disabled = false, id, onPress, shapeStyle, style },
  ref,
) => (
  <Root
    onClick={disabled ? noop : onPress}
    className={classNames('LoveJunk-GenericButton', className)}
    disabled={disabled}
    id={id}
    ref={ref}
    style={style}
    type="button"
  >
    {children({ disabled, style: shapeStyle })}
  </Root>
)

interface RootProps {
  disabled: boolean
}

const Root = styled.button<RootProps>`
  background: none;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  font-family: Lato-Regular;
  font-size: 1rem;
  padding: 0;
`

export default forwardRef(GenericButton)
