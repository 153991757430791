import {
  COLLECTOR_LOOKING_AT_ACTIVE_ADVERT,
  CUSTOMER_LOOKING_AT_ACTIVE_ADVERT,
  CUSTOMER_LOOKING_AT_ACTIVE_REUSER_ADVERT,
  CUSTOMER_LOOKING_AT_UNDERWAY_REUSER_ADVERT,
  REUSER_LOOKING_AT_ACTIVE_ADVERT,
  REUSER_LOOKING_AT_UNDERWAY_ADVERT,
} from '../constants'
import { Status, SupplierType } from '../entities/advert'
import { SessionRelation } from '../entities/meta/types'
import { isDefined } from '../helpers'

export interface AdvertMatchOwnershipParams {
  advertStatus?: Status
  relation?: SessionRelation
  supplierType?: SupplierType
  jobId?: number
  counterOfferId?: number
}

interface AdvertMatchOwnershipResult {
  isCollectorLookingAtActiveAdvert: boolean
  isCustomerLookingAtActiveCollectorAdvert: boolean
  isReuserLookingAtActiveAdvert: boolean
  isCustomerLookingAtActiveReuserAdvert: boolean
  isCollectorLookingAtUnderwayAdvert: boolean
  isCustomerLookingAtUnderwayCollectorAdvert: boolean
  isReuserLookingAtUnderwayAdvert: boolean
  isCustomerLookingAtUnderwayReuserAdvert: boolean
}

export interface BannerTextParams extends AdvertMatchOwnershipResult {
  junkLoverName: string
}

export const calculateAdvertViewingOwnership = ({
  advertStatus,
  relation,
  supplierType,
  jobId,
  counterOfferId,
}: AdvertMatchOwnershipParams): AdvertMatchOwnershipResult => {
  // Active
  const isCollectorLookingAtActiveAdvert =
    relation === 'public' && !isDefined(jobId) && isDefined(counterOfferId) && advertStatus === 'active'
  const isCustomerLookingAtActiveCollectorAdvert =
    relation === 'owner' && !isDefined(jobId) && isDefined(counterOfferId) && advertStatus === 'active'
  const isReuserLookingAtActiveAdvert =
    relation === 'public' && !isDefined(jobId) && !isDefined(counterOfferId) && advertStatus === 'active'
  const isCustomerLookingAtActiveReuserAdvert =
    relation === 'owner' &&
    !isDefined(jobId) &&
    !isDefined(counterOfferId) &&
    supplierType !== 'collector' &&
    advertStatus === 'active'
  // Underway
  const isCollectorLookingAtUnderwayAdvert = relation === 'collector' && advertStatus === 'helpunderway'
  const isCustomerLookingAtUnderwayCollectorAdvert =
    relation === 'owner' && isDefined(jobId) && supplierType === 'collector' && advertStatus === 'helpunderway'
  const isReuserLookingAtUnderwayAdvert = relation === 'junklover' && advertStatus === 'helpunderway'
  const isCustomerLookingAtUnderwayReuserAdvert =
    relation === 'owner' && isDefined(jobId) && supplierType === 'junklover' && advertStatus === 'helpunderway'

  return {
    isCollectorLookingAtActiveAdvert,
    isCustomerLookingAtActiveCollectorAdvert,
    isReuserLookingAtActiveAdvert,
    isCustomerLookingAtActiveReuserAdvert,
    isCollectorLookingAtUnderwayAdvert,
    isCustomerLookingAtUnderwayCollectorAdvert,
    isReuserLookingAtUnderwayAdvert,
    isCustomerLookingAtUnderwayReuserAdvert,
  }
}

export const getBannerText = ({
  isCollectorLookingAtActiveAdvert,
  isCustomerLookingAtActiveCollectorAdvert,
  isReuserLookingAtActiveAdvert,
  isCustomerLookingAtActiveReuserAdvert,
  isReuserLookingAtUnderwayAdvert,
  isCustomerLookingAtUnderwayReuserAdvert,
  junkLoverName,
}: BannerTextParams): string => {
  if (isCollectorLookingAtActiveAdvert) {
    return COLLECTOR_LOOKING_AT_ACTIVE_ADVERT
  }
  if (isCustomerLookingAtActiveCollectorAdvert) {
    return CUSTOMER_LOOKING_AT_ACTIVE_ADVERT
  }
  if (isReuserLookingAtActiveAdvert) {
    return REUSER_LOOKING_AT_ACTIVE_ADVERT
  }
  if (isCustomerLookingAtActiveReuserAdvert) {
    return CUSTOMER_LOOKING_AT_ACTIVE_REUSER_ADVERT(junkLoverName)
  }
  if (isReuserLookingAtUnderwayAdvert) {
    return REUSER_LOOKING_AT_UNDERWAY_ADVERT
  }
  if (isCustomerLookingAtUnderwayReuserAdvert) {
    return CUSTOMER_LOOKING_AT_UNDERWAY_REUSER_ADVERT
  }
  return ''
}
