import { flow } from 'lodash'

import Reducer from '../../types/reducer'
import { emailRequestModalLens, isEmailRequestInProgressLens } from './lens'
import State, { emailRequestDefaultState as defaultState } from './state'
import { CLOSE_EMAIL_REQUEST_MODAL, EMAIL_REQUEST_FAILURE, EMAIL_REQUEST_START, EMAIL_REQUEST_SUCCESS } from './types'

const emailRequestReducer: Reducer<State> = (state = defaultState(), action) => {
  switch (action.type) {
    case CLOSE_EMAIL_REQUEST_MODAL:
      return emailRequestModalLens(state).set(null)
    case EMAIL_REQUEST_START:
      return isEmailRequestInProgressLens(state).set(true)
    case EMAIL_REQUEST_FAILURE:
      return isEmailRequestInProgressLens(state).set(false)
    case EMAIL_REQUEST_SUCCESS:
      return flow(
        isEmailRequestInProgressLens,
        l => l.set(false),
        emailRequestModalLens,
        l => l.set(action.payload),
      )(state)
    default:
      return state
  }
}

export default emailRequestReducer
