import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg'
import { css, styled } from 'styled'
import { device } from 'utils/css'

export const LogoCss = css`
  display: block;
  width: 10em;

  @media ${device.mobile} {
    width: 8em;
  }

  @media ${device.mobileSmall} {
    width: 6em;
  }
`

export const LogoSvg = styled(LogoIcon)`
  ${LogoCss}
`

export const LogoImage = styled.img`
  ${LogoCss}
`
