import { isMobile } from 'utils/environment'

import { styled } from '../styled'
import { device } from '../utils/css'
import Text from './text/Text'

const FormInfo = styled(Text).attrs({ centered: isMobile ? undefined : true, size: isMobile ? undefined : 1.125 })`
  align-self: center;
  margin: 0 auto 1em;

  @media ${device.mobile} {
    align-self: flex-start;
  }
`

export default FormInfo
