import { loginReducer, SupportedAction } from '@lovejunk/core'
import Reducer from 'types/reducer'

import { returnToLens } from './lens'
import State, { loginDefaultState } from './state'
import { SET_RETURN_TO } from './types'

const reducer: Reducer<State> = (newState = loginDefaultState(), action) => {
  // TODO: If possible, implement without need to cast.
  const state = loginReducer(newState, action as SupportedAction) as State

  switch (action.type) {
    case SET_RETURN_TO:
      return returnToLens(state).set(action.payload)
    default:
      return state
  }
}

export default reducer
