import { Action } from 'redux'

import { FailureAction, PayloadAction } from '../../types/core'

export interface SupportRequestParams {
  message: string
  name: string

  email?: string
  phone?: string
}

export interface TradeAccountEnquiryParams {
  contactName: string
  organisation: string
  email: string
  message: string
  recaptchaToken: string

  phone?: string
  referredBy?: string
}

export const SUPPORT_REQUEST_SET_MODAL_OPEN = 'SUPPORT_REQUEST_SET_MODAL_OPEN'
export type SupportRequestSetModalOpen = PayloadAction<typeof SUPPORT_REQUEST_SET_MODAL_OPEN, boolean>

export const SUPPORT_REQUEST_START = 'SUPPORT_REQUEST_START'
export const SUPPORT_REQUEST_SUCCESS = 'SUPPORT_REQUEST_SUCCESS'
export const SUPPORT_REQUEST_FAILURE = 'SUPPORT_REQUEST_FAILURE'

export type SupportRequestStart = Action<typeof SUPPORT_REQUEST_START>
export type SupportRequestSuccess = Action<typeof SUPPORT_REQUEST_SUCCESS>
export type SupportRequestFailure = FailureAction<typeof SUPPORT_REQUEST_FAILURE>

export type SupportRequestResult = SupportRequestSuccess | SupportRequestFailure
export type SupportRequest = SupportRequestStart | SupportRequestResult

export type SupportRequestAction = SupportRequest | SupportRequestSetModalOpen
