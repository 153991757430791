import { first, join, size } from 'lodash/fp'
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { postcodeOrDistrict } from '../../entities/advert/helpers/role'
import {
  AcceptCounterOfferPayload,
  AdvertActionPayload,
  AdvertBase,
  AdvertReference,
  CollectionAgreedPayload,
  UserDetails,
} from '../../entities/advert/types'
import {
  AddPhonePressPayload,
  ChatActionUrls,
  ChatAdvertInfo,
  ChatAssetsUrlsPayload,
  ChatMessage,
  ChatMessagesVersion,
  ChatOtherParticipants,
  ChatUrlsPayload,
  CreateChatThreadPayload,
  FetchChatMessagesAppPayload,
  FetchChatMessagesUpdatesPayload,
  FetchChatMessagesUpdatesResult,
  OnShowAttachment,
  OnSubmitChat,
  OnSubmitChatWithAssets,
  RevealPhonePressPayload,
  StartConferenceCallPayload,
  SubmitChatMessage,
  SubmitChatMessageBundle,
  VIEW_PHONE_SUCCESS,
  ViewPhoneResult,
} from '../../entities/chat/types'
import { AcceptJunkLoverOfferResult } from '../../entities/customer-advert/types/actions'
import { isConflictError, isDefined, isNotFoundError, timeout } from '../../helpers'
import { useDelayedChat } from '../../hooks'
import { Dispatch, DispatchAsync, DispatchOptional, FavouriteId, NoOp, Optional, Transform } from '../../types/core'
import { ForwardRefComponent, ToElements } from '../../types/ui'
import { calculateAdvertViewingOwnership, getBannerText } from '../../utils'
import { Props as AdvertProps } from './Advert'
import { Props as BannerProps } from './Banner'
import { Props as FooterProps } from './Footer'
import { Props as WindowProps } from './Window'

interface AddPhoneButtonProps {
  onPress: NoOp
}

interface BookNowButtonProps {
  onPress?: Dispatch<CollectionAgreedPayload>
  progress?: boolean
  text: string
}

export interface ElementsProps {
  AddPhoneButton: AddPhoneButtonProps
  Advert: AdvertProps
  Banner: BannerProps
  BookNowButton: BookNowButtonProps
  Footer: FooterProps
  Header: unknown
  Root: unknown
  AdvertContainer?: unknown
}

interface Elements<T> extends ToElements<ElementsProps> {
  Window: ForwardRefComponent<WindowProps, T>
}

export interface DispatchProps {
  accept: DispatchAsync<AcceptCounterOfferPayload, Optional<AcceptJunkLoverOfferResult>>
  addPhoneRequested: Dispatch<boolean>
  createChatThread: Dispatch<CreateChatThreadPayload>
  fetchChatMessages: Dispatch<FetchChatMessagesAppPayload>
  fetchChatMessagesUpdates: DispatchAsync<FetchChatMessagesUpdatesPayload, FetchChatMessagesUpdatesResult>
  setCollectionAgreedPayload: Dispatch<CollectionAgreedPayload>
  setConferenceCallPayload: Dispatch<StartConferenceCallPayload>
  showConferenceCallModal: NoOp
  startConferenceCall: Dispatch<string>
  submitChatMessage: SubmitChatMessage
  submitChatMessageBundle: SubmitChatMessageBundle
  viewPhone: DispatchAsync<string, ViewPhoneResult>
}

interface OwnProps<T> {
  onAddPhone: Dispatch<AddPhonePressPayload>
  onTitle: Dispatch<string>
  onRevealPhone: Dispatch<string>
  onScrollToEnd: Dispatch<T>
  onShowAttachment: OnShowAttachment

  advert?: AdvertBase
  advertUrl?: string
  createChatThreadUrl?: string
  onAccepted?: DispatchOptional<AdvertReference>
  onAdvertPress?: NoOp
  interlocutorName?: string
  showPhone?: boolean
  title?: string
  viewMessagesUrl?: string
}

export interface StateProps {
  actionUrls: ChatActionUrls
  isCollector: boolean
  messages: ChatMessage[]
  version: ChatMessagesVersion
  viewPhoneInProgress: boolean
  currentAdvert?: AdvertBase
  advertInfo?: ChatAdvertInfo
  favouriteId?: FavouriteId
  otherParticipants?: ChatOtherParticipants
  ownerPhoneNumber?: string
}

type Props<T> = DispatchProps & OwnProps<T> & StateProps

const getInterlocutorDetails: Transform<AdvertBase, Optional<UserDetails>> = ({
  assignedCollectorDetails,
  assignedJunkLoverDetails,
  customerDetails,
  view,
}) => (view === 'owner' ? assignedCollectorDetails || assignedJunkLoverDetails : customerDetails)

function factory<T>({
  AdvertContainer,
  AddPhoneButton,
  Advert,
  Banner,
  BookNowButton,
  Footer,
  Header,
  Root,
  Window,
}: Elements<T>): FC<Props<T>> {
  return function Chat({
    accept,
    actionUrls,
    addPhoneRequested,
    advert,
    advertInfo,
    advertUrl,
    createChatThread,
    createChatThreadUrl,
    fetchChatMessages,
    fetchChatMessagesUpdates,
    interlocutorName,
    isCollector,
    messages,
    onAccepted,
    onAddPhone,
    onAdvertPress,
    onTitle,
    onRevealPhone,
    onScrollToEnd: onScrollToEndProp,
    onShowAttachment,
    otherParticipants,
    ownerPhoneNumber,
    setConferenceCallPayload,
    setCollectionAgreedPayload,
    showConferenceCallModal,
    showPhone = true,
    submitChatMessage,
    submitChatMessageBundle,
    title: titleProp,
    version,
    viewMessagesUrl: viewMessagesUrlProp,
    viewPhone,
    viewPhoneInProgress,
  }) {
    const {
      acceptJunkLoverOffer: acceptJunkLoverOfferUrl,
      addPhoneNumber: addPhoneNumberUrl,
      startConferenceCall: startConferenceCallUrl,
      submitMessage: submitMessageUrl,
      submitMessageWithImages: submitImageMessageUrl,
      submitMessageWithVideos: submitVideoMessageUrl,
      viewPhoneNumber: viewPhoneUrl,
      viewThreadUpdates: viewThreadUpdatesUrl,
    } = actionUrls
    const advertId = advert?.id || advertInfo?.id
    const advertRef = advertInfo?.reference
    const viewMessagesUrl = viewMessagesUrlProp || advert?.actions.viewMessages?.url
    const otherParticipantsTitle = useMemo(() => join(', ')(otherParticipants), [otherParticipants])
    const interlocutorFirstName = useMemo(() => advert && getInterlocutorDetails(advert)?.firstName, [advert])
    const jobId = advertInfo?.jobId
    const counterOfferId = advertInfo?.counterOfferId
    const advertStatus = advertInfo?.status || advert?.status
    const relation = advertInfo?.relation
    const supplierType = advert?.publicDetails?.supplierType

    const title = useMemo<string>(
      () => titleProp || otherParticipantsTitle || advertInfo?.title || interlocutorFirstName || 'Chat',
      [advertInfo?.title, interlocutorFirstName, otherParticipantsTitle, titleProp],
    )
    const messagesCount = size(messages)
    const messagesCountRef = useRef<number>(0)
    const [initialMessagesLoaded, setInitialMessagesLoaded] = useState<boolean>(false)
    const [initialFocus, setInitialFocus] = useState<boolean>(false)
    const windowRef = useRef<T>(null)
    const { current: windowRefCurrent } = windowRef

    const { onViewThreadUpdatesInterval } = useDelayedChat({
      fetchChatMessagesUpdates,
      version,
      viewThreadUpdatesUrl,
    })

    const onScrollToEnd = useCallback(
      () => windowRefCurrent && onScrollToEndProp(windowRefCurrent),
      [onScrollToEndProp, windowRefCurrent],
    )

    const scrollToEnd = useCallback(() => timeout(1000).then(onScrollToEnd), [onScrollToEnd])

    useEffect(() => {
      if (createChatThreadUrl) createChatThread({ url: createChatThreadUrl })
    }, [createChatThread, createChatThreadUrl])

    useEffect(() => {
      const { current: oldSize } = messagesCountRef

      if (messagesCount > oldSize) scrollToEnd()

      messagesCountRef.current = messagesCount
    }, [messagesCount, scrollToEnd])

    useEffect(() => {
      if (messages) setInitialMessagesLoaded(true)
    }, [messages])

    useEffect(() => {
      if (initialMessagesLoaded && !initialFocus) {
        scrollToEnd()
        setInitialFocus(true)
      }
    }, [initialFocus, initialMessagesLoaded, scrollToEnd])

    useEffect(() => {
      if (isDefined(viewMessagesUrl)) fetchChatMessages({ advertId, url: viewMessagesUrl })
    }, [advertId, fetchChatMessages, viewMessagesUrl])

    useEffect(() => {
      onTitle(title)
    }, [onTitle, title])

    const createRevealPhonePress = useCallback<Transform<RevealPhonePressPayload, NoOp>>(
      ({ viewPhoneUrl, startConferenceCallUrl }) =>
        () => {
          if (isDefined(startConferenceCallUrl) && isDefined(ownerPhoneNumber)) {
            setConferenceCallPayload({
              firstName: interlocutorFirstName,
              ownerPhoneNumber,
              startConferenceCallUrl,
            })
            showConferenceCallModal()
          } else
            viewPhone(viewPhoneUrl).then(action => {
              if (action.type === VIEW_PHONE_SUCCESS) {
                onRevealPhone(action.payload)
              } else if (isNotFoundError(action.payload) || isConflictError(action.payload)) {
                addPhoneRequested(true)
              }
            })
        },
      [
        addPhoneRequested,
        interlocutorFirstName,
        onRevealPhone,
        ownerPhoneNumber,
        setConferenceCallPayload,
        showConferenceCallModal,
        viewPhone,
      ],
    )

    const junkLoverName =
      (isDefined(acceptJunkLoverOfferUrl) ? interlocutorFirstName || interlocutorName : undefined) || 'the other party'

    const advertActionPayload = useMemo<Optional<AdvertActionPayload>>(
      () =>
        isDefined(acceptJunkLoverOfferUrl) && isDefined(advertUrl)
          ? {
              advertUrl,
              trackingEvents: ['match'],
              url: acceptJunkLoverOfferUrl,
            }
          : undefined,
      [acceptJunkLoverOfferUrl, advertUrl],
    )

    const createOnBookPress = useCallback<Transform<AdvertActionPayload, NoOp>>(
      payload => () => accept(payload).then(() => onAccepted?.(advertRef)),
      [accept, advertRef, onAccepted],
    )

    const onChatSubmit = useCallback(
      <T,>(action: T) => {
        onViewThreadUpdatesInterval()

        return action
      },
      [onViewThreadUpdatesInterval],
    )

    const submit = useCallback<SubmitChatMessage>(
      payload => {
        scrollToEnd()

        return submitChatMessage(payload).then(onChatSubmit)
      },
      [scrollToEnd, onChatSubmit, submitChatMessage],
    )

    const onSubmitBundle = useCallback<SubmitChatMessageBundle>(
      payload => {
        scrollToEnd()

        return submitChatMessageBundle(payload).then(onChatSubmit)
      },
      [scrollToEnd, onChatSubmit, submitChatMessageBundle],
    )

    const createOnSubmit = useCallback<Transform<ChatUrlsPayload, OnSubmitChat>>(
      chatUrlsPayload => chatMessagePayload => submit({ ...chatUrlsPayload, ...chatMessagePayload }),
      [submit],
    )

    const createOnAddPhone = useCallback<Transform<string, NoOp>>(
      addPhoneUrl => () => onAddPhone({ addPhoneUrl }),
      [onAddPhone],
    )

    const onAddPhonePress = useMemo(
      () => (isDefined(addPhoneNumberUrl) ? createOnAddPhone(addPhoneNumberUrl) : undefined),
      [addPhoneNumberUrl, createOnAddPhone],
    )

    const createOnSubmitWithAssets = useCallback<Transform<ChatAssetsUrlsPayload, OnSubmitChatWithAssets>>(
      chatAssetsUrlsPayload => chatMessageWithAssetsPayload =>
        onSubmitBundle({ ...chatAssetsUrlsPayload, ...chatMessageWithAssetsPayload }),
      [onSubmitBundle],
    )

    const onPhonePress = useMemo(
      () =>
        onAddPhonePress ||
        (isDefined(viewPhoneUrl) ? createRevealPhonePress({ viewPhoneUrl, startConferenceCallUrl }) : undefined),
      [createRevealPhonePress, onAddPhonePress, startConferenceCallUrl, viewPhoneUrl],
    )

    const onSubmit = useMemo(
      () => (isDefined(submitMessageUrl) ? createOnSubmit({ submitMessageUrl }) : undefined),
      [createOnSubmit, submitMessageUrl],
    )

    const onSubmitWithAssets = useMemo(
      () =>
        isDefined(submitImageMessageUrl) && isDefined(submitVideoMessageUrl)
          ? createOnSubmitWithAssets({ submitImageMessageUrl, submitVideoMessageUrl })
          : undefined,
      [createOnSubmitWithAssets, submitImageMessageUrl, submitVideoMessageUrl],
    )

    const advertPropsFromAdvert = useCallback<Transform<AdvertBase, AdvertProps>>(
      ({ locationDetails, metaDetails, publicDetails, status }) => ({
        postcode: postcodeOrDistrict(locationDetails.address, status, isCollector),
        status,
        imageUrl: first(publicDetails.images)?.fullUrl,
        price: publicDetails.price,
        timeDetails: publicDetails.timeDetails,
        title: publicDetails.title,
        reference: metaDetails.reference,
      }),
      [isCollector],
    )

    const advertProps = useMemo<Optional<AdvertProps>>(
      () => advertInfo || (advert && advertPropsFromAdvert(advert)),
      [advert, advertInfo, advertPropsFromAdvert],
    )

    const advertMatchAndOwnership = useMemo(
      () => calculateAdvertViewingOwnership({ advertStatus, relation, supplierType, jobId, counterOfferId }),
      [advertStatus, relation, supplierType, jobId, counterOfferId],
    )

    const bannerText = useMemo(() => {
      return getBannerText({ ...advertMatchAndOwnership, junkLoverName })
    }, [advertMatchAndOwnership, junkLoverName])
    return (
      <Root>
        <Header>
          {advertProps && AdvertContainer ? (
            <AdvertContainer>
              <Advert {...advertProps} onPress={onAdvertPress} />
            </AdvertContainer>
          ) : (
            advertProps && <Advert {...advertProps} onPress={onAdvertPress} />
          )}
          {onAddPhonePress && showPhone ? <AddPhoneButton onPress={onAddPhonePress} /> : null}
          {advertActionPayload && (
            <BookNowButton
              onPress={() =>
                setCollectionAgreedPayload({
                  ...advertActionPayload,
                  showModal: true,
                  action: createOnBookPress(advertActionPayload),
                  junkLoverName,
                })
              }
              text="Collection time agreed?"
            />
          )}
        </Header>
        {bannerText ? <Banner text={bannerText} /> : null}
        <Window messages={messages} onShowAttachment={onShowAttachment} ref={windowRef} />
        <Footer
          onFocus={scrollToEnd}
          onPhonePress={onPhonePress}
          onSubmit={onSubmit}
          onSubmitWithAssets={onSubmitWithAssets}
          showPhone={showPhone}
          viewPhoneInProgress={viewPhoneInProgress}
        />
      </Root>
    )
  }
}

export default factory
