import { Currency, Format, Identity, ProcessImageOptions } from './types/core'

export const APP_NAME = 'LoveJunk'
export const APP_STORE_ID = '1518229240'
export const GOOGLE_STORE_ID = 'com.lovejunk'
export const API_URL_KEY = 'apiKey'

// Other
export const DEFAULT_CURRENCY: Currency = 'GBP'
export const MINIMUM_RATING_TO_SHARE = 4
export const MINIMUM_RATING_TO_ADD_COLLECTOR_TO_FAVOURITES = 5
export const RECAPTCHA_CHECK_FAILED_MESSAGE = 'Recaptcha check failed'
export const SHOW_RED_DOT_HEADER = 1
export const SHOW_RED_DOT_DRAWER = 1

export const LOVEJUNK_URL = 'https://www.lovejunk.com'
export const LOVEJUNK_BOOKINGS_URL = 'https://bookings.lovejunk.com'
export const LOVEJUNK_STAGING_BOOKINGS_URL = 'https://bookings-staging.lovejunk.com'
const APP_URL = 'https://app.lovejunk.com'
const BLOG_URL = LOVEJUNK_URL + '/blog'
const GUIDES_ADVICE = BLOG_URL + '/guides-advice'

// Urls
export const ABOUT_US_URL = BLOG_URL + '/trash-talk/about-us'
export const APP_STORE_URL = 'https://apps.apple.com/gb/app/lovejunk/id' + APP_STORE_ID
export const BECOME_COLLECTOR_URL = LOVEJUNK_URL + '/become-a-collector'
export const BECOME_JUNKLOVER_URL = LOVEJUNK_URL + '/become-a-reuser'
export const COLLECTION_PRICES_URL = LOVEJUNK_URL + '/gallery/rubbish-removal'
export const CUSTOMER_REVIEWS_URL = BLOG_URL + '/trash-talk/customer-reviews'
export const DELIVERY_PRICES_PAGE_URL = LOVEJUNK_URL + '/delivery'
export const DOWNLOAD_URL = APP_URL + '/download'
export const GOOGLE_STORE_DYNAMIC_LINK = APP_URL + '/googleplay'
export const GOOGLE_STORE_URL = 'https://play.google.com/store/apps/details?id=' + GOOGLE_STORE_ID
export const HAZARDOUS_WASTE_URL = GUIDES_ADVICE + '/hazardous-waste/'
export const HELP_URL = LOVEJUNK_URL + '/help'
export const HOMEPAGE_URL = LOVEJUNK_URL
export const INFO_URL_WEB = LOVEJUNK_URL + '/gallery-guideweb'
export const LICENSED_WASTE_CARRIER_URL = 'https://www.gov.uk/waste-carrier-or-broker-registration'
export const PRIVACY_URL = LOVEJUNK_URL + '/privacy'
export const SHARE_URL = LOVEJUNK_URL + '/share'
export const SUPPORT_EMAIL = 'support@lovejunk.com'
export const TERMS_URL = LOVEJUNK_URL + '/terms'
export const WTN_TOOL_URL = LOVEJUNK_URL + '/waste-transfer-note-creator'

// new card screen
export const ADD_CARD_SCREEN_POST_SETUP_TITLE = 'Confirm your booking'
export const VAT_STATUS_DESCRIPTION = 'Show collectors offers ex VAT'
export const ADD_CARD_SUBMIT_CAPTION_CONFIRM_BOOKING = 'Confirm booking now'
export const ADD_CARD_SUBMIT_CAPTION_COMPLETE_PAYMENT = 'Complete payment'
export const ADD_CARD_SUBMIT_CAPTION_DEFAULT = 'Save card'

// home gallery screen
export const HOME_GALLERY_BUTTON = 'GET JUNK COLLECTED'
export const SHOW_ADVERT_DURATION = false
export const SHOW_BEST_COUNTER_OFFER = true
export const SHOW_BEST_COUNTER_OFFER_TO_COLLECTOR = true
export const SHOW_CHAT_PHONE = true
export const SHOW_COUNTER_OFFER_INDICATOR = false
export const SHOW_MATCHED_TIME = false
export const SHOW_OFFER_PRICE = true
export const SHOW_REUSABLE = true
export const SHOW_UNDER_OFFER = true
export const MAX_DISTANCE = 100

// new advert screen
export const BOOKING_FORM_TITLE = 'Listing details'
export const BUILDER_WASTE_TITLE = 'DIY / builder waste'
export const BUILDER_WASTE_DESCRIPTION = 'e.g. rubble, tiles, flooring'
export const COLLECTION_TIME_DESCRIPTION = 'Weekdays (Anytime) offer best prices'
export const COLLECTION_TIME_TITLE = 'Collection time slot'
export const DEFAULT_PAGE_TITLE = 'Create a listing to find a collector fast!'
export const EASY_ACCESS_TITLE = 'Easy access'
export const EASY_ACCESS_DESCRIPTION = 'Out front & obvious'
export const EASY_ACCESS_REASON_DESCRIPTION = 'e.g. upstairs, back garden, poor parking'
export const FREEGLE_PAGE_TITLE = 'Find a collector in seconds'
export const MAIN_TITLE = 'Summarise your junk'
export const MAIN_TITLE_DESCRIPTION = 'e.g. bag of rubble and chair'
export const MAIN_TITLE_MAX_LENGTH = 30
export const NEW_ADVERT_EDIT_HEADING =
  'Editing your listing will result in a new listing being published. Existing collector offers will not be saved.'
export const NEW_ADVERT_MAX_ASSETS = 4
export const NEW_ADVERT_SUMMARY_DESCRIPTION = 'More info helps collector response'
export const NEW_ADVERT_SUMMARY_TITLE = 'Describe your junk in detail'
export const NEW_ADVERT_SUMMARY_MAX_LENGTH = 350
export const PRICE_ESTIMATE = ''
export const PRICE_ESTIMATE_TOP_OF_RANGE = ''
export const PRICE_ESTIMATE_BOTTOM_OF_RANGE = ''
export const PRICE_ESTIMATE_BOTTOM_OF_RANGE_ORANGE = false
export const RESIDENTIAL_TITLE = 'Residential'
export const RESIDENTIAL_DESCRIPTION = 'Not business address'
export const REUSE_ONLY_TEXT =
  'I only want to get offers to collect my junk for free (i.e. no interest in receiving quotes from waste collectors)'
export const REUSABLE_TITLE = 'Reusable'
export const REUSABLE_DESCRIPTION = 'Could it all be reused?'
export const REVIEW_PLACEHOLDER = 'Any comments or feedback?'
export const REVIEW_SUBMIT_CAPTION = 'Submit'
export const PUBLISH_BUTTON = 'LIST MY JUNK'
export const PUBLISH_BUTTON_WIDTH = 55
export const TIPPING_NEW_TIP_MAX_ASSETS = 1

export const XMAS_MODE = false

export const AUTH_IDENTIFIER_NOTE = 'Your details are not shared with collectors. You will receive quotes via LoveJunk'

// picture screen
export const PICTURES_BUTTON_TITLE = 'Take some photos of the junk'
export const PICTURES_BUTTON_SUBTITLE = '(Not ready? Use photo of similar job)'

// create account screen
export const CREATE_ACCOUNT_TITLE: Format<boolean> = isCustomer =>
  isCustomer ? 'Enter your details to receive quotes' : 'Enter your details'
export const CREATE_ACCOUNT_SUBMIT_CAPTION: Format<boolean> = isCustomer =>
  isCustomer ? 'Send me quotes' : 'Create account'

// partner links
export const FREEGLE_HOMEPAGE = 'https://www.ilovefreegle.org/'

// add card feedback modal
export const ADD_CARD_FEEDBACK_MODAL_TITLE = 'Error adding card'
export const ADD_CARD_FEEDBACK_MODAL_TEXT =
  'There was a problem adding your card details. Please retry or use a new card'

// advert details screen
export const FREE_TEXT = 'FREE'
export const COLLECT_FOR_FREE_CAPTION = 'Collect for free'
export const VIEWS_OWNER_ONLY = true
export const NEED_IT_DELIVERED_TEXT = 'Need it delivered?'
export const CHECK_PRICES_TEXT = 'Check prices'
export const MESSAGE_OWNER_TEXT = 'Message owner'
export const VIEW_MESSAGES_TEXT = 'View messages'
export const CONTACT_CUSTOMER_TEXT = 'Contact customer'
export const MESSAGED_OWNER_AWAITING_REPLY_TEXT = 'Messaged owner - awaiting reply'

// review collection screen
export const ADD_FAVOURITE_COLLECTOR_SUBMIT_CAPTION = 'Add to favourites'
export const ADD_FAVOURITE_COLLECTOR_TITLE = 'Add to favourite collectors'
export const ADD_FAVOURITE_COLLECTOR_TEXT: Identity<string> = collectorName =>
  `Choosing ${collectorName} as a 'favourite collector' allows you to message them anytime and to post jobs just to them (instead of all collectors) but still access ${APP_NAME} support whenever required and keep within privacy and security of the platform.`

// share modal screen
export const SHARE_SCREEN_TITLE = ''
export const SHARE_SCREEN_BODY = ''

export const CUSTOM_API_URL_VALUE = 'custom'
export const CUSTOM_API_URL_PREFIX = 'https://'
export const CUSTOM_API_URL_SUFFIX = '-elmer.api-lovejunk.com/v1/elmer/'

export const DEFAULT_PROCESS_IMAGE_OPTIONS: ProcessImageOptions = {
  width: 1080,
  height: 1920,
  format: 'JPEG',
  quality: 100,
}

export const ADD_FAVOURITE_COLLECTOR_MODAL_ERROR = 'There was an error adding collector to favourites.'
export const FAVOURITE_COLLECTOR_SELECT_TEXT =
  'Select the collector(s) you want to notify. Only they will see this listing.'

// invalid timeslot modal
export const INVALID_TIME_SLOT_MODAL_TITLE = 'Invalid collection time'
export const INVALID_TIME_SLOT_MODAL_BODY =
  'The collection time you selected is no longer available. Please select a new one.'
export const INVALID_TIME_SLOT_MODAL_BUTTON = 'Edit'

export const EMAIL_REQUEST_MODAL_BODY: Identity<string> = email => `Sent to ${email}.`

// AdvertCreationTimeoutModal
export const ADVERT_CREATION_TIMEOUT_MODAL_TITLE = 'So sorry, something went wrong'
export const ADVERT_CREATION_TIMEOUT_MODAL_BODY =
  'Please check your email to see if you received your listing confirmation. If not, please press publish to try again.'

// AdvertCreationTooManyModal
export const ADVERT_CREATION_TOO_MANY_MODAL_TITLE = 'Looks like your listing has already been published'
export const ADVERT_CREATION_TOO_MANY_MODAL_BODY =
  'Please check your email to see if you received your listing confirmation. If not, please press publish to try again.'

// JunkReuserUnderReviewModal
export const JUNK_REUSER_UNDER_REVIEW_MODAL_TITLE = 'Thanks for registering as a Junk Reuser'
export const JUNK_REUSER_UNDER_REVIEW_MODAL_BODY = 'Your account is under review'

// Chat screen
// banner texts
export const COLLECTOR_LOOKING_AT_ACTIVE_ADVERT =
  'No booking confirmed yet. Please instruct customer to confirm booking to view address, phone and secure payment.'
export const CUSTOMER_LOOKING_AT_ACTIVE_ADVERT =
  'No booking confirmed yet. Once collection details agreed, confirm the job using BOOK NOW button on previous page.'
export const CUSTOMER_LOOKING_AT_UNDERWAY_REUSER_ADVERT =
  "After collection, please tap 'Collection done' on the listing page so we can close the job and record your positive impact on the environment"
export const REUSER_LOOKING_AT_ACTIVE_ADVERT =
  "Instruct customer to click 'Collection time agreed' as soon as you have agreed a date and time for the collection."
export const REUSER_LOOKING_AT_UNDERWAY_ADVERT =
  "Collection not yet confirmed. Tap 'Collection done' on the listing page to ensure legal ownership of item passes to you"
export const CUSTOMER_LOOKING_AT_ACTIVE_REUSER_ADVERT: Identity<string> = junkLoverName =>
  `Clicking the green button notifies ${junkLoverName} you want them to collect and stops the listing from expiring after a period of time.`
