import { formatDistanceToNow } from 'date-fns'
import { flow } from 'lodash/fp'

import { parseDate } from '../../helpers'
import { Format } from '../../types/core'
import { CustomerDefaultServiceReceipt, CustomerReceipt } from './types'

export const isCustomerDefaultServiceReceipt = (model: CustomerReceipt): model is CustomerDefaultServiceReceipt =>
  model.type === 'customer'

export const formatOfferDuration: Format<string> = flow(parseDate, formatDistanceToNow)
