import { get } from '../../api'
import { CompoundApi } from '../../api-action'
import { responseToBody } from '../../helpers'
import { Transform } from '../../types/core'
import { TimeSlots, TimeSlotsResponse } from './types'

export const getTimeSlotsApi: Transform<string, CompoundApi<TimeSlotsResponse, TimeSlots>> = url => [
  get(url),
  responseToBody,
]
