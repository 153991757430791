export const action =
  <T>(type: T) =>
  () => ({
    type,
  })

export const payloadAction =
  <T>(type: T) =>
  <P>(payload: P) => ({
    type,
    payload,
  })

export const presetPayloadAction =
  <T>(type: T) =>
  <P>(payload: P) =>
  () => ({
    type,
    payload,
  })

// For some reason TS can't recognize properly T type if defined as <T>(type: T) => (merger: ...)
//  and is passing type as a `string`.
export const mixedPayloadAction =
  <T, P, P1, P2>(type: T, merger: (p1: P1, p2: P2) => P) =>
  (payload1: P1) =>
  (payload2: P2) => ({
    type,
    payload: merger(payload1, payload2),
  })

export const failureAction = payloadAction
