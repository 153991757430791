import { Get, Nullable } from '@lovejunk/core'

import { Utm } from './types'

interface State {
  params: Nullable<Partial<Utm>>
}

export const defaultState: Get<State> = () => ({
  params: null,
})

export default State
