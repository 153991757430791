import { Nullable, PaginationMeta } from '../../types/core'
import { ActivityItem } from './types'

export interface Activity<T = ActivityItem> {
  isLoading: boolean
  isLoadingNext: boolean
  items: T[]
  meta: Nullable<PaginationMeta>
}

export const initialActivity = {
  isLoading: false,
  isLoadingNext: false,
  items: [],
  meta: null,
}

interface State {
  current: Activity
  history: Activity
}

export const defaultState: State = {
  current: initialActivity,
  history: initialActivity,
}

export default State
