import { flow } from 'lodash/fp'

import { buildLens, isNotNull, lensFactory } from '../../helpers'
import RootState from '../../state'
import { GetLens } from '../../types/core'
import State from './state'

export const appRootLens: GetLens<RootState, State> = state => () => state.app

const lens = lensFactory<State>()

export const apiUrlLens = lens('apiUrl')
export const getApiUrlLens = buildLens(appRootLens, apiUrlLens)

export const configurationLens = lens('configuration')
export const getConfigurationLens = buildLens(appRootLens, configurationLens)
export const hasConfiguration = flow(getConfigurationLens, isNotNull)

export const errorLens = lens('error')

export const hadSuccessfulOauthCheckLens = lens('hadSuccessfulOauthCheck')
export const hadSuccessfulOauthCheck = buildLens(appRootLens, hadSuccessfulOauthCheckLens)

export const isApiUrlFetchedLens = lens('isApiUrlFetched')
export const isApiUrlFetched = buildLens(appRootLens, isApiUrlFetchedLens)

export const isRehydratedLens = lens('isRehydrated')
export const isRehydrated = buildLens(appRootLens, isRehydratedLens)

export const getConstantsLens = flow(getConfigurationLens, configuration => configuration && configuration.constants)
export const getEndpointsLens = flow(getConfigurationLens, configuration => configuration && configuration.endpoints)

export const selectedActivityLens = lens('selectedActivity')

export default appRootLens
