import React from 'react'

import { Color } from '../theme'
import { Transform } from '../types/core'
import { ComponentFactory, ToElements } from '../types/ui'

const marginVertical: Transform<number, Transform<boolean, number>> = marginBase => border =>
  marginBase + 0.5 * Number(border)

interface RootProps {
  color: Color
  spacing: number
  thickness: number
}

export interface Props {
  border?: boolean
  marginBase?: number
}

export interface ElementsProps {
  Root: RootProps
}

type Elements = ToElements<ElementsProps>

const factory: ComponentFactory<Elements, Props> =
  ({ Root }) =>
  ({ border = true, marginBase = 0.5 }) =>
    <Root color="grey" spacing={marginVertical(marginBase)(border)} thickness={Number(border)} />

export default factory
