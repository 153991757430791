import { Dispatch, NoOp, Nullable, Postcode, PostcodeResult, stateProps, Transform } from '@lovejunk/core'
import {
  addressRootLens,
  isPostcodeSupportedLens,
  isPostcodeValidLens,
  onPostcodeChange,
  postcodeLens,
  submitPostcode,
  wasPostcodeCheckedLens,
} from 'entities/address'
import useFormSubmit from 'hooks/form-submit'
import { toString, trim } from 'lodash/fp'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { styled } from '../styled'
import { Styles } from '../types/ui'
import { device } from '../utils/css'
import MainButton from './button/MainButton'
import Form from './containers/Form'
import FormField from './containers/FormField'
import TextInput from './input/TextInput'

interface OwnProps {
  onSubmit: Dispatch<Postcode>
  text: string

  autoFocus?: boolean
  buttonId?: string
}

interface DispatchProps {
  onPostcodeChange: Transform<Nullable<string>, Promise<PostcodeResult>>
  submitPostcode: NoOp
}

interface StateProps {
  isPostcodeSupported: Nullable<boolean>
  isPostcodeValid: Nullable<boolean>
  postcode: Nullable<Postcode>
  wasPostcodeChecked: Nullable<boolean>
}

type Props = DispatchProps & OwnProps & StateProps

const PostcodeForm: FC<Props> = ({
  autoFocus,
  buttonId,
  isPostcodeSupported,
  isPostcodeValid,
  onPostcodeChange,
  onSubmit: onSubmitProp,
  postcode: defaultPostcode,
  submitPostcode,
  text,
  wasPostcodeChecked,
}) => {
  const [postcode, setPostcode] = useState<Postcode>()
  const [isDirty, setDirty] = useState<boolean>(false)
  const postcodeError =
    isDirty && wasPostcodeChecked === true
      ? undefined
      : isPostcodeValid === false
      ? 'invalid postcode'
      : isPostcodeSupported === false
      ? 'not within coverage area'
      : undefined

  const onChange = useCallback(
    (postcode: string) => {
      setPostcode(postcode)
      setDirty(true)
    },
    [setPostcode],
  )
  const onSubmit = useCallback(() => {
    const postcodeToCheck: Postcode = trim(toString(postcode))

    setDirty(false)
    submitPostcode()
    onPostcodeChange(postcodeToCheck).then(() => onSubmitProp(postcodeToCheck))
  }, [onPostcodeChange, onSubmitProp, postcode, submitPostcode])

  useEffect(() => {
    setPostcode(toString(defaultPostcode))
  }, [defaultPostcode, setPostcode])

  const onFormSubmit = useFormSubmit(onSubmit)

  return (
    <Form compact onSubmit={onFormSubmit}>
      <FormField>
        <InputContainer>
          <Input
            autoFocus={autoFocus}
            error={postcodeError}
            inputStyle={styles.input}
            nativePlaceholder="postcode"
            onChange={onChange}
            value={postcode}
          />
          <Button text={text} onPress={onSubmit} id={buttonId} />
        </InputContainer>
      </FormField>
    </Form>
  )
}

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  @media ${device.mobile} {
    flex-direction: column;
  }
`

const Input = styled(TextInput)`
  margin-right: 1em;

  @media ${device.mobile} {
    width: 100%;
    margin: 0 auto 1em;
  }
`

const Button = styled(MainButton)`
  @media ${device.mobile} {
    width: 100%;
    margin: 0 auto;
  }
`

const styles: Styles = {
  input: {
    textTransform: 'uppercase',
  },
}

const mapStateToProps = stateProps(addressRootLens)(state => ({
  isPostcodeSupported: isPostcodeSupportedLens(state).get(),
  isPostcodeValid: isPostcodeValidLens(state).get(),
  postcode: postcodeLens(state).get(),
  wasPostcodeChecked: wasPostcodeCheckedLens(state).get(),
}))

const mapDispatchToProp = {
  onPostcodeChange,
  submitPostcode,
}

export default connect(mapStateToProps, mapDispatchToProp)(PostcodeForm)
