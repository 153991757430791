import React from 'react'
import { styled, ThemeProps } from 'styled'

import CenteredRow from '../containers/CenteredRow'
import Label from '../Label'
import Button, { Props as ButtonProps } from './Button'

interface Props extends Omit<ButtonProps, 'state'> {
  description: string
  title: string

  error?: string
  required?: boolean
}

const Toggle = ({ description, required, title, ...props }: Props) => (
  <Root required={required}>
    <StyledLabel title={title} description={description} />
    <Buttons>
      <Button state={true} {...props} />
      <Button state={false} {...props} />
    </Buttons>
    <Error>{props.error}</Error>
  </Root>
)

interface RootProps {
  required?: boolean
}

const Root = styled(CenteredRow)<RootProps>`
  position: relative;
  border-bottom-color: ${({ required, theme: { colors } }) => (required ? colors.required : 'transparent')};
  border-bottom-width: ${({ required }) => Number(Boolean(required))};
`

const StyledLabel = styled(Label)`
  flex-grow: 1;
  margin-bottom: 0;
`

const Buttons = CenteredRow

const Error = styled.p<ThemeProps>`
  display: block;
  margin: 0;
  position: absolute;
  right: 4px;
  top: -18px;
  color: ${({ theme: { colors } }) => colors.error};
  font-weight: bold;
  text-align: left;
  font-size: 12px;
`

export default Toggle
