import { buildLens, GetLens, lensFactory } from '@lovejunk/core'
import RootState from 'core/state'

import State from './state'

export const loginRootLens: GetLens<RootState, State> = state => () => state.login

const lens = lensFactory<State>()

export const returnToLens = lens('returnTo')

export const getReturnTo = buildLens(loginRootLens, returnToLens)

export default loginRootLens
