import { flow } from 'lodash/fp'

import { action, failureAction, payloadAction } from '../../action-factory'
import apiAction, { ActionBundle } from '../../api-action'
import { isHttpError, isUnprocessable, responseToBody } from '../../helpers'
import { FailureActionFactory, Nullable, PayloadActionFactory } from '../../types/core'
import { PayloadThunk } from '../../types/thunk'
import { withEndpoint } from '../app/helpers'
import { fetchFreegleAdvertApi } from './api'
import {
  FETCH_FREEGLE_ADVERT_FAILURE,
  FETCH_FREEGLE_ADVERT_START,
  FETCH_FREEGLE_ADVERT_SUCCESS,
  FetchFreegleAdvertFailure,
  FetchFreegleAdvertParams,
  FetchFreegleAdvertResponse,
  FetchFreegleAdvertResult,
  FetchFreegleAdvertStart,
  FetchFreegleAdvertSuccess,
  FreegleAdvertErrorData,
  SET_FREEGLE_HAS_POSTCODE,
  SetFreegleHasPostcode,
} from './types'

const parseFreegleError = (error: Error) =>
  isHttpError(error) && isUnprocessable(error)
    ? new FreegleAdvertError(responseToBody(JSON.parse(error.message)))
    : error

const fetchFreegleAdvertFailure: FailureActionFactory<FetchFreegleAdvertFailure> = flow(
  parseFreegleError,
  failureAction(FETCH_FREEGLE_ADVERT_FAILURE),
)

export const fetchFreegleAdvertActionBundle: ActionBundle<
  FetchFreegleAdvertStart,
  FetchFreegleAdvertSuccess,
  FetchFreegleAdvertFailure,
  FetchFreegleAdvertResponse
> = [action(FETCH_FREEGLE_ADVERT_START), payloadAction(FETCH_FREEGLE_ADVERT_SUCCESS), fetchFreegleAdvertFailure]

export class FreegleAdvertError extends Error {
  constructor(readonly data: FreegleAdvertErrorData) {
    super('freegle')
  }
}

export const fetchFreegleAdvert: PayloadThunk<FetchFreegleAdvertParams, FetchFreegleAdvertResult> = params =>
  withEndpoint(
    'freegleListings',
    url => apiAction(fetchFreegleAdvertActionBundle)(fetchFreegleAdvertApi(url, params)),
    fetchFreegleAdvertFailure,
  )

export const setFreegleHasPostcode: PayloadActionFactory<SetFreegleHasPostcode, Nullable<boolean>> = payloadAction(
  SET_FREEGLE_HAS_POSTCODE,
)
