import { ChatElementsProps, chatFactory as factory } from '@lovejunk/core'
import { BsTelephonePlusFill } from 'react-icons/bs'
import { styled } from 'styled'
import { isMobile } from 'utils/environment'

import IconButton from '../button/IconButton'
import MainButton from '../button/MainButton'
import CenteredRow from '../containers/CenteredRow'
import Panel from '../Panel'
import Advert, { AdvertContainer } from './Advert'
import Banner from './Banner'
import Footer from './Footer'
import Window, { Element as WindowElement } from './Window'

const Chat = factory<WindowElement>({
  AddPhoneButton: styled(IconButton).attrs({ color: 'primary', ReactIcon: BsTelephonePlusFill, size: 2 })`
    padding: 2em;
  `,
  Advert,
  AdvertContainer,
  Banner,
  BookNowButton: styled(MainButton).attrs({
    shapeStyle: {
      minWidth: 'auto',
      maxWidth: '70%',
      float: 'right',
    },
    padding: isMobile ? '0.75em 0.8em' : '0.75em 1em',
    style: { fontSize: isMobile ? '1rem' : '1.2rem' },
    mini: isMobile,
    uppercase: true,
  })<ChatElementsProps['BookNowButton']>`
    margin-right: ${isMobile ? 0 : 10}px;
  `,
  Footer,
  Header: styled(CenteredRow)`
    justify-content: space-between;
  `,
  Root: Panel,
  Window,
})

export default Chat
