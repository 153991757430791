import { buildLens, lensFactory } from '../../helpers'
import RootState from '../../state'
import { GetLens } from '../../types/core'
import State from './state'

export const oauthRootLens: GetLens<RootState, State> = state => () => state.oauth

const lens = lensFactory<State>()

export const credentialsLens = lens('credentials')
export const isGettingTokenLens = lens('isGettingToken')
export const isCheckingTokenLens = lens('isCheckingToken')

export const getCredentials = buildLens(oauthRootLens, credentialsLens)

export default oauthRootLens
