import { find, flow, indexOf, map, unionBy } from 'lodash/fp'

import { lensFactory, replaceAt } from '../../helpers'
import { Lens, SetLens, UpdateLens } from '../../types/core'
import { ActivityItem, ActivityItemResource, ActivityType } from '../activities/types'
import { AdvertId } from '../advert/types'
import { ReloadActivityHasUnreadMessagesSuccessPayload } from '../chat'
import { unmarshal } from './marshaller'
import State, { Activity } from './state'

const activityTypeSimpleLens = lensFactory<Activity>()

export const createActivityLens = <T extends State>(type: ActivityType) => {
  const itemsLens: Lens<T, ActivityItem[], ActivityItemResource[]> = state => ({
    get: () => state[type].items,
    set: resource => ({ ...state, [type]: { ...state[type], items: map(unmarshal)(resource) } }),
  })

  const mergeItemsLens: SetLens<T, ActivityItemResource[]> = state => resource => ({
    ...state,
    [type]: {
      ...state[type],
      items: unionBy<ActivityItem>('advertId')(state[type].items)(map(unmarshal)(resource)),
    },
  })

  const clearLens: UpdateLens<T> = state => ({ ...state, [type]: { ...state[type], items: [] } })

  const hasUnreadMessagesLens: Lens<T, boolean, ReloadActivityHasUnreadMessagesSuccessPayload, AdvertId> = state => ({
    get: advertId => Boolean(find<ActivityItem>({ advertId })(state[type].items)?.hasUnreadMessages),
    set: ({ advertId, hasUnreadMessages }) => {
      const { items: oldItems } = state[type]
      const item = find<ActivityItem>({ advertId })(oldItems)

      if (!item) return state

      const index = indexOf(item)(oldItems)
      const items = replaceAt(index)(oldItems)({ ...item, hasUnreadMessages })

      return { ...state, [type]: { ...state[type], items } }
    },
  })

  const createActivityLens =
    <K extends keyof Activity>(key: K): Lens<T, Activity[K]> =>
    state => {
      const { get, set } = activityTypeSimpleLens(key)(state[type])

      return {
        get,
        set: flow(set, value => ({ ...state, [type]: value })),
      }
    }

  const metaLens = createActivityLens('meta')
  const loadingLens = createActivityLens('isLoading')
  const loadingNextLens = createActivityLens('isLoadingNext')

  return { clearLens, hasUnreadMessagesLens, itemsLens, loadingLens, loadingNextLens, mergeItemsLens, metaLens }
}

export const currentActivityLens = createActivityLens<State>('current')
export const historyActivityLens = createActivityLens<State>('history')
