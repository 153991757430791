import { Action } from 'redux'

import { Credentials, FailureAction, Nullable, PayloadAction, Response } from '../../types/core'

export interface TokenStatus {
  oauth: string
  session: string
}

interface GetOAuthAccessTokenBody {
  accessToken: string
  tokenType: string
  expiresIn: number
  scope: string
}

export type GetOAuthAccessTokenResponse = Response<GetOAuthAccessTokenBody>

export type CheckOAuthTokenStatusResponse = Response<TokenStatus>

export const GET_TOKEN_START = 'GET_TOKEN_START'
export const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS'
export const GET_TOKEN_FAILURE = 'GET_TOKEN_FAILURE'

export const CHECK_TOKEN_START = 'CHECK_TOKEN_START'
export const CHECK_TOKEN_SUCCESS = 'CHECK_TOKEN_SUCCESS'
export const CHECK_TOKEN_FAILURE = 'CHECK_TOKEN_FAILURE'

export type GetTokenStart = Action<typeof GET_TOKEN_START>
export type GetTokenFailure = FailureAction<typeof GET_TOKEN_FAILURE>
export type GetTokenSuccess = PayloadAction<typeof GET_TOKEN_SUCCESS, Credentials>

export type GetTokenResult = GetTokenFailure | GetTokenSuccess
export type GetToken = GetTokenStart | GetTokenResult

export type CheckTokenStart = Action<typeof CHECK_TOKEN_START>
export type CheckTokenFailure = Action<typeof CHECK_TOKEN_FAILURE>
export type CheckTokenSuccess = Action<typeof CHECK_TOKEN_SUCCESS>

export type CheckTokenResult = CheckTokenFailure | CheckTokenSuccess
export type CheckToken = CheckTokenStart | CheckTokenResult

export type OAuthAction = GetToken | CheckToken
export type OAuthResult = CheckTokenResult | GetTokenResult
export type OAuthResultSuccess = CheckTokenSuccess | GetTokenSuccess

type GrantType = 'authorization_code'
type Scope = 'elmerpostcodewidget' | 'lovejunkapp' | 'lovejunkweb'

export interface OAuthConfig {
  oauthParams: OAuthParams
}

export interface OAuthParams {
  clientId: string
  grantType: GrantType
  clientSecret: string
  scope: Scope
}

export interface CheckOrGetTokenPayload {
  credentials: Nullable<Credentials>
  sessionToken: Nullable<string>
}

export interface CheckOAuthTokenStatusPayload {
  accessToken: string
  sessionToken: Nullable<string>
  tokenStatusesUrl: string
}
