import React, { PropsWithChildren } from 'react'

import { Color } from '../theme'
import { NoOp } from '../types/core'
import { ComponentFactory, ToElements } from '../types/ui'

interface RootProps {
  onPress?: NoOp
}

export interface ElementsProps {
  Chevron: unknown
  Dot: unknown
  Label: unknown
  Root: RootProps
  Value: unknown
}

export interface ListItemProps {
  label: string

  dot?: boolean
  noChevron?: boolean
  onPress?: NoOp
  noBorder?: boolean
  labelColor?: string | Color
  chevronColor?: string | Color
  valueColor?: string | Color
  size?: number
}

type Elements = ToElements<ElementsProps>

const factory: ComponentFactory<Elements, PropsWithChildren<ListItemProps>> =
  ({ Chevron, Dot, Label, Root, Value }) =>
  ({ chevronColor, children, dot, label, labelColor, noChevron, onPress, size, valueColor, ...props }) =>
    (
      <Root {...props} onPress={onPress}>
        <Label labelColor={labelColor} size={size}>
          {label}
          {dot && <Dot />}
        </Label>
        <Value valueColor={valueColor} size={size}>
          {children}
        </Value>
        {onPress && !noChevron ? <Chevron chevronColor={chevronColor} /> : null}
      </Root>
    )

export default factory
