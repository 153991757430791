import { appDefaultState as coreAppDefaultState, AppState as CoreAppState, Nullable } from '@lovejunk/core'
import { apiUrl } from 'utils/environment'

import { Navigation } from './types'

interface AppState extends CoreAppState {
  accountDeleted: boolean
  advertsVisited: boolean
  isMenuVisible: boolean
  navigation: Nullable<Navigation>
}

export const appDefaultState: AppState = {
  ...coreAppDefaultState,
  accountDeleted: false,
  apiUrl,
  advertsVisited: false,
  isMenuVisible: false,
  navigation: null,
}

export default AppState
