import React, { PropsWithChildren } from 'react'

import { formatCollectionTimeSlot } from '../../entities/advert/helpers'
import { TimeDetails } from '../../entities/advert/types'
import { compactAndJoin } from '../../helpers'
import { ComponentFactory, ToElements } from '../../types/ui'

interface ElementsProps {
  Root: unknown
}

type Elements = ToElements<ElementsProps>

export type Props = PropsWithChildren<TimeDetails>

const factory: ComponentFactory<Elements, Props> =
  ({ Root }) =>
  ({ children, ...props }) =>
    <Root {...props}>{compactAndJoin(' ')([children, formatCollectionTimeSlot(props)])}</Root>

export default factory
