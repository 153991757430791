import { map } from 'lodash/fp'
import React, { useCallback } from 'react'

import { Attachment as Model, OnShowAttachment } from '../../entities/chat/types'
import { hasItems, isDefined } from '../../helpers'
import { ComponentFactory, Render, ToElements } from '../../types/ui'
import { Props as AttachmentProps } from './Attachment'

interface ElementsProps {
  Attachment: AttachmentProps
  Root: unknown
}

type Elements = ToElements<ElementsProps>

export interface Props {
  isAuthor: boolean
  onShow: OnShowAttachment

  items?: Model[]
}

const factory: ComponentFactory<Elements, Props> = ({ Attachment, Root }) =>
  function Attachments({ isAuthor, items, onShow }) {
    const renderAttachment = useCallback<Render<Model>>(
      item => <Attachment {...item} key={item.id} isAuthor={isAuthor} onShow={onShow} />,
      [isAuthor, onShow],
    )

    return isDefined(items) && hasItems(items) ? <Root>{map(renderAttachment)(items)}</Root> : null
  }

export default factory
