import { flow, join, map, split, take } from 'lodash/fp'

import { Format } from '../../types/core'
import { OpenTimeSlot, TimeSlot, TimeSlotFixed, TimeSlotFixedName } from './types'

export const timeSlotFixedNameFormatter: Record<TimeSlotFixedName, string> = {
  anytime: 'Anytime',
  am: 'AM',
  pm: 'PM',
}

export const formatTimeSlotFixedName = (name: TimeSlotFixedName) => timeSlotFixedNameFormatter[name]
export const formatRangePart = flow(split(':'), take(2), join(':'))

export const formatRange = ({ start, end }: TimeSlot) => flow(map(formatRangePart), join(' - '))([start, end])
export const formatFixed = ({ name }: TimeSlotFixed) => formatTimeSlotFixedName(name)

const openTimeSlotLabel: Record<OpenTimeSlot, string> = {
  anytime: 'Anytime',
  asap: 'ASAP',
}

export const formatOpenTimeSlot: Format<OpenTimeSlot> = openTimeSlot => openTimeSlotLabel[openTimeSlot]
