import { buildLens, lensFactory } from '../../helpers'
import RootState from '../../state'
import { GetLens } from '../../types/core'
import State from './state'

export const timeSlotsRootLens: GetLens<RootState, State> = state => () => state.timeSlots

const lens = lensFactory<State>()

export const timeSlotsDataLens = lens('data')

export const timeSlotsData = buildLens(timeSlotsRootLens, timeSlotsDataLens)

export default timeSlotsRootLens
