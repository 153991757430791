export default {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'lovejunk-98fcd.firebaseapp.com',
  databaseURL: 'https://lovejunk-98fcd.firebaseio.com',
  projectId: 'lovejunk-98fcd',
  storageBucket: 'lovejunk-98fcd.appspot.com',
  messagingSenderId: '116740203402',
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: 'G-3BXNS49N12',
}
