import { find, first, flow } from 'lodash/fp'

import { call, isDefined, isNotNull, lensFactory } from '../../helpers'
import RootState from '../../state'
import { AssetType, FavouriteId, GetLens, Lens, SetLens, Transform, UpdateLens } from '../../types/core'
import { filterAssets } from '../../utils/assets'
import State, { Data, initialData } from './state'
import { NewAdvertAddress, NewAdvertCollectionPoint, NewAdvertDetails, NewAdvertFavouriteCollector } from './types'

const lens = lensFactory<State>()

export const addressIdLens = lens('addressId')
export const favouriteCollectorsLens = lens('favouriteCollectors')
export const fixedTimeSlotLens = lens('fixedTimeSlot')
export const hazardousWasteErrorLens = lens('hazardousWasteError')
export const idLens = lens('id')
export const isConfirmingPublishingLens = lens('isConfirmingPublishing')
export const isFailureModalDismissedLens = lens('isFailureModalDismissed')
export const isInvalidAddressModalVisibleLens = lens('isInvalidAddressModalVisible')
export const isInvalidTimeSlotModalVisibleLens = lens('isInvalidTimeSlotModalVisible')
export const isManualAddressLens = lens('isManualAddress')
export const isPublishingLens = lens('isPublishing')
export const isPublishingCompletedLens = lens('isPublishingCompleted')
export const isReuseOnlyLens = lens('isReuseOnly')
export const isSelectedCollectorsLockedLens = lens('isSelectedCollectorsLocked')
export const isSuccessModalDismissedLens = lens('isSuccessModalDismissed')
export const manualAddressEntryLens = lens('manualAddressEntry')
export const newAdvertReferenceLens = lens('advertReference')
export const partnerDetailsLens = lens('partnerDetails')
export const publishingErrorLens = lens('publishingError')
export const residentialLens = lens('residential')
export const reusableLens = lens('reusable')
export const showPublishIntentFeedbackLens = lens('showPublishIntentFeedback')
export const selectedCollectorsLens = lens('selectedCollectors')
export const selectedDateLens = lens('selectedDate')
export const selectedTimeSlotLens = lens('selectedTimeSlot')
export const setAdvertCreationTimeoutLens = lens('timeout')
export const setAdvertCreationTooManyLens = lens('tooMany')
export const suggestedPriceLens = lens('suggestedPriceRange')
export const summaryLens = lens('summary')
export const timeSlotLens = lens('timeSlot')
export const titleLens = lens('title')

export const createSelectedCollectorLens =
  <S>(
    favouriteCollectorsLens: Lens<S, NewAdvertFavouriteCollector[]>,
    isSelectedCollectorsLockedLens: Lens<S, boolean>,
    selectedCollectorsLens: Lens<S, FavouriteId[]>,
  ) =>
  (state: S) => {
    const favouriteCollectors = favouriteCollectorsLens(state).get()
    const isSelectedCollectorsLocked = isSelectedCollectorsLockedLens(state).get()
    const selectedCollectors = selectedCollectorsLens(state).get()
    const id = first(selectedCollectors)

    return isSelectedCollectorsLocked && isDefined(id)
      ? find<NewAdvertFavouriteCollector>({ id })(favouriteCollectors)
      : undefined
  }

export const selectedCollectorLens = createSelectedCollectorLens<State>(
  favouriteCollectorsLens,
  isSelectedCollectorsLockedLens,
  selectedCollectorsLens,
)

export const isEditLens: GetLens<State, boolean> = flow(idLens, getter => flow(getter.get, isNotNull))

export const editAdvertLens: SetLens<State, Data> = state => data => ({ ...state, ...data })

export const assetsLens = lens('assets')

const getAssetTypeLens: Transform<AssetType, GetLens<State, State['assets']>> = assetType => state => () =>
  flow(assetsLens, l => l.get, call, filterAssets(assetType))(state)

export const getImagesLens = getAssetTypeLens('image')
export const getVideosLens = getAssetTypeLens('video')

export const clearDataLens: UpdateLens<State> = state => ({ ...state, ...initialData })

export const addressLens: Lens<State, NewAdvertAddress> = state => ({
  get: () => {
    const { address1, address2, address3, city, postcode } = state

    return { address1, address2, address3, city, postcode }
  },
  set: address => ({ ...state, ...address }),
})

export const collectionPointLens: Lens<State, NewAdvertCollectionPoint> = state => ({
  get: () => {
    const { easyAccess, easyAccessReason } = state

    return {
      easyAccess,
      easyAccessReason,
    }
  },
  set: collectionPoint => ({
    ...state,
    ...collectionPoint,
  }),
})

export const detailsLens: Lens<State, NewAdvertDetails> = state => ({
  get: () => {
    const { builderWaste, isLightweight, residential, size } = state

    return {
      builderWaste,
      isLightweight,
      residential,
      size,
    }
  },
  set: details => ({ ...state, ...details }),
})

export const newAdvertDataLens: GetLens<State, Data> =
  ({
    address1,
    address2,
    address3,
    addressId,
    advertReference,
    assets,
    builderWaste,
    city,
    easyAccess,
    easyAccessReason,
    fixedTimeSlot,
    id,
    isLightweight,
    isReuseOnly,
    manualAddressEntry,
    postcode,
    residential,
    reusable,
    selectedCollectors,
    selectedDate,
    selectedTimeSlot,
    size,
    suggestedPriceRange,
    summary,
    timeSlot,
    title,
  }) =>
  () => ({
    address1,
    address2,
    address3,
    addressId,
    advertReference,
    assets,
    builderWaste,
    city,
    easyAccess,
    easyAccessReason,
    fixedTimeSlot,
    id,
    isLightweight,
    isReuseOnly,
    manualAddressEntry,
    postcode,
    residential,
    reusable,
    selectedCollectors,
    selectedDate,
    selectedTimeSlot,
    size,
    suggestedPriceRange,
    summary,
    timeSlot,
    title,
  })

export const newAdvertRootLens: GetLens<RootState, State> = state => () => state.newAdvert
