import { action, failureAction, payloadAction } from '../../action-factory'
import apiAction, { ActionBundle } from '../../api-action'
import { NoMetaError, NotCustomerError } from '../../errors'
import { actionStep } from '../../helpers'
import { FailureActionFactory, PayloadActionFactory } from '../../types/core'
import { StatelessPayloadThunk } from '../../types/thunk'
import { fetchMeta, fetchMetaStep } from '../meta/actions'
import { isCustomerMetaPresent } from '../meta/helpers'
import {
  getTippingSite as getTippingSiteApi,
  getTippingSites as getTippingSitesApi,
  postTippingSiteQrCode as postTippingQrCodeUrlApi,
  updateAccountEmail as updateAccountEmailApi,
  updatePreferredPaymentMethod as updatePreferredPaymentMethodApi,
} from './api'
import {
  GetTippingSiteResponse,
  GetTippingSitesResponse,
  PostTippingQrCodeUrlResponse,
  UpdateAccountEmailPayload,
  UpdateEmailPayload,
  UpdatePreferredPaymentMethodPayload,
} from './types'
import {
  GET_TIPPING_SITE_FAILURE,
  GET_TIPPING_SITE_START,
  GET_TIPPING_SITE_SUCCESS,
  GET_TIPPING_SITES_FAILURE,
  GET_TIPPING_SITES_START,
  GET_TIPPING_SITES_SUCCESS,
  GetTippingSite,
  GetTippingSiteFailure,
  GetTippingSites,
  GetTippingSitesFailure,
  GetTippingSitesStart,
  GetTippingSitesSuccess,
  GetTippingSiteStart,
  GetTippingSiteSuccess,
  POST_TIPPING_QR_CODE_URL_FAILURE,
  POST_TIPPING_QR_CODE_URL_START,
  POST_TIPPING_QR_CODE_URL_SUCCESS,
  PostTippingQrCodeUrl,
  PostTippingQrCodeUrlFailure,
  PostTippingQrCodeUrlStart,
  PostTippingQrCodeUrlSuccess,
  UPDATE_ACCOUNT_EMAIL_FAILURE,
  UPDATE_ACCOUNT_EMAIL_START,
  UPDATE_ACCOUNT_EMAIL_SUCCESS,
  UPDATE_PREFERRED_PAYMENT_METHOD_FAILURE,
  UPDATE_PREFERRED_PAYMENT_METHOD_START,
  UPDATE_PREFERRED_PAYMENT_METHOD_SUCCESS,
  UpdateAccountEmailFailure,
  UpdateAccountEmailResult,
  UpdateAccountEmailStart,
  UpdateAccountEmailSuccess,
  UpdatePreferredPaymentMethodFailure,
  UpdatePreferredPaymentMethodResult,
  UpdatePreferredPaymentMethodStart,
  UpdatePreferredPaymentMethodSuccess,
} from './types/actions'

const updateAccountEmailFailure: FailureActionFactory<UpdateAccountEmailFailure> =
  failureAction(UPDATE_ACCOUNT_EMAIL_FAILURE)
const updateAccountEmailActionBundle: ActionBundle<
  UpdateAccountEmailStart,
  UpdateAccountEmailSuccess,
  UpdateAccountEmailFailure
> = [action(UPDATE_ACCOUNT_EMAIL_START), action(UPDATE_ACCOUNT_EMAIL_SUCCESS), updateAccountEmailFailure]

export const updateEmail: StatelessPayloadThunk<UpdateEmailPayload, UpdateAccountEmailResult> =
  ({ email, url }) =>
  dispatch =>
    dispatch(apiAction(updateAccountEmailActionBundle)(updateAccountEmailApi(url, email)))
      .then(actionStep(fetchMeta)(UPDATE_ACCOUNT_EMAIL_SUCCESS))
      .then(action => dispatch(action))

export const updateAccountEmail: StatelessPayloadThunk<UpdateAccountEmailPayload, UpdateAccountEmailResult> =
  ({ email, meta }) =>
  dispatch =>
    meta
      ? dispatch(updateEmail({ email, url: meta.updateUserUrl }))
      : Promise.resolve(dispatch(updateAccountEmailFailure(new NoMetaError())))

const updatePreferredPaymentMethodFailure: FailureActionFactory<UpdatePreferredPaymentMethodFailure> = failureAction(
  UPDATE_PREFERRED_PAYMENT_METHOD_FAILURE,
)

const updatePreferredPaymentMethodActionBundle: ActionBundle<
  UpdatePreferredPaymentMethodStart,
  UpdatePreferredPaymentMethodSuccess,
  UpdatePreferredPaymentMethodFailure
> = [
  action(UPDATE_PREFERRED_PAYMENT_METHOD_START),
  action(UPDATE_PREFERRED_PAYMENT_METHOD_SUCCESS),
  updatePreferredPaymentMethodFailure,
]

export const updatePreferredPaymentMethod: StatelessPayloadThunk<
  UpdatePreferredPaymentMethodPayload,
  UpdatePreferredPaymentMethodResult
> =
  ({ meta, paymentMethod }) =>
  dispatch => {
    if (!isCustomerMetaPresent(meta))
      return Promise.resolve(dispatch(updatePreferredPaymentMethodFailure(new NotCustomerError())))

    const { preferredPaymentMethodUrl } = meta.customerInfo

    return dispatch(
      apiAction(updatePreferredPaymentMethodActionBundle)(
        updatePreferredPaymentMethodApi(preferredPaymentMethodUrl, paymentMethod),
      ),
    )
      .then(fetchMetaStep(UPDATE_PREFERRED_PAYMENT_METHOD_SUCCESS))
      .then(action => dispatch(action))
  }
const getTippingSitesSuccess: PayloadActionFactory<GetTippingSitesSuccess, GetTippingSitesResponse> =
  payloadAction(GET_TIPPING_SITES_SUCCESS)
const getTippingSitesFailure: FailureActionFactory<GetTippingSitesFailure> = failureAction(GET_TIPPING_SITES_FAILURE)
const getTippingSitesActionBundle: ActionBundle<
  GetTippingSitesStart,
  GetTippingSitesSuccess,
  GetTippingSitesFailure,
  GetTippingSitesResponse
> = [action(GET_TIPPING_SITES_START), getTippingSitesSuccess, getTippingSitesFailure]

export const getTippingSites: StatelessPayloadThunk<string, GetTippingSites> = url => dispatch =>
  dispatch(apiAction(getTippingSitesActionBundle)(getTippingSitesApi(url)))

const getTippingSiteSuccess: PayloadActionFactory<GetTippingSiteSuccess, GetTippingSiteResponse> =
  payloadAction(GET_TIPPING_SITE_SUCCESS)
const getTippingSiteFailure: FailureActionFactory<GetTippingSiteFailure> = failureAction(GET_TIPPING_SITE_FAILURE)
const getTippingSiteActionBundle: ActionBundle<
  GetTippingSiteStart,
  GetTippingSiteSuccess,
  GetTippingSiteFailure,
  GetTippingSiteResponse
> = [action(GET_TIPPING_SITE_START), getTippingSiteSuccess, getTippingSiteFailure]

export const getTippingSite: StatelessPayloadThunk<string, GetTippingSite> = url => dispatch =>
  dispatch(apiAction(getTippingSiteActionBundle)(getTippingSiteApi(url)))

const postTippingQrCodeUrlSuccess: PayloadActionFactory<PostTippingQrCodeUrlSuccess, PostTippingQrCodeUrlResponse> =
  payloadAction(POST_TIPPING_QR_CODE_URL_SUCCESS)
const postTippingQrCodeUrlFailure: FailureActionFactory<PostTippingQrCodeUrlFailure> = failureAction(
  POST_TIPPING_QR_CODE_URL_FAILURE,
)
const postTippingQrCodeUrlActionBundle: ActionBundle<
  PostTippingQrCodeUrlStart,
  PostTippingQrCodeUrlSuccess,
  PostTippingQrCodeUrlFailure,
  PostTippingQrCodeUrlResponse
> = [action(POST_TIPPING_QR_CODE_URL_START), postTippingQrCodeUrlSuccess, postTippingQrCodeUrlFailure]

export const postTippingQrCodeUrl: StatelessPayloadThunk<string, PostTippingQrCodeUrl> = url => dispatch =>
  dispatch(apiAction(postTippingQrCodeUrlActionBundle)(postTippingQrCodeUrlApi(url)))
