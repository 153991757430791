import { css, styled } from 'styled'
import { device } from 'utils/css'

export const panelCss = css`
  background-color: ${({ theme: { colors } }) => colors.white};
  border-color: ${({ theme: { colors } }) => colors.light};
  border-radius: 0.5em;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.12);
`

const Panel = styled.div`
  ${panelCss};

  @media ${device.mobile} {
    box-shadow: none;
    border-radius: 0;
    border-width: 0;
  }
`

export default Panel
