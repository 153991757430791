import { LOVEJUNK_BOOKINGS_URL, LOVEJUNK_STAGING_BOOKINGS_URL, NoOp } from '@lovejunk/core'
import NavigationPath from 'navigation/paths'
import React, { FC, PropsWithChildren } from 'react'
import { css, styled } from 'styled'
import { isStagingEnv } from 'utils/environment'

import Dot from '../Dot'
import StyledLink from './StyledLink'

interface Props {
  $isModalMode: boolean
  dot?: boolean
  onPress?: NoOp
  hostAware?: boolean
  to: NavigationPath
}

const SessionLink: FC<PropsWithChildren<Props>> = ({ children, dot, hostAware, to, ...rootProps }) => {
  const isBookingHost = window.location.hostname.includes('bookings')
  const isLocalHost = window.location.hostname.includes('localhost')

  if (isLocalHost || !hostAware || isBookingHost) {
    return (
      <NavLink to={to} {...rootProps}>
        {children}
        {dot && <StyledDot />}
      </NavLink>
    )
  }

  const href = isStagingEnv ? `${LOVEJUNK_STAGING_BOOKINGS_URL}${to}` : `${LOVEJUNK_BOOKINGS_URL}${to}`

  return (
    <AnchorLink $isModalMode={rootProps.$isModalMode} href={href} onClick={rootProps.onPress}>
      {children}
      {dot && <StyledDot />}
    </AnchorLink>
  )
}

interface RootProps {
  $isModalMode: boolean
}

const navCss = css<RootProps>`
  display: flex;
  gap: 0.125em;
  color: ${({ theme: { colors } }) => colors.regularText};
  cursor: pointer;
  font-size: ${({ $isModalMode }) => ($isModalMode ? '1.5em' : 'inherit')};
  text-align: ${({ $isModalMode }) => ($isModalMode ? 'left' : 'center')};

  :hover {
    color: ${({ theme: { colors } }) => colors.primary} !important;
  }
`

export const NavLink = styled(StyledLink)<RootProps>`
  ${navCss}
`

export const AnchorLink = styled.a<RootProps>`
  ${navCss}

  text-decoration: none;
`

const StyledDot = styled(Dot)`
  margin-top: -0.125em;
`

export default SessionLink
