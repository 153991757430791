import React, { FC } from 'react'
import { styled } from 'styled'

import UploadInput, { Props as UploadInputProps } from '../input/UploadInput'
import TextBase from '../text/Text'
import { Shape } from './MainButton'

interface Props extends UploadInputProps {
  text: string
}

const UploadButton: FC<Props> = ({ text, ...uploadInputProps }) => (
  <UploadInput {...uploadInputProps}>
    <Shape>
      <Text>{text}</Text>
    </Shape>
  </UploadInput>
)

const Text = styled(TextBase).attrs({ centered: true })`
  user-select: none;
`

export default UploadButton
