import { action, failureAction, payloadAction } from '../../action-factory'
import apiAction, { ActionBundle } from '../../api-action'
import { ActionFactory, FailureActionFactory, Nullable, PayloadActionFactory, TagId } from '../../types/core'
import { Thunk } from '../../types/thunk'
import { withEndpoint } from '../app/helpers'
import { getCapabilities } from '../meta/lens'
import { fetchAdvertsApi, fetchNextAdvertsApi } from './api'
import { roleBasedFilter } from './helpers'
import { advertsRootLens, filterLens, getNextPageUrlLens } from './lens'
import { marshalFilter } from './marshaller'
import {
  AdvertItemResource,
  CHECK_GALLERY_VERSION_FAILURE,
  CHECK_GALLERY_VERSION_START,
  CHECK_GALLERY_VERSION_SUCCESS,
  CheckGalleryVersionFailure,
  CheckGalleryVersionStart,
  CheckGalleryVersionSuccess,
  CLEAR_ADVERTS,
  ClearAdverts,
  FETCH_ADVERT_ITEM_FAILURE,
  FETCH_ADVERT_ITEM_START,
  FETCH_ADVERT_ITEM_SUCCESS,
  FETCH_ADVERTS_FAILURE,
  FETCH_ADVERTS_NEXT_PAGE_CHECK_FAILURE,
  FETCH_ADVERTS_NEXT_PAGE_FAILURE,
  FETCH_ADVERTS_NEXT_PAGE_START,
  FETCH_ADVERTS_NEXT_PAGE_SUCCESS,
  FETCH_ADVERTS_START,
  FETCH_ADVERTS_SUCCESS,
  FetchAdvertItemFailure,
  FetchAdvertItemStart,
  FetchAdvertItemSuccess,
  FetchAdverts,
  FetchAdvertsFailure,
  FetchAdvertsNextPage,
  FetchAdvertsNextPageCheckFailure,
  FetchAdvertsNextPageFailure,
  FetchAdvertsNextPageStart,
  FetchAdvertsNextPageSuccess,
  FetchAdvertsResponse,
  FetchAdvertsStart,
  FetchAdvertsSuccess,
  GalleryVersion,
  SET_ADVERTS_CURRENT_PAGE,
  SET_ADVERTS_PAGE_SIZE,
  SET_BUILDER_WASTE_FILTER,
  SET_COLLECTED_FILTER,
  SET_MATCHED_FILTER,
  SET_NEEDS_FETCH,
  SET_RESIDENTIAL_FILTER,
  SET_REUSABLE_FILTER,
  SET_TAG_FILTER,
  SetAdvertsCurrentPage,
  SetAdvertsPageSize,
  SetBuilderWasteFilter,
  SetCollectedFilter,
  SetMatchedFilter,
  SetNeedsFetch,
  SetResidentialFilter,
  SetReusableFilter,
  SetTagFilter,
} from './types'

export const checkGalleryVersionFailure: FailureActionFactory<CheckGalleryVersionFailure> =
  failureAction(CHECK_GALLERY_VERSION_FAILURE)

export const checkGalleryVersionActionBundle: ActionBundle<
  CheckGalleryVersionStart,
  CheckGalleryVersionSuccess,
  CheckGalleryVersionFailure,
  GalleryVersion
> = [action(CHECK_GALLERY_VERSION_START), payloadAction(CHECK_GALLERY_VERSION_SUCCESS), checkGalleryVersionFailure]

export const fetchAdvertItemFailure: FailureActionFactory<FetchAdvertItemFailure> =
  failureAction(FETCH_ADVERT_ITEM_FAILURE)

export const fetchAdvertItemActionBundle: ActionBundle<
  FetchAdvertItemStart,
  FetchAdvertItemSuccess,
  FetchAdvertItemFailure,
  AdvertItemResource
> = [action(FETCH_ADVERT_ITEM_START), payloadAction(FETCH_ADVERT_ITEM_SUCCESS), fetchAdvertItemFailure]

export const fetchAdvertsStart: ActionFactory<FetchAdvertsStart> = action(FETCH_ADVERTS_START)

export const fetchAdvertsSuccess: PayloadActionFactory<FetchAdvertsSuccess, FetchAdvertsResponse> =
  payloadAction(FETCH_ADVERTS_SUCCESS)
export const fetchAdvertsFailure: FailureActionFactory<FetchAdvertsFailure> = failureAction(FETCH_ADVERTS_FAILURE)
export const fetchAdvertsActionBundle: ActionBundle<
  FetchAdvertsStart,
  FetchAdvertsSuccess,
  FetchAdvertsFailure,
  FetchAdvertsResponse
> = [fetchAdvertsStart, fetchAdvertsSuccess, fetchAdvertsFailure]

export const fetchAdvertsNextPageFailure: FailureActionFactory<FetchAdvertsNextPageFailure> = failureAction(
  FETCH_ADVERTS_NEXT_PAGE_FAILURE,
)

export const fetchAdvertsNextPageActionBundle: ActionBundle<
  FetchAdvertsNextPageStart,
  FetchAdvertsNextPageSuccess,
  FetchAdvertsNextPageFailure,
  FetchAdvertsResponse
> = [action(FETCH_ADVERTS_NEXT_PAGE_START), payloadAction(FETCH_ADVERTS_NEXT_PAGE_SUCCESS), fetchAdvertsNextPageFailure]

export const fetchAdvertsNextPageCheckFailure: FailureActionFactory<FetchAdvertsNextPageCheckFailure> = failureAction(
  FETCH_ADVERTS_NEXT_PAGE_CHECK_FAILURE,
)

export const setAdvertsCurrentPage: PayloadActionFactory<SetAdvertsCurrentPage, number> =
  payloadAction(SET_ADVERTS_CURRENT_PAGE)

export const setAdvertsPageSize: PayloadActionFactory<SetAdvertsPageSize, number> = payloadAction(SET_ADVERTS_PAGE_SIZE)

export const setBuilderWasteFilter: PayloadActionFactory<SetBuilderWasteFilter, Nullable<boolean>> = payloadAction(
  SET_BUILDER_WASTE_FILTER,
)

export const setCollectedFilter: PayloadActionFactory<SetCollectedFilter, Nullable<boolean>> = payloadAction(
  SET_COLLECTED_FILTER,
)

export const setMatchedFilter: PayloadActionFactory<SetMatchedFilter, Nullable<boolean>> = payloadAction(
  SET_MATCHED_FILTER,
)

export const setTagFilter: PayloadActionFactory<SetTagFilter, Nullable<TagId>> = payloadAction(SET_TAG_FILTER)

export const setResidentialFilter: PayloadActionFactory<SetResidentialFilter, Nullable<boolean>> = payloadAction(
  SET_RESIDENTIAL_FILTER,
)

export const setReusableFilter: PayloadActionFactory<SetReusableFilter, Nullable<boolean>> = payloadAction(
  SET_REUSABLE_FILTER,
)

export const clearAdverts: ActionFactory<ClearAdverts> = action(CLEAR_ADVERTS)
export const setNeedsFetch: PayloadActionFactory<SetNeedsFetch, boolean> = payloadAction(SET_NEEDS_FETCH)

export const fetchAdverts: Thunk<FetchAdverts> = () =>
  withEndpoint(
    'adverts',
    advertsUrl => (dispatch, getState) => {
      const rootState = getState()
      const filter = filterLens(advertsRootLens(rootState)()).get()

      return dispatch(
        apiAction(fetchAdvertsActionBundle)(
          fetchAdvertsApi(advertsUrl, marshalFilter(roleBasedFilter(getCapabilities(rootState))(filter))),
        ),
      )
    },
    fetchAdvertsFailure,
  )

export const fetchNextPage: Thunk<FetchAdvertsNextPage> = () => (dispatch, getState) => {
  const rootState = getState()
  const url = getNextPageUrlLens(advertsRootLens(rootState)())()

  return url
    ? dispatch(apiAction(fetchAdvertsNextPageActionBundle)(fetchNextAdvertsApi(url)))
    : Promise.resolve(dispatch(fetchAdvertsNextPageFailure(new Error('no meta - there is no next page to fetch'))))
}
