import { addDays, isBefore, set } from 'date-fns/fp'
import { flow, over, split } from 'lodash/fp'

import {
  DateTimeSlotPayload,
  NewAdvertResourceFixedTimeSlot,
  ValidNewAdvertFixedTimeSlot,
} from '../entities/new-advert/types'
import { TimeSlotResource } from '../entities/time-slots/types'
import { Marshal } from '../types'
import { timeSlotFormatter, yearMonthDayFormatter } from '../utils/date'
import { mapParseDecimalInt } from '../utils/number'

const splitDateTime = split(':')

const createSpanDate = flow(splitDateTime, mapParseDecimalInt, ([hours, minutes, seconds]) =>
  set({ hours, minutes, seconds }),
)

export const marshallTimeSlot: Marshal<DateTimeSlotPayload, TimeSlotResource> = ({ date, timeSlot }) => {
  const [start, end] = over<Date>([createSpanDate(timeSlot.start), createSpanDate(timeSlot.end)])(date)

  return {
    start: timeSlotFormatter(start),
    end: timeSlotFormatter(addDays(Number(isBefore(start)(end)))(end)),
  }
}

export const marshallFixedTimeSlot: Marshal<ValidNewAdvertFixedTimeSlot, NewAdvertResourceFixedTimeSlot> = ({
  date,
  timeSlot,
}) => ({ date: yearMonthDayFormatter(date), timeSlot })
