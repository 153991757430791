import { DotElementsProps as ElementsProps, dotFactory as factory } from '@lovejunk/core'
import { styled } from 'styled'

export default factory({
  Root: styled.div<ElementsProps['Root']>`
    background-color: ${({ color, theme: { colors } }) => colors[color]};
    border-radius: ${({ size }) => size / 2}em;
    width: ${({ size }) => size}em;
    height: ${({ size }) => size}em;
  `,
})
