import { flow } from 'lodash/fp'

import Reducer from '../../types/reducer'
import { RELOAD_ACTIVITY_HAS_UNREAD_MESSAGES_SUCCESS } from '../chat/types'
import {
  currentSupplierActivityLens as currentLens,
  historySupplierActivityLens as historyLens,
  offersLens,
} from '../supplier-adverts/lens'
import State, { defaultState } from '../supplier-adverts/state'
import {
  FETCH_JUNK_LOVER_ADVERTS_NEXT_PAGE_SUCCESS,
  FETCH_JUNK_LOVER_ADVERTS_SUCCESS,
  FETCH_JUNK_LOVER_HISTORY_ADVERTS_NEXT_PAGE_SUCCESS,
  FETCH_JUNK_LOVER_HISTORY_ADVERTS_SUCCESS,
  FETCH_JUNK_REUSER_OFFERS_SUCCESS,
} from './types'

const reducer: Reducer<State> = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_JUNK_LOVER_ADVERTS_SUCCESS:
      return flow(
        currentLens.itemsLens,
        l => l.set(action.payload.body.entities),
        currentLens.metaLens,
        l => l.set(action.payload.meta),
        currentLens.loadingLens,
        l => l.set(false),
      )(state)
    case FETCH_JUNK_LOVER_ADVERTS_NEXT_PAGE_SUCCESS:
      return flow(
        currentLens.mergeItemsLens,
        l => l(action.payload.body.entities),
        currentLens.metaLens,
        l => l.set(action.payload.meta),
        currentLens.loadingNextLens,
        l => l.set(false),
      )(state)
    case FETCH_JUNK_LOVER_HISTORY_ADVERTS_SUCCESS:
      return flow(
        historyLens.itemsLens,
        l => l.set(action.payload.body.entities),
        historyLens.metaLens,
        l => l.set(action.payload.meta),
        historyLens.loadingLens,
        l => l.set(false),
      )(state)
    case FETCH_JUNK_LOVER_HISTORY_ADVERTS_NEXT_PAGE_SUCCESS:
      return flow(
        historyLens.mergeItemsLens,
        l => l(action.payload.body.entities),
        historyLens.metaLens,
        l => l.set(action.payload.meta),
        historyLens.loadingNextLens,
        l => l.set(false),
      )(state)
    case FETCH_JUNK_REUSER_OFFERS_SUCCESS:
      return flow(
        offersLens.itemsLens,
        l => l.set(action.payload.body.entities),
        offersLens.metaLens,
        l => l.set(action.payload.meta),
        offersLens.loadingLens,
        l => l.set(false),
      )(state)
    case RELOAD_ACTIVITY_HAS_UNREAD_MESSAGES_SUCCESS:
      return currentLens.hasUnreadMessagesLens(state).set(action.payload)
    default:
      return state
  }
}

export default reducer
