import { flow, identity, includes } from 'lodash/fp'
import { Action } from 'redux'

import { request, toBody } from '../api'
import { CHECK_TOKEN_SUCCESS, GET_TOKEN_SUCCESS, OAuthResult, OAuthResultSuccess } from '../entities/oauth/types'
import { isOAuthTokenError } from '../errors'
import { then } from '../helpers'
import { ActionFactory, FailureActionFactory, PayloadActionFactory, Transform } from '../types/core'
import { GenericThunkAction, GenericThunkDispatch } from '../types/thunk'
import getConfig from './config'

export const isOAuthResultSuccess = (action: OAuthResult): action is OAuthResultSuccess =>
  includes((action as OAuthResultSuccess).type)([CHECK_TOKEN_SUCCESS, GET_TOKEN_SUCCESS])

const onError =
  <A extends Action, S>(dispatch: GenericThunkDispatch<S, A>) =>
  async <R>(error: Error) => {
    if (isOAuthTokenError(error)) {
      const oauthResult = await dispatch(getConfig().checkOrGetToken())

      if (isOAuthResultSuccess(oauthResult)) {
        return request<R>({ ...error.opts, retry: false }).then(toBody)
      }
    }

    throw error
  }

export type SimpleApi<R = void> = Promise<R>
export type Resolve<R, D> = Transform<R, D>
export type CompoundApi<R = void, D = R> = [Promise<R>, Resolve<R, D>]

type Api<R, D = R> = SimpleApi<R> | CompoundApi<R, D>

export type ActionBundle<ST, SU, FA, P = void> = [
  ActionFactory<ST>,
  PayloadActionFactory<SU, P>,
  FailureActionFactory<FA>,
]

const isSimpleApi = <R, D>(api: Api<R, D>): api is SimpleApi<R> => api instanceof Promise

export const toPromiseResolve = <R, D>(api: Api<R, D>): CompoundApi<R, D> =>
  isSimpleApi(api) ? [api, identity as Transform<R, D>] : api

export default <A extends Action, S, ST extends A, SU extends A, FA extends A, P>(
    [start, success, failure]: ActionBundle<ST, SU, FA, P>,
    external = false,
  ) =>
  <R>(api: Api<R, P>): GenericThunkAction<SU | FA, S, A> =>
  dispatch => {
    const [promise, resolve] = toPromiseResolve(api)

    dispatch(start())

    return promise
      .then(resolve)
      .catch(error => {
        if (external) {
          throw error
        } else {
          return flow(onError(dispatch), then(resolve))(error)
        }
      })
      .then(success)
      .catch(failure)
      .then<SU | FA>(dispatch)
  }
