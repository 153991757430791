import { Color, FontWeight } from '@lovejunk/core'
import React, { FC, PropsWithChildren } from 'react'
import { styled, ThemeProps } from 'styled'
import { withTheme } from 'styled-components'
import { isDesktop } from 'utils/environment'

import { useHover } from '../effects'
import Text from '../text/Text'
import GenericButton, { ExternalProps } from './GenericButton'

interface OwnProps {
  bold?: boolean
  centered?: boolean
  color?: Color
  hoverColor?: Color
  size?: number
  underlined?: boolean
  weight?: FontWeight
}

export type Props = ExternalProps<OwnProps>

const TextButton: FC<PropsWithChildren<Props & ThemeProps>> = ({
  centered = true,
  children,
  bold,
  color = 'primary',
  hoverColor,
  size,
  style,
  theme: { colors },
  underlined = false,
  weight,
  ...props
}) => {
  const { isHover, ref } = useHover()
  const isHoverActive = isDesktop && hoverColor && isHover

  return (
    <GenericButton {...props} ref={ref} style={{ ...style, ...(centered ? undefined : { textAlign: 'left' }) }}>
      {({ disabled, style }) => {
        const shapeStyle = {
          ...style,
          color: isHoverActive && !disabled ? colors.white : style?.color,
          backgroundColor: isHoverActive && !disabled ? colors[hoverColor] : style?.backgroundColor,
        }

        return (
          <Shape style={shapeStyle}>
            <Text
              block
              bold={bold}
              color={isHoverActive && !disabled ? 'light' : color}
              size={size}
              underlined={underlined}
              weight={weight}
            >
              {children}
            </Text>
          </Shape>
        )
      }}
    </GenericButton>
  )
}

const Shape = styled.div`
  padding: 0.625em;
`

export default withTheme(TextButton)
