import React from 'react'

import { Factory } from './factory'

export interface StateProps {
  isApiUrlFetched: boolean
  isRehydrated: boolean
}

const factory: Factory<StateProps> = ({ Check }) =>
  function StoreCheck({ children, isApiUrlFetched, isRehydrated }) {
    return <Check value={isApiUrlFetched && isRehydrated}>{children}</Check>
  }

export default factory
