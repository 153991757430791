import { GetLens, lensFactory } from '@lovejunk/core'
import RootState from 'core/state'

import State from './state'

const lens = lensFactory<State>()

export const accountDeletedLens = lens('accountDeleted')
export const advertsVisitedLens = lens('advertsVisited')
export const isMenuVisibleLens = lens('isMenuVisible')
export const navigationLens = lens('navigation')

export const rootLens: GetLens<RootState, State> = state => () => state.app

export default rootLens
