import { Color, NoOp, SearchParams } from '@lovejunk/core'
import NavigationPath from 'navigation/paths'
import React, { PropsWithChildren } from 'react'
import { generatePath, Link as NativeLink, LinkProps as NativeLinkProps } from 'react-router-dom'
import { styled } from 'styled'
import { PathParams } from 'types'
import { searchParamsToString } from 'utils'

interface TransientProps {
  color?: Color
  size?: number
  $underline?: boolean
}

interface OwnProps<T extends NavigationPath> extends NativeLinkProps {
  to: T

  onPress?: NoOp
  params?: PathParams<T>
  search?: SearchParams
}

type Props<T extends NavigationPath> = OwnProps<T> & TransientProps

const Link = <T extends NavigationPath>({ onPress, params, search, to, ...props }: PropsWithChildren<Props<T>>) => (
  <Root
    {...props}
    onClick={onPress}
    to={{ pathname: generatePath(to, params), search: searchParamsToString(search) }}
  />
)

const Root = styled(NativeLink)<TransientProps>`
  color: ${({ color, theme: { colors } }) => colors[color || 'primary']};
  ${({ size }) => (size ? `font-size: ${size}em;` : undefined)}
  // Forcing 'none' since user agent applies 'underline' as default for "a" elements.
  text-decoration: ${({ $underline = false }) => ($underline ? 'underline' : 'none')};
`

export default Link
