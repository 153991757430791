import { HazardousWasteError } from '../../errors'
import { Asset, FavouriteId, Get, Nullable, PriceRange } from '../../types/core'
import { AdvertId, AdvertReference, LoadSize as Size } from '../advert/types'
import { TimeSlot, TimeSlots } from '../time-slots/types'
import {
  CollectionDate,
  NewAdvertAddress,
  NewAdvertCollectionPoint,
  NewAdvertDetails,
  NewAdvertFavouriteCollector,
  NewAdvertFixedTimeSlot,
  NullifiedTimeSlotPayload,
  PartnerDetails,
} from './types'

export interface Data {
  address1: string
  address2: string
  address3: string
  addressId: Nullable<string>
  // injected from response
  advertReference: Nullable<AdvertReference>
  assets: Asset[]
  builderWaste: Nullable<boolean>
  city: string
  easyAccess: Nullable<boolean>
  easyAccessReason: string
  fixedTimeSlot: Nullable<NewAdvertFixedTimeSlot>
  id: Nullable<AdvertId>
  isReuseOnly: Nullable<boolean>
  manualAddressEntry: boolean
  postcode: string
  residential: Nullable<boolean>
  reusable: Nullable<boolean>
  selectedCollectors: FavouriteId[]
  selectedDate: Nullable<CollectionDate>
  selectedTimeSlot: Nullable<TimeSlot>
  size: Nullable<Size>
  suggestedPriceRange: Nullable<PriceRange>
  summary: string
  timeSlot: Nullable<NullifiedTimeSlotPayload>
  title: string

  isLightweight?: Nullable<boolean>
}

export interface BaseState {
  favouriteCollectors: NewAdvertFavouriteCollector[]
  hazardousWasteError: Nullable<HazardousWasteError>
  isConfirmingPublishing: boolean
  isInvalidAddressModalVisible: boolean
  isInvalidTimeSlotModalVisible: boolean
  isManualAddress: Nullable<boolean>
  isPublishing: boolean
  isPublishingCompleted: boolean
  isSelectedCollectorsLocked: boolean
  partnerDetails: Nullable<PartnerDetails>
  publishingError: Nullable<string>
  showPublishIntentFeedback: boolean
  timeout: boolean
  tooMany: boolean
}

export interface State extends BaseState, Data {
  isFailureModalDismissed: boolean
  isSuccessModalDismissed: boolean
  timeSlots: Nullable<TimeSlots>
}

export const initialAddress: NewAdvertAddress = {
  address1: '',
  address2: '',
  address3: '',
  city: '',
  postcode: '',
}

export const initialCollectionPoint: NewAdvertCollectionPoint = {
  easyAccess: null,
  easyAccessReason: '',
}

export const initialDetails: NewAdvertDetails = {
  isLightweight: null,
  residential: null,
  builderWaste: null,
  size: null,
}

export const initialDataBase = {
  assets: [],
  fixedTimeSlot: null,
  id: null,
  isReuseOnly: null,
  manualAddressEntry: false,
  reusable: null,
  selectedCollectors: [],
  suggestedPriceRange: null,
  summary: '',
  timeSlot: null,
  title: '',
}

export const initialData: Data = {
  ...initialAddress,
  ...initialCollectionPoint,
  ...initialDetails,
  ...initialDataBase,
  addressId: null,
  advertReference: null,
  selectedDate: null,
  selectedTimeSlot: null,
}

export const initialStateBase: BaseState = {
  favouriteCollectors: [],
  hazardousWasteError: null,
  isConfirmingPublishing: false,
  isInvalidAddressModalVisible: false,
  isInvalidTimeSlotModalVisible: false,
  isManualAddress: null,
  isPublishing: false,
  isPublishingCompleted: false,
  isSelectedCollectorsLocked: false,
  partnerDetails: null,
  publishingError: null,
  showPublishIntentFeedback: false,
  timeout: false,
  tooMany: false,
}

export const defaultState: Get<State> = () => ({
  ...initialData,
  ...initialStateBase,
  isFailureModalDismissed: false,
  isSuccessModalDismissed: false,
  timeSlots: null,
})

export default State
