import { find, flow, includes, isNull, negate, noop, toLower } from 'lodash/fp'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { CUSTOM_API_URL_PREFIX, CUSTOM_API_URL_SUFFIX, CUSTOM_API_URL_VALUE } from '../constants'
import { SetApiUrlFetched } from '../entities/app/types'
import { isPresent } from '../helpers'
import { Dispatch, NoOp, Nullable, Optional, Transform, Validation } from '../types/core'
import { DropdownOption } from '../types/ui'

export const toApiUrlSelectOption = (label: string, value: string): DropdownOption<string> => ({ label, value })

export const createApiUrlOptions: Transform<string, DropdownOption<string>[]> = stagingApiUrl => [
  toApiUrlSelectOption('Staging', stagingApiUrl),
  toApiUrlSelectOption('Custom...', CUSTOM_API_URL_VALUE),
]

export const customApiUrlValidation: Validation = flow(toLower, negate(includes('prod')))

const useApiUrl = (
  apiUrl: string,
  changeApiUrl: Dispatch<string, Promise<SetApiUrlFetched>>,
  stagingApiUrl: string,
  afterApiUrlChange: NoOp = noop,
) => {
  const [apiUrlValue, setApiUrlValue] = useState<string>(stagingApiUrl)
  const [customApiUrlValue, setCustomApiUrlValue] = useState<string>('')
  const [isCustomApiUrl, setCustomApiUrl] = useState<boolean>(false)
  const [isCustomApiUrlValid, setCustomApiUrlValid] = useState<boolean>(true)

  const apiUrlOptions = useMemo(() => createApiUrlOptions(stagingApiUrl), [stagingApiUrl])

  const onValueChange = useCallback<Transform<string, boolean>>(
    value => {
      const isCustomApiUrlValue = value === CUSTOM_API_URL_VALUE

      setApiUrlValue(value)
      setCustomApiUrl(isCustomApiUrlValue)

      return isCustomApiUrlValue
    },
    [setApiUrlValue, setCustomApiUrl],
  )

  const onApiUrlChange = useCallback<Dispatch<Nullable<string>>>(
    value => {
      if (isNull(value) || value === apiUrl) return

      const isCustomApiUrlValue = onValueChange(value)

      if (!isCustomApiUrlValue) changeApiUrl(value).then(afterApiUrlChange)
    },
    [afterApiUrlChange, apiUrl, changeApiUrl, onValueChange],
  )

  const onCustomApiUrlSubmit = useCallback(() => {
    const isValid = isPresent(customApiUrlValue) && customApiUrlValidation(customApiUrlValue)

    setCustomApiUrlValid(isValid)

    if (!isValid) return

    changeApiUrl(CUSTOM_API_URL_PREFIX + customApiUrlValue + CUSTOM_API_URL_SUFFIX).then(afterApiUrlChange)
  }, [afterApiUrlChange, changeApiUrl, customApiUrlValue])

  const findOption = useCallback<Transform<string, Optional<DropdownOption<string>>>>(
    value => find<DropdownOption<string>>({ value })(apiUrlOptions),
    [apiUrlOptions],
  )

  useEffect(() => {
    onValueChange(findOption(apiUrl)?.value || CUSTOM_API_URL_VALUE)

    const re = new RegExp(CUSTOM_API_URL_PREFIX + '(.+)' + CUSTOM_API_URL_SUFFIX)
    const result = re.exec(apiUrl)?.[1]

    if (result) setCustomApiUrlValue(result)
  }, [apiUrl, findOption, onValueChange])

  const apiUrlOption = useMemo(() => findOption(apiUrlValue), [apiUrlValue, findOption])

  return {
    apiUrlOption,
    apiUrlOptions,
    customApiUrlValue,
    isCustomApiUrl,
    isCustomApiUrlValid,
    onApiUrlChange,
    onCustomApiUrlSubmit,
    setCustomApiUrlValue,
  }
}

export default useApiUrl
