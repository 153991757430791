import { Action } from 'redux'

import { FailureAction, PayloadAction } from '../../../types/core'
import { AcceptJobResponseBody, CollectorReceipt, RemovePhotoPayload, SavePhotoPayload } from '.'

export const ACCEPT_JOB_FLOW_START = 'ACCEPT_JOB_FLOW_START'
export const ACCEPT_JOB_FLOW_SUCCESS = 'ACCEPT_JOB_FLOW_SUCCESS'
export const ACCEPT_JOB_FLOW_FAILURE = 'ACCEPT_JOB_FLOW_FAILURE'
export const ACCEPT_JOB_START = 'ACCEPT_JOB_START'
export const ACCEPT_JOB_SUCCESS = 'ACCEPT_JOB_SUCCESS'
export const ACCEPT_JOB_FAILURE = 'ACCEPT_JOB_FAILURE'
export const CLEAR_ACCEPT_JOB_ERROR = 'CLEAR_ACCEPT_JOB_ERROR'
export const SET_ACCEPT_JOB_CONFIRMED = 'SET_ACCEPT_JOB_CONFIRMED'
export const SET_ACCEPT_JOB_INTENT = 'SET_ACCEPT_JOB_INTENT'

export type AcceptJobFlowStart = Action<typeof ACCEPT_JOB_FLOW_START>
export type AcceptJobFlowSuccess = Action<typeof ACCEPT_JOB_FLOW_SUCCESS>
export type AcceptJobFlowFailure = FailureAction<typeof ACCEPT_JOB_FLOW_FAILURE>
export type AcceptJobStart = Action<typeof ACCEPT_JOB_START>
export type AcceptJobSuccess = PayloadAction<typeof ACCEPT_JOB_SUCCESS, AcceptJobResponseBody>
export type AcceptJobFailure = FailureAction<typeof ACCEPT_JOB_FAILURE>
export type SetAcceptJobConfirmed = PayloadAction<typeof SET_ACCEPT_JOB_CONFIRMED, boolean>
export type SetAcceptJobIntent = PayloadAction<typeof SET_ACCEPT_JOB_INTENT, boolean>

export type AcceptJobFlowResult = AcceptJobFlowSuccess | AcceptJobFlowFailure
export type AcceptJobFlow = AcceptJobFlowStart | AcceptJobFlowResult
export type AcceptJobResult = AcceptJobSuccess | AcceptJobFailure
export type AcceptJob = AcceptJobStart | AcceptJobResult

export type ClearAcceptJobError = Action<typeof CLEAR_ACCEPT_JOB_ERROR>

export const GET_COLLECTOR_RECEIPT_START = 'GET_COLLECTOR_RECEIPT_START'
export const GET_COLLECTOR_RECEIPT_SUCCESS = 'GET_COLLECTOR_RECEIPT_SUCCESS'
export const GET_COLLECTOR_RECEIPT_FAILURE = 'GET_COLLECTOR_RECEIPT_FAILURE'

export type GetCollectorReceiptStart = Action<typeof GET_COLLECTOR_RECEIPT_START>
export type GetCollectorReceiptSuccess = PayloadAction<typeof GET_COLLECTOR_RECEIPT_SUCCESS, CollectorReceipt>
export type GetCollectorReceiptFailure = FailureAction<typeof GET_COLLECTOR_RECEIPT_FAILURE>
export type GetCollectorReceiptResult = GetCollectorReceiptSuccess | GetCollectorReceiptFailure
export type GetCollectorReceipt = GetCollectorReceiptStart | GetCollectorReceiptResult

export const MAKE_COUNTER_OFFER_START = 'MAKE_COUNTER_OFFER_START'
export const MAKE_COUNTER_OFFER_SUCCESS = 'MAKE_COUNTER_OFFER_SUCCESS'
export const MAKE_COUNTER_OFFER_FAILURE = 'MAKE_COUNTER_OFFER_FAILURE'

export type MakeCounterOfferStart = Action<typeof MAKE_COUNTER_OFFER_START>
export type MakeCounterOfferSuccess = Action<typeof MAKE_COUNTER_OFFER_SUCCESS>
export type MakeCounterOfferFailure = FailureAction<typeof MAKE_COUNTER_OFFER_FAILURE>
export type MakeCounterOfferResult = MakeCounterOfferSuccess | MakeCounterOfferFailure
export type MakeCounterOffer = MakeCounterOfferStart | MakeCounterOfferResult

export const WITHDRAW_COUNTER_OFFER_START = 'WITHDRAW_COUNTER_OFFER_START'
export const WITHDRAW_COUNTER_OFFER_SUCCESS = 'WITHDRAW_COUNTER_OFFER_SUCCESS'
export const WITHDRAW_COUNTER_OFFER_FAILURE = 'WITHDRAW_COUNTER_OFFER_FAILURE'

export type WithdrawCounterOfferStart = Action<typeof WITHDRAW_COUNTER_OFFER_START>
export type WithdrawCounterOfferSuccess = Action<typeof WITHDRAW_COUNTER_OFFER_SUCCESS>
export type WithdrawCounterOfferFailure = FailureAction<typeof WITHDRAW_COUNTER_OFFER_FAILURE>

export type WithdrawCounterOffer = WithdrawCounterOfferStart | WithdrawCounterOfferSuccess | WithdrawCounterOfferFailure

export const SAVE_AFTER_PHOTO = 'SAVE_AFTER_PHOTO'
export type SaveAfterPhoto = PayloadAction<typeof SAVE_AFTER_PHOTO, SavePhotoPayload>

export const SAVE_BEFORE_PHOTO = 'SAVE_BEFORE_PHOTO'
export type SaveBeforePhoto = PayloadAction<typeof SAVE_BEFORE_PHOTO, SavePhotoPayload>

export const REMOVE_AFTER_PHOTO = 'REMOVE_AFTER_PHOTO'
export type RemoveAfterPhoto = PayloadAction<typeof REMOVE_AFTER_PHOTO, RemovePhotoPayload>

export const REMOVE_BEFORE_PHOTO = 'REMOVE_BEFORE_PHOTO'
export type RemoveBeforePhoto = PayloadAction<typeof REMOVE_BEFORE_PHOTO, RemovePhotoPayload>

export const SET_COLLECTOR_NOT_APPROVED = 'SET_COLLECTOR_NOT_APPROVED'

export type SetCollectorNotApproved = PayloadAction<typeof SET_COLLECTOR_NOT_APPROVED, boolean>

export type CollectorAdvertAction =
  | AcceptJob
  | AcceptJobFlow
  | ClearAcceptJobError
  | GetCollectorReceipt
  | MakeCounterOffer
  | RemoveAfterPhoto
  | RemoveBeforePhoto
  | SaveBeforePhoto
  | SaveAfterPhoto
  | SetAcceptJobConfirmed
  | SetAcceptJobIntent
  | SetCollectorNotApproved
  | WithdrawCounterOffer
