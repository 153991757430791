import React, { useEffect } from 'react'

import { NoOp } from '../../types/core'
import { Factory } from './factory'

interface DispatchProps {
  getConfiguration: NoOp
}

export interface StateProps {
  hasConfiguration: boolean
}

const factory: Factory<DispatchProps & StateProps> = ({ Check }) =>
  function StoreCheck({ children, getConfiguration, hasConfiguration }) {
    useEffect(() => {
      if (!hasConfiguration) getConfiguration()
    }, [getConfiguration, hasConfiguration])

    return <Check value={hasConfiguration}>{children}</Check>
  }

export default factory
