import { GetLens, isDefined, Lens, lensFactory, SetLens } from '@lovejunk/core/src'
import RootState from 'core/state'
import { orderBy, unionBy } from 'lodash/fp'

import State from './state'
import { SuccessfulClientReferenceUpdate, TradeJobItem } from './types'

const lens = lensFactory<State>()

export const areJobsLoadingLens = lens('loading')
export const updatingClientReferenceLens = lens('updatingClientReference')
export const successfulClientReferenceUpdateModalLens = lens('successfulClientReferenceUpdateModal')
export const metaLens = lens('meta')

export const updateClientReferenceLens: SetLens<State, SuccessfulClientReferenceUpdate> = state => data => ({
  ...state,
  items: state.items.map(item => {
    if (item.advertReference !== data.advertReference) return item

    return {
      ...item,
      clientReference: data.clientReference,
    }
  }),
})

export const itemsSetLens: SetLens<State, TradeJobItem[]> = state => items => ({
  ...state,
  items,
})

export const itemsLens: Lens<State, TradeJobItem[]> = state => ({
  get: () => state.items,
  set: items => ({ ...state, items: unionBy('advertReference', state.items, items) }),
})

export const getAdvertItemsLens: GetLens<State, TradeJobItem[]> = state => () =>
  orderBy(['listedAt'], ['desc'], state.items)

export const canLoadMoreJobsLens: GetLens<State, boolean> = state => () => isDefined(state.meta?.next_url)

export const tradeJobsRootLens: GetLens<RootState, State> = state => () => state.tradeJobs
