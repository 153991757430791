import Reducer from '../../types/reducer'
import { ADD_IDENTIFIER_FAILURE } from '../login/types'
import { authIdentifierVerificationPayloadLens, otpErrorLens, otpResentPayloadLens } from './lens'
import State, { otpDefaultState as defaultState } from './state'
import {
  AUTH_IDENTIFIER_NOT_VERIFIED,
  AUTH_IDENTIFIER_VERIFIED,
  DISMISS_AUTH_IDENTIFIER_VERIFICATION,
  DISMISS_OTP_RESENT_MODAL,
  DISMISS_OTP_VERIFY_FAILED_MODAL,
  RESEND_OTP,
  VERIFY_OTP_FAILURE,
} from './types'

export const otpReducer: Reducer<State> = (state = defaultState(), action) => {
  switch (action.type) {
    case AUTH_IDENTIFIER_VERIFIED:
    case AUTH_IDENTIFIER_NOT_VERIFIED:
      return authIdentifierVerificationPayloadLens(state).set(action.payload)
    case ADD_IDENTIFIER_FAILURE:
      return authIdentifierVerificationPayloadLens(state).set(action.payload)
    case DISMISS_AUTH_IDENTIFIER_VERIFICATION:
      return authIdentifierVerificationPayloadLens(state).set(null)
    case RESEND_OTP:
      return otpResentPayloadLens(state).set(action.payload)
    case DISMISS_OTP_RESENT_MODAL:
      return otpResentPayloadLens(state).set(null)
    case VERIFY_OTP_FAILURE:
      return otpErrorLens(state).set(action.payload)
    case DISMISS_OTP_VERIFY_FAILED_MODAL:
      return otpErrorLens(state).set(null)
    default:
      return state
  }
}

export default otpReducer
