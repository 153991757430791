import { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { GALLERY_URL } from 'utils/constants'

const RedirectToGallery: FC = () => {
  const { seoUrl, tagId } = useParams()

  const slug = seoUrl || tagId
  const url = slug ? `${GALLERY_URL}/gallery/${slug}` : `${GALLERY_URL}/gallery/rubbish-removal`

  useEffect(() => {
    window.location.replace(url)
  }, [url])

  return null
}

export default RedirectToGallery
