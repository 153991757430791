import { Action } from 'redux'

import { Optional, TrackingEvents } from './types/core'
import { emitAnalyticsEvents } from './utils/analytics'

export const emitTrackingEventsStep =
  <T, A extends Action<T>>(actionType: A['type'], trackingEvents: Optional<TrackingEvents>) =>
  (action: A) =>
  () =>
    action.type === actionType ? emitAnalyticsEvents(trackingEvents).then(() => action) : Promise.resolve(action)
