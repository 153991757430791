import { compact, flow, isNull, join } from 'lodash/fp'

import { isDefined } from '../../../helpers'
import { Optional, Price, Transform, Validation } from '../../../types/core'
import { formatPrice } from '../../../utils/intl'
import { isVatPrice, vatPriceToExVatPrice, vatPriceToPrice } from '../../../utils/price'
import { isCollectorMeta, isCollectorMetaPresent } from '../../meta/helpers'
import { BestCounterOfferPayload, CollectionChargePayload, Prices, PricesOffer, PricesProps } from '../types/prices'

export const pricesOfferArray: Transform<PricesOffer, Optional<string[]>> = ({ price, retainDecimals, vat }) =>
  price ? compact([formatPrice(price, retainDecimals), vat && `(${vat})`]) : undefined

export const formatPricesOffer: Transform<PricesOffer, Optional<string>> = flow(
  pricesOfferArray,
  r => r && join(' ')(r),
)

export const calculatePrices: Transform<PricesProps, Prices> = props => ({
  baseOffer: pricesPropsToBaseOffer(props),
  counterOffer: pricesPropsToCounterOffer(props),
})

export const pricesPropsToBaseOffer: Transform<PricesProps, PricesOffer> = pricesProps => ({
  price: pricesProps.price,
  vat: getBaseOfferVat(pricesProps),
})

export const pricesPropsToCounterOffer: Transform<PricesProps, Optional<PricesOffer>> = ({
  bestCounterOffer,
  customerIsVatRegistered,
  offersMode = false,
  isOwner,
  meta,
  price,
  showBestCounterOfferToCollector = true,
}) => {
  const isCollector = isCollectorMetaPresent(meta)
  const payload: Optional<BestCounterOfferPayload> =
    bestCounterOffer && (isOwner || !isCollector || showBestCounterOfferToCollector)
      ? {
          bestCounterOffer,
          customerIsVatRegistered,
          isOwner,
          meta,
          price,
        }
      : undefined

  const [getPrice, getVat] = offersMode
    ? [getOffersCounterOfferPrice, getOffersCounterOfferVat]
    : [getCounterOfferPrice, getCounterOfferVat]

  return payload && { price: getPrice(payload), vat: getVat(payload) }
}

const getBaseOfferVat: Transform<PricesProps, Optional<string>> = ({
  customerIsVatRegistered,
  isOwner,
  meta,
  price,
  skipOwnerCheckForVat = false,
}) =>
  (skipOwnerCheckForVat || isOwner) && customerIsVatRegistered && price && isVatPrice(price) && price.hasVat
    ? formatPrice({ amount: price.amountExVat, currency: price.currency }) + ' + VAT'
    : isCollectorMetaPresent(meta) && customerIsVatRegistered && !isOwner
    ? 'VAT'
    : undefined

const getCounterOfferPrice: Transform<BestCounterOfferPayload, Price> = ({ bestCounterOffer }) =>
  vatPriceToPrice(bestCounterOffer)

const getOffersCounterOfferPrice: Transform<BestCounterOfferPayload, Price> = ({
  bestCounterOffer,
  customerIsVatRegistered,
}) => (customerIsVatRegistered ? vatPriceToExVatPrice : vatPriceToPrice)(bestCounterOffer)

const getCounterOfferVat: Transform<BestCounterOfferPayload, Optional<string>> = ({
  bestCounterOffer: { amountExVat: amount, currency, hasVat },
  customerIsVatRegistered,
  isOwner,
  meta,
}) =>
  isNull(meta) || !(isCollectorMeta(meta) || isOwner) || !customerIsVatRegistered
    ? undefined
    : !hasVat
    ? 'no VAT'
    : isCollectorMeta(meta) && hasVat && meta.collectorInfo.isVatRegistered
    ? 'VAT'
    : formatPrice({ amount, currency }) + ' + VAT'

const getOffersCounterOfferVat: Transform<BestCounterOfferPayload, Optional<string>> = ({
  bestCounterOffer: { amount, currency, hasVat },
  customerIsVatRegistered,
}) => (!customerIsVatRegistered ? undefined : hasVat ? formatPrice({ amount, currency }) + ' inc VAT' : 'no VAT')

export const showCollectionCharge: Validation<CollectionChargePayload> = ({ isSupplier, status }) =>
  !isSupplier && isDefined(status) && (status === 'collected' || status === 'helpunderway')
