import { Get, Nullable } from '../../types/core'
import { TimeSlots } from './types'

export interface TimeSlotsState {
  data: Nullable<TimeSlots>
}

export const timeSlotsDefaultState: Get<TimeSlotsState> = () => ({
  data: null,
})

export default TimeSlotsState
