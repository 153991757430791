import { addEmUnitToSize, Optional, Size, Transform } from '@lovejunk/core'
import { flow } from 'lodash/fp'
import React, { CSSProperties, forwardRef, ForwardRefRenderFunction } from 'react'
import { styled } from 'styled'
import { classNames } from 'utils/css'

import Column from '../../containers/Column'
import TextBase from '../../text/Text'
import GenericButton, { ExternalProps } from '../GenericButton'
import Loader from './Loader'

interface OwnProps {
  text: string

  border?: boolean
  contrastDark?: boolean
  destructive?: boolean
  mini?: boolean
  progress?: boolean
  progressText?: string
  secondary?: boolean
  shadow?: boolean
  shapeStyle?: CSSProperties
  textSize?: number
  textStyle?: CSSProperties
  uppercase?: boolean
}

export type Props = ExternalProps<OwnProps>

const defaultPadding: Size = { width: 0.75, height: 1 }
const sizeFactor: Transform<Optional<boolean>, number> = (mini = false) => (mini ? 0.75 : 1)

const MainButton: ForwardRefRenderFunction<HTMLButtonElement, Props> = (
  {
    border,
    className,
    contrastDark,
    destructive,
    disabled,
    mini,
    progressText = 'Loading...',
    progress,
    secondary,
    shadow,
    shapeStyle,
    text,
    textSize = 1,
    textStyle,
    uppercase = true,
    ...props
  },
  ref,
) => {
  const scale = sizeFactor(mini)
  const baseSize = scale * textSize
  const padding = mini ? { width: 0.75, height: 0.5 } : defaultPadding

  return (
    <GenericButton
      {...props}
      className={classNames(className, 'LoveJunk-MainButton')}
      disabled={disabled || progress}
      ref={ref}
    >
      {({ disabled, style }) => (
        <Shape
          border={border}
          contrastDark={contrastDark}
          destructive={destructive}
          disabled={disabled}
          mini={mini}
          padding={padding}
          secondary={secondary}
          shadow={shadow}
          style={style}
          shapeStyle={shapeStyle}
        >
          {progress ? (
            <Progress mini={mini} padding={padding} scale={scale} size={baseSize}>
              {progressText}
            </Progress>
          ) : (
            <Text className="LoveJunk-MainButton_text" size={baseSize} style={textStyle} uppercase={uppercase}>
              {text}
            </Text>
          )}
        </Shape>
      )}
    </GenericButton>
  )
}

interface ShapeProps {
  border?: boolean
  contrastDark?: boolean
  destructive?: boolean
  disabled?: boolean
  mini?: boolean
  padding?: Size
  secondary?: boolean
  shadow?: boolean
  shapeStyle?: CSSProperties
}

export const Shape = styled(Column)<ShapeProps>`
  background-color: ${({ contrastDark, disabled, destructive, secondary, theme: { colors } }) =>
    disabled
      ? colors.grey
      : contrastDark
      ? colors.silver
      : secondary
      ? colors.secondary
      : destructive
      ? colors.error
      : colors.primary};
  color: ${({ theme: { colors } }) => colors.white};
  font-family: Lato-Semibold;
  font-size: ${({ style }) => style?.fontSize || '1.125em'};
  min-width: ${({ mini, shapeStyle }) => (mini ? 6 : shapeStyle?.minWidth || 12)}em;
  padding: ${({ padding = defaultPadding }) =>
    flow(addEmUnitToSize, ({ width, height }) => `${height} ${width}`)(padding)};
  border-radius: 0.5em;
  box-shadow: ${({ shadow = true }) => (shadow ? '0 0 0.25em 1px rgba(0, 0, 0, 0.21)' : 'none')};
`

interface ProgressProps {
  mini?: boolean
  padding: Size
  scale: number
}

const Progress = styled(Loader)<ProgressProps>`
  margin-left: ${({ padding, scale }) => scale * padding.width}em;
`

const Text = styled(TextBase).attrs({ centered: true })`
  user-select: none;
`

export default forwardRef(MainButton)
