import React, { FC } from 'react'
import { styled } from 'styled'
import { keyframes } from 'styled-components'

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

interface Props {
  size?: number
}

const Spinner: FC<Props> = ({ size = 1.5, ...props }) => <Root {...props} size={size} />

interface RootProps extends Props {
  size: number
}

const Root = styled.div<RootProps>`
  width: ${({ size }) => size}em;
  height: ${({ size }) => size}em;
  border: ${({ size }) => size / 5}em solid ${({ theme: { colors } }) => colors.white};
  border-radius: 50%;
  border-top: ${({ size }) => size / 5}em solid transparent;
  animation: ${spin} 1s linear infinite;
`

export default Spinner
