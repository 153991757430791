import { isDefined, ListItemElementsProps as ElementsProps, listItemFactory as factory } from '@lovejunk/core'
import React, { FC } from 'react'
import { MdChevronRight } from 'react-icons/md'
import { styled } from 'styled'

import CenteredRow from './containers/CenteredRow'
import Dot from './Dot'
import ReactIcon from './ReactIcon'
import Text from './text/Text'

export const BaseText = styled(Text)`
  margin: 0 0.5em;
`

export const Label = styled(BaseText).attrs({ color: 'greyText' })``

const StyledRoot = styled(CenteredRow)`
  border-color: ${({ theme: { colors } }) => colors.grey};
  border-style: solid;
  border-width: 0 0 1px;
  cursor: ${({ onClick }) => (isDefined(onClick) ? 'pointer' : 'auto')};
  height: 4em;
  justify-content: space-between;
  padding: 1em 0;
`

export const Root: FC<ElementsProps['Root']> = ({ onPress: onClick, ...props }) => (
  <StyledRoot {...props} onClick={onClick} />
)

type ListItemElements = Parameters<typeof factory>[0]

const ListItemElements: ListItemElements = {
  Chevron: styled(ReactIcon).attrs({ color: 'grey', Icon: MdChevronRight, size: 2 })``,
  Dot: styled(Dot)`
    margin-top: -0.125em;
  `,
  Label: styled(Label)`
    display: flex;
    gap: 0.125em;
    flex: 1;
  `,
  Root,
  Value: BaseText,
}

const ListItem = factory(ListItemElements)
export const GenericListItem = factory({ ...ListItemElements, Value: CenteredRow })

export default ListItem
