import { action, failureAction, payloadAction } from '../../action-factory'
import apiAction, { ActionBundle } from '../../api-action'
import { StatelessPayloadThunk } from '../../types/thunk'
import { getDisposalSitesApi } from './api'
import { DisposalSite } from './types'
import {
  GET_DISPOSAL_SITES_FAILURE,
  GET_DISPOSAL_SITES_START,
  GET_DISPOSAL_SITES_SUCCESS,
  GetDisposalSitesFailure,
  GetDisposalSitesResult,
  GetDisposalSitesStart,
  GetDisposalSitesSuccess,
} from './types/actions'

const getDisposalSitesActionBundle: ActionBundle<
  GetDisposalSitesStart,
  GetDisposalSitesSuccess,
  GetDisposalSitesFailure,
  DisposalSite[]
> = [
  action(GET_DISPOSAL_SITES_START),
  payloadAction(GET_DISPOSAL_SITES_SUCCESS),
  failureAction(GET_DISPOSAL_SITES_FAILURE),
]

export const getDisposalSites: StatelessPayloadThunk<string, GetDisposalSitesResult> = url => dispatch =>
  dispatch(apiAction(getDisposalSitesActionBundle)(getDisposalSitesApi(url)))
