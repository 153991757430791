import Reducer from '../../types/reducer'
import { favouritesItemsLens } from './lens'
import State, { favouritesDefaultState as defaultState } from './state'
import { FAVOURITES_SUCCESS } from './types'

const reducer: Reducer<State> = (state = defaultState, action) => {
  switch (action.type) {
    case FAVOURITES_SUCCESS:
      return favouritesItemsLens(state).set(action.payload)
    default:
      return state
  }
}

export default reducer
