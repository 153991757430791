import { sentryMiddleware } from '@lovejunk/core'
import { composeWithDevTools } from '@redux-devtools/extension'
import { applyMiddleware, createStore, StoreEnhancer } from 'redux'
import thunk from 'redux-thunk'
import { ThunkDispatch } from 'types/thunk'

import { persistedReducer as reducer } from './reducer'

const composeEnhancers = composeWithDevTools({ serialize: true })
const enhancer = composeEnhancers(applyMiddleware(thunk, sentryMiddleware)) as StoreEnhancer<{
  dispatch: ThunkDispatch
}>

const store = createStore(reducer, enhancer)

export const { dispatch, getState } = store

export default store
