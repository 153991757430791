import { Dispatch, isNotNull } from '@lovejunk/core'
import { isEmpty } from 'lodash/fp'
import { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

export default (setPartner: Dispatch<string>) => {
  const [searchParams] = useSearchParams()
  const partner = useMemo(() => searchParams.get('partner'), [searchParams])

  useEffect(() => {
    if (isNotNull(partner) && !isEmpty(partner)) setPartner(partner)
  }, [partner, setPartner])
}
