import {
  Location,
  NoOp,
  Nullable,
  PaymentMethod,
  PostcodePayload,
  Price,
  RemoteActionBase,
  RemoteUrlAction,
  Response,
  ResponseUrlMeta,
  TagBase,
  TrackingEvents,
  VatPrice,
  VatPriceRange,
} from '../../../types/core'
import { Meta } from '../../meta/types'
import { OpenTimeSlot, TimeSlotFixedName } from '../../time-slots/types'

interface GenericAdvert<D> {
  actions: Partial<AdvertActions>
  customerIsVatRegistered: boolean
  id: AdvertId
  junkDetails: JunkDetails
  locationDetails: LocationDetails
  metaDetails: MetaDetails<D>
  publicDetails: PublicDetails
  status: Status
  view: AdvertView

  assignedCollectorDetails?: CollectorDetails
  assignedJunkLoverDetails?: JunkLoverDetails
  counterOfferDetails?: CounterOfferDetails
  customerDetails?: CustomerDetails
  jobDetails?: JobDetails
  junkLoverOfferDetails?: JunkLoverOfferDetails
}

export type AdvertBase = GenericAdvert<Date>
export type AdvertBaseResource = GenericAdvert<string>

export type AdvertId = number
export type AdvertReference = string
export type AdvertSource = 'app' | 'web'
export type AdvertTag = TagBase
export type LoadSize = 'small' | 'medium' | 'large' | 'extralarge'
export type Status = 'active' | 'cancelled' | 'collected' | 'expired' | 'helpunderway'
export type JobStatus = 'pending' | 'awaitingcollection' | 'collectorconfirmed' | 'complete' | 'paymentinprogress'
export type NonPublicAdvertView = 'associated' | 'owner'
export type AdvertView = NonPublicAdvertView | 'public'
export type Prerequisite = 'setpreferredpaymentmethod'
export type SupplierType = 'collector' | 'junklover'

export interface Rating {
  name: string
  value: number
}

export const ratings: Rating[] = [
  { name: '1', value: 1 },
  { name: '2', value: 2 },
  { name: '3', value: 3 },
  { name: '4', value: 4 },
  { name: '5', value: 5 },
]

export interface CollectionPoint {
  easyAccess?: boolean
  easyAccessReason?: string
}

export interface Wtn {
  advertReference: AdvertReference
  advertTitle: string
  city: string
  collectedAt: string
  collectorFullName: string
  collectorOpCentre: string
  collectorOrgName: string
  collectorAddress: string
  customerFullName: string
  disposalSiteName: string
  disposalSiteLicense: string
  jobId: number
  postcode: string
  size: number
  supplierIsReuser: boolean

  address1?: string
  address2?: string
  address3?: string
  collectorOrgLicense?: string
  disposalSiteDiversion?: number
  jobDisposalDetails?: Partial<JobDisposalDetails>
}

export interface JobDisposalDetails {
  fridgeFreezerOrAircon: number
  bulkyAppliance: number
  otherAppliance: number
  upholsteredSeating: number
}

type ReceiptType = 'customer' | 'collector' | 'customerreuseservice'

export interface ReceiptBase<T extends ReceiptType> {
  advertReference: AdvertReference
  collectedAt: string
  creditUsed: Price
  netAmountPaid: Price
  paymentType: PaymentMethod
  type: T
  vat: Price
}

export interface CollectorReceiptAddress {
  city: string
  postcode: string

  address1?: string
  address2?: string
}

export interface AdvertAsset {
  fullUrl: string
}

export interface Details {
  builderWaste?: boolean
  happyToHelp?: boolean
  isLightweight?: boolean
  residential?: boolean
  reusable?: boolean
  size?: LoadSize
}

export interface AddressResource extends PostcodePayload {
  address1: string
  address2: string
  city: string

  address3?: string
  postcodeDistrict?: string
}

interface JunkDetails {
  builderWaste: boolean
  easyAccess: boolean
  isReusable: boolean
  isTrade: boolean
  residential: boolean
  size: LoadSize

  easyAccessReason?: string
  isLightweight?: boolean
  isReuseOnly?: boolean
}

export interface TimeDetails<D = string> {
  endAt: D
  startAt: D

  fixedTimeSlotChoice?: TimeSlotFixedName
  openTimeSlotChoice?: OpenTimeSlot
}

export interface CounterOfferDetails {
  bestCounterOffer: VatPrice
  counterOfferIsCharitable: boolean
}

export interface JunkLoverOfferDetails {
  allowReuseRequests: boolean
  hasJunkLoverOffer: boolean

  conditions?: string
  expiry?: string
  junkLoverDetails?: OfferJunkLoverDetails
}

interface MetaDetails<D> {
  createdAt: D
  offeredAt: D
  reference: AdvertReference
  reviewed: boolean
  source: AdvertSource

  matchedTimeInSeconds?: number
  numberOfViews?: number
}

export interface AdvertAddress extends PostcodePayload {
  city: string

  address1?: string
  address2?: string
  address3?: string
}

interface LocationDetails extends Location {
  accuracyInDegrees: number
  accuracyInMetres: number
  address: AdvertAddress
  isUlez: boolean
}

interface PublicDetails {
  images: AdvertAsset[]
  timeDetails: TimeDetails
  title: string

  price?: Price
  summary?: string
  supplierType?: SupplierType
  tags?: AdvertTag[]
  videos?: AdvertAsset[]
}

export interface UserName {
  firstName: string

  lastName?: string
}

export interface UserDetails extends UserName {
  phoneNumber?: string
}

export interface UserDetailsWithPhoneNumber extends UserDetails {
  phoneNumber: string
}

export interface SupplierDetails extends UserDetails {
  averageRating: number
  collectionsNum: number
  selfieUrl: string
}

export interface CollectorDetails extends SupplierDetails {
  licenseNumber: string
}

export interface CustomerDetails extends UserDetails {
  isVatRegistered: boolean
  rating: number
}

export interface DocumentPayload extends AdvertActionPayload {
  advertId: AdvertId
}

export interface JunkLoverDetails extends SupplierDetails {
  postcode?: string
}

export type OfferJunkLoverDetails = Omit<JunkLoverDetails, 'phoneNumber'>

export interface JobDetails {
  status: JobStatus
  window: JobWindow

  collectedAt?: string
  currentDisposalSiteId?: number
  price?: Price
}

interface WithdrawCounterOfferActionDetails {
  price: Price
}

interface DetailsAction<T> {
  details: T
}

type RemoteDetailsAction<T> = RemoteActionBase & DetailsAction<T>

type RemoteUrlDetailsAction<T> = RemoteUrlAction & DetailsAction<T>

type WithdrawCounterOfferAction = RemoteUrlDetailsAction<WithdrawCounterOfferActionDetails>

interface MakeCounterOfferActionDetails {
  allowConditionalCounterOffers: boolean
  validRange: VatPriceRange

  commissionPercentage?: number
}

export type MakeCounterOfferAction = RemoteUrlDetailsAction<MakeCounterOfferActionDetails>

export interface JobDisposalActionDetails {
  disposalSiteId: number
}

export type JobDisposalAction = RemoteUrlDetailsAction<JobDisposalActionDetails>

export interface PrerequisitesAction extends RemoteUrlAction {
  prerequisites?: Prerequisite[]
}

interface IncreasePriceActionDetails {
  increment: Price
}

export type IncreasePriceAction = RemoteUrlDetailsAction<IncreasePriceActionDetails>

interface ViewMessagesActionDetails {
  isOpen: boolean
}

export type ViewMessagesAction = RemoteUrlDetailsAction<ViewMessagesActionDetails>

type CancelAdvertAction = RemoteUrlAction
type RepostAction = RemoteUrlAction

export interface EditAndRepostActionDetails {
  repost: RepostAction

  cancel?: CancelAdvertAction
}

interface FetchOffersActionDetails {
  hasUnreadMessages: boolean
}

export type EditAndRepostAction = RemoteDetailsAction<EditAndRepostActionDetails>
export type FetchOffersAction = RemoteUrlDetailsAction<FetchOffersActionDetails>

export interface RelistOrCancelActionDetails {
  relist: RemoteUrlAction
  cancelJob: RemoteUrlAction
}

type RelistOrCancel = RemoteDetailsAction<RelistOrCancelActionDetails>

export interface AdvertActions {
  acceptJob: RemoteUrlAction
  addFavourite: RemoteUrlAction
  awaitingOffers: RemoteActionBase
  awaitingReply: RemoteActionBase
  collectJunkForFree: RemoteActionBase
  collectorDisposalSites: RemoteUrlAction
  collectorReceipt: RemoteUrlAction
  collectorWtn: RemoteUrlAction
  confirmAndDisposeWithImages: RemoteUrlAction
  confirmAndReuseWithImages: RemoteUrlAction
  customerReceipt: RemoteUrlAction
  customerWtn: RemoteUrlAction
  deleteAdvert: RemoteUrlAction
  editAndRepost: EditAndRepostAction
  fetchAllCounterOffers: FetchOffersAction
  fetchAllJunkLoverOffers: FetchOffersAction
  increasePrice: IncreasePriceAction
  initiatePaymentProcess: RemoteUrlAction
  jobDisposal: JobDisposalAction
  junkLoverConfirmAndReuse: RemoteUrlAction
  junkLoverReceipt: RemoteUrlAction
  junkLoverWtn: RemoteUrlAction
  makeAdjustment: RemoteUrlAction
  makeCounterOffer: MakeCounterOfferAction
  makeJunkLoverOffer: RemoteUrlAction
  promptReuserSignup: RemoteActionBase
  relistOrCancel: RelistOrCancel
  repost: RepostAction
  review: RemoteUrlAction
  viewMessages: ViewMessagesAction
  waitForPayment: RemoteActionBase
  withdrawCounterOffer: WithdrawCounterOfferAction
}

interface CollectorCollectionDoneAdvertStateActions extends Partial<AdvertActions> {
  collectorDisposalSites: AdvertActions['collectorDisposalSites']

  confirmAndDisposeWithImages?: AdvertActions['confirmAndDisposeWithImages']
  confirmAndReuseWithImages?: AdvertActions['confirmAndReuseWithImages']
}

export interface CollectorCollectionDoneAdvertState extends AdvertBase {
  actions: CollectorCollectionDoneAdvertStateActions
}

interface JunkLoverCollectionDoneAdvertStateActions extends Partial<AdvertActions> {
  junkLoverConfirmAndReuse: AdvertActions['junkLoverConfirmAndReuse']
}

export interface JunkLoverCollectionDoneAdvertState extends AdvertBase {
  actions: JunkLoverCollectionDoneAdvertStateActions
}

export interface AdvertWithCounterOffer extends AdvertBase {
  counterOfferDetails: CounterOfferDetails
  status: 'active'
}

export interface OwnerAdvertWithCounterOffer extends AdvertWithCounterOffer {
  view: 'owner'
}

export interface JobWindow {
  slotStart: string
  slotEnd: string
}

export interface AdvertNavigationBody {
  location: string
}

export type AdvertNavigationResponse = Response<AdvertNavigationBody>
export type AdvertResponse = Response<AdvertBaseResource, ResponseUrlMeta>

export interface AdvertDetailsPayload<T = AdvertBase> {
  advert: T
  advertUrl: string // Advert details URL
}

export interface AdvertActionPayload {
  advertUrl: string // Advert details URL
  url: string // Advert action URL

  trackingEvents?: TrackingEvents
}

export interface AdvertIdAndUrl {
  advertId: AdvertId
  advertUrl: string
}

export interface AdvertIdRefUrl {
  advertId: AdvertId
  advertRef: AdvertReference
  advertUrl: string
}

export interface AdvertPayload {
  id: AdvertId
  url: string // Advert details URL
}

export interface AdvertPayloadWithRef {
  reference: AdvertReference
  url: string // Advert details URL
}

export type CollectJunkPayload = AdvertIdRefUrl

export interface AdvertPriceVisibilityPayload {
  advert: AdvertBase
  meta: Nullable<Meta>
}

export interface SubmitPayload {
  comments: string
  rating: Rating['value']
}

export interface AcceptCounterOfferPayload extends AdvertActionPayload {
  prerequisites?: Prerequisite[]
}

export interface AcceptJunkLoverOfferPayload extends AdvertActionPayload {
  prerequisites?: Prerequisite[]
}

export interface NewAdvertPayload {
  advertUrl: string

  cancelUrl?: string
}

export type Relist = Omit<AdvertActionPayload, 'advertUrl'>

export interface CancellationReasonPayload extends AdvertActionPayload {
  relist?: Relist
}

export interface ConfirmReusableCollectionPayload extends AdvertDetailsPayload {
  reuseUrl: string
}

export type FetchWtnResponse = Response<Wtn>

export interface CollectionAgreedPayload extends AdvertActionPayload {
  junkLoverName: string
  showModal: boolean
  action: NoOp
}
