import React, { FC, PropsWithChildren } from 'react'

import { styled } from '../../../styled'
import CenteredRow from '../../containers/CenteredRow'
import Spinner from '../../Spinner'
import TextBase from '../../text/Text'

interface Props {
  size?: number
}

const Loader: FC<PropsWithChildren<Props>> = ({ children, size = 1, ...props }) => (
  <Root {...props}>
    <Spinner size={size} />
    <Text size={size}>{children}</Text>
  </Root>
)

const Root = styled(CenteredRow)`
  justify-content: center;
`

const Text = styled(TextBase)`
  margin: auto 0;
  padding: 0 0.5em;
`

export default Loader
