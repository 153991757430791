import { entries, map, size } from 'lodash/fp'
import React, { FC, ReactElement, useCallback, useMemo } from 'react'

import { Dispatch, Entry, NoOp, Transform } from '../types/core'
import { Render, ToElements } from '../types/ui'

interface TabProps {
  key: string
  onPress: NoOp
  selected: boolean
}

interface ViewProps {
  key: string

  visible?: boolean
}

export interface ElementsProps {
  Root: unknown
  Tab: TabProps
  Tabs: unknown
  View: ViewProps
  Views: unknown
}

type Elements = ToElements<ElementsProps>

interface ContentPayload {
  visible: boolean
}

interface DataItem<T> {
  renderContent: Render<ContentPayload>
  title: string
  value: T
}

export type Data<T> = DataItem<T>[]

interface Props<T> {
  data: Data<T>
  onChange: Dispatch<T>

  value?: T
}

const factory =
  <T,>({ Root, Tab, Tabs, View, Views }: Elements): FC<Props<T>> =>
  ({ data, onChange, value }) => {
    const dataEntries = useMemo(() => entries(data), [data])
    const createOnTabPress = useCallback<Transform<T, NoOp>>(v => () => onChange(v), [onChange])
    const tabs = useMemo(
      () =>
        map<Entry<DataItem<T>>, ReactElement>(([idx, { title, value: dataItemValue }]) => (
          <Tab key={idx} onPress={createOnTabPress(dataItemValue)} selected={dataItemValue === value}>
            {title}
          </Tab>
        ))(dataEntries),
      [createOnTabPress, dataEntries, value],
    )
    const views = useMemo(
      () =>
        map<Entry<DataItem<T>>, ReactElement>(([idx, { renderContent, value: dataItemValue }]) => {
          const visible = dataItemValue === value

          return (
            <View key={idx} visible={visible}>
              {renderContent({ visible })}
            </View>
          )
        })(dataEntries),
      [dataEntries, value],
    )

    return (
      <Root>
        {size(tabs) > 1 && <Tabs>{tabs}</Tabs>}
        <Views>{views}</Views>
      </Root>
    )
  }

export default factory
