import { action, failureAction, payloadAction } from '../../action-factory'
import apiAction, { ActionBundle } from '../../api-action'
import { NoMetaError } from '../../errors'
import { ActionFactory, PayloadActionFactory, Transform } from '../../types/core'
import { StatelessPayloadThunk, StatelessThunk } from '../../types/thunk'
import buildConfig from '../../utils/build-config'
import { withEndpoint } from '../app/helpers'
import { fetchMetaStep } from '../meta/actions'
import { addIdentifierApi, loginApi } from './api'
import {
  ACCOUNT_SUSPENDED,
  AccountSuspended,
  ADD_IDENTIFIER_FAILURE,
  ADD_IDENTIFIER_START,
  ADD_IDENTIFIER_SUCCESS,
  AddIdentifierBasePayload,
  AddIdentifierFailure,
  AddIdentifierPayload,
  AddIdentifierResult,
  AddIdentifierStart,
  AddIdentifierSuccess,
  CANCEL_LOGIN,
  CancelLogin,
  LOGIN_COLLECTOR,
  LOGIN_CUSTOMER,
  LOGIN_JUNK_LOVER,
  LOGIN_OTP_FAILURE,
  LOGIN_OTP_START,
  LOGIN_OTP_SUCCESS,
  LoginCollector,
  LoginConfig,
  LoginCustomer,
  LoginJunkLover,
  LoginOtpFailure,
  LoginOtpResult,
  LoginOtpStart,
  LoginOtpSuccess,
  LoginParams,
  LoginPayload,
  LOGOUT_SUCCESS,
  LogoutSuccess,
  SET_LOGIN_ERROR,
  SetLoginError,
} from './types'

const { getConfig, setConfig } = buildConfig<LoginConfig>('login')

export { setConfig as configureLogin }

export const loginOtpStart: PayloadActionFactory<LoginOtpStart, LoginOtpStart['payload']> =
  payloadAction(LOGIN_OTP_START)
export const loginOtpSuccess: ActionFactory<LoginOtpSuccess> = action(LOGIN_OTP_SUCCESS)
export const loginOtpFailure: PayloadActionFactory<LoginOtpFailure, LoginOtpFailure['payload']> =
  failureAction(LOGIN_OTP_FAILURE)

export const createLoginOtpFailure: Transform<LoginPayload, Transform<Error, ReturnType<typeof loginOtpFailure>>> =
  data => error =>
    loginOtpFailure({ data, error })

export const loginOtpActionBundle: Transform<
  LoginParams,
  ActionBundle<LoginOtpStart, LoginOtpSuccess, LoginOtpFailure>
> = params => [() => loginOtpStart(params.identifier), loginOtpSuccess, createLoginOtpFailure(params)]

export const login: StatelessPayloadThunk<LoginParams, LoginOtpResult> = params =>
  withEndpoint(
    'otp',
    ({ generation: otpGenerationUrl }) =>
      dispatch =>
        dispatch(apiAction(loginOtpActionBundle(params))(loginApi(otpGenerationUrl, params))),
    createLoginOtpFailure(params),
  )

export const logout: StatelessThunk<LogoutSuccess> = () => dispatch => {
  const { resetToHome, setUserId } = getConfig()

  resetToHome()
  setUserId(null)

  return Promise.resolve(dispatch(logoutSuccess()))
}

export const logoutSuccess: ActionFactory<LogoutSuccess> = action(LOGOUT_SUCCESS)

export const cancelLogin: ActionFactory<CancelLogin> = action(CANCEL_LOGIN)

export const loginCollector: ActionFactory<LoginCollector> = action(LOGIN_COLLECTOR)
export const loginCustomer: ActionFactory<LoginCustomer> = action(LOGIN_CUSTOMER)
export const loginJunkLover: ActionFactory<LoginJunkLover> = action(LOGIN_JUNK_LOVER)

export const setLoginError: PayloadActionFactory<SetLoginError, SetLoginError['payload']> =
  payloadAction(SET_LOGIN_ERROR)

export const accountSuspended: PayloadActionFactory<AccountSuspended, AccountSuspended['payload']> =
  payloadAction(ACCOUNT_SUSPENDED)

export const addIdentifierStart: ActionFactory<AddIdentifierStart> = action(ADD_IDENTIFIER_START)
export const addIdentifierSuccess: PayloadActionFactory<AddIdentifierSuccess, AddIdentifierSuccess['payload']> =
  payloadAction(ADD_IDENTIFIER_SUCCESS)
export const addIdentifierFailure: PayloadActionFactory<AddIdentifierFailure, AddIdentifierFailure['payload']> =
  payloadAction(ADD_IDENTIFIER_FAILURE)

export const addIdentifierActionBundle: Transform<
  AddIdentifierBasePayload,
  ActionBundle<AddIdentifierStart, AddIdentifierSuccess, AddIdentifierFailure, AddIdentifierSuccess['payload']>
> = payload => [addIdentifierStart, addIdentifierSuccess, error => addIdentifierFailure({ ...payload, error })]

export const addIdentifier: StatelessPayloadThunk<AddIdentifierPayload, AddIdentifierResult> =
  ({ data: { backToChat, identifier, locked, type, url }, device, meta }) =>
  dispatch => {
    const basePayload: AddIdentifierBasePayload = { backToChat, locked, type }

    if (!meta) return Promise.resolve(dispatch(addIdentifierFailure({ error: new NoMetaError(), ...basePayload })))

    const metaUrl = meta.actions.addIdentifier?.url
    const addIdentifierUrl = url || metaUrl

    if (addIdentifierUrl === undefined) {
      return Promise.resolve(
        dispatch(addIdentifierFailure({ error: new Error('`addIdentifier` url not found'), ...basePayload })),
      )
    }
    return dispatch(
      apiAction(addIdentifierActionBundle({ backToChat, locked, type }))(
        addIdentifierApi(addIdentifierUrl, { device, identifier }),
      ),
    )
      .then(fetchMetaStep(ADD_IDENTIFIER_SUCCESS))
      .then(action => dispatch(action))
  }
