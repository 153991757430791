import { lensFactory } from '../../helpers'
import RootState from '../../state'
import { GetLens } from '../../types/core'
import State from './state'

export const accountRootLens: GetLens<RootState, State> = state => () => state.account

const lens = lensFactory<State>()

export const tippingSiteArrangementsLens = lens('tippingSiteArrangements')
export const tippingSiteArrangementLens = lens('tippingSiteArrangement')
export const qrCodeUrlLens = lens('qrCodeUrl')
export const fetchingQrCodeUrlLens = lens('fetchingQrCodeUrl')
export const fetchingTippingSitesLens = lens('fetchingTippingSites')

export default accountRootLens
