import { Asset, mimeToAssetType, Optional, promiseAll, Transform } from '@lovejunk/core'
import imageCompression from 'browser-image-compression'
import { flow, isString, map } from 'lodash/fp'

interface CompressionOptions {
  maxSizeMB: number
}

const options: CompressionOptions = {
  maxSizeMB: 2,
}

interface CompressPayload {
  file: File
  options: CompressionOptions
  mimeType: string
}

const compress: Transform<CompressPayload, Promise<File>> = ({ file, mimeType, options }) =>
  mimeToAssetType(mimeType) === 'image' ? imageCompression(file, options) : Promise.resolve(file)

export default flow(
  map<File, Promise<Optional<Asset>>>(
    file =>
      new Promise<Optional<Asset>>(resolve => {
        const fileReader = new FileReader()

        fileReader.onerror = function () {
          resolve(undefined)
        }

        fileReader.onload = function () {
          resolve(isString(this.result) ? { mimeType: file.type, path: this.result } : undefined)
        }

        return compress({ file, options, mimeType: file.type }).then(compressedFile =>
          fileReader.readAsDataURL(compressedFile),
        )
      }),
  ),
  promiseAll,
)
