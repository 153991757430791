import { NoOp } from '@lovejunk/core'
import NavigationPath from 'navigation/paths'
import React, { FC } from 'react'
import { styled } from 'styled'

import StyledLink from './StyledLink'

interface Props {
  onPress?: NoOp
}

const ReusablesLink: FC<Props> = ({ onPress }) => (
  <Root onPress={onPress} to={NavigationPath.Reusables}>
    Free stuff
  </Root>
)

const Root = styled(StyledLink)`
  display: flex;
  gap: 0.125em;
`

export default ReusablesLink
