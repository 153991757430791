import React from 'react'

import { getCollectionJobTimeAndDay, userName } from '../../entities/advert/helpers'
import { isCollectorDetails } from '../../entities/advert/helpers/role'
import { CollectorDetails, JobDetails, JunkLoverDetails } from '../../entities/advert/types'
import { ComponentFactory, ToElements } from '../../types/ui'
import { Props as UserCardProps } from './UserCard/factory'

export interface OwnProps {
  supplierDetails: CollectorDetails | JunkLoverDetails
  jobDetails: JobDetails
}

type Props = OwnProps

interface ElementsProps {
  CollectedAt: unknown
  Heading: unknown
  Root: unknown
  UserCard: UserCardProps
}

type Elements = ToElements<ElementsProps>

const factory: ComponentFactory<Elements, Props> = ({ CollectedAt, Heading, Root, UserCard }) =>
  function SupplierInfo({ supplierDetails, jobDetails: { collectedAt } }) {
    return (
      <Root>
        {collectedAt ? (
          <>
            <Heading>Collected!</Heading>
            <CollectedAt>{getCollectionJobTimeAndDay(collectedAt)}</CollectedAt>
          </>
        ) : null}
        <UserCard
          image={supplierDetails.selfieUrl}
          name={userName(supplierDetails)}
          license={isCollectorDetails(supplierDetails) ? supplierDetails.licenseNumber : undefined}
        />
      </Root>
    )
  }

export default factory
