import { styled } from 'styled'
import { device } from 'utils/css'

const Panels = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  @media ${device.tablet} {
    flex-direction: column;
  }
`

export default Panels
