import { compactAndJoin, isDefined } from '../helpers'
import { BodyItem, FileItem, FileModel, FileToBodyItem, Identity, Marshal, MarshalFile, Transform } from '../types/core'
import { mimeTypeToExtension } from '../utils/assets'
import { parseDecimalInt } from '../utils/number'

export type CreateToBodyItemPayload = Pick<FileModel, 'prefix'>

export const createFileMarshaller: Transform<Identity<string>, Marshal<FileModel, BodyItem>> =
  wrapFile =>
  ({ extension, index, path, prefix }) => {
    const name = prefix + (isDefined(index) ? index + 1 : '')

    return {
      name,
      value: wrapFile(path),
      fileName: compactAndJoin('.')([name, extension]),
    }
  }

export const createToBodyItem: Transform<MarshalFile, Transform<CreateToBodyItemPayload, FileToBodyItem>> =
  marshalFile => payload => fileItem =>
    marshalFile({ ...payload, ...fileItem, extension: mimeTypeToExtension(fileItem.mimeType) })

export const createFileItemMapper =
  <T>(iterator: Transform<FileItem, T>): Transform<[string, FileItem], T> =>
  ([index, fileItem]) =>
    iterator({ ...fileItem, index: parseDecimalInt(index) })
