import { isNull } from 'lodash/fp'

import { action, failureAction, payloadAction } from '../../action-factory'
import { NoAccessTokenError } from '../../errors'
import { ActionFactory, Credentials, FailureActionFactory, PayloadActionFactory, Transform } from '../../types/core'
import { StatelessPayloadThunk, StatelessThunk } from '../../types/thunk'
import { withEndpoint } from '../app/helpers'
import { logout } from '../login/actions'
import { checkOAuthTokenStatusApi, getOAuthAccessTokenApi } from './api'
import {
  CHECK_TOKEN_FAILURE,
  CHECK_TOKEN_START,
  CHECK_TOKEN_SUCCESS,
  CheckOAuthTokenStatusPayload,
  CheckOrGetTokenPayload,
  CheckTokenFailure,
  CheckTokenStart,
  CheckTokenSuccess,
  GET_TOKEN_FAILURE,
  GET_TOKEN_START,
  GET_TOKEN_SUCCESS,
  GetTokenFailure,
  GetTokenResult,
  GetTokenStart,
  GetTokenSuccess,
  OAuthResult,
} from './types'

export const getTokenStart: ActionFactory<GetTokenStart> = action(GET_TOKEN_START)
export const getTokenSuccess: PayloadActionFactory<GetTokenSuccess, Credentials> = payloadAction(GET_TOKEN_SUCCESS)
export const getTokenFailure: FailureActionFactory<GetTokenFailure> = failureAction(GET_TOKEN_FAILURE)

export const getToken: StatelessThunk<GetTokenResult> = () =>
  withEndpoint(
    'oauthToken',
    oauthTokenUrl => dispatch => {
      dispatch(getTokenStart())

      return getOAuthAccessTokenApi(oauthTokenUrl)
        .then(credentials =>
          credentials.accessToken ? getTokenSuccess(credentials) : getTokenFailure(new NoAccessTokenError()),
        )
        .then(action => dispatch(action))
    },
    getTokenFailure,
  )

export const checkTokenStart: ActionFactory<CheckTokenStart> = action(CHECK_TOKEN_START)
export const checkTokenSuccess: ActionFactory<CheckTokenSuccess> = action(CHECK_TOKEN_SUCCESS)
export const checkTokenFailure: ActionFactory<CheckTokenFailure> = action(CHECK_TOKEN_FAILURE)

export const checkOrGetToken: Transform<CheckOrGetTokenPayload, StatelessThunk<OAuthResult>> =
  ({ credentials, sessionToken }) =>
  () =>
    withEndpoint(
      'tokenStatuses',
      tokenStatusesUrl => dispatch => {
        if (isNull(credentials) || isNull(credentials.accessToken)) {
          return dispatch(getToken())
        }

        dispatch(checkTokenStart())

        const { accessToken } = credentials

        return dispatch(checkOAuthTokenStatus({ accessToken, sessionToken, tokenStatusesUrl }))
      },
      getTokenFailure,
    )

export const checkOAuthTokenStatus: StatelessPayloadThunk<CheckOAuthTokenStatusPayload, OAuthResult> =
  ({ accessToken, sessionToken, tokenStatusesUrl }) =>
  dispatch =>
    checkOAuthTokenStatusApi(tokenStatusesUrl, accessToken, sessionToken).then(({ oauth, session }) => {
      const isOauthValid = oauth === 'valid'
      const isSessionValid = isNull(sessionToken) || session === 'valid'

      if (isOauthValid) {
        if (!isSessionValid) dispatch(logout())

        return dispatch(checkTokenSuccess())
      }

      dispatch(checkTokenFailure())

      return dispatch(getToken()).then(action => {
        if (!isSessionValid) dispatch(logout())

        return action
      })
    })
