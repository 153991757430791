import { toUpper } from 'lodash/fp'
import React, { useMemo } from 'react'

import { ComponentFactory, ToElements } from '../../../types/ui'
import { Props as AvatarProps } from './Avatar'
import { IconProps, Props as InfoProps } from './Info'

export interface Props {
  image: string
  name: string

  collections?: number
  license?: string
  postcode?: string
  rating?: number
}

interface ElementsProps {
  Avatar: AvatarProps
  CollectionsIcon: IconProps
  Content: unknown
  License: unknown
  Info: InfoProps
  InfoContainer: unknown
  Name: unknown
  RatingIcon: IconProps
  Root: unknown
}

type Elements = ToElements<ElementsProps>

const factory: ComponentFactory<Elements, Props> = ({
  Avatar,
  CollectionsIcon,
  Content,
  Info,
  InfoContainer,
  License,
  Name,
  RatingIcon,
  Root,
}) =>
  function UserCard({ collections, image, license, name, postcode, rating, ...props }) {
    const renderAvatar = useMemo(() => <Avatar src={image} />, [image])
    const renderLicense = useMemo(() => (license ? <License>License: {toUpper(license)}</License> : null), [license])
    const renderName = useMemo(() => <Name>{name}</Name>, [name])
    const renderRating = useMemo(() => (rating ? <Info Icon={RatingIcon}>{rating}</Info> : null), [rating])
    const renderPostcode = useMemo(() => (postcode ? <Info>{postcode}</Info> : null), [postcode])
    const renderCollections = useMemo(
      () => (collections ? <Info Icon={CollectionsIcon}>{collections}</Info> : null),
      [collections],
    )

    return (
      <Root {...props}>
        <Content>{renderAvatar}</Content>
        <Content>
          {renderName}
          {renderLicense}
          <InfoContainer>
            {renderPostcode}
            {renderRating}
            {renderCollections}
          </InfoContainer>
        </Content>
      </Root>
    )
  }

export default factory
