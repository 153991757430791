import { first } from 'lodash/fp'

import { UnsupportedPostcodeError } from '../errors'
import { Transform } from '../types/core'
import { FromPostcode, GeocodeFrom } from '../types/geocoder'

export const createFromPostcode: Transform<GeocodeFrom, FromPostcode> = from => postcode =>
  from(postcode).then(({ results }) => {
    const result = first(results)

    if (!result) throw new UnsupportedPostcodeError(postcode)

    return result.geometry.location
  })
