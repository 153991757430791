import { action, failureAction, payloadAction } from '../../action-factory'
import apiAction, { ActionBundle } from '../../api-action'
import { FailureActionFactory, PayloadActionFactory } from '../../types/core'
import { StatelessThunk } from '../../types/thunk'
import { withEndpoint } from '../app/helpers'
import { getTimeSlotsApi } from './api'
import {
  GET_TIME_SLOTS_FAILURE,
  GET_TIME_SLOTS_START,
  GET_TIME_SLOTS_SUCCESS,
  GetTimeSlotsFailure,
  GetTimeSlotsResult,
  GetTimeSlotsStart,
  GetTimeSlotsSuccess,
  TimeSlots,
} from './types'

export const getTimeSlotsSuccess: PayloadActionFactory<GetTimeSlotsSuccess, TimeSlots> =
  payloadAction(GET_TIME_SLOTS_SUCCESS)
const getTimeSlotsFailure: FailureActionFactory<GetTimeSlotsFailure> = failureAction(GET_TIME_SLOTS_FAILURE)

const getTimeSlotsActionBundle: ActionBundle<GetTimeSlotsStart, GetTimeSlotsSuccess, GetTimeSlotsFailure, TimeSlots> = [
  action(GET_TIME_SLOTS_START),
  getTimeSlotsSuccess,
  getTimeSlotsFailure,
]

export const getTimeSlots: StatelessThunk<GetTimeSlotsResult> = () =>
  withEndpoint(
    'timeSlots',
    timeSlotsUrl => dispatch => dispatch(apiAction(getTimeSlotsActionBundle)(getTimeSlotsApi(timeSlotsUrl))),
    getTimeSlotsFailure,
  )
