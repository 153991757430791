import { NoOp } from '@lovejunk/core'
import React, { FC, ReactNode } from 'react'
import { styled, ThemeProps } from 'styled'
import { ClassNameProps } from 'types/ui'

import CenteredColumn from '../../containers/CenteredColumn'
import CenteredRow from '../../containers/CenteredRow'
import Text from '../../text/Text'
import Check from './Check'

interface Props extends ClassNameProps {
  onPress: NoOp

  checked?: boolean
  children?: ReactNode
  disabled?: boolean
  reverse?: boolean
  size?: number
  textSize?: number
}

const CheckBox: FC<Props> = ({
  checked,
  children,
  className,
  disabled = false,
  onPress,
  reverse,
  size = 2,
  textSize,
}) => (
  <Root className={className} reverse={reverse}>
    <Input onClick={!disabled ? onPress : undefined} size={size}>
      {checked ? <Check disabled={disabled} size={size} /> : null}
    </Input>
    {children ? <Text size={textSize}>{children}</Text> : null}
  </Root>
)

const Root = styled(CenteredRow)`
  gap: 1em;
  user-select: none;
`

interface InputProps extends ThemeProps {
  size: number
}

const Input = styled(CenteredColumn)<InputProps>`
  background-color: ${({ theme: { colors } }) => colors.white};
  border: 1px solid ${({ theme: { colors } }) => colors.greyBorder};
  border-radius: ${({ size }) => size / 4}em;
  flex-shrink: 0;
  justify-content: center;
  cursor: pointer;
  padding: ${({ size }) => size / 16}em;
  height: ${({ size }) => size}em;
  width: ${({ size }) => size}em;
`

export default CheckBox
