import React, { useCallback } from 'react'

import { Dispatch, NoOp } from '../../types/core'
import { ComponentFactory, ToElements } from '../../types/ui'
import { Mode } from '../modals/types'

interface DispatchProps {
  authorised: NoOp
  setFeedback: Dispatch<false>
}

export interface StateProps {
  visible: boolean
}

type Props = DispatchProps & StateProps

interface RootProps {
  body: string
  onPress: NoOp
  title: string
  mode: Mode
  visible: boolean
}

interface ElementsProps {
  Root: RootProps
}

type Elements = ToElements<ElementsProps>

const factory: ComponentFactory<Elements, Props> =
  ({ Root }) =>
  ({ authorised, setFeedback, visible }) => {
    const onPress = useCallback(() => {
      authorised()
      setFeedback(false)
    }, [authorised, setFeedback])

    return (
      <Root
        body="Your account is now live!"
        mode="success"
        onPress={onPress}
        title="Thank you for registering"
        visible={visible}
      />
    )
  }

export default factory
