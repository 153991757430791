import { BaseMeta, CollectorMeta, CustomerMeta, JunkLoverMeta, SupplierStats, TipperMeta } from '../types'

export const metaFixture: BaseMeta = {
  actions: {},
  capabilities: [],
  creditBalance: { amount: 10, currency: 'GBP' },
  currency: 'GBP',
  firstName: 'firstName',
  lastName: 'lastName',
  messagingInfo: {
    hasUnreadMessages: false,
    hasGlobalUnreadMessages: false,
    reloadHasUnreadMessagesUrl: 'reloadHasUnreadMessagesUrl',
    reloadHasGlobalUnreadMessagesUrl: 'globalUnreadMessagesUrl',
  },
  phoneNumber: 'phoneNumber',
  deleteUserUrl: 'deleteUserUrl',
  updateUserUrl: 'updateUserUrl',
  userId: 1,
}

const collectorStats: SupplierStats = {
  averageRating: 4.9281,
  jobCompletions: 5,
  reliability: 93,
}

const junkloverStats: SupplierStats = {
  averageRating: 3.5281,
  jobCompletions: 4,
  reliability: 50,
}

export const collectorMetaFixture: CollectorMeta = {
  ...metaFixture,
  capabilities: ['collector'],
  collectorActivitiesUrl: 'collectorActivitiesUrl',
  collectorDisposalSitesUrl: 'collectorDisposalSitesUrl',
  collectorInfo: {
    accountNumber: 'accountNumber',
    isVatRegistered: false,
    licenseInfo: {
      licenseNumber: 'licenseNumber',
      organisation: 'organisation',
    },
    operationalCentre: 'operationalCentre',
    receiveMarketingNotifications: false,
    receiveMarketingNotificationsUrl: 'receiveMarketingNotificationsUrl',
    reference: 'collector-reference',
    selfieUrl: 'selfieUrl',
    sortCode: 'sortCode',
    stats: collectorStats,
    vehicleRegNumber: 'vehicleRegNumber',
    verificationStatus: 'approved',
  },
  collectorStripeInfo: {
    accountStatus: 'accountStatus',
    lastUpdated: 'lastUpdated',
    verificationUrl: 'verificationUrl',
  },
}

export const customerMetaFixture: CustomerMeta = {
  ...metaFixture,
  capabilities: ['customer'],
  advertCreationUrl: 'advertCreationUrl',
  customerActivitiesUrl: 'customerActivitiesUrl',
  customerPaymentCapabilities: ['native'],
  customerStripeInfo: {
    paymentMethodsUrl: 'paymentMethodsUrl',
    stripeCustomerUrl: 'stripeCustomerUrl',
  },
  customerInfo: {
    isVatRegistered: false,
    favouriteCollectorsUrl: 'favouriteCollectorsUrl',
    preferredPaymentMethod: 'native',
    preferredPaymentMethodUrl: 'preferredPaymentMethodUrl',
    reference: 'customer-reference',
  },
}

export const junkLoverMetaFixture: JunkLoverMeta = {
  ...metaFixture,
  capabilities: ['junklover'],
  junkLoverActivitiesUrl: 'junkLoverActivitiesUrl',
  junkLoverInfo: {
    postcode: 'postcode',
    reference: 'junk-lover-reference',
    selfieUrl: 'selfieUrl',
    status: 'active',
    receiveMarketingNotifications: true,
    receiveMarketingNotificationsUrl: 'receiveMarketingNotificationsUrl',
    stats: junkloverStats,
  },
}

export const tipperMetaFixture: TipperMeta = {
  ...metaFixture,
  capabilities: ['tipper'],
  tipperInfo: {
    isTrusted: false,
    reference: 'tipper-reference',
    status: 'approved',
  },
}
