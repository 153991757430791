import React, { useCallback, useMemo } from 'react'

import { CollectionAgreedPayload } from '../entities/advert/types'
import { JunkReuserOffer, OnAcceptCollectorOfferPayload } from '../entities/customer-advert/types'
import { isDefined } from '../helpers'
import { Dispatch, NoOp, Nullable, Optional, Transform } from '../types/core'
import { ComponentFactory, ToElements } from '../types/ui'
import { CollectionsStatProps } from './UserStat'

interface NameProps {
  onPress?: NoOp
}

interface RootProps {
  isFavourite?: boolean
}

interface ChatButtonProps {
  hasUnread?: boolean
  onPress: NoOp
  text: string
}

interface BookNowButtonProps {
  flex: boolean
  onPress: NoOp
  progress?: boolean
  text: string
}

interface ChatPayload {
  title: string

  advertUrl?: string
  createChatThreadUrl?: string
  interlocutorName?: string
  viewMessagesUrl?: string
}

interface AvatarProps {
  src: string
}

export interface ElementsProps {
  Avatar: AvatarProps
  Bold: unknown
  BookNowButton: BookNowButtonProps
  Buttons: unknown
  ChatButton: ChatButtonProps
  Collections: CollectionsStatProps
  Heading: unknown
  Name: NameProps
  Rating: unknown
  Right: unknown
  Root: RootProps
  Stats: unknown
  Body?: unknown
}

type Elements = ToElements<ElementsProps>

export interface Props extends JunkReuserOffer {
  onChat: Dispatch<ChatPayload>
  acceptingCounterOfferUrl: Nullable<string>
  onBook: Dispatch<OnAcceptCollectorOfferPayload>
  setCollectionAgreedPayload: Dispatch<CollectionAgreedPayload>
}

const factory: ComponentFactory<Elements, Props> = ({
  Avatar,
  Body,
  Bold,
  BookNowButton,
  Buttons,
  ChatButton,
  Collections,
  Heading,
  Rating,
  Root,
  Stats,
}) =>
  function JunkReuserOfferItem({
    acceptingCounterOfferUrl,
    actions: {
      accept: { trackingEvents, url },
      createChatThread,
      viewMessages,
    },
    hasUnread,
    junkLoverInfo: { name, selfieUrl },
    onChat,
    onBook,
    setCollectionAgreedPayload,
    stats,
  }) {
    const onBookPress = useCallback(() => onBook({ trackingEvents, url }), [onBook, trackingEvents, url])
    const chatPayload = useMemo<Optional<ChatPayload>>(
      () =>
        (createChatThread || viewMessages) && {
          createChatThreadUrl: createChatThread?.url,
          title: name,
          viewMessagesUrl: viewMessages?.url,
          interlocutorName: name,
        },
      [createChatThread, name, viewMessages],
    )
    const createOnChatPress = useCallback<Transform<ChatPayload, NoOp>>(payload => () => onChat(payload), [onChat])
    const onChatPress = useMemo(() => chatPayload && createOnChatPress(chatPayload), [chatPayload, createOnChatPress])
    const numberOfCollections = useMemo(() => stats?.numberOfCollections, [stats])
    const rating = useMemo(() => stats?.averageRating, [stats])

    return (
      <Root isFavourite={false}>
        <Heading>
          <Bold>{name}</Bold>
          {(isDefined(rating) || isDefined(numberOfCollections)) && (
            <Stats>
              {isDefined(rating) && <Rating fractionDigits={1}>{rating}</Rating>}
              {isDefined(numberOfCollections) && <Collections>{numberOfCollections}</Collections>}
            </Stats>
          )}
        </Heading>
        {Body && (
          <Body>
            <Avatar src={selfieUrl} />
            <Buttons>
              {onChatPress && <ChatButton hasUnread={hasUnread} onPress={onChatPress} text="View messages" />}
              <BookNowButton
                flex={isDefined(onChatPress)}
                onPress={() =>
                  setCollectionAgreedPayload({
                    showModal: true,
                    action: onBookPress,
                    junkLoverName: chatPayload?.interlocutorName || name || 'to the other party',
                    url,
                    advertUrl: chatPayload?.advertUrl || '',
                  })
                }
                progress={url === acceptingCounterOfferUrl}
                text="Collection time agreed?"
              />
            </Buttons>
          </Body>
        )}
      </Root>
    )
  }

export default factory
