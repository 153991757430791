import { Action } from 'redux'

import { FailureAction, Location, PaginationMeta, PayloadAction, RemoteUrlAction, Response } from '../../types/core'
import { AdvertReference } from '../advert/types'

interface CollectorProfileActions {
  viewCollectorReviews: RemoteUrlAction
}

export interface CollectorProfile {
  actions: Partial<CollectorProfileActions>
  averageRating: number
  licenseNumber: string
  location: Location
  name: string
  numberOfCollections: number
  radiusInKm: number
  selfieUrl: string

  blurb?: string
  region?: string
}

export type GetCollectorProfileResponse = Response<CollectorProfile>

export const GET_COLLECTOR_PROFILE_START = 'GET_COLLECTOR_PROFILE_START'
export const GET_COLLECTOR_PROFILE_SUCCESS = 'GET_COLLECTOR_PROFILE_SUCCESS'
export const GET_COLLECTOR_PROFILE_FAILURE = 'GET_COLLECTOR_PROFILE_FAILURE'

export type GetCollectorProfileStart = Action<typeof GET_COLLECTOR_PROFILE_START>
export type GetCollectorProfileSuccess = PayloadAction<typeof GET_COLLECTOR_PROFILE_SUCCESS, CollectorProfile>
export type GetCollectorProfileFailure = FailureAction<typeof GET_COLLECTOR_PROFILE_FAILURE>
export type GetCollectorProfileResult = GetCollectorProfileSuccess | GetCollectorProfileFailure
export type GetCollectorProfile = GetCollectorProfileStart | GetCollectorProfileResult

export interface CollectorReview {
  customerName: string
  rating: number
  advertTitle: string
  reviewedAt: string
  advertReference: AdvertReference

  comments?: string
  image?: string
  postcodeDistrict?: string
}

export type CollectorReviews = CollectorReview[]

export interface CollectorReviewsBody {
  entities: CollectorReviews
}

export type GetCollectorReviewsResponse = Response<CollectorReviewsBody, PaginationMeta>

export interface GetCollectorReviewsParams {
  pageSize?: number
}

export interface GetCollectorReviewsPayload extends GetCollectorReviewsParams {
  url: string
}

export interface GetCollectorReviewsData {
  items: CollectorReviews

  nextUrl?: string
}

export const GET_COLLECTOR_REVIEWS_START = 'GET_COLLECTOR_REVIEWS_START'
export const GET_COLLECTOR_REVIEWS_SUCCESS = 'GET_COLLECTOR_REVIEWS_SUCCESS'
export const GET_COLLECTOR_REVIEWS_FAILURE = 'GET_COLLECTOR_REVIEWS_FAILURE'

export type GetCollectorReviewsStart = Action<typeof GET_COLLECTOR_REVIEWS_START>
export type GetCollectorReviewsSuccess = PayloadAction<typeof GET_COLLECTOR_REVIEWS_SUCCESS, GetCollectorReviewsData>
export type GetCollectorReviewsFailure = FailureAction<typeof GET_COLLECTOR_REVIEWS_FAILURE>
export type GetCollectorReviewsResult = GetCollectorReviewsSuccess | GetCollectorReviewsFailure
export type GetCollectorReviews = GetCollectorReviewsStart | GetCollectorReviewsResult

export type CollectorProfileAction = GetCollectorProfile | GetCollectorReviews
