import { APP_NAME, DOWNLOAD_URL } from '../../constants'
import { formatPrice } from '../../utils'
import { ViewData, ViewDataConfig } from './types'

export const SHARE_BASE_TITLE = `${APP_NAME} Rubbish Clearance`

export const viewDataConfig: ViewDataConfig<ViewData> = {
  noReferrals: () => ({
    share: {
      title: SHARE_BASE_TITLE,
      message: `Check out ${APP_NAME}, a cheap eco-friendly way to get rid of bulky waste`,
      link: DOWNLOAD_URL,
    },
  }),
  none: link => ({
    share: {
      title: SHARE_BASE_TITLE,
      message: `Check out ${APP_NAME}, a cheap eco-friendly way to get rid of bulky waste`,
      link,
    },
  }),
  noReferrer: link => benefit => ({
    share: {
      title: `Share ${APP_NAME}, Give ${formatPrice(benefit)}`,
      message: `Give a friend ${formatPrice(benefit)} off their first ${APP_NAME} collection`,
      link,
    },
  }),
  noReferee: link => benefit => ({
    share: {
      title: `Share ${APP_NAME}, Get ${formatPrice(benefit)}`,
      message: `Refer a friend to ${APP_NAME} and get ${formatPrice(benefit)} off your next collection`,
      link,
    },
  }),
  mutual:
    link =>
    ({ referrerBenefit, refereeBenefit }) => ({
      share: {
        title: `Give ${formatPrice(refereeBenefit)}, Get ${formatPrice(referrerBenefit)}`,
        message: `Give a friend ${formatPrice(refereeBenefit)} off their first collection and get ${formatPrice(
          referrerBenefit,
        )} yourself`,
        link,
      },
    }),
}
