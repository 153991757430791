import { lensFactory } from '../../helpers'
import RootState from '../../state'
import { GetLens } from '../../types/core'
import State from './state'

const lens = lensFactory<State>()

export const emailRequestModalLens = lens('emailRequestModal')
export const isEmailRequestInProgressLens = lens('isEmailRequestInProgress')

export const emailRequestRootLens: GetLens<RootState, State> = state => () => state.emailRequest

export default emailRequestRootLens
