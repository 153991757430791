import { DEFAULT_PROCESS_IMAGE_OPTIONS } from '../../constants'
import { buildBodyItem } from '../../helpers'
import { MarshalImagePayload } from '../../marshallers/image'
import { Transform } from '../../types/core'
import { MarshalSignupJunkLover } from './types'

export const createMarshaller: Transform<MarshalImagePayload, MarshalSignupJunkLover> =
  ({ marshalFile, processImage }) =>
  ({ details, selfie }) =>
    processImage(DEFAULT_PROCESS_IMAGE_OPTIONS)({ mimeType: 'image/jpg', path: selfie }).then(path => [
      buildBodyItem('data', { ...details, tosAcceptance: true }),
      marshalFile({ extension: 'jpg', mimeType: 'image/jpg', prefix: 'selfie', path }),
    ])
