import { captureEvent } from '@sentry/react'
import { includes } from 'lodash/fp'
import { Action, Dispatch } from 'redux'

import { COLLECT_FOR_FREE_FLOW_FAILURE, COLLECT_JUNK_FLOW_FAILURE } from '../entities/advert/types/actions'
import {
  FETCH_CHAT_MESSAGES_UPDATES_FAILURE,
  RELOAD_ACTIVITY_HAS_UNREAD_MESSAGES_FAILURE,
} from '../entities/chat/types'
import { COLLECTION_PAYMENT_FAILURE } from '../entities/customer-advert/types/actions'
import { MAKE_JUNK_LOVER_OFFER_FAILURE } from '../entities/junk-reuser-advert/types'
import { PUBLISH_ADVERT_FLOW_FAILURE } from '../entities/new-advert/types'
import {
  isCollectionIntentNotConfirmedError,
  isCollectorNotApprovedError,
  isJunkLoverNotApprovedError,
  isNetworkProblem,
  isNoActivityToReloadHasUnreadMessagesError,
  isNoReloadHasUnreadMessagesActionError,
  isNoSessionError,
  isNotRoleError,
  REDIRECT_TO_ADD_CARD,
  REDIRECT_TO_ASK,
} from '../errors'
import { isFailureAction, isHttpError, isNotFound } from '../helpers'
import { FailureAction } from '../types/core'

// Genuine 404 response from BE of there are no new chat messages
const isChatMessagesNotFound = <T extends string>(action: FailureAction<T>) =>
  action.type === FETCH_CHAT_MESSAGES_UPDATES_FAILURE && isHttpError(action.payload) && isNotFound(action.payload)

// User is not logged in when publishing new advert.
const isPublishAdvertNoSession = <T extends string>(action: FailureAction<T>) =>
  action.type === PUBLISH_ADVERT_FLOW_FAILURE && isNoSessionError(action.payload)

// Activity not found when trying to clear unread messages for given advert on chat load.
// This is genuine as there may be no activities loaded i.e. chat was opened from notification.
const isReloadActivityHasUnreadMessages = <T extends string>(action: FailureAction<T>) =>
  action.type === RELOAD_ACTIVITY_HAS_UNREAD_MESSAGES_FAILURE &&
  (isNoActivityToReloadHasUnreadMessagesError(action.payload) || isNoReloadHasUnreadMessagesActionError(action.payload))

// This means user is presented with "confirm payment" modal.
const isCollectionIntentNotConfirmed = <T extends string>(action: FailureAction<T>) =>
  action.type === COLLECTION_PAYMENT_FAILURE && isCollectionIntentNotConfirmedError(action.payload)

// JunkLover is not approved when making an offer or trying to collect for free.
const isJunkLoverNotApproved = <T extends string>(action: FailureAction<T>) =>
  action.type === MAKE_JUNK_LOVER_OFFER_FAILURE && isJunkLoverNotApprovedError(action.payload)

// Collector is not approved when making an offer or trying to collect.
const isCollectorNotApproved = <T extends string>(action: FailureAction<T>) =>
  action.type === COLLECT_JUNK_FLOW_FAILURE && isCollectorNotApprovedError(action.payload)

// Non JunkLover trying to collect for free.
const isCollectForFreeNotJunkLover = <T extends string>(action: FailureAction<T>) =>
  action.type === COLLECT_FOR_FREE_FLOW_FAILURE && isNotRoleError(action.payload)

const isNetwork = <T extends string>(action: FailureAction<T>) => isNetworkProblem(action.payload)

const shouldSkipLog = <T extends string>(action: FailureAction<T>) =>
  isNetwork(action) ||
  isChatMessagesNotFound(action) ||
  isPublishAdvertNoSession(action) ||
  isReloadActivityHasUnreadMessages(action) ||
  isCollectionIntentNotConfirmed(action) ||
  isCollectorNotApproved(action) ||
  isJunkLoverNotApproved(action) ||
  isCollectForFreeNotJunkLover(action) ||
  includes(action.payload.message)([REDIRECT_TO_ADD_CARD, REDIRECT_TO_ASK])

export const sentryMiddleware = () => (next: Dispatch<Action>) => (action: Action) => {
  if (isFailureAction(action) && !shouldSkipLog(action)) {
    const { payload: error, type: transaction } = action
    const { message: errorMessage, name, ...rest } = error
    const payloadJson = JSON.stringify(rest)
    const message = `[${name}] ${errorMessage}` + (payloadJson === '{}' ? '' : ' | ' + payloadJson)

    captureEvent({ level: 'log', message, transaction })
  }

  return next(action)
}
