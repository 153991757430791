import { flow } from 'lodash/fp'

import { get, multipart } from '../../api'
import { CompoundApi } from '../../api-action'
import { responseToBody } from '../../helpers'
import { BodyItem, PriceRange, Transform } from '../../types/core'
import { marshalPriceSuggestionParams, unmarshalPriceSuggestionRange } from './marshaller'
import {
  NewAdvertFavouriteCollectors,
  NewAdvertFavouriteCollectorsResponse,
  PriceSuggestionParams,
  PriceSuggestionResponse,
  PublishAdvertParams,
  PublishedAdvert,
  SendPublishResponse,
  VideoUploadResponse,
} from './types'

export const priceSuggestionApi = (
  url: string,
  params: PriceSuggestionParams,
): CompoundApi<PriceSuggestionResponse, PriceRange> => [
  get(url, { params: marshalPriceSuggestionParams(params) }),
  flow(responseToBody, unmarshalPriceSuggestionRange),
]

export const publishAdvertApi: Transform<
  PublishAdvertParams,
  Transform<BodyItem[], CompoundApi<SendPublishResponse, PublishedAdvert>>
> =
  ({ url, userAgent }) =>
  body =>
    [multipart(url, body, { userAgent, timeout: 'extended', timeoutAlert: true }), responseToBody]

export const videoUploadApi = (url: string, body: BodyItem[]): CompoundApi<VideoUploadResponse, string> => [
  multipart(url, body, { timeout: 'extended' }),
  flow(responseToBody, body => body.id),
]

export const fetchFavouriteCollectorsApi: Transform<
  string,
  CompoundApi<NewAdvertFavouriteCollectorsResponse, NewAdvertFavouriteCollectors>
> = url => [get(url), flow(responseToBody, body => body.entities)]
