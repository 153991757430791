import React, { ComponentType } from 'react'

import { Color } from '../../../theme'
import { ComponentFactory, ToElements } from '../../../types/ui'

export interface IconProps {
  color: Color
}

export interface Props {
  Icon?: ComponentType<IconProps>
}

interface ElementsProps {
  Root: unknown
  Text: unknown

  Icon?: IconProps
}

type Elements = ToElements<ElementsProps>

const factory: ComponentFactory<Elements> = ({ Icon, Root, Text }) =>
  function UserCardInfo({ children }) {
    return (
      <Root>
        {Icon ? <Icon color="primary" /> : null}
        <Text>{children}</Text>
      </Root>
    )
  }

export default factory
