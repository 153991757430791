import {
  ApiConfig,
  BodyItem,
  configureApi,
  getCredentials,
  getSessionToken,
  isDefined,
  toApiResponse,
  Transform,
} from '@lovejunk/core'
import { getState } from 'core/store'
import { getDeviceId } from 'entities/app'
import { each, flow, map, partition } from 'lodash/fp'
import { apiUrl, getUserAgent } from 'utils/environment'

interface FileBodyItem extends BodyItem {
  fileName: string
}

interface BlobBodyItem {
  name: string
  value: Blob
  fileName: string
}

type FormDataItem = BodyItem | BlobBodyItem

const isFileBodyItem = (item: BodyItem): item is FileBodyItem => isDefined(item.fileName)

const toBlobBodyItem: Transform<FileBodyItem, Promise<BlobBodyItem>> = ({ value, ...rest }) =>
  fetch(value)
    .then(r => r.blob())
    .then(blob => ({ value: blob, ...rest }))

const toFormData: Transform<FormDataItem[], FormData> = bodyItems => {
  const formData = new FormData()

  each(({ name, value, fileName }) =>
    fileName ? formData.append(name, value, fileName) : formData.append(name, value),
  )(bodyItems)

  return formData
}

export const apiConfig: ApiConfig = {
  features: [
    'FreeReuse',
    'MessageAttachments',
    'MultipleCollectorOffers',
    'MultiReuseOffer',
    'NoReuseOfferWithdrawal',
    'OtpV2',
    'ReuseOfferChat',
    'ReuserOffersTab',
    'TipperTippingSiteDetails',
  ],
  fetch: ({ url, ...requestInit }) => fetch(url, requestInit).then(toApiResponse),
  fetchMultipart: ({ url, body, headers }) => {
    const [filesBodyItems, dataBodyItems] = partition<BodyItem, FileBodyItem>(isFileBodyItem)(body)

    return Promise.all(map(toBlobBodyItem)(filesBodyItems))
      .then(transformedFilesBodyItems => [...transformedFilesBodyItems, ...dataBodyItems])
      .then(toFormData)
      .then(body => fetch(url, { body, headers, method: 'POST' }))
      .then(toApiResponse)
  },
  getCredentials: flow(getState, getCredentials),
  getIdentifier: flow(getState, getDeviceId),
  getSessionToken: flow(getState, getSessionToken),
  getUserAgent,
  platform: 'web',
  url: apiUrl,
}

export default () => configureApi(apiConfig)
