import { Get } from '../../types/core'

interface State {
  isModalOpen: boolean
}

export const supportRequestDefaultState: Get<State> = () => ({
  isModalOpen: false,
})

export default State
