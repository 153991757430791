import { flow, join, takeRight } from 'lodash/fp'

import { isDefined } from '../../helpers'
import { Identity, Price, PriceRange } from '../../types/core'
import { PhotoPayload, SavePhotoPayload } from './types'

export const isSavePhotoPayload = (payload: PhotoPayload): payload is SavePhotoPayload =>
  isDefined((payload as SavePhotoPayload).assetPath)

interface CounterOfferValid {
  price: Price
  valid: true
}

interface CounterOfferInvalid {
  error: string
  valid: false
}

export type CounterOfferValidationResult = CounterOfferValid | CounterOfferInvalid

export const isCounterOfferValid = (result: CounterOfferValidationResult): result is CounterOfferValid => result.valid

export const validateCounterOffer = (
  counterOffer: string,
  { start: { amount: min, currency }, end: { amount: max } }: PriceRange,
): CounterOfferValidationResult => {
  const amount = parseInt(counterOffer, 10)

  const isNumber = !isNaN(amount)
  const isInRange = min <= amount && amount <= max
  const isDiscrete = amount % 5 === 0

  return isNumber && isInRange && isDiscrete
    ? { valid: true, price: { amount, currency } }
    : { valid: false, error: isNumber && isInRange ? 'Not a multiple of 5' : 'Invalid amount' }
}

export const truncateLicense: Identity<string> = flow(takeRight(4), join(''), i => '...' + i)
