import ActivitiesState, { Activity, defaultState as activitiesDefaultState, initialActivity } from '../activities/state'
import { OfferItem } from '../activities/types'

interface State extends ActivitiesState {
  offers: Activity<OfferItem>
}

export const defaultState: State = {
  ...activitiesDefaultState,
  offers: initialActivity,
}

export default State
