import { Action } from 'redux'

import { FailureAction, Nullable, PayloadAction, Postcode, Price, Response } from '../../types/core'

export const FETCH_FREEGLE_ADVERT_FAILURE = 'FETCH_FREEGLE_ADVERT_FAILURE'
export const FETCH_FREEGLE_ADVERT_START = 'FETCH_FREEGLE_ADVERT_START'
export const FETCH_FREEGLE_ADVERT_SUCCESS = 'FETCH_FREEGLE_ADVERT_SUCCESS'
export const SET_FREEGLE_HAS_POSTCODE = 'SET_FREEGLE_HAS_POSTCODE'

export type FetchFreegleAdvertStart = Action<typeof FETCH_FREEGLE_ADVERT_START>
export type FetchFreegleAdvertSuccess = PayloadAction<typeof FETCH_FREEGLE_ADVERT_SUCCESS, FetchFreegleAdvertResponse>
export type FetchFreegleAdvertFailure = FailureAction<typeof FETCH_FREEGLE_ADVERT_FAILURE>
export type SetFreegleHasPostcode = PayloadAction<typeof SET_FREEGLE_HAS_POSTCODE, Nullable<boolean>>

export type FreegleFailureType = 'outsidecoverage'

export interface FreegleAdvertErrorData {
  message: string
  meta: {
    url: string
  }
  postcode: string
  type: FreegleFailureType
}

export interface FreegleAdvertImage {
  isPrimary: boolean
  path: string
}

export interface FetchFreegleAdvertResponse {
  freegleId: string
  images: FreegleAdvertImage[]
  isResidential: boolean
  isReusable: boolean
  price: Price

  postcode?: Postcode
  title?: string
}

export interface FetchFreegleAdvertParams {
  freegleId: string
  signature: string
}

export type FetchFreegleAdvertResult = FetchFreegleAdvertFailure | FetchFreegleAdvertSuccess
export type FetchFreegleAdvert = FetchFreegleAdvertResult | FetchFreegleAdvertStart

export type GetFreegleAdvertResponse = Response<FetchFreegleAdvertResponse>

export type FreegleAction = FetchFreegleAdvert | SetFreegleHasPostcode
