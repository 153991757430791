import { ChatAdvertProps } from '@lovejunk/core'
import NavigationPath from 'navigation/paths'
import React, { FC } from 'react'
import { styled } from 'styled'

import {
  Body,
  BodyFooter,
  BodyHeader,
  Container as BaseContainer,
  ImageContainer as BaseImageContainer,
  Postcode,
} from '../Adverts/Item'
import Image from '../Adverts/Item/Image'
import Title from '../Adverts/Item/Title'
import Link from '../Link'

const Advert: FC<ChatAdvertProps> = ({ imageUrl, onPress, postcode, price, reference, title }) => (
  <Link color="black" params={{ seoReference: reference }} onPress={onPress} to={NavigationPath.Advert}>
    <Container>
      <ImageContainer>{imageUrl && title && <Image imageUrl={imageUrl} title={title} />}</ImageContainer>
      <Body>
        <BodyHeader>
          <Title price={price} title={title} />
        </BodyHeader>
        {postcode ? <BodyFooter>{postcode ? <Postcode>{postcode}</Postcode> : null}</BodyFooter> : null}
      </Body>
    </Container>
  </Link>
)

const StyledDiv = styled.div`
  min-width: 65%;
`

export const AdvertContainer: FC<any> = ({ children }) => <StyledDiv>{children}</StyledDiv>

const Container = styled(BaseContainer)`
  border: none;
  padding: 0.5em;

  &:hover {
    box-shadow: none;
  }
`

const ImageContainer = styled(BaseImageContainer)`
  width: 6em;
  height: 6em;
`

export default Advert
