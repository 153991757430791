import { chatIconFactory as factory } from '@lovejunk/core'
import { FaRegComment } from 'react-icons/fa'
import { styled } from 'styled'

import IconButton from './button/IconButton'
import Row from './containers/Row'
import Dot from './Dot'

export default factory({
  Button: styled(IconButton).attrs({
    ReactIcon: FaRegComment,
    size: 1.5,
  })`
    padding: 1em;
  `,
  Dot: styled(Dot).attrs({ size: 0.75 })`
    pointer-events: none;
    position: absolute;
    top: 10px;
    right: 6px;
  `,
  Root: styled(Row)`
    position: relative;
  `,
})
