import { trim } from 'lodash/fp'

import { action, failureAction, payloadAction } from '../../action-factory'
import apiAction, { ActionBundle } from '../../api-action'
import { ActionFactory, FailureActionFactory, PayloadActionFactory } from '../../types/core'
import { StatelessPayloadThunk } from '../../types/thunk'
import { fetchAdvert } from '../advert/actions'
import { makeCounterOfferApi, withdrawCounterOfferApi } from '../collector-advert/api'
import {
  AcceptJobResponseBody,
  CollectorReceipt,
  MakeCounterOfferPayload,
  RemovePhotoPayload,
  SavePhotoPayload,
  WithdrawCounterOfferPayload,
} from './types'
import {
  ACCEPT_JOB_FAILURE,
  ACCEPT_JOB_FLOW_FAILURE,
  ACCEPT_JOB_FLOW_START,
  ACCEPT_JOB_FLOW_SUCCESS,
  ACCEPT_JOB_START,
  ACCEPT_JOB_SUCCESS,
  AcceptJobFailure,
  AcceptJobFlowFailure,
  AcceptJobFlowStart,
  AcceptJobFlowSuccess,
  AcceptJobStart,
  AcceptJobSuccess,
  CLEAR_ACCEPT_JOB_ERROR,
  ClearAcceptJobError,
  GET_COLLECTOR_RECEIPT_FAILURE,
  GET_COLLECTOR_RECEIPT_START,
  GET_COLLECTOR_RECEIPT_SUCCESS,
  GetCollectorReceiptFailure,
  GetCollectorReceiptStart,
  GetCollectorReceiptSuccess,
  MAKE_COUNTER_OFFER_FAILURE,
  MAKE_COUNTER_OFFER_START,
  MAKE_COUNTER_OFFER_SUCCESS,
  MakeCounterOfferFailure,
  MakeCounterOfferResult,
  MakeCounterOfferStart,
  MakeCounterOfferSuccess,
  REMOVE_AFTER_PHOTO,
  REMOVE_BEFORE_PHOTO,
  RemoveAfterPhoto,
  RemoveBeforePhoto,
  SAVE_AFTER_PHOTO,
  SAVE_BEFORE_PHOTO,
  SaveAfterPhoto,
  SaveBeforePhoto,
  SET_ACCEPT_JOB_CONFIRMED,
  SET_ACCEPT_JOB_INTENT,
  SET_COLLECTOR_NOT_APPROVED,
  SetAcceptJobConfirmed,
  SetAcceptJobIntent,
  SetCollectorNotApproved,
  WITHDRAW_COUNTER_OFFER_FAILURE,
  WITHDRAW_COUNTER_OFFER_START,
  WITHDRAW_COUNTER_OFFER_SUCCESS,
  WithdrawCounterOffer,
  WithdrawCounterOfferFailure,
  WithdrawCounterOfferStart,
  WithdrawCounterOfferSuccess,
} from './types/actions'

export const setCollectorNotApproved: PayloadActionFactory<
  SetCollectorNotApproved,
  SetCollectorNotApproved['payload']
> = payloadAction(SET_COLLECTOR_NOT_APPROVED)

export const clearAcceptJobError: ActionFactory<ClearAcceptJobError> = action(CLEAR_ACCEPT_JOB_ERROR)

export const setAcceptJobIntent: PayloadActionFactory<SetAcceptJobIntent, boolean> =
  payloadAction(SET_ACCEPT_JOB_INTENT)

export const setAcceptJobConfirmed: PayloadActionFactory<SetAcceptJobConfirmed, boolean> =
  payloadAction(SET_ACCEPT_JOB_CONFIRMED)

export const acceptJobFlowStart: ActionFactory<AcceptJobFlowStart> = action(ACCEPT_JOB_FLOW_START)
export const acceptJobFlowSuccess: ActionFactory<AcceptJobFlowSuccess> = action(ACCEPT_JOB_FLOW_SUCCESS)
export const acceptJobFlowFailure: FailureActionFactory<AcceptJobFlowFailure> = failureAction(ACCEPT_JOB_FLOW_FAILURE)

export const acceptJobActionBundle: ActionBundle<
  AcceptJobStart,
  AcceptJobSuccess,
  AcceptJobFailure,
  AcceptJobResponseBody
> = [action(ACCEPT_JOB_START), payloadAction(ACCEPT_JOB_SUCCESS), failureAction(ACCEPT_JOB_FAILURE)]

export const getCollectorReceiptActionBundle: ActionBundle<
  GetCollectorReceiptStart,
  GetCollectorReceiptSuccess,
  GetCollectorReceiptFailure,
  CollectorReceipt
> = [
  action(GET_COLLECTOR_RECEIPT_START),
  payloadAction(GET_COLLECTOR_RECEIPT_SUCCESS),
  failureAction(GET_COLLECTOR_RECEIPT_FAILURE),
]

export const makeCounterOfferFailure: FailureActionFactory<MakeCounterOfferFailure> =
  failureAction(MAKE_COUNTER_OFFER_FAILURE)

const makeCounterOfferActionBundle: ActionBundle<
  MakeCounterOfferStart,
  MakeCounterOfferSuccess,
  MakeCounterOfferFailure
> = [action(MAKE_COUNTER_OFFER_START), action(MAKE_COUNTER_OFFER_SUCCESS), makeCounterOfferFailure]

export const makeCounterOffer: StatelessPayloadThunk<MakeCounterOfferPayload, MakeCounterOfferResult> =
  ({ advertUrl, price, text, url }) =>
  dispatch =>
    dispatch(
      apiAction(makeCounterOfferActionBundle)(makeCounterOfferApi(url, { price, conditions: text && trim(text) })),
    ).then(action => dispatch(fetchAdvert(advertUrl)).then(() => action))

const withdrawCounterOfferActionBundle: ActionBundle<
  WithdrawCounterOfferStart,
  WithdrawCounterOfferSuccess,
  WithdrawCounterOfferFailure
> = [
  action(WITHDRAW_COUNTER_OFFER_START),
  action(WITHDRAW_COUNTER_OFFER_SUCCESS),
  failureAction(WITHDRAW_COUNTER_OFFER_FAILURE),
]

export const withdrawCounterOffer: StatelessPayloadThunk<WithdrawCounterOfferPayload, WithdrawCounterOffer> =
  ({ advertUrl, url }) =>
  dispatch =>
    dispatch(apiAction(withdrawCounterOfferActionBundle)(withdrawCounterOfferApi(url))).then(action =>
      dispatch(fetchAdvert(advertUrl)).then(() => action),
    )

export const removeAfterPhoto: PayloadActionFactory<RemoveAfterPhoto, RemovePhotoPayload> =
  payloadAction(REMOVE_AFTER_PHOTO)
export const removeBeforePhoto: PayloadActionFactory<RemoveBeforePhoto, RemovePhotoPayload> =
  payloadAction(REMOVE_BEFORE_PHOTO)

export const saveAfterPhoto: PayloadActionFactory<SaveAfterPhoto, SavePhotoPayload> = payloadAction(SAVE_AFTER_PHOTO)
export const saveBeforePhoto: PayloadActionFactory<SaveBeforePhoto, SavePhotoPayload> = payloadAction(SAVE_BEFORE_PHOTO)
