import { flow, isDate, lte, size, toString } from 'lodash/fp'

import { MAIN_TITLE_MAX_LENGTH } from '../../constants'
import { hasItems, isDefined, isNotEmpty, isNotNull, isPresent, undefineNull } from '../../helpers'
import { Asset, Nullable, Optional, Transform, Validation } from '../../types'
import { filterAssets } from '../../utils/assets'
import { ValidationErrorsInput } from '../../utils/validate-form'
import { LoadSize } from '../advert/types'
import { TimeSlot } from '../time-slots/types'
import { CollectionDate, ValidationField } from './types'

const addressValidation: Transform<boolean, Validation> = isManualAddress => value =>
  isManualAddress || isNotEmpty(value)

const address1Validation: Transform<boolean, Validation> = isManualAddress => value =>
  !isManualAddress || isNotEmpty(value)

const builderWasteValidation: Transform<Nullable<boolean>, Validation<Nullable<boolean>>> = reusable => value =>
  Boolean(reusable) || isNotNull(value)

const cityValidation: Transform<boolean, Validation> = isManualAddress => value => !isManualAddress || isNotEmpty(value)

const easyAccessReasonValidation: Transform<boolean, Transform<Nullable<boolean>, Validation<Optional<string>>>> =
  easyAccessVariant => easyAccess => value =>
    Boolean(easyAccess) || easyAccessVariant || isPresent(toString(value))

export const assetsValidation: Validation<Asset[]> = flow(filterAssets('image'), hasItems)

export const reusableValidation = isNotNull

export const summaryValidation: Transform<Nullable<boolean>, Validation> = reusable => value =>
  !reusable || isNotEmpty(value)

export const suggestedPriceRangeValidation = isNotNull

const postcodeValidation: Transform<boolean, Validation> = isManualAddress => value =>
  !isManualAddress || isPresent(value)

const maxLengthValidator: Transform<number, Validation> = maxLength => flow(size, lte, f => f(maxLength))

interface ValidationPayload {
  address1: string
  addressValue: string
  assets: Asset[]
  builderWaste: Nullable<boolean>
  city: string
  easyAccess: Nullable<boolean>
  easyAccessReason: string
  easyAccessVariant: boolean
  isManualAddress: boolean
  loadSize: Nullable<LoadSize>
  postcode: string
  residential: Nullable<boolean>
  reusable: Nullable<boolean>
  selectedDate: Nullable<CollectionDate>
  selectedTimeSlot: Nullable<TimeSlot>
  summary: string
  title: string
}

export const newAdvertValidation: Transform<ValidationPayload, ValidationErrorsInput<ValidationField>> = ({
  address1,
  addressValue,
  assets,
  builderWaste,
  city,
  easyAccess,
  easyAccessReason,
  easyAccessVariant,
  isManualAddress,
  loadSize,
  postcode,
  residential,
  reusable,
  selectedDate,
  selectedTimeSlot,
  summary,
  title,
}) => ({
  address: {
    validation: addressValidation(isManualAddress),
    value: addressValue,
  },
  address1: {
    validation: address1Validation(isManualAddress),
    value: address1,
  },
  assets: {
    validation: assetsValidation,
    value: assets,
    message: '1 image required',
  },
  builderWaste: {
    validation: builderWasteValidation(reusable),
    value: builderWaste,
  },
  city: {
    validation: cityValidation(isManualAddress),
    value: city,
  },
  date: {
    validation: isDefined,
    value: undefineNull(selectedDate),
  },
  easyAccess: {
    validation: isNotNull,
    value: easyAccess,
  },
  easyAccessReason: {
    validation: easyAccessReasonValidation(easyAccessVariant)(easyAccess),
    value: easyAccessReason,
  },
  loadSize: {
    validation: isNotNull,
    value: loadSize,
  },
  postcode: {
    validation: postcodeValidation(isManualAddress),
    value: postcode,
  },
  residential: {
    validation: isNotNull,
    value: residential,
  },
  reusable: {
    validation: reusableValidation,
    value: reusable,
  },
  summary: {
    validation: summaryValidation(reusable),
    value: summary,
  },
  timeSlot: {
    validation: value => isDefined(value) || !isDate(selectedDate),
    value: undefineNull(selectedTimeSlot),
  },
  title: [
    {
      validation: isNotEmpty,
      value: title,
    },
    {
      message: `Title can't be longer than ${MAIN_TITLE_MAX_LENGTH} characters`,
      validation: maxLengthValidator(MAIN_TITLE_MAX_LENGTH),
      value: title,
    },
  ],
})
