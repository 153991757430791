import React, { useCallback } from 'react'

import { ADD_IDENTIFIER_SUCCESS, AddIdentifierData, AddIdentifierResult } from '../../entities/login/types'
import { AuthIdentifierType, AuthIdentifierValue, Dispatch, DispatchAsync, NoOp } from '../../types/core'
import { ComponentFactory, ToElements } from '../../types/ui'
import { formatAuthIdentifierType } from '../../utils'

interface SuccessPayload {
  identifier: AuthIdentifierValue
  type: AuthIdentifierType
  otpLength: number
  url: string

  addAuthIdentifierUrl?: string
}

interface DispatchProps {
  addIdentifier: DispatchAsync<AddIdentifierData, AddIdentifierResult>
}

interface OwnProps {
  identifier: AuthIdentifierValue
  onSuccess: Dispatch<SuccessPayload>
  type: AuthIdentifierType

  url?: string
}

export type Props = DispatchProps & OwnProps

interface ButtonProps {
  onPress: NoOp
  text: string
}

interface LinkProps {
  identifier: AuthIdentifierValue
  type: AuthIdentifierType
}

interface ElementsProps {
  Button: ButtonProps
  Identifier: unknown
  Link: LinkProps
  Root: unknown
  Title: unknown
}

type Elements = ToElements<ElementsProps>

const factory: ComponentFactory<Elements, Props> =
  ({ Button, Identifier, Link, Root, Title }) =>
  ({ addIdentifier, identifier, onSuccess, type, url }) => {
    const onPress = useCallback(
      () =>
        addIdentifier({ identifier, type, url }).then(action => {
          if (action.type === ADD_IDENTIFIER_SUCCESS) {
            const {
              actions: { verifyIdentifier },
              otpLength,
            } = action.payload

            if (verifyIdentifier)
              onSuccess({ addAuthIdentifierUrl: url, identifier, otpLength, type, url: verifyIdentifier.url })
          }
        }),
      [addIdentifier, identifier, onSuccess, type, url],
    )

    return (
      <Root>
        <Title>
          Verify <Identifier>{identifier}</Identifier> as your {formatAuthIdentifierType(type)}
        </Title>
        <Button onPress={onPress} text="Verify" />
        <Link identifier={identifier} type={type}>
          Change {type}
        </Link>
      </Root>
    )
  }

export default factory
