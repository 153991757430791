import { noop, upperFirst } from 'lodash/fp'
import React, { ReactNode, useCallback, useMemo } from 'react'

import { BackToChat } from '../../entities/account/types'
import { isAuthIdentifierNotVerified } from '../../entities/otp/helpers'
import { AuthIdentifierVerificationPayload } from '../../entities/otp/types'
import { isNotNull } from '../../helpers'
import { Dispatch, NoOp, Nullable, Transform } from '../../types/core'
import { ComponentFactory, ToElements } from '../../types/ui'
import { formatAuthIdentifierType } from '../../utils'
import { Mode } from './types'

interface DispatchProps {
  dismiss: NoOp
}

export interface StateProps {
  isSupplierSignup: boolean
  payload: Nullable<AuthIdentifierVerificationPayload>
}

interface OwnProps {
  navigateBack: Dispatch<BackToChat>
  navigateToAccount: NoOp
  navigateToHome: NoOp

  isSupplierSignup?: boolean
}

export type ExternalProps = DispatchProps & StateProps

type Props = ExternalProps & OwnProps

interface RootProps {
  mode: Mode
  onPress: NoOp
  title: string
  visible: boolean
}

interface SupportEmailLinkProps {
  subject: string
}

interface ElementsProps {
  Root: RootProps
  SupportEmailLink: SupportEmailLinkProps
}

type Elements = ToElements<ElementsProps>

const factory: ComponentFactory<Elements, Props> =
  ({ Root, SupportEmailLink }) =>
  ({ dismiss, isSupplierSignup, navigateBack, navigateToAccount, navigateToHome, payload }) => {
    const { backToChat, error, locked, type } = isNotNull(payload)
      ? isAuthIdentifierNotVerified(payload)
        ? payload
        : { ...payload, error: undefined }
      : { backToChat: undefined, error: undefined, locked: undefined, type: undefined }

    const mode = useMemo<Mode>(() => (error ? 'error' : 'success'), [error])
    const verified = useMemo<string>(
      () => (error ? (isSupplierSignup ? 'already registered' : 'not verified') : 'verified'),
      [error, isSupplierSignup],
    )
    const title = useMemo<string>(() => (type ? `${upperFirst(type)} ${verified}` : ''), [type, verified])
    const content = useMemo<ReactNode>(
      () =>
        error ? (
          <>
            This {type} is already in use by another user, please contact{' '}
            <SupportEmailLink subject="Auth identifier verification." />.
          </>
        ) : type ? (
          `Your ${formatAuthIdentifierType(type)} has been verified.`
        ) : (
          ''
        ),
      [error, type],
    )

    const createNavigateBack = useCallback<Transform<BackToChat, NoOp>>(
      backToChat => () => navigateBack(backToChat),
      [navigateBack],
    )
    const navigate = useMemo(
      () =>
        backToChat
          ? createNavigateBack(backToChat)
          : locked
          ? error
            ? noop
            : navigateToHome
          : error && isSupplierSignup
          ? noop
          : navigateToAccount,
      [backToChat, createNavigateBack, error, isSupplierSignup, locked, navigateToAccount, navigateToHome],
    )
    const onPress = useCallback(() => {
      navigate()
      dismiss()
    }, [dismiss, navigate])

    return (
      <Root mode={mode} onPress={onPress} title={title} visible={isNotNull(payload)}>
        {content}
      </Root>
    )
  }

export default factory
