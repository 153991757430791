import React from 'react'

import { formatDurationInSeconds } from '../../entities/advert/helpers'
import { ComponentFactory, ToElements } from '../../types/ui'
import { Props as CollectionTimeProps } from './CollectionTime'

interface ElementsProps {
  CollectionTime: CollectionTimeProps
  MatchedIn: unknown
  Root: unknown
  Views: unknown
}

type Elements = ToElements<ElementsProps>

export interface OwnProps extends CollectionTimeProps {
  matchedTimeInSeconds?: number
  views?: number
}

type Props = OwnProps

const factory: ComponentFactory<Elements, Props> = ({ CollectionTime, MatchedIn, Root, Views }) =>
  function SecondaryRow({ matchedTimeInSeconds, views, ...timeDetails }) {
    return (
      <Root>
        <CollectionTime {...timeDetails} />
        {/* note this implicit conversion is on purpose, as we don't display if 0 */}
        {matchedTimeInSeconds ? (
          <MatchedIn>matched in {formatDurationInSeconds(matchedTimeInSeconds)}</MatchedIn>
        ) : views ? (
          <Views>{views}</Views>
        ) : null}
      </Root>
    )
  }

export default factory
