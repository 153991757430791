import Reducer from '../../types/reducer'
import { timeSlotsDataLens } from './lens'
import State, { timeSlotsDefaultState as defaultState } from './state'
import { GET_TIME_SLOTS_SUCCESS } from './types'

const reducer: Reducer<State> = (state = defaultState(), action) => {
  switch (action.type) {
    case GET_TIME_SLOTS_SUCCESS:
      return timeSlotsDataLens(state).set(action.payload)
    default:
      return state
  }
}

export default reducer
