import { configureSignup, SignupConfig } from '@lovejunk/core'
import NavigationPath from 'navigation/paths'

const signupConfig: SignupConfig = {
  triggerScreens: {
    publish: NavigationPath.NewAdvert,
  },
}

export default () => {
  configureSignup(signupConfig)
}
