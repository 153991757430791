import { get } from '../../api'
import { CompoundApi } from '../../api-action'
import { responseToBody } from '../../helpers'
import { Transform } from '../../types/core'
import {
  CollectorProfile,
  GetCollectorProfileResponse,
  GetCollectorReviewsData,
  GetCollectorReviewsParams,
  GetCollectorReviewsResponse,
} from './types'

export const getCollectorProfileApi: Transform<
  string,
  CompoundApi<GetCollectorProfileResponse, CollectorProfile>
> = url => [get(url), responseToBody]

export const getCollectorReviewsApi = (
  url: string,
  { pageSize }: GetCollectorReviewsParams,
): CompoundApi<GetCollectorReviewsResponse, GetCollectorReviewsData> => [
  get(url, { params: { page_size: pageSize } }),
  ({ body, meta }) => ({ items: body.entities, nextUrl: meta.next_url }),
]
