import { flow } from 'lodash/fp'

import { post } from '../../api'
import { CompoundApi, SimpleApi } from '../../api-action'
import { responseToBody } from '../../helpers'
import { SessionToken, Transform } from '../../types/core'
import { VerifyOtpParams, VerifyOtpResponse } from './types'

export const verifyOtpApi: Transform<
  string,
  Transform<VerifyOtpParams, CompoundApi<VerifyOtpResponse, SessionToken>>
> = url => params => [post(url, params), flow(responseToBody, ({ token }) => token)]

export const verifyAuthIdentityOtpApi: Transform<string, Transform<VerifyOtpParams, SimpleApi>> = url => params =>
  post(url, params)
