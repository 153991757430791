import { flow, isError } from 'lodash/fp'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { formatAdvertShortPostcode } from '../../entities/advert/helpers/role'
import { AdvertAsset, LoadSize, TimeDetails } from '../../entities/advert/types'
import {
  bookSelectedCollector,
  isDateTimeSlotPayload,
  isValidNewAdvertFixedTimeSlot,
  isValidTimeSlotPayload,
} from '../../entities/new-advert/helpers'
import {
  NewAdvertAddress,
  NewAdvertCollectionPoint,
  NewAdvertDetails,
  NewAdvertFavouriteCollector,
  NewAdvertFavouriteCollectors as FavouriteCollectors,
  NewAdvertFixedTimeSlot,
  NullifiedTimeSlotPayload,
} from '../../entities/new-advert/types'
import { TimeSlotFixedName } from '../../entities/time-slots/types'
import { isUnsupportedPostcodeError } from '../../errors'
import { hasItems, isDefined, isPresent, undefineNull } from '../../helpers'
import { marshallTimeSlot } from '../../marshallers/time-slot'
import { Color } from '../../theme'
import { Dispatch, LatLng, NoOp, Nullable, Optional } from '../../types/core'
import { FromPostcode } from '../../types/geocoder'
import { ComponentFactory, ToElements } from '../../types/ui'
import { OwnProps as SecondaryRowProps } from '../advert/SecondaryRow'

interface AdditionalDetailsProps {
  easyAccess: Nullable<boolean>

  easyAccessReason?: string
  summary?: string
}

interface CarouselProps {
  id: string
  images: AdvertAsset[]
  local?: boolean
  videos?: AdvertAsset[]
}

interface DetailsItemsProps {
  builderWaste?: boolean
  easyAccess?: boolean
  lightweight?: boolean
  residential?: boolean
  reusable?: boolean
  size?: LoadSize
}

interface DetailsProps {
  hideTitle?: boolean
}

interface EditLinkProps {
  postcode?: string
}

interface FavouriteCollectorSelectProps {
  favouriteCollectors: FavouriteCollectors
}

interface FavouriteCollectorJobButtonProps {
  onPress: NoOp

  color?: Color
  underline?: boolean
}

interface MapProps {
  latLng?: LatLng
}

interface SubmitButtonProps {
  onPress: NoOp
  text: string

  progress?: boolean
}

export interface ElementsProps {
  AdditionalDetails: AdditionalDetailsProps
  Address: unknown
  Carousel: CarouselProps
  City: unknown
  CityRow: unknown
  Container: unknown
  Details: DetailsProps
  DetailsItems: DetailsItemsProps
  Divider: unknown
  EditLink: EditLinkProps
  FavouriteCollectorJobButton: FavouriteCollectorJobButtonProps
  FavouriteCollectorSelect: FavouriteCollectorSelectProps
  Map: MapProps
  Root: unknown
  SecondaryRow: SecondaryRowProps
  SubmitButton: SubmitButtonProps
  Title: unknown
  Ulez: unknown
}

type Elements = ToElements<ElementsProps>

interface DispatchProps {
  fetchFavouriteCollectors: Dispatch<string>
  unsupportedPostcode: Dispatch<Error>
}

interface NewAdvertStateProps {
  address: NewAdvertAddress
  images: AdvertAsset[]
  collectionPoint: NewAdvertCollectionPoint
  details: NewAdvertDetails
  favouriteCollectors: FavouriteCollectors
  fixedTimeSlot: Nullable<NewAdvertFixedTimeSlot>
  isConfirmingPublishing: boolean
  isReuseOnly: Nullable<boolean>
  isUlez: Nullable<boolean>
  reusable: Nullable<boolean>
  summary: string
  timeSlot: Nullable<NullifiedTimeSlotPayload>
  title: string

  selectedCollector?: NewAdvertFavouriteCollector
}

interface MetaStateProps {
  favouriteCollectorsUrl?: string
}

export type StateProps = NewAdvertStateProps & MetaStateProps

interface OwnProps {
  fromPostcode: FromPostcode
  onSubmit: NoOp
}

export type ExternalProps = DispatchProps & StateProps

type Props = ExternalProps & OwnProps

const factory: ComponentFactory<Elements, Props> = ({
  AdditionalDetails,
  Address,
  Carousel,
  City,
  CityRow,
  Container,
  Details,
  DetailsItems,
  Divider,
  EditLink,
  FavouriteCollectorJobButton,
  FavouriteCollectorSelect,
  Map,
  Root,
  SecondaryRow,
  SubmitButton,
  Title,
  Ulez,
}) =>
  function Summary({
    address: { city, postcode },
    images,
    collectionPoint: { easyAccess, easyAccessReason },
    details: { builderWaste, isLightweight, residential, size },
    favouriteCollectors,
    favouriteCollectorsUrl,
    fetchFavouriteCollectors,
    fixedTimeSlot,
    fromPostcode,
    isConfirmingPublishing,
    isReuseOnly,
    isUlez,
    onSubmit,
    reusable,
    selectedCollector,
    summary,
    timeSlot,
    title,
    unsupportedPostcode,
  }) {
    const [latLng, setLatLng] = useState<LatLng>()
    const [isFavouriteCollectorSelectVisible, setIsFavouriteCollectorSelectVisible] = useState<boolean>(false)
    const onFavouriteCollectorJobPress = useCallback(() => setIsFavouriteCollectorSelectVisible(true), [])

    const fixedTimeSlotChoice = useMemo<Optional<TimeSlotFixedName>>(
      () => (fixedTimeSlot && isValidNewAdvertFixedTimeSlot(fixedTimeSlot) ? fixedTimeSlot.timeSlot : undefined),
      [fixedTimeSlot],
    )

    const timeDetails = useMemo<Nullable<TimeDetails>>(
      () =>
        timeSlot && isValidTimeSlotPayload(timeSlot)
          ? {
              ...(isDateTimeSlotPayload(timeSlot)
                ? flow(marshallTimeSlot, ({ end: endAt, start: startAt }) => ({
                    endAt,
                    startAt,
                  }))(timeSlot)
                : { endAt: '', startAt: '', openTimeSlotChoice: timeSlot.date }),
              fixedTimeSlotChoice,
            }
          : null,
      [fixedTimeSlotChoice, timeSlot],
    )

    const submitButtonText = useMemo(
      () => (selectedCollector ? bookSelectedCollector(selectedCollector) : 'Publish now'),
      [selectedCollector],
    )

    useEffect(() => {
      if (!isPresent(postcode)) return

      fromPostcode(postcode)
        .then(setLatLng)
        .catch(e => (isError(e) && isUnsupportedPostcodeError(e) ? unsupportedPostcode(e) : undefined))
    }, [fromPostcode, postcode, unsupportedPostcode])

    useEffect(() => {
      if (isDefined(favouriteCollectorsUrl)) fetchFavouriteCollectors(favouriteCollectorsUrl)
    }, [favouriteCollectorsUrl, fetchFavouriteCollectors])

    return (
      <Root>
        <Carousel id="advert-summary" images={images} local />
        <Container>
          <Title>{title}</Title>
          {timeDetails && <SecondaryRow {...timeDetails} />}
          <Divider />
          <Details hideTitle />
          <DetailsItems
            builderWaste={undefineNull(builderWaste)}
            easyAccess={undefineNull(easyAccess)}
            lightweight={undefineNull(isLightweight)}
            residential={undefineNull(residential)}
            reusable={undefineNull(reusable)}
            size={undefineNull(size)}
          />
          <Divider />
          <AdditionalDetails easyAccess={easyAccess} easyAccessReason={easyAccessReason} summary={summary} />
          <Divider />
          {isDefined(latLng) ? <Map latLng={latLng} /> : null}
          <CityRow>
            <City>{city}</City>
            {isUlez ? <Ulez>(congestion zone)</Ulez> : null}
          </CityRow>
          <Address>{formatAdvertShortPostcode({ postcode })}</Address>
          <Divider />
          {hasItems(favouriteCollectors) &&
            !selectedCollector &&
            !isReuseOnly &&
            (isFavouriteCollectorSelectVisible ? (
              <FavouriteCollectorSelect favouriteCollectors={favouriteCollectors} />
            ) : (
              <FavouriteCollectorJobButton color="secondary" onPress={onFavouriteCollectorJobPress} underline>
                favourite collector job
              </FavouriteCollectorJobButton>
            ))}
          <SubmitButton text={submitButtonText} onPress={onSubmit} progress={isConfirmingPublishing} />
          <EditLink postcode={postcode}>Edit</EditLink>
        </Container>
      </Root>
    )
  }

export default factory
