import Reducer from '../../types/reducer'
import State, { accountDefaultState } from './state'

export const accountReducer: Reducer<State> = (state = accountDefaultState, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default accountReducer
