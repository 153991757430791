import { IoImagesOutline } from 'react-icons/io5'
import { styled } from 'styled'
import { isMobile } from 'utils/environment'

import AssetsRow, { IconProps } from '../AssetsRow'
import ReactIconComponent from '../ReactIcon'

const Icon = styled(ReactIconComponent).attrs({ Icon: IoImagesOutline, size: isMobile ? 1.5 : 2 })<IconProps>``

const AssetButton = styled(AssetsRow).attrs({ hideAssets: true, Icon, mediaType: 'image' })``

export default AssetButton
