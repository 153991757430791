import { createImgixParams } from '@lovejunk/core'
import React, { FC } from 'react'
import { styled } from 'styled'
import { isMobile } from 'utils/environment'

interface Props {
  imageUrl: string
  title: string
}

const ActivityItemImage: FC<Props> = ({ imageUrl, title }) => (
  <Root src={addParams(imageUrl)} alt={`${title} for removal and disposal`} />
)

const addParams = createImgixParams(140)

const borderRadius = 0.5 * Number(!isMobile) + 'em'

export const Root = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: ${borderRadius};
  height: 100%;
  width: 100%;
`

export default ActivityItemImage
