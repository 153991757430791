import { flow } from 'lodash/fp'

import { get } from '../../api'
import { CompoundApi } from '../../api-action'
import { responseToBody } from '../../helpers'
import { Tags, TagsParams, TagsResponse } from './types'

export const getTagsApi = (url: string, params?: TagsParams): CompoundApi<TagsResponse, Tags> => [
  get(url, { params }),
  flow(responseToBody, body => body.entities),
]
