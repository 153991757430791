import { flow, map } from 'lodash/fp'

import { get, multipart, post, put } from '../../api'
import { CompoundApi, SimpleApi } from '../../api-action'
import { responseToBody } from '../../helpers'
import DateMarshaller from '../../marshallers/date'
import { BodyItem, Transform } from '../../types/core'
import { chatMessageMarshaller } from './marshaller'
import {
  ChatId,
  ChatMessagesVersion,
  ChatNavigationBody,
  ChatNavigationResponse,
  CreateChatThreadBody,
  CreateChatThreadResponse,
  FetchChatMessagesBody,
  FetchChatMessagesResponse,
  FetchChatMessagesUpdatesBody,
  FetchChatMessagesUpdatesResponse,
  GetAttachmentUrlResponse,
  ReloadActivityHasUnreadMessagesParams,
  ReloadActivityHasUnreadMessagesResponse,
  ReloadActivityHasUnreadMessagesSuccessPayload,
  ReloadFavouritesHasUnreadMessagesPayload,
  ReloadFavouritesHasUnreadMessagesResponse,
  ReloadFavouritesHasUnreadMessagesSuccessPayload,
  ReloadGlobalHasUnreadMessagesResponse,
  ReloadHasUnreadMessagesResponse,
  SendChatMessageResponse,
  SentChatMessageBody,
  ViewPhoneResponse,
} from './types'

export const chatNavigationApi: Transform<
  string,
  Transform<ChatId, CompoundApi<ChatNavigationResponse, ChatNavigationBody>>
> = url => id => [get(url, { params: { id } }), flow(responseToBody)]

const unmarshalMessages = map(chatMessageMarshaller.unmarshal)

export const createChatThreadApi: Transform<
  string,
  CompoundApi<CreateChatThreadResponse, CreateChatThreadBody>
> = url => [
  post(url),
  flow(responseToBody, ({ messages, ...rest }) => ({ ...rest, messages: unmarshalMessages(messages) })),
]

export const fetchChatMessagesApi: Transform<
  string,
  CompoundApi<FetchChatMessagesResponse, FetchChatMessagesBody>
> = url => [
  get(url),
  flow(responseToBody, ({ messages, ...rest }) => ({ ...rest, messages: unmarshalMessages(messages) })),
]

export const fetchChatMessagesUpdatesApi = (
  url: string,
  version: ChatMessagesVersion,
): CompoundApi<FetchChatMessagesUpdatesResponse, FetchChatMessagesUpdatesBody> => [
  get(url, { params: { version } }),
  flow(responseToBody, ({ messages, ...rest }) => ({
    messages: map(chatMessageMarshaller.unmarshal)(messages),
    ...rest,
  })),
]

export const getAttachmentUrl: Transform<string, Promise<string>> = signedUrl =>
  post<GetAttachmentUrlResponse>(signedUrl)
    .then(responseToBody)
    .then(({ url }) => url)

const parseChatMessageResponse: Transform<SendChatMessageResponse, SentChatMessageBody> = flow(
  responseToBody,
  ({ createdAt, sentAt, ...rest }) => ({
    createdAt: DateMarshaller.unmarshal(createdAt),
    sentAt: DateMarshaller.unmarshal(sentAt),
    ...rest,
  }),
)

export const sendChatMessageApi = (
  url: string,
  message: string,
): CompoundApi<SendChatMessageResponse, SentChatMessageBody> => [post(url, { message }), parseChatMessageResponse]

export const sendChatMessageWithAssetsApi =
  (url: string) =>
  (bodyItems: BodyItem[]): CompoundApi<SendChatMessageResponse, SentChatMessageBody> =>
    [multipart(url, bodyItems, { timeout: 'extended' }), parseChatMessageResponse]

export const reloadActivityHasUnreadMessagesApi: Transform<
  ReloadActivityHasUnreadMessagesParams,
  CompoundApi<ReloadActivityHasUnreadMessagesResponse, ReloadActivityHasUnreadMessagesSuccessPayload>
> = ({ advertId, url }) => [get(url), flow(responseToBody, body => ({ advertId, ...body }))]

export const reloadHasUnreadMessagesApi: Transform<
  string,
  CompoundApi<ReloadHasUnreadMessagesResponse, boolean>
> = url => [get(url), flow(responseToBody, body => body.hasUnreadMessages)]

export const reloadGlobalHasUnreadMessagesApi: Transform<
  string,
  CompoundApi<ReloadGlobalHasUnreadMessagesResponse, boolean>
> = url => [get(url), flow(responseToBody, body => body.hasUnreadMessages)]

export const reloadFavouritesHasUnreadMessagesApi: Transform<
  ReloadFavouritesHasUnreadMessagesPayload,
  CompoundApi<ReloadFavouritesHasUnreadMessagesResponse, ReloadFavouritesHasUnreadMessagesSuccessPayload>
> = ({ favouriteInteractions, url }) => [get(url), flow(responseToBody, body => ({ favouriteInteractions, ...body }))]

export const startConferenceCallApi: Transform<string, SimpleApi> = post

export const viewPhoneApi: Transform<string, CompoundApi<ViewPhoneResponse, string>> = url => [
  put(url),
  flow(responseToBody, body => body.phoneNumber),
]
