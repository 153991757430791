import { AppConfig, configureApp, getConfigurationLens as getConfiguration } from '@lovejunk/core'
import RootState from 'core/state'

export const appConfig: AppConfig<RootState> = {
  getConfiguration,
}

export default () => {
  configureApp(appConfig)
}
