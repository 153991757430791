import { flow } from 'lodash/fp'

import Reducer from '../../types/reducer'
import { COLLECT_FOR_FREE_FLOW_START } from '../advert/types/actions'
import { AUTHORISED } from '../signup/types'
import {
  advertRefLens,
  advertUrlLens,
  detailsLens,
  isNotApprovedLens,
  isSigningUpLens,
  selfieLens,
  showFeedbackLens,
} from './lens'
import State, { defaultState } from './state'
import {
  SAVE_JUNK_LOVER_DETAILS,
  SAVE_JUNK_LOVER_SELFIE,
  SET_JUNK_LOVER_NOT_APPROVED,
  SET_JUNK_LOVER_SIGNUP_FEEDBACK,
  SIGNUP_JUNK_LOVER_FAILURE,
  SIGNUP_JUNK_LOVER_START,
  SIGNUP_JUNK_LOVER_SUCCESS,
} from './types/actions'

const reducer: Reducer<State> = (state = defaultState, action) => {
  switch (action.type) {
    case SAVE_JUNK_LOVER_DETAILS:
      return detailsLens(state).set(action.payload)
    case SAVE_JUNK_LOVER_SELFIE:
      return selfieLens(state).set(action.payload)
    case SET_JUNK_LOVER_NOT_APPROVED:
      return isNotApprovedLens(state).set(action.payload)
    case SIGNUP_JUNK_LOVER_START:
      return isSigningUpLens(state).set(true)
    case SIGNUP_JUNK_LOVER_FAILURE:
      return isSigningUpLens(state).set(false)
    case SIGNUP_JUNK_LOVER_SUCCESS:
      return flow(
        isSigningUpLens,
        l => l.set(false),
        showFeedbackLens,
        l => l.set(true),
      )(state)
    case SET_JUNK_LOVER_SIGNUP_FEEDBACK:
      return showFeedbackLens(state).set(action.payload)
    case COLLECT_FOR_FREE_FLOW_START:
      return flow(
        advertRefLens,
        l => l.set(action.payload.advertRef),
        advertUrlLens,
        l => l.set(action.payload.advertUrl),
      )(state)
    case AUTHORISED:
      return flow(
        advertRefLens,
        l => l.set(null),
        advertUrlLens,
        l => l.set(null),
      )(state)
    default:
      return state
  }
}

export default reducer
