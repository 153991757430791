import './index.css'

import { Dispatch } from '@lovejunk/core'
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js'
import { StripeCardNumberElement, StripeCardNumberElementOptions } from '@stripe/stripe-js'
import React, { useCallback, useMemo } from 'react'
import { styled, ThemeProps } from 'styled'
import { withTheme } from 'styled-components'
import { ClassNameProps } from 'types/ui'
import { withSpacing } from 'utils/css'

import Row from '../../containers/Row'
import Label from '../../Label'

type Props = ThemeProps & ClassNameProps

const CardInput = ({ className, theme: { colors } }: Props) => {
  const options = useMemo<StripeCardNumberElementOptions>(
    () => ({
      style: {
        base: {
          fontSize: '16px',
          color: colors.black,
          '::placeholder': {
            color: colors.greyText,
          },
        },
        invalid: {
          color: colors.required,
        },
      },
    }),
    [colors],
  )
  const inlineOptions = useMemo(
    () => ({ ...options, classes: { base: 'StripeElement StripeElementInline' } }),
    [options],
  )

  const onReady = useCallback<Dispatch<StripeCardNumberElement>>(element => element.focus(), [])

  return (
    <Root className={className}>
      <Label title="Card information" />
      <Elements>
        <CardNumberElement options={options} onReady={onReady} />
        <ExpiryCvcRow>
          <CardExpiryElement options={inlineOptions} />
          <CardCvcElement options={inlineOptions} />
        </ExpiryCvcRow>
      </Elements>
    </Root>
  )
}

const Root = styled.div``
const Elements = styled.div``
const ExpiryCvcRow = styled(Row)`
  ${withSpacing()}
  padding-top: 0.5em;
`

export default withTheme(CardInput)
