import { Transform } from '../../types/core'

export interface LoginErrorData {
  message: string
  title: string
}

const tooManyAttemptsData: Transform<string, LoginErrorData> = action => ({
  message: `You tried to ${action} too many times. Please try again in 30 minutes.`,
  title: `Too many ${action} attempts`,
})

export const tooManyLoginAttemptsData = tooManyAttemptsData('login')
export const tooManySignupAttemptsData = tooManyAttemptsData('signup')
export const recaptchaErrorData: LoginErrorData = {
  message: 'Recaptcha verification failed.',
  title: 'ReCaptcha error',
}
