import React, { useCallback } from 'react'

import { calculatePrices, formatPricesOffer, showCollectionCharge } from '../../entities/advert/helpers/prices'
import { PricesProps } from '../../entities/advert/types/prices'
import { hasDataSupplierCapability } from '../../entities/meta/lens'
import { isDefined } from '../../helpers'
import { Identity } from '../../types/core'
import { ComponentFactory, ToElements } from '../../types/ui'
import { RowContent } from '../../utils/css'

interface ElementsProps {
  BaseOfferPrice: unknown
  CounterOfferPrice: unknown
  Root: RowContent
}

type Elements = ToElements<ElementsProps>

const factory: ComponentFactory<Elements, PricesProps> = ({ BaseOfferPrice, CounterOfferPrice, Root }) =>
  function PriceRow(props) {
    const { baseOffer, counterOffer } = calculatePrices(props)
    const formattedBaseOffer = formatPricesOffer(baseOffer)
    const formattedCounterOffer = counterOffer && formatPricesOffer(counterOffer)
    const { meta, status } = props
    const isSupplier = hasDataSupplierCapability(meta)
    const baseOfferText = useCallback<Identity<string>>(
      formattedPrice => formattedPrice + (showCollectionCharge({ isSupplier, status }) ? ' collection charge' : ''),
      [isSupplier, status],
    )

    return (
      <Root leftSide={isDefined(formattedBaseOffer)} rightSide={isDefined(formattedCounterOffer)}>
        {formattedBaseOffer ? <BaseOfferPrice>{baseOfferText(formattedBaseOffer)}</BaseOfferPrice> : null}
        {formattedCounterOffer ? <CounterOfferPrice>{formattedCounterOffer}</CounterOfferPrice> : null}
      </Root>
    )
  }

export default factory
