import { Dispatch, isDefined, isPresent, Transform } from '@lovejunk/core'
import { flow, identity, isEmpty, isNumber, size, toString } from 'lodash/fp'
import React, {
  ChangeEventHandler,
  ComponentPropsWithRef,
  CSSProperties,
  FocusEventHandler,
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { isSafari } from 'react-device-detect'
import { isMobile } from 'utils/environment'

import { css, styled } from '../../styled'
import { removeLeading } from '../../utils'
import Column from '../containers/Column'
import Title from '../Label'

interface HtmlInputProps extends Omit<ComponentPropsWithRef<'input'>, 'onChange'> {
  // override `input` props
  onChange?: Dispatch<string>
  value?: string
}

export interface Props extends HtmlInputProps {
  error?: string
  errorOutside?: boolean
  hideMaxLength?: boolean
  inputStyle?: CSSProperties
  nativePlaceholder?: string
  prefix?: string
  prefixStyle?: CSSProperties
  small?: boolean
  title?: string
  transform?: Transform<string, string>
}

const TextInput: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  {
    className,
    error,
    errorOutside = false,
    hideMaxLength,
    inputStyle,
    nativePlaceholder,
    onChange,
    placeholder,
    prefix,
    small,
    title,
    transform = identity,
    value,
    ...inputProps
  },
  ref,
) => {
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const { maxLength } = inputProps
  const isMaxLengthHidden = !maxLength || Boolean(hideMaxLength)

  const renderError = useMemo(() => error && <Error outside={errorOutside}>{error}</Error>, [error, errorOutside])

  const renderTitle = useMemo(
    () => isDefined(title) && isPresent(title) && <Title small={small} title={title} />,
    [small, title],
  )

  const renderPlaceholder = useMemo(
    () => placeholder && <Label floating={isFocused || !isEmpty(value)}>{placeholder}</Label>,
    [isFocused, placeholder, value],
  )

  const renderMaxLength = useMemo(
    () =>
      !isMaxLengthHidden && (
        <MaxLength>
          {size(isNumber(value) ? toString(value) : value)}/{maxLength}
        </MaxLength>
      ),
    [isMaxLengthHidden, maxLength, value],
  )

  const onInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    e => {
      if (isDefined(onChange))
        flow(isDefined(prefix) ? removeLeading(toString(prefix)) : identity, transform, onChange)(e.currentTarget.value)
    },
    [onChange, prefix, transform],
  )

  const onBlur = useCallback<FocusEventHandler<HTMLInputElement>>(() => setIsFocused(false), [setIsFocused])
  const onFocus = useCallback<FocusEventHandler<HTMLInputElement>>(() => setIsFocused(true), [setIsFocused])

  return (
    <div className={className}>
      {renderTitle}
      <Container className="LoveJunk-TextInput_container">
        {renderPlaceholder}
        {renderMaxLength}
        {renderError}
        <Input
          className="LoveJunk-TextInput_input"
          type="text"
          ref={ref}
          onBlur={onBlur}
          onChange={onInputChange}
          onFocus={onFocus}
          placeholder={nativePlaceholder}
          $small={small}
          style={inputStyle}
          value={(isEmpty(value) ? '' : toString(prefix)) + toString(value)}
          hasError={isDefined(error)}
          hasPlaceholder={!isEmpty(placeholder)}
          {...inputProps}
        />
      </Container>
    </div>
  )
}

const Container = styled(Column)`
  position: relative;
`

export interface InputProps {
  disabled?: boolean
  hasError?: boolean
  hasPlaceholder?: boolean
  $small?: boolean
}

export const inputCss = css<Pick<Props, 'inputStyle'> & InputProps>`
  appearance: none;
  padding: ${({ hasPlaceholder, $small }) =>
    hasPlaceholder ? `${isMobile ? 1.75 : 1.375}em 0.625em 0.625em` : $small ? '0.5em' : '1em 0.5em'};
  background-color: ${({ inputStyle }) => inputStyle?.backgroundColor || 'transparent'};
  border-color: ${({ hasError, theme: { colors } }) => (hasError ? colors.error : colors.greyBorder)};
  border-radius: 0.5em;
  border-style: solid;
  border-width: 1px;
  color: ${({ theme: { colors } }) => colors.text};
  font-family: Lato-Regular;
  font-size: 1.125em;
  outline: 0;
  transition: 0.1s border-color linear;
  // Required for input to not exceed available space - breaks chat footer if not present.
  width: 100%;
  z-index: 1;

  :focus {
    box-shadow: 0 0 0 1px ${({ theme: { colors } }) => colors.secondary};
    border-color: ${({ disabled, hasError, theme: { colors } }) => (disabled || hasError ? 'auto' : colors.secondary)};
  }

  &:disabled {
    // Fix for safari disabled input color being lighter than in other browsers.
    ${isSafari ? '-webkit-text-fill-color' : 'color'}: ${({ theme: { colors } }) => colors.greyText};
    opacity: 0.5;
  }
`

export const Input = styled.input<Pick<Props, 'inputStyle'> & InputProps>`
  ${inputCss}
`

interface LabelProps {
  floating: boolean
}

export const Label = styled.label<LabelProps>`
  color: ${({ theme: { colors } }) => colors.greyText};
  font-family: Lato-Regular;
  pointer-events: none;
  position: absolute;
  transform: ${({ floating }) => `scale(${floating ? 0.75 : 1})`};
  transform-origin: top left;
  transition: all 0.2s ease-out;
  inset: 0.125em 0.5em;
  border-top-left-radius: 0.5em;
  background-color: ${({ theme: { colors } }) => colors.white}ee;
`

export const MaxLength = styled.span`
  font-size: 13px;
  font-family: Lato-Regular;
  color: ${({ theme: { colors } }) => colors.greyText};
  position: absolute;
  right: 0.5em;
  bottom: 0.25em;
  background-color: ${({ theme: { colors } }) => colors.white}ee;
  border-bottom-right-radius: 0.5em;
`

interface ErrorProps {
  outside: boolean
}

const errorInsideCss = css`
  position: absolute;
  right: 0.5em;
  top: 0.5em;
`

const errorOutsideCss = css`
  margin-top: 0.25em;
  text-align: right;
`

export const Error = styled.p<ErrorProps>`
  margin: 0;
  color: ${({ theme: { colors } }) => colors.error};
  font-weight: bold;
  text-align: left;
  font-size: 12px;
  z-index: 100;

  ${({ outside }) => (outside ? errorOutsideCss : errorInsideCss)}
`

export default forwardRef(TextInput)
