import { CUSTOMER_REVIEWS_URL } from '@lovejunk/core'
import { ReactComponent as StarComponent } from 'assets/icons/star-2.svg'
import { times } from 'lodash'
import React, { FC, PropsWithChildren } from 'react'
import { styled } from 'styled'
import { ClassNameProps } from 'types/ui'
import { device, withSpacing } from 'utils/css'

import InfoBox, { Text } from './containers/InfoBox'
import Row from './containers/Row'
import ExternalLink from './ExternalLink'
import BaseText from './text/BaseText'

const renderStar = (index: number) => <Star key={index} />

interface Props extends ClassNameProps {
  author: string
}

const Testimonial: FC<PropsWithChildren<Props>> = ({ author, children, className }) => (
  <Root className={className} href={CUSTOMER_REVIEWS_URL} target="_self">
    <Content>
      <Stars>{times(5, renderStar)}</Stars>
      <Text>"{children}"</Text>
      <Author>by {author} (read more)</Author>
    </Content>
  </Root>
)

const Root = styled(ExternalLink)`
  display: block;
  text-decoration: none;
  margin-bottom: 1em;

  @media ${device.mobile} {
    padding: 1em;
  }
`

const Content = styled(InfoBox)`
  ${withSpacing(0.5, true)}

  cursor: pointer;
`

const Author = styled(BaseText)`
  color: ${({ theme: { colors } }) => colors.secondary};
  font-size: 0.875em;
`

const Stars = Row

const Star = styled(StarComponent)`
  ${withSpacing()}

  fill: ${({ theme: { colors } }) => colors.tertiary};

  width: 2em;
  height: 2em;
`

export default Testimonial
