import { entries, map } from 'lodash/fp'
import React, { ComponentType, useCallback, useMemo } from 'react'

import { hasItems, isDefined } from '../../helpers'
import { Asset, AssetPath, AssetType, Dispatch, NoOp, Transform } from '../../types/core'
import { ComponentFactory, Render, TestID, ToElements } from '../../types/ui'
import { assetType } from '../../utils/assets'

interface AssetContainerProps {
  testID?: TestID
}

interface AssetsContainerProps {
  limit: number
}

interface AssetProps {
  src: string
}

interface CloseButtonProps {
  onPress: NoOp

  testID?: TestID
}

export interface ElementsProps {
  AssetContainer: AssetContainerProps
  AssetsContainer: AssetsContainerProps
  CloseButton: CloseButtonProps
  Error: unknown
  Image: AssetProps
  Video: AssetProps
  Root: unknown
}

type Elements = ToElements<ElementsProps>

export interface Props {
  autoHide?: boolean
  error?: string
  items: Asset[]
  limit: number
  onRemove?: Dispatch<AssetPath>
}

const factory: ComponentFactory<Elements, Props> = ({
  AssetContainer,
  AssetsContainer,
  CloseButton,
  Error,
  Image,
  Root,
  Video,
}) =>
  function Assets({ autoHide = false, error, items, limit, onRemove }) {
    const createOnRemove = useCallback<Transform<AssetPath, NoOp>>(
      path => () => {
        onRemove && onRemove(path)
      },
      [onRemove],
    )

    const typeToAsset = useMemo<Record<AssetType, ComponentType<AssetProps>>>(
      () => ({ image: Image, video: Video }),
      [],
    )

    const renderAsset = useCallback<Render<[string, Asset]>>(
      ([index, asset]) => {
        const { path } = asset
        const type = assetType(asset)
        const Asset = isDefined(type) ? typeToAsset[type] : undefined

        return Asset ? (
          <AssetContainer key={path} testID={`assets-row__asset-container--${index}`}>
            <Asset src={path} />
            {onRemove ? (
              <CloseButton
                onPress={createOnRemove(path)}
                testID={`assets-row__asset-container--${index}__close-button`}
              />
            ) : null}
          </AssetContainer>
        ) : null
      },
      [createOnRemove, onRemove, typeToAsset],
    )

    if (autoHide && !hasItems(items)) return null

    return (
      <Root>
        <AssetsContainer limit={limit}>{map(renderAsset)(entries(items))}</AssetsContainer>
        {isDefined(error) ? <Error>{error}</Error> : null}
      </Root>
    )
  }

export default factory
