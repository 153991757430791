import { Action } from 'redux'

import { FailureAction, PayloadAction } from '../../types/core'
import { FetchActivityItemsResponse } from '../activities/types'

export const FETCH_CUSTOMER_ADVERTS_START = 'FETCH_CUSTOMER_ADVERTS_START'
export const FETCH_CUSTOMER_ADVERTS_SUCCESS = 'FETCH_CUSTOMER_ADVERTS_SUCCESS'
export const FETCH_CUSTOMER_ADVERTS_FAILURE = 'FETCH_CUSTOMER_ADVERTS_FAILURE'

export type FetchCustomerAdvertsStart = Action<typeof FETCH_CUSTOMER_ADVERTS_START>
export type FetchCustomerAdvertsSuccess = PayloadAction<
  typeof FETCH_CUSTOMER_ADVERTS_SUCCESS,
  FetchActivityItemsResponse
>
export type FetchCustomerAdvertsFailure = FailureAction<typeof FETCH_CUSTOMER_ADVERTS_FAILURE>
export type FetchCustomerAdvertsResult = FetchCustomerAdvertsSuccess | FetchCustomerAdvertsFailure
export type FetchCustomerAdverts = FetchCustomerAdvertsStart | FetchCustomerAdvertsResult

export const FETCH_CUSTOMER_ADVERTS_NEXT_PAGE_START = 'FETCH_CUSTOMER_ADVERTS_NEXT_PAGE_START'
export const FETCH_CUSTOMER_ADVERTS_NEXT_PAGE_SUCCESS = 'FETCH_CUSTOMER_ADVERTS_NEXT_PAGE_SUCCESS'
export const FETCH_CUSTOMER_ADVERTS_NEXT_PAGE_FAILURE = 'FETCH_CUSTOMER_ADVERTS_NEXT_PAGE_FAILURE'

export type FetchCustomerAdvertsNextPageStart = Action<typeof FETCH_CUSTOMER_ADVERTS_NEXT_PAGE_START>
export type FetchCustomerAdvertsNextPageSuccess = PayloadAction<
  typeof FETCH_CUSTOMER_ADVERTS_NEXT_PAGE_SUCCESS,
  FetchActivityItemsResponse
>
export type FetchCustomerAdvertsNextPageFailure = FailureAction<typeof FETCH_CUSTOMER_ADVERTS_NEXT_PAGE_FAILURE>

export type FetchCustomerAdvertsNextPageResult =
  | FetchCustomerAdvertsNextPageSuccess
  | FetchCustomerAdvertsNextPageFailure

export type FetchCustomerAdvertsNextPage = FetchCustomerAdvertsNextPageStart | FetchCustomerAdvertsNextPageResult

export const FETCH_CUSTOMER_HISTORY_ADVERTS_START = 'FETCH_CUSTOMER_HISTORY_ADVERTS_START'
export const FETCH_CUSTOMER_HISTORY_ADVERTS_SUCCESS = 'FETCH_CUSTOMER_HISTORY_ADVERTS_SUCCESS'
export const FETCH_CUSTOMER_HISTORY_ADVERTS_FAILURE = 'FETCH_CUSTOMER_HISTORY_ADVERTS_FAILURE'

export type FetchCustomerHistoryAdvertsStart = Action<typeof FETCH_CUSTOMER_HISTORY_ADVERTS_START>
export type FetchCustomerHistoryAdvertsSuccess = PayloadAction<
  typeof FETCH_CUSTOMER_HISTORY_ADVERTS_SUCCESS,
  FetchActivityItemsResponse
>
export type FetchCustomerHistoryAdvertsFailure = FailureAction<typeof FETCH_CUSTOMER_HISTORY_ADVERTS_FAILURE>
export type FetchCustomerHistoryAdvertsResult = FetchCustomerHistoryAdvertsSuccess | FetchCustomerHistoryAdvertsFailure
export type FetchCustomerHistoryAdverts = FetchCustomerHistoryAdvertsStart | FetchCustomerHistoryAdvertsResult

export const FETCH_CUSTOMER_HISTORY_ADVERTS_NEXT_PAGE_START = 'FETCH_CUSTOMER_HISTORY_ADVERTS_NEXT_PAGE_START'
export const FETCH_CUSTOMER_HISTORY_ADVERTS_NEXT_PAGE_SUCCESS = 'FETCH_CUSTOMER_HISTORY_ADVERTS_NEXT_PAGE_SUCCESS'
export const FETCH_CUSTOMER_HISTORY_ADVERTS_NEXT_PAGE_FAILURE = 'FETCH_CUSTOMER_HISTORY_ADVERTS_NEXT_PAGE_FAILURE'

export type FetchCustomerHistoryAdvertsNextPageStart = Action<typeof FETCH_CUSTOMER_HISTORY_ADVERTS_NEXT_PAGE_START>
export type FetchCustomerHistoryAdvertsNextPageSuccess = PayloadAction<
  typeof FETCH_CUSTOMER_HISTORY_ADVERTS_NEXT_PAGE_SUCCESS,
  FetchActivityItemsResponse
>
export type FetchCustomerHistoryAdvertsNextPageFailure = FailureAction<
  typeof FETCH_CUSTOMER_HISTORY_ADVERTS_NEXT_PAGE_FAILURE
>

export type FetchCustomerHistoryAdvertsNextPageResult =
  | FetchCustomerHistoryAdvertsNextPageSuccess
  | FetchCustomerHistoryAdvertsNextPageFailure

export type FetchCustomerHistoryAdvertsNextPage =
  | FetchCustomerHistoryAdvertsNextPageStart
  | FetchCustomerHistoryAdvertsNextPageResult

export type CustomerAdvertsAction =
  | FetchCustomerAdverts
  | FetchCustomerAdvertsNextPage
  | FetchCustomerHistoryAdverts
  | FetchCustomerHistoryAdvertsNextPage
