import { Dispatch, Nullable, Render } from '@lovejunk/core'
import { entries, flow, isUndefined, kebabCase, map } from 'lodash/fp'
import React, { FC, useCallback, useState } from 'react'
import { styled, ThemeProps } from 'styled'
import { withTheme } from 'styled-components'
import { ClassNameProps } from 'types/ui'
import { device } from 'utils/css'

import Column from '../containers/Column'
import { panelCss } from '../Panel'
import PanelTitle from '../Panel/Title'
import Item from './Item'
import items, { Item as ItemModel, ItemId } from './items'

interface Props {
  selectedId?: Nullable<ItemId>
  setSelectedId?: Dispatch<Nullable<ItemId>>
}

const Faq: FC<Props & ClassNameProps & ThemeProps> = ({ className, selectedId, setSelectedId }) => {
  const [innerSelectedId, setInnerSelectedId] = useState<Nullable<ItemId>>(null)
  const onChange = useCallback(
    (id: Nullable<ItemId>) => (setSelectedId ? setSelectedId(id) : setInnerSelectedId(id)),
    [setInnerSelectedId, setSelectedId],
  )

  const renderItem = useCallback<Render<[ItemId, ItemModel]>>(
    ([key, { title, description }]) => (
      <Item
        key={`faq-${kebabCase(key)}`}
        index={key}
        title={title}
        description={description}
        isOpen={isUndefined(selectedId) ? innerSelectedId === key : selectedId === key}
        onChange={onChange}
      />
    ),
    [onChange, innerSelectedId, selectedId],
  )

  return (
    <Root className={className}>
      <Title>Questions</Title>
      <Items>{flow(entries, map(renderItem))(items)}</Items>
    </Root>
  )
}

const Root = styled(Column)`
  @media ${device.mobile} {
    ${panelCss}

    background-color: ${({ theme: { colors } }) => colors.green};
    border-width: 0;
    box-shadow: none;
  }
`

const Items = styled.div``

const Title = styled(PanelTitle)`
  color: ${({ theme: { colors } }) => colors.black};
  padding: 0 2rem;

  @media ${device.mobile} {
    padding: 0 1rem;
    margin: 1em 0;
  }
`

export default withTheme(Faq)
