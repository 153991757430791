import { flow } from 'lodash/fp'
import Reducer from 'types/reducer'

import {
  areJobsLoadingLens,
  itemsLens,
  itemsSetLens,
  metaLens,
  successfulClientReferenceUpdateModalLens,
  updateClientReferenceLens,
  updatingClientReferenceLens,
} from './lens'
import { unmarshal } from './marshaller'
import State, { tradeJobsDefaultState as defaultState } from './state'
import {
  CLEAR_TRADE_JOBS,
  DISMISS_SUCCESSFUL_CLIENT_REFERENCE_UPDATE_MODAL,
  FETCH_TRADE_JOBS_FAILURE,
  FETCH_TRADE_JOBS_NEXT_PAGE_FAILURE,
  FETCH_TRADE_JOBS_NEXT_PAGE_START,
  FETCH_TRADE_JOBS_NEXT_PAGE_SUCCESS,
  FETCH_TRADE_JOBS_START,
  FETCH_TRADE_JOBS_SUCCESS,
  UPDATE_CLIENT_REFERENCE_REQUEST_FAILURE,
  UPDATE_CLIENT_REFERENCE_REQUEST_START,
  UPDATE_CLIENT_REFERENCE_REQUEST_SUCCESS,
} from './types'

const reducer: Reducer<State> = (state = defaultState(), action) => {
  switch (action.type) {
    case CLEAR_TRADE_JOBS: {
      return itemsSetLens(state)([])
    }
    case FETCH_TRADE_JOBS_START:
    case FETCH_TRADE_JOBS_NEXT_PAGE_START: {
      return areJobsLoadingLens(state).set(true)
    }
    case FETCH_TRADE_JOBS_SUCCESS: {
      const items = action.payload.body.entities.map(unmarshal)

      return flow(
        s => itemsSetLens(s)(items),
        s => metaLens(s).set(action.payload.meta),
        s => areJobsLoadingLens(s).set(false),
      )(state)
    }
    case FETCH_TRADE_JOBS_NEXT_PAGE_SUCCESS: {
      const items = action.payload.body.entities.map(unmarshal)

      return flow(
        s => itemsLens(s).set(items),
        s => metaLens(s).set(action.payload.meta),
        s => areJobsLoadingLens(s).set(false),
      )(state)
    }
    case FETCH_TRADE_JOBS_FAILURE:
    case FETCH_TRADE_JOBS_NEXT_PAGE_FAILURE: {
      return areJobsLoadingLens(state).set(false)
    }
    case UPDATE_CLIENT_REFERENCE_REQUEST_START: {
      return updatingClientReferenceLens(state).set(true)
    }
    case UPDATE_CLIENT_REFERENCE_REQUEST_SUCCESS: {
      return flow(
        s => updatingClientReferenceLens(s).set(false),
        s => successfulClientReferenceUpdateModalLens(s).set(true),
        s => updateClientReferenceLens(s)(action.payload),
      )(state)
    }
    case UPDATE_CLIENT_REFERENCE_REQUEST_FAILURE: {
      return updatingClientReferenceLens(state).set(false)
    }
    case DISMISS_SUCCESSFUL_CLIENT_REFERENCE_UPDATE_MODAL: {
      return successfulClientReferenceUpdateModalLens(state).set(false)
    }
    default:
      return state
  }
}

export default reducer
