import { compactAndJoin, parseDecimalInt } from '@lovejunk/core'
import { find, flow, isUndefined } from 'lodash/fp'
import { useEffect, useMemo, useRef } from 'react'
import { CallbackOptions, DataLayerObject, GTagOptions } from 'types/optimize'

const event = 'optimize.activate'

export default (name: string, callbackOptions?: CallbackOptions) => {
  const isActivated = useRef<boolean>(false)
  const { callback, scope } = callbackOptions || {}
  const id = useMemo<string>(() => compactAndJoin(':')([scope, name]), [name, scope])

  useEffect(() => {
    if (isActivated.current) return

    if (dataLayer) {
      isActivated.current = true

      const dataLayerObject: DataLayerObject = { event, id }

      if (isUndefined(find(dataLayerObject)(dataLayer))) dataLayer.push(dataLayerObject)
    }

    if (callback) {
      const options: GTagOptions = { name, callback: flow(parseDecimalInt, callback) }

      gtag('event', 'optimize.callback', options)

      return () => gtag('event', 'optimize.callback', { ...options, remove: true })
    }
  }, [id, name, callback])
}
