import {
  AdvertSource,
  FailureAction,
  JobStatus,
  Nullable,
  PayloadAction,
  RemoteUrlAction,
  Response,
  SessionRelation,
  Status,
  VatPrice,
} from '@lovejunk/core/src'
import { Action } from 'redux'

export interface FetchTradeJobsMeta {
  next_url?: string
  page_number: number
  page_size: number
  url: string
}

export interface TradeJobActions {
  updateClientReference?: RemoteUrlAction
  receiptEndpoint?: RemoteUrlAction
  wtnEndpoint?: RemoteUrlAction
}

export type TradeJobBase<D> = {
  actions: TradeJobActions
  address1?: string
  address2?: string
  address3?: string
  advertImages: string[]
  advertReference: string
  advertSource: AdvertSource
  advertStatus: Status
  beforeAndAfterImages: string[]
  city: string
  clientReference?: string
  collectedAt?: D
  jobStatus: JobStatus
  listedAt: D
  postcode: string
  price?: VatPrice
  relation: SessionRelation
  title: string
}

export type TradeJobItemResource = TradeJobBase<string>

export type TradeJobItem = TradeJobBase<Date>

interface FetchTradeJobItemsResponseBody {
  entities: TradeJobItemResource[]
}

// More filters are coming
export interface Filters {
  postcode?: string
}

export interface FilterParams {
  postcode?: string
}

export type FetchTradeJobsResponse = Response<FetchTradeJobItemsResponseBody, Nullable<FetchTradeJobsMeta>>

export const CLEAR_TRADE_JOBS = 'CLEAR_TRADE_JOBS'

export type ClearTradeJobs = Action<typeof CLEAR_TRADE_JOBS>

export const FETCH_TRADE_JOBS_START = 'FETCH_TRADE_JOBS_START'
export const FETCH_TRADE_JOBS_SUCCESS = 'FETCH_TRADE_JOBS_SUCCESS'
export const FETCH_TRADE_JOBS_FAILURE = 'FETCH_TRADE_JOBS_FAILURE'

export const FETCH_TRADE_JOBS_NEXT_PAGE_START = 'FETCH_TRADE_JOBS_NEXT_PAGE_START'
export const FETCH_TRADE_JOBS_NEXT_PAGE_SUCCESS = 'FETCH_TRADE_JOBS_NEXT_PAGE_SUCCESS'
export const FETCH_TRADE_JOBS_NEXT_PAGE_FAILURE = 'FETCH_TRADE_JOBS_NEXT_PAGE_FAILURE'

export type FetchTradeJobsStart = Action<typeof FETCH_TRADE_JOBS_START>
export type FetchTradeJobsSuccess = PayloadAction<typeof FETCH_TRADE_JOBS_SUCCESS, FetchTradeJobsResponse>

export type FetchTradeJobsFailure = FailureAction<typeof FETCH_TRADE_JOBS_FAILURE>
export type FetchTradeJobsResult = FetchTradeJobsSuccess | FetchTradeJobsFailure
export type FetchTradeJobs = FetchTradeJobsStart | FetchTradeJobsResult

export type FetchTradeJobsNextPageStart = Action<typeof FETCH_TRADE_JOBS_NEXT_PAGE_START>
export type FetchTradeJobsNextPageSuccess = PayloadAction<
  typeof FETCH_TRADE_JOBS_NEXT_PAGE_SUCCESS,
  FetchTradeJobsResponse
>
export type FetchTradeJobsNextPageFailure = FailureAction<typeof FETCH_TRADE_JOBS_NEXT_PAGE_FAILURE>

export type FetchTradeJobsNextPageResult = FetchTradeJobsNextPageSuccess | FetchTradeJobsNextPageFailure

export type FetchTradeJobsNextPage =
  | FetchTradeJobsNextPageStart
  | FetchTradeJobsNextPageSuccess
  | FetchTradeJobsNextPageFailure

export type UpdateClientReferenceRequestData = {
  advertReference: string
  clientReference: string
  url: string
}

export type SuccessfulClientReferenceUpdate = {
  advertReference: string
  clientReference: string
}

export const UPDATE_CLIENT_REFERENCE_REQUEST_START = 'UPDATE_CLIENT_REFERENCE_REQUEST_START'
export const UPDATE_CLIENT_REFERENCE_REQUEST_SUCCESS = 'UPDATE_CLIENT_REFERENCE_REQUEST_SUCCESS'
export const UPDATE_CLIENT_REFERENCE_REQUEST_FAILURE = 'UPDATE_CLIENT_REFERENCE_REQUEST_FAILURE'

export type UpdateClientReferenceRequestStart = Action<typeof UPDATE_CLIENT_REFERENCE_REQUEST_START>
export type UpdateClientReferenceRequestSuccess = PayloadAction<
  typeof UPDATE_CLIENT_REFERENCE_REQUEST_SUCCESS,
  SuccessfulClientReferenceUpdate
>
export type UpdateClientReferenceRequestFailure = FailureAction<typeof UPDATE_CLIENT_REFERENCE_REQUEST_FAILURE>
export type UpdateClientReferenceRequestResult =
  | UpdateClientReferenceRequestSuccess
  | UpdateClientReferenceRequestFailure
export type UpdateClientReferenceRequest = UpdateClientReferenceRequestStart | UpdateClientReferenceRequestResult

export type UpdateClientReferenceAction = UpdateClientReferenceRequest

export const DISMISS_SUCCESSFUL_CLIENT_REFERENCE_UPDATE_MODAL = 'DISMISS_SUCCESSFUL_CLIENT_REFERENCE_UPDATE_MODAL'
export type DismissSuccessfulClientReferenceUpdateModal = Action<
  typeof DISMISS_SUCCESSFUL_CLIENT_REFERENCE_UPDATE_MODAL
>

export type TradeJobsAction = ClearTradeJobs | FetchTradeJobs | FetchTradeJobsNextPage
