import { addEmUnitToSize, Color, Size } from '@lovejunk/core'
import { isString } from 'lodash/fp'
import React, { FC } from 'react'
import { styled, ThemeProps } from 'styled'
import { withTheme } from 'styled-components'
import { ClassNameProps, SvgIcon } from 'types/ui'

export interface Props extends ClassNameProps {
  color: Color
  Icon: SvgIcon | string
  size: Size

  strokeColor?: Color
}

const SvgIconComponent: FC<Props & ThemeProps> = ({ className, color, Icon, size, strokeColor, theme: { colors } }) =>
  isString(Icon) ? (
    <Img className={className} src={Icon} {...size} />
  ) : (
    <Icon
      className={className}
      color={strokeColor && colors[strokeColor]}
      fill={colors[color]}
      {...addEmUnitToSize(size)}
    />
  )

const Img = styled.img<Size>`
  width: ${({ width }) => width}em;
  height: ${({ height }) => height}em;
`

export default withTheme(SvgIconComponent)
