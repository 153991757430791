import { AssetsElementsProps, assetsFactory as factory, AssetsProps, componentAdapter } from '@lovejunk/core'
import { Close } from 'assets/icons'
import { omit } from 'lodash/fp'
import React, { FC, useMemo } from 'react'
import { styled } from 'styled'
import { ClassNameProps } from 'types/ui'
import { device } from 'utils/css'

import Column from '../containers/Column'
import Row from '../containers/Row'
import ImageTile from '../ImageTile'
import VideoTile from '../VideoTile'

interface Props extends AssetsProps {
  size: number
}

const AssetContainer = styled.div<AssetsElementsProps['AssetContainer']>`
  position: relative;
  margin: 0 0.5em;

  @media ${device.mobile} {
    margin: 0 0.25em;
  }
`

const AssetsContainer = styled(Row)<AssetsElementsProps['AssetsContainer']>`
  margin: 0 -0.5em;

  @media ${device.mobile} {
    margin: 0 -0.25em;
  }
`

const CloseButton = styled(
  componentAdapter(styled.img`
    background: ${({ theme: { colors } }) => colors.primary};
    border-radius: 25%;
    cursor: pointer;
    padding: 0.25em;
    position: absolute;
    right: -0.5em;
    top: -0.5em;
    height: 1.5em;
    width: 1.5em;
    z-index: 1;
  `)<AssetsElementsProps['CloseButton']>(({ onPress: onClick }) => ({ onClick, src: Close }), omit('onPress')),
)``

const Error = styled.p`
  margin: 0;
  position: absolute;
  right: 0.5em;
  bottom: 0.25em;
  color: ${({ theme: { colors } }) => colors.error};
  font-weight: bold;
  text-align: left;
  font-size: 0.75em;
`

const StyledColumn = styled(Column)`
  padding: 0.5em;
`

const Assets: FC<Props & ClassNameProps> = ({ className, size, ...props }) => {
  const Image = useMemo<FC<AssetsElementsProps['Image']>>(() => props => <ImageTile {...props} size={size} />, [size])
  const Video = useMemo<FC<AssetsElementsProps['Video']>>(() => props => <VideoTile {...props} size={size} />, [size])
  const Root = useMemo<FC>(() => props => <StyledColumn {...props} className={className} />, [className])

  return factory({
    AssetContainer,
    AssetsContainer,
    CloseButton,
    Error,
    Image,
    Root,
    Video,
  })(props)
}

export default Assets
