import { post } from '../../api'
import { responseToBody } from '../../helpers'
import { Nullable } from '../../types/core'
import getConfig from './config'
import { CheckOAuthTokenStatusResponse, GetOAuthAccessTokenResponse } from './types'

export const getOAuthAccessTokenApi = (url: string) =>
  post<GetOAuthAccessTokenResponse>(url, getConfig().oauthParams, { retry: false, skipAuthHeaders: true }).then(
    responseToBody,
  )

export const checkOAuthTokenStatusApi = (url: string, oauth: string, session: Nullable<string>) =>
  post<CheckOAuthTokenStatusResponse>(url, { oauth, session }, { retry: false, skipAuthHeaders: true }).then(
    responseToBody,
  )
