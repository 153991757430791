import { compact, entries, flow, map, zip } from 'lodash/fp'

import { DEFAULT_PROCESS_IMAGE_OPTIONS } from '../constants'
import { isDefined, promiseAll, then } from '../helpers'
import { Asset, AssetPath, BodyItem, FileItem, MarshalFile, Optional, ProcessImage, Transform } from '../types/core'
import { assetToMimeType } from '../utils/assets'
import { createFileItemMapper, createToBodyItem } from './file'

export interface MarshalImagePayload {
  processImage: ProcessImage
  marshalFile: MarshalFile
}

export type ImagesToBodyItems = Transform<Asset[], Promise<BodyItem[]>>

export const createImagesToBodyItems: Transform<MarshalImagePayload, ImagesToBodyItems> =
  ({ marshalFile, processImage }) =>
  assets =>
    flow(
      entries,
      map(createFileItemMapper(processImage(DEFAULT_PROCESS_IMAGE_OPTIONS))),
      promiseAll,
      then(zip(assets)),
      then(
        flow(
          map<[Asset, AssetPath], Optional<FileItem>>(([asset, path]) => {
            const mimeType = assetToMimeType(asset)

            return isDefined(mimeType) ? { ...asset, mimeType, path } : undefined
          }),
          compact,
          entries,
          map(createFileItemMapper(createToBodyItem(marshalFile)({ prefix: 'image' }))),
        ),
      ),
    )(assets)
