import { Action } from 'redux'

import { FailureAction, PayloadAction } from '../../types/core'
import { FetchActivityItemsResponse, FetchSupplierOffersResponse } from '../activities/types'

export const FETCH_COLLECTOR_ADVERTS_START = 'FETCH_COLLECTOR_ADVERTS_START'
export const FETCH_COLLECTOR_ADVERTS_SUCCESS = 'FETCH_COLLECTOR_ADVERTS_SUCCESS'
export const FETCH_COLLECTOR_ADVERTS_FAILURE = 'FETCH_COLLECTOR_ADVERTS_FAILURE'

export type FetchCollectorAdvertsStart = Action<typeof FETCH_COLLECTOR_ADVERTS_START>
export type FetchCollectorAdvertsSuccess = PayloadAction<
  typeof FETCH_COLLECTOR_ADVERTS_SUCCESS,
  FetchActivityItemsResponse
>
export type FetchCollectorAdvertsFailure = FailureAction<typeof FETCH_COLLECTOR_ADVERTS_FAILURE>

export type FetchCollectorAdvertsResult = FetchCollectorAdvertsSuccess | FetchCollectorAdvertsFailure
export type FetchCollectorAdverts = FetchCollectorAdvertsStart | FetchCollectorAdvertsResult

export const FETCH_COLLECTOR_HISTORY_ADVERTS_START = 'FETCH_COLLECTOR_HISTORY_ADVERTS_START'
export const FETCH_COLLECTOR_HISTORY_ADVERTS_SUCCESS = 'FETCH_COLLECTOR_HISTORY_ADVERTS_SUCCESS'
export const FETCH_COLLECTOR_HISTORY_ADVERTS_FAILURE = 'FETCH_COLLECTOR_HISTORY_ADVERTS_FAILURE'

export type FetchCollectorHistoryAdvertsStart = Action<typeof FETCH_COLLECTOR_HISTORY_ADVERTS_START>
export type FetchCollectorHistoryAdvertsSuccess = PayloadAction<
  typeof FETCH_COLLECTOR_HISTORY_ADVERTS_SUCCESS,
  FetchActivityItemsResponse
>
export type FetchCollectorHistoryAdvertsFailure = FailureAction<typeof FETCH_COLLECTOR_HISTORY_ADVERTS_FAILURE>

export type FetchCollectorHistoryAdvertsResult =
  | FetchCollectorHistoryAdvertsSuccess
  | FetchCollectorHistoryAdvertsFailure
export type FetchCollectorHistoryAdverts = FetchCollectorHistoryAdvertsStart | FetchCollectorHistoryAdvertsResult

export const FETCH_COLLECTOR_ADVERTS_NEXT_PAGE_START = 'FETCH_COLLECTOR_ADVERTS_NEXT_PAGE_START'
export const FETCH_COLLECTOR_ADVERTS_NEXT_PAGE_SUCCESS = 'FETCH_COLLECTOR_ADVERTS_NEXT_PAGE_SUCCESS'
export const FETCH_COLLECTOR_ADVERTS_NEXT_PAGE_FAILURE = 'FETCH_COLLECTOR_ADVERTS_NEXT_PAGE_FAILURE'

export type FetchCollectorAdvertsNextPageStart = Action<typeof FETCH_COLLECTOR_ADVERTS_NEXT_PAGE_START>
export type FetchCollectorAdvertsNextPageSuccess = PayloadAction<
  typeof FETCH_COLLECTOR_ADVERTS_NEXT_PAGE_SUCCESS,
  FetchActivityItemsResponse
>
export type FetchCollectorAdvertsNextPageFailure = FailureAction<typeof FETCH_COLLECTOR_ADVERTS_NEXT_PAGE_FAILURE>

export type FetchCollectorAdvertsNextPageResult =
  | FetchCollectorAdvertsNextPageSuccess
  | FetchCollectorAdvertsNextPageFailure

export type FetchCollectorAdvertsNextPage = FetchCollectorAdvertsNextPageStart | FetchCollectorAdvertsNextPageResult

export const FETCH_COLLECTOR_HISTORY_ADVERTS_NEXT_PAGE_START = 'FETCH_COLLECTOR_HISTORY_ADVERTS_NEXT_PAGE_START'
export const FETCH_COLLECTOR_HISTORY_ADVERTS_NEXT_PAGE_SUCCESS = 'FETCH_COLLECTOR_HISTORY_ADVERTS_NEXT_PAGE_SUCCESS'
export const FETCH_COLLECTOR_HISTORY_ADVERTS_NEXT_PAGE_FAILURE = 'FETCH_COLLECTOR_HISTORY_ADVERTS_NEXT_PAGE_FAILURE'

export type FetchCollectorHistoryAdvertsNextPageStart = Action<typeof FETCH_COLLECTOR_HISTORY_ADVERTS_NEXT_PAGE_START>
export type FetchCollectorHistoryAdvertsNextPageSuccess = PayloadAction<
  typeof FETCH_COLLECTOR_HISTORY_ADVERTS_NEXT_PAGE_SUCCESS,
  FetchActivityItemsResponse
>
export type FetchCollectorHistoryAdvertsNextPageFailure = FailureAction<
  typeof FETCH_COLLECTOR_HISTORY_ADVERTS_NEXT_PAGE_FAILURE
>

export type FetchCollectorHistoryAdvertsNextPageResult =
  | FetchCollectorHistoryAdvertsNextPageSuccess
  | FetchCollectorHistoryAdvertsNextPageFailure

export type FetchCollectorHistoryAdvertsNextPage =
  | FetchCollectorHistoryAdvertsNextPageStart
  | FetchCollectorHistoryAdvertsNextPageResult

export const FETCH_COLLECTOR_OFFERS_START = 'FETCH_COLLECTOR_OFFERS_START'
export const FETCH_COLLECTOR_OFFERS_SUCCESS = 'FETCH_COLLECTOR_OFFERS_SUCCESS'
export const FETCH_COLLECTOR_OFFERS_FAILURE = 'FETCH_COLLECTOR_OFFERS_FAILURE'

export type FetchCollectorOffersStart = Action<typeof FETCH_COLLECTOR_OFFERS_START>
export type FetchCollectorOffersSuccess = PayloadAction<
  typeof FETCH_COLLECTOR_OFFERS_SUCCESS,
  FetchSupplierOffersResponse
>
export type FetchCollectorOffersFailure = FailureAction<typeof FETCH_COLLECTOR_OFFERS_FAILURE>
export type FetchCollectorOffersResult = FetchCollectorOffersSuccess | FetchCollectorOffersFailure
export type FetchCollectorOffers = FetchCollectorOffersStart | FetchCollectorOffersResult

export const FETCH_COLLECTOR_OFFERS_NEXT_PAGE_START = 'FETCH_COLLECTOR_OFFERS_NEXT_PAGE_START'
export const FETCH_COLLECTOR_OFFERS_NEXT_PAGE_SUCCESS = 'FETCH_COLLECTOR_OFFERS_NEXT_PAGE_SUCCESS'
export const FETCH_COLLECTOR_OFFERS_NEXT_PAGE_FAILURE = 'FETCH_COLLECTOR_OFFERS_NEXT_PAGE_FAILURE'

export type FetchCollectorOffersNextPageStart = Action<typeof FETCH_COLLECTOR_OFFERS_NEXT_PAGE_START>
export type FetchCollectorOffersNextPageSuccess = PayloadAction<
  typeof FETCH_COLLECTOR_OFFERS_NEXT_PAGE_SUCCESS,
  FetchSupplierOffersResponse
>
export type FetchCollectorOffersNextPageFailure = FailureAction<typeof FETCH_COLLECTOR_OFFERS_NEXT_PAGE_FAILURE>
export type FetchCollectorOffersNextPageResult =
  | FetchCollectorOffersNextPageSuccess
  | FetchCollectorOffersNextPageFailure
export type FetchCollectorOffersNextPage = FetchCollectorOffersNextPageStart | FetchCollectorOffersNextPageResult

export type CollectorAdvertsAction =
  | FetchCollectorAdverts
  | FetchCollectorAdvertsNextPage
  | FetchCollectorHistoryAdverts
  | FetchCollectorHistoryAdvertsNextPage
  | FetchCollectorOffers
  | FetchCollectorOffersNextPage
