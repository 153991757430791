import { collectorReviewItemFactory as factory, componentAdapter } from '@lovejunk/core'
import { omit } from 'lodash/fp'
import { styled } from 'styled'
import { withSpacing } from 'utils/css'

import { CenteredRow, Column, Row } from '../containers'
import Rating from '../Rating'
import { Text } from '../text'

export default factory({
  Root: Row,
  AdvertTitle: componentAdapter(styled(Text)`
    cursor: pointer;
    margin-bottom: 0.5em;
  `)(
    ({ onPress: onClick }) => ({
      bold: true,
      color: 'contrast1',
      onClick,
      size: 0.875,
      underlined: true,
    }),
    omit('onPress'),
  ),
  Comments: styled(Text).attrs({ color: 'regularText' })`
    margin: 0.5em 0;
  `,
  TopRow: styled(CenteredRow)`
    ${withSpacing()}
    flex-wrap: wrap;
    margin-bottom: 1em;
  `,
  Content: styled(Column)`
    flex: 1;
  `,
  Image: componentAdapter(styled.img`
    cursor: pointer;
    height: 100%;
    margin-left: 0.5em;
    object-fit: cover;
    width: 5em;
  `)(({ onPress: onClick, uri: src }) => ({ onClick, src }), omit('onPress')),
  NameAndPostcode: styled(Text).attrs({ bold: true, size: 0.875 })``,
  Rating,
  ReviewedAt: styled(Text).attrs({ color: 'greyText', size: 0.75 })`
    margin: 0.5em 0;
  `,
  Verified: styled(Text).attrs({ bold: true, children: 'verified', size: 0.75 })`
    background-color: ${({ theme: { colors } }) => colors.green};
    border-radius: 0.25em;
    overflow: hidden;
    padding: 0.375em 0.675em;
  `,
})
