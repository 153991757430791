import { Action } from 'redux'

import { FailureAction, PayloadAction, Response } from '../../types/core'

export interface PostcodeCheckBody {
  postcode: string
  supported: boolean
  isUlez: boolean
}

export type PostcodeCheckResponse = Response<PostcodeCheckBody>

export const POSTCODE_CHECK_START = 'POSTCODE_CHECK_START'
export const POSTCODE_CHECK_SUCCESS = 'POSTCODE_CHECK_SUCCESS'
export const POSTCODE_CHECK_FAILURE = 'POSTCODE_CHECK_FAILURE'
export const POSTCODE_SUPPORTED_START = 'POSTCODE_SUPPORTED_START'
export const POSTCODE_SUPPORTED_SUCCESS = 'POSTCODE_SUPPORTED_SUCCESS'
export const POSTCODE_SUPPORTED_FAILURE = 'POSTCODE_SUPPORTED_FAILURE'

export type PostcodeCheckStart = Action<typeof POSTCODE_CHECK_START>
export type PostcodeCheckSuccess = PayloadAction<typeof POSTCODE_CHECK_SUCCESS, PostcodeCheckBody>
export type PostcodeCheckFailure = FailureAction<typeof POSTCODE_CHECK_FAILURE>
export type PostcodeSupportedStart = Action<typeof POSTCODE_SUPPORTED_START>
export type PostcodeSupportedSuccess = PayloadAction<typeof POSTCODE_SUPPORTED_SUCCESS, boolean>
export type PostcodeSupportedFailure = FailureAction<typeof POSTCODE_SUPPORTED_FAILURE>

export type PostcodeCheckResult = PostcodeCheckSuccess | PostcodeCheckFailure
export type PostcodeCheck = PostcodeCheckStart | PostcodeCheckResult
export type PostcodeSupportedResult = PostcodeSupportedSuccess | PostcodeSupportedFailure
export type PostcodeSupported = PostcodeSupportedStart | PostcodeSupportedResult

export const POSTCODE_VALIDATION_START = 'POSTCODE_VALIDATION_START'
export const POSTCODE_VALIDATION_SUCCESS = 'POSTCODE_VALIDATION_SUCCESS'
export const POSTCODE_VALIDATION_FAILURE = 'POSTCODE_VALIDATION_FAILURE'
export const POSTCODE_VALID_START = 'POSTCODE_VALID_START'
export const POSTCODE_VALID_SUCCESS = 'POSTCODE_VALID_SUCCESS'
export const POSTCODE_VALID_FAILURE = 'POSTCODE_VALID_FAILURE'

export type PostcodeValidationStart = Action<typeof POSTCODE_VALIDATION_START>
export type PostcodeValidationSuccess = Action<typeof POSTCODE_VALIDATION_SUCCESS>
export type PostcodeValidationFailure = FailureAction<typeof POSTCODE_VALIDATION_FAILURE>
export type PostcodeValidationResult = PostcodeValidationSuccess | PostcodeValidationFailure
export type PostcodeValidation = PostcodeValidationStart | PostcodeValidationResult

export type PostcodeValidStart = Action<typeof POSTCODE_VALID_START>
export type PostcodeValidFailure = FailureAction<typeof POSTCODE_VALID_FAILURE>
export type PostcodeValidSuccess = PayloadAction<typeof POSTCODE_VALID_SUCCESS, boolean>
export type PostcodeValidResult = PostcodeValidSuccess | PostcodeValidFailure

export type PostcodeValid = PostcodeValidStart | PostcodeValidResult

export type PostcodeResult = PostcodeSupportedResult | PostcodeValidResult

export type PostcodeAction = PostcodeCheck | PostcodeSupported | PostcodeValid | PostcodeValidation
