import { Action } from 'redux'

import { FailureAction, PayloadAction } from '../../../types/core'
import { DisposalSite } from '.'

export const GET_DISPOSAL_SITES_START = 'GET_DISPOSAL_SITES_START'
export const GET_DISPOSAL_SITES_SUCCESS = 'GET_DISPOSAL_SITES_SUCCESS'
export const GET_DISPOSAL_SITES_FAILURE = 'GET_DISPOSAL_SITES_FAILURE'

export type GetDisposalSitesStart = Action<typeof GET_DISPOSAL_SITES_START>
export type GetDisposalSitesSuccess = PayloadAction<typeof GET_DISPOSAL_SITES_SUCCESS, DisposalSite[]>
export type GetDisposalSitesFailure = FailureAction<typeof GET_DISPOSAL_SITES_FAILURE>
export type GetDisposalSitesResult = GetDisposalSitesSuccess | GetDisposalSitesFailure
export type GetDisposalSites = GetDisposalSitesStart | GetDisposalSitesResult

export type DisposalSitesAction = GetDisposalSites
