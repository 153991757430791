import { isDefined, NoOp } from '@lovejunk/core'
import React, { FC } from 'react'
import { styled } from 'styled'
import { withError, withSpacing } from 'utils/css'

import Column from './containers/Column'
import FormFieldError from './FormFieldError'
import CheckBox from './input/CheckBox'

interface Props {
  isChecked: boolean
  onPress: NoOp

  error?: string
}

const MarketingOffers: FC<Props> = ({ error, isChecked, onPress }) => (
  <Root hasError={isDefined(error)}>
    <CheckBox checked={isChecked} onPress={onPress}>
      I would like to receive offers, updates and discounts from LoveJunk
    </CheckBox>
    {isDefined(error) ? <FormFieldError>{error}</FormFieldError> : null}
  </Root>
)

const Root = styled(Column)`
  ${withSpacing(0.5, true)}
  ${withError}
`

export default MarketingOffers
