import { action, failureAction, payloadAction } from '../../action-factory'
import apiAction, { ActionBundle } from '../../api-action'
import { ActionFactory, FailureActionFactory, PayloadActionFactory, Transform } from '../../types/core'
import { StatelessPayloadThunk } from '../../types/thunk'
import { emailRequestApi } from './api'
import {
  CLOSE_EMAIL_REQUEST_MODAL,
  CloseEmailRequestModal,
  EMAIL_REQUEST_FAILURE,
  EMAIL_REQUEST_START,
  EMAIL_REQUEST_SUCCESS,
  EmailRequestData,
  EmailRequestFailure,
  EmailRequestPayload,
  EmailRequestResult,
  EmailRequestStart,
  EmailRequestSuccess,
} from './types'

export const emailRequestSuccess: PayloadActionFactory<EmailRequestSuccess, EmailRequestData> =
  payloadAction(EMAIL_REQUEST_SUCCESS)
export const emailRequestFailure: FailureActionFactory<EmailRequestFailure> = failureAction(EMAIL_REQUEST_FAILURE)

const emailRequestActionBundle: Transform<
  EmailRequestData,
  ActionBundle<EmailRequestStart, EmailRequestSuccess, EmailRequestFailure>
> = emailRequestSuccessPayload => [
  action(EMAIL_REQUEST_START),
  () => emailRequestSuccess(emailRequestSuccessPayload),
  emailRequestFailure,
]

export const emailRequest: StatelessPayloadThunk<EmailRequestPayload, EmailRequestResult> =
  ({ email, type, url }) =>
  dispatch =>
    dispatch(apiAction(emailRequestActionBundle({ email, type }))(emailRequestApi(url, email)))

export const closeEmailRequestModal: ActionFactory<CloseEmailRequestModal> = action(CLOSE_EMAIL_REQUEST_MODAL)
