import { CollectorProfileListProps, CollectorReview, Nullable, Transform } from '@lovejunk/core'
import { isEmpty, map } from 'lodash/fp'
import React, { FC, ReactElement, useCallback } from 'react'
import { styled } from 'styled'
import { withSpacing } from 'utils/css'

const Reviews: FC<CollectorProfileListProps> = ({ children, data, keyExtractor, renderItem }) => {
  const renderListItem = useCallback<Transform<CollectorReview, Nullable<ReactElement>>>(
    item => <Item key={keyExtractor([item])}>{renderItem([item])}</Item>,
    [keyExtractor, renderItem],
  )

  return <Root>{isEmpty(data) ? children : map(renderListItem)(data)}</Root>
}

const Root = styled.ul`
  margin: 0;
  padding: 0;
  ${withSpacing(1, true)}

  & > li {
    border-bottom-style: solid;
    border-bottom-color: ${({ theme: { colors } }) => colors.grey};
    border-bottom-width: 1px;
  }

  & > li:last-child {
    border-bottom-width: 0;
  }
`

const Item = styled.li`
  list-style-type: none;
  display: block;
`

export default Reviews
