import { flow, isUndefined, trim } from 'lodash/fp'
import { useCallback, useMemo, useState } from 'react'

import { SignupJunkLoverDetails } from '../../entities/signup-junk-reuser/types'
import { isPresent, undefineNull } from '../../helpers'
import { Dispatch, NoOp, Nullable, Transform } from '../../types/core'
import { isPostcode } from '../../validations'

interface DispatchProps {
  saveDetails: Dispatch<SignupJunkLoverDetails>
  signup: NoOp
}

interface OwnProps {
  onSubmit?: NoOp
}

interface StateProps {
  details: SignupJunkLoverDetails
  isSigningUp: boolean
  selfie: Nullable<string>
}

export type Props = DispatchProps & StateProps

const parsePostcode = trim

interface Result {
  isSigningUp: boolean
  onPostcodeChange: Dispatch<string>
  onSubmit: NoOp
  postcode: string

  selfie?: string
  postcodeError?: string
}

const useDetails: Transform<OwnProps & Props, Result> = ({
  details: { postcode: postcodeProp },
  isSigningUp,
  onSubmit: onSubmitProp,
  saveDetails,
  selfie,
  signup,
}) => {
  const [postcode, setPostcode] = useState<Result['postcode']>(postcodeProp)
  const [isSubmitted, setSubmitted] = useState<boolean>(false)
  const onPostcodeChange = useCallback<Result['onPostcodeChange']>(value => flow(parsePostcode, setPostcode)(value), [])

  const postcodeError = useMemo<Result['postcodeError']>(
    () =>
      isSubmitted
        ? isPresent(postcode)
          ? isPostcode(postcode)
            ? undefined
            : 'Invalid postcode'
          : 'Required'
        : undefined,
    [postcode, isSubmitted],
  )

  const isFormValid = useMemo<boolean>(() => isUndefined(postcodeError), [postcodeError])

  const onSubmit = useCallback<Result['onSubmit']>(() => {
    onSubmitProp?.()

    setSubmitted(true)

    if (isFormValid) {
      saveDetails({ postcode })
      signup()
    }
  }, [isFormValid, onSubmitProp, postcode, saveDetails, signup])

  return { isSigningUp, onPostcodeChange, onSubmit, postcode, postcodeError, selfie: undefineNull(selfie) }
}

export default useDetails
