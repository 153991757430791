import { Action } from 'redux'

import { FailureAction, Nullable, PayloadAction } from '../../../types/core'
import { SignupJunkLoverDetails, SignupJunkLoverResponse } from '.'

export const CANCEL_JUNK_LOVER_SIGNUP = 'CANCEL_JUNK_LOVER_SIGNUP'
export const CREATE_JUNK_LOVER_ACCOUNT = 'CREATE_JUNK_LOVER_ACCOUNT'
export const SAVE_JUNK_LOVER_DETAILS = 'SAVE_JUNK_LOVER_DETAILS'
export const SAVE_JUNK_LOVER_SELFIE = 'SAVE_JUNK_LOVER_SELFIE'
export const SET_JUNK_LOVER_NOT_APPROVED = 'SET_JUNK_LOVER_NOT_APPROVED'
export const SET_JUNK_LOVER_SIGNUP_FEEDBACK = 'SET_JUNK_LOVER_SIGNUP_FEEDBACK'
export const SIGNUP_JUNK_LOVER_START = 'SIGNUP_JUNK_LOVER_START'
export const SIGNUP_JUNK_LOVER_SUCCESS = 'SIGNUP_JUNK_LOVER_SUCCESS'
export const SIGNUP_JUNK_LOVER_FAILURE = 'SIGNUP_JUNK_LOVER_FAILURE'

export type CancelJunkLoverSignup = Action<typeof CANCEL_JUNK_LOVER_SIGNUP>
export type CreateJunkLoverAccount = Action<typeof CREATE_JUNK_LOVER_ACCOUNT>
export type SaveJunkLoverDetails = PayloadAction<typeof SAVE_JUNK_LOVER_DETAILS, SignupJunkLoverDetails>
export type SaveJunkLoverSelfie = PayloadAction<typeof SAVE_JUNK_LOVER_SELFIE, Nullable<string>>
export type SetJunkLoverNotApproved = PayloadAction<typeof SET_JUNK_LOVER_NOT_APPROVED, boolean>
export type SetJunkLoverSignupFeedback = PayloadAction<typeof SET_JUNK_LOVER_SIGNUP_FEEDBACK, boolean>
export type SignupJunkLoverStart = Action<typeof SIGNUP_JUNK_LOVER_START>
export type SignupJunkLoverSuccess = PayloadAction<typeof SIGNUP_JUNK_LOVER_SUCCESS, SignupJunkLoverResponse>
export type SignupJunkLoverFailure = FailureAction<typeof SIGNUP_JUNK_LOVER_FAILURE>

export type SignupJunkLoverResult = SignupJunkLoverSuccess | SignupJunkLoverFailure
export type SignupJunkLover = SignupJunkLoverStart | SignupJunkLoverResult

export type SignupJunkLoverAction =
  | CancelJunkLoverSignup
  | CreateJunkLoverAccount
  | SaveJunkLoverDetails
  | SaveJunkLoverSelfie
  | SetJunkLoverNotApproved
  | SetJunkLoverSignupFeedback
  | SignupJunkLover
