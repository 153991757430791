import { Action } from 'redux'

import { FailureAction, PayloadAction, Response, Span } from '../../types/core'

export const AM = 'am'
export const PM = 'pm'
export const ANYTIME = 'anytime'

export type TimeSlotFixedName = typeof AM | typeof PM | typeof ANYTIME

export type TimeSlot = Span<string>

// The structure is same as in `TimeSlot`, but here values include date, while `TimeSlot` is only day time range.
export type TimeSlotResource = Span<string>

export interface TimeSlotFixed {
  name: TimeSlotFixedName
  range: TimeSlot
}

export interface TimeSlotValue {
  fixed: TimeSlotFixed[]
  ranges: TimeSlot[]
}

export type OpenTimeSlot = 'anytime' | 'asap'

export interface TimeSlots {
  today: TimeSlotValue
  future: TimeSlotValue
  openTimeSlots: OpenTimeSlot[]
}

export type TimeSlotsResponse = Response<TimeSlots>

export const GET_TIME_SLOTS_START = 'GET_TIME_SLOTS_START'
export const GET_TIME_SLOTS_SUCCESS = 'GET_TIME_SLOTS_SUCCESS'
export const GET_TIME_SLOTS_FAILURE = 'GET_TIME_SLOTS_FAILURE'

export type GetTimeSlotsStart = Action<typeof GET_TIME_SLOTS_START>
export type GetTimeSlotsSuccess = PayloadAction<typeof GET_TIME_SLOTS_SUCCESS, TimeSlots>
export type GetTimeSlotsFailure = FailureAction<typeof GET_TIME_SLOTS_FAILURE>
export type GetTimeSlotsResult = GetTimeSlotsSuccess | GetTimeSlotsFailure
export type GetTimeSlots = GetTimeSlotsStart | GetTimeSlotsSuccess | GetTimeSlotsFailure

export type TimeSlotsAction = GetTimeSlots
