import Reducer from 'types/reducer'

import { recentAdvertsLens } from './lens'
import State, { defaultState } from './state'
import { FETCH_RECENT_ADVERTS_SUCCESS } from './types'

const reducer: Reducer<State> = (state = defaultState(), action) => {
  switch (action.type) {
    case FETCH_RECENT_ADVERTS_SUCCESS:
      return recentAdvertsLens(state).set(action.payload.body.entities)
    default:
      return state
  }
}

export default reducer
