import { flow, map } from 'lodash/fp'

import { callWith, promiseAll, returnUndefined, then } from '../helpers'
import { Transform, TransformOptional } from '../types/core'
import buildConfig from '../utils/build-config'

export type AnalyticsEvent = string
export type AnalyticsParams = Record<string, any>

export type LogAnalyticsEvent = Transform<AnalyticsEvent, TransformOptional<AnalyticsParams, Promise<void>>>

export interface AnalyticsConfig {
  logEvent: LogAnalyticsEvent
}

const { getConfig, setConfig } = buildConfig<AnalyticsConfig>('analytics')

export { getConfig as getAnalyticsConfig, setConfig as configureAnalytics }

// This is expanded to function only to make `getConfig` lazy.
const logEvent: LogAnalyticsEvent = event => getConfig().logEvent(event)

export const emitAnalyticsEvents = flow(
  map<string, Promise<void>>(flow(logEvent, flow(returnUndefined, callWith)())),
  promiseAll,
  then(returnUndefined),
)
