const primary = '#26a65b'
const secondary = '#4f7ea0'
const tertiary = '#ff9900'
const selected = '#2798ae'
const success = '#00a961'
const error = '#ec2100'
const dark = '#4c4b5d'
const light = '#eaeaea'
const white = '#ffffff'
const black = '#000000'
const grey = '#dedede'
const mediumGrey = '#efefef'
const silver = '#c4c4c4'
const lightGrey = '#f7f7f7'
const greyText = '#999'
const greyBorder = '#ababab'
const required = '#f03258'
const blackish = '#111'
const contrast0 = '#303030'
const contrast1 = '#505050'
const contrast2 = '#7f7f7f'
const contrast3 = '#aeaeae'
const blue = '#007aff'
const text = '#282b2c'
const regularText = '#4a4f51'
const green = '#e4f1e9'
const pastelGreen = '#d4edda'
const darkPastelGreen = '#155724'
const pastelBlue = '#cce5ff'
const darkPastelBlue = '#004085'
const yellow = '#f6c142'
const pink = '#ff98d0'
const orange = '#ffa500'
const transparent = 'transparent'

const colors = {
  regularText,
  text,
  darkText: blackish,

  // General
  primary,
  secondary,
  tertiary,
  selected,
  silver,
  success,
  error,
  dark,
  light,
  white,
  black,
  grey,
  lightGrey,
  mediumGrey,
  greyText,
  required,
  greyBorder,
  blackish,
  blue,
  green,
  pastelGreen,
  darkPastelGreen,
  pastelBlue,
  darkPastelBlue,
  yellow,
  pink,
  orange,
  transparent,

  // Contrast
  contrast0,
  contrast1,
  contrast2,
  contrast3,

  // Backgrounds
  darkBackground: contrast0,
  lightBackground: lightGrey,

  // Fonts
  darkFont: contrast0,
  lightFont: lightGrey,
}

const typography = {
  body: {
    fontSize: 16,
    fontFamily: 'Lato-Regular',
    destressed: {
      fontSize: 15,
      fontFamily: 'Lato-Regular',
      color: colors.contrast3,
    },
    paragraph: {
      fontSize: 15,
      fontFamily: 'Lato-Regular',
      color: colors.regularText,
    },
  },
  bigger: {
    fontSize: 18,
    fontFamily: 'Lato-Regular',
  },
  large: {
    fontSize: 24,
    fontFamily: 'Lato-Regular',
  },
  label: {
    fontSize: 15,
    fontFamily: 'Lato-Bold',
  },
}

const theme = {
  colors,
  typography,
}

export type Theme = typeof theme
export type Colors = Theme['colors']
export type Color = keyof Theme['colors']

export default theme
