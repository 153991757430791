import { map, round } from 'lodash/fp'
import React, { ReactElement, useCallback, useMemo } from 'react'

import { Rating, ratings } from '../../entities/advert/types'
import { isDefined } from '../../helpers'
import { Color } from '../../theme'
import { Dispatch, NoOp, Transform } from '../../types/core'
import { ComponentFactory, ToElements } from '../../types/ui'

interface RootProps {
  error: boolean
}

interface StarProps {
  error: boolean
  selected: boolean

  color?: Color
  onPress?: NoOp
  selectedColor?: Color
  size?: number
}

export interface ElementsProps {
  Error: unknown
  Root: RootProps
  Star: StarProps
}

type Elements = ToElements<ElementsProps>

export interface Props {
  color?: Color
  error?: boolean
  onChange?: Dispatch<number>
  rating?: number
  size?: number
}

interface CreateOnPressPayload {
  onChange: Dispatch<number>
  value: number
}

const factory: ComponentFactory<Elements, Props> = ({ Error, Root, Star }) =>
  function Rating({ color, error = false, onChange, rating, size, ...props }) {
    const createOnPress = useCallback<Transform<CreateOnPressPayload, NoOp>>(
      ({ onChange, value }) =>
        () =>
          onChange(value),
      [],
    )

    const renderElement = useCallback<Transform<Rating, ReactElement>>(
      ({ value }) => (
        <Star
          color={color}
          error={error}
          key={`rating-${value}`}
          onPress={onChange && createOnPress({ onChange, value })}
          selected={isDefined(rating) && value <= round(rating)}
          size={size}
        />
      ),
      [color, createOnPress, error, onChange, rating, size],
    )
    const elements = useMemo(() => map(renderElement)(ratings), [renderElement])

    return (
      <Root {...props} error={error}>
        {elements}
        {error ? <Error>Required</Error> : null}
      </Root>
    )
  }

export default factory
