import React, { FC } from 'react'
import { Helmet, HelmetProps } from 'react-helmet'

interface Props extends HelmetProps {
  metaDescription: string
  metaTitle: string
}

const Header: FC<Props> = ({ metaDescription, metaTitle }) => (
  <Helmet
    meta={[
      { name: 'description', content: metaDescription },
      { name: 'title', content: metaTitle },
    ]}
    title={metaTitle}
  />
)

export default Header
