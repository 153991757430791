import { ChatAttachmentMockElementsProps } from '@lovejunk/core'
import React, { FC, PropsWithChildren } from 'react'

import GenericButton from '../../button/GenericButton'
import CenteredColumn from '../../containers/CenteredColumn'

const Root: FC<PropsWithChildren<ChatAttachmentMockElementsProps['Root']>> = ({ children, onPress }) => (
  <GenericButton onPress={onPress}>{() => <CenteredColumn>{children}</CenteredColumn>}</GenericButton>
)

export default Root
