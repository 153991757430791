import { toString } from 'lodash/fp'
import React, { useCallback, useMemo, useState } from 'react'

import { Dispatch, NoOp } from '../../../types/core'
import { ComponentFactory, ToElements } from '../../../types/ui'

interface OnPressPayload {
  value: string

  notes?: string
}

export type OnItemPress = Dispatch<OnPressPayload>

interface Props {
  onPress: OnItemPress
  setNotes: Dispatch<string>
  value: string

  selectedValue?: string
}

interface OptionButtonProps {
  onPress: NoOp
  selected: boolean
}

export interface TextAreaProps {
  label: string
  maxLength: number
  onChange: Dispatch<string>
  value: string
}

interface ElementsProps {
  Option: unknown
  OptionButton: OptionButtonProps
  OptionLabel: unknown
  Root: unknown
  TextArea: TextAreaProps
}

type Elements = ToElements<ElementsProps>

const factory: ComponentFactory<Elements, Props> = ({ Option, OptionButton, OptionLabel, Root, TextArea }) =>
  function Item({ children, onPress: onPressProp, selectedValue, setNotes: setNotesProp, value }) {
    const selected = useMemo(() => selectedValue === value, [selectedValue, value])
    const [notes, setNotes] = useState<string>()
    const onChange = useCallback<Dispatch<string>>(
      notes => {
        setNotes(notes)

        if (selected) setNotesProp(notes)
      },
      [selected, setNotesProp],
    )
    const onPress = useCallback(() => onPressProp({ notes, value }), [notes, onPressProp, value])

    return (
      <Root>
        <Option>
          <OptionButton onPress={onPress} selected={selected} />
          <OptionLabel>{children}</OptionLabel>
        </Option>
        {selected ? (
          <TextArea
            label="Would you like to tell us more?"
            maxLength={250}
            onChange={onChange}
            value={toString(notes)}
          />
        ) : null}
      </Root>
    )
  }

export default factory
