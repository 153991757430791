import { Action } from 'redux'

import { Nullable, Price, PriceValue, RemoteUrlAction, Response, TrackingEvents, VatPrice } from '../../../types/core'
import {
  AdvertActionPayload,
  AdvertDetailsPayload,
  AdvertIdAndUrl,
  AdvertReference,
  CollectorDetails,
  Prerequisite,
  PrerequisitesAction,
  Rating,
  ReceiptBase,
} from '../../advert/types'
import { Meta } from '../../meta/types'

export interface AdjustPricePayload extends AdvertActionPayload {
  amount: PriceValue
}

export interface CollectorsOffersPayload extends AdvertIdAndUrl {
  offersUrl: string
}

export interface JunkReusersOffersPayload extends CollectorsOffersPayload {
  junkLoverName?: string
}

export interface IncreasePricePayload extends AdvertActionPayload {
  increment: Price
}

export interface ConfirmationPayload extends AdvertDetailsPayload {
  url: string
}

export interface InitiatePaymentPayload {
  url: string
  useSavedPaymentMethod: boolean
}

export interface PurchaseListingParams extends Partial<Price> {
  advertRef: AdvertReference
  advertTitle: string
  customerRef: string
}

export const REVIEW_COLLECTION_CONFIRMED = 'REVIEW_COLLECTION_CONFIRMED'
export type ReviewCollectionConfirmed = Action<typeof REVIEW_COLLECTION_CONFIRMED>

export interface ReviewCollectionPayload {
  advertUrl: string
  comments: string
  rating: Rating['value']
  url: string
}

export interface ReviewCollectionParams {
  comments: string
  rating: Rating['name']
  url: string
}

export interface ShowAddCardModalPayload {
  confirmPayment: boolean
}

export interface DeleteAdvertPayload {
  deleteUrl: string
}

export interface CancelAdvertParams {
  reasonId: string

  notes?: string
}

export type CancelAdvertPayload = AdvertActionPayload & CancelAdvertParams

export interface AddFavouriteCollectorIntentPayload extends AdvertActionPayload {
  collectorDetails: CollectorDetails
}

export interface Offeror {
  collections: number
  image: string
  name: string
  rating: number

  postcode?: string
}

export interface CustomerDefaultServiceReceipt extends ReceiptBase<'customer'> {
  collectorReference: string
  customerReference: string
  customerFirstName: string
  customerLastName: string
  priceWithVat: Price
  priceLessVat: Price
  title: string
  transactionDate: string

  collectorOrganisation?: string
  collectorVatNumber?: string
}

export interface CustomerReuseServiceReceipt extends ReceiptBase<'customerreuseservice'> {
  address: string
  amountExVat: Price
  amountPaid: Price
  customerName: string
  collectedAt: string
  date: string // ISO-8601
  service: string
  supplier: string
  vatNumber: string
}

export type CustomerReceipt = CustomerDefaultServiceReceipt | CustomerReuseServiceReceipt
export type CustomerReceiptResponse = Response<CustomerReceipt>

export interface InitiatePaymentParams {
  useSavedPaymentMethod: boolean
}

type PaymentStatus =
  | 'canceled'
  | 'processing'
  | 'requires_action'
  | 'requires_confirmation'
  | 'requires_payment_method'
  | 'succeeded'

export interface InitiatePaymentResponseBody {
  clientSecret: string
  paymentStatus: PaymentStatus
  actions: {
    getJobStatus: RemoteUrlAction
  }
}

export type InitiatePaymentResponse = Response<InitiatePaymentResponseBody>

interface CollectorOfferActions {
  accept: PrerequisitesAction
  createChatThread?: RemoteUrlAction
  viewMessages?: RemoteUrlAction
  viewCollectorProfile?: RemoteUrlAction
}

interface JunkReuserOfferActions {
  accept: PrerequisitesAction
  createChatThread?: RemoteUrlAction
  viewMessages?: RemoteUrlAction
  viewCollectorProfile?: RemoteUrlAction
}

interface CollectorInfo {
  averageRating: number
  firstName: string
  isFavourite: boolean
  license: string
  numberOfCollections: number
}

interface JunkLoverInfo {
  name: string
  reference: string
  selfieUrl: string
}

export interface CollectorOffer {
  actions: CollectorOfferActions
  amount: VatPrice
  collectorInfo: CollectorInfo
  hasUnread: boolean

  conditions?: string
  expiry?: string
}

interface JunkReuserOfferStats {
  averageRating: number
  numberOfCollections: number
}

export interface JunkReuserOffer {
  actions: JunkReuserOfferActions
  junkLoverInfo: JunkLoverInfo

  conditions?: string
  hasUnread?: boolean
  stats?: JunkReuserOfferStats
}
export type JunkReusersOffers = JunkReuserOffer[]

export interface JunkReusersOffersBody {
  entities: JunkReusersOffers
}

export type FetchJunkReusersOffersResponse = Response<JunkReusersOffersBody>

export type CollectorOffers = CollectorOffer[]

export interface CollectorOffersBody {
  entities: CollectorOffers
}

export type FetchCollectorsOffersResponse = Response<CollectorOffersBody>

export interface OnAcceptCollectorOfferPayload {
  url: string

  prerequisites?: Prerequisite[]
  trackingEvents?: TrackingEvents
}

export interface PreferredPaymentMethodRequiredPayload {
  meta: Nullable<Meta>

  prerequisites?: Prerequisite[]
}
