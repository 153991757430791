import { isEmpty } from 'lodash/fp'

import { Marshaller } from '../types/core'

const marshaller: Marshaller<string, string> = {
  marshal: value => value.replace(/^0?(\d*)/, '$1'),
  unmarshal: value => (isEmpty(value) ? '' : value.replace(/^0?(\d*)/, '0$1')),
}

export default marshaller
