import { action, failureAction, payloadAction } from '../../action-factory'
import apiAction, { ActionBundle } from '../../api-action'
import { payloadActionStep } from '../../helpers'
import { ActionFactory, FailureActionFactory, Nullable, PayloadActionFactory, Route } from '../../types/core'
import { StatelessPayloadThunk } from '../../types/thunk'
import { withEndpoint } from '../app/helpers'
import { advertNavigationApi, confirmReusableCollectionApi, fetchAdvertApi, fetchWtnApi } from './api'
import {
  AdvertPayloadWithRef,
  AdvertReference,
  AdvertResponse,
  CollectionAgreedPayload,
  CollectJunkPayload,
  ConfirmReusableCollectionPayload,
  FetchWtnResponse,
} from './types'
import {
  COLLECT_FOR_FREE_FLOW_FAILURE,
  COLLECT_FOR_FREE_FLOW_START,
  COLLECT_FOR_FREE_FLOW_SUCCESS,
  COLLECT_JUNK_FLOW_FAILURE,
  COLLECT_JUNK_FLOW_START,
  COLLECT_JUNK_FLOW_SUCCESS,
  CollectForFreeFlowFailure,
  CollectForFreeFlowStart,
  CollectForFreeFlowSuccess,
  CollectJunkFlowFailure,
  CollectJunkFlowStart,
  CollectJunkFlowSuccess,
  CONFIRM_REUSABLE_COLLECTION_FAILURE,
  CONFIRM_REUSABLE_COLLECTION_INTENT,
  CONFIRM_REUSABLE_COLLECTION_START,
  CONFIRM_REUSABLE_COLLECTION_SUCCESS,
  ConfirmReusableCollectionFailure,
  ConfirmReusableCollectionIntent,
  ConfirmReusableCollectionResult,
  ConfirmReusableCollectionStart,
  ConfirmReusableCollectionSuccess,
  FETCH_ADVERT_FAILURE,
  FETCH_ADVERT_START,
  FETCH_ADVERT_SUCCESS,
  FETCH_WTN_FAILURE,
  FETCH_WTN_START,
  FETCH_WTN_SUCCESS,
  FetchAdvertFailure,
  FetchAdvertResult,
  FetchAdvertStart,
  FetchAdvertSuccess,
  FetchWtn,
  FetchWtnFailure,
  FetchWtnStart,
  FetchWtnSuccess,
  SET_ADVERT_NEEDS_FETCH,
  SET_ADVERT_URL,
  SET_BACK_TO,
  SET_COLLECTION_AGREED_PAYLOAD,
  SetAdvertNeedsFetch,
  SetAdvertUrl,
  SetBackTo,
  SetCollectionAgreedPayload,
} from './types/actions'

export const fetchAdvertStart: ActionFactory<FetchAdvertStart> = action(FETCH_ADVERT_START)
export const fetchAdvertSuccess: PayloadActionFactory<FetchAdvertSuccess, AdvertResponse> =
  payloadAction(FETCH_ADVERT_SUCCESS)
export const fetchAdvertFailure: FailureActionFactory<FetchAdvertFailure> = failureAction(FETCH_ADVERT_FAILURE)

const fetchAdvertActionBundle: ActionBundle<FetchAdvertStart, FetchAdvertSuccess, FetchAdvertFailure, AdvertResponse> =
  [fetchAdvertStart, fetchAdvertSuccess, fetchAdvertFailure]

export const advertNavigation: StatelessPayloadThunk<AdvertReference, FetchAdvertResult> = reference =>
  withEndpoint(
    'advertNavigation',
    advertNavigationUrl => dispatch =>
      dispatch(apiAction(fetchAdvertActionBundle)(advertNavigationApi(advertNavigationUrl, reference))),
    fetchAdvertFailure,
  )

export const fetchAdvert: StatelessPayloadThunk<string, FetchAdvertResult> = url =>
  apiAction(fetchAdvertActionBundle)(fetchAdvertApi(url))

export const setAdvertNeedsFetch: PayloadActionFactory<SetAdvertNeedsFetch, SetAdvertNeedsFetch['payload']> =
  payloadAction(SET_ADVERT_NEEDS_FETCH)

export const setAdvertUrl: PayloadActionFactory<SetAdvertUrl, AdvertPayloadWithRef> = payloadAction(SET_ADVERT_URL)

export const setBackTo: PayloadActionFactory<SetBackTo, Nullable<Route>> = payloadAction(SET_BACK_TO)

export const collectJunkFlowStart: PayloadActionFactory<CollectJunkFlowStart, CollectJunkPayload> =
  payloadAction(COLLECT_JUNK_FLOW_START)
export const collectJunkFlowSuccess: ActionFactory<CollectJunkFlowSuccess> = action(COLLECT_JUNK_FLOW_SUCCESS)
export const collectJunkFlowFailure: FailureActionFactory<CollectJunkFlowFailure> =
  failureAction(COLLECT_JUNK_FLOW_FAILURE)

export const collectForFreeFlowStart: PayloadActionFactory<CollectForFreeFlowStart, CollectJunkPayload> =
  payloadAction(COLLECT_FOR_FREE_FLOW_START)
export const collectForFreeFlowSuccess: ActionFactory<CollectForFreeFlowSuccess> = action(COLLECT_FOR_FREE_FLOW_SUCCESS)
export const collectForFreeFlowFailure: FailureActionFactory<CollectForFreeFlowFailure> =
  failureAction(COLLECT_FOR_FREE_FLOW_FAILURE)

export const confirmReusableCollectionIntent: PayloadActionFactory<
  ConfirmReusableCollectionIntent,
  Nullable<ConfirmReusableCollectionPayload>
> = payloadAction(CONFIRM_REUSABLE_COLLECTION_INTENT)

const confirmReusableCollectionActionBundle: ActionBundle<
  ConfirmReusableCollectionStart,
  ConfirmReusableCollectionSuccess,
  ConfirmReusableCollectionFailure
> = [
  action(CONFIRM_REUSABLE_COLLECTION_START),
  action(CONFIRM_REUSABLE_COLLECTION_SUCCESS),
  failureAction(CONFIRM_REUSABLE_COLLECTION_FAILURE),
]

export const confirmReusableCollection: StatelessPayloadThunk<
  ConfirmReusableCollectionPayload,
  ConfirmReusableCollectionResult
> =
  ({ advertUrl, reuseUrl }) =>
  dispatch =>
    dispatch(apiAction(confirmReusableCollectionActionBundle)(confirmReusableCollectionApi(reuseUrl)))
      .then(payloadActionStep(fetchAdvert)(CONFIRM_REUSABLE_COLLECTION_SUCCESS, advertUrl))
      .then(action => dispatch(action))

export const fetchWtnStart: ActionFactory<FetchWtnStart> = action(FETCH_WTN_START)
export const fetchWtnSuccess: PayloadActionFactory<FetchWtnSuccess, FetchWtnResponse> = payloadAction(FETCH_WTN_SUCCESS)

const fetchWtnActionBundle: ActionBundle<FetchWtnStart, FetchWtnSuccess, FetchWtnFailure, FetchWtnResponse> = [
  fetchWtnStart,
  fetchWtnSuccess,
  failureAction(FETCH_WTN_FAILURE),
]

export const fetchWtn: StatelessPayloadThunk<string, FetchWtn> = url => dispatch =>
  dispatch(apiAction(fetchWtnActionBundle)(fetchWtnApi(url)))

export const setCollectionAgreedPayload: PayloadActionFactory<SetCollectionAgreedPayload, CollectionAgreedPayload> =
  payloadAction(SET_COLLECTION_AGREED_PAYLOAD)
