import { FavouriteId, Get, Nullable } from '../../types/core'
import { uniqueNumber } from '../../utils/number'
import {
  ChatActions,
  ChatActionUrls,
  ChatAdvertInfo,
  ChatMessage,
  ChatMessagesVersion,
  ChatOtherParticipants,
  StartConferenceCallPayload,
} from './types'

export const defaultChatMessage: Get<ChatMessage> = () => ({
  authorName: 'owner',
  id: -uniqueNumber(), // negative to distinct from real messages
  isAuthor: true,
  type: 'chat',
  sentAt: new Date(),
})

export interface ChatState {
  actionUrls: ChatActionUrls
  isPhoneRequestedModalVisible: boolean
  messages: ChatMessage[]
  isConferenceCallModalVisible: boolean
  startConferenceCallPayload: Nullable<StartConferenceCallPayload>
  version: ChatMessagesVersion
  viewPhoneInProgress: boolean

  advertInfo?: ChatAdvertInfo
  favouriteId?: FavouriteId
  otherParticipants?: ChatOtherParticipants
}

export const initialChatActions: ChatActions = {}
export const initialChatActionUrls: ChatActionUrls = {}
export const initialChatMessages: ChatMessage[] = []
export const initialChatVersion = 0

export const chatDefaultState: ChatState = {
  actionUrls: initialChatActionUrls,
  isPhoneRequestedModalVisible: false,
  isConferenceCallModalVisible: false,
  startConferenceCallPayload: null,
  messages: initialChatMessages,
  version: initialChatVersion,
  viewPhoneInProgress: false,
}

export default ChatState
