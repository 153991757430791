import { compact, flow, includes, isEmpty, isEqual, isUndefined } from 'lodash/fp'

import { areAllDefined, isDefined, isNotNull } from '../../helpers'
import { Nullable, Optional, PaymentMethod, Postcode, Transform, Validation } from '../../types/core'
import {
  CollectorInfo,
  CollectorMeta,
  CustomerInfo,
  CustomerMeta,
  FavouriteInteractions,
  JunkLoverInfo,
  JunkLoverMeta,
  Meta,
  TipperMeta,
  TradeCustomerMeta,
} from './types'

export const isCollectorMeta = (meta: Meta): meta is CollectorMeta => {
  const { collectorActivitiesUrl, collectorDisposalSitesUrl, collectorInfo, collectorStripeInfo } =
    meta as CollectorMeta

  return areAllDefined(collectorActivitiesUrl, collectorDisposalSitesUrl, collectorInfo, collectorStripeInfo)
}

export const isJunkLoverMeta = (meta: Meta): meta is JunkLoverMeta => {
  const { junkLoverActivitiesUrl, junkLoverInfo } = meta as JunkLoverMeta

  return areAllDefined(junkLoverActivitiesUrl, junkLoverInfo)
}

export const isTipperMeta = (meta: Meta): meta is TipperMeta => {
  const { tipperInfo } = meta as TipperMeta

  return tipperInfo != null
}

export const isCustomerMeta = (meta: Meta): meta is CustomerMeta => {
  const { advertCreationUrl, customerActivitiesUrl, customerPaymentCapabilities, customerStripeInfo } =
    meta as CustomerMeta

  return (
    !isUndefined(advertCreationUrl) &&
    !isUndefined(customerActivitiesUrl) &&
    !isUndefined(customerPaymentCapabilities) &&
    !isUndefined(customerStripeInfo)
  )
}

export const isCollectorMetaPresent = (meta: Nullable<Meta>): meta is CollectorMeta =>
  isNotNull(meta) && isCollectorMeta(meta)

export const isCustomerMetaPresent = (meta: Nullable<Meta>): meta is CustomerMeta =>
  isNotNull(meta) && isCustomerMeta(meta)

export const isJunkLoverMetaPresent = (meta: Nullable<Meta>): meta is JunkLoverMeta =>
  isNotNull(meta) && isJunkLoverMeta(meta)

export const isTradeCustomerMetaPresent = (meta: Nullable<Meta>): meta is TradeCustomerMeta =>
  isNotNull(meta) && isDefined(meta.actions.tradeCustomerPortal)

const hasPaymentMethod = flow<[PaymentMethod], Validation<PaymentMethod[]>, Validation<CustomerMeta>>(
  includes,
  check =>
    ({ customerPaymentCapabilities }) =>
      check(customerPaymentCapabilities),
)

const isPreferredPaymentMethod = flow<[PaymentMethod], Validation<Optional<PaymentMethod>>, Validation<CustomerMeta>>(
  isEqual,
  check =>
    ({ customerInfo: { preferredPaymentMethod } }) =>
      check(preferredPaymentMethod),
)

export const hasNativePay = hasPaymentMethod('native')
export const hasCardPay = hasPaymentMethod('card')
export const hasNoPaymentMethod = ({ customerPaymentCapabilities }: CustomerMeta) =>
  isEmpty(customerPaymentCapabilities)
export const isCardPreferred = isPreferredPaymentMethod('card')
export const isNativePreferred = isPreferredPaymentMethod('native')
export const getOperationalCentre: Transform<Meta, Optional<Postcode>> = meta =>
  isCollectorMeta(meta)
    ? meta.collectorInfo.operationalCentre
    : isJunkLoverMeta(meta)
    ? meta.junkLoverInfo.postcode
    : undefined

export const isJunkLoverInfo = (info: JunkLoverInfo | CollectorInfo): info is JunkLoverInfo =>
  isDefined((info as JunkLoverInfo).postcode)

interface RoleInfo {
  collectorInfo: CollectorInfo
  customerInfo: CustomerInfo
  junkLoverInfo: JunkLoverInfo
}

export const roleInfo: Transform<Nullable<Meta>, Partial<RoleInfo>> = meta => ({
  collectorInfo: isCollectorMetaPresent(meta) ? meta.collectorInfo : undefined,
  customerInfo: isCustomerMetaPresent(meta) ? meta.customerInfo : undefined,
  junkLoverInfo: isJunkLoverMetaPresent(meta) ? meta.junkLoverInfo : undefined,
})

export type RoleReference = [string, string]

export const roleReference: Transform<Partial<RoleInfo>, RoleReference[]> = ({
  collectorInfo,
  customerInfo,
  junkLoverInfo,
}) =>
  compact([
    customerInfo && ['Customer', customerInfo.reference],
    collectorInfo && ['Collector', collectorInfo.reference],
    junkLoverInfo && ['Junk Reuser', junkLoverInfo.reference],
  ])

export const isFavouriteInteractions = (value: string): value is FavouriteInteractions => {
  return includes(value)(['listFavouriteCollectorInteractions', 'listFavouriteUserInteractions'])
}
