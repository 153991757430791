import { flow } from 'lodash/fp'

import { buildLens, isNotNull, lensFactory } from '../../helpers'
import RootState from '../../state'
import { GetLens } from '../../types/core'
import State from './state'

export const loginRootLens: GetLens<RootState, State> = state => () => state.login

const lens = lensFactory<State>()

export const accountSuspendedLens = lens('accountSuspended')
export const loginErrorLens = lens('error')
export const isSigningInLens = lens('isSigningIn')
export const sessionTokenLens = lens('token')

export const getSessionToken = buildLens(loginRootLens, sessionTokenLens)

export const isLoggedIn = flow(getSessionToken, isNotNull)

export default loginRootLens
