import { AdvertItem, Get } from '@lovejunk/core'

interface State {
  items: AdvertItem[]
}

export const defaultState: Get<State> = () => ({
  items: [],
})

export default State
