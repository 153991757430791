import { GetLens, lensFactory, Optional, Suggestion } from '@lovejunk/core'
import RootState from 'core/state'
import { find } from 'lodash/fp'

import State from './state'

export const addressRootLens: GetLens<RootState, State> = state => () => state.address

const lens = lensFactory<State>()

export const addressListLens = lens('list')
export const isPostcodeSupportedLens = lens('isPostcodeSupported')
export const isPostcodeValidLens = lens('isPostcodeValid')
export const isUlezPostcodeLens = lens('isUlezPostcode')
export const postcodeLens = lens('postcode')
export const wasPostcodeCheckedLens = lens('wasPostcodeChecked')

export const findAddress: GetLens<State, Optional<Suggestion>, Suggestion['id']> = state => id =>
  find<Suggestion>({ id })(addressListLens(state).get())

export default addressRootLens
