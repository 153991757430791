import { action, failureAction, payloadAction } from '../../action-factory'
import apiAction, { ActionBundle } from '../../api-action'
import { ActionFactory, FailureActionFactory, PayloadActionFactory } from '../../types/core'
import { StatelessOptionalPayloadThunk } from '../../types/thunk'
import { withEndpoint } from '../app/helpers'
import { getTagsApi } from './api'
import {
  FETCH_TAGS_FAILURE,
  FETCH_TAGS_START,
  FETCH_TAGS_SUCCESS,
  FetchTagsFailure,
  FetchTagsResult,
  FetchTagsStart,
  FetchTagsSuccess,
  Tags,
  TagsParams,
} from './types'

export const fetchTagsFailure: FailureActionFactory<FetchTagsFailure> = failureAction(FETCH_TAGS_FAILURE)
export const fetchTagsActionBundle: ActionBundle<FetchTagsStart, FetchTagsSuccess, FetchTagsFailure, Tags> = [
  action(FETCH_TAGS_START),
  payloadAction(FETCH_TAGS_SUCCESS),
  fetchTagsFailure,
]
export const fetchTagsStart: ActionFactory<FetchTagsStart> = action(FETCH_TAGS_START)
export const fetchTagsSuccess: PayloadActionFactory<FetchTagsSuccess, Tags> = payloadAction(FETCH_TAGS_SUCCESS)

export const fetchTags: StatelessOptionalPayloadThunk<TagsParams, FetchTagsResult> = params =>
  withEndpoint(
    'tags',
    url => dispatch => dispatch(apiAction(fetchTagsActionBundle)(getTagsApi(url, params))),
    fetchTagsFailure,
  )
