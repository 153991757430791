import {
  action,
  ActionFactory,
  changeApiUrl as coreChangeApiUrl,
  ClearStorage,
  CreateSetStorageItem,
  Dispatch,
  fetchApiUrl as coreFetchApiUrl,
  FetchApiUrlPayload,
  getApiUrlLens,
  getConfiguration as coreGetConfiguration,
  GetConfigurationResult,
  GetStorageItem,
  LoginPayload,
  onConfiguration,
  payloadAction,
  PayloadActionFactory,
  SetApiUrlFetched,
  Transform,
} from '@lovejunk/core'
import { PERSIST_CONFIG_KEY } from 'core/reducer'
import { flow, toString } from 'lodash/fp'
import NavigationPath from 'navigation/paths'
import { generatePath } from 'react-router-dom'
import storage from 'redux-persist/lib/storage'
import { PayloadThunk, Thunk } from 'types/thunk'
import { searchParamsToString } from 'utils'
import { apiUrl as stagingUrl, isProductionEnv as isProduction } from 'utils/environment'

import { ADVERTS_VISITED, AdvertsVisited, NAVIGATE, Navigate, SET_MENU_VISIBLE, SetMenuVisible } from './types'

const clearStorage: ClearStorage = () => storage.removeItem(`persist:${PERSIST_CONFIG_KEY}`)
const getItem: GetStorageItem = apiUrlKey => storage.getItem(apiUrlKey)
const createSetItem: CreateSetStorageItem = url => apiUrlKey => storage.setItem(apiUrlKey, url)

const createFetchApiUrlPayload: Transform<boolean, FetchApiUrlPayload> = preserveStore => ({
  clearStorage,
  defaultUrl: stagingUrl,
  getItem,
  preserveStore,
})

export const fetchApiUrl: PayloadThunk<boolean, SetApiUrlFetched> = preserveStore => dispatch =>
  dispatch(coreFetchApiUrl(createFetchApiUrlPayload(preserveStore)))

export const changeApiUrl: PayloadThunk<string, SetApiUrlFetched> = url => dispatch =>
  dispatch(
    coreChangeApiUrl({
      ...createFetchApiUrlPayload(false),
      clearStorage,
      setItem: createSetItem(url),
    }),
  )

export const getConfiguration: Thunk<GetConfigurationResult> = () => (dispatch, getState) =>
  dispatch(coreGetConfiguration()).then(
    onConfiguration({
      apiUrl: getApiUrlLens(getState()),
      reset: flow(changeApiUrl, dispatch),
      isProduction,
      stagingUrl,
    }),
  )

export const advertsVisited: ActionFactory<AdvertsVisited> = action(ADVERTS_VISITED)
export const navigate: PayloadActionFactory<Navigate, Navigate['payload']> = payloadAction(NAVIGATE)

interface NavigateToOtpPayload extends LoginPayload {
  url: string
}

export const navigateToOtp: Dispatch<NavigateToOtpPayload, Navigate> = ({ identifier, type, url }) =>
  navigate({
    to: {
      pathname: generatePath(NavigationPath.VerifyOtp, { type }),
      search: searchParamsToString({ identifier, length: toString(6), url }),
    },
  })

export const setMenuVisible: PayloadActionFactory<SetMenuVisible, SetMenuVisible['payload']> =
  payloadAction(SET_MENU_VISIBLE)
