import { get, put } from '../../api'
import { SimpleApi } from '../../api-action'
import { responseToBody } from '../../helpers'
import { Transform } from '../../types/core'
import { AdvertNavigationResponse, AdvertResponse, FetchWtnResponse } from './types'

export const advertNavigationApi = (url: string, reference: string): SimpleApi<AdvertResponse> =>
  get<AdvertNavigationResponse>(url, { params: { reference }, timeoutAlert: true })
    .then(responseToBody)
    .then(({ location }) => location)
    .then(fetchAdvertApi)

export const confirmReusableCollectionApi: Transform<string, SimpleApi> = put

export const fetchAdvertApi: Transform<string, SimpleApi<AdvertResponse>> = url => get(url, { timeoutAlert: true })

export const fetchWtnApi: Transform<string, SimpleApi<FetchWtnResponse>> = get
