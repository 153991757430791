import { debounce } from 'lodash'
import { useEffect, useMemo } from 'react'

import { LoadSize } from '../entities/advert/types'
import { PriceSuggestionPayload } from '../entities/new-advert/types'
import { undefineNull } from '../helpers'
import { Dispatch, Nullable } from '../types/core'

interface PriceSuggestionProps {
  builderWaste: Nullable<boolean>
  easyAccess: Nullable<boolean>
  loadSize: Nullable<LoadSize>
  reusable: Nullable<boolean>
  title: string
}

const usePriceSuggestion = (
  priceSuggestion: Dispatch<PriceSuggestionPayload>,
  { builderWaste, easyAccess, loadSize, reusable, title }: PriceSuggestionProps,
) => {
  const priceSuggestionPayload = useMemo<PriceSuggestionPayload>(
    () => ({
      builderWaste: undefineNull(builderWaste),
      easyAccess: undefineNull(easyAccess),
      reusable: undefineNull(reusable),
      size: undefineNull(loadSize),
      title,
    }),
    [builderWaste, easyAccess, loadSize, reusable, title],
  )
  const debouncedPriceSuggestion = useMemo(() => debounce(priceSuggestion, 1000), [priceSuggestion])

  useEffect(() => {
    debouncedPriceSuggestion(priceSuggestionPayload)
  }, [priceSuggestionPayload, debouncedPriceSuggestion])
}

export default usePriceSuggestion
