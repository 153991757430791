import { Action } from 'redux'

import { FailureAction, PayloadAction } from '../../types/core'

export const EMAIL_REQUEST_START = 'EMAIL_REQUEST_START'
export const EMAIL_REQUEST_SUCCESS = 'EMAIL_REQUEST_SUCCESS'
export const EMAIL_REQUEST_FAILURE = 'EMAIL_REQUEST_FAILURE'

export type EmailRequestStart = Action<typeof EMAIL_REQUEST_START>
export type EmailRequestSuccess = PayloadAction<typeof EMAIL_REQUEST_SUCCESS, EmailRequestData>
export type EmailRequestFailure = FailureAction<typeof EMAIL_REQUEST_FAILURE>
export type EmailRequestResult = EmailRequestSuccess | EmailRequestFailure
export type EmailRequest = EmailRequestStart | EmailRequestResult

export const CLOSE_EMAIL_REQUEST_MODAL = 'CLOSE_EMAIL_REQUEST_MODAL'
export type CloseEmailRequestModal = Action<typeof CLOSE_EMAIL_REQUEST_MODAL>

export type EmailAction = CloseEmailRequestModal | EmailRequest

export type EmailRequestType = 'wtn' | 'receipt'
export type ReceiptRequestAdvertAction = 'customerReceipt' | 'collectorReceipt' | 'junkLoverReceipt'
export type WtnRequestAdvertAction = 'customerWtn' | 'collectorWtn' | 'junkLoverWtn'
export type EmailRequestAdvertAction = ReceiptRequestAdvertAction | WtnRequestAdvertAction

export interface EmailRequestViewData {
  modalTitle: string
  emailTitle: string
  emailSubtitle: string
}

export interface EmailRequestData {
  email: string
  type: EmailRequestType
}

export interface EmailRequestPayload {
  email: string
  type: EmailRequestType
  url: string
}
