import {
  advertDuration,
  AdvertIdAndUrl,
  AdvertPayloadWithRef,
  AdvertReference,
  ChatIconProps,
  Color,
  Dispatch,
  formatCollectionTimeSlot,
  formatCompletedDateAndTime,
  formatOpenTimeSlot,
  isDefined,
  isEditableActivityItem,
  isOfferItem,
  NoOp,
  Optional,
  postcodeOrDistrict,
  statusText,
  SupplierAdvertItem,
  Transform,
} from '@lovejunk/core'
import NavigationPath from 'navigation/paths'
import React, { FC, PropsWithChildren, useCallback, useMemo } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { styled } from 'styled'
import { searchParamsToString } from 'utils'
import { device } from 'utils/css'
import { isMobile } from 'utils/environment'

import ChatIcon from '../../ChatIcon'
import Column from '../../containers/Column'
import Row from '../../containers/Row'
import Link from '../../Link'
import BaseText from '../../text/BaseText'
import Image from './Image'
import Status from './Status'
import Title from './Title'

interface Props {
  isCollector: boolean
  isJunkLover: boolean
  item: SupplierAdvertItem
  setAdvertUrl: Dispatch<AdvertPayloadWithRef>
}

type OnAdvertPressPayload = AdvertIdAndUrl

interface CreateOnChatPressPayload {
  viewMessagesUrl: string
}

const AdvertsItem: FC<Props> = props => {
  const { isCollector, item, setAdvertUrl } = props
  const {
    actions: { viewAdvert, viewMessages },
    advertId,
    advertReference,
    advertStatus,
    advertTitle,
    endAt,
    fixedTimeSlotChoice,
    hasUnreadMessages,
    imageUrl,
    openTimeSlotChoice,
    price,
    startAt,
  } = item
  const isActivityItemMode = !isOfferItem(item)
  const { collectedAt, jobStatus, offeredAt } = isActivityItemMode
    ? { collectedAt: item.collectedAt, jobStatus: item.jobStatus, offeredAt: item.offeredAt }
    : { collectedAt: undefined, jobStatus: undefined, offeredAt: undefined }
  const navigate = useNavigate()

  const collectionTime = useMemo(() => {
    if (collectedAt && jobStatus === 'complete') return formatCompletedDateAndTime(collectedAt.toISOString())

    if (advertStatus)
      return openTimeSlotChoice
        ? formatOpenTimeSlot(openTimeSlotChoice)
        : formatCollectionTimeSlot(
            { startAt: startAt.toISOString(), endAt: endAt.toISOString(), fixedTimeSlotChoice },
            { weekDay: true },
          )
  }, [advertStatus, collectedAt, endAt, fixedTimeSlotChoice, jobStatus, openTimeSlotChoice, startAt])

  const viewAdvertUrl = useMemo(() => viewAdvert?.url, [viewAdvert])

  const onAdvertPressPayload = useMemo<Optional<OnAdvertPressPayload>>(
    () => (viewAdvertUrl ? { advertId, advertUrl: viewAdvertUrl } : undefined),
    [advertId, viewAdvertUrl],
  )

  const createOnPress = useCallback<Transform<OnAdvertPressPayload, NoOp>>(
    ({ advertUrl }) =>
      () => {
        setAdvertUrl({ reference: advertReference, url: advertUrl })
        navigate({ pathname: generatePath(NavigationPath.Advert, { seoReference: advertReference }) })
      },
    [advertReference, navigate, setAdvertUrl],
  )

  const createOnAdvertLinkPress = useCallback<Transform<OnAdvertPressPayload, NoOp>>(
    ({ advertUrl }) =>
      () => {
        setAdvertUrl({ reference: advertReference, url: advertUrl })
      },
    [advertReference, setAdvertUrl],
  )

  const onAdvertLinkPress = useMemo(
    () => onAdvertPressPayload && createOnAdvertLinkPress(onAdvertPressPayload),
    [onAdvertPressPayload, createOnAdvertLinkPress],
  )

  const onPress = useMemo(
    () => onAdvertPressPayload && createOnPress(onAdvertPressPayload),
    [createOnPress, onAdvertPressPayload],
  )

  const createOnChatPressPayload = useMemo<Optional<CreateOnChatPressPayload>>(
    () => (viewMessages ? { viewMessagesUrl: viewMessages.url } : undefined),
    [viewMessages],
  )

  const chatNavigatesToAdvert = useMemo(() => !viewMessages && hasUnreadMessages, [hasUnreadMessages, viewMessages])

  const onAdvertPress = useMemo(() => (chatNavigatesToAdvert ? onPress : undefined), [chatNavigatesToAdvert, onPress])

  const createOnChatPress = useCallback<Transform<CreateOnChatPressPayload, NoOp>>(
    ({ viewMessagesUrl }) =>
      () =>
        navigate({
          pathname: generatePath(NavigationPath.Chat, { ref: advertReference }),
          search: searchParamsToString({ viewMessagesUrl, advertUrl: viewAdvertUrl }),
        }),
    [advertReference, navigate, viewAdvertUrl],
  )

  const onChatPress = useMemo(
    () => createOnChatPressPayload && createOnChatPress(createOnChatPressPayload),
    [createOnChatPress, createOnChatPressPayload],
  )

  const chatIconProps = useMemo<Optional<ChatIconProps>>(
    () =>
      (onChatPress && { dot: hasUnreadMessages, onPress: onChatPress }) ||
      (onAdvertPress && { dot: hasUnreadMessages, onPress: onAdvertPress }) ||
      undefined,
    [hasUnreadMessages, onAdvertPress, onChatPress],
  )

  const duration = offeredAt && advertDuration(offeredAt)
  const postcode = postcodeOrDistrict(item, advertStatus, isCollector)
  const isEditable = useMemo(() => isActivityItemMode && isEditableActivityItem(item), [item, isActivityItemMode])

  return (
    <Container>
      <LinkToAdvert onPress={onAdvertLinkPress} reference={advertReference}>
        <ImageContainer>
          <Image imageUrl={imageUrl} title={advertTitle} />
          <ImageOverlay>
            <Status advertStatus={advertStatus} jobStatus={jobStatus} price={price} />
            {duration && isCollector && <Duration>{duration}</Duration>}
          </ImageOverlay>
        </ImageContainer>
      </LinkToAdvert>
      <Body>
        <BodyHeader>
          <LinkToAdvert onPress={onAdvertLinkPress} reference={advertReference}>
            <Title price={price} title={advertTitle} />
          </LinkToAdvert>
          {isDefined(jobStatus) && <StatusText>{statusText(advertStatus, jobStatus)}</StatusText>}
          {collectionTime && <CollectionTimeSlot>{collectionTime}</CollectionTimeSlot>}
        </BodyHeader>
        {(isDefined(postcode) || isEditable || chatIconProps) && (
          <BodyFooter>
            {isDefined(postcode) && <Postcode>{postcode}</Postcode>}
            {isEditable && (
              <LinkToAdvert color="secondary" onPress={onAdvertLinkPress} reference={advertReference} underline>
                Edit & Repost
              </LinkToAdvert>
            )}
            {chatIconProps && <StyledChatIcon {...chatIconProps} />}
          </BodyFooter>
        )}
      </Body>
    </Container>
  )
}

interface LinkToAdvertProps {
  reference: AdvertReference

  color?: Color
  onPress?: NoOp
  underline?: boolean
}

const LinkToAdvert: FC<PropsWithChildren<LinkToAdvertProps>> = ({
  children,
  color = 'black',
  onPress,
  reference: seoReference,
  underline,
}) =>
  isDefined(onPress) ? (
    <Link color={color} params={{ seoReference }} onPress={onPress} to={NavigationPath.Advert} $underline={underline}>
      {children}
    </Link>
  ) : (
    <div>{children}</div>
  )

export const Container = styled(Row)`
  padding: 0.375em;
  border-radius: 8px;
  border: 1px solid ${({ theme: { colors } }) => colors.light};
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 0 6px 4px ${({ theme: { colors } }) => colors.grey};
  }

  @media ${device.tablet} {
    &:hover {
      box-shadow: none;
    }
  }
`

export const Body = styled(Column)`
  flex: 1;
  justify-content: space-between;
  padding: 0.5em;
`

export const BodyHeader = styled.div``

export const BodyFooter = styled(Row)`
  align-items: flex-end;
  justify-content: space-between;
`

const borderRadius = 0.5 * Number(!isMobile) + 'em'

export const ImageContainer = styled(Column)`
  background-color: ${({ theme: { colors } }) => colors.black};
  border-radius: ${borderRadius};
  flex-grow: 0;
  height: 8.75em;
  position: relative;
  width: 8.75em;

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.6) 0%,
      transparent 30%,
      transparent 70%,
      rgba(0, 0, 0, 0.6) 100%
    );
    border-radius: ${borderRadius};
    pointer-events: none;
    content: '';
    z-index: 1;
  }
`

const ImageOverlay = styled(Column)`
  justify-content: space-between;
  flex: 1;
  z-index: 1;
`

const Duration = styled.label`
  margin: 10px;
  font-weight: bold;
  align-self: flex-start;
  color: ${({ theme: { colors } }) => colors.white};
`

const StatusText = styled(BaseText)`
  font-style: italic;
`

export const CollectionTimeSlot = BaseText

export const Postcode = styled(BaseText)`
  color: ${({ theme: { colors } }) => colors.greyText};
  text-transform: uppercase;
`

const StyledChatIcon = styled(ChatIcon)`
  margin: 0 -0.5em -1em 0;
  padding: 1em;
`

export default AdvertsItem
