import {
  ADD_CARD_SCREEN_POST_SETUP_TITLE,
  ADD_CARD_SUBMIT_CAPTION_COMPLETE_PAYMENT,
  ADD_CARD_SUBMIT_CAPTION_CONFIRM_BOOKING,
  ADD_CARD_SUBMIT_CAPTION_DEFAULT,
} from '../../constants'
import { Format, Nullable } from '../../types/core'
import { PostSetup } from './types'

interface PaymentButtonCaptionPayload {
  postSetup: Nullable<PostSetup>

  confirmPayment?: boolean
}

export const paymentButtonCaption: Format<PaymentButtonCaptionPayload> = ({ confirmPayment, postSetup }) =>
  postSetup
    ? ADD_CARD_SUBMIT_CAPTION_CONFIRM_BOOKING
    : confirmPayment
    ? ADD_CARD_SUBMIT_CAPTION_COMPLETE_PAYMENT
    : ADD_CARD_SUBMIT_CAPTION_DEFAULT

interface PaymentButtonTitlePayload {
  postSetup: Nullable<PostSetup>
  defaultText: string
}

export const paymentButtonTitle: Format<PaymentButtonTitlePayload> = ({ defaultText, postSetup }) =>
  postSetup ? ADD_CARD_SCREEN_POST_SETUP_TITLE : defaultText
