import { lensFactory } from '../../helpers'
import RootState from '../../state'
import { GetLens } from '../../types/core'
import State from './state'

const lens = lensFactory<State>()

export const addCardErrorLens = lens('addCardError')
export const configLens = lens('config')
export const inProgressLens = lens('inProgress')
export const paymentPostSetupLens = lens('postSetup')

export const paymentRootLens: GetLens<RootState, State> = state => () => state.payment

export default paymentRootLens
