import { TabViewElementsProps, tabViewFactory as factory } from '@lovejunk/core'
import React, { FC } from 'react'
import { styled } from 'styled'

import CenteredRow from './containers/CenteredRow'
import Column from './containers/Column'
import Row from './containers/Row'
import Text from './text/Text'

type OnTabPress = TabViewElementsProps['Tab']['onPress']

interface TabRootProps extends Omit<TabViewElementsProps['Tab'], 'onPress'> {
  onClick: OnTabPress
}

const TabRoot = styled(Text).attrs<TabRootProps>(({ selected }) => ({
  bold: true,
  centered: true,
  color: selected ? 'secondary' : 'greyText',
}))<TabRootProps>`
  border-bottom-width: 0.25em;
  border-bottom-style: solid;
  border-bottom-color: ${({ selected, theme: { colors } }) => (selected ? colors.secondary : 'transparent')};
  cursor: ${({ selected }) => (selected ? 'auto' : 'pointer')};
  flex: 1;
  padding: 0.5em;
`

const Tab: FC<TabViewElementsProps['Tab']> = ({ onPress: onClick, ...props }) => (
  <TabRoot {...props} onClick={onClick} />
)

export default <T,>() =>
  factory<T>({
    Root: Column,
    Tab,
    Tabs: styled(CenteredRow)`
      gap: 0.25em;
      margin-bottom: 0.5em;
    `,
    View: styled(Column)<TabViewElementsProps['View']>`
      flex: 1;
      display: ${({ visible }) => (visible ? 'initial' : 'none')};
    `,
    Views: Row,
  })
