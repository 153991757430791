import {
  AdvertActionPayload,
  CancellationReasonPayload,
  customerAdvertDefaultState,
  CustomerAdvertState,
  Get,
  Nullable,
} from '@lovejunk/core'

interface State extends CustomerAdvertState {
  cancelAdvertPayload: Nullable<AdvertActionPayload>
  relistOrCancelPayload: Nullable<CancellationReasonPayload>
}

export const defaultState: Get<State> = () => ({
  ...customerAdvertDefaultState(),
  cancelAdvertPayload: null,
  relistOrCancelPayload: null,
})

export default State
