import { Action } from 'redux'

import { FailureAction, PayloadAction, Response, TagBase } from '../../types/core'

export interface Tag extends TagBase {
  seoTitle: string
  seoDescription: string

  guideUrl?: string
  guideUrlText?: string
  textContent?: string
  textTitle?: string
  title?: string
}

export type Tags = Tag[]

export interface TagsResponseBody {
  entities: Tags
}

interface SupportedTagsParams {
  shouldShowInGeneral: boolean
  shouldShowInReusables: boolean
}

export type TagsParams = Partial<SupportedTagsParams>

export type TagsResponse = Response<TagsResponseBody>

export const FETCH_TAGS_START = 'FETCH_TAGS_START'
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS'
export const FETCH_TAGS_FAILURE = 'FETCH_TAGS_FAILURE'

export type FetchTagsStart = Action<typeof FETCH_TAGS_START>
export type FetchTagsSuccess = PayloadAction<typeof FETCH_TAGS_SUCCESS, Tags>
export type FetchTagsFailure = FailureAction<typeof FETCH_TAGS_FAILURE>
export type FetchTagsResult = FetchTagsSuccess | FetchTagsFailure

export type FetchTags = FetchTagsStart | FetchTagsResult

export type TagsAction = FetchTags
