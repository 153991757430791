import { lensFactory } from '../../helpers'
import RootState from '../../state'
import { GetLens } from '../../types/core'
import State from './state'

export const freegleRootLens: GetLens<RootState, State> = state => () => state.freegle

const lens = lensFactory<State>()

export const freegleHasPostcodeLens = lens('hasPostcode')
export const freegleIdLens = lens('freegleId')
export const isLoadingFreegleAdvertLens = lens('isLoadingFreegleAdvert')
