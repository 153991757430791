import { toString } from 'lodash/fp'
import Geocode from 'react-geocode'

export const apiKey = toString(process.env.REACT_APP_GOOGLE_API_KEY)

export default () => {
  Geocode.setApiKey(apiKey)
  Geocode.setLanguage('en')
  Geocode.setRegion('uk')
}
