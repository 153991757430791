import Reducer from '../../types/reducer'
import { CHECK_TOKEN_SUCCESS, GET_TOKEN_SUCCESS } from '../oauth/types'
import {
  configurationLens,
  errorLens,
  hadSuccessfulOauthCheckLens,
  isApiUrlFetchedLens,
  isRehydratedLens,
  selectedActivityLens,
} from './lens'
import State, { appDefaultState } from './state'
import {
  DISMISS_ERROR,
  GET_CONFIGURATION_SUCCESS,
  REHYDRATE,
  SET_API_URL_FETCHED,
  SET_SELECTED_ACTIVITY,
} from './types'

export const appReducer: Reducer<State> = (state = appDefaultState, action) => {
  switch (action.type) {
    case DISMISS_ERROR:
      return errorLens(state).set(null)
    case GET_CONFIGURATION_SUCCESS:
      return configurationLens(state).set(action.payload)
    case CHECK_TOKEN_SUCCESS:
      return hadSuccessfulOauthCheckLens(state).set(true)
    case GET_TOKEN_SUCCESS:
      return hadSuccessfulOauthCheckLens(state).set(true)
    case REHYDRATE:
      return isRehydratedLens(state).set(true)
    case SET_API_URL_FETCHED:
      return isApiUrlFetchedLens(state).set(true)
    case SET_SELECTED_ACTIVITY:
      return selectedActivityLens(state).set(action.payload)
    default:
      return state
  }
}

export default appReducer
