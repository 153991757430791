import { action, failureAction, payloadAction } from '../../action-factory'
import apiAction, { ActionBundle } from '../../api-action'
import { NoMetaError, NotJunkLoverError } from '../../errors'
import { FailureActionFactory, Nullable, PaginationMeta } from '../../types/core'
import { StatelessPayloadThunk } from '../../types/thunk'
import { fetchApi, fetchOfferActivitiesApi } from '../activities/api'
import { FetchActivityItemsResponse, FetchSupplierOffersResponse } from '../activities/types'
import { isJunkLoverMetaPresent } from '../meta/helpers'
import { Meta } from '../meta/types'
import {
  FETCH_JUNK_LOVER_ADVERTS_FAILURE,
  FETCH_JUNK_LOVER_ADVERTS_NEXT_PAGE_FAILURE,
  FETCH_JUNK_LOVER_ADVERTS_NEXT_PAGE_START,
  FETCH_JUNK_LOVER_ADVERTS_NEXT_PAGE_SUCCESS,
  FETCH_JUNK_LOVER_ADVERTS_START,
  FETCH_JUNK_LOVER_ADVERTS_SUCCESS,
  FETCH_JUNK_LOVER_HISTORY_ADVERTS_FAILURE,
  FETCH_JUNK_LOVER_HISTORY_ADVERTS_NEXT_PAGE_FAILURE,
  FETCH_JUNK_LOVER_HISTORY_ADVERTS_NEXT_PAGE_START,
  FETCH_JUNK_LOVER_HISTORY_ADVERTS_NEXT_PAGE_SUCCESS,
  FETCH_JUNK_LOVER_HISTORY_ADVERTS_START,
  FETCH_JUNK_LOVER_HISTORY_ADVERTS_SUCCESS,
  FETCH_JUNK_REUSER_OFFERS_FAILURE,
  FETCH_JUNK_REUSER_OFFERS_NEXT_PAGE_FAILURE,
  FETCH_JUNK_REUSER_OFFERS_NEXT_PAGE_START,
  FETCH_JUNK_REUSER_OFFERS_NEXT_PAGE_SUCCESS,
  FETCH_JUNK_REUSER_OFFERS_START,
  FETCH_JUNK_REUSER_OFFERS_SUCCESS,
  FetchJunkLoverAdvertsFailure,
  FetchJunkLoverAdvertsNextPageFailure,
  FetchJunkLoverAdvertsNextPageResult,
  FetchJunkLoverAdvertsNextPageStart,
  FetchJunkLoverAdvertsNextPageSuccess,
  FetchJunkLoverAdvertsResult,
  FetchJunkLoverAdvertsStart,
  FetchJunkLoverAdvertsSuccess,
  FetchJunkLoverHistoryAdvertsFailure,
  FetchJunkLoverHistoryAdvertsNextPageFailure,
  FetchJunkLoverHistoryAdvertsNextPageResult,
  FetchJunkLoverHistoryAdvertsNextPageStart,
  FetchJunkLoverHistoryAdvertsNextPageSuccess,
  FetchJunkLoverHistoryAdvertsResult,
  FetchJunkLoverHistoryAdvertsStart,
  FetchJunkLoverHistoryAdvertsSuccess,
  FetchJunkReuserOffersFailure,
  FetchJunkReuserOffersNextPageFailure,
  FetchJunkReuserOffersNextPageResult,
  FetchJunkReuserOffersNextPageStart,
  FetchJunkReuserOffersNextPageSuccess,
  FetchJunkReuserOffersResult,
  FetchJunkReuserOffersStart,
  FetchJunkReuserOffersSuccess,
} from './types'

const fetchFailure: FailureActionFactory<FetchJunkLoverAdvertsFailure> = failureAction(FETCH_JUNK_LOVER_ADVERTS_FAILURE)

const fetchActionBundle: ActionBundle<
  FetchJunkLoverAdvertsStart,
  FetchJunkLoverAdvertsSuccess,
  FetchJunkLoverAdvertsFailure,
  FetchActivityItemsResponse
> = [action(FETCH_JUNK_LOVER_ADVERTS_START), payloadAction(FETCH_JUNK_LOVER_ADVERTS_SUCCESS), fetchFailure]

export const fetch: StatelessPayloadThunk<Nullable<Meta>, FetchJunkLoverAdvertsResult> = meta => dispatch =>
  isJunkLoverMetaPresent(meta)
    ? dispatch(apiAction(fetchActionBundle)(fetchApi(meta.junkLoverActivitiesUrl)))
    : Promise.resolve(dispatch(fetchFailure(new NotJunkLoverError())))

const fetchNextPageFailure: FailureActionFactory<FetchJunkLoverAdvertsNextPageFailure> = failureAction(
  FETCH_JUNK_LOVER_ADVERTS_NEXT_PAGE_FAILURE,
)

const fetchNextPageActionBundle: ActionBundle<
  FetchJunkLoverAdvertsNextPageStart,
  FetchJunkLoverAdvertsNextPageSuccess,
  FetchJunkLoverAdvertsNextPageFailure,
  FetchActivityItemsResponse
> = [
  action(FETCH_JUNK_LOVER_ADVERTS_NEXT_PAGE_START),
  payloadAction(FETCH_JUNK_LOVER_ADVERTS_NEXT_PAGE_SUCCESS),
  fetchNextPageFailure,
]

export const fetchNextPage: StatelessPayloadThunk<Nullable<PaginationMeta>, FetchJunkLoverAdvertsNextPageResult> =
  paginationMeta => dispatch => {
    if (!paginationMeta) return Promise.resolve(dispatch(fetchNextPageFailure(new Error('no meta in activities'))))

    const { next_url: nextUrl } = paginationMeta

    if (!nextUrl)
      return Promise.resolve(dispatch(fetchNextPageFailure(new Error('no next url to fetch in activities meta'))))

    return dispatch(apiAction(fetchNextPageActionBundle)(fetchApi(nextUrl)))
  }

const fetchHistoryFailure: FailureActionFactory<FetchJunkLoverHistoryAdvertsFailure> = failureAction(
  FETCH_JUNK_LOVER_HISTORY_ADVERTS_FAILURE,
)

const fetchHistoryActionBundle: ActionBundle<
  FetchJunkLoverHistoryAdvertsStart,
  FetchJunkLoverHistoryAdvertsSuccess,
  FetchJunkLoverHistoryAdvertsFailure,
  FetchActivityItemsResponse
> = [
  action(FETCH_JUNK_LOVER_HISTORY_ADVERTS_START),
  payloadAction(FETCH_JUNK_LOVER_HISTORY_ADVERTS_SUCCESS),
  fetchHistoryFailure,
]

export const fetchHistory: StatelessPayloadThunk<Nullable<Meta>, FetchJunkLoverHistoryAdvertsResult> =
  meta => dispatch =>
    isJunkLoverMetaPresent(meta)
      ? dispatch(apiAction(fetchHistoryActionBundle)(fetchApi(meta.junkLoverActivitiesUrl, { isHistory: true })))
      : Promise.resolve(dispatch(fetchHistoryFailure(new NotJunkLoverError())))

const fetchHistoryNextPageFailure: FailureActionFactory<FetchJunkLoverHistoryAdvertsNextPageFailure> = failureAction(
  FETCH_JUNK_LOVER_HISTORY_ADVERTS_NEXT_PAGE_FAILURE,
)

const fetchHistoryNextPageActionBundle: ActionBundle<
  FetchJunkLoverHistoryAdvertsNextPageStart,
  FetchJunkLoverHistoryAdvertsNextPageSuccess,
  FetchJunkLoverHistoryAdvertsNextPageFailure,
  FetchActivityItemsResponse
> = [
  action(FETCH_JUNK_LOVER_HISTORY_ADVERTS_NEXT_PAGE_START),
  payloadAction(FETCH_JUNK_LOVER_HISTORY_ADVERTS_NEXT_PAGE_SUCCESS),
  fetchHistoryNextPageFailure,
]

export const fetchHistoryNextPage: StatelessPayloadThunk<
  Nullable<PaginationMeta>,
  FetchJunkLoverHistoryAdvertsNextPageResult
> = paginationMeta => dispatch => {
  if (!paginationMeta) return Promise.resolve(dispatch(fetchHistoryNextPageFailure(new Error('no meta in activities'))))

  const { next_url: nextUrl } = paginationMeta

  if (!nextUrl)
    return Promise.resolve(dispatch(fetchHistoryNextPageFailure(new Error('no next url to fetch in activities meta'))))

  return dispatch(apiAction(fetchHistoryNextPageActionBundle)(fetchApi(nextUrl)))
}

const fetchJunkReuserOffersFailure: FailureActionFactory<FetchJunkReuserOffersFailure> = failureAction(
  FETCH_JUNK_REUSER_OFFERS_FAILURE,
)

const fetchJunkReuserOffersActionBundle: ActionBundle<
  FetchJunkReuserOffersStart,
  FetchJunkReuserOffersSuccess,
  FetchJunkReuserOffersFailure,
  FetchSupplierOffersResponse
> = [
  action(FETCH_JUNK_REUSER_OFFERS_START),
  payloadAction(FETCH_JUNK_REUSER_OFFERS_SUCCESS),
  fetchJunkReuserOffersFailure,
]

export const fetchOffers: StatelessPayloadThunk<Nullable<Meta>, FetchJunkReuserOffersResult> =
  meta => async dispatch => {
    if (!meta) return dispatch(fetchJunkReuserOffersFailure(new NoMetaError()))

    const {
      actions: { offerActivities },
    } = meta

    if (!offerActivities) return dispatch(fetchJunkReuserOffersFailure(new Error('no offerActivities in meta')))

    return dispatch(apiAction(fetchJunkReuserOffersActionBundle)(fetchOfferActivitiesApi(offerActivities.url)))
  }

const fetchJunkReuserOffersNextPageFailure: FailureActionFactory<FetchJunkReuserOffersNextPageFailure> = failureAction(
  FETCH_JUNK_REUSER_OFFERS_NEXT_PAGE_FAILURE,
)

const fetchJunkReuserOffersNextPageActionBundle: ActionBundle<
  FetchJunkReuserOffersNextPageStart,
  FetchJunkReuserOffersNextPageSuccess,
  FetchJunkReuserOffersNextPageFailure,
  FetchSupplierOffersResponse
> = [
  action(FETCH_JUNK_REUSER_OFFERS_NEXT_PAGE_START),
  payloadAction(FETCH_JUNK_REUSER_OFFERS_NEXT_PAGE_SUCCESS),
  fetchJunkReuserOffersNextPageFailure,
]

export const fetchOffersNextPage: StatelessPayloadThunk<
  Nullable<PaginationMeta>,
  FetchJunkReuserOffersNextPageResult
> = paginationMeta => dispatch => {
  if (!paginationMeta)
    return Promise.resolve(dispatch(fetchJunkReuserOffersNextPageFailure(new Error('no meta in activities'))))

  const { next_url: nextUrl } = paginationMeta

  if (!nextUrl)
    return Promise.resolve(
      dispatch(fetchJunkReuserOffersNextPageFailure(new Error('no next url to fetch in activities meta'))),
    )

  return dispatch(apiAction(fetchJunkReuserOffersNextPageActionBundle)(fetchApi(nextUrl)))
}
