import { lensFactory } from '../../helpers'
import RootState from '../../state'
import { GetLens } from '../../types/core'
import State from './state'

export const otpRootLens: GetLens<RootState, State> = state => () => state.otp

const lens = lensFactory<State>()

export const authIdentifierVerificationPayloadLens = lens('authIdentifierVerificationPayload')
export const otpErrorLens = lens('error')
export const otpResentPayloadLens = lens('otpResentPayload')

export default otpRootLens
