import {
  ActivityItem,
  AdvertPayloadWithRef,
  currentActivityLens,
  currentSupplierActivityLens,
  customerAdvertsRootLens,
  Dispatch,
  hasItems,
  historyActivityLens,
  historySupplierActivityLens,
  isCollector,
  isJunkLover,
  isTradeCustomer,
  junkReuserAdvertsRootLens,
  NoOp,
  Nullable,
  OfferItem,
  offersLens,
  Render,
  SelectedActivity,
  selectedActivityLens,
  setAdvertUrl,
  setSelectedActivity,
  stateProps,
  SupplierAdvertItem,
  TabViewData as GenericTabViewData,
  Transform,
  undefineNull,
} from '@lovejunk/core'
import RootState from 'core/state'
import { fetch as fetchAdverts } from 'entities/adverts'
import { rootLens as appRootLens } from 'entities/app'
import { map, union } from 'lodash/fp'
import React, { FC, ReactNode, useCallback, useEffect, useMemo } from 'react'
import { connect, MapStateToProps } from 'react-redux'
import { styled } from 'styled'
import { device } from 'utils/css'

import Column from '../containers/Column'
import PanelTitle from '../Panel/Title'
import createTabView from '../TabView'
import BaseText from '../text/BaseText'
import Item from './Item'

interface DispatchProps {
  fetchAdverts: NoOp
  setAdvertUrl: Dispatch<AdvertPayloadWithRef>
  setSelectedActivity: Dispatch<Nullable<SelectedActivity>>
}

export interface StateProps {
  customerCurrentAdverts: ActivityItem[]
  customerHistoryAdverts: ActivityItem[]
  junkReuserCurrentAdverts: ActivityItem[]
  junkReuserHistoryAdverts: ActivityItem[]
  junkReuserOffersAdverts: OfferItem[]
  isCollector: boolean
  isJunkLover: boolean
  isTradeCustomer: boolean

  selectedActivity?: SelectedActivity
}

type Props = DispatchProps & StateProps

interface RenderListPayload {
  activities: SupplierAdvertItem[]
  type: SelectedActivity
}

type TabViewData = GenericTabViewData<SelectedActivity>

const TabView = createTabView<SelectedActivity>()

const Adverts: FC<Props> = ({
  customerCurrentAdverts,
  customerHistoryAdverts,
  junkReuserCurrentAdverts,
  junkReuserHistoryAdverts,
  junkReuserOffersAdverts,
  fetchAdverts,
  isCollector,
  isJunkLover,
  isTradeCustomer,
  selectedActivity = 'current',
  setAdvertUrl,
  setSelectedActivity,
}) => {
  const offerActivities = useMemo(() => junkReuserOffersAdverts, [junkReuserOffersAdverts])
  const currentActivities = useMemo(
    () => union(customerCurrentAdverts, junkReuserCurrentAdverts),
    [customerCurrentAdverts, junkReuserCurrentAdverts],
  )
  const historyActivities = useMemo(
    () => union(customerHistoryAdverts, junkReuserHistoryAdverts),
    [customerHistoryAdverts, junkReuserHistoryAdverts],
  )

  useEffect(() => {
    fetchAdverts()
  }, [fetchAdverts])

  const renderItem = useCallback<Transform<SupplierAdvertItem, ReactNode>>(
    item => (
      <Item
        item={item}
        isCollector={isCollector}
        isJunkLover={isJunkLover}
        key={item.advertId}
        setAdvertUrl={setAdvertUrl}
      />
    ),
    [setAdvertUrl, isCollector, isJunkLover],
  )

  const renderList = useCallback<Render<RenderListPayload>>(
    ({ activities, type }) =>
      hasItems(activities) ? (
        <List>{map(renderItem)(activities)}</List>
      ) : (
        <NoListings>You have no {type} listings</NoListings>
      ),
    [renderItem],
  )

  const renderCurrentActivitiesContent = useCallback(
    () => renderList({ activities: currentActivities, type: 'current' }),
    [currentActivities, renderList],
  )
  const renderHistoryActivitiesContent = useCallback(
    () => renderList({ activities: historyActivities, type: 'history' }),
    [historyActivities, renderList],
  )
  const renderOfferActivitiesContent = useCallback(
    () => renderList({ activities: offerActivities, type: 'offers' }),
    [offerActivities, renderList],
  )
  const offerActivitiesContentTabViewData = useMemo<TabViewData>(
    () => (isJunkLover ? [{ renderContent: renderOfferActivitiesContent, title: 'Offers', value: 'offers' }] : []),
    [isJunkLover, renderOfferActivitiesContent],
  )
  const currentActivitiesContentTabViewData = useMemo<TabViewData>(
    () => [{ renderContent: renderCurrentActivitiesContent, title: 'Current', value: 'current' }],
    [renderCurrentActivitiesContent],
  )
  const historyActivitiesContentTabViewData = useMemo<TabViewData>(
    () =>
      !isTradeCustomer || isJunkLover
        ? [{ renderContent: renderHistoryActivitiesContent, title: 'History', value: 'history' }]
        : [],
    [isJunkLover, isTradeCustomer, renderHistoryActivitiesContent],
  )

  const tabViewData = useMemo<TabViewData>(
    () => [
      ...offerActivitiesContentTabViewData,
      ...currentActivitiesContentTabViewData,
      ...historyActivitiesContentTabViewData,
    ],
    [currentActivitiesContentTabViewData, historyActivitiesContentTabViewData, offerActivitiesContentTabViewData],
  )

  return (
    <Root>
      <Title>Your Activity</Title>
      <TabView data={tabViewData} onChange={setSelectedActivity} value={selectedActivity} />
    </Root>
  )
}

const Root = styled.div``

const List = styled(Column)`
  gap: 1em;
`

const NoListings = styled(BaseText)`
  font-size: 1.125em;
  padding: 4em 0;
  text-align: center;

  @media ${device.mobile} {
    padding: 1em 0;
  }
`

const Title = styled(PanelTitle)`
  margin-bottom: 2em;

  @media ${device.mobile} {
    margin-bottom: 1em;
    padding: 0 1em;
  }
`

const mapStateToProps: MapStateToProps<StateProps, unknown, RootState> = (rootState, ownProps) => ({
  ...stateProps(appRootLens)(state => ({
    selectedActivity: undefineNull(selectedActivityLens(state).get()),
  }))(rootState, ownProps),
  ...stateProps(customerAdvertsRootLens)(state => ({
    customerCurrentAdverts: currentActivityLens.itemsLens(state).get(),
    customerHistoryAdverts: historyActivityLens.itemsLens(state).get(),
  }))(rootState, ownProps),
  ...stateProps(junkReuserAdvertsRootLens)(state => ({
    junkReuserCurrentAdverts: currentSupplierActivityLens.itemsLens(state).get(),
    junkReuserHistoryAdverts: historySupplierActivityLens.itemsLens(state).get(),
    junkReuserOffersAdverts: offersLens.itemsLens(state).get(),
  }))(rootState, ownProps),
  isCollector: isCollector(rootState),
  isJunkLover: isJunkLover(rootState),
  isTradeCustomer: isTradeCustomer(rootState),
})

export default connect(mapStateToProps, { fetchAdverts, setAdvertUrl, setSelectedActivity })(Adverts)
