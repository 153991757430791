import { flow, toString, trim, upperFirst } from 'lodash/fp'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { AUTH_IDENTIFIER_NOTE } from '../../constants'
import { AddAuthIdentifierPayload, VerifyIdentityPayload } from '../../entities/account/types'
import { ADD_IDENTIFIER_SUCCESS, AddIdentifierData, AddIdentifierResult } from '../../entities/login/types'
import { VerifyOtpScreenParams } from '../../entities/otp/types'
import { AuthIdentifierValue, Dispatch, DispatchAsync, NoOp } from '../../types/core'
import { ComponentFactory, ToElements } from '../../types/ui'
import { defaultCountry, formatAuthIdentifierType } from '../../utils'
import { isEmail, isPhoneNumber } from '../../validations'

export interface DispatchProps {
  addIdentifier: DispatchAsync<AddIdentifierData, AddIdentifierResult>
  updateAccountEmail: Dispatch<string>
}

interface OwnProps extends AddAuthIdentifierPayload {
  navigateToVerifyAuthIdentifier: Dispatch<VerifyIdentityPayload>
  navigateToVerifyOtp: Dispatch<VerifyOtpScreenParams>
}

export type Props = DispatchProps & OwnProps

interface ButtonProps {
  onPress: NoOp
  text: string
}

interface PhoneInputProps {
  onChange: Dispatch<AuthIdentifierValue>
  title: string

  error?: string
}

interface TextInputProps extends PhoneInputProps {
  value: AuthIdentifierValue
}

interface ElementsProps {
  Button: ButtonProps
  FormField: unknown
  Label: unknown
  Note: unknown
  PhoneInput: PhoneInputProps
  Root: unknown
  TextInput: TextInputProps

  CountryCodeText?: unknown
  Title?: unknown
}

export type Elements = ToElements<ElementsProps>

const factory: ComponentFactory<Elements, Props> =
  ({ Button, CountryCodeText, FormField, Label, Note, PhoneInput, Root, TextInput, Title }) =>
  ({
    addIdentifier,
    change,
    backToChat,
    identifier,
    locked,
    navigateToVerifyAuthIdentifier,
    navigateToVerifyOtp,
    type,
    updateAccountEmail,
    url,
  }) => {
    const [isDirty, setIsDirty] = useState<boolean>(false)
    const [value, setValue] = useState<AuthIdentifierValue>('')
    const verifyIdentityPayload = useMemo<VerifyIdentityPayload>(
      () => ({ backToChat, identifier: value, type, url }),
      [backToChat, type, url, value],
    )
    const emailError = useMemo(
      () => (type === 'email' && !isEmail(value) ? `Invalid ${formatAuthIdentifierType(type)}` : undefined),
      [type, value],
    )
    const phoneError = useMemo(
      () => (type === 'phone' && !isPhoneNumber(value) ? `Invalid ${formatAuthIdentifierType(type)}` : undefined),
      [type, value],
    )
    const isValid = useMemo(() => !(emailError || phoneError), [emailError, phoneError])
    const onPress = useCallback(() => {
      setIsDirty(true)

      if (!isValid) return

      if (verifyIdentityPayload.type === 'email') updateAccountEmail(verifyIdentityPayload.identifier)

      if (identifier && !change) {
        navigateToVerifyAuthIdentifier(verifyIdentityPayload)
      } else {
        addIdentifier(verifyIdentityPayload).then(action => {
          if (action.type === ADD_IDENTIFIER_SUCCESS) {
            const {
              actions: { verifyIdentifier },
              otpLength: length,
            } = action.payload

            if (verifyIdentifier)
              navigateToVerifyOtp({
                ...verifyIdentityPayload,
                addAuthIdentifierUrl: url,
                isVerification: true,
                length,
                locked,
                url: verifyIdentifier.url,
              })
          }
        })
      }
    }, [
      addIdentifier,
      change,
      identifier,
      isValid,
      locked,
      navigateToVerifyAuthIdentifier,
      navigateToVerifyOtp,
      updateAccountEmail,
      url,
      verifyIdentityPayload,
    ])
    const inputProps = useMemo(() => ({ onChange: flow(trim, setValue), title: upperFirst(type) }), [type])

    useEffect(() => {
      inputProps.onChange(toString(identifier))
    }, [identifier, inputProps])

    return (
      <Root locked={locked}>
        {Title ? (
          <Title>
            {identifier
              ? `Update your ${formatAuthIdentifierType(type)}`
              : `Add your ${formatAuthIdentifierType(type)} to securely connect you to the
            collector. Your number won’t be shared.`}
          </Title>
        ) : null}
        {type === 'phone' && CountryCodeText ? (
          <FormField>
            <Label>Country code</Label>
            <CountryCodeText>United Kingdom ({defaultCountry.dialCode})</CountryCodeText>
          </FormField>
        ) : null}
        <FormField>
          {type === 'phone' ? (
            <PhoneInput {...inputProps} error={isDirty ? phoneError : undefined} />
          ) : (
            <TextInput {...inputProps} error={isDirty ? emailError : undefined} value={value} />
          )}
          {type === 'email' ? <Note>{AUTH_IDENTIFIER_NOTE}</Note> : null}
        </FormField>
        <Button onPress={onPress} text={identifier ? 'Update' : 'Add'} />
      </Root>
    )
  }

export default factory
