import { Get, Nullable } from '../../types/core'
import { ReferralCodeData, ReferralInfo, ViewDataContent } from './types'

export interface ReferralsState {
  // User provided referral code during signup - subject for verification and used in signup payload
  code: Nullable<string>
  // Information about referral code, referrer and referee benefits
  codeData: Nullable<ReferralCodeData>
  // Error displayed on signup page if `code` check wasn't successful
  error: boolean
  // Info returned after successful `code` check. Contains information about referrer and credit.
  // Displayed in `CreditsModal` and `Signup`.
  info: Nullable<ReferralInfo>
  // Flag determines referral code registration mode - it can be either automatic (via deep link)
  // or manual (via manually provided `code`)
  isAuto: boolean
  // Flag to display `CreditsModal` with information about applied credit.
  // Displayed after visiting referral dynamic link upon successful referral code registration.
  showCreditInfo: boolean
  // View data used to display information in various components e.g. `ShareModal`.
  // The values are derived from `ReferralCodeData`.
  viewDataContent: Nullable<ViewDataContent>
}

export const referralsDefaultState: Get<ReferralsState> = () => ({
  code: null,
  codeData: null,
  error: false,
  info: null,
  isAuto: false,
  showCreditInfo: false,
  viewDataContent: null,
})

export default ReferralsState
