import { styled } from '../../styled'

interface Props {
  reverse?: boolean
}

export default styled.div<Props>`
  display: flex;
  flex-direction: ${({ reverse = false }) => (reverse ? 'column-reverse' : 'column')};
`
