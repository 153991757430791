import { GetLens, lensFactory } from '@lovejunk/core'

import RootState from '../../core/state'
import State from './state'

export const utmRootLens: GetLens<RootState, State> = state => () => state.utm

const simpleLens = lensFactory<State>()

export const utmLens = simpleLens('params')

export default utmRootLens
