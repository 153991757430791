import {
  fetchCustomerAdverts,
  fetchCustomerHistoryAdverts,
  fetchJunkReuserAdverts,
  fetchJunkReuserHistoryAdverts,
  fetchJunkReuserOffers,
  getMetaDataLens,
} from '@lovejunk/core'
import withState from 'core/with-state'
import { Thunk } from 'types/thunk'

import { FetchAdvertsResult } from './types'

export const fetch: Thunk<FetchAdvertsResult> = () =>
  withState(getMetaDataLens)((dispatch, meta) =>
    Promise.all([
      dispatch(fetchCustomerAdverts(meta)),
      dispatch(fetchCustomerHistoryAdverts(meta)),
      dispatch(fetchJunkReuserAdverts(meta)),
      dispatch(fetchJunkReuserHistoryAdverts(meta)),
      dispatch(fetchJunkReuserOffers(meta)),
    ]),
  )
