import React from 'react'

import { CHECK_PRICES_TEXT, DELIVERY_PRICES_PAGE_URL, NEED_IT_DELIVERED_TEXT } from '../../constants'
import { ComponentFactory, ToElements } from '../../types/ui'

interface ExternalLinkProps {
  url: string
}

export interface ElementsProps {
  Text: unknown
  ExternalLink: ExternalLinkProps
  TruckIcon: unknown
  Root: unknown
}

type Elements = ToElements<ElementsProps>

const factory: ComponentFactory<Elements> = ({ Root, Text, ExternalLink, TruckIcon }) =>
  function CheckPricesLink() {
    return (
      <Root>
        <Text>{NEED_IT_DELIVERED_TEXT}</Text>
        <ExternalLink url={DELIVERY_PRICES_PAGE_URL}>{CHECK_PRICES_TEXT}</ExternalLink>
        <TruckIcon />
      </Root>
    )
  }

export default factory
