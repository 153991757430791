import { isDefined, Optional, Transform } from '@lovejunk/core'
import { compact, findKey, first, isEqual } from 'lodash/fp'
import React, { FC, lazy, useEffect } from 'react'
import { matchRoutes } from 'react-router'
import { RouteObject, useLocation, useRoutes } from 'react-router-dom'
import { isMobile, isProductionEnv } from 'utils/environment'
import { setCurrentScreenName } from 'utils/firebase/analytics'

import NavigationPath from './paths'
import RedirectToGallery from './RedirectToGallery'

const AccountElement = lazy(() => import('../screens/account/Account'))
const AddAuthIdentifier = lazy(() => import('../screens/account/AddAuthIdentifier'))
const AdvertElement = lazy(() => import('./elements/Advert'))
const BecomeJunkReuser = lazy(() => import('../screens/junk-reuser/signup/Become'))
const ChatElement = lazy(() => import('./elements/Chat'))
const ChatWithoutAdvertElement = lazy(() => import('./elements/ChatWithoutAdvert'))
const FreegleElement = lazy(() => import('./elements/Freegle'))
const NewAdvertElement = lazy(() => import('./elements/NewAdvert'))
const NewCardElement = lazy(() => import('./elements/NewCard'))
const CollectorProfile = lazy(() => import('../screens/CollectorProfile'))
const CollectorsOffers = lazy(() => import('../screens/CollectorsOffers'))
const Dashboard = lazy(() => import('../screens/Dashboard'))
const DevSettings = lazy(() => import('../screens/DevSettings'))
const Favourites = lazy(() => import('../screens/Favourites'))
const Home = lazy(() => import('../screens/Home'))
const JunkReuserOffer = lazy(() => import('../screens/junk-reuser/Offer'))
const JunkReusersOffers = lazy(() => import('../screens/JunkReusersOffers'))
const Login = lazy(() => import('../screens/Login'))
const NewAdvertSummary = lazy(() => import('../screens/NewAdvertSummary'))
const NotFound = lazy(() => import('../screens/NotFound'))
const PaymentMethods = lazy(() => import('../screens/account/PaymentMethods'))
const Reusables = lazy(() => import('screens/Reusables'))
const Signup = lazy(() => import('../screens/Signup'))
const SignupJunkReuser = lazy(() => import('../screens/junk-reuser/signup/Details'))
const TradeCustomerJobs = lazy(() => import('../screens/TradeCustomerJobs'))
const TradeEnquiryForm = lazy(() => import('../screens/TradeEnquiryForm'))
const VerifyAuthIdentifier = lazy(() => import('../screens/account/VerifyAuthIdentifier'))
const VerifyOtp = lazy(() => import('../screens/VerifyOtp'))

const routes: RouteObject[] = compact([
  { path: NavigationPath.Account, element: <AccountElement /> },
  { path: NavigationPath.AddAuthIdentifier, element: <AddAuthIdentifier /> },
  { path: NavigationPath.Advert, element: <AdvertElement /> },
  { path: NavigationPath.BecomeJunkReuser, element: <BecomeJunkReuser /> },
  { path: NavigationPath.Chat, element: <ChatElement /> },
  { path: NavigationPath.ChatWithoutAdvert, element: <ChatWithoutAdvertElement /> },
  { path: NavigationPath.CollectorProfile, element: <CollectorProfile /> },
  { path: NavigationPath.CollectorsOffers, element: <CollectorsOffers /> },
  { path: NavigationPath.Dashboard, element: <Dashboard /> },
  { path: NavigationPath.FavouriteCollectors, element: <Favourites /> },
  { path: NavigationPath.Freegle, element: <FreegleElement /> },
  { path: NavigationPath.GalleryReusables, element: <Reusables /> },
  { path: NavigationPath.Home, element: <Home /> },
  { path: NavigationPath.JunkReuserOffer, element: <JunkReuserOffer /> },
  { path: NavigationPath.JunkReusersOffers, element: <JunkReusersOffers /> },
  { path: NavigationPath.Login, element: <Login /> },
  { path: NavigationPath.NewAdvert, element: <NewAdvertElement /> },
  { path: NavigationPath.NewAdvertSummary, element: <NewAdvertSummary /> },
  { path: NavigationPath.NewCard, element: <NewCardElement /> },
  { path: NavigationPath.PaymentMethods, element: <PaymentMethods /> },
  { path: NavigationPath.Referrals, element: <Signup /> },
  { path: NavigationPath.Reusables, element: <Reusables /> },
  { path: NavigationPath.Signup, element: <Signup /> },
  { path: NavigationPath.SignupJunkReuser, element: <SignupJunkReuser /> },
  { path: NavigationPath.TradeEnquiryForm, element: <TradeEnquiryForm /> },
  { path: NavigationPath.VerifyAuthIdentifier, element: <VerifyAuthIdentifier /> },
  { path: NavigationPath.VerifyOtp, element: <VerifyOtp /> },
  { path: NavigationPath.Gallery, element: <RedirectToGallery /> },
  { path: NavigationPath.GallerySeo, element: <RedirectToGallery /> },
  { path: NavigationPath.GalleryTag, element: <RedirectToGallery /> },
  ...(isMobile ? [] : [{ path: NavigationPath.TradeCustomerJobs, element: <TradeCustomerJobs /> }]),
  ...(isProductionEnv ? [] : [{ path: NavigationPath.DevSettings, element: <DevSettings /> }]),
  { path: NavigationPath.NotFound, element: <NotFound /> },
])

const pathnameToNavigationPath: Transform<string, Optional<string>> = pathname => {
  const routeMatch = first(matchRoutes(routes, pathname))

  if (isDefined(routeMatch)) {
    const {
      route: { path: routePath },
    } = routeMatch

    return routePath && findKey(isEqual(routePath))(NavigationPath)
  }
}

const Navigator: FC = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    const navigationPath = pathnameToNavigationPath(pathname)

    if (navigationPath) setCurrentScreenName(navigationPath)
  }, [pathname])

  return useRoutes(routes)
}

export default Navigator
