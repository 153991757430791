import { isZero } from '../../../helpers'
import DateMarshaller from '../../../marshallers/date'
import { Price, Transform, VatPrice } from '../../../types/core'
import advertMarshaller from '../marshaller'
import {
  AdvertAddress,
  AdvertAsset,
  AdvertBase,
  AdvertBaseResource,
  CollectorDetails,
  FetchWtnResponse,
  JobWindow,
  Wtn,
} from '../types'

const address: AdvertAddress = {
  address1: 'Flat 87',
  address2: 'Portland Cl.',
  address3: 'UK',
  city: 'Bracknell',
  postcode: 'RG12 0TU',
}

const images: AdvertAsset[] = [
  {
    fullUrl: 'https://picsum.photos/500/700',
  },
]

const videos: AdvertAsset[] = []

export const priceFixture: Price = {
  currency: 'GBP',
  amount: 40,
}

const createdAt = '2019-10-01T09:01:38.000Z'

export interface BestCounterOfferFixturePayload {
  amountExVat: number
  vat: number
}

export const bestCounterOfferFixture: Transform<BestCounterOfferFixturePayload, VatPrice> = ({ amountExVat, vat }) => ({
  amount: amountExVat + vat,
  amountExVat,
  currency: 'GBP',
  hasVat: !isZero(vat),
  vat,
})

export const advertBaseResourceFixture: AdvertBaseResource = {
  actions: {},
  customerIsVatRegistered: false,
  id: 1,
  junkDetails: {
    builderWaste: false,
    easyAccess: true,
    easyAccessReason: '',
    isLightweight: true,
    isReusable: false,
    isTrade: false,
    residential: true,
    size: 'small',
  },
  locationDetails: {
    accuracyInDegrees: 0.001,
    accuracyInMetres: 350,
    address,
    latitude: 1,
    longitude: 1,
    isUlez: false,
  },
  metaDetails: {
    createdAt,
    offeredAt: createdAt,
    reference: 'A-WXI7OKUJ',
    reviewed: false,
    source: 'web',
  },
  publicDetails: {
    images,
    price: priceFixture,
    summary: '',
    timeDetails: {
      startAt: '2020-04-02 11:00:00',
      endAt: '2020-04-02 13:00:00',
    },
    title: 'Laptop',
    videos,
  },
  status: 'active',
  view: 'public',
}

export const advertBaseFixture: AdvertBase = {
  ...advertBaseResourceFixture,
  metaDetails: {
    ...advertBaseResourceFixture.metaDetails,
    createdAt: new Date(advertBaseResourceFixture.metaDetails.createdAt),
    offeredAt: new Date(advertBaseResourceFixture.metaDetails.offeredAt),
  },
}

const supplierDetails: CollectorDetails = {
  firstName: 'collectorFirstName',
  lastName: 'collectorLastName',
  phoneNumber: 'collectorPhoneNumber',
  averageRating: 5,
  selfieUrl: 'selfieUrl',
  collectionsNum: 32,
  licenseNumber: '1Afsefr3',
}

const jobWindow: JobWindow = {
  slotStart: 'slotStart',
  slotEnd: 'slotEnd',
}

const advertBase: AdvertBase = advertBaseFixture

export const advertResourceFixture: AdvertBaseResource = {
  ...advertBase,
  metaDetails: {
    ...advertBase.metaDetails,
    createdAt: DateMarshaller.marshal(advertBase.metaDetails.createdAt),
    offeredAt: DateMarshaller.marshal(advertBase.metaDetails.offeredAt),
  },
  actions: {
    customerReceipt: { name: 'receipt', url: 'receiptUrl', urlPath: 'receiptUrlPath' },
    customerWtn: { name: 'wtn', url: 'wtnUrl', urlPath: 'wtnUrlPath' },
  },
  assignedCollectorDetails: supplierDetails,
  jobDetails: {
    price: priceFixture,
    status: 'collectorconfirmed',
    window: jobWindow,
  },
  publicDetails: {
    ...advertBase.publicDetails,
    supplierType: 'collector',
  },
}

export const customerAdvertConfirmedCollectionFixture: AdvertBase = {
  ...advertBase,
  assignedCollectorDetails: supplierDetails,
  jobDetails: {
    price: priceFixture,
    status: 'awaitingcollection',
    window: jobWindow,
  },
  publicDetails: {
    ...advertBase.publicDetails,
    supplierType: 'collector',
  },
}

export const customerAdvertConfirmedCollectionResourceFixture = advertMarshaller.marshal(
  customerAdvertConfirmedCollectionFixture,
)

export const advertFixture = advertMarshaller.unmarshal(advertResourceFixture)

export const wtnFixture: Wtn = {
  jobId: 45,
  collectedAt: '2020-06-09 13:55:20',
  address1: '92 Beaumont rd',
  city: 'London',
  size: 12,
  disposalSiteLicense: '3vDUz0x3KfvVftFlxu4h84APJg9JmvthAScQboA3g1HwDiFKI4R3Ht197gvDNIapxSsxhOcE',
  postcode: 'SW15 1AX',
  disposalSiteName: 'v79yb9Fku4xQRO0GIGYCRqlaMwHeJrhjX02mx1ZDuGXb5C97LgWuQjJ1fM8RSN3R0E',
  advertTitle: '0',
  customerFullName: 'twQuK2Wv1ILz7JUVEOLtBZIkatclFHfkNsi5cyf zLpCyLTwXrmPcm',
  collectorOrgLicense: 'aIfa3D0W8kD8auSQwv2Y2de5R48miWtE',
  collectorOpCentre: 'SW15 1AX',
  collectorFullName: 'PJGV7xKk6FJME8SgKCJtcH6PfkH7b45QL7EwCqEdwe7H730acCjx7KuVK8ZlZPEifJondpQ7yKgVoAd',
  collectorOrgName: 'ED1aEUqkas4UZ1Dlw5yxdKh5TyoJvVGugUrqEGmzeXS0PTeNVT',
  advertReference: '12swwa',
  collectorAddress: '12 Mor road SE1A22',
  supplierIsReuser: false,
}

export const wtnWithAddress2And3Fixture: Wtn = {
  jobId: 45,
  collectedAt: '2020-06-09 13:55:20',
  address1: '92 Beaumont rd',
  address2: 'Ground Floor',
  address3: 'Building 23/1',
  city: 'London',
  size: 12,
  disposalSiteLicense: '3vDUz0x3KfvVftFlxu4h84APJg9JmvthAScQboA3g1HwDiFKI4R3Ht197gvDNIapxSsxhOcE',
  postcode: 'SW15 1AX',
  disposalSiteName: 'v79yb9Fku4xQRO0GIGYCRqlaMwHeJrhjX02mx1ZDuGXb5C97LgWuQjJ1fM8RSN3R0E',
  advertTitle: '0',
  customerFullName: 'twQuK2Wv1ILz7JUVEOLtBZIkatclFHfkNsi5cyf zLpCyLTwXrmPcm',
  collectorOrgLicense: 'aIfa3D0W8kD8auSQwv2Y2de5R48miWtE',
  collectorOpCentre: 'SW15 1AX',
  collectorFullName: 'PJGV7xKk6FJME8SgKCJtcH6PfkH7b45QL7EwCqEdwe7H730acCjx7KuVK8ZlZPEifJondpQ7yKgVoAd',
  collectorOrgName: 'ED1aEUqkas4UZ1Dlw5yxdKh5TyoJvVGugUrqEGmzeXS0PTeNVT',
  advertReference: '12swwa',
  collectorAddress: '12 Mor road SE1A22',
  supplierIsReuser: false,
}

export const wtnFixtureResponse: FetchWtnResponse = { body: wtnFixture }
