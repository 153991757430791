import React, { ComponentType, useCallback, useEffect, useMemo, useState } from 'react'

import { getAttachmentUrl as getUrl } from '../../entities/chat/api'
import { Attachment, AttachmentAssetProps, OnShowAttachment } from '../../entities/chat/types'
import { AssetType, Dispatch, NoOp, Optional } from '../../types/core'
import { ComponentFactory, ToElements } from '../../types/ui'

interface ElementsProps {
  Image: AttachmentAssetProps
  Video: AttachmentAssetProps
  Root: unknown
}

type Elements = ToElements<ElementsProps>

export interface Props extends Attachment {
  isAuthor: boolean
  onShow: OnShowAttachment
}

const factory: ComponentFactory<Elements, Props> = ({ Image, Root, Video }) =>
  function Attachment({ actions, autodownload, isAuthor, onShow, type }) {
    const [url, setUrl] = useState<string>()
    const getSignedUrlActionUrl = useMemo<Optional<string>>(() => actions?.getSignedUrl?.url, [actions])
    const typeToAsset = useMemo<Record<AssetType, ComponentType<AttachmentAssetProps>>>(
      () => ({
        image: Image,
        video: Video,
      }),
      [],
    )
    const Asset = typeToAsset[type]
    const onTap = useCallback<NoOp>(() => {
      if (getSignedUrlActionUrl) getUrl(getSignedUrlActionUrl).then(setUrl)
    }, [getSignedUrlActionUrl])
    const onPress = useCallback<Dispatch<string>>(path => onShow({ path, type }), [onShow, type])

    useEffect(() => {
      if (autodownload) onTap()
    }, [autodownload, onTap])

    return (
      <Root>
        <Asset isAuthor={isAuthor} onPress={onPress} onTap={autodownload ? undefined : onTap} url={url} />
      </Root>
    )
  }

export default factory
