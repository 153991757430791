import { action, failureAction, payloadAction } from '../../action-factory'
import apiAction, { ActionBundle } from '../../api-action'
import { ActionFactory, FailureActionFactory, Nullable, PayloadActionFactory, Transform } from '../../types/core'
import { StatelessPayloadThunk, StatelessThunk } from '../../types/thunk'
import { withEndpoint } from '../app/helpers'
import { checkReferralCodeApi, getReferralCodeApi, registerReferralCodeApi } from './api'
import {
  CHECK_REFERRAL_CODE_FAILURE,
  CHECK_REFERRAL_CODE_START,
  CHECK_REFERRAL_CODE_SUCCESS,
  CheckReferralCodeFailure,
  CheckReferralCodeParams,
  CheckReferralCodeResult,
  CheckReferralCodeStart,
  CheckReferralCodeSuccess,
  GET_REFERRAL_CODE_FAILURE,
  GET_REFERRAL_CODE_START,
  GET_REFERRAL_CODE_SUCCESS,
  GetReferralCodeFailure,
  GetReferralCodeResult,
  GetReferralCodeStart,
  GetReferralCodeSuccess,
  ReferralCodeData,
  ReferralInfo,
  REGISTER_REFERRAL_CODE_FAILURE,
  REGISTER_REFERRAL_CODE_START,
  REGISTER_REFERRAL_CODE_SUCCESS,
  RegisterReferralCodeFailure,
  RegisterReferralCodeParams,
  RegisterReferralCodeResult,
  RegisterReferralCodeStart,
  RegisterReferralCodeSuccess,
  RESET_REFERRAL,
  ResetReferral,
  SET_REFERRAL_IS_AUTO,
  SET_VIEW_DATA_CONTENT,
  SetReferralIsAuto,
  SetViewDataContent,
  SHOW_CREDIT_INFO,
  ShowCreditInfo,
  ViewDataContent,
} from './types'

export const setReferralIsAuto: PayloadActionFactory<SetReferralIsAuto, boolean> = payloadAction(SET_REFERRAL_IS_AUTO)

export const showCreditInfo: PayloadActionFactory<ShowCreditInfo, boolean> = payloadAction(SHOW_CREDIT_INFO)

export const registerReferralCodeStart: ActionFactory<RegisterReferralCodeStart> = action(REGISTER_REFERRAL_CODE_START)
export const registerReferralCodeSuccess: ActionFactory<RegisterReferralCodeSuccess> =
  action(REGISTER_REFERRAL_CODE_SUCCESS)
export const registerReferralCodeFailure: FailureActionFactory<RegisterReferralCodeFailure> =
  failureAction(REGISTER_REFERRAL_CODE_FAILURE)
export const registerReferralCodeActionBundle: ActionBundle<
  RegisterReferralCodeStart,
  RegisterReferralCodeSuccess,
  RegisterReferralCodeFailure
> = [registerReferralCodeStart, registerReferralCodeSuccess, registerReferralCodeFailure]

export const registerReferralCode: StatelessPayloadThunk<
  RegisterReferralCodeParams,
  RegisterReferralCodeResult
> = params =>
  withEndpoint(
    'referrals',
    ({ otpReferrals }) =>
      dispatch =>
        dispatch(apiAction(registerReferralCodeActionBundle)(registerReferralCodeApi(otpReferrals, params))),
    registerReferralCodeFailure,
  )

export const checkReferralCodeStart: PayloadActionFactory<CheckReferralCodeStart, string> =
  payloadAction(CHECK_REFERRAL_CODE_START)
export const checkReferralCodeSuccess: PayloadActionFactory<CheckReferralCodeSuccess, ReferralInfo> =
  payloadAction(CHECK_REFERRAL_CODE_SUCCESS)
export const checkReferralCodeFailure: FailureActionFactory<CheckReferralCodeFailure> =
  failureAction(CHECK_REFERRAL_CODE_FAILURE)
const checkReferralCodeActionBundle: Transform<
  string,
  ActionBundle<CheckReferralCodeStart, CheckReferralCodeSuccess, CheckReferralCodeFailure, ReferralInfo>
> = code => [() => checkReferralCodeStart(code), checkReferralCodeSuccess, checkReferralCodeFailure]

export const checkReferralCode: StatelessPayloadThunk<CheckReferralCodeParams, CheckReferralCodeResult> = params =>
  withEndpoint(
    'referrals',
    ({ userReferralCodes }) =>
      dispatch =>
        dispatch(
          apiAction(checkReferralCodeActionBundle(params.code))(checkReferralCodeApi(userReferralCodes, params)),
        ),
    checkReferralCodeFailure,
  )

export const getReferralCodeFailure: FailureActionFactory<GetReferralCodeFailure> =
  failureAction(GET_REFERRAL_CODE_FAILURE)
const getReferralCodeActionBundle: ActionBundle<
  GetReferralCodeStart,
  GetReferralCodeSuccess,
  GetReferralCodeFailure,
  ReferralCodeData
> = [action(GET_REFERRAL_CODE_START), payloadAction(GET_REFERRAL_CODE_SUCCESS), getReferralCodeFailure]

// Requests user referral data.
export const getReferralCode: StatelessThunk<GetReferralCodeResult> = () =>
  withEndpoint(
    'referrals',
    ({ userReferralCodes }) =>
      dispatch =>
        dispatch(apiAction(getReferralCodeActionBundle)(getReferralCodeApi(userReferralCodes))),
    getReferralCodeFailure,
  )

export const setViewDataContent: PayloadActionFactory<SetViewDataContent, Nullable<ViewDataContent>> = payloadAction(
  SET_VIEW_DATA_CONTENT,
)

export const resetReferral: ActionFactory<ResetReferral> = action(RESET_REFERRAL)
