import { get } from '../../api'
import { CompoundApi } from '../../api-action'
import { responseToBody } from '../../helpers'
import { FetchFreegleAdvertParams, FetchFreegleAdvertResponse, GetFreegleAdvertResponse } from './types'

export const fetchFreegleAdvertApi = (
  url: string,
  { freegleId, signature }: FetchFreegleAdvertParams,
): CompoundApi<GetFreegleAdvertResponse, FetchFreegleAdvertResponse> => [
  get(url + '/' + freegleId, { params: { signature } }),
  responseToBody,
]
