import { Action } from 'redux'

import { FailureAction, Get, Nullable, PayloadAction, Price, Response, Transform } from '../../types/core'

export const GET_REFERRAL_CODE_START = 'GET_REFERRAL_CODE_START'
export const GET_REFERRAL_CODE_SUCCESS = 'GET_REFERRAL_CODE_SUCCESS'
export const GET_REFERRAL_CODE_FAILURE = 'GET_REFERRAL_CODE_FAILURE'

export type GetReferralCodeStart = Action<typeof GET_REFERRAL_CODE_START>
export type GetReferralCodeSuccess = PayloadAction<typeof GET_REFERRAL_CODE_SUCCESS, ReferralCodeData>
export type GetReferralCodeFailure = FailureAction<typeof GET_REFERRAL_CODE_FAILURE>

export type GetReferralCode = GetReferralCodeStart | GetReferralCodeSuccess | GetReferralCodeFailure
export type GetReferralCodeResult = GetReferralCodeSuccess | GetReferralCodeFailure

export interface MutualBenefits {
  referrerBenefit: Price
  refereeBenefit: Price
}

export interface ReferralCodeData extends Partial<MutualBenefits> {
  code: string
}

export type GetReferralCodeResponse = Response<ReferralCodeData>

export const CHECK_REFERRAL_CODE_START = 'CHECK_REFERRAL_CODE_START'
export const CHECK_REFERRAL_CODE_SUCCESS = 'CHECK_REFERRAL_CODE_SUCCESS'
export const CHECK_REFERRAL_CODE_FAILURE = 'CHECK_REFERRAL_CODE_FAILURE'

export type CheckReferralCodeStart = PayloadAction<typeof CHECK_REFERRAL_CODE_START, string>
export type CheckReferralCodeSuccess = PayloadAction<typeof CHECK_REFERRAL_CODE_SUCCESS, ReferralInfo>
export type CheckReferralCodeFailure = FailureAction<typeof CHECK_REFERRAL_CODE_FAILURE>

export type CheckReferralCodeResult = CheckReferralCodeSuccess | CheckReferralCodeFailure
export type CheckReferralCode = CheckReferralCodeStart | CheckReferralCodeResult

export const REGISTER_REFERRAL_CODE_START = 'REGISTER_REFERRAL_CODE_START'
export const REGISTER_REFERRAL_CODE_SUCCESS = 'REGISTER_REFERRAL_CODE_SUCCESS'
export const REGISTER_REFERRAL_CODE_FAILURE = 'REGISTER_REFERRAL_CODE_FAILURE'

export type RegisterReferralCodeStart = Action<typeof REGISTER_REFERRAL_CODE_START>
export type RegisterReferralCodeSuccess = Action<typeof REGISTER_REFERRAL_CODE_SUCCESS>
export type RegisterReferralCodeFailure = FailureAction<typeof REGISTER_REFERRAL_CODE_FAILURE>

export type RegisterReferralCodeResult = RegisterReferralCodeSuccess | RegisterReferralCodeFailure
export type RegisterReferralCode = RegisterReferralCodeStart | RegisterReferralCodeResult

export const SET_VIEW_DATA_CONTENT = 'SET_VIEW_DATA_CONTENT'

export type SetViewDataContent = PayloadAction<typeof SET_VIEW_DATA_CONTENT, Nullable<ViewDataContent>>

export const RESET_REFERRAL = 'RESET_REFERRAL'

// This action is used to switch from auto referral mode to manual mode in case where referral code from dynamic link
// is invalid.
export type ResetReferral = Action<typeof RESET_REFERRAL>

export const SET_REFERRAL_IS_AUTO = 'SET_REFERRAL_IS_AUTO'

export type SetReferralIsAuto = PayloadAction<typeof SET_REFERRAL_IS_AUTO, boolean>

export const SHOW_CREDIT_INFO = 'SHOW_CREDIT_INFO'

export type ShowCreditInfo = PayloadAction<typeof SHOW_CREDIT_INFO, boolean>

export type ReferralsAction =
  | CheckReferralCode
  | GetReferralCode
  | RegisterReferralCode
  | ResetReferral
  | SetReferralIsAuto
  | SetViewDataContent
  | ShowCreditInfo

export interface ViewDataContent {
  message: string
  title: string

  button?: string
  link?: string
}

export interface ViewData {
  share: ViewDataContent
}

export interface CheckReferralCodeParams {
  code: string
}

export interface ReferralInfo {
  refereeCredit: Price
  referrer: string
}

export type CheckReferralCodeResponse = Response<ReferralInfo>

export type ReferralCode = string

export interface RegisterReferralCodeParams {
  code: ReferralCode
  identifier: string
}

export interface ViewDataConfig<T> {
  noReferrals: Get<T>
  none: Transform<string, T>
  noReferrer: Transform<string, Transform<Price, T>>
  noReferee: Transform<string, Transform<Price, T>>
  mutual: Transform<string, Transform<MutualBenefits, T>>
}
