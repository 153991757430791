import { format } from 'date-fns/fp'

import { Transform } from '../types/core'

enum FORMAT {
  dayMonth = 'dd MMM',
  yearMonthDay = 'yyyy-MM-dd',
  timeSlot = 'yyyy-MM-dd HH:mm:ss',
}

const formatter: Transform<FORMAT, Transform<Date, string>> = format

export const dayMonthFormatter = formatter(FORMAT.dayMonth)
export const yearMonthDayFormatter = formatter(FORMAT.yearMonthDay)
export const timeSlotFormatter = formatter(FORMAT.timeSlot)
