import { Get, Nullable } from '../../types/core'
import { PaymentConfig, PostSetup } from './types'

export interface PaymentState {
  addCardError: Nullable<string>
  config: Nullable<PaymentConfig>
  inProgress: boolean
  postSetup: Nullable<PostSetup>
}

export const paymentDefaultState: Get<PaymentState> = () => ({
  addCardError: null,
  config: null,
  inProgress: false,
  postSetup: null,
})

export default PaymentState
