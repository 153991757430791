import { action, failureAction, payloadAction } from '../../action-factory'
import apiAction, { ActionBundle } from '../../api-action'
import { NoMetaError, NotCollectorError } from '../../errors'
import { ActionFactory, FailureActionFactory, Nullable, PaginationMeta, PayloadActionFactory } from '../../types/core'
import { StatelessPayloadThunk } from '../../types/thunk'
import { fetchApi, fetchOfferActivitiesApi } from '../activities/api'
import { FetchActivityItemsResponse, FetchSupplierOffersResponse } from '../activities/types'
import { isCollectorMetaPresent } from '../meta/helpers'
import { Meta } from '../meta/types'
import {
  FETCH_COLLECTOR_ADVERTS_FAILURE,
  FETCH_COLLECTOR_ADVERTS_NEXT_PAGE_FAILURE,
  FETCH_COLLECTOR_ADVERTS_NEXT_PAGE_START,
  FETCH_COLLECTOR_ADVERTS_NEXT_PAGE_SUCCESS,
  FETCH_COLLECTOR_ADVERTS_START,
  FETCH_COLLECTOR_ADVERTS_SUCCESS,
  FETCH_COLLECTOR_HISTORY_ADVERTS_FAILURE,
  FETCH_COLLECTOR_HISTORY_ADVERTS_NEXT_PAGE_FAILURE,
  FETCH_COLLECTOR_HISTORY_ADVERTS_NEXT_PAGE_START,
  FETCH_COLLECTOR_HISTORY_ADVERTS_NEXT_PAGE_SUCCESS,
  FETCH_COLLECTOR_HISTORY_ADVERTS_START,
  FETCH_COLLECTOR_HISTORY_ADVERTS_SUCCESS,
  FETCH_COLLECTOR_OFFERS_FAILURE,
  FETCH_COLLECTOR_OFFERS_NEXT_PAGE_FAILURE,
  FETCH_COLLECTOR_OFFERS_NEXT_PAGE_START,
  FETCH_COLLECTOR_OFFERS_NEXT_PAGE_SUCCESS,
  FETCH_COLLECTOR_OFFERS_START,
  FETCH_COLLECTOR_OFFERS_SUCCESS,
  FetchCollectorAdvertsFailure,
  FetchCollectorAdvertsNextPageFailure,
  FetchCollectorAdvertsNextPageResult,
  FetchCollectorAdvertsNextPageStart,
  FetchCollectorAdvertsNextPageSuccess,
  FetchCollectorAdvertsResult,
  FetchCollectorAdvertsStart,
  FetchCollectorAdvertsSuccess,
  FetchCollectorHistoryAdvertsFailure,
  FetchCollectorHistoryAdvertsNextPageFailure,
  FetchCollectorHistoryAdvertsNextPageResult,
  FetchCollectorHistoryAdvertsNextPageStart,
  FetchCollectorHistoryAdvertsNextPageSuccess,
  FetchCollectorHistoryAdvertsResult,
  FetchCollectorHistoryAdvertsStart,
  FetchCollectorHistoryAdvertsSuccess,
  FetchCollectorOffersFailure,
  FetchCollectorOffersNextPageFailure,
  FetchCollectorOffersNextPageResult,
  FetchCollectorOffersNextPageStart,
  FetchCollectorOffersNextPageSuccess,
  FetchCollectorOffersResult,
  FetchCollectorOffersStart,
  FetchCollectorOffersSuccess,
} from './types'

export const fetchStart: ActionFactory<FetchCollectorAdvertsStart> = action(FETCH_COLLECTOR_ADVERTS_START)
export const fetchSuccess: PayloadActionFactory<FetchCollectorAdvertsSuccess, FetchActivityItemsResponse> =
  payloadAction(FETCH_COLLECTOR_ADVERTS_SUCCESS)

const fetchFailure: FailureActionFactory<FetchCollectorAdvertsFailure> = failureAction(FETCH_COLLECTOR_ADVERTS_FAILURE)
const fetchActionBundle: ActionBundle<
  FetchCollectorAdvertsStart,
  FetchCollectorAdvertsSuccess,
  FetchCollectorAdvertsFailure,
  FetchActivityItemsResponse
> = [fetchStart, fetchSuccess, fetchFailure]

export const fetch: StatelessPayloadThunk<Nullable<Meta>, FetchCollectorAdvertsResult> = meta => dispatch =>
  isCollectorMetaPresent(meta)
    ? dispatch(apiAction(fetchActionBundle)(fetchApi(meta.collectorActivitiesUrl)))
    : Promise.resolve(dispatch(fetchFailure(new NotCollectorError())))

const fetchNextPageFailure: FailureActionFactory<FetchCollectorAdvertsNextPageFailure> = failureAction(
  FETCH_COLLECTOR_ADVERTS_NEXT_PAGE_FAILURE,
)

const fetchNextPageActionBundle: ActionBundle<
  FetchCollectorAdvertsNextPageStart,
  FetchCollectorAdvertsNextPageSuccess,
  FetchCollectorAdvertsNextPageFailure,
  FetchActivityItemsResponse
> = [
  action(FETCH_COLLECTOR_ADVERTS_NEXT_PAGE_START),
  payloadAction(FETCH_COLLECTOR_ADVERTS_NEXT_PAGE_SUCCESS),
  fetchNextPageFailure,
]

export const fetchNextPage: StatelessPayloadThunk<Nullable<PaginationMeta>, FetchCollectorAdvertsNextPageResult> =
  paginationMeta => dispatch => {
    if (!paginationMeta) return Promise.resolve(dispatch(fetchNextPageFailure(new Error('no meta in activities'))))

    const { next_url: nextUrl } = paginationMeta

    if (!nextUrl)
      return Promise.resolve(dispatch(fetchNextPageFailure(new Error('no next url to fetch in activities meta'))))

    return dispatch(apiAction(fetchNextPageActionBundle)(fetchApi(nextUrl)))
  }

const fetchHistoryFailure: FailureActionFactory<FetchCollectorHistoryAdvertsFailure> = failureAction(
  FETCH_COLLECTOR_HISTORY_ADVERTS_FAILURE,
)

const fetchHistoryActionBundle: ActionBundle<
  FetchCollectorHistoryAdvertsStart,
  FetchCollectorHistoryAdvertsSuccess,
  FetchCollectorHistoryAdvertsFailure,
  FetchActivityItemsResponse
> = [
  action(FETCH_COLLECTOR_HISTORY_ADVERTS_START),
  payloadAction(FETCH_COLLECTOR_HISTORY_ADVERTS_SUCCESS),
  fetchHistoryFailure,
]

export const fetchHistory: StatelessPayloadThunk<Nullable<Meta>, FetchCollectorHistoryAdvertsResult> =
  meta => dispatch =>
    isCollectorMetaPresent(meta)
      ? dispatch(apiAction(fetchHistoryActionBundle)(fetchApi(meta.collectorActivitiesUrl, { isHistory: true })))
      : Promise.resolve(dispatch(fetchHistoryFailure(new NotCollectorError())))

const fetchHistoryNextPageFailure: FailureActionFactory<FetchCollectorHistoryAdvertsNextPageFailure> = failureAction(
  FETCH_COLLECTOR_HISTORY_ADVERTS_NEXT_PAGE_FAILURE,
)

const fetchHistoryNextPageActionBundle: ActionBundle<
  FetchCollectorHistoryAdvertsNextPageStart,
  FetchCollectorHistoryAdvertsNextPageSuccess,
  FetchCollectorHistoryAdvertsNextPageFailure,
  FetchActivityItemsResponse
> = [
  action(FETCH_COLLECTOR_HISTORY_ADVERTS_NEXT_PAGE_START),
  payloadAction(FETCH_COLLECTOR_HISTORY_ADVERTS_NEXT_PAGE_SUCCESS),
  fetchHistoryNextPageFailure,
]

export const fetchHistoryNextPage: StatelessPayloadThunk<
  Nullable<PaginationMeta>,
  FetchCollectorHistoryAdvertsNextPageResult
> = paginationMeta => dispatch => {
  if (!paginationMeta) return Promise.resolve(dispatch(fetchHistoryNextPageFailure(new Error('no meta in activities'))))

  const { next_url: nextUrl } = paginationMeta

  if (!nextUrl)
    return Promise.resolve(dispatch(fetchHistoryNextPageFailure(new Error('no next url to fetch in activities meta'))))

  return dispatch(apiAction(fetchHistoryNextPageActionBundle)(fetchApi(nextUrl)))
}

const fetchCollectorOffersFailure: FailureActionFactory<FetchCollectorOffersFailure> =
  failureAction(FETCH_COLLECTOR_OFFERS_FAILURE)

const fetchCollectorOffersActionBundle: ActionBundle<
  FetchCollectorOffersStart,
  FetchCollectorOffersSuccess,
  FetchCollectorOffersFailure,
  FetchSupplierOffersResponse
> = [action(FETCH_COLLECTOR_OFFERS_START), payloadAction(FETCH_COLLECTOR_OFFERS_SUCCESS), fetchCollectorOffersFailure]

export const fetchOffers: StatelessPayloadThunk<Nullable<Meta>, FetchCollectorOffersResult> =
  meta => async dispatch => {
    if (!meta) return dispatch(fetchCollectorOffersFailure(new NoMetaError()))

    const {
      actions: { offerActivities },
    } = meta

    if (!offerActivities) return dispatch(fetchCollectorOffersFailure(new Error('no offerActivities in meta')))

    return dispatch(apiAction(fetchCollectorOffersActionBundle)(fetchOfferActivitiesApi(offerActivities.url)))
  }

const fetchCollectorOffersNextPageFailure: FailureActionFactory<FetchCollectorOffersNextPageFailure> = failureAction(
  FETCH_COLLECTOR_OFFERS_NEXT_PAGE_FAILURE,
)

const fetchCollectorOffersNextPageActionBundle: ActionBundle<
  FetchCollectorOffersNextPageStart,
  FetchCollectorOffersNextPageSuccess,
  FetchCollectorOffersNextPageFailure,
  FetchSupplierOffersResponse
> = [
  action(FETCH_COLLECTOR_OFFERS_NEXT_PAGE_START),
  payloadAction(FETCH_COLLECTOR_OFFERS_NEXT_PAGE_SUCCESS),
  fetchCollectorOffersNextPageFailure,
]

export const fetchOffersNextPage: StatelessPayloadThunk<Nullable<PaginationMeta>, FetchCollectorOffersNextPageResult> =
  paginationMeta => dispatch => {
    if (!paginationMeta)
      return Promise.resolve(dispatch(fetchCollectorOffersNextPageFailure(new Error('no meta in activities'))))

    const { next_url: nextUrl } = paginationMeta

    if (!nextUrl)
      return Promise.resolve(
        dispatch(fetchCollectorOffersNextPageFailure(new Error('no next url to fetch in activities meta'))),
      )

    return dispatch(apiAction(fetchCollectorOffersNextPageActionBundle)(fetchApi(nextUrl)))
  }
