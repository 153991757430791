import { post, put } from '../../api'
import { CompoundApi, SimpleApi } from '../../api-action'
import { responseToBody } from '../../helpers'
import {
  CreateCustomerParams,
  CreateCustomerResponse,
  CreateCustomerResponseBody,
  CreateElmerCustomerResponse,
  CreateElmerCustomerResponseBody,
  CreatePaymentMethodTokenParams,
  CreatePaymentMethodTokenResponse,
  CreateSetupIntentParams,
  CreateSetupIntentResponse,
  CreateSetupIntentResponseBody,
  UpdateStripeCustomerResponse,
  UpdateStripeCustomerResponseBody,
} from './types'

// BE requires body, even if it's empty
export const createCustomerApi = (
  url: string,
  params?: CreateCustomerParams,
): CompoundApi<CreateElmerCustomerResponse, CreateElmerCustomerResponseBody> => [
  post(url, params || {}),
  responseToBody,
]

export const createPaymentMethodToken = (
  url: string,
  params: CreatePaymentMethodTokenParams,
): SimpleApi<CreatePaymentMethodTokenResponse> => post(url, params)

export const createStripeCustomerApi = (
  url: string,
  update = false,
): CompoundApi<CreateCustomerResponse, CreateCustomerResponseBody> => [
  update ? put<CreateCustomerResponse>(url) : post<CreateCustomerResponse>(url),
  responseToBody,
]

export const createSetupIntentApi = (
  url: string,
  params: CreateSetupIntentParams,
): CompoundApi<CreateSetupIntentResponse, CreateSetupIntentResponseBody> => [post(url, params), responseToBody]

export const updateStripeCustomerApi = (
  url: string,
): CompoundApi<UpdateStripeCustomerResponse, UpdateStripeCustomerResponseBody> => [put(url), responseToBody]
