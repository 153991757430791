import Reducer from '../../types/reducer'
import { VERIFY_OTP_SUCCESS } from '../otp/types'
import { accountSuspendedLens, isSigningInLens, loginErrorLens, sessionTokenLens } from './lens'
import State, { loginDefaultState } from './state'
import {
  ACCOUNT_SUSPENDED,
  LOGIN_OTP_FAILURE,
  LOGIN_OTP_START,
  LOGIN_OTP_SUCCESS,
  LOGOUT_SUCCESS,
  SET_LOGIN_ERROR,
} from './types'

export const loginReducer: Reducer<State> = (state = loginDefaultState(), action) => {
  switch (action.type) {
    case LOGIN_OTP_START:
      return isSigningInLens(state).set(true)
    case LOGIN_OTP_SUCCESS:
    case LOGIN_OTP_FAILURE:
      return isSigningInLens(state).set(false)
    case VERIFY_OTP_SUCCESS:
      return sessionTokenLens(state).set(action.payload)
    case LOGOUT_SUCCESS:
      return sessionTokenLens(state).set(null)
    case SET_LOGIN_ERROR:
      return loginErrorLens(state).set(action.payload)
    case ACCOUNT_SUSPENDED:
      return accountSuspendedLens(state).set(action.payload)
    default:
      return state
  }
}

export default loginReducer
