import { Get, Nullable } from '../../types/core'

interface State {
  freegleId: Nullable<string>
  hasPostcode: Nullable<boolean>
  isLoadingFreegleAdvert: boolean
}

export const freegleDefaultState: Get<State> = () => ({
  freegleId: null,
  hasPostcode: null,
  isLoadingFreegleAdvert: false,
})

export default State
