import { formatRelative } from 'date-fns/fp'
import React, { useMemo } from 'react'

import { ChatMessage, OnShowAttachment } from '../../entities/chat/types'
import { isDefined } from '../../helpers'
import { ComponentFactory, ToElements } from '../../types/ui'
import { Props as AttachmentsProps } from './Attachments'

export interface ContainerProps {
  isAuthor: boolean
  isServiceMessage: boolean
}

export interface MessageProps {
  isAuthor: boolean
  isServiceMessage: boolean
}

export interface RootProps {
  isAuthor: boolean
  isServiceMessage: boolean
}

export interface SentAtProps {
  isAuthor: boolean
}

interface ElementsProps {
  Attachments: AttachmentsProps
  Container: ContainerProps
  Message: MessageProps
  Pending: unknown
  Root: RootProps
  SentAt: SentAtProps
}

type Elements = ToElements<ElementsProps>

interface Props extends Omit<ChatMessage, 'message' | 'persisted'> {
  onShowAttachment: OnShowAttachment
}

const factory: ComponentFactory<Elements, Props> = ({ Attachments, Container, Message, Pending, Root, SentAt }) =>
  function Item({ attachments, children, isAuthor, onShowAttachment, pending, sentAt, type }) {
    const isServiceMessage = useMemo(() => type === 'servicemessage', [type])

    return (
      <Root isAuthor={isAuthor} isServiceMessage={isServiceMessage}>
        {pending ? <Pending /> : null}
        <Container isAuthor={isAuthor} isServiceMessage={isServiceMessage}>
          <Attachments isAuthor={isAuthor} items={attachments} onShow={onShowAttachment} />
          {isDefined(children) ? (
            <Message isAuthor={isAuthor} isServiceMessage={isServiceMessage}>
              {children}
            </Message>
          ) : null}
          {isServiceMessage ? null : <SentAt isAuthor={isAuthor}>{formatRelative(new Date())(sentAt)}</SentAt>}
        </Container>
      </Root>
    )
  }

export default factory
