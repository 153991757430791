import {
  entries,
  filter,
  findKey,
  flatMap,
  flow,
  fromPairs,
  includes,
  isEqual,
  join,
  last,
  map,
  property,
  split,
} from 'lodash/fp'
import { parse as parseUri, URIComponents } from 'uri-js'

import { isDefined, pushTo, unshiftIn } from '../helpers'
import { Asset, AssetType, Identity, Optional, Transform, TransformOptional, Validation } from '../types/core'

export const assetToPath = property<Asset, 'path'>('path')
export const mapAssetToPath = map<Asset, string>(assetToPath)
export const filterAsset = flow<[AssetType], Validation<Optional<AssetType>>, Validation<Asset>>(isEqual, isEqualType =>
  flow(assetType, isEqualType),
)
export const filterAssets: Transform<AssetType, Identity<Asset[]>> = flow(filterAsset, filter)

const uriToExtension = flow(
  decodeURIComponent,
  uri => parseUri(uri),
  property<URIComponents, 'path'>('path'),
  split('/'),
  last,
  split('.'),
  last,
)

const extensionMapping: Record<string, string> = {
  quicktime: 'mov',
}

const transformExtension: Identity<Optional<string>> = extension =>
  isDefined(extension) ? extensionMapping[extension] || extension : undefined

export const mimeTypeToExtension = flow(split('/'), last, transformExtension)

export const assetTypeToMimeType: Record<AssetType, string[]> = {
  image: ['jpg', 'jpeg', 'png'],
  video: ['mov', 'mp4', 'quicktime'],
}

const mimeToAssetTypeMap: Record<string, Optional<AssetType>> = flow(
  entries,
  flatMap(([type, extensions]) => map(flow(pushTo([type]), join('/'), unshiftIn([type])))(extensions)),
  fromPairs,
)(assetTypeToMimeType)

export const mimeToAssetType: TransformOptional<string, Optional<AssetType>> = mimeType =>
  isDefined(mimeType) ? mimeToAssetTypeMap[mimeType] : undefined

const extensionToMimeType: Identity<Optional<string>> = ext => {
  const key = findKey<string[]>(flow(map(transformExtension), includes(ext)))(assetTypeToMimeType)

  return isDefined(key) ? join('/')([key, ext]) : undefined
}

export const urlToMimeType: Transform<string, Optional<string>> = flow(uriToExtension, extensionToMimeType)
export const assetToMimeType: Transform<Asset, Optional<string>> = ({ mimeType, path }) =>
  mimeType || urlToMimeType(path)
export const assetType: Transform<Asset, Optional<AssetType>> = flow(assetToMimeType, mimeToAssetType)
export const pathToAsset: Transform<string, Asset> = path => ({
  mimeType: urlToMimeType(path),
  path,
})
