import setupAddress from './address'
import setupAnalytics from './analytics'
import setupApi from './api'
import setupApiAction from './api-action'
import setupApp from './app'
import setupGeocode from './geocode'
import setupLogin from './login'
import setupOauth from './oauth'
import setupSentry from './sentry'
import setupSignup from './signup'

export default () => {
  setupAddress()
  setupAnalytics()
  setupApi()
  setupApiAction()
  setupApp()
  setupGeocode()
  setupLogin()
  setupOauth()
  setupSentry()
  setupSignup()
}
