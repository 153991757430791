import { styled } from 'styled'
import { device } from 'utils/css'

import BaseText from '../text/BaseText'
import CenteredColumn from './CenteredColumn'

const InfoBox = styled(CenteredColumn)`
  background-color: ${({ theme: { colors } }) => colors.green};
  border-radius: 0.5em;
  padding: 1.5em 2em;

  @media ${device.mobile} {
    padding: 1em 0.5em;
  }
`

export const Title = styled(BaseText)`
  color: ${({ theme: { colors } }) => colors.primary};
  font-size: 1.25em;
  text-align: center;
  margin-bottom: 1em;

  @media ${device.mobile} {
    font-size: 1.125em;
  }
`

export const Text = styled(BaseText)`
  text-align: center;
  font-size: 1.125em;

  @media ${device.mobile} {
    font-size: 1em;
  }
`

export default InfoBox
