import Cookies, { CookieAttributes } from 'js-cookie'
import { useEffect } from 'react'
import { isProductionEnv } from 'utils/environment'

// TODO: test these
function getCookieDomain(): string {
  const isLocalhost = window.location.origin.includes('localhost')

  if (isLocalhost) {
    return 'localhost'
  }

  if (isProductionEnv) {
    return '.bookings.lovejunk.com'
  }

  return '.bookings-staging.lovejunk.com'
}

export function useStoreGoogleAnalytic(): void {
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.href)
    const gclid = searchParams.get('gclid')
    const msclkid = searchParams.get('msclkid')

    if (gclid != null || msclkid != null) {
      const cookieValue = JSON.stringify({
        gclid,
        msclkid,
      })

      const now = new Date()
      const tomorrow = new Date().setDate(now.getDate() + 1)

      const cookieOptions: CookieAttributes = {
        domain: getCookieDomain(),
        sameSite: 'Strict',
        secure: true,
        expires: tomorrow,
      }

      Cookies.set('__gtm_campaign_url', cookieValue, cookieOptions)
    }
  }, [])
}
