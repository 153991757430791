import { Asset, Assets, Dispatch, isDefined, isNotNull, MediaType, Nullable, Optional, then } from '@lovejunk/core'
import { compact, concat, flow, size, take } from 'lodash/fp'
import React, { CSSProperties, FC, PropsWithChildren, useCallback, useMemo, useRef } from 'react'
import { css, styled } from 'styled'
import { ClassNameProps } from 'types/ui'
import { buildAccept } from 'utils'
import fileLoader from 'utils/file-loader'

export interface Props extends ClassNameProps {
  assets: Assets
  limit: number
  onSelect: Dispatch<Assets>

  error?: string
  border?: boolean
  disabled?: boolean
  hover?: boolean
  labelStyle?: CSSProperties
  mediaType?: MediaType
  multiple?: boolean
}

const UploadInput: FC<PropsWithChildren<Props>> = ({
  assets,
  border,
  children,
  className,
  disabled: disabledProp,
  error,
  hover,
  labelStyle,
  limit,
  mediaType = 'image',
  multiple = true,
  onSelect,
}) => {
  const ref = useRef<HTMLInputElement>(null)
  const selectionLimit = useMemo(() => limit - size(assets), [assets, limit])
  const disabled = disabledProp || selectionLimit <= 0
  const onFilesLoaded = useCallback<Dispatch<Optional<Asset>[]>>(
    pickedAssets =>
      flow(compact, concat(assets), onSelect, () => {
        if (isNotNull(ref.current)) ref.current.value = ''
      })(pickedAssets),
    [assets, onSelect, ref],
  )

  const onInputChange = useCallback<Dispatch<Nullable<FileList>>>(
    fileList => flow(take(selectionLimit), fileLoader, then(onFilesLoaded))(fileList),
    [onFilesLoaded, selectionLimit],
  )

  const onFileInputChange = useCallback(() => ref.current && onInputChange(ref.current.files), [onInputChange, ref])

  return (
    <>
      <HtmlLabel
        border={border}
        className={className}
        disabled={disabled}
        hasError={isDefined(error)}
        hover={hover}
        htmlFor="file-upload"
        style={labelStyle}
      >
        {children}
      </HtmlLabel>
      <Input
        id="file-upload"
        type="file"
        accept={buildAccept(mediaType)}
        multiple={multiple}
        ref={ref}
        onChange={onFileInputChange}
        disabled={disabled}
      />
    </>
  )
}

const Input = styled.input`
  display: none;
`

const labelBorderCss = css<LabelProps>`
  border: 2px dashed ${({ hasError, theme: { colors } }) => (hasError ? colors.error : colors.greyBorder)};
  border-radius: 0.5em;
`

const labelHoverCss = css<LabelProps>`
  :hover {
    background-color: ${({ theme: { colors } }) => colors.grey};
  }
`

interface LabelProps {
  border?: boolean
  disabled?: boolean
  hasError?: boolean
  hover?: boolean
}

const HtmlLabel = styled.label<LabelProps>`
  cursor: pointer;
  ${({ border }) => (border ? labelBorderCss : undefined)}
  padding: 0.625em;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  ${({ hover }) => (hover ? labelHoverCss : undefined)}
`

export default UploadInput
