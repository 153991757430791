import { then } from '@lovejunk/core'
import { constant, flow, isEqual, toString, truncate } from 'lodash/fp'
import { isDesktop, isMobileOnly as isMobile, isTablet } from 'react-device-detect'

export const getUserAgent = constant(Promise.resolve(navigator.userAgent))
export const getIdentifier = flow(getUserAgent, then(truncate({ length: 255 })))

export const apiUrl = toString(process.env.REACT_APP_API_URL)
export const build = toString(process.env.NODE_ENV)
export const environment = toString(process.env.REACT_APP_ENV)

const isBuild = isEqual(build)
const isEnv = isEqual(environment)

export const isDevBuild = isBuild('development')
export const isProductionBuild = isBuild('production')
export const isTestBuild = isBuild('test')

export const isProductionEnv = isEnv('production')
export const isStagingEnv = isEnv('staging')

export { isDesktop, isMobile, isTablet }
