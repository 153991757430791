import { assign, filter, flow, gt, map, mapValues, omit, unionBy } from 'lodash/fp'

import { callWith, lensFactory } from '../../helpers'
import RootState from '../../state'
import { GetLens, Lens, RemoteUrlAction, SetLens, UpdateLens, Validation } from '../../types/core'
import State, { defaultChatMessage } from './state'
import { ChatActions, ChatMessage } from './types'

export const chatRootLens: GetLens<RootState, State> = state => () => state.chat

const lens = lensFactory<State>()

export const chatAdvertInfoLens = lens('advertInfo')
export const chatOtherParticipantsLens = lens('otherParticipants')
export const chatMessagesLens = lens('messages')
export const chatMessagesVersionLens = lens('version')
export const isPhoneRequestedModalVisibleLens = lens('isPhoneRequestedModalVisible')
export const isConferenceCallModalVisibleLens = lens('isConferenceCallModalVisible')
export const startConferenceCallPayloadLens = lens('startConferenceCallPayload')
export const viewPhoneInProgressLens = lens('viewPhoneInProgress')

const unionById = unionBy<ChatMessage>('id')

export const mergeChatMessagesLens: SetLens<State, State['messages']> = state => messages => ({
  ...state,
  messages: unionById(messages)(state.messages),
})

const filterLocalMessage: Validation<ChatMessage> = ({ id, persisted }) => gt(id)(0) || Boolean(persisted)

const filterLocalMessages = filter<ChatMessage>(filterLocalMessage)

export const cleanupLocalMessagesLens: UpdateLens<State> = state => ({
  ...state,
  messages: filterLocalMessages(state.messages),
})

export const chatActionUrlsLens: Lens<State, State['actionUrls'], ChatActions> = state => ({
  get: () => state.actionUrls,
  set: ({ bookNow, ...chatActions }) => ({
    ...state,
    actionUrls: mapValues<RemoteUrlAction, 'url'>('url')(chatActions),
    favouriteId: bookNow?.details.favouriteId,
  }),
})
export const getFavouriteIdLens: GetLens<State, State['favouriteId']> = state => () => state.favouriteId

const omitPending = omit<ChatMessage, 'pending'>('pending')
const mapOmitPending = map(omitPending)

export const clearPendingMessagesLens: UpdateLens<State> = state => ({
  ...state,
  messages: mapOmitPending(state.messages),
})

export const pushDefaultChatMessageLens: SetLens<State, Partial<ChatMessage>> = state => chatMessage => ({
  ...state,
  messages: [...state.messages, flow(defaultChatMessage, assign, callWith(chatMessage))()],
})

export const pushChatMessageLens: SetLens<State, string> = state => message =>
  pushDefaultChatMessageLens(state)({ message, pending: true })

export const pushContactMessageLens: SetLens<State, string> = state => phoneNumber =>
  pushDefaultChatMessageLens(state)({
    isAuthor: false,
    message: `You can reach the collector at ${phoneNumber}`,
    persisted: true,
    type: 'servicemessage',
  })

export default chatRootLens
