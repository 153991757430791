import { NoOp } from '@lovejunk/core'
import { styled } from 'styled'

interface Props {
  size: number
  src: string

  onClick?: NoOp
}

const ImageTile = styled.img<Props>`
  border-radius: 0.5em;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
  display: block;
  object-fit: cover;
  width: ${({ size }) => size}em;
  height: ${({ size }) => size}em;
`

export default ImageTile
