import { isDefined } from '../../helpers'
import DateMarshaller from '../../marshallers/date'
import { Unmarshal } from '../../types/core'
import { ActivityItem, ActivityItemResource, OfferItem, OfferItemResource } from './types'

export const unmarshal: Unmarshal<ActivityItemResource, ActivityItem> = ({
  endAt,
  collectedAt,
  offeredAt,
  startAt,
  ...rest
}) => ({
  ...rest,
  collectedAt: isDefined(collectedAt) ? DateMarshaller.unmarshal(collectedAt) : undefined,
  endAt: DateMarshaller.unmarshal(endAt),
  offeredAt: DateMarshaller.unmarshal(offeredAt),
  startAt: DateMarshaller.unmarshal(startAt),
})

export const unmarshalOfferItem: Unmarshal<OfferItemResource, OfferItem> = ({ endAt, postedAt, startAt, ...rest }) => ({
  ...rest,
  endAt: DateMarshaller.unmarshal(endAt),
  postedAt: DateMarshaller.unmarshal(postedAt),
  startAt: DateMarshaller.unmarshal(startAt),
})
