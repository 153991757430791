export default {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'lovejunk-staging.firebaseapp.com',
  databaseURL: 'https://lovejunk-staging.firebaseio.com',
  projectId: 'lovejunk-staging',
  storageBucket: 'lovejunk-staging.appspot.com',
  messagingSenderId: '179100019745',
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: 'G-KQXPQMCPYS',
}
