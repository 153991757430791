import { buildLens, lensFactory } from '../../helpers'
import RootState from '../../state'
import { GetLens } from '../../types/core'
import State from './state'

export const signupCustomerRootLens: GetLens<RootState, State> = state => () => state.signupCustomer

const lens = lensFactory<State>()

export const isCustomerVatRegisteredLens = lens('isVatRegistered')

export const getIsCustomerVatRegistered = buildLens(signupCustomerRootLens, isCustomerVatRegisteredLens)

export default signupCustomerRootLens
