import { Action } from 'redux'

import { Distance, FailureAction, Nullable, PayloadAction, Price, Response, Span, Transform } from '../../types/core'
import { ActivityType } from '../activities/types'

export interface CancellationReason {
  label: string
  value: string
}

export interface Constants {
  cancellationReasons: CancellationReason[]
  enableCaptcha: boolean
  junkCollectorRadiusRange: Span<Distance>
  junkLoverOfferPrice: Price
  reusableOfferDuration: number
  maximumVideoDuration: number

  conditionalCounterOfferDuration?: number
  twilioNumber?: string
}

export interface Endpoints {
  adverts: string
  advertNavigation: string
  chatNavigation: string
  clientSupport: string
  customers: string
  firebaseConfiguration: string
  freegleListings: string
  galleryVersion: string
  junkCollectors: string
  junkLovers: string
  oauthToken: string
  otp: {
    generation: string
    signUp: string
    login: string
  }
  passwordSession: string
  postcodeCheck: string
  postcodeValidation: string
  priceSuggestion: string
  referrals: {
    userReferralCodes: string
    otpReferrals: string
  }
  stripe: {
    customers: string
    paymentMethods: string
    setupIntents: string
  }
  supportRequests: string
  tags: string
  timeSlots: string
  tokenStatuses: string
  tradeEnquiries: string
  videoUpload: string
  whoami: string
  wtnEnquiries: string
}

export interface Configuration {
  constants: Constants
  endpoints: Endpoints
}

export interface AppConfig<S> {
  getConfiguration: Transform<S, Nullable<Configuration>>
}

export const DISMISS_ERROR = 'DISMISS_ERROR'

export type DismissError = Action<typeof DISMISS_ERROR>

export const REHYDRATE = 'persist/REHYDRATE'

export type Rehydrate = PayloadAction<typeof REHYDRATE, object>

export const CLEAR_CONFIGURATION = 'CLEAR_CONFIGURATION'

export type ClearConfiguration = Action<typeof CLEAR_CONFIGURATION>

export const GET_CONFIGURATION_START = 'GET_CONFIGURATION_START'
export const GET_CONFIGURATION_SUCCESS = 'GET_CONFIGURATION_SUCCESS'
export const GET_CONFIGURATION_FAILURE = 'GET_CONFIGURATION_FAILURE'

export type GetConfigurationStart = Action<typeof GET_CONFIGURATION_START>
export type GetConfigurationFailure = FailureAction<typeof GET_CONFIGURATION_FAILURE>
export type GetConfigurationSuccess = PayloadAction<typeof GET_CONFIGURATION_SUCCESS, Configuration>

export type GetConfigurationResult = GetConfigurationFailure | GetConfigurationSuccess
export type GetConfiguration = GetConfigurationStart | GetConfigurationResult

export type GetConfigurationResponse = Response<Configuration>

export const SET_API_URL = 'SET_API_URL'
export const SET_API_URL_FETCHED = 'SET_API_URL_FETCHED'

export interface SetApiUrlPayload {
  preserveStore: boolean
  url: string
}
export type SetApiUrl = PayloadAction<typeof SET_API_URL, SetApiUrlPayload>
export type SetApiUrlFetched = Action<typeof SET_API_URL_FETCHED>

export const SET_DEVICE_ID = 'SET_DEVICE_ID'

export type SetDeviceId = PayloadAction<typeof SET_DEVICE_ID, Nullable<string>>

export const UNSUPPORTED_POSTCODE = 'UNSUPPORTED_POSTCODE'

export type UnsupportedPostcode = FailureAction<typeof UNSUPPORTED_POSTCODE>

export type SelectedActivity = ActivityType | 'offers'

export const SET_SELECTED_ACTIVITY = 'SET_SELECTED_ACTIVITY'

export type SetSelectedActivity = PayloadAction<typeof SET_SELECTED_ACTIVITY, Nullable<SelectedActivity>>

export type AppAction =
  | ClearConfiguration
  | DismissError
  | GetConfiguration
  | Rehydrate
  | SetApiUrl
  | SetApiUrlFetched
  | SetDeviceId
  | SetSelectedActivity
  | UnsupportedPostcode
