import { QueryClient } from '@tanstack/react-query'

const RETRY_COUNT = 5

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: process.env.NODE_ENV === 'development' ? false : RETRY_COUNT,
    },
  },
})

export default queryClient
