import { flow } from 'lodash/fp'

import { action, failureAction, payloadAction } from '../../action-factory'
import apiAction, { ActionBundle } from '../../api-action'
import { ActionFactory, FailureActionFactory, PayloadActionFactory } from '../../types/core'
import { StatelessPayloadThunk } from '../../types/thunk'
import { stripeFailureUrl, stripeSuccessUrl } from '../../utils/stripe'
import { fetchMetaStep } from '../meta/actions'
import { signupCollectorApi } from './api'
import {
  CollectorBankAccount,
  MarshalSignupCollector,
  SignupCollectorDetails,
  SignupCollectorResponseBody,
} from './types'
import {
  CANCEL_COLLECTOR_SIGNUP,
  CancelCollectorSignup,
  CREATE_COLLECTOR_ACCOUNT,
  CreateCollectorAccount,
  SAVE_COLLECTOR_BANK_ACCOUNT,
  SAVE_COLLECTOR_DETAILS,
  SAVE_COLLECTOR_SELFIE,
  SaveCollectorBankAccount,
  SaveCollectorDetails,
  SaveCollectorSelfie,
  SET_COLLECTOR_SIGNUP_FEEDBACK,
  SetCollectorSignupFeedback,
  SIGNUP_COLLECTOR_FAILURE,
  SIGNUP_COLLECTOR_START,
  SIGNUP_COLLECTOR_SUCCESS,
  SignupCollectorFailure,
  SignupCollectorResult,
  SignupCollectorStart,
  SignupCollectorSuccess,
} from './types/actions'

export const createCollectorAccount: ActionFactory<CreateCollectorAccount> = action(CREATE_COLLECTOR_ACCOUNT)

export const cancelCollectorSignup: ActionFactory<CancelCollectorSignup> = action(CANCEL_COLLECTOR_SIGNUP)

export const setCollectorSignupFeedback: PayloadActionFactory<
  SetCollectorSignupFeedback,
  SetCollectorSignupFeedback['payload']
> = payloadAction(SET_COLLECTOR_SIGNUP_FEEDBACK)

export const saveCollectorDetails: PayloadActionFactory<SaveCollectorDetails, SaveCollectorDetails['payload']> =
  payloadAction(SAVE_COLLECTOR_DETAILS)
export const saveCollectorSelfie: PayloadActionFactory<SaveCollectorSelfie, SaveCollectorSelfie['payload']> =
  payloadAction(SAVE_COLLECTOR_SELFIE)
export const saveCollectorBankAccount: PayloadActionFactory<
  SaveCollectorBankAccount,
  SaveCollectorBankAccount['payload']
> = payloadAction(SAVE_COLLECTOR_BANK_ACCOUNT)

export const signupCollectorFailure: FailureActionFactory<SignupCollectorFailure> =
  failureAction(SIGNUP_COLLECTOR_FAILURE)
const signupCollectorActionBundle: ActionBundle<
  SignupCollectorStart,
  SignupCollectorSuccess,
  SignupCollectorFailure,
  SignupCollectorResponseBody
> = [action(SIGNUP_COLLECTOR_START), payloadAction(SIGNUP_COLLECTOR_SUCCESS), signupCollectorFailure]

interface SignupCollectorPayload {
  bankAccount: CollectorBankAccount
  details: SignupCollectorDetails
  marshal: MarshalSignupCollector
  selfie: string
  url: string
}

export const signupCollector: StatelessPayloadThunk<SignupCollectorPayload, SignupCollectorResult> =
  ({ bankAccount: { accountNumber, sortCode }, details, marshal, selfie, url }) =>
  dispatch =>
    marshal({
      selfie,
      details,
      stripeSuccessUrl,
      stripeFailureUrl,
      accountNumber,
      sortCode,
    })
      .then(flow(signupCollectorApi(url), apiAction(signupCollectorActionBundle)))
      .then(action => dispatch(action))
      .then(fetchMetaStep(SIGNUP_COLLECTOR_SUCCESS))
      .then(action => dispatch(action))
