import {
  COLLECTION_PRICES_URL,
  Dispatch,
  FREEGLE_HOMEPAGE,
  freegleIdLens,
  freegleRootLens,
  getUserActions,
  GOOGLE_STORE_DYNAMIC_LINK,
  hasUnreadFavouriteCollectorMessages,
  HELP_URL,
  HOMEPAGE_URL,
  isJunkLover,
  isLoggedIn,
  isNotNull,
  isTradeCustomer,
  logout,
  NoOp,
  XMAS_MODE,
} from '@lovejunk/core'
import { GooglePlayBadge } from 'assets/icons'
import { ReactComponent as FreegleLogoIcon } from 'assets/icons/freegle-logo.svg'
import { ReactComponent as MenuBurger } from 'assets/icons/menu-burger.svg'
import { ReactComponent as MenuClose } from 'assets/icons/menu-close.svg'
import XmasLogo from 'assets/images/header-title-xmas.png'
import RootState from 'core/state'
import { setMenuVisible } from 'entities/app'
import appRootLens, { isMenuVisibleLens } from 'entities/app/lens'
import NavigationPath from 'navigation/paths'
import React, { FC, useCallback, useMemo } from 'react'
import { isAndroid } from 'react-device-detect'
import { connect, MapStateToProps } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { styled } from 'styled'
import { ContentWidthProps, device, withContentWidth } from 'utils/css'
import { isMobile, isProductionEnv } from 'utils/environment'
import { logClickOnLogin } from 'utils/firebase/analytics'

import IconButton from '../button/IconButton'
import CenteredColumn from '../containers/CenteredColumn'
import CenteredRow from '../containers/CenteredRow'
import ExternalLink from '../ExternalLink'
import Link from '../Link'
import { LogoImage, LogoSvg } from '../Logo'
import ActivityLink from './ActivityLink'
import ReusablesLink from './ReusablesLink'
import SessionLink from './SessionLink'
import StoreLink from './StoreLink'
import StyledExternalLink from './StyledExternalLink'
import StyledLink from './StyledLink'

interface DispatchProps {
  logout: NoOp
  setMenuVisible: Dispatch<boolean>
}

interface OwnProps extends ContentWidthProps {
  externalLogoLink?: boolean
  externalLogoUrl?: string
  reusables?: boolean
}

interface StateProps {
  isFreegle: boolean
  isJunkLover: boolean
  isLoggedIn: boolean
  isTradeCustomer: boolean
  isMenuVisible: boolean

  hasUnreadFavouriteMessages?: boolean
}

type Props = DispatchProps & OwnProps & StateProps

const Header: FC<Props> = ({
  externalLogoLink = false,
  externalLogoUrl = HOMEPAGE_URL,
  hasUnreadFavouriteMessages,
  isFreegle,
  isJunkLover,
  isLoggedIn,
  isMenuVisible,
  isTradeCustomer,
  noMargin,
  reusables = false,
  setMenuVisible,
  size,
}) => {
  const location = useLocation()
  const closeMenu = useCallback(() => setMenuVisible(false), [setMenuVisible])

  const onLogIn = useCallback(() => {
    closeMenu()
    logClickOnLogin()
  }, [closeMenu])

  const onMenuPress = useCallback(() => setMenuVisible(!isMenuVisible), [isMenuVisible, setMenuVisible])
  const publicReusables = useMemo(() => reusables && !isLoggedIn, [isLoggedIn, reusables])

  return (
    <Root noMargin={noMargin}>
      <Container publicReusables={publicReusables} size={size}>
        {externalLogoLink ? (
          <ExternalLink href={externalLogoUrl} onPress={closeMenu} target="_self">
            {XMAS_MODE ? <LogoImage src={XmasLogo} /> : <LogoSvg />}
          </ExternalLink>
        ) : (
          <Link onPress={closeMenu} to={isLoggedIn ? NavigationPath.Dashboard : NavigationPath.Home}>
            {XMAS_MODE ? <LogoImage src={XmasLogo} /> : <LogoSvg />}
          </Link>
        )}
        {!isProductionEnv && location.pathname !== NavigationPath.DevSettings && (
          <DevButton onPress={closeMenu} to={NavigationPath.DevSettings}>
            Settings
          </DevButton>
        )}
        {(!isMobile || isMenuVisible) && (
          <LinkGroup publicReusables={publicReusables}>
            {isFreegle && (
              <ExternalLink href={FREEGLE_HOMEPAGE} onClick={closeMenu} target="_blank">
                <FreegleLogo />
              </ExternalLink>
            )}
            {isTradeCustomer && !isMobile && (
              <StyledLink onPress={closeMenu} to={NavigationPath.TradeCustomerJobs}>
                History
              </StyledLink>
            )}
            {publicReusables && <StyledExternalLink href={HOMEPAGE_URL}>Home</StyledExternalLink>}
            {publicReusables && <StyledExternalLink href={COLLECTION_PRICES_URL}>Collection prices</StyledExternalLink>}
            {(publicReusables || (isLoggedIn && (!isTradeCustomer || isJunkLover))) && (
              <ReusablesLink onPress={closeMenu} />
            )}
            {publicReusables && <StyledExternalLink href={HELP_URL}>Help</StyledExternalLink>}
            {isLoggedIn && (
              <StyledLink onPress={closeMenu} to={NavigationPath.Home}>
                Create listing
              </StyledLink>
            )}
            {isLoggedIn && <ActivityLink onPress={closeMenu} />}
            {isLoggedIn ? (
              <SessionLink
                dot={hasUnreadFavouriteMessages}
                $isModalMode={isMobile}
                onPress={closeMenu}
                to={NavigationPath.Account}
              >
                Account
              </SessionLink>
            ) : (
              <SessionLink $isModalMode={isMobile} hostAware onPress={onLogIn} to={NavigationPath.Login}>
                Log in
              </SessionLink>
            )}
            {!isFreegle && isAndroid && (
              <StoreLink href={GOOGLE_STORE_DYNAMIC_LINK} onPress={closeMenu} src={GooglePlayBadge} />
            )}
          </LinkGroup>
        )}
        {isMobile && <IconButton SvgIcon={isMenuVisible ? MenuClose : MenuBurger} onPress={onMenuPress} size={2} />}
      </Container>
    </Root>
  )
}

interface PublicReusablesProps {
  publicReusables: boolean
}

const Root = styled(CenteredColumn)<ContentWidthProps>`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
  margin-bottom: ${({ noMargin }) => (noMargin ? 0 : 2)}em;

  @media ${device.mobile} {
    margin-bottom: ${({ noMargin }) => (noMargin ? 0 : 1.5)}em;
  }
`

type ContainerProps = ContentWidthProps & PublicReusablesProps

const Container = styled(CenteredRow)<ContainerProps>`
  ${withContentWidth}

  gap: 1em;
  justify-content: space-between;
  background-color: ${({ theme: { colors } }) => colors.white};
  height: ${({ publicReusables }) => (publicReusables ? 6 : 5.5)}em;
  padding: 0 1em;

  @media ${device.mobile} {
    height: 4.5em;
  }
`

const LinkGroup = styled(CenteredRow)<PublicReusablesProps>`
  gap: ${({ publicReusables }) => (publicReusables ? 3.75 : 2)}em;

  ${({ publicReusables }) => (publicReusables ? 'font-size: 1.125em;' : undefined)}

  @media ${device.tablet} {
    gap: 1em;
  }

  @media ${device.mobile} {
    position: absolute;
    top: 6em;
    left: 0;
    right: 0;
    bottom: 0;

    align-items: stretch;
    background-color: ${({ theme: { colors } }) => colors.white};
    flex-direction: column;
    gap: 2em;
    padding: 0 2em;
    width: 100%;
    z-index: 3;
  }
`

const FreegleLogo = styled(FreegleLogoIcon)`
  display: block;
  width: 4em;
`

const DevButton = styled(Link)`
  background-color: ${({ theme: { colors } }) => colors.error};
  color: ${({ theme: { colors } }) => colors.white};
  padding: 0.25em 0.5em;

  :hover {
    color: ${({ theme: { colors } }) => colors.black} !important;
  }
`

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = rootState => ({
  hasUnreadFavouriteMessages: hasUnreadFavouriteCollectorMessages(getUserActions(rootState)),
  isFreegle: isNotNull(freegleIdLens(freegleRootLens(rootState)()).get()),
  isLoggedIn: isLoggedIn(rootState),
  isJunkLover: isJunkLover(rootState),
  isTradeCustomer: isTradeCustomer(rootState),
  isMenuVisible: isMenuVisibleLens(appRootLens(rootState)()).get(),
})

const mapDispatchToProps = {
  logout,
  setMenuVisible,
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
