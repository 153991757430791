import { Get, loginDefaultState as coreLoginDefaultState, LoginState, Nullable } from '@lovejunk/core'
import { flow } from 'lodash'

interface State extends LoginState {
  returnTo: Nullable<string>
}

export const loginDefaultState: Get<State> = flow(coreLoginDefaultState, state => ({
  ...state,
  returnTo: null,
}))

export default State
