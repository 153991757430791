import { AdvertActionPayload, CancellationReasonPayload, Nullable, PayloadAction } from '@lovejunk/core'

export const RELIST_OR_CANCEL = 'RELIST_OR_CANCEL'

export type RelistOrCancel = PayloadAction<typeof RELIST_OR_CANCEL, Nullable<CancellationReasonPayload>>

export const SET_CANCEL_ADVERT_PAYLOAD = 'SET_CANCEL_ADVERT_PAYLOAD'

export type SetCancelAdvertPayload = PayloadAction<typeof SET_CANCEL_ADVERT_PAYLOAD, Nullable<AdvertActionPayload>>

export type CustomerAdvertAction = RelistOrCancel | SetCancelAdvertPayload
