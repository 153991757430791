import React from 'react'

import {
  ADD_FAVOURITE_COLLECTOR_SUBMIT_CAPTION,
  ADD_FAVOURITE_COLLECTOR_TEXT,
  ADD_FAVOURITE_COLLECTOR_TITLE,
} from '../../constants'
import { Color } from '../../theme'
import { NoOp } from '../../types/core'
import { ComponentFactory, ToElements } from '../../types/ui'

interface StateProps {
  isAdding: boolean
}

export interface OwnProps {
  collectorName: string
  onAdd: NoOp
  onDismiss: NoOp
}

type Props = OwnProps & StateProps

export interface DismissButtonProps {
  color: Color
  disabled: boolean
  onPress: NoOp
}

export interface SubmitButtonProps {
  onPress: NoOp
  progress: boolean
  text: string
}

interface ElementsProps {
  DismissButton: DismissButtonProps
  SubmitButton: SubmitButtonProps
  Root: unknown
  SubTitle: unknown
  Text: unknown
}

type Elements = ToElements<ElementsProps>

const factory: ComponentFactory<Elements, Props> = ({ DismissButton, Root, SubmitButton, SubTitle, Text }) =>
  function AddFavouriteCollector({ collectorName, isAdding, onAdd, onDismiss }) {
    return (
      <Root>
        <SubTitle>{ADD_FAVOURITE_COLLECTOR_TITLE}</SubTitle>
        <Text>{ADD_FAVOURITE_COLLECTOR_TEXT(collectorName)}</Text>
        <SubmitButton onPress={onAdd} progress={isAdding} text={ADD_FAVOURITE_COLLECTOR_SUBMIT_CAPTION} />
        <DismissButton color="greyText" disabled={isAdding} onPress={onDismiss}>
          Dismiss
        </DismissButton>
      </Root>
    )
  }

export default factory
