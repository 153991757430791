import { stringify as stringifyQuery } from 'query-string'
import { serialize as serializeUri } from 'uri-js'

import { responseToJson } from '../../helpers'
import { Transform } from '../../types/core'
import buildConfig from '../../utils/build-config'
import { Address, AddressConfig, AddressItem, AutocompleteResponse, FindByPostcodeResult, Suggestion } from './types'

const { getConfig, setConfig } = buildConfig<AddressConfig>('address')

export { setConfig as configureAddress }

export const findByPostcodeApi: Transform<string, Promise<AddressItem[]>> = postcode =>
  fetch(
    serializeUri({
      scheme: 'https',
      host: 'api.getaddress.io',
      path: 'find/' + postcode,
      query: stringifyQuery({ 'api-key': getConfig().apiKey, all: true }),
    }),
  )
    .then<FindByPostcodeResult>(responseToJson)
    .then(json => json.addresses)

export const getAddressesApi: Transform<string, Promise<Suggestion[]>> = term =>
  fetch(
    serializeUri({
      scheme: 'https',
      host: 'api.getaddress.io',
      path: 'autocomplete/' + term,
      query: stringifyQuery({ 'api-key': getConfig().apiKey, all: true }),
    }),
  )
    .then<AutocompleteResponse>(responseToJson)
    .then(json => json.suggestions)

export const getAddressApi: Transform<string, Promise<Address>> = path =>
  fetch(
    serializeUri({
      scheme: 'https',
      host: 'api.getaddress.io',
      path,
      query: stringifyQuery({ 'api-key': getConfig().apiKey }),
    }),
  ).then<Address>(responseToJson)
