import { Action } from 'redux'

import {
  AuthIdentifierType,
  AuthIdentifierValue,
  AuthParams,
  DeviceId,
  Dispatch,
  FailureAction,
  NoOp,
  Nullable,
  PayloadAction,
  RemoteUrlAction,
  Response,
} from '../../types/core'
import { BackToChat } from '../account/types'
import { Meta } from '../meta/types'

export type SetUserId = Dispatch<Nullable<number>>

export interface LoginConfig {
  resetToHome: NoOp
  setUserId: SetUserId
}

export interface LoginPayload {
  identifier: AuthIdentifierValue
  type: AuthIdentifierType
}

export interface LoginParams extends AuthParams {
  type: AuthIdentifierType
}

export interface LoginFailurePayload {
  data: LoginPayload
  error: Error
}

export const LOGIN_OTP_START = 'LOGIN_OTP_START'
export const LOGIN_OTP_SUCCESS = 'LOGIN_OTP_SUCCESS'
export const LOGIN_OTP_FAILURE = 'LOGIN_OTP_FAILURE'

export type LoginOtpStart = PayloadAction<typeof LOGIN_OTP_START, string>
export type LoginOtpSuccess = Action<typeof LOGIN_OTP_SUCCESS>
export type LoginOtpFailure = PayloadAction<typeof LOGIN_OTP_FAILURE, LoginFailurePayload>

export type LoginOtpResult = LoginOtpFailure | LoginOtpSuccess
export type LoginOtp = LoginOtpStart | LoginOtpResult

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

export type LogoutSuccess = Action<typeof LOGOUT_SUCCESS>

export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR'

export type SetLoginError = PayloadAction<typeof SET_LOGIN_ERROR, Nullable<LoginFailurePayload>>

export const CANCEL_LOGIN = 'CANCEL_LOGIN'

export type CancelLogin = Action<typeof CANCEL_LOGIN>

export const LOGIN_COLLECTOR = 'LOGIN_COLLECTOR'
export const LOGIN_CUSTOMER = 'LOGIN_CUSTOMER'
export const LOGIN_JUNK_LOVER = 'LOGIN_JUNK_LOVER'

export type LoginCollector = Action<typeof LOGIN_COLLECTOR>
export type LoginCustomer = Action<typeof LOGIN_CUSTOMER>
export type LoginJunkLover = Action<typeof LOGIN_JUNK_LOVER>

export const ACCOUNT_SUSPENDED = 'ACCOUNT_SUSPENDED'
export type AccountSuspended = PayloadAction<typeof ACCOUNT_SUSPENDED, boolean>

export interface AddIdentifierData {
  identifier: AuthIdentifierValue
  type: AuthIdentifierType

  backToChat?: BackToChat
  locked?: boolean
  url?: string
}

export interface AddIdentifierParams {
  device: DeviceId
  identifier: AuthIdentifierValue
}

export interface AddIdentifierPayload {
  data: AddIdentifierData
  device: DeviceId
  meta: Nullable<Meta>
}

interface AddIdentifierResponseActions {
  verifyIdentifier?: RemoteUrlAction
}

export interface AddIdentifierResponseBody {
  otpLength: number
  actions: AddIdentifierResponseActions
}

export type AddIdentifierResponse = Response<AddIdentifierResponseBody>

export const ADD_IDENTIFIER_START = 'ADD_IDENTIFIER_START'
export const ADD_IDENTIFIER_SUCCESS = 'ADD_IDENTIFIER_SUCCESS'
export const ADD_IDENTIFIER_FAILURE = 'ADD_IDENTIFIER_FAILURE'

export interface AddIdentifierBasePayload {
  type: AuthIdentifierType

  backToChat?: BackToChat
  locked?: boolean
}

export interface AddIdentifierFailurePayload extends AddIdentifierBasePayload {
  error: Error
}

export type AddIdentifierStart = Action<typeof ADD_IDENTIFIER_START>
export type AddIdentifierSuccess = PayloadAction<typeof ADD_IDENTIFIER_SUCCESS, AddIdentifierResponseBody>
export type AddIdentifierFailure = PayloadAction<typeof ADD_IDENTIFIER_FAILURE, AddIdentifierFailurePayload>
export type AddIdentifierResult = AddIdentifierSuccess | AddIdentifierFailure
export type AddIdentifier = AddIdentifierStart | AddIdentifierResult

export const VERIFY_IDENTIFIER_START = 'VERIFY_IDENTIFIER_START'
export const VERIFY_IDENTIFIER_SUCCESS = 'VERIFY_IDENTIFIER_SUCCESS'
export const VERIFY_IDENTIFIER_FAILURE = 'VERIFY_IDENTIFIER_FAILURE'

export type VerifyIdentifierStart = Action<typeof VERIFY_IDENTIFIER_START>
export type VerifyIdentifierSuccess = Action<typeof VERIFY_IDENTIFIER_SUCCESS>
export type VerifyIdentifierFailure = FailureAction<typeof VERIFY_IDENTIFIER_FAILURE>
export type VerifyIdentifierResult = VerifyIdentifierSuccess | VerifyIdentifierFailure
export type VerifyIdentifier = VerifyIdentifierStart | VerifyIdentifierResult

export type LoginAction =
  | AccountSuspended
  | AddIdentifier
  | CancelLogin
  | LoginCollector
  | LoginCustomer
  | LoginJunkLover
  | LoginOtp
  | LogoutSuccess
  | SetLoginError
  | VerifyIdentifier
