import React, { ComponentProps, FC } from 'react'
import { styled } from 'styled'
import { device } from 'utils/css'

import ExternalLink from '../ExternalLink'

interface Props extends ComponentProps<typeof ExternalLink> {
  href: string
  src: string
}

const StoreLink: FC<Props> = ({ src, ...props }) => (
  <ExternalLink {...props} rel="noopener noreferrer">
    <Badge src={src} />
  </ExternalLink>
)

const Badge = styled.img`
  display: block;
  height: 2em;

  @media ${device.mobile} {
    height: 1.5em;
  }
`

export default StoreLink
