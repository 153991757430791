import React, { ReactElement } from 'react'

import { ComponentFactory, ToElements } from '../../types/ui'

interface ChildrenProps {
  children: ReactElement
}

export type GenericProps<T> = ChildrenProps & T

interface OwnProps {
  value: boolean
}

export type Props = GenericProps<OwnProps>

interface ElementsProps {
  Loading: unknown
}

type Elements = ToElements<ElementsProps>

const factory: ComponentFactory<Elements, Props> = ({ Loading }) =>
  function Check({ children, value }) {
    return value ? children : <Loading />
  }

export default factory
