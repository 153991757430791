import { Action } from 'redux'

import { Transform } from './types/core'
import { GenericThunkAction, GenericThunkDispatch } from './types/thunk'

const withState =
  <A extends Action, S>() =>
  <T>(f1: Transform<S, T>) =>
  <R>(f2: (dispatch: GenericThunkDispatch<S, A>, state: T) => Promise<R>): GenericThunkAction<R, S, A> =>
  (dispatch, getState) =>
    f2(dispatch, f1(getState()))

export default withState
