import { flow, pickBy } from 'lodash/fp'

import { buildLens, isDefined, isNotNull, lensFactory } from '../../helpers'
import RootState from '../../state'
import { GetLens, Lens, Validation } from '../../types/core'
import { isSupplierConfig } from './config'
import State from './state'
import { SignupPayload } from './types'

export const signupRootLens: GetLens<RootState, State> = state => () => state.signup

const lens = lensFactory<State>()

export const identifierLens = lens('identifier')
export const isSigningUpLens = lens('isSigningUp')
export const signupErrorLens = lens('error')
export const signupRoleConfigLens = lens('config')

export const signupDataLens: Lens<State, SignupPayload, Partial<SignupPayload>> = state => ({
  get: () => ({
    countryCode: state.countryCode,
    email: state.email,
    firstName: state.firstName,
    identifier: state.identifier,
    identifierType: state.identifierType,
    lastName: state.lastName,
    phoneNumber: state.phoneNumber,
    receiveMarketingOffers: state.receiveMarketingOffers,
  }),
  set: data => ({ ...state, ...pickBy<SignupPayload>(isDefined)(data) }),
})

export const getIsSigningUp = buildLens(signupRootLens, isSigningUpLens)
export const getSignupRoleConfig = buildLens(signupRootLens, signupRoleConfigLens)
export const getSignupData = buildLens(signupRootLens, signupDataLens)
export const isSupplierSignup: Validation<RootState> = flow(
  getSignupRoleConfig,
  c => isNotNull(c) && isSupplierConfig(c),
)

export default signupRootLens
