import React, { useEffect } from 'react'

import { NoOp } from '../../types/core'
import { Factory } from './factory'

interface DispatchProps {
  fetchTags: NoOp
  getRemoteConfig: NoOp
}

export interface StateProps {
  hasRemoteConfig: boolean
  tagsLoaded: boolean
}

const factory: Factory<DispatchProps & StateProps> = ({ Check }) =>
  function StoreCheck({ children, fetchTags, getRemoteConfig, hasRemoteConfig, tagsLoaded }) {
    useEffect(() => {
      if (!hasRemoteConfig) getRemoteConfig()
    }, [getRemoteConfig, hasRemoteConfig])

    useEffect(() => {
      if (!tagsLoaded) fetchTags()
    }, [fetchTags, tagsLoaded])

    return <Check value={hasRemoteConfig && tagsLoaded}>{children}</Check>
  }

export default factory
