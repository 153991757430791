import { Action } from 'redux'

import { ImagesToBodyItems } from '../../marshallers/image'
import {
  Asset,
  Dispatch,
  FailureAction,
  FavouriteId,
  FileToBodyItem,
  NoOp,
  Optional,
  PayloadAction,
  Price,
  RemoteUrlAction,
  Response,
} from '../../types/core'
import { ActivityItem } from '../activities/types'
import { AdvertId, AdvertReference, Status, TimeDetails } from '../advert/types'
import { FavouriteInteractions, SessionRelation, UserActions } from '../meta/types'

export type ChatId = number
export type AttachmentType = 'image' | 'video'
export type ChatMessageType = 'chat' | 'servicemessage'

export type AttachmentId = number
export type ChatMessageId = number
export type ChatMessagesVersion = number

export interface ShowBookNowPayload {
  favouriteId?: FavouriteId
}

export interface BookNowPayload extends ShowBookNowPayload {
  favouriteId: FavouriteId
}

interface BookNowActionDetails {
  favouriteId: FavouriteId
}

interface BookNowAction {
  details: BookNowActionDetails
}

export interface AttachmentPayload {
  path: string
  type: AttachmentType
}

export type OnShowAttachment = Dispatch<AttachmentPayload>

interface AttachmentActions {
  getSignedUrl: RemoteUrlAction
}

export interface AttachmentAssetProps {
  onPress: Dispatch<string>
  isAuthor: boolean

  onTap?: NoOp
  url?: string
}

export interface Attachment {
  autodownload: boolean
  id: AttachmentId
  type: AttachmentType

  actions?: Partial<AttachmentActions>
}

interface GetAttachmentUrlBody {
  url: string
}

export type GetAttachmentUrlResponse = Response<GetAttachmentUrlBody>

interface ChatMessageBase<D> {
  authorName: string
  id: ChatMessageId
  isAuthor: boolean
  type: ChatMessageType
  sentAt: D

  attachments?: Attachment[]
  message?: string // 350 characters max
  // these are not coming from BE but added in FE as an optimistic update.
  pending?: true
  persisted?: true
}

export type ChatMessage = ChatMessageBase<Date>
export type ChatMessageResource = ChatMessageBase<string>

interface SupportedChatActions {
  acceptJunkLoverOffer: RemoteUrlAction
  addPhoneNumber: RemoteUrlAction
  bookNow: BookNowAction
  startConferenceCall: RemoteUrlAction
  submitMessage: RemoteUrlAction
  submitMessageWithImages: RemoteUrlAction
  submitMessageWithVideos: RemoteUrlAction
  viewMessages: RemoteUrlAction
  viewPhoneNumber: RemoteUrlAction
  viewThreadUpdates: RemoteUrlAction
}

export type ChatActions = Partial<SupportedChatActions>

export type ChatActionUrls = Partial<Record<keyof SupportedChatActions, string>>

export type ChatOtherParticipants = string[]

export interface ChatAdvertInfo {
  id: AdvertId
  reference: AdvertReference
  relation: SessionRelation
  title: string
  status: Status
  timeDetails: TimeDetails

  counterOfferId?: number
  imageUrl?: string
  jobId?: number
  postcode?: string
  postcodeDistrict?: string
  price?: Price
}

export interface FetchChatMessagesResponseBase<T> {
  actions: ChatActions
  messages: T[]
  version: ChatMessagesVersion

  advertInfo?: ChatAdvertInfo
  otherParticipantNames?: ChatOtherParticipants
}

export interface CreateChatThreadPayload {
  url: string
}

export interface ChatNavigationBody {
  viewMessages?: RemoteUrlAction
}

export type ChatNavigationResponse = Response<ChatNavigationBody>

export const CHAT_NAVIGATION_START = 'CHAT_NAVIGATION_START'
export const CHAT_NAVIGATION_SUCCESS = 'CHAT_NAVIGATION_SUCCESS'
export const CHAT_NAVIGATION_FAILURE = 'CHAT_NAVIGATION_FAILURE'

export type ChatNavigationStart = Action<typeof CHAT_NAVIGATION_START>
export type ChatNavigationSuccess = PayloadAction<typeof CHAT_NAVIGATION_SUCCESS, ChatNavigationBody>
export type ChatNavigationFailure = FailureAction<typeof CHAT_NAVIGATION_FAILURE>
export type ChatNavigationResult = ChatNavigationSuccess | ChatNavigationFailure
type ChatNavigation = ChatNavigationStart | ChatNavigationResult

export type CreateChatThreadResponseBody = FetchChatMessagesResponseBase<ChatMessageResource>
export type CreateChatThreadBody = FetchChatMessagesResponseBase<ChatMessage>
export type CreateChatThreadResponse = Response<CreateChatThreadResponseBody>

export const CREATE_CHAT_THREAD_START = 'CREATE_CHAT_THREAD_START'
export const CREATE_CHAT_THREAD_SUCCESS = 'CREATE_CHAT_THREAD_SUCCESS'
export const CREATE_CHAT_THREAD_FAILURE = 'CREATE_CHAT_THREAD_FAILURE'

export type CreateChatThreadStart = Action<typeof CREATE_CHAT_THREAD_START>
export type CreateChatThreadSuccess = PayloadAction<typeof CREATE_CHAT_THREAD_SUCCESS, CreateChatThreadBody>
export type CreateChatThreadFailure = FailureAction<typeof CREATE_CHAT_THREAD_FAILURE>
export type CreateChatThreadResult = CreateChatThreadSuccess | CreateChatThreadFailure
type CreateChatThread = CreateChatThreadStart | CreateChatThreadResult

export interface AddPhonePressPayload {
  addPhoneUrl: string
}

export interface RevealPhonePressPayload {
  startConferenceCallUrl?: string
  viewPhoneUrl: string
}

export interface StartConferenceCallPayload {
  startConferenceCallUrl: string
  ownerPhoneNumber: string
  firstName?: string
}

export type FetchChatMessagesResponseBody = FetchChatMessagesResponseBase<ChatMessageResource>
export type FetchChatMessagesBody = FetchChatMessagesResponseBase<ChatMessage>
export type FetchChatMessagesResponse = Response<FetchChatMessagesResponseBody>

export const FETCH_CHAT_MESSAGES_START = 'FETCH_CHAT_MESSAGES_START'
export const FETCH_CHAT_MESSAGES_SUCCESS = 'FETCH_CHAT_MESSAGES_SUCCESS'
export const FETCH_CHAT_MESSAGES_FAILURE = 'FETCH_CHAT_MESSAGES_FAILURE'

export type FetchChatMessagesStart = Action<typeof FETCH_CHAT_MESSAGES_START>
export type FetchChatMessagesSuccess = PayloadAction<typeof FETCH_CHAT_MESSAGES_SUCCESS, FetchChatMessagesBody>
export type FetchChatMessagesFailure = FailureAction<typeof FETCH_CHAT_MESSAGES_FAILURE>
export type FetchChatMessagesResult = FetchChatMessagesSuccess | FetchChatMessagesFailure
type FetchChatMessages = FetchChatMessagesStart | FetchChatMessagesResult

export interface FetchChatMessagesUpdatesResponseBase<T> {
  messages: T[]
  version: ChatMessagesVersion
}

export type FetchChatMessagesUpdatesResponseBody = FetchChatMessagesUpdatesResponseBase<ChatMessageResource>
export type FetchChatMessagesUpdatesBody = FetchChatMessagesUpdatesResponseBase<ChatMessage>
export type FetchChatMessagesUpdatesResponse = Response<FetchChatMessagesUpdatesResponseBody>

export const FETCH_CHAT_MESSAGES_UPDATES_START = 'FETCH_CHAT_MESSAGES_UPDATES_START'
export const FETCH_CHAT_MESSAGES_UPDATES_SUCCESS = 'FETCH_CHAT_MESSAGES_UPDATES_SUCCESS'
export const FETCH_CHAT_MESSAGES_UPDATES_FAILURE = 'FETCH_CHAT_MESSAGES_UPDATES_FAILURE'

export type FetchChatMessagesUpdatesStart = Action<typeof FETCH_CHAT_MESSAGES_UPDATES_START>
export type FetchChatMessagesUpdatesSuccess = PayloadAction<
  typeof FETCH_CHAT_MESSAGES_UPDATES_SUCCESS,
  FetchChatMessagesUpdatesBody
>
export type FetchChatMessagesUpdatesFailure = FailureAction<typeof FETCH_CHAT_MESSAGES_UPDATES_FAILURE>
export type FetchChatMessagesUpdatesResult = FetchChatMessagesUpdatesSuccess | FetchChatMessagesUpdatesFailure
type FetchChatMessagesUpdates = FetchChatMessagesUpdatesStart | FetchChatMessagesUpdatesResult

export interface FetchChatMessagesAppPayload {
  url: string

  advertId?: AdvertId
}

export interface FetchChatMessagesPayload extends FetchChatMessagesAppPayload {
  activities: ActivityItem[]

  reloadHasUnreadMessagesUrl?: string
  userActions?: Partial<UserActions>
}

export interface FetchChatMessagesUpdatesPayload {
  url: string
  version: ChatMessagesVersion
}

export interface ChatUrlsPayload {
  submitMessageUrl: string
}

export interface ChatAssetsUrlsPayload {
  submitImageMessageUrl: string
  submitVideoMessageUrl: string
}

export interface ChatMessagePayload {
  message: string
}

export interface ChatMessageWithAssetsPayload extends ChatMessagePayload {
  assets: Asset[]
}

export type SubmitChatMessageBundlePayload = ChatAssetsUrlsPayload & ChatMessageWithAssetsPayload

export interface MessageJson {
  message: string
}

export type SubmitChatMessagePayload = ChatUrlsPayload & ChatMessagePayload

export interface SendChatMessageBundlePayload extends SubmitChatMessageBundlePayload {
  imagesToBodyItems: ImagesToBodyItems
  videoToBodyItem: FileToBodyItem
}

export const SEND_CHAT_MESSAGE_START = 'SEND_CHAT_MESSAGE_START'
export const SEND_CHAT_MESSAGE_SUCCESS = 'SEND_CHAT_MESSAGE_SUCCESS'
export const SEND_CHAT_MESSAGE_FAILURE = 'SEND_CHAT_MESSAGE_FAILURE'

export interface SentChatMessageResponseBase<D> {
  createdAt: D
  id: ChatMessageId
  sentAt: D
  type: ChatMessageType
}

export type SentChatMessageResponseBody = SentChatMessageResponseBase<string>
export type SentChatMessageBody = SentChatMessageResponseBase<Date>
export type SendChatMessageResponse = Response<SentChatMessageResponseBody>

export type SendChatMessageStart = PayloadAction<typeof SEND_CHAT_MESSAGE_START, Optional<string>>
export type SendChatMessageSuccess = PayloadAction<typeof SEND_CHAT_MESSAGE_SUCCESS, SentChatMessageBody>
export type SendChatMessageFailure = FailureAction<typeof SEND_CHAT_MESSAGE_FAILURE>
export type SendChatMessageResult = SendChatMessageSuccess | SendChatMessageFailure
type SendChatMessage = SendChatMessageStart | SendChatMessageResult

export const SEND_CHAT_MESSAGE_BUNDLE_SUCCESS = 'SEND_CHAT_MESSAGE_BUNDLE_SUCCESS'
export const SEND_CHAT_MESSAGE_BUNDLE_FAILURE = 'SEND_CHAT_MESSAGE_BUNDLE_FAILURE'

export type SendChatMessageBundleSuccess = Action<typeof SEND_CHAT_MESSAGE_BUNDLE_SUCCESS>
export type SendChatMessageBundleFailure = FailureAction<typeof SEND_CHAT_MESSAGE_BUNDLE_FAILURE>
export type SendChatMessageBundleResult = SendChatMessageBundleSuccess | SendChatMessageBundleFailure

export const SHOW_CONFERENCE_CALL_MODAL = 'SHOW_CONFERENCE_CALL_MODAL'
export const CLOSE_CONFERENCE_CALL_MODAL = 'CLOSE_CONFERENCE_CALL_MODAL'
export const SET_CONFERENCE_CALL_PAYLOAD = 'SET_CONFERENCE_CALL_PAYLOAD'

export type ShowConferenceCallModal = Action<typeof SHOW_CONFERENCE_CALL_MODAL>
export type SetConferenceCallPayload = PayloadAction<typeof SET_CONFERENCE_CALL_PAYLOAD, StartConferenceCallPayload>
export type CloseConferenceCallModal = Action<typeof CLOSE_CONFERENCE_CALL_MODAL>

export type OnSubmitChat = Dispatch<ChatMessagePayload, Promise<SendChatMessageResult>>
export type OnSubmitChatWithAssets = Dispatch<ChatMessageWithAssetsPayload, Promise<SendChatMessageBundleResult>>
export type SubmitChatMessage = Dispatch<SubmitChatMessagePayload, Promise<SendChatMessageResult>>
export type SubmitChatMessageBundle = Dispatch<SubmitChatMessageBundlePayload, Promise<SendChatMessageBundleResult>>

export interface ReloadGlobalHasUnreadMessagesResponseBody {
  hasUnreadMessages: boolean
}

// global
export type ReloadGlobalHasUnreadMessagesResponse = Response<ReloadGlobalHasUnreadMessagesResponseBody>

export const RELOAD_GLOBAL_HAS_UNREAD_MESSAGES_START = 'RELOAD_GLOBAL_HAS_UNREAD_MESSAGES_START'
export const RELOAD_GLOBAL_HAS_UNREAD_MESSAGES_SUCCESS = 'RELOAD_GLOBAL_HAS_UNREAD_MESSAGES_SUCCESS'
export const RELOAD_GLOBAL_HAS_UNREAD_MESSAGES_FAILURE = 'RELOAD_GLOBAL_HAS_UNREAD_MESSAGES_FAILURE'

// listing-related
export type ReloadHasUnreadMessagesResponseBody = ReloadGlobalHasUnreadMessagesResponseBody
export type ReloadHasUnreadMessagesResponse = Response<ReloadHasUnreadMessagesResponseBody>

export const RELOAD_HAS_UNREAD_MESSAGES_START = 'RELOAD_HAS_UNREAD_MESSAGES_START'
export const RELOAD_HAS_UNREAD_MESSAGES_SUCCESS = 'RELOAD_HAS_UNREAD_MESSAGES_SUCCESS'
export const RELOAD_HAS_UNREAD_MESSAGES_FAILURE = 'RELOAD_HAS_UNREAD_MESSAGES_FAILURE'

export type ReloadHasUnreadMessagesStart = Action<typeof RELOAD_HAS_UNREAD_MESSAGES_START>
export type ReloadHasUnreadMessagesSuccess = PayloadAction<typeof RELOAD_HAS_UNREAD_MESSAGES_SUCCESS, boolean>
export type ReloadHasUnreadMessagesFailure = FailureAction<typeof RELOAD_HAS_UNREAD_MESSAGES_FAILURE>
export type ReloadHasUnreadMessagesResult = ReloadHasUnreadMessagesSuccess | ReloadHasUnreadMessagesFailure
export type ReloadHasUnreadMessages = ReloadHasUnreadMessagesStart | ReloadHasUnreadMessagesResult

export type ReloadGlobalHasUnreadMessagesStart = Action<typeof RELOAD_GLOBAL_HAS_UNREAD_MESSAGES_START>
export type ReloadGlobalHasUnreadMessagesSuccess = PayloadAction<
  typeof RELOAD_GLOBAL_HAS_UNREAD_MESSAGES_SUCCESS,
  boolean
>
export type ReloadGlobalHasUnreadMessagesFailure = FailureAction<typeof RELOAD_GLOBAL_HAS_UNREAD_MESSAGES_FAILURE>
export type ReloadGlobalHasUnreadMessagesResult =
  | ReloadGlobalHasUnreadMessagesSuccess
  | ReloadGlobalHasUnreadMessagesFailure
export type ReloadGlobalHasUnreadMessages = ReloadGlobalHasUnreadMessagesStart | ReloadGlobalHasUnreadMessagesResult

export interface ReloadActivityHasUnreadMessagesParams {
  advertId: AdvertId
  url: string
}

export interface ReloadActivityHasUnreadMessagesPayload {
  advertId: AdvertId
  activities: ActivityItem[]
}

export interface ReloadActivityHasUnreadMessagesResponseBody {
  hasUnreadMessages: boolean
}

export interface ReloadActivityHasUnreadMessagesSuccessPayload extends ReloadActivityHasUnreadMessagesResponseBody {
  advertId: AdvertId
}

export type ReloadActivityHasUnreadMessagesResponse = Response<ReloadActivityHasUnreadMessagesResponseBody>

export const RELOAD_ACTIVITY_HAS_UNREAD_MESSAGES_START = 'RELOAD_ACTIVITY_HAS_UNREAD_MESSAGES_START'
export const RELOAD_ACTIVITY_HAS_UNREAD_MESSAGES_SUCCESS = 'RELOAD_ACTIVITY_HAS_UNREAD_MESSAGES_SUCCESS'
export const RELOAD_ACTIVITY_HAS_UNREAD_MESSAGES_FAILURE = 'RELOAD_ACTIVITY_HAS_UNREAD_MESSAGES_FAILURE'

export type ReloadActivityHasUnreadMessagesStart = Action<typeof RELOAD_ACTIVITY_HAS_UNREAD_MESSAGES_START>
export type ReloadActivityHasUnreadMessagesSuccess = PayloadAction<
  typeof RELOAD_ACTIVITY_HAS_UNREAD_MESSAGES_SUCCESS,
  ReloadActivityHasUnreadMessagesSuccessPayload
>
export type ReloadActivityHasUnreadMessagesFailure = FailureAction<typeof RELOAD_ACTIVITY_HAS_UNREAD_MESSAGES_FAILURE>
export type ReloadActivityHasUnreadMessagesResult =
  | ReloadActivityHasUnreadMessagesSuccess
  | ReloadActivityHasUnreadMessagesFailure
export type ReloadActivityHasUnreadMessages =
  | ReloadActivityHasUnreadMessagesStart
  | ReloadActivityHasUnreadMessagesResult

export interface ReloadFavouritesHasUnreadMessagesPayload {
  favouriteInteractions: FavouriteInteractions
  url: string
}

export const RELOAD_FAVOURITES_HAS_UNREAD_MESSAGES_START = 'RELOAD_FAVOURITES_HAS_UNREAD_MESSAGES_START'
export const RELOAD_FAVOURITES_HAS_UNREAD_MESSAGES_SUCCESS = 'RELOAD_FAVOURITES_HAS_UNREAD_MESSAGES_SUCCESS'
export const RELOAD_FAVOURITES_HAS_UNREAD_MESSAGES_FAILURE = 'RELOAD_FAVOURITES_HAS_UNREAD_MESSAGES_FAILURE'

export interface ReloadFavouritesHasUnreadMessagesSuccessPayload {
  favouriteInteractions: FavouriteInteractions
  hasUnreadMessages: boolean
}

export interface ReloadFavouritesHasUnreadMessagesResponseBody {
  hasUnreadMessages: boolean
}

export type ReloadFavouritesHasUnreadMessagesResponse = Response<ReloadFavouritesHasUnreadMessagesResponseBody>

export type ReloadFavouritesHasUnreadMessagesStart = Action<typeof RELOAD_FAVOURITES_HAS_UNREAD_MESSAGES_START>
export type ReloadFavouritesHasUnreadMessagesSuccess = PayloadAction<
  typeof RELOAD_FAVOURITES_HAS_UNREAD_MESSAGES_SUCCESS,
  ReloadFavouritesHasUnreadMessagesSuccessPayload
>
export type ReloadFavouritesHasUnreadMessagesFailure = FailureAction<
  typeof RELOAD_FAVOURITES_HAS_UNREAD_MESSAGES_FAILURE
>
export type ReloadFavouritesHasUnreadMessagesResult =
  | ReloadFavouritesHasUnreadMessagesSuccess
  | ReloadFavouritesHasUnreadMessagesFailure
export type ReloadFavouritesHasUnreadMessages =
  | ReloadFavouritesHasUnreadMessagesStart
  | ReloadFavouritesHasUnreadMessagesResult

export const PUSH_CONTACT_MESSAGE = 'PUSH_CONTACT_MESSAGE'
export type PushContactMessage = PayloadAction<typeof PUSH_CONTACT_MESSAGE, string>

export const START_CONFERENCE_CALL_START = 'START_CONFERENCE_CALL_START'
export const START_CONFERENCE_CALL_SUCCESS = 'START_CONFERENCE_CALL_SUCCESS'
export const START_CONFERENCE_CALL_FAILURE = 'START_CONFERENCE_CALL_FAILURE'

export type StartConferenceCallStart = Action<typeof START_CONFERENCE_CALL_START>
export type StartConferenceCallSuccess = Action<typeof START_CONFERENCE_CALL_SUCCESS>
export type StartConferenceCallFailure = FailureAction<typeof START_CONFERENCE_CALL_FAILURE>

export type StartConferenceCallResult = StartConferenceCallSuccess | StartConferenceCallFailure
export type StartConferenceCall = StartConferenceCallStart | StartConferenceCallResult

export interface ViewPhoneResponseBody {
  phoneNumber: string
}

export type ViewPhoneResponse = Response<ViewPhoneResponseBody>

export const VIEW_PHONE_START = 'VIEW_PHONE_START'
export const VIEW_PHONE_SUCCESS = 'VIEW_PHONE_SUCCESS'
export const VIEW_PHONE_FAILURE = 'VIEW_PHONE_FAILURE'

export type ViewPhoneStart = Action<typeof VIEW_PHONE_START>
export type ViewPhoneSuccess = PayloadAction<typeof VIEW_PHONE_SUCCESS, string>
export type ViewPhoneFailure = FailureAction<typeof VIEW_PHONE_FAILURE>

export type ViewPhoneResult = ViewPhoneSuccess | ViewPhoneFailure
export type ViewPhone = ViewPhoneStart | ViewPhoneResult

export const CLOSE_CHAT = 'CLOSE_CHAT'
export type CloseChat = Action<typeof CLOSE_CHAT>

export const ADD_PHONE_REQUESTED = 'ADD_PHONE_REQUESTED'
export type AddPhoneRequested = PayloadAction<typeof ADD_PHONE_REQUESTED, boolean>

export type ChatAction =
  | AddPhoneRequested
  | ChatNavigation
  | CloseChat
  | CreateChatThread
  | FetchChatMessages
  | FetchChatMessagesUpdates
  | CloseConferenceCallModal
  | PushContactMessage
  | ReloadActivityHasUnreadMessages
  | ReloadFavouritesHasUnreadMessages
  | ReloadHasUnreadMessages
  | ReloadGlobalHasUnreadMessages
  | ShowConferenceCallModal
  | SendChatMessage
  | SendChatMessageBundleResult
  | SetConferenceCallPayload
  | StartConferenceCall
  | ViewPhone
