import { flow, pick, reverse, sortBy } from 'lodash/fp'

import { Asset, Marshal, Price, PriceValue } from '../../types/core'
import { FreegleAdvertImage } from './types'

export const imageMarshaller: Marshal<FreegleAdvertImage[], Asset[]> = flow(
  sortBy('isPrimary'),
  reverse,
  pick<FreegleAdvertImage, 'path'>('path'),
)

export const priceMarshaller: Marshal<Price, PriceValue> = price => price.amount
