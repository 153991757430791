import { Dispatch, LoadSize as Size } from '@lovejunk/core'
import React, { useCallback } from 'react'
import { styled, ThemeProps } from 'styled'
import { withTheme } from 'styled-components'
import { CarProps } from 'utils/advert'
import { device } from 'utils/css'

import CenteredColumn from './containers/CenteredColumn'

export interface Props extends CarProps {
  onPress: Dispatch<Size>
  selected: boolean
}

const CarButton = ({ description, Icon: src, onPress: onPressProp, selected, size, title }: Props & ThemeProps) => {
  const onPress = useCallback(() => onPressProp(size), [onPressProp, size])

  return (
    <Root onClick={onPress}>
      <IconContainer selected={selected}>
        <Icon src={src} />
      </IconContainer>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Root>
  )
}

const Root = styled(CenteredColumn)`
  flex: 1;
  color: ${({ theme: { colors } }) => colors.carIcon};
`

interface IconContainerProps extends ThemeProps {
  selected: boolean
}

const IconContainer = styled(CenteredColumn)<IconContainerProps>`
  border-color: ${({ selected, theme: { colors } }) => (selected ? colors.selected : colors.grey)};
  border-style: solid;
  border-width: 4px;
  border-radius: 6px;
  cursor: pointer;
  height: 4em;
  justify-content: center;
  margin-bottom: 10px;
  padding: 5px;
  transition: 0.1s background-color linear;

  :hover {
    background-color: ${({ theme: { colors } }) => colors.selected};
  }

  @media ${device.tablet} {
    :hover {
      background-color: auto;
    }
  }
`

const Icon = styled.img`
  width: 100%;
`

const Title = styled.p`
  margin: 0;
  text-align: center;
`

const Description = styled.p`
  color: ${({ theme: { colors } }) => colors.contrast2};
  font-size: 0.8em;
  margin: 0;
  text-align: center;
`

export default withTheme(CarButton)
