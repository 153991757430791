import { getConstantsLens } from '@lovejunk/core'
import RootState from 'core/state'
import { toString } from 'lodash/fp'
import React, { FC, PropsWithChildren } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { connect, MapStateToProps } from 'react-redux'

interface StateProps {
  enableCaptcha: boolean
}

type Props = StateProps

const ReCaptcha: FC<PropsWithChildren<Props>> = ({ children, enableCaptcha }) =>
  enableCaptcha ? (
    <GoogleReCaptchaProvider reCaptchaKey={toString(process.env.REACT_APP_SITE_KEY_V3)}>
      {children}
    </GoogleReCaptchaProvider>
  ) : (
    <>{children}</>
  )

const mapStateToProps: MapStateToProps<StateProps, unknown, RootState> = rootState => ({
  enableCaptcha: Boolean(getConstantsLens(rootState)?.enableCaptcha),
})

export default connect(mapStateToProps)(ReCaptcha)
