import { castArray, filter, first, flow, map, mapValues, negate, some, spread } from 'lodash/fp'

import { areAnyDefined, isDefined } from '../helpers'
import { OneOrMany, Optional, Transform, Validation } from '../types/core'

export interface ValidationConfig<T> {
  validation: Validation<T>
  value: T
  message?: string
}

type MaybeError = Optional<string>
type ValidationConfigValue = OneOrMany<ValidationConfig<any>>
export type ValidationErrorsInput<T extends string> = Record<T, ValidationConfigValue>
type ValidationErrorsOutput<T extends string> = Record<T, MaybeError>
type FieldErrorOutput<T extends string> = Transform<T, MaybeError>

const validateField = <T>(validation: Validation<T>, value: T, message = 'Required') =>
  validation(value) ? undefined : message

const runValidationConfig = <T>({ message, validation, value }: ValidationConfig<T>): MaybeError =>
  validateField(validation, value, message)

const mapValidationConfigs: Transform<ValidationConfigValue, MaybeError[]> = flow(castArray, map(runValidationConfig))

export const validateConfig: Validation<ValidationConfigValue> = flow(
  mapValidationConfigs,
  negate(spread(areAnyDefined)),
)

const validationErrors = <T extends string>(input: ValidationErrorsInput<T>): ValidationErrorsOutput<T> =>
  mapValues<ValidationErrorsInput<T>, MaybeError>(flow(mapValidationConfigs, filter(isDefined), first))(input)

const createFieldErrorOutput =
  (isSubmitted: boolean) =>
  <T extends string>(errors: ValidationErrorsOutput<T>): FieldErrorOutput<T> =>
  key =>
    isSubmitted ? errors[key] : undefined

interface ValidateFormResult<T extends string> {
  hasError: boolean
  fieldErrorOutput: FieldErrorOutput<T>
}

const validateForm =
  (isSubmitted: boolean) =>
  <T extends string>(input: ValidationErrorsInput<T>) =>
    flow<[ValidationErrorsInput<T>], ValidationErrorsOutput<T>, ValidateFormResult<T>>(validationErrors, errors => ({
      hasError: isSubmitted && some(isDefined)(errors),
      fieldErrorOutput: createFieldErrorOutput(isSubmitted)(errors),
    }))(input)

export default validateForm
