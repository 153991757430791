import React, { useCallback } from 'react'

import { AdvertReference } from '../../entities/advert/types'
import { formatReviewedAt } from '../../entities/collector-profile/helpers'
import { CollectorReview } from '../../entities/collector-profile/types'
import { compactAndJoin } from '../../helpers'
import { Dispatch, NoOp } from '../../types/core'
import { ComponentFactory, ToElements } from '../../types/ui'
import { createImgixParams } from '../../utils/imgix'
import { Props as RatingProps } from '../advert/Rating'

interface AdvertTitleProps {
  onPress: NoOp
}

interface ImageProps {
  onPress: NoOp

  uri?: string
}

export interface ElementsProps {
  AdvertTitle: AdvertTitleProps
  Comments: unknown
  Content: unknown
  Image: ImageProps
  NameAndPostcode: unknown
  Rating: RatingProps
  ReviewedAt: unknown
  Root: unknown
  TopRow: unknown
  Verified: unknown
}

type Elements = ToElements<ElementsProps>

export interface Props extends CollectorReview {
  navigateToAdvert: Dispatch<AdvertReference>
}

const addParams = createImgixParams(100)

const factory: ComponentFactory<Elements, Props> = ({
  AdvertTitle,
  Comments,
  Content,
  Image,
  NameAndPostcode,
  Rating,
  ReviewedAt,
  Root,
  TopRow,
  Verified,
}) =>
  function CollectorReviewItem({
    advertReference,
    advertTitle,
    comments,
    customerName,
    image,
    navigateToAdvert,
    postcodeDistrict,
    rating,
    reviewedAt,
  }) {
    const onAdvert = useCallback(() => navigateToAdvert(advertReference), [advertReference, navigateToAdvert])

    return (
      <Root>
        <Content>
          <TopRow>
            <NameAndPostcode>{compactAndJoin(', ')([customerName, postcodeDistrict])}</NameAndPostcode>
            <Rating rating={rating} color="tertiary" />
            <Verified />
          </TopRow>
          <AdvertTitle onPress={onAdvert}>{advertTitle}</AdvertTitle>
          {comments ? <Comments>{comments}</Comments> : null}
          <ReviewedAt>{formatReviewedAt(reviewedAt)}</ReviewedAt>
        </Content>
        <Image onPress={onAdvert} uri={image && addParams(image)} />
      </Root>
    )
  }

export default factory
