import { Get, Nullable } from '../../types/core'
import { AddIdentifierData, AddIdentifierFailurePayload } from '../login/types'
import { AuthIdentifierVerificationPayload } from './types'

interface State {
  authIdentifierVerificationPayload: Nullable<AddIdentifierFailurePayload | AuthIdentifierVerificationPayload>
  error: Nullable<Error>
  otpResentPayload: Nullable<AddIdentifierData>
}

export const otpDefaultState: Get<State> = () => ({
  authIdentifierVerificationPayload: null,
  error: null,
  otpResentPayload: null,
})

export default State
