import { CollectorCardElementsProps as ElementsProps, collectorProfileCardFactory as factory } from '@lovejunk/core'
import { MdCheck } from 'react-icons/md'
import { styled } from 'styled'

import CenteredRow from '../containers/CenteredRow'
import CenteredView from '../containers/CenteredView'
import Column from '../containers/Column'
import Row from '../containers/Row'
import Rating from '../Rating'
import ReactIcon from '../ReactIcon'
import Avatar from '../SupplierInfo/UserCard/Avatar'
import Text from '../text/Text'

export default factory({
  Avatar,
  AvatarContainer: styled(CenteredView)`
    padding: 0 1em;
  `,
  Check: styled(ReactIcon).attrs({ Icon: MdCheck, size: 1 })`
    margin-right: 0.25em;
  `,
  CheckContainer: styled(CenteredRow)`
    justify-content: flex-start;
  `,
  CheckText: Text,
  Collections: styled(Text)`
    margin-bottom: 0.5em;
  `,
  Content: styled(Column)`
    justify-content: flex-start;
    margin-left: 1em;
  `,
  HumanizedRating: styled(Text).attrs({ bold: true, color: 'yellow' })``,
  Name: styled(Text).attrs({ bold: true, size: 1 })``,
  Rating: styled(Rating)<ElementsProps['Rating']>`
    justify-content: flex-start;
    margin: 0.5em 0;
  `,
  Root: styled(Row)`
    background-color: ${({ theme: { colors } }) => colors.green};
    justify-content: flex-start;
    padding: 1em;
  `,
})
