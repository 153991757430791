import { flow, isUndefined, join } from 'lodash/fp'

import { buildUrl, isDefined, pushTo } from '../../helpers'
import { OptionalTransform, Price, Transform, TransformOptional } from '../../types/core'
import { formatPrice } from '../../utils/intl'
import { isZeroPrice } from '../../utils/price'
import { ReferralCodeData, ReferralInfo, ViewDataConfig } from './types'

export const buildReferralUrl: TransformOptional<string, Transform<string, string>> = flow(buildUrl, urlToPath =>
  flow(pushTo(['referrals']), join('/'), urlToPath),
)

export const hasBenefit = (price?: Price): price is Price => isDefined(price) && !isZeroPrice(price)

export const createReferralsViewData =
  <T>(
    { mutual, none, noReferee, noReferrals, noReferrer }: ViewDataConfig<T>,
    createLink: Transform<string, Promise<string>>,
  ): OptionalTransform<ReferralCodeData, Promise<T>> =>
  data => {
    if (isUndefined(data)) return Promise.resolve(noReferrals())

    const { code, ...benefits } = data
    const { referrerBenefit, refereeBenefit } = benefits
    const linkPromise = createLink(code)

    if (hasBenefit(referrerBenefit) && hasBenefit(refereeBenefit)) {
      return linkPromise.then(mutual).then(f => f({ referrerBenefit, refereeBenefit }))
    }

    if (hasBenefit(referrerBenefit)) {
      return linkPromise.then(noReferee).then(f => f(referrerBenefit))
    }

    if (hasBenefit(refereeBenefit)) {
      return linkPromise.then(noReferrer).then(f => f(refereeBenefit))
    }

    return linkPromise.then(none)
  }

export const referralSocialParameters = {
  title: 'LoveJunk - Rubbish Clearance',
  image: 'https://elmer-files.s3-eu-west-2.amazonaws.com/elmer/images/icon.png',
  description:
    'Get rid of your bulky waste responsibly and cheaply with the click of a button. ' +
    'For DIY & builders rubbish, furniture & appliances, garden refuse and general junk. ' +
    'Licensed collectors. Tracked disposal. 96% landfill diversion.',
}

export const referralAnalytics = (campaign: string) => ({
  campaign,
  medium: 'promocodes',
  source: 'share',
})

export const creditInfo: Transform<ReferralInfo, string> = ({ refereeCredit }) => `${formatPrice(refereeCredit)} credit`
