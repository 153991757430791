import { constant, entries, flow, isEmpty, isUndefined, pickBy, toString } from 'lodash/fp'
import React, { ReactElement, ReactNode, useCallback, useMemo, useState } from 'react'

import {
  ADD_FAVOURITE_COLLECTOR_TITLE,
  COLLECT_FOR_FREE_CAPTION,
  CONTACT_CUSTOMER_TEXT,
  MESSAGE_OWNER_TEXT,
  MESSAGED_OWNER_AWAITING_REPLY_TEXT,
  VIEW_MESSAGES_TEXT,
} from '../../constants'
import {
  collectionDoneCaption,
  formatValidRange,
  getPrice,
  isAdvertWithCounterOffer as isAdvertWithCounterOfferHelper,
  isOwnerAdvertWithCounterOffer as isOwnerAdvertWithCounterOfferHelper,
} from '../../entities/advert/helpers'
import {
  isCollectorCollectionDoneState,
  isCustomerToConfirmState,
  isJunkLoverCollectionDoneState,
} from '../../entities/advert/helpers/role'
import {
  AdvertBase,
  AdvertDetailsPayload,
  AdvertIdRefUrl,
  CancellationReasonPayload,
  CollectJunkPayload,
  ConfirmReusableCollectionPayload,
  DocumentPayload as DocumentPayloadBase,
  EditAndRepostActionDetails,
  MakeCounterOfferAction,
  NewAdvertPayload,
  RelistOrCancelActionDetails,
  SupplierDetails,
  UserDetails,
  UserDetailsWithPhoneNumber,
} from '../../entities/advert/types'
import {
  CounterOfferValidationResult,
  isCounterOfferValid,
  validateCounterOffer,
} from '../../entities/collector-advert/helpers'
import {
  AcceptJobPayload,
  CollectorCollectionPayload,
  MakeCounterOfferPayload,
  WithdrawCounterOfferPayload,
} from '../../entities/collector-advert/types'
import { MAKE_COUNTER_OFFER_SUCCESS, MakeCounterOfferResult } from '../../entities/collector-advert/types/actions'
import {
  AddFavouriteCollectorIntentPayload,
  AdjustPricePayload,
  CollectorsOffersPayload,
  ConfirmationPayload,
  DeleteAdvertPayload,
  IncreasePricePayload,
  JunkReusersOffersPayload,
} from '../../entities/customer-advert/types'
import { OnIncreasePricePayload } from '../../entities/customer-advert/types/actions'
import { CollectForFreePayload } from '../../entities/junk-reuser-advert/types'
import { CollectorNotApprovedError, JunkLoverNotApprovedError } from '../../errors'
import { hasValue, isDefined, resolve } from '../../helpers'
import { Dispatch, Get, NoOp, Optional, Price, RemoteUrlAction, Transform, TransformOptional } from '../../types/core'
import { Navigate } from '../../types/navigation'
import { ComponentFactory, Render, ToElements } from '../../types/ui'
import { defaultCurrencySymbol, formatPrice } from '../../utils/intl'
import { arePricesEqual, increasePriceBy } from '../../utils/price'
import { renderList } from '../../utils/ui'
import { OwnProps as SupplierInfoProps } from '../SupplierInfo/factory'

export type Callback<P = string> = Transform<P, NoOp>

interface RenderIncreasePriceButtonPayload {
  increment: Price
  url: string
}

interface RenderAcceptJobPayload extends AcceptJobPayload {
  isRequesting: boolean

  price?: Price
}

export type RenderAcceptJob = Render<RenderAcceptJobPayload>

interface DocumentPayload extends DocumentPayloadBase {
  email?: string
}

export type RenderDocument = Render<DocumentPayload>

interface ExternalLinkButtonProps {
  url: string
  text: string
}

export interface DispatchProps {
  acceptJob: Dispatch<AcceptJobPayload>
  checkCollectorApproval: Dispatch<AdvertIdRefUrl, Promise<Optional<CollectorNotApprovedError>>>
  checkJunkLoverApproval: Dispatch<AdvertIdRefUrl, Promise<Optional<JunkLoverNotApprovedError>>>
  collectForFree: Dispatch<CollectForFreePayload>
  confirmReusableCollectionIntent: Dispatch<ConfirmReusableCollectionPayload>
  deleteAdvertIntent: Dispatch<DeleteAdvertPayload>
  editAdvert: Dispatch<AdvertBase>
  increasePrice: Dispatch<IncreasePricePayload>
  initiatePayment: Dispatch<ConfirmationPayload>
  makeCounterOffer: Dispatch<MakeCounterOfferPayload, Promise<MakeCounterOfferResult>>
  setAddFavouriteCollectorIntent: Dispatch<AddFavouriteCollectorIntentPayload>
  userCollectForFree: Dispatch<CollectJunkPayload>
  withdrawCounterOffer: Dispatch<WithdrawCounterOfferPayload>
}

interface CollectorAdvertStateProps {
  isRequesting: boolean
}

interface CollectorAdvertsStateProps {
  isMakeCounterOfferInProgress: boolean
  isWithdrawCounterOfferInProgress: boolean
}

interface CustomerAdvertStateProps {
  isConfirming: boolean
  isIncreasing: boolean
}

interface MetaStateProps {
  isCollector: boolean
  isJunkLover: boolean

  email?: string
}

export type StateProps = CollectorAdvertStateProps &
  CollectorAdvertsStateProps &
  CustomerAdvertStateProps &
  MetaStateProps

interface OwnProps extends AdvertDetailsPayload {
  navigate: Navigate
  promptReuserSignup: Dispatch<AdvertIdRefUrl>
  relistOrCancel: Dispatch<CancellationReasonPayload>
  renderCollectorReceipt: RenderDocument
  renderCollectorWtn: RenderDocument
  renderContactCollector: Render<string>
  renderCustomerReceipt: RenderDocument
  renderCustomerWtn: RenderDocument

  checkLocationPermission?: Get<Promise<boolean>>
  contactCustomer?: Transform<string, NoOp>
  renderAcceptJob?: RenderAcceptJob
  shouldRenderNonPublicViewAsPublic?: boolean
}

type Props = DispatchProps & OwnProps & StateProps

interface ButtonProps {
  onPress: NoOp
  text: string

  disabled?: boolean
  progress?: boolean
  progressText?: string
  secondary?: boolean
}

interface ButtonWithDotProps extends ButtonProps {
  dot?: boolean
}

export interface CounterOfferButtonProps {
  onPress: NoOp
  progress: boolean
  text: string

  disabled?: boolean
  secondary?: boolean
}

interface CounterOfferInputProps {
  label: string
  onChange: Dispatch<string>
  value: string

  error?: string
  prefix?: string
}

interface DividerProps {
  border?: boolean
}

interface ElementsProps {
  Action: unknown
  AdjustPrice: AdjustPricePayload
  Button: ButtonProps
  ButtonWithDot: ButtonWithDotProps
  CounterOfferButton: CounterOfferButtonProps
  CounterOfferInput: CounterOfferInputProps
  CounterOfferRow: unknown
  CounterOfferText: unknown
  Divider: DividerProps
  ExternalLinkButton: ExternalLinkButtonProps
  Info: unknown
  Root: unknown
  SupplierInfo: SupplierInfoProps
}

type Elements = ToElements<ElementsProps>

const factory: ComponentFactory<Elements, Props> = ({
  Action,
  AdjustPrice,
  Button,
  ButtonWithDot,
  CounterOfferButton,
  CounterOfferInput,
  CounterOfferRow,
  CounterOfferText,
  Divider,
  Info,
  Root,
  SupplierInfo,
}) => {
  return function Actions({
    acceptJob,
    advert,
    advert: {
      actions,
      actions: {
        acceptJob: acceptJobAction,
        addFavourite: addFavouriteAction,
        awaitingOffers: awaitingOffersAction,
        awaitingReply: awaitingReplyAction,
        collectorDisposalSites: collectorDisposalSitesAction,
        collectorReceipt: collectorReceiptAction,
        collectorWtn: collectorWtnAction,
        collectJunkForFree: collectJunkForFreeAction,
        customerReceipt: customerReceiptAction,
        customerWtn: customerWtnAction,
        deleteAdvert: deleteAction,
        editAndRepost: editAndRepostAction,
        fetchAllCounterOffers: fetchAllCounterOffersAction,
        fetchAllJunkLoverOffers: fetchAllJunkLoverOffersAction,
        increasePrice: increasePriceAction,
        initiatePaymentProcess: initiatePaymentAction,
        jobDisposal: jobDisposalAction,
        makeAdjustment: adjustPriceAction,
        makeCounterOffer: makeCounterOfferAction,
        makeJunkLoverOffer: makeJunkLoverOfferAction,
        promptReuserSignup: promptReuserSignupAction,
        relistOrCancel: relistOrCancelAction,
        repost: repostAction,
        viewMessages: viewMessagesAction,
        waitForPayment: waitForPaymentAction,
        withdrawCounterOffer: withdrawCounterOfferAction,
      },
      assignedCollectorDetails: collectorDetails,
      assignedJunkLoverDetails: junkLoverDetails,
      customerDetails,
      id: advertId,
      jobDetails,
      junkDetails: { isReusable },
      junkLoverOfferDetails,
      metaDetails: { reference: advertRef, source },
      publicDetails: { supplierType, timeDetails },
      status,
      view,
    },
    advertUrl,
    checkCollectorApproval,
    checkJunkLoverApproval,
    checkLocationPermission = constant(resolve(true)),
    collectForFree,
    confirmReusableCollectionIntent,
    contactCustomer,
    deleteAdvertIntent,
    editAdvert,
    email,
    increasePrice,
    initiatePayment,
    isMakeCounterOfferInProgress,
    isConfirming,
    isCollector,
    isIncreasing,
    isJunkLover,
    isRequesting,
    isWithdrawCounterOfferInProgress,
    makeCounterOffer,
    navigate,
    promptReuserSignup: promptReuserSignupProp,
    relistOrCancel,
    renderAcceptJob: renderAcceptJobProp,
    renderCollectorReceipt: renderCollectorReceiptProp,
    renderCollectorWtn: renderCollectorWtnProp,
    renderContactCollector: renderContactCollectorProp,
    renderCustomerReceipt: renderCustomerReceiptProp,
    renderCustomerWtn: renderCustomerWtnProp,
    setAddFavouriteCollectorIntent,
    shouldRenderNonPublicViewAsPublic = false,
    userCollectForFree,
    withdrawCounterOffer,
  }) {
    const advertIdRefUrl = useMemo<AdvertIdRefUrl>(
      () => ({ advertId, advertRef, advertUrl }),
      [advertId, advertRef, advertUrl],
    )
    const isAdvertWithCounterOffer = isAdvertWithCounterOfferHelper(advert)
    const isOwnerAdvertWithCounterOffer = isOwnerAdvertWithCounterOfferHelper(advert)
    const price = getPrice(advert)
    const priceValue = isDefined(price) ? price.amount : undefined
    const hasNoActions = isEmpty(actions)
    const isActive = status === 'active'
    const isCollected = status === 'collected'
    const isUnderway = status === 'helpunderway'
    const isOwner = view === 'owner'
    const showViewMessagesText = isJunkLover && !isOwner
    const isSupplier = isCollector || isJunkLover
    const jobStatus = useMemo(() => jobDetails?.status, [jobDetails])
    const [counterOffer, setCounterOffer] = useState<string>('')
    const [counterOfferSubmitted, setCounterOfferSubmitted] = useState<boolean>(false)

    const counterOfferValidationResult = useMemo<Optional<CounterOfferValidationResult>>(
      () =>
        makeCounterOfferAction
          ? validateCounterOffer(counterOffer, makeCounterOfferAction.details.validRange)
          : undefined,
      [counterOffer, makeCounterOfferAction],
    )
    const counterOfferDisabled = useMemo<boolean>(
      () => counterOfferSubmitted && isDefined(counterOfferValidationResult) && !counterOfferValidationResult.valid,
      [counterOfferValidationResult, counterOfferSubmitted],
    )
    const counterOfferError = useMemo(
      () =>
        counterOfferSubmitted &&
        isDefined(counterOfferValidationResult) &&
        !isCounterOfferValid(counterOfferValidationResult)
          ? counterOfferValidationResult.error
          : undefined,
      [counterOfferValidationResult, counterOfferSubmitted],
    )

    const onAcceptJob = useCallback<Callback<RemoteUrlAction>>(
      ({ trackingEvents, url }) =>
        () =>
          checkLocationPermission().then(hasPermission => {
            if (!hasPermission) return

            acceptJob({ ...advertIdRefUrl, trackingEvents, url })
          }),
      [acceptJob, advertIdRefUrl, checkLocationPermission],
    )

    const onIncreasePrice = useCallback<Callback<OnIncreasePricePayload>>(
      payload => () => increasePrice({ ...payload, advertUrl }),
      [advertUrl, increasePrice],
    )

    const onInitiatePayment = useCallback<Callback<ConfirmationPayload>>(
      payload => () => initiatePayment(payload),
      [initiatePayment],
    )

    const onEdit = useCallback<Callback<AdvertBase>>(
      advert => () => {
        editAdvert(advert)
        navigate('NewAdvert', undefined, { postcode: toString(advert.locationDetails.address.postcode) })
      },
      [editAdvert, navigate],
    )

    const onCancelOrEdit = useCallback<Callback<EditAndRepostActionDetails>>(
      ({ cancel }) =>
        () => {
          const params: NewAdvertPayload = { advertUrl, cancelUrl: cancel?.url }

          editAdvert(advert)
          navigate('NewAdvert', params, { ...params, postcode: toString(advert.locationDetails.address.postcode) })
        },
      [advert, advertUrl, editAdvert, navigate],
    )

    const onRelistOrCancel = useCallback<Callback<RelistOrCancelActionDetails>>(
      ({ cancelJob, relist }) =>
        () =>
          relistOrCancel({ advertUrl, ...cancelJob, relist }),
      [advertUrl, relistOrCancel],
    )

    const onDelete = useCallback<Callback>(deleteUrl => () => deleteAdvertIntent({ deleteUrl }), [deleteAdvertIntent])

    const onChat = useCallback(() => navigate('Chat', advertIdRefUrl), [advertIdRefUrl, navigate])

    const onViewCollectorsOffers = useCallback<Callback>(
      offersUrl => () => {
        const payload: CollectorsOffersPayload = { advertId, advertUrl, offersUrl }

        return navigate('CollectorsOffers', payload, { ...payload, advertId: toString(payload.advertId) })
      },
      [advertId, advertUrl, navigate],
    )

    const onViewJunkReusersOffers = useCallback<Callback>(
      offersUrl => () => {
        const junkLoverName =
          junkLoverDetails?.firstName || junkLoverOfferDetails?.junkLoverDetails?.firstName || undefined

        const payload: JunkReusersOffersPayload = { advertId, advertUrl, offersUrl, junkLoverName }

        return navigate('JunkReusersOffers', payload, {
          ...payload,
          advertId: toString(payload.advertId),
        })
      },
      [advertId, advertUrl, junkLoverDetails, junkLoverOfferDetails, navigate],
    )

    const onAddFavourite = useCallback<Callback<AddFavouriteCollectorIntentPayload>>(
      payload => () => setAddFavouriteCollectorIntent(payload),
      [setAddFavouriteCollectorIntent],
    )

    const onUserCollectForFree = useCallback(() => {
      checkJunkLoverApproval(advertIdRefUrl).then(error => error || userCollectForFree(advertIdRefUrl))
    }, [advertIdRefUrl, checkJunkLoverApproval, userCollectForFree])

    const renderAction = useCallback<Transform<[string, ReactElement], ReactElement>>(
      ([key, action]) => <Action key={key}>{action}</Action>,
      [],
    )

    const renderDivider = useCallback<Transform<number, ReactElement>>(key => <Divider key={key} border={false} />, [])

    const renderAwaitingOffers = useMemo(
      () => (awaitingOffersAction ? <Info>Listing live: awaiting offers</Info> : null),
      [awaitingOffersAction],
    )

    const renderAwaitingReply = useMemo(
      () => awaitingReplyAction && <Info>{MESSAGED_OWNER_AWAITING_REPLY_TEXT}</Info>,
      [awaitingReplyAction],
    )

    const renderSupplierInfo = useCallback<TransformOptional<SupplierDetails, ReactNode>>(
      supplierDetails =>
        isUnderway && supplierDetails && jobDetails ? (
          <SupplierInfo supplierDetails={supplierDetails} jobDetails={jobDetails} />
        ) : null,
      [isUnderway, jobDetails],
    )

    const renderCollectorInfo = useMemo(
      () => renderSupplierInfo(collectorDetails),
      [collectorDetails, renderSupplierInfo],
    )

    const renderJunkLoverInfo = useMemo(
      () => renderSupplierInfo(junkLoverDetails),
      [junkLoverDetails, renderSupplierInfo],
    )

    const renderBestCounterOffer = useMemo(() => {
      if (!isOwnerAdvertWithCounterOffer) return null

      const {
        counterOfferDetails: { bestCounterOffer },
      } = advert

      return <Info>{formatPrice(bestCounterOffer)} is your best collector offer</Info>
    }, [advert, isOwnerAdvertWithCounterOffer])

    const renderCollectionUnderway = useMemo(() => <Info>Collection underway</Info>, [])
    const renderCancelled = useMemo(() => <Info>Cancelled listing</Info>, [])
    const renderCollectionDone = useMemo(
      () => <Info>{collectionDoneCaption(isSupplier ? undefined : supplierType)}</Info>,
      [isSupplier, supplierType],
    )
    const renderExpired = useMemo(() => <Info>Expired listing</Info>, [])
    const renderSeekingCollector = useMemo(() => <Info>Live: seeking collector</Info>, [])
    const renderNonReusable = useMemo(() => <Info>Non-Reusable Listing</Info>, [])
    const renderUnderOffer = useMemo(() => <Info>Live: under offer</Info>, [])

    const renderUserCollectForFree = useMemo(
      () =>
        collectJunkForFreeAction ? (
          <Button
            text={showViewMessagesText ? MESSAGE_OWNER_TEXT : COLLECT_FOR_FREE_CAPTION}
            disabled={isRequesting}
            onPress={onUserCollectForFree}
          />
        ) : null,
      [collectJunkForFreeAction, isRequesting, onUserCollectForFree, showViewMessagesText],
    )

    const renderAcceptJob = useMemo(
      () =>
        acceptJobAction
          ? renderAcceptJobProp?.({ ...advertIdRefUrl, isRequesting, price, url: acceptJobAction.url }) || (
              <Button
                text={'Collect' + (price ? ` for ${formatPrice(price)}` : '')}
                disabled={isRequesting}
                onPress={onAcceptJob(acceptJobAction)}
              />
            )
          : null,
      [acceptJobAction, advertIdRefUrl, isRequesting, onAcceptJob, price, renderAcceptJobProp],
    )

    const onMakeJunkLoverOffer = useCallback<Callback>(
      url => () => {
        checkJunkLoverApproval(advertIdRefUrl).then(error => error || collectForFree({ ...advertIdRefUrl, url }))
      },
      [advertIdRefUrl, checkJunkLoverApproval, collectForFree],
    )

    const renderDefaultPublicFallback = useMemo(
      () => (isAdvertWithCounterOffer && isCollector ? renderUnderOffer : renderSeekingCollector),
      [isAdvertWithCounterOffer, isCollector, renderSeekingCollector, renderUnderOffer],
    )

    const renderDefaultPublic = useMemo(
      () =>
        hasNoActions ? (isJunkLover ? (isReusable ? null : renderNonReusable) : renderDefaultPublicFallback) : null,
      [hasNoActions, isJunkLover, isReusable, renderDefaultPublicFallback, renderNonReusable],
    )

    const renderPublic = useMemo(() => {
      if (view === 'public' || shouldRenderNonPublicViewAsPublic)
        switch (status) {
          case 'cancelled':
            return renderCancelled
          case 'collected':
            return renderCollectionDone
          case 'expired':
            return renderExpired
          case 'helpunderway':
            return renderCollectionUnderway
          default:
            return renderDefaultPublic
        }

      return null
    }, [
      renderCancelled,
      renderCollectionDone,
      renderCollectionUnderway,
      renderDefaultPublic,
      renderExpired,
      shouldRenderNonPublicViewAsPublic,
      status,
      view,
    ])

    const onPromptReuserSignup = useCallback(
      () => promptReuserSignupProp(advertIdRefUrl),
      [advertIdRefUrl, promptReuserSignupProp],
    )

    const renderPromptReuserSignup = useMemo(
      () => promptReuserSignupAction && <Button text={MESSAGE_OWNER_TEXT} onPress={onPromptReuserSignup} />,
      [onPromptReuserSignup, promptReuserSignupAction],
    )

    const renderAdjustPrice = useMemo(
      () =>
        adjustPriceAction && isDefined(priceValue) ? (
          <AdjustPrice advertUrl={advertUrl} amount={priceValue} url={adjustPriceAction.url} />
        ) : null,
      [adjustPriceAction, advertUrl, priceValue],
    )

    const renderAddFavourite = useMemo(
      () =>
        addFavouriteAction && collectorDetails ? (
          <Button
            text={ADD_FAVOURITE_COLLECTOR_TITLE}
            onPress={onAddFavourite({ advertUrl, collectorDetails, url: addFavouriteAction.url })}
            secondary
          />
        ) : null,
      [addFavouriteAction, advertUrl, collectorDetails, onAddFavourite],
    )

    const renderNoResponsesYet = useMemo(() => <Info>No responses yet - try increasing your offer</Info>, [])

    const renderIncreasePriceButton = useCallback<Render<RenderIncreasePriceButtonPayload>>(
      ({ increment, url }) =>
        isDefined(priceValue) ? (
          <Button
            onPress={onIncreasePrice({ increment, url })}
            progress={isIncreasing}
            progressText="Increasing price..."
            text={`Increase offer price to ${formatPrice(increasePriceBy(increment.amount)(priceValue))}`}
          />
        ) : null,
      [isIncreasing, onIncreasePrice, priceValue],
    )

    const renderIncreasePrice = useMemo(() => {
      if (!increasePriceAction || isUndefined(priceValue)) return null

      const {
        details: { increment },
        url,
      } = increasePriceAction
      const increasedPrice = increasePriceBy(increment.amount)(priceValue)

      if (isAdvertWithCounterOffer) {
        const {
          counterOfferDetails: { bestCounterOffer },
        } = advert

        return increasedPrice.amount < bestCounterOffer.amount ? renderIncreasePriceButton({ increment, url }) : null
      } else {
        return (
          <>
            {renderNoResponsesYet}
            <Divider border={false} />
            {renderIncreasePriceButton({ increment, url })}
          </>
        )
      }
    }, [
      advert,
      increasePriceAction,
      isAdvertWithCounterOffer,
      priceValue,
      renderIncreasePriceButton,
      renderNoResponsesYet,
    ])

    const chatContactRole = useMemo<string>(() => (isOwner ? 'collector' : 'customer'), [isOwner])

    const renderChat = useMemo(
      () =>
        viewMessagesAction ? (
          <Button
            text={
              viewMessagesAction.details.isOpen
                ? showViewMessagesText
                  ? VIEW_MESSAGES_TEXT
                  : `Contact ${chatContactRole}`
                : 'Chat history'
            }
            onPress={onChat}
            secondary
          />
        ) : null,
      [chatContactRole, onChat, viewMessagesAction, showViewMessagesText],
    )

    const shouldRenderContact = useCallback(
      (userDetails: Optional<UserDetails>): userDetails is UserDetailsWithPhoneNumber =>
        isDefined(userDetails?.phoneNumber) && isUnderway && !viewMessagesAction,
      [isUnderway, viewMessagesAction],
    )

    const renderContactCollector = useMemo(
      () => (shouldRenderContact(collectorDetails) ? renderContactCollectorProp(collectorDetails.phoneNumber) : null),
      [collectorDetails, renderContactCollectorProp, shouldRenderContact],
    )

    const renderContactJunkLover = useMemo(
      () => (shouldRenderContact(junkLoverDetails) ? renderContactCollectorProp(junkLoverDetails.phoneNumber) : null),
      [junkLoverDetails, renderContactCollectorProp, shouldRenderContact],
    )

    const renderInitiatePayment = useMemo(
      () =>
        initiatePaymentAction ? (
          <Button
            onPress={onInitiatePayment({ advert, advertUrl, url: initiatePaymentAction.url })}
            progress={isConfirming}
            progressText="Confirming..."
            text="Confirm & pay for collection"
          />
        ) : null,
      [advert, advertUrl, initiatePaymentAction, isConfirming, onInitiatePayment],
    )

    const renderEdit = useMemo(
      () => (repostAction ? <Button text="Edit & Repost" onPress={onEdit(advert)} /> : null),
      [advert, onEdit, repostAction],
    )

    const renderRelistOrCancel = useMemo(
      () =>
        relistOrCancelAction ? (
          <Button text="Relist or Cancel listing" onPress={onRelistOrCancel(relistOrCancelAction.details)} secondary />
        ) : null,
      [onRelistOrCancel, relistOrCancelAction],
    )

    const renderCancelOrEdit = useMemo(
      () =>
        editAndRepostAction ? (
          <Button text="Cancel or Edit listing" onPress={onCancelOrEdit(editAndRepostAction.details)} secondary />
        ) : null,
      [editAndRepostAction, onCancelOrEdit],
    )

    const renderDelete = useMemo(
      () => (deleteAction ? <Button text="Delete" onPress={onDelete(deleteAction.url)} secondary /> : null),
      [deleteAction, onDelete],
    )

    const renderReviewCounterOffers = useMemo(
      () =>
        fetchAllCounterOffersAction ? (
          <ButtonWithDot
            dot={fetchAllCounterOffersAction.details.hasUnreadMessages}
            text="Waste collector offers"
            onPress={onViewCollectorsOffers(fetchAllCounterOffersAction.url)}
          />
        ) : null,
      [fetchAllCounterOffersAction, onViewCollectorsOffers],
    )

    const renderJunkReusersOffers = useMemo(
      () =>
        fetchAllJunkLoverOffersAction ? (
          <ButtonWithDot
            dot={fetchAllJunkLoverOffersAction.details.hasUnreadMessages}
            text="Junk Reuser Messages"
            onPress={onViewJunkReusersOffers(fetchAllJunkLoverOffersAction.url)}
          />
        ) : null,
      [fetchAllJunkLoverOffersAction, onViewJunkReusersOffers],
    )

    const renderCustomerCollectionDone = useMemo(
      // Extra condition for `source`, because - when source is "app" - this is also rendered by "public" render group.
      () => (isCollected && isOwner && source === 'web' ? renderCollectionDone : null),
      [isCollected, isOwner, renderCollectionDone, source],
    )

    const onMakeCounterOffer = useCallback<Callback<MakeCounterOfferAction>>(
      ({ details: { allowConditionalCounterOffers, commissionPercentage }, url }) =>
        () => {
          setCounterOfferSubmitted(true)

          if (isDefined(counterOfferValidationResult) && isCounterOfferValid(counterOfferValidationResult)) {
            checkCollectorApproval(advertIdRefUrl).then(error => {
              if (error) return

              const { price: offerPrice } = counterOfferValidationResult
              const isConditionRequired = isDefined(price) && arePricesEqual(offerPrice, price)

              if (allowConditionalCounterOffers || isConditionRequired) {
                navigate('CollectorOffer', {
                  ...advertIdRefUrl,
                  commissionPercentage,
                  isConditionRequired,
                  offerPrice,
                  timeDetails,
                  url,
                })

                setCounterOfferSubmitted(false)
              } else {
                makeCounterOffer({ ...advertIdRefUrl, url, price: offerPrice }).then(action => {
                  if (action.type === MAKE_COUNTER_OFFER_SUCCESS) setCounterOfferSubmitted(false)
                })
              }
            })
          }
        },
      [
        advertIdRefUrl,
        checkCollectorApproval,
        counterOfferValidationResult,
        makeCounterOffer,
        navigate,
        price,
        setCounterOfferSubmitted,
        timeDetails,
      ],
    )

    const onWithdrawCounterOffer = useCallback<Callback>(
      url => () => withdrawCounterOffer({ advertUrl, url }),
      [advertUrl, withdrawCounterOffer],
    )

    const onCollectorCollectionCompleted = useCallback<Callback<CollectorCollectionPayload>>(
      params => () => navigate('DisposalSites', params),
      [navigate],
    )

    const onJunkLoverCollectionCompleted = useCallback<Callback<ConfirmReusableCollectionPayload>>(
      payload => () => confirmReusableCollectionIntent(payload),
      [confirmReusableCollectionIntent],
    )

    const renderMakeCounterOffer = useMemo(
      () =>
        makeCounterOfferAction ? (
          <CounterOfferRow>
            <CounterOfferInput
              value={counterOffer}
              label={formatValidRange(makeCounterOfferAction.details.validRange)}
              onChange={setCounterOffer}
              error={counterOfferError}
              prefix={defaultCurrencySymbol}
            />
            <CounterOfferButton
              text="Offer now"
              onPress={onMakeCounterOffer(makeCounterOfferAction)}
              disabled={counterOfferDisabled}
              progress={isMakeCounterOfferInProgress}
            />
          </CounterOfferRow>
        ) : null,
      [
        counterOffer,
        counterOfferDisabled,
        counterOfferError,
        isMakeCounterOfferInProgress,
        makeCounterOfferAction,
        onMakeCounterOffer,
        setCounterOffer,
      ],
    )

    const renderWithdrawCounterOffer = useMemo(
      () =>
        withdrawCounterOfferAction ? (
          <CounterOfferRow>
            {showViewMessagesText ? (
              <Info>{MESSAGED_OWNER_AWAITING_REPLY_TEXT}</Info>
            ) : (
              <>
                <CounterOfferText>
                  Counter offered {formatPrice(withdrawCounterOfferAction.details.price)}
                </CounterOfferText>
                <CounterOfferButton
                  text="Withdraw Offer"
                  onPress={onWithdrawCounterOffer(withdrawCounterOfferAction.url)}
                  secondary
                  progress={isWithdrawCounterOfferInProgress}
                />
              </>
            )}
          </CounterOfferRow>
        ) : null,
      [isWithdrawCounterOfferInProgress, onWithdrawCounterOffer, showViewMessagesText, withdrawCounterOfferAction],
    )

    const renderWaitForPayment = useMemo(
      () => (waitForPaymentAction ? <Info>Payment in progress - please wait</Info> : null),
      [waitForPaymentAction],
    )

    const renderCompleteCollectorCollection = useMemo(
      () =>
        isCollectorCollectionDoneState(advert) ? (
          <Button
            text="Confirm junk collected"
            onPress={onCollectorCollectionCompleted({
              id: advertId,
              url: advertUrl,
              disposalSitesUrl: advert.actions.collectorDisposalSites.url,
              disposeUrl: advert.actions.confirmAndDisposeWithImages?.url,
              reuseUrl: advert.actions.confirmAndReuseWithImages?.url,
            })}
          />
        ) : null,
      [advert, advertId, advertUrl, onCollectorCollectionCompleted],
    )

    const renderCompleteJunkLoverCollection = useMemo(
      () =>
        isJunkLoverCollectionDoneState(advert) ? (
          <Button
            text="Collection done?"
            onPress={onJunkLoverCollectionCompleted({
              advert,
              advertUrl,
              reuseUrl: advert.actions.junkLoverConfirmAndReuse.url,
            })}
          />
        ) : null,
      [advert, advertUrl, onJunkLoverCollectionCompleted],
    )

    const renderCustomerToConfirm = useMemo(
      () => (isCustomerToConfirmState(advert) ? <Info>Customer to confirm</Info> : null),
      [advert],
    )

    const renderCustomerReceipt = useMemo(
      () =>
        customerReceiptAction
          ? renderCustomerReceiptProp({ advertId, advertUrl, email, url: customerReceiptAction.url })
          : null,
      [advertId, advertUrl, customerReceiptAction, email, renderCustomerReceiptProp],
    )

    const renderCustomerWtn = useMemo(
      () =>
        customerWtnAction ? renderCustomerWtnProp({ advertId, advertUrl, email, url: customerWtnAction.url }) : null,
      [advertId, advertUrl, customerWtnAction, email, renderCustomerWtnProp],
    )

    const renderCollectorReceipt = useMemo(
      () =>
        collectorReceiptAction
          ? renderCollectorReceiptProp({
              advertId,
              advertUrl,
              email,
              url: collectorReceiptAction.url,
            })
          : null,
      [advertId, advertUrl, collectorReceiptAction, email, renderCollectorReceiptProp],
    )

    const renderCollectorWtn = useMemo(
      () =>
        collectorWtnAction ? renderCollectorWtnProp({ advertId, advertUrl, email, url: collectorWtnAction.url }) : null,
      [advertId, advertUrl, collectorWtnAction, email, renderCollectorWtnProp],
    )

    const renderContactCustomer = useCallback<Render<string>>(
      phoneNumber =>
        contactCustomer ? (
          <Button
            text={showViewMessagesText ? VIEW_MESSAGES_TEXT : CONTACT_CUSTOMER_TEXT}
            onPress={contactCustomer(phoneNumber)}
            secondary
          />
        ) : null,
      [contactCustomer, showViewMessagesText],
    )

    const renderCollectorContactCustomer = useMemo(
      () =>
        shouldRenderContact(customerDetails) && supplierType === 'collector'
          ? renderContactCustomer(customerDetails.phoneNumber)
          : null,
      [customerDetails, renderContactCustomer, shouldRenderContact, supplierType],
    )

    const renderJunkLoverContactCustomer = useMemo(
      () =>
        shouldRenderContact(customerDetails) && supplierType === 'junklover' && jobStatus === 'awaitingcollection'
          ? renderContactCustomer(customerDetails.phoneNumber)
          : null,
      [customerDetails, jobStatus, renderContactCustomer, shouldRenderContact, supplierType],
    )

    const renderAmendDisposalSites = useMemo(
      () =>
        collectorDisposalSitesAction && jobDisposalAction ? (
          <Button
            text="Amend disposal details"
            onPress={onCollectorCollectionCompleted({
              id: advertId,
              url: advertUrl,
              disposalSitesUrl: collectorDisposalSitesAction.url,
              disposalSiteId: jobDisposalAction.details.disposalSiteId,
              jobDisposalUrl: jobDisposalAction.url,
            })}
            secondary
          />
        ) : null,
      [advertId, advertUrl, collectorDisposalSitesAction, jobDisposalAction, onCollectorCollectionCompleted],
    )

    const renderMakeJunkLoverOffer = useMemo(
      () =>
        makeJunkLoverOfferAction ? (
          <Button
            text={showViewMessagesText ? MESSAGE_OWNER_TEXT : COLLECT_FOR_FREE_CAPTION}
            onPress={onMakeJunkLoverOffer(makeJunkLoverOfferAction.url)}
          />
        ) : null,
      [makeJunkLoverOfferAction, onMakeJunkLoverOffer, showViewMessagesText],
    )

    const renderJunkLoverCollectionDone = useMemo(
      () =>
        view === 'associated' &&
        (jobStatus === 'collectorconfirmed' || jobStatus === 'complete') &&
        supplierType === 'junklover'
          ? renderCollectionDone
          : null,
      [jobStatus, renderCollectionDone, supplierType, view],
    )

    const isActiveWithoutActionsForJunkLover = useMemo(
      () => isJunkLover && isActive && hasNoActions,
      [hasNoActions, isActive, isJunkLover],
    )

    const shouldRenderReuseOfferDisabled = useMemo(
      () => isActiveWithoutActionsForJunkLover && isReusable && !junkLoverOfferDetails?.allowReuseRequests,
      [isActiveWithoutActionsForJunkLover, isReusable, junkLoverOfferDetails],
    )

    const renderReuseOfferDisabled = useMemo(
      () => (shouldRenderReuseOfferDisabled ? <Info>Reuse Offers Disabled</Info> : null),
      [shouldRenderReuseOfferDisabled],
    )

    const shouldRenderReuseOfferInProgress = useMemo(
      () =>
        !shouldRenderReuseOfferDisabled &&
        isActiveWithoutActionsForJunkLover &&
        junkLoverOfferDetails?.hasJunkLoverOffer &&
        view === 'public',
      [isActiveWithoutActionsForJunkLover, junkLoverOfferDetails, shouldRenderReuseOfferDisabled, view],
    )

    const renderReuseOfferInProgress = useMemo(
      () => (shouldRenderReuseOfferInProgress ? <Info>Reuse Offer in progress</Info> : null),
      [shouldRenderReuseOfferInProgress],
    )

    return (
      <Root>
        {flow(pickBy(hasValue), entries, renderList({ renderDivider, renderListItem: renderAction }), items =>
          isEmpty(items) ? items : [...items, <Divider key="end-divider" />],
        )({
          /* role groups may repeat to enforce buttons order */

          /* customer */
          collectionDone: renderCustomerCollectionDone,
          awaitingOffers: renderAwaitingOffers,
          awaitingReply: renderAwaitingReply,
          collectorInfo: renderCollectorInfo,
          junkLoverInfo: renderJunkLoverInfo,
          bestCounterOffer: renderBestCounterOffer,
          junkReusersOffers: renderJunkReusersOffers,
          edit: renderEdit,
          delete: renderDelete,
          reviewCounterOffers: renderReviewCounterOffers,
          increasePrice: renderIncreasePrice,
          initiatePayment: renderInitiatePayment,
          waitForPayment: renderWaitForPayment,

          /* customer or supplier */
          completeJunkLoverCollection: renderCompleteJunkLoverCollection,

          /* customer */
          customerReceipt: renderCustomerReceipt,
          customerWtn: renderCustomerWtn,
          contactCollector: renderContactCollector,
          contactJunkLover: renderContactJunkLover,
          cancelOrEdit: renderCancelOrEdit,
          adjustPrice: renderAdjustPrice,

          /* collector */
          customerToConfirm: renderCustomerToConfirm,
          completeCollectorCollection: renderCompleteCollectorCollection,
          acceptJob: renderAcceptJob,
          makeCounterOffer: renderMakeCounterOffer,
          withdrawCounterOffer: renderWithdrawCounterOffer,
          collectorReceipt: renderCollectorReceipt,
          collectorWtn: renderCollectorWtn,
          collectorContactCustomer: renderCollectorContactCustomer,
          amendDisposalSites: renderAmendDisposalSites,

          /* junklover */
          junkLoverContactCustomer: renderJunkLoverContactCustomer,
          makeJunkLoverOffer: renderMakeJunkLoverOffer,
          junkLoverCollectionDone: renderJunkLoverCollectionDone,

          /* anyone */
          public: renderPublic,
          userCollectForFree: renderUserCollectForFree,
          reuseOfferInProgress: renderReuseOfferInProgress,
          reuseOfferDisabled: renderReuseOfferDisabled,
          chat: renderChat,
          promptReuserSignup: renderPromptReuserSignup,

          /* customer */
          relistOrCancel: renderRelistOrCancel,
          addFavourite: renderAddFavourite,
        })}
      </Root>
    )
  }
}

export default factory
