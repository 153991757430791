import React, { useCallback, useState } from 'react'

import { REVIEW_PLACEHOLDER, REVIEW_SUBMIT_CAPTION } from '../../constants'
import { Rating, SubmitPayload } from '../../entities/advert/types'
import { isDefined } from '../../helpers'
import { Dispatch, NoOp, Supplier } from '../../types/core'
import { ComponentFactory, ToElements } from '../../types/ui'
import { Props as RatingComponentProps } from './Rating'

export interface CommentBoxProps {
  placeholder: string
  maxLength: number
  onChange: Dispatch<string>
  value: string
}

export interface SubmitButtonProps {
  disabled: boolean
  onPress: NoOp
  progress: boolean
  text: string
}

interface ElementsProps {
  CommentBox: CommentBoxProps
  RatingComponent: RatingComponentProps
  Root: unknown
  SubmitButton: SubmitButtonProps
  SubTitle: unknown
  Title: unknown
}

type Elements = ToElements<ElementsProps>

export interface OwnProps {
  onSubmit: Dispatch<SubmitPayload>

  assignedSupplier?: Supplier
}

interface StateProps {
  isReviewing: boolean
}

type Props = OwnProps & StateProps

const MAX_LENGTH = 300

const formatAssignedSupplier: Record<Supplier, string> = {
  collector: 'collector',
  junklover: 'Junk Reuser',
}

const factory: ComponentFactory<Elements, Props> = ({
  CommentBox,
  RatingComponent,
  Root,
  SubmitButton,
  SubTitle,
  Title,
}) =>
  function ReviewCollection({ assignedSupplier = 'collector', isReviewing, onSubmit: onSubmitProp }) {
    const [isDirty, setIsDirty] = useState<boolean>(false)
    const [rating, setRating] = useState<Rating['value']>()
    const [comments, setComments] = useState<string>('')
    const hasRating = isDefined(rating)
    const ratingHasError = isDirty && !hasRating

    const onSubmit = useCallback(() => {
      setIsDirty(true)

      if (!hasRating) return

      onSubmitProp({ comments, rating })

      setComments('')
      setIsDirty(false)
      setRating(undefined)
    }, [comments, hasRating, onSubmitProp, rating])

    return (
      <Root>
        <SubTitle>Review</SubTitle>
        <Title>Please rate your {formatAssignedSupplier[assignedSupplier]}</Title>
        <RatingComponent error={ratingHasError} onChange={setRating} rating={rating} />
        <CommentBox maxLength={MAX_LENGTH} onChange={setComments} placeholder={REVIEW_PLACEHOLDER} value={comments} />
        <SubmitButton disabled={!hasRating} onPress={onSubmit} progress={isReviewing} text={REVIEW_SUBMIT_CAPTION} />
      </Root>
    )
  }

export default factory
