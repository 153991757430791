import { flow } from 'lodash/fp'

import { action, failureAction, payloadAction } from '../../action-factory'
import apiAction, { ActionBundle } from '../../api-action'
import { actionStep } from '../../helpers'
import {
  ActionFactory,
  DispatchAsync,
  FailureActionFactory,
  Get,
  NoOp,
  PayloadActionFactory,
  SessionToken,
} from '../../types/core'
import { StatelessPayloadThunk } from '../../types/thunk'
import { withEndpoint } from '../app/helpers'
import { fetchMetaStep } from '../meta/actions'
import { verifyAuthIdentityOtpApi, verifyOtpApi } from './api'
import {
  AUTH_IDENTIFIER_NOT_VERIFIED,
  AUTH_IDENTIFIER_VERIFIED,
  AuthIdentifierNotVerified,
  AuthIdentifierNotVerifiedPayload,
  AuthIdentifierVerified,
  AuthIdentifierVerifiedPayload,
  DISMISS_AUTH_IDENTIFIER_VERIFICATION,
  DISMISS_OTP_RESENT_MODAL,
  DISMISS_OTP_VERIFY_FAILED_MODAL,
  DismissAuthIdentifierVerification,
  DismissOtpResentModal,
  DismissOtpVerifyFailedModal,
  RESEND_OTP,
  ResendOtp,
  VERIFY_AUTH_IDENTITY_OTP_FAILURE,
  VERIFY_AUTH_IDENTITY_OTP_START,
  VERIFY_AUTH_IDENTITY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE,
  VERIFY_OTP_START,
  VERIFY_OTP_SUCCESS,
  VerifyAuthIdentityOtpFailure,
  VerifyAuthIdentityOtpPayload,
  VerifyAuthIdentityOtpResult,
  VerifyAuthIdentityOtpStart,
  VerifyAuthIdentityOtpSuccess,
  VerifyOtpFailure,
  VerifyOtpPayload,
  VerifyOtpResult,
  VerifyOtpStart,
  VerifyOtpSuccess,
} from './types'

export const authIdentifierVerified: PayloadActionFactory<AuthIdentifierVerified, AuthIdentifierVerified['payload']> =
  payloadAction(AUTH_IDENTIFIER_VERIFIED)
export const authIdentifierNotVerified: PayloadActionFactory<
  AuthIdentifierNotVerified,
  AuthIdentifierNotVerified['payload']
> = failureAction(AUTH_IDENTIFIER_NOT_VERIFIED)
export const dismissAuthIdentifierVerification: ActionFactory<DismissAuthIdentifierVerification> = action(
  DISMISS_AUTH_IDENTIFIER_VERIFICATION,
)

export const verifyOtpSuccess: PayloadActionFactory<VerifyOtpSuccess, VerifyOtpSuccess['payload']> =
  payloadAction(VERIFY_OTP_SUCCESS)
export const verifyOtpFailure: FailureActionFactory<VerifyOtpFailure> = failureAction(VERIFY_OTP_FAILURE)
export const verifyOtpActionBundle: ActionBundle<VerifyOtpStart, VerifyOtpSuccess, VerifyOtpFailure, SessionToken> = [
  action(VERIFY_OTP_START),
  verifyOtpSuccess,
  verifyOtpFailure,
]

export const verifyAuthIdentityOtpActionBundle: ActionBundle<
  VerifyAuthIdentityOtpStart,
  VerifyAuthIdentityOtpSuccess,
  VerifyAuthIdentityOtpFailure
> = [
  action(VERIFY_AUTH_IDENTITY_OTP_START),
  action(VERIFY_AUTH_IDENTITY_OTP_SUCCESS),
  failureAction(VERIFY_AUTH_IDENTITY_OTP_FAILURE),
]

export const resendOtp: PayloadActionFactory<ResendOtp, ResendOtp['payload']> = payloadAction(RESEND_OTP)

export const dismissOtpResentModal: ActionFactory<DismissOtpResentModal> = action(DISMISS_OTP_RESENT_MODAL)
export const dismissOtpVerifyFailedModal: ActionFactory<DismissOtpVerifyFailedModal> = action(
  DISMISS_OTP_VERIFY_FAILED_MODAL,
)

export const onAuthIdentityVerificationSuccess: StatelessPayloadThunk<
  AuthIdentifierVerifiedPayload,
  AuthIdentifierVerified
> = payload => dispatch => Promise.resolve(dispatch(authIdentifierVerified(payload)))

export const onAuthIdentityVerificationFailure: StatelessPayloadThunk<
  AuthIdentifierNotVerifiedPayload,
  AuthIdentifierNotVerified
> = payload => dispatch => Promise.resolve(dispatch(authIdentifierNotVerified(payload)))

interface CreateVerifyPayload<S> {
  getDeviceId: DispatchAsync<S, string>
  getState: Get<S>
}

export const createVerify =
  <S>({ getDeviceId, getState }: CreateVerifyPayload<S>): StatelessPayloadThunk<VerifyOtpPayload, VerifyOtpResult> =>
  payload =>
    withEndpoint(
      'otp',
      ({ login: loginUrl }) =>
        dispatch =>
          getDeviceId(getState())
            .then(device => ({ ...payload, device }))
            .then(flow(verifyOtpApi(loginUrl), apiAction(verifyOtpActionBundle), action => dispatch(action))),
      verifyOtpFailure,
    )

interface CreateVerifyAuthIdentityPayload<S> extends CreateVerifyPayload<S> {
  completeSignup: NoOp
}

export const createVerifyAuthIdentity =
  <S>({
    completeSignup,
    getDeviceId,
    getState,
  }: CreateVerifyAuthIdentityPayload<S>): StatelessPayloadThunk<
    VerifyAuthIdentityOtpPayload,
    VerifyAuthIdentityOtpResult
  > =>
  ({ url, ...payload }) =>
  dispatch =>
    getDeviceId(getState())
      .then(device => ({ ...payload, device }))
      .then(
        flow(verifyAuthIdentityOtpApi(url), apiAction(verifyAuthIdentityOtpActionBundle), action => dispatch(action)),
      )
      .then(fetchMetaStep(VERIFY_AUTH_IDENTITY_OTP_SUCCESS))
      .then(action => dispatch(action))
      // This step is needed when logged in email-only customer is trying to register as a supplier.
      // After verifying the phone, we need to re-run (previously interrupted by truthy `hasUnverifiedPhoneNumber`)
      // `onLoggedIn` to resume registration process.
      .then(actionStep(() => completeSignup())(VERIFY_AUTH_IDENTITY_OTP_SUCCESS))
      .then(action => dispatch(action))
