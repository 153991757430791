import { Credentials, Get, Nullable } from '../../types/core'

interface State {
  credentials: Nullable<Credentials>
  isCheckingToken: boolean
  isGettingToken: boolean
}

export const oauthDefaultState: Get<State> = () => ({
  credentials: null,
  isCheckingToken: false,
  isGettingToken: false,
})

export default State
