import { flow } from 'lodash/fp'

import { action, payloadAction } from '../../action-factory'
import apiAction, { ActionBundle } from '../../api-action'
import { ActionFactory, FailureActionFactory, PayloadActionFactory } from '../../types/core'
import { StatelessPayloadThunk } from '../../types/thunk'
import { withEndpoint } from '../app/helpers'
import { fetchMetaStep } from '../meta/actions'
import { signupJunkLoverApi } from './api'
import { MarshalSignupJunkLover, SignupJunkLoverDetails, SignupJunkLoverResponse } from './types'
import {
  CANCEL_JUNK_LOVER_SIGNUP,
  CancelJunkLoverSignup,
  CREATE_JUNK_LOVER_ACCOUNT,
  CreateJunkLoverAccount,
  SAVE_JUNK_LOVER_DETAILS,
  SAVE_JUNK_LOVER_SELFIE,
  SaveJunkLoverDetails,
  SaveJunkLoverSelfie,
  SET_JUNK_LOVER_NOT_APPROVED,
  SET_JUNK_LOVER_SIGNUP_FEEDBACK,
  SetJunkLoverNotApproved,
  SetJunkLoverSignupFeedback,
  SIGNUP_JUNK_LOVER_FAILURE,
  SIGNUP_JUNK_LOVER_START,
  SIGNUP_JUNK_LOVER_SUCCESS,
  SignupJunkLoverFailure,
  SignupJunkLoverResult,
  SignupJunkLoverStart,
  SignupJunkLoverSuccess,
} from './types/actions'

export const setJunkLoverNotApproved: PayloadActionFactory<SetJunkLoverNotApproved, boolean> =
  payloadAction(SET_JUNK_LOVER_NOT_APPROVED)

export const createJunkLoverAccount: ActionFactory<CreateJunkLoverAccount> = action(CREATE_JUNK_LOVER_ACCOUNT)

export const cancelJunkLoverSignup: ActionFactory<CancelJunkLoverSignup> = action(CANCEL_JUNK_LOVER_SIGNUP)

export const saveJunkLoverDetails: PayloadActionFactory<SaveJunkLoverDetails, SaveJunkLoverDetails['payload']> =
  payloadAction(SAVE_JUNK_LOVER_DETAILS)
export const saveJunkLoverSelfie: PayloadActionFactory<SaveJunkLoverSelfie, SaveJunkLoverSelfie['payload']> =
  payloadAction(SAVE_JUNK_LOVER_SELFIE)

export const setJunkLoverSignupFeedback: PayloadActionFactory<
  SetJunkLoverSignupFeedback,
  SetJunkLoverSignupFeedback['payload']
> = payloadAction(SET_JUNK_LOVER_SIGNUP_FEEDBACK)

export const signupJunkLoverFailure: FailureActionFactory<SignupJunkLoverFailure> =
  payloadAction(SIGNUP_JUNK_LOVER_FAILURE)
const signupJunkLoverActionBundle: ActionBundle<
  SignupJunkLoverStart,
  SignupJunkLoverSuccess,
  SignupJunkLoverFailure,
  SignupJunkLoverResponse
> = [action(SIGNUP_JUNK_LOVER_START), payloadAction(SIGNUP_JUNK_LOVER_SUCCESS), signupJunkLoverFailure]

interface SignupJunkLoverPayload {
  details: SignupJunkLoverDetails
  marshal: MarshalSignupJunkLover
  selfie: string
}

export const signupJunkLover: StatelessPayloadThunk<SignupJunkLoverPayload, SignupJunkLoverResult> = ({
  details,
  marshal,
  selfie,
}) =>
  withEndpoint(
    'junkLovers',
    url => dispatch =>
      marshal({ selfie, details })
        .then(flow(signupJunkLoverApi(url), apiAction(signupJunkLoverActionBundle)))
        .then(action => dispatch(action))
        .then(fetchMetaStep(SIGNUP_JUNK_LOVER_SUCCESS))
        .then(action => dispatch(action)),
    signupJunkLoverFailure,
  )
