import { styled } from 'styled'
import { device } from 'utils/css'

import { Props } from './Text'

export default styled.h1<Props>`
  color: ${({ color = 'contrast1', theme: { colors } }) => colors[color]};
  font-size: ${({ size = 2 }) => size}em;
  margin: 0 0 0.875em;
  ${({ centered = false }) => (centered ? 'text-align: center;' : undefined)}

  @media ${device.mobile} {
    box-shadow: none;
    border-radius: 0;
    color: ${({ color = 'selected', theme: { colors } }) => colors[color]};
    font-size: ${({ size = 2 }) => 0.75 * size}em;
    margin-bottom: 0.5em;
    padding: 0 1rem;
  }
`
