import { flow } from 'lodash/fp'

import { get } from '../../api'
import { CompoundApi } from '../../api-action'
import { responseToBody } from '../../helpers'
import { Transform } from '../../types/core'
import { Favourite, FavouritesResponse } from './types'

export const favouritesApi: Transform<string, CompoundApi<FavouritesResponse, Favourite[]>> = url => [
  get(url),
  flow(responseToBody, ({ entities }) => entities),
]
