import { Color, isDefined, Transform } from '@lovejunk/core'
import React, { FC } from 'react'
import { IconType } from 'react-icons'
import { ThemeProps } from 'styled'
import { withTheme } from 'styled-components'

export interface Props {
  Icon: IconType

  color?: Color
  disabled?: boolean
  disabledColor?: Color
  size?: number
}

const toLength: Transform<number, string> = size => size + 'em'

const ReactIconComponent: FC<Props & ThemeProps> = ({
  color: colorProp,
  disabled,
  disabledColor,
  Icon,
  size = 1,
  theme: { colors },
  ...props
}) => {
  const color = disabled && isDefined(disabledColor) ? disabledColor : colorProp

  return <Icon {...props} color={color && colors[color]} size={toLength(size)} />
}

export default withTheme(ReactIconComponent)
