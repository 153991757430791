import { values } from 'lodash/fp'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { MINIMUM_RATING_TO_ADD_COLLECTOR_TO_FAVOURITES } from '../../constants'
import { getBestCounterOffer, getPrice, isPriceVisible, userName } from '../../entities/advert/helpers'
import {
  formatAdvertAddress,
  formatAdvertShortPostcode,
  formatMapAddress,
  formatReusableAdvertAddress,
  isFullAddressVisible,
} from '../../entities/advert/helpers/role'
import {
  AdvertActionPayload,
  AdvertBase,
  AdvertDetailsPayload,
  Rating,
  SubmitPayload,
} from '../../entities/advert/types'
import { PricesProps } from '../../entities/advert/types/prices'
import { ReviewCollectionPayload } from '../../entities/customer-advert/types'
import {
  ADD_FAVOURITE_COLLECTOR_SUCCESS,
  AddFavouriteCollectorResult,
  COLLECTION_REVIEW_SUCCESS,
  CollectionReviewResult,
} from '../../entities/customer-advert/types/actions'
import { hasDataSupplierCapability } from '../../entities/meta/lens'
import { Meta } from '../../entities/meta/types'
import { areAllDefined, nullifyUndefined } from '../../helpers'
import LocationMarshaller from '../../marshallers/location'
import { Dispatch, NoOp, Nullable, Optional, RemoteUrlAction, Supplier, Transform } from '../../types/core'
import { MapProps, OnMapPressPayload } from '../../types/geocoder'
import { ComponentFactory, ToElements } from '../../types/ui'
import { OwnProps as AddFavouriteCollectorProps } from './AddFavouriteCollector'
import { OwnProps as AdditionalDetailsProps } from './AdditionalDetails'
import { OwnProps as DetailsProps } from './Details'
import { OwnProps as DetailsItemsProps } from './DetailsItems'
import { OwnProps as ReusableRowProps } from './ReusableRow'
import { OwnProps as ReviewCollectionProps } from './ReviewCollection'
import { OwnProps as SecondaryRowProps } from './SecondaryRow'

interface AddCollectorState {
  addFavouriteAction: RemoteUrlAction
  collectorName: string
  rating: Rating['value']
}

type PartialAddCollectorState = Partial<AddCollectorState>

const isAddCollectorState = (state: PartialAddCollectorState): state is AddCollectorState =>
  areAllDefined(...values(state))

export interface MapData {
  address: string
}

interface AddressProps {
  mapData?: MapData
}

interface ElementsProps {
  Actions: AdvertDetailsPayload
  AddFavouriteCollector: AddFavouriteCollectorProps
  AdditionalDetails: AdditionalDetailsProps
  Address: AddressProps
  AdvertRef: unknown
  City: unknown
  CityRow: unknown
  Container: unknown
  CheckPricesLink: unknown
  Details: DetailsProps
  DetailsItems: DetailsItemsProps
  PriceRow: PricesProps
  ReusableRow: ReusableRowProps
  ReviewCollection: ReviewCollectionProps
  SecondaryRow: SecondaryRowProps
  Title: unknown
  Ulez: unknown
  Divider: unknown
  Map: MapProps
}

type Elements = ToElements<ElementsProps>

interface DispatchProps {
  addFavouriteCollector: Dispatch<AdvertActionPayload, Promise<AddFavouriteCollectorResult>>
  postReviewCollection: Dispatch<Rating['value']>
  reviewCollection: Dispatch<ReviewCollectionPayload, Promise<CollectionReviewResult>>
}

export interface OwnProps {
  advert: AdvertBase
  advertUrl: string
  onReusablePress: NoOp

  header?: Element
  onMapPress?: Dispatch<OnMapPressPayload>
  showReusableView?: boolean
  skipOwnerCheckForVat?: boolean
  viewsOwnerOnly?: boolean
}

export interface StateProps {
  meta: Nullable<Meta>
}

type Props = DispatchProps & OwnProps & StateProps

const factory: ComponentFactory<Elements, Props> = ({
  Actions,
  AddFavouriteCollector,
  AdditionalDetails,
  Address,
  AdvertRef,
  City,
  CityRow,
  Container,
  CheckPricesLink,
  Details,
  DetailsItems,
  Divider,
  Map,
  PriceRow,
  ReusableRow,
  ReviewCollection,
  SecondaryRow,
  Title,
  Ulez,
}) =>
  function Main({
    addFavouriteCollector,
    advert,
    advertUrl,
    children,
    header,
    meta,
    onMapPress,
    onReusablePress,
    postReviewCollection,
    reviewCollection,
    showReusableView,
    skipOwnerCheckForVat = false,
    viewsOwnerOnly = false,
  }) {
    const {
      actions: { addFavourite: addFavouriteAction, review: reviewAction },
      assignedCollectorDetails,
      assignedJunkLoverDetails,
      customerDetails,
      customerIsVatRegistered,
      junkDetails: { builderWaste, easyAccess, easyAccessReason, isLightweight, isReusable, residential, size },
      locationDetails: { accuracyInDegrees, accuracyInMetres, address, latitude, longitude, isUlez },
      publicDetails: { summary, timeDetails, title },
      metaDetails: { matchedTimeInSeconds, numberOfViews, offeredAt, reference },
      view,
      status,
    } = advert

    const assignedSupplier = useMemo<Optional<Supplier>>(
      () => (assignedCollectorDetails && 'collector') || (assignedJunkLoverDetails && 'junklover'),
      [assignedCollectorDetails, assignedJunkLoverDetails],
    )
    const price = getPrice(advert)
    const isOwner = view === 'owner'
    const isSupplier = hasDataSupplierCapability(meta)
    const latLng = LocationMarshaller.unmarshal({ latitude, longitude })
    const collectorName = assignedCollectorDetails?.firstName
    const views = viewsOwnerOnly && !isOwner ? undefined : numberOfViews
    const exact = useMemo(() => isFullAddressVisible(advert), [advert])
    const formatAddress = useMemo(() => (exact ? formatAdvertAddress : formatAdvertShortPostcode), [exact])
    const formattedAddress = useMemo(() => formatAddress(address), [address, formatAddress])
    const formattedMapAddress = useMemo(() => formatMapAddress(address), [address])

    const renderReusableShortAddressRow = useMemo(
      () => (showReusableView ? <ReusableRow address={formatReusableAdvertAddress(address)} /> : null),
      [address, showReusableView],
    )

    const [addCollectorRating, setAddCollectorRating] = useState<Rating['value']>()
    const addCollectorState = useMemo<PartialAddCollectorState>(
      () => ({
        addFavouriteAction,
        collectorName,
        rating: addCollectorRating,
      }),
      [addFavouriteAction, collectorName, addCollectorRating],
    )
    const isAddToFavouriteCollectorsVisible = isAddCollectorState(addCollectorState)
    const createOnAddFavouriteCollector = useCallback<Transform<AddCollectorState, NoOp>>(
      ({ addFavouriteAction: { url }, rating }) =>
        () =>
          addFavouriteCollector({ advertUrl, url }).then(action => {
            if (action.type === ADD_FAVOURITE_COLLECTOR_SUCCESS) {
              postReviewCollection(rating)
              setAddCollectorRating(undefined)
            }
          }),
      [addFavouriteCollector, advertUrl, postReviewCollection],
    )

    const createOnAddFavouriteCollectorDismiss = useCallback<Transform<AddCollectorState, NoOp>>(
      ({ rating }) =>
        () => {
          postReviewCollection(rating)
          setAddCollectorRating(undefined)
        },
      [postReviewCollection],
    )

    const customerName = useMemo(() => customerDetails && userName(customerDetails), [customerDetails])
    const mapData = useMemo<Optional<MapData>>(
      () => (exact ? { address: formattedMapAddress } : undefined),
      [exact, formattedMapAddress],
    )

    const onMapPressPayload = useMemo<OnMapPressPayload>(
      () => ({
        exact,
        formattedAddress: formattedMapAddress,
        latLng,
        name: customerName,
        radius: accuracyInMetres,
      }),
      [customerName, exact, formattedMapAddress, latLng, accuracyInMetres],
    )

    const createOnMapPress = useCallback<Transform<Dispatch<OnMapPressPayload>, NoOp>>(
      onMapPress => () => onMapPress(onMapPressPayload),
      [onMapPressPayload],
    )

    const onSubmitReview = useCallback<Dispatch<SubmitPayload>>(
      ({ comments, rating }) => {
        if (!reviewAction) return

        reviewCollection({ advertUrl, comments, rating, url: reviewAction.url }).then(action => {
          if (action.type === COLLECTION_REVIEW_SUCCESS) {
            if (rating >= MINIMUM_RATING_TO_ADD_COLLECTOR_TO_FAVOURITES) {
              setAddCollectorRating(rating)
            } else {
              postReviewCollection(rating)
              setAddCollectorRating(undefined)
            }
          }
        })
      },
      [advertUrl, postReviewCollection, reviewAction, reviewCollection],
    )

    useEffect(() => {
      setAddCollectorRating(undefined)
    }, [])

    return (
      <>
        {nullifyUndefined(reviewAction || isAddToFavouriteCollectorsVisible ? undefined : header)}
        <Container>
          {!showReusableView && isPriceVisible({ advert, meta }) && (
            <PriceRow
              bestCounterOffer={getBestCounterOffer(advert)}
              customerIsVatRegistered={customerIsVatRegistered}
              isOwner={isOwner}
              meta={meta}
              price={price}
              status={status}
              skipOwnerCheckForVat={skipOwnerCheckForVat}
            />
          )}
          <Title>{title}</Title>
          {!reviewAction && !isAddToFavouriteCollectorsVisible && !showReusableView && (
            <SecondaryRow
              matchedTimeInSeconds={isSupplier ? undefined : matchedTimeInSeconds}
              views={views}
              {...timeDetails}
            />
          )}
          {renderReusableShortAddressRow}
          <Divider />
          {reviewAction ? (
            <ReviewCollection assignedSupplier={assignedSupplier} onSubmit={onSubmitReview} />
          ) : isAddToFavouriteCollectorsVisible ? (
            <AddFavouriteCollector
              collectorName={addCollectorState.collectorName}
              onAdd={createOnAddFavouriteCollector(addCollectorState)}
              onDismiss={createOnAddFavouriteCollectorDismiss(addCollectorState)}
            />
          ) : (
            <>
              <Actions advert={advert} advertUrl={advertUrl} />
              {!showReusableView && (
                <>
                  <Details listedAt={offeredAt} />
                  <DetailsItems
                    builderWaste={builderWaste}
                    easyAccess={easyAccess}
                    lightweight={isLightweight}
                    residential={residential}
                    reusable={isReusable}
                    onReusablePress={onReusablePress}
                    size={size}
                  />
                  <Divider />
                </>
              )}
              <AdditionalDetails easyAccess={easyAccess} easyAccessReason={easyAccessReason} summary={summary} />
              <Divider />
              {showReusableView && (
                <>
                  <CheckPricesLink />
                  <Divider />
                </>
              )}
              <Map
                accuracy={accuracyInDegrees}
                exact={exact}
                latLng={latLng}
                onPress={onMapPress && createOnMapPress(onMapPress)}
                radius={accuracyInMetres}
              />
              <CityRow>
                <City>{address.city}</City>
                {isUlez ? <Ulez>(congestion zone)</Ulez> : null}
              </CityRow>
              <Address mapData={mapData}>{formattedAddress}</Address>
              <Divider />
              <AdvertRef>{reference}</AdvertRef>
            </>
          )}
          {children}
        </Container>
      </>
    )
  }

export default factory
