import { parseDate } from '../../../helpers'
import { ActivityItem, ActivityItemResource } from '../../activities/types'

const actions: ActivityItem['actions'] = {
  reloadHasUnreadMessages: {
    name: 'reloadHasUnreadMessages',
    url: 'reloadHasUnreadMessages',
    urlPath: 'reloadHasUnreadMessages',
  },
  viewAdvert: {
    name: 'viewAdvert',
    url: 'viewAdvert',
    urlPath: 'viewAdvert',
  },
  viewMessages: {
    name: 'viewMessages',
    url: 'viewMessages',
    urlPath: 'viewMessages',
  },
}

export const activityItemResourcesFixture: ActivityItemResource[] = [
  {
    actions,
    advertId: 1,
    advertReference: 'advert-1',
    advertTitle: 'Cardboard Box',
    advertStatus: 'collected',
    collectedAt: '2019-10-01T09:01:38Z',
    endAt: '2019-10-02T07:00:38Z',
    hasUnreadMessages: false,
    imageUrl: 'https://picsum.photos/500/700',
    jobStatus: 'complete',
    offeredAt: '2019-09-01T09:01:38Z',
    postcode: 'SW151AX',
    price: {
      amount: 10,
      currency: 'GBP',
    },
    startAt: '2019-10-01T07:00:38Z',
  },
  {
    actions,
    advertId: 2,
    advertReference: 'advert-2',
    advertTitle: 'Metal Drawer Tools',
    advertStatus: 'collected',
    collectedAt: '2019-10-04T09:01:38Z',
    endAt: '2019-10-02T07:00:38Z',
    hasUnreadMessages: false,
    imageUrl: 'https://picsum.photos/500/700',
    jobStatus: 'complete',
    offeredAt: '2019-09-01T09:01:38Z',
    postcode: '',
    price: {
      amount: 25,
      currency: 'GBP',
    },
    startAt: '2019-10-01T07:00:38Z',
  },
]

export const activityItemFixture: ActivityItem = {
  actions,
  advertId: 1,
  advertReference: 'advert-1',
  advertTitle: 'Cardboard Box',
  advertStatus: 'collected',
  collectedAt: parseDate('2019-10-01T09:01:38Z'),
  endAt: parseDate('2019-10-02T07:00:38Z'),
  hasUnreadMessages: false,
  imageUrl: 'https://picsum.photos/500/700',
  jobStatus: 'complete',
  offeredAt: parseDate('2019-09-01T09:01:38Z'),
  postcode: 'SW151AX',
  price: {
    amount: 10,
    currency: 'GBP',
  },
  startAt: parseDate('2019-10-01T07:00:38Z'),
}

export const activityItemActionsFixture = actions

export const activityItemsFixture: ActivityItem[] = [
  {
    actions,
    advertId: 1,
    advertReference: 'advert-1',
    advertTitle: 'Cardboard Box',
    advertStatus: 'active',
    collectedAt: parseDate('2019-10-01T09:01:38Z'),
    endAt: parseDate('2019-10-02T07:00:38Z'),
    hasUnreadMessages: false,
    imageUrl: 'https://picsum.photos/500/700',
    jobStatus: 'awaitingcollection',
    offeredAt: parseDate('2019-09-01T09:01:38Z'),
    postcode: 'SW151AX',
    price: {
      amount: 10,
      currency: 'GBP',
    },
    startAt: parseDate('2019-10-01T07:00:38Z'),
  },
  {
    actions,
    advertId: 2,
    advertReference: 'advert-2',
    advertTitle: 'Metal Drawer Tools',
    advertStatus: 'collected',
    collectedAt: parseDate('2019-10-04T09:01:38Z'),
    endAt: parseDate('2019-10-02T07:00:38Z'),
    hasUnreadMessages: false,
    imageUrl: 'https://picsum.photos/500/700',
    jobStatus: 'complete',
    offeredAt: parseDate('2019-09-01T09:01:38Z'),
    postcode: '',
    price: {
      amount: 25,
      currency: 'GBP',
    },
    startAt: parseDate('2019-10-01T07:00:38Z'),
  },
  {
    actions,
    advertId: 3,
    advertReference: 'advert-3',
    advertTitle: 'Junk',
    advertStatus: 'helpunderway',
    collectedAt: parseDate('2019-10-01T09:01:38Z'),
    endAt: parseDate('2019-10-02T07:00:38Z'),
    hasUnreadMessages: false,
    imageUrl: 'https://picsum.photos/500/700',
    jobStatus: 'collectorconfirmed',
    offeredAt: parseDate('2019-09-01T09:01:38Z'),
    postcode: 'SW151AX',
    price: {
      amount: 10,
      currency: 'GBP',
    },
    startAt: parseDate('2019-10-01T07:00:38Z'),
  },
  {
    actions,
    advertId: 4,
    advertReference: 'advert-4',
    advertTitle: 'Garden waste',
    advertStatus: 'collected',
    collectedAt: parseDate('2019-10-04T09:01:38Z'),
    endAt: parseDate('2019-10-02T07:00:38Z'),
    hasUnreadMessages: false,
    imageUrl: 'https://picsum.photos/500/700',
    jobStatus: 'complete',
    offeredAt: parseDate('2019-09-01T09:01:38Z'),
    postcode: '',
    price: {
      amount: 25,
      currency: 'GBP',
    },
    startAt: parseDate('2019-10-01T07:00:38Z'),
  },
]
