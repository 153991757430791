import { action, failureAction, payloadAction } from '../../action-factory'
import apiAction, { ActionBundle } from '../../api-action'
import { ActionFactory, FailureActionFactory, PayloadActionFactory } from '../../types/core'
import { StatelessPayloadThunk } from '../../types/thunk'
import { withEndpoint } from '../app/helpers'
import { supportRequestApi } from './api'
import {
  SUPPORT_REQUEST_FAILURE,
  SUPPORT_REQUEST_SET_MODAL_OPEN,
  SUPPORT_REQUEST_START,
  SUPPORT_REQUEST_SUCCESS,
  SupportRequestFailure,
  SupportRequestParams,
  SupportRequestResult,
  SupportRequestSetModalOpen,
  SupportRequestStart,
  SupportRequestSuccess,
} from './types'

export const supportRequestSetModalOpen: PayloadActionFactory<
  SupportRequestSetModalOpen,
  SupportRequestSetModalOpen['payload']
> = payloadAction(SUPPORT_REQUEST_SET_MODAL_OPEN)
export const supportRequestStart: ActionFactory<SupportRequestStart> = action(SUPPORT_REQUEST_START)
export const supportRequestSuccess: ActionFactory<SupportRequestSuccess> = action(SUPPORT_REQUEST_SUCCESS)
export const supportRequestFailure: FailureActionFactory<SupportRequestFailure> = failureAction(SUPPORT_REQUEST_FAILURE)
export const supportRequestActionBundle: ActionBundle<
  SupportRequestStart,
  SupportRequestSuccess,
  SupportRequestFailure
> = [supportRequestStart, supportRequestSuccess, supportRequestFailure]

export const supportRequest: StatelessPayloadThunk<SupportRequestParams, SupportRequestResult> = params =>
  withEndpoint(
    'supportRequests',
    url => dispatch => dispatch(apiAction(supportRequestActionBundle)(supportRequestApi(url, params))),
    supportRequestFailure,
  )
