import { compact, first, flow, isEmpty, join, map, reject, split, toString } from 'lodash/fp'

import { compactAndJoin, isDefined } from '../../../helpers'
import { PostcodePayload, Transform } from '../../../types'
import {
  AdvertAddress,
  AdvertBase,
  CollectorCollectionDoneAdvertState,
  CollectorDetails,
  JunkLoverCollectionDoneAdvertState,
  JunkLoverDetails,
  Status,
} from '../types'
import { retrievePostcode } from '.'

// TODO: Move those functions elsewhere. Keeping here for now as moving to index causes CD due to role checks.

export const formatAdvertShortPostcode: Transform<PostcodePayload, string> = flow(
  retrievePostcode,
  split(' '),
  first,
  toString,
)

export const isCollectorCollectionDoneState = (advert: AdvertBase): advert is CollectorCollectionDoneAdvertState => {
  const {
    actions: { collectorDisposalSites, confirmAndDisposeWithImages, confirmAndReuseWithImages },
  } = advert

  return (
    isDefined(collectorDisposalSites) &&
    (isDefined(confirmAndDisposeWithImages) || isDefined(confirmAndReuseWithImages))
  )
}

export const isJunkLoverCollectionDoneState = (advert: AdvertBase): advert is JunkLoverCollectionDoneAdvertState => {
  const {
    actions: { junkLoverConfirmAndReuse },
  } = advert

  return isDefined(junkLoverConfirmAndReuse)
}

export const isCustomerToConfirmState = ({ jobDetails, publicDetails: { supplierType }, view }: AdvertBase) =>
  jobDetails?.status === 'collectorconfirmed' && supplierType === 'collector' && view === 'associated'

export const isFullAddressVisible = (advert: AdvertBase) =>
  isCollectorCollectionDoneState(advert) || isCustomerToConfirmState(advert)

export const formatAdvertAddress: Transform<AdvertAddress, string> = flow(
  address => {
    const { address1, address2, address3, city } = address

    return [[address1, address2], [city], [retrievePostcode(address)], [address3]]
  },
  map(compact),
  reject(isEmpty),
  map(join(' ')),
  join(', '),
)

export const formatReusableAdvertAddress: Transform<AdvertAddress, string> = address =>
  `${address.city}, ${retrievePostcode(address)}`

export const formatMapAddress: Transform<AdvertAddress, string> = address => {
  const { address1, address2, address3, city } = address

  return `${compactAndJoin(' ')([address1, compactAndJoin(', ')([address2, address3])])}, ${retrievePostcode(
    address,
  )}, ${city}`
}

export const isCollectorDetails = (details: CollectorDetails | JunkLoverDetails): details is CollectorDetails =>
  isDefined((details as CollectorDetails).licenseNumber)

export const postcodeOrDistrict = (
  { postcode, postcodeDistrict }: PostcodePayload,
  status: Status,
  isCollector: boolean,
) => (isCollector && status === 'helpunderway' ? postcode : undefined) || postcodeDistrict
