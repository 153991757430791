import React, { FC } from 'react'
import { ClassNameProps } from 'types/ui'

import { styled } from '../styled'
import FormField from './containers/FormField'

type Props = ClassNameProps

const FormDivider: FC<Props> = ({ className }) => (
  <FormField className={className} fullWidth>
    <Line />
  </FormField>
)

const Line = styled.hr`
  background-color: ${({ theme: { colors } }) => colors.lightGrey};
  border: none;
  height: 6px;
`

export default FormDivider
