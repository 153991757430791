import { flow } from 'lodash/fp'

import { del, get, put } from '../../api'
import { CompoundApi, SimpleApi } from '../../api-action'
import { DeleteAccountError } from '../../errors'
import { responseToBody } from '../../helpers'
import { Transform } from '../../types/core'
import {
  CustomerCardInfo,
  CustomerCardInfoResponse,
  DeleteAccountResponse,
  GetMetaResponse,
  Meta,
  UpdateUserParams,
} from './types'

export const deleteAccountApi: Transform<string, CompoundApi<DeleteAccountResponse, void>> = url => [
  del(url),
  flow(responseToBody, ({ message }) => {
    if (!message) return

    throw new DeleteAccountError(message)
  }),
]

export const getMetaApi: Transform<string, CompoundApi<GetMetaResponse, Meta>> = url => [get(url), responseToBody]

export const setVatRegisteredApi = (url: string, isVatRegistered: boolean): SimpleApi => put(url, { isVatRegistered })

export const updateUserApi = (url: string, params: UpdateUserParams): SimpleApi => put(url, params)

export const fetchCustomerCardInfo: Transform<
  string,
  CompoundApi<CustomerCardInfoResponse, CustomerCardInfo>
> = url => [get(url), responseToBody]

export const notificationsPreferenceApi = (
  url: string,
  receiveMarketingNotifications: boolean,
): CompoundApi<void, boolean> => [put(url, { receiveMarketingNotifications }), () => receiveMarketingNotifications]
