import React, { useMemo } from 'react'

import { BECOME_JUNKLOVER_URL } from '../../constants'
import { NoOp } from '../../types/core'
import { ComponentFactory, ToElements } from '../../types/ui'

interface Props {
  createAccount: NoOp
  isLoggedIn: boolean
  onLoggedIn: NoOp
}

interface ExternalLinkProps {
  url: string
}

interface SubmitProps {
  onPress: NoOp
  text: string
}

interface ElementsProps {
  ExternalLink: ExternalLinkProps
  LoginLink: unknown
  Paragraph: unknown
  Root: unknown
  Submit: SubmitProps
  Title: unknown
}

type Elements = ToElements<ElementsProps>

const factory: ComponentFactory<Elements, Props> = ({ ExternalLink, LoginLink, Paragraph, Root, Submit, Title }) =>
  function ({ createAccount, isLoggedIn, onLoggedIn }) {
    const [createAccountText, createAccountHandler] = useMemo(
      () => (isLoggedIn ? ['CONTINUE', onLoggedIn] : ['CREATE ACCOUNT', createAccount]),
      [createAccount, isLoggedIn, onLoggedIn],
    )

    const title = useMemo(() => (isLoggedIn ? 'Message Owner' : 'Create account'), [isLoggedIn])

    const registrationInfo = 'Registration is free and takes seconds.'

    return (
      <Root>
        <Title>{title}</Title>
        <Paragraph>Pick up reusables for free from the LoveJunk platform.</Paragraph>
        <Paragraph>You can keep, donate or resell any item you collect.</Paragraph>
        <Paragraph>
          Read <ExternalLink url={BECOME_JUNKLOVER_URL}>how it works</ExternalLink>.
        </Paragraph>
        {!isLoggedIn && <Paragraph>{registrationInfo}</Paragraph>}
        <Submit text={createAccountText} onPress={createAccountHandler} />
        {!isLoggedIn && <LoginLink>Already have an account? Login</LoginLink>}
      </Root>
    )
  }

export default factory
