import {
  isApiUrlFetched,
  isRehydrated,
  storeCheckFactory as factory,
  StoreCheckStateProps as StateProps,
} from '@lovejunk/core'
import RootState from 'core/state'
import { connect, MapStateToProps } from 'react-redux'

import Check from './Check'

const mapStateToProps: MapStateToProps<StateProps, unknown, RootState> = rootState => ({
  isApiUrlFetched: isApiUrlFetched(rootState),
  isRehydrated: isRehydrated(rootState),
})

export default connect(mapStateToProps)(factory({ Check }))
