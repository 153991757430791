import Reducer from '../../types/reducer'
import { supportRequestIsModalOpenLens } from './lens'
import State, { supportRequestDefaultState as defaultState } from './state'
import { SUPPORT_REQUEST_FAILURE, SUPPORT_REQUEST_SET_MODAL_OPEN, SUPPORT_REQUEST_SUCCESS } from './types'

const reducer: Reducer<State> = (state = defaultState(), action) => {
  switch (action.type) {
    case SUPPORT_REQUEST_SUCCESS:
    case SUPPORT_REQUEST_FAILURE:
      return supportRequestIsModalOpenLens(state).set(false)
    case SUPPORT_REQUEST_SET_MODAL_OPEN:
      return supportRequestIsModalOpenLens(state).set(action.payload)
    default:
      return state
  }
}

export default reducer
