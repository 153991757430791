import { Get, Nullable, Postcode, Suggestion } from '@lovejunk/core'

interface State {
  list: Suggestion[]
  isPostcodeSupported: Nullable<boolean>
  isPostcodeValid: Nullable<boolean>
  isUlezPostcode: Nullable<boolean>
  postcode: Nullable<Postcode>
  wasPostcodeChecked: Nullable<boolean>
}

export const defaultState: Get<State> = () => ({
  list: [],
  isPostcodeSupported: null,
  isPostcodeValid: null,
  isUlezPostcode: null,
  postcode: null,
  wasPostcodeChecked: null,
})

export default State
