import { action, payloadAction } from '../../action-factory'
import { ActionFactory, Nullable, PayloadActionFactory } from '../../types/core'
import {
  CANCEL_CUSTOMER_SIGNUP,
  CancelCustomerSignup,
  SET_IS_CUSTOMER_VAT_REGISTERED,
  SetIsCustomerVatRegistered,
} from './types'

export const cancelCustomerSignup: ActionFactory<CancelCustomerSignup> = action(CANCEL_CUSTOMER_SIGNUP)
export const setIsCustomerVatRegistered: PayloadActionFactory<
  SetIsCustomerVatRegistered,
  Nullable<boolean>
> = payloadAction(SET_IS_CUSTOMER_VAT_REGISTERED)
