import { IoClose } from 'react-icons/io5'
import { styled } from 'styled'

import IconButton from './IconButton'

export default styled(IconButton).attrs(({ color = 'white', size = 1 }) => ({ color, ReactIcon: IoClose, size }))`
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  // some other elements like gallery gradient has z-index: 1
  z-index: 2;
`
