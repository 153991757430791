import { action, failureAction, payloadAction } from '../../action-factory'
import { ActionBundle } from '../../api-action'
import { ActionFactory, FailureActionFactory, PayloadActionFactory, Transform } from '../../types/core'
import {
  AUTHORISED,
  Authorised,
  CANCEL_SIGNUP,
  CancelSignup,
  CLEAR_SIGNUP_ERROR,
  ClearError,
  SET_SIGNUP_DATA,
  SetSignupData,
  SIGNUP_OTP_FAILURE,
  SIGNUP_OTP_START,
  SIGNUP_OTP_SUCCESS,
  SignupOtpFailure,
  SignupOtpStart,
  SignupOtpSuccess,
  SignupPayload,
} from './types'

export const signupOtpStart: PayloadActionFactory<SignupOtpStart, SignupPayload> = payloadAction(SIGNUP_OTP_START)
export const signupOtpSuccess: ActionFactory<SignupOtpSuccess> = action(SIGNUP_OTP_SUCCESS)
export const signupOtpFailure: FailureActionFactory<SignupOtpFailure> = failureAction(SIGNUP_OTP_FAILURE)
export const signupOtpActionBundle: Transform<
  SignupPayload,
  ActionBundle<SignupOtpStart, SignupOtpSuccess, SignupOtpFailure>
> = signupPayload => [() => signupOtpStart(signupPayload), signupOtpSuccess, signupOtpFailure]

export const authorised: ActionFactory<Authorised> = action(AUTHORISED)
export const cancelSignup: ActionFactory<CancelSignup> = action(CANCEL_SIGNUP)
export const clearError: ActionFactory<ClearError> = action(CLEAR_SIGNUP_ERROR)

export const setSignupData: PayloadActionFactory<SetSignupData, Partial<SignupPayload>> = payloadAction(SET_SIGNUP_DATA)
