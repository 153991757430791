import {
  CollectorProfileElementsProps,
  collectorProfileScreenFactory as factory,
  getCollectorProfile,
  getCollectorReviews,
} from '@lovejunk/core'
import React from 'react'
import { connect } from 'react-redux'
import { styled } from 'styled'
import { withSpacing } from 'utils/css'

import MainButton from '../button/MainButton'
import TextButton from '../button/TextButton'
import Column from '../containers/Column'
import Map from '../Map'
import Text from '../text/Text'
import Card from './Card'
import ReviewItem from './ReviewItem'
import Reviews from './Reviews'

const CollectorProfile = factory({
  About: Text,
  Card,
  Map: ({ radius, ...props }) => <Map {...props} height={14} radius={radius && 1000 * radius} />,
  Reviews,
  ReviewsPlaceholder: styled(Column)`
    ${withSpacing(0.5, true)}
  `,
  ReviewsPlaceholderItem: styled(Text).attrs({ block: true })``,
  LoadMore: styled(MainButton).attrs<CollectorProfileElementsProps['LoadMore']>(({ children: text }) => ({ text }))`
    align-self: center;
    margin-top: 1em;
  `,
  ReviewItem,
  Root: styled(Column)`
    padding: 2em;
  `,
  SectionTitle: styled.h2`
    font-family: Lato-Bold;
    font-size: 1.375em;
    margin: 1em 0;
  `,
  ShowFullAbout: styled(TextButton).attrs({ color: 'secondary' })<CollectorProfileElementsProps['ShowFullAbout']>`
    align-self: flex-end;
  `,
})

const mapDispatchToProps = {
  getCollectorProfile,
  getCollectorReviews,
}

export default connect(undefined, mapDispatchToProps)(CollectorProfile)
