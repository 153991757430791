import { subHours, subMinutes, subSeconds } from 'date-fns'

import { configurationFixture as configuration } from '../../entities/app/tests/fixtures'
import RootState from '../../state'
import { Transform } from '../../types/core'

export const stateWithConfiguration = (state: RootState) => ({ ...state, app: { ...state.app, configuration } })

export const mockPartially = (moduleName: string, mock: Record<string, jest.MockedFunction<any>>) =>
  jest.setMock(moduleName, {
    ...jest.requireActual(moduleName),
    ...mock,
  })

type TimeModifier = (date: Date, value: number) => Date

export const ago: Transform<TimeModifier, Transform<number, Date>> = modifier => value => modifier(new Date(), value)

export const secondsAgo = ago(subSeconds)
export const minutesAgo = ago(subMinutes)
export const hoursAgo = ago(subHours)
