import { saveJunkLoverSelfie } from '../../entities/signup-junk-reuser/actions'
import State from '../../entities/signup-junk-reuser/state'
import { Get } from '../../types/core'

interface DispatchProps {
  saveSelfie: typeof saveJunkLoverSelfie
}

interface StateProps {
  selfie: State['selfie']
}

export type Props = DispatchProps & StateProps

interface Result {
  text: string
}

const useSelfie: Get<Result> = () => ({
  text: 'Smile! Owners see this when reviewing your message.',
})

export default useSelfie
