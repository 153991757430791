import { PayloadAction } from '@lovejunk/core'

export interface Utm {
  utm_campaign: string
  utm_medium: string
  utm_source: string
}

export const UTM_PARAMS: (keyof Utm)[] = ['utm_campaign', 'utm_medium', 'utm_source']

export const SAVE_UTM = 'SAVE_UTM'

export type SaveUtm = PayloadAction<typeof SAVE_UTM, Partial<Utm>>

export type UtmAction = SaveUtm
