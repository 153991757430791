import { includes, toUpper } from 'lodash/fp'
import React, { useCallback, useEffect, useMemo } from 'react'

import { isCustomerMetaPresent } from '../../entities/meta/helpers'
import { customerCardInfoLens, metaDataLens } from '../../entities/meta/lens'
import MetaState from '../../entities/meta/state'
import { CustomerCardInfo } from '../../entities/meta/types'
import { compactAndJoin, isNotNull } from '../../helpers'
import { Dispatch, NoOp, Nullable, PaymentMethod, Transform } from '../../types/core'
import { ComponentFactory, ToElements } from '../../types/ui'

interface DispatchProps {
  fetchCustomerCardInfo: NoOp
  updatePreferredPaymentMethod: Dispatch<PaymentMethod>
}

interface OwnProps {
  onCardPress: NoOp

  nativePayText?: string
  showNative?: boolean
}

interface StateProps {
  customerCardInfo: Nullable<CustomerCardInfo>
  customerPaymentCapabilities?: PaymentMethod[]
  preferredPaymentMethod?: PaymentMethod
}

export type ExternalProps = DispatchProps & StateProps

type Props = ExternalProps & OwnProps

interface CardButtonProps {
  onPress: NoOp
}

interface CheckBoxProps {
  onChange: Dispatch<boolean>

  value?: boolean
}

interface ElementsProps {
  CardButton: CardButtonProps
  CardIcon: unknown
  CheckBox: CheckBoxProps
  Container: unknown
  Icon: unknown
  IconAndTitle: unknown
  NativePayIcon: unknown
  Root: unknown
  Text: unknown
}

type Elements = ToElements<ElementsProps>

const factory: ComponentFactory<Elements, Props> =
  ({ CardButton, CardIcon, CheckBox, Container, Icon, IconAndTitle, NativePayIcon, Root, Text }) =>
  ({
    customerCardInfo,
    customerPaymentCapabilities,
    fetchCustomerCardInfo,
    nativePayText,
    onCardPress,
    preferredPaymentMethod,
    showNative = true,
    updatePreferredPaymentMethod,
  }) => {
    useEffect(() => {
      fetchCustomerCardInfo()
    }, [fetchCustomerCardInfo])

    const onCardSelect = useCallback(() => updatePreferredPaymentMethod('card'), [updatePreferredPaymentMethod])
    const onNativePaySelect = useCallback(() => updatePreferredPaymentMethod('native'), [updatePreferredPaymentMethod])
    const hasNative = includes('native')(customerPaymentCapabilities)
    const hasCard = includes('card')(customerPaymentCapabilities)
    const isNativePaySelected = preferredPaymentMethod === 'native'
    const isCardSelected = preferredPaymentMethod === 'card'
    const [brand, last4] = isNotNull(customerCardInfo) ? [customerCardInfo.brand, customerCardInfo.last4] : ['', '']
    const cardText = hasCard
      ? compactAndJoin(' ')([`**** **** **** ${last4}`, brand ? `(${toUpper(brand)})` : null, '(click to update)'])
      : 'Add payment card'
    const isNativeEnabled = useMemo(() => hasNative && showNative, [hasNative, showNative])

    return (
      <Root>
        {isNativeEnabled ? (
          <Container>
            <IconAndTitle>
              <Icon>
                <NativePayIcon />
              </Icon>
              <Text>{nativePayText}</Text>
            </IconAndTitle>
            {hasCard ? <CheckBox onChange={onNativePaySelect} value={isNativePaySelected} /> : null}
          </Container>
        ) : null}
        <Container>
          <CardButton onPress={onCardPress}>
            <IconAndTitle>
              <Icon>
                <CardIcon />
              </Icon>
              <Text>{cardText}</Text>
            </IconAndTitle>
          </CardButton>
          {hasCard && isNativeEnabled ? <CheckBox onChange={onCardSelect} value={isCardSelected} /> : null}
        </Container>
      </Root>
    )
  }

export const metaStateToStateProps: Transform<MetaState, StateProps> = state => {
  const meta = metaDataLens(state).get()
  const { customerPaymentCapabilities, preferredPaymentMethod } = isCustomerMetaPresent(meta)
    ? {
        customerPaymentCapabilities: meta.customerPaymentCapabilities,
        preferredPaymentMethod: meta.customerInfo.preferredPaymentMethod,
      }
    : {
        customerPaymentCapabilities: undefined,
        preferredPaymentMethod: undefined,
      }

  return {
    customerCardInfo: customerCardInfoLens(state).get(),
    customerPaymentCapabilities,
    preferredPaymentMethod,
  }
}

export default factory
