import { styled } from 'styled'

interface Props {
  children?: string
}

const defaultText =
  'Get matched to your cheapest licensed waste collector or reuse organisation in seconds. Save yourself time & money and dispose of waste responsibly.'

// https://css-tricks.com/the-image-replacement-museum/#2014-h5bp-image-replacement-2
const H1 = styled.h1.attrs<Props>(({ children = defaultText }) => ({ children }))`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`

export default H1
