import React from 'react'

import { advertCreation } from '../../entities/advert/helpers'
import { ComponentFactory, ToElements } from '../../types/ui'

export interface OwnProps {
  hideTitle?: boolean
  listedAt?: Date
}

type Props = OwnProps

interface ElementsProps {
  PostedAt: unknown
  Root: unknown
  Title: unknown
}

type Elements = ToElements<ElementsProps>

const factory: ComponentFactory<Elements, Props> = ({ PostedAt, Root, Title }) =>
  function PriceRow({ hideTitle = false, listedAt }) {
    return hideTitle && !listedAt ? null : (
      <Root>
        {hideTitle ? null : <Title>Details</Title>}
        {listedAt ? <PostedAt>listed {advertCreation(listedAt)}</PostedAt> : null}
      </Root>
    )
  }

export default factory
