import { flow } from 'lodash'

import Reducer from '../../types/reducer'
import { CONFIRM_PUBLISH_ADVERT_FLOW_SUCCESS } from '../new-advert/types'
import { freegleHasPostcodeLens, freegleIdLens, isLoadingFreegleAdvertLens } from './lens'
import State, { freegleDefaultState as defaultState } from './state'
import {
  FETCH_FREEGLE_ADVERT_FAILURE,
  FETCH_FREEGLE_ADVERT_START,
  FETCH_FREEGLE_ADVERT_SUCCESS,
  SET_FREEGLE_HAS_POSTCODE,
} from './types'

const reducer: Reducer<State> = (state = defaultState(), action) => {
  switch (action.type) {
    case FETCH_FREEGLE_ADVERT_START:
      return isLoadingFreegleAdvertLens(state).set(true)
    case FETCH_FREEGLE_ADVERT_FAILURE:
      return isLoadingFreegleAdvertLens(state).set(false)
    case FETCH_FREEGLE_ADVERT_SUCCESS:
      return flow(
        freegleIdLens,
        l => l.set(action.payload.freegleId),
        isLoadingFreegleAdvertLens,
        l => l.set(false),
      )(state)
    case CONFIRM_PUBLISH_ADVERT_FLOW_SUCCESS:
      return freegleIdLens(state).set(null)
    case SET_FREEGLE_HAS_POSTCODE:
      return freegleHasPostcodeLens(state).set(action.payload)
    default:
      return state
  }
}

export default reducer
