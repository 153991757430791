import { filter, flow, includes, isNull, toLower, union } from 'lodash/fp'

import { HazardousWasteError } from '../../errors'
import { callWith, hasItems, isNotNull, toLocalState } from '../../helpers'
import { ToModel, Transform } from '../../types/core'
import {
  isValidNewAdvertAddress,
  isValidNewAdvertCollectionPoint,
  isValidNewAdvertDetails,
  isValidNewAdvertFixedTimeSlot,
  isValidTimeSlotPayload,
} from './helpers'
import {
  addressLens,
  collectionPointLens,
  detailsLens,
  fixedTimeSlotLens,
  getImagesLens,
  getVideosLens,
  idLens,
  isReuseOnlyLens,
  manualAddressEntryLens,
  newAdvertRootLens,
  partnerDetailsLens,
  reusableLens,
  selectedCollectorsLens,
  suggestedPriceLens,
  summaryLens,
  timeSlotLens,
  titleLens,
} from './lens'
import State from './state'
import { NewAdvert, ValidNewAdvert } from './types'
import { reusableValidation, suggestedPriceRangeValidation } from './validation'

// `reference` is set to `null` as in web advert is not editable.
const toModel: ToModel<State, NewAdvert> = state => ({
  address: addressLens(state).get(),
  assets: [...getImagesLens(state)(), ...getVideosLens(state)()],
  collectionPoint: collectionPointLens(state).get(),
  confirmed: true,
  details: detailsLens(state).get(),
  fixedTimeSlot: fixedTimeSlotLens(state).get(),
  id: idLens(state).get(),
  isReuseOnly: isReuseOnlyLens(state).get(),
  manualAddressEntry: manualAddressEntryLens(state).get(),
  partnerDetails: partnerDetailsLens(state).get(),
  reference: null,
  reusable: reusableLens(state).get(),
  selectedCollectors: selectedCollectorsLens(state).get(),
  suggestedPriceRange: suggestedPriceLens(state).get(),
  summary: summaryLens(state).get(),
  timeSlot: timeSlotLens(state).get(),
  title: titleLens(state).get(),
})

const fridgeKeywords = ['fridge']
const freezerKeywords = ['freezer']
const hazardousKeywords = [
  'asbestos',
  'car battery',
  'car batteries',
  'commercial fridge',
  'commercial freezer',
  'chiller',
  'fire extinguisher',
  'gas bottle',
  'paint',
]

const filterKeywords: Transform<string[], Transform<string, string[]>> = keywords =>
  flow(toLower, callWith, callWithValue => filter(flow(includes, callWithValue))(keywords))

const filterFridge = filterKeywords(fridgeKeywords)
const filterFreezer = filterKeywords(freezerKeywords)
const filterHazardousKeywords = filterKeywords(hazardousKeywords)

const findHazardousPhrases: Transform<NewAdvert, string[]> = ({ details: { residential }, summary, title }) => [
  ...union(filterHazardousKeywords(summary), filterHazardousKeywords(title)),
  ...(!residential && hasItems(union(filterFridge(summary), filterFridge(title))) ? ['commercial fridge'] : []),
  ...(!residential && hasItems(union(filterFreezer(summary), filterFreezer(title))) ? ['commercial freezer'] : []),
]

export const validateNewAdvert: Transform<NewAdvert, Promise<ValidNewAdvert>> = newAdvert =>
  new Promise((resolve, reject) => {
    const { collectionPoint, details, fixedTimeSlot, reusable, suggestedPriceRange, timeSlot } = newAdvert

    if (isNull(fixedTimeSlot) && isNull(timeSlot)) return reject(new Error('time slot not set'))

    if (isNotNull(fixedTimeSlot) && !isValidNewAdvertFixedTimeSlot(fixedTimeSlot))
      return reject(new Error('fixed time slot not valid'))

    if (isNotNull(timeSlot) && !isValidTimeSlotPayload(timeSlot)) return reject(new Error('fixed time slot not valid'))

    if (!suggestedPriceRangeValidation(suggestedPriceRange)) return reject(new Error('suggested price not set'))

    if (!reusableValidation(reusable)) return reject(new Error('reusable not set'))

    if (!isValidNewAdvertAddress(newAdvert.address)) return reject(new Error('address not valid'))

    if (!isValidNewAdvertDetails(details)) return reject(new Error('details not valid'))

    // Here we validate model, only to be consumed by the BE, so we make it as less strict as possible.
    // By passing `true` we make `easyAccessReason` not required.
    if (!isValidNewAdvertCollectionPoint(collectionPoint, true)) return reject(new Error('collection point not valid'))

    const hazardousPhrases = findHazardousPhrases(newAdvert)

    if (hasItems(hazardousPhrases)) return reject(new HazardousWasteError(hazardousPhrases))

    // Spread because some properties are type guarded to compose `ValidNewAdvert`.
    return resolve({
      ...newAdvert,
      collectionPoint,
      details,
      fixedTimeSlot,
      reusable,
      suggestedPriceRange,
      timeSlot,
    })
  })

export const validatedAdvert = flow(toLocalState(newAdvertRootLens), toModel, validateNewAdvert)
