import { DateMarshaller, Marshal, Unmarshal } from '@lovejunk/core/src'

import { FilterParams, Filters, TradeJobItem, TradeJobItemResource } from './types'

export const unmarshal: Unmarshal<TradeJobItemResource, TradeJobItem> = ({ collectedAt, listedAt, ...rest }) => ({
  ...rest,
  collectedAt: collectedAt ? DateMarshaller.unmarshal(collectedAt) : undefined,
  listedAt: DateMarshaller.unmarshal(listedAt),
})

export const marshalFilters: Marshal<Filters, FilterParams> = ({ postcode }) => ({ postcode: postcode || undefined })
