import {
  hadSuccessfulOauthCheck,
  oauthCheckFactory as factory,
  OauthCheckStateProps as StateProps,
} from '@lovejunk/core'
import RootState from 'core/state'
import { checkOrGetToken } from 'entities/oauth'
import { connect, MapStateToProps } from 'react-redux'

import Check from './Check'

const mapStateToProps: MapStateToProps<StateProps, unknown, RootState> = rootState => ({
  hadSuccessfulOauthCheck: hadSuccessfulOauthCheck(rootState),
})

const mapDispatchToProps = {
  checkOrGetToken,
}

export default connect(mapStateToProps, mapDispatchToProps)(factory({ Check }))
