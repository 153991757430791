import { appReducer, CONFIRM_ACCOUNT_DELETED, SupportedAction } from '@lovejunk/core'
import Reducer from 'types/reducer'

import { accountDeletedLens, advertsVisitedLens, isMenuVisibleLens, navigationLens } from './lens'
import AppState, { appDefaultState } from './state'
import { ADVERTS_VISITED, NAVIGATE, SET_MENU_VISIBLE } from './types'

const reducer: Reducer<AppState> = (newState = appDefaultState, action) => {
  const state = appReducer(newState, action as SupportedAction) as AppState

  switch (action.type) {
    case ADVERTS_VISITED:
      return advertsVisitedLens(state).set(true)
    case CONFIRM_ACCOUNT_DELETED:
      return accountDeletedLens(state).set(false)
    case NAVIGATE:
      return navigationLens(state).set(action.payload)
    case SET_MENU_VISIBLE:
      return isMenuVisibleLens(state).set(action.payload)
    default:
      return state
  }
}

export default reducer
