import { configureOauth, OAuthConfig, OAuthParams } from '@lovejunk/core'
import { toString } from 'lodash'

const oauthParams: OAuthParams = {
  clientId: 'lovejunkweb',
  grantType: 'authorization_code',
  clientSecret: toString(process.env.REACT_APP_CLIENT_SECRET),
  scope: 'lovejunkweb',
}

const oauthConfig: OAuthConfig = {
  oauthParams,
}

export default () => {
  configureOauth(oauthConfig)
}
