import { styled } from 'styled'
import { isMobile } from 'utils/environment'

const Title = styled.h3`
  margin-top: 0;
  margin-bottom: 1em;
  color: ${({ theme: { colors } }) => (isMobile ? colors.selected : colors.contrast1)};
`

export default Title
