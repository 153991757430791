import { styled } from 'styled'

import BaseText from './BaseText'

const FieldInfo = styled(BaseText)`
  margin-top: 0.5em;
  font-size: 0.875em;
`

export default FieldInfo
