import React from 'react'

import { Color } from '../../theme'
import { NoOp } from '../../types/core'
import { ComponentFactory, ToElements } from '../../types/ui'

interface Props {
  isAuthor: boolean
  onPress: NoOp
}

interface IconProps {
  color: Color
}

interface RootProps {
  onPress: NoOp
}

interface TextProps {
  color: Color
}

export interface ElementsProps {
  Icon: IconProps
  Root: RootProps
  Text: TextProps
}

type Elements = ToElements<ElementsProps>

const factory: ComponentFactory<Elements, Props> = ({ Icon, Root, Text }) =>
  function AttachmentMock({ isAuthor, onPress }) {
    return (
      <Root onPress={onPress}>
        <Icon color={isAuthor ? 'lightGrey' : 'greyText'} />
        <Text color={isAuthor ? 'lightGrey' : 'regularText'}>Tap to view...</Text>
      </Root>
    )
  }

export default factory
