import { Identity, Transform } from '../types/core'

export const replace =
  (pattern: string, replacement: string): Identity<string> =>
  value =>
    value.replace(pattern, replacement)

export const substring =
  (start: number, end?: number): Identity<string> =>
  value =>
    value.substring(start, end)
export const prependWith: Transform<string, Identity<string>> = prefix => value => prefix + value
