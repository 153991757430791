import { Nullable } from '../../types/core'
import { AdvertId, AdvertReference } from '../advert/types'
import { SignupJunkLoverDetails } from './types'

interface State {
  advertId: Nullable<AdvertId>
  advertRef: Nullable<AdvertReference>
  advertUrl: Nullable<string>
  details: SignupJunkLoverDetails
  isNotApproved: boolean
  isSigningUp: boolean
  selfie: Nullable<string>
  showFeedback: boolean
}

const initialDetails: SignupJunkLoverDetails = {
  postcode: '',
}

export const defaultState: State = {
  advertId: null,
  advertRef: null,
  advertUrl: null,
  details: initialDetails,
  isNotApproved: false,
  isSigningUp: false,
  selfie: null,
  showFeedback: false,
}

export default State
