import { trim } from 'lodash/fp'

import { action, failureAction } from '../../action-factory'
import apiAction, { ActionBundle } from '../../api-action'
import { JunkLoverNotApprovedError } from '../../errors'
import { FailureActionFactory } from '../../types/core'
import { StatelessPayloadThunk } from '../../types/thunk'
import { fetchAdvert } from '../advert/actions'
import { fetchMeta } from '../meta/actions'
import { setJunkLoverNotApproved } from '../signup-junk-reuser/actions'
import { makeOfferApi } from './api'
import {
  MAKE_JUNK_LOVER_OFFER_FAILURE,
  MAKE_JUNK_LOVER_OFFER_START,
  MAKE_JUNK_LOVER_OFFER_SUCCESS,
  MakeJunkLoverOfferFailure,
  MakeJunkLoverOfferPayload,
  MakeJunkLoverOfferResult,
  MakeJunkLoverOfferStart,
  MakeJunkLoverOfferSuccess,
} from './types'

export const makeJunkLoverOfferFailure: FailureActionFactory<MakeJunkLoverOfferFailure> =
  failureAction(MAKE_JUNK_LOVER_OFFER_FAILURE)

export const makeJunkLoverOfferActionBundle: ActionBundle<
  MakeJunkLoverOfferStart,
  MakeJunkLoverOfferSuccess,
  MakeJunkLoverOfferFailure
> = [action(MAKE_JUNK_LOVER_OFFER_START), action(MAKE_JUNK_LOVER_OFFER_SUCCESS), makeJunkLoverOfferFailure]

export const makeJunkLoverOffer: StatelessPayloadThunk<MakeJunkLoverOfferPayload, MakeJunkLoverOfferResult> =
  ({ advertUrl, isApproved, isLoggedIn, text, url }) =>
  async dispatch => {
    if (isLoggedIn) await dispatch(fetchMeta())

    if (isApproved) {
      const action = await dispatch(
        apiAction(makeJunkLoverOfferActionBundle)(makeOfferApi(url, { conditions: text && trim(text) })),
      )

      await dispatch(fetchAdvert(advertUrl))

      return action
    } else {
      dispatch(setJunkLoverNotApproved(true))

      return dispatch(makeJunkLoverOfferFailure(new JunkLoverNotApprovedError()))
    }
  }
