import { stateProps } from '@lovejunk/core'
import RootState from 'core/state'
import { isMenuVisibleLens } from 'entities/app'
import appRootLens from 'entities/app/lens'
import { connect, MapStateToProps } from 'react-redux'
import { styled } from 'styled'
import { ContentWidthProps, device, withContentWidth } from 'utils/css'

interface StateProps {
  hidden: boolean
}

type OwnProps = ContentWidthProps
type Props = OwnProps & StateProps

const Main = styled.div<Props>`
  ${withContentWidth}

  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  margin-bottom: 2em;
  padding: 0 1em;

  @media ${device.mobile} {
    margin-bottom: 0;
  }

  @media ${device.mobileSmall} {
    font-size: 0.9em;
  }
`

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = stateProps(appRootLens)(state => ({
  hidden: isMenuVisibleLens(state).get(),
}))

export default connect(mapStateToProps)(Main)
