import { Nullable, ResponseUrlMeta, Route } from '../../types/core'
import { AdvertBase, AdvertReference, CollectionAgreedPayload, ConfirmReusableCollectionPayload } from './types'

export interface DataItem {
  meta: ResponseUrlMeta
  needsFetch: boolean

  body?: AdvertBase
}

interface State {
  backTo: Nullable<Route>
  confirmReusableCollectionIntent: Nullable<ConfirmReusableCollectionPayload>
  collectionAgreed: Nullable<CollectionAgreedPayload>
  data: Record<AdvertReference, DataItem>
  lastOpenedAdvert: Nullable<AdvertBase>
}

export const advertDefaultState: State = {
  backTo: null,
  confirmReusableCollectionIntent: null,
  collectionAgreed: null,
  data: {},
  lastOpenedAdvert: null,
}

export default State
