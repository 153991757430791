import { compact, flow, join, rest } from 'lodash/fp'

import { css } from '../styled'
import { isMobile } from './environment'

export const withSpacing = (spacing = 0.5, vertical = false) => css`
  & > * {
    margin: ${vertical ? `${spacing}em 0` : `0 ${spacing}em`};
  }

  & > *:first-child {
    margin-${vertical ? 'top' : 'left'}: 0;
  }
  & > *:last-child {
    margin-${vertical ? 'bottom' : 'right'}: 0;
  }
`

interface WithErrorProps {
  hasError: boolean
}

export const withError = css<WithErrorProps>`
  border: 1px solid
    ${({
      hasError,
      theme: {
        colors: { error },
      },
    }) => (hasError ? error : 'transparent')};
  border-radius: 8px;
  padding: ${({ hasError }) => (hasError ? '0.25em' : '0')};
`

export type ContentWidth = 'auto' | 'full' | 'narrow' | 'wide' | 'extra-wide'

const contentWidthMapping: Record<ContentWidth, string> = {
  auto: 'auto',
  full: '100%',
  narrow: '768px',
  wide: '960px',
  'extra-wide': '1366px',
}

export interface ContentWidthProps {
  size?: ContentWidth
  noMargin?: boolean
}

export const withContentWidth = css<ContentWidthProps>`
  align-self: center;
  max-width: ${({ size = 'narrow' }) => contentWidthMapping[isMobile ? 'full' : size]};
  width: 100%;
`

export const breakpoint = {
  mobileSmall: '374px',
  mobile: '719px',
  tablet: '1023px',
}

export const extraLargeScreenMin = 1182
export const largeScreenMin = 830
export const midScreenMin = 600
export const smallScreenMin = 376

export const device = {
  mobileSmall: `(max-width: ${breakpoint.mobileSmall})`,
  mobile: `(max-width: ${breakpoint.mobile})`,
  tablet: `(max-width: ${breakpoint.tablet})`,
}

export const minWidth = {
  small: `(min-width: ${smallScreenMin}px)`,
  medium: `(min-width: ${midScreenMin}px)`,
  large: `(min-width: ${largeScreenMin}px)`,
  xlLarge: `(min-width: ${extraLargeScreenMin}px)`,
}

export const classNames = rest(flow(compact, join(' ')))

export const mainPadding = '0'
