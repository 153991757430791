import { styled } from 'styled'

interface Props {
  fullWidth?: boolean
  margin?: string
}

const FormField = styled.div<Props>`
  // !important overrides withSpacing
  margin: ${({ fullWidth, margin }) => (fullWidth ? '0.625em  -2em' : margin || '0.625em 0')} !important;
`

export default FormField
