import { find, flow, indexOf, map, unionBy } from 'lodash/fp'

import { lensFactory, replaceAt } from '../../helpers'
import { Lens, UpdateLens } from '../../types/core'
import { createActivityLens } from '../activities/lens'
import { unmarshalOfferItem as unmarshal } from '../activities/marshaller'
import { Activity } from '../activities/state'
import { OfferItem, OfferItemResource } from '../activities/types'
import { AdvertId } from '../advert/types'
import { ReloadActivityHasUnreadMessagesSuccessPayload } from '../chat/types'
import State from './state'

const activityTypeSimpleLens = lensFactory<Activity<OfferItem>>()

const createOffersLens = () => {
  const itemsLens: Lens<State, OfferItem[], OfferItemResource[]> = state => ({
    get: () => state.offers.items,
    set: resource => ({
      ...state,
      offers: {
        ...state.offers,
        items: unionBy<OfferItem>('advertId')(state.offers.items)(map(unmarshal)(resource)),
      },
    }),
  })

  const clearLens: UpdateLens<State> = state => ({ ...state, offers: { ...state.offers, items: [] } })

  const hasUnreadMessagesLens: Lens<
    State,
    boolean,
    ReloadActivityHasUnreadMessagesSuccessPayload,
    AdvertId
  > = state => ({
    get: advertId => Boolean(find<OfferItem>({ advertId })(state.offers.items)?.hasUnreadMessages),
    set: ({ advertId, hasUnreadMessages }) => {
      const { items: oldItems } = state.offers
      const item = find<OfferItem>({ advertId })(oldItems)

      if (!item) return state

      const index = indexOf(item)(oldItems)
      const items = replaceAt(index)(oldItems)({ ...item, hasUnreadMessages })

      return { ...state, offers: { ...state.offers, items } }
    },
  })

  const createActivityLens =
    <K extends keyof Activity<OfferItem>>(key: K): Lens<State, Activity<OfferItem>[K]> =>
    state => {
      const { get, set } = activityTypeSimpleLens(key)(state.offers)

      return {
        get,
        set: flow(set, value => ({ ...state, offers: value })),
      }
    }

  const metaLens = createActivityLens('meta')
  const loadingLens = createActivityLens('isLoading')
  const loadingNextLens = createActivityLens('isLoadingNext')

  return { clearLens, hasUnreadMessagesLens, itemsLens, loadingLens, loadingNextLens, metaLens }
}

export const currentSupplierActivityLens = createActivityLens<State>('current')
export const historySupplierActivityLens = createActivityLens<State>('history')
export const offersLens = createOffersLens()
