import { flow, isUndefined, noop, reject, unionBy } from 'lodash/fp'
import React, { useCallback, useReducer, useState } from 'react'
import { Action } from 'redux'

import { action, payloadAction } from '../../action-factory'
import { OnSubmitChat, OnSubmitChatWithAssets } from '../../entities/chat/types'
import { hasItems, isDefined, isPresent } from '../../helpers'
import { Color } from '../../theme'
import {
  ActionFactory,
  Asset,
  AssetPath,
  Dispatch,
  NoOp,
  Optional,
  PayloadAction,
  PayloadActionFactory,
} from '../../types/core'
import { ActionReducer } from '../../types/reducer'
import { ComponentFactory, ToElements } from '../../types/ui'
import { Props as AssetsProps } from '../AssetsRow/Assets'

export interface IconProps {
  disabled: boolean
}

export interface AssetButtonProps {
  assets: Asset[]
  disabled: boolean
  disabledColor: Color
  limit: number
  onSelect: Dispatch<Asset[]>
}

interface PhoneButtonProps {
  disabled: boolean
  disabledColor: Color
  onPress: NoOp
}

interface SubmitButtonProps {
  disabled: boolean
  onPress: NoOp
  progress: boolean
  shadow: boolean
  text: string
}

interface TextAreaProps {
  disabled: boolean
  maxLength: number
  onChange: ((e: any) => void) | Dispatch<string>
  onFocus: NoOp
  onSubmit: NoOp
  placeholder: Optional<string>
  resetHeight: Optional<boolean>
  value: string
  setMessage: Dispatch<string>
}

export interface ElementsProps {
  AssetButton: AssetButtonProps
  Assets: AssetsProps
  Controls: unknown
  PhoneButton: PhoneButtonProps
  Root: unknown
  SubmitButton: SubmitButtonProps
  TextArea: TextAreaProps
}

type Elements = ToElements<ElementsProps>

export interface Props {
  onFocus: NoOp
  showPhone: boolean
  viewPhoneInProgress: boolean

  onPhonePress?: NoOp
  onSubmit?: OnSubmitChat
  onSubmitWithAssets?: OnSubmitChatWithAssets
}

const MAX_ASSETS = 20
const ADD_ASSETS = 'ADD_ASSETS'
const CLEAR_ASSETS = 'CLEAR_ASSETS'
const REMOVE_ASSET = 'REMOVE_ASSET'
type AddAssetsAction = PayloadAction<typeof ADD_ASSETS, Asset[]>
type ClearAssetsAction = Action<typeof CLEAR_ASSETS>
type RemoveAssetAction = PayloadAction<typeof REMOVE_ASSET, AssetPath>
type AssetAction = AddAssetsAction | ClearAssetsAction | RemoveAssetAction

const addAssetAction: PayloadActionFactory<AddAssetsAction, AddAssetsAction['payload']> = payloadAction(ADD_ASSETS)
const clearAssetsAction: ActionFactory<ClearAssetsAction> = action(CLEAR_ASSETS)
const removeAssetAction: PayloadActionFactory<RemoveAssetAction, RemoveAssetAction['payload']> =
  payloadAction(REMOVE_ASSET)
const assetsReducer: ActionReducer<Asset[], AssetAction> = (state = [], action) => {
  switch (action.type) {
    case ADD_ASSETS:
      return unionBy<Asset>('path')(state)(action.payload)
    case CLEAR_ASSETS:
      return []
    case REMOVE_ASSET:
      return reject<Asset>({ path: action.payload })(state)
  }
}

const factory: ComponentFactory<Elements, Props> = ({
  AssetButton,
  Assets,
  Controls,
  PhoneButton,
  TextArea,
  Root,
  SubmitButton,
}) =>
  function Footer({ onFocus, onPhonePress, onSubmit, onSubmitWithAssets, showPhone, viewPhoneInProgress }) {
    const [isBusy, setBusy] = useState<boolean>(false)
    const [assets, dispatchAssets] = useReducer(assetsReducer, [])
    const [message, setMessage] = useState<string>('')
    const [resetWindowMessageHeight, setResetWindowMessageHeight] = useState(false)
    const setNotBusy = useCallback(() => setBusy(false), [])
    const onSend = useCallback(() => {
      setMessage('')
      setResetWindowMessageHeight(true)

      if (onSubmitWithAssets) {
        setBusy(true)
        flow(clearAssetsAction, dispatchAssets)()

        return onSubmitWithAssets({ assets, message }).then(setNotBusy)
      }

      if (onSubmit) {
        setBusy(true)

        return onSubmit({ message }).then(setNotBusy)
      }
    }, [assets, message, onSubmit, onSubmitWithAssets, setNotBusy])
    const disabled = isUndefined(onSubmit) && isUndefined(onSubmitWithAssets)
    const onSelect = useCallback((assets: Asset[]) => flow(addAssetAction, dispatchAssets)(assets), [])
    const onRemoveAsset = useCallback((assetPath: string) => flow(removeAssetAction, dispatchAssets)(assetPath), [])

    return (
      <Root>
        <Assets autoHide limit={MAX_ASSETS} items={assets} onRemove={onRemoveAsset} />
        <Controls>
          {showPhone ? (
            <PhoneButton
              disabled={disabled || isUndefined(onPhonePress)}
              disabledColor="grey"
              onPress={onPhonePress || noop}
            />
          ) : null}
          <AssetButton
            assets={assets}
            disabled={disabled || isUndefined(onSubmitWithAssets)}
            disabledColor="grey"
            limit={MAX_ASSETS}
            onSelect={onSelect}
          />
          <TextArea
            disabled={disabled}
            maxLength={350}
            onChange={setMessage}
            onFocus={onFocus}
            onSubmit={onSend}
            placeholder={disabled ? undefined : 'Type a message...'}
            value={message}
            resetHeight={resetWindowMessageHeight}
            setMessage={setMessage}
          />
          <SubmitButton
            disabled={disabled || !(isPresent(message) || (isDefined(onSubmitWithAssets) && hasItems(assets)))}
            onPress={onSend}
            progress={viewPhoneInProgress || isBusy}
            shadow={false}
            text="Send"
          />
        </Controls>
      </Root>
    )
  }

export default factory
