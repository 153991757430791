import { Get, Nullable, SessionToken } from '../../types/core'
import { LoginFailurePayload } from './types'

export interface LoginState {
  accountSuspended: boolean
  error: Nullable<LoginFailurePayload>
  isSigningIn: boolean
  token: Nullable<SessionToken>
}

export const loginDefaultState: Get<LoginState> = () => ({
  accountSuspended: false,
  error: null,
  isSigningIn: false,
  token: null,
})

export default LoginState
