import { compact, flow, map } from 'lodash/fp'

import { isDefined } from '../../helpers'
import { Optional, Transform, TransformOptional } from '../../types/core'
import { ReloadFavouritesHasUnreadMessagesPayload } from '../chat/types'
import { FavouriteInteractions, FavouritesInteractions, UserActions } from '../meta/types'
import { Favourite, FavouriteCollector, FavouritesParams } from './types'

export const isFavouriteCollector = (favourite: Favourite): favourite is FavouriteCollector =>
  isDefined((favourite as FavouriteCollector).stats)

const actionToReloadUrl: Transform<FavouritesInteractions, string> = ({
  details: {
    reloadHasUnreadMessages: { url },
  },
}) => url

export const userActionsToFavouritesParams: TransformOptional<
  Partial<UserActions>,
  Optional<FavouritesParams>
> = userActions => {
  if (!userActions) return

  const favouriteInteractions: FavouriteInteractions = 'listFavouriteCollectorInteractions'
  const action = userActions[favouriteInteractions]

  return action && { favouriteInteractions, reloadHasUnreadMessagesUrl: actionToReloadUrl(action), url: action.url }
}

export const hasUnreadFavouriteCollectorMessages: TransformOptional<
  Partial<UserActions>,
  Optional<boolean>
> = userActions => {
  if (!userActions) return

  const favouriteInteractions: FavouriteInteractions = 'listFavouriteCollectorInteractions'
  const action = userActions[favouriteInteractions]

  if (!action) return

  return action.details.hasUnreadMessages
}

export const getReloadFavouriteMessagesData: TransformOptional<
  Partial<UserActions>,
  Optional<ReloadFavouritesHasUnreadMessagesPayload[]>
> = userActions =>
  userActions &&
  flow(
    map<FavouriteInteractions, Optional<ReloadFavouritesHasUnreadMessagesPayload>>(favouriteInteractions => {
      const action = userActions[favouriteInteractions]

      return action && { favouriteInteractions, url: actionToReloadUrl(action) }
    }),
    compact,
  )(['listFavouriteCollectorInteractions', 'listFavouriteUserInteractions'])
