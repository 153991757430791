import { join } from 'lodash/fp'
import React, { useCallback, useMemo } from 'react'

import { HAZARDOUS_WASTE_URL } from '../../constants'
import { HazardousWasteError } from '../../errors'
import { hasItems } from '../../helpers'
import { Dispatch, NoOp, Nullable } from '../../types/core'
import { ComponentFactory, ToElements } from '../../types/ui'
import { Mode } from '../modals/types'

interface DispatchProps {
  setHazardousWasteError: Dispatch<null>
}

interface OwnProps {
  navigateToDetails: NoOp
}

interface StateProps {
  error: Nullable<HazardousWasteError>
}

export type ExternalProps = DispatchProps & StateProps

type Props = ExternalProps & OwnProps

interface RootProps {
  onPress: NoOp
  title: string

  mode?: Mode
  visible?: boolean
}

interface ExternalLinkProps {
  url: string
}

interface ElementsProps {
  Body: unknown
  ExternalLink: ExternalLinkProps
  Root: RootProps
}

type Elements = ToElements<ElementsProps>

const factory: ComponentFactory<Elements, Props> =
  ({ Body, ExternalLink, Root }) =>
  ({ error, navigateToDetails, setHazardousWasteError }) => {
    const onDismiss = useCallback(() => {
      setHazardousWasteError(null)
      navigateToDetails()
    }, [navigateToDetails, setHazardousWasteError])
    const wordsPhrase = useMemo(() => (error && hasItems(error.words) ? ` (${join(', ')(error.words)})` : ''), [error])

    return (
      <Root mode="error" onPress={onDismiss} title="Hazardous Waste" visible={Boolean(error)}>
        <Body>
          Description contains <ExternalLink url={HAZARDOUS_WASTE_URL}>hazardous waste</ExternalLink>
          {wordsPhrase}. Please remove the hazardous waste and try again.
        </Body>
      </Root>
    )
  }

export default factory
