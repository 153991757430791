import { dropRight, flatten, flow, map, size, times, zip } from 'lodash/fp'
import { ComponentType, createElement } from 'react'

import { Transform } from '../types/core'
import { DropdownOption, Render } from '../types/ui'

export const componentAdapter =
  <I extends {}>(Component: ComponentType<I>) =>
  <O>(transform: Transform<O, I>, cleanup?: Transform<I & O, I>): ComponentType<O> =>
    flow(
      props => ({ ...props, ...transform(props) }),
      props => (cleanup ? cleanup(props) : props),
      props => createElement(Component, props),
    )

interface RenderListConfig<T> {
  renderDivider: Render<number>
  renderListItem: Render<T>
}

export const renderList =
  <T>({ renderDivider, renderListItem }: RenderListConfig<T>) =>
  (data: T[]) => {
    const listItems = map(renderListItem)(data)
    const dividers = times(renderDivider)(size(data))
    const itemsAndDividers = zip(listItems, dividers)
    const flattened = flatten(itemsAndDividers)

    return dropRight(1)(flattened)
  }

export const toDropDownValue = <T>({ value }: DropdownOption<T>) => value
