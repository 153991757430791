import { Color, FontWeight } from '@lovejunk/core'
import { styled } from 'styled'

export interface Props {
  align?: 'center' | 'left' | 'right'
  block?: boolean
  bold?: boolean
  centered?: boolean
  color?: Color
  semibold?: boolean
  size?: number
  underlined?: boolean
  uppercase?: boolean
  weight?: FontWeight
}

export default styled.span<Props>`
  ${({ align }) => (align ? `text-align: ${align};` : undefined)}
  ${({ block = false }) => (block ? 'display: block;' : undefined)}
  ${({ bold = false }) => (bold ? 'font-family: Lato-Bold;' : undefined)}
  ${({ semibold = false }) => (semibold ? 'font-family: Lato-Semibold;' : undefined)}
  ${({ centered = false }) => (centered ? 'text-align: center;' : undefined)}
  ${({ color, theme: { colors } }) => (color ? `color: ${colors[color]};` : undefined)}
  ${({ size }) => (size ? `font-size: ${size}em;` : undefined)}
  ${({ underlined }) => (underlined ? 'text-decoration: underline;' : undefined)}
  ${({ uppercase }) => (uppercase ? 'text-transform: uppercase;' : undefined)}
  ${({ weight }) => (weight ? `font-weight: ${weight};` : undefined)}
`
