import React from 'react'

import { FREE_TEXT } from '../../constants'
import { ComponentFactory, ToElements } from '../../types/ui'

interface ElementsProps {
  Address: unknown
  FreeText: unknown
  Root: unknown
}

type Elements = ToElements<ElementsProps>

export interface OwnProps {
  address: string
}

type Props = OwnProps

const factory: ComponentFactory<Elements, Props> = ({ Address, FreeText, Root }) =>
  function ReusableRow({ address }) {
    return (
      <Root>
        <Address>{address}</Address>
        <FreeText>{FREE_TEXT}</FreeText>
      </Root>
    )
  }

export default factory
