import { addDays, format, isToday, startOfToday } from 'date-fns'
import {
  assign,
  cloneDeep,
  find,
  flatten,
  flow,
  isDate,
  isEqual,
  map,
  range,
  toString,
  upperFirst,
  zipWith,
} from 'lodash/fp'
import { parse as parseUri, serialize as serializeUri } from 'uri-js'

import { areAllPresent, isDefined, isNotNull, isPresent, nullifyUndefined } from '../../helpers'
import { Asset, Format, Optional, Transform, Validation } from '../../types/core'
import { DropdownOption } from '../../types/ui'
import { pathToAsset } from '../../utils/assets'
import { Address } from '../address/types'
import { retrievePostcode } from '../advert/helpers'
import { AdvertAsset, AdvertBase, AdvertBaseResource, LoadSize as Size } from '../advert/types'
import { formatFixed, formatOpenTimeSlot, formatRange } from '../time-slots/helpers'
import { OpenTimeSlot, TimeSlot, TimeSlotFixed, TimeSlotFixedName, TimeSlots, TimeSlotValue } from '../time-slots/types'
import { Data } from './state'
import {
  CarProps,
  CarPropsWithoutIcon,
  DateTimeSlotPayload,
  EditableAdvert,
  EditableNewAdvert,
  NewAdvertAddress,
  NewAdvertBase,
  NewAdvertCollectionPoint,
  NewAdvertDetails,
  NewAdvertFavouriteCollector,
  NewAdvertFixedTimeSlot,
  NullifiedDateTimeSlotPayload,
  NullifiedTimeSlotPayload,
  PublishListingParams,
  PublishListingPayload,
  TimeSlotPayload,
  TshirtSize,
  ValidNewAdvertCollectionPoint,
  ValidNewAdvertDetails,
  ValidNewAdvertFixedTimeSlot,
} from './types'

const CAR_PROPS: CarPropsWithoutIcon[] = [
  {
    size: 'small',
    description: 'One item',
    title: 'Single',
  },
  {
    size: 'medium',
    description: 'Car load',
    title: 'Small',
  },
  {
    size: 'large',
    description: 'Small van',
    title: 'Medium',
  },
  {
    size: 'extralarge',
    description: 'Large van',
    title: 'Large',
  },
]

const buildIconProps = <T>(Icon: T) => ({ Icon })

export const buildCarProps = <T>(icons: T[]): CarProps<T>[] =>
  zipWith<CarPropsWithoutIcon, { Icon: T }, CarProps<T>>(assign)(cloneDeep(CAR_PROPS), map(buildIconProps)(icons))

export const PUBLISH_LISTING = 'publish_listing'

const MAXIMUM_COLLECTION_DAYS = 7

const toCollectionDate: Transform<Date, DropdownOption<Date>> = value => ({ label: format(value, 'eee dd MMM'), value })

export const getCollectionDates = flow(startOfToday, today =>
  map<number, DropdownOption<Date>>(i => toCollectionDate(addDays(today, i)))(range(0, MAXIMUM_COLLECTION_DAYS)),
)

export const openTimeSlotDropdownOptions = map<OpenTimeSlot, DropdownOption<OpenTimeSlot>>(value => ({
  label: formatOpenTimeSlot(value),
  value,
}))

const fixedToCollectionTimeSlot: Transform<TimeSlotFixed, DropdownOption<TimeSlot>> = fixed => ({
  label: formatFixed(fixed),
  value: fixed.range,
})

const rangeToCollectionTimeSlot: Transform<TimeSlot, DropdownOption<TimeSlot>> = value => ({
  label: formatRange(value),
  value,
})

export const getCollectionTimeSlots: Transform<TimeSlots, Transform<Date, DropdownOption<TimeSlot>[]>> = ({
  today,
  future,
}) =>
  flow(
    isToday,
    isDateToday => (isDateToday ? today : future),
    ({ fixed, ranges }) => [...map(fixedToCollectionTimeSlot)(fixed), ...map(rangeToCollectionTimeSlot)(ranges)],
  )

export const findTimeSlotFixedName: Transform<TimeSlots, Transform<DateTimeSlotPayload, Optional<TimeSlotFixedName>>> =
  ({ today, future }) =>
  ({ date, timeSlot }) =>
    flow(
      isToday,
      isDateToday => (isDateToday ? today : future),
      ({ fixed }) => find<TimeSlotFixed>(({ range }) => isEqual(range, timeSlot))(fixed)?.name,
    )(date)

export const fixedTimeSlotToTimeSlot: Transform<TimeSlots, Transform<NewAdvertFixedTimeSlot, Optional<TimeSlot>>> =
  ({ today, future }) =>
  ({ timeSlot }) =>
    timeSlot
      ? find<TimeSlotFixed>({ name: timeSlot })(
          flatten(map<TimeSlotValue, TimeSlotFixed[]>(i => i.fixed)([today, future])),
        )?.range
      : undefined

export const isValidNewAdvertCollectionPoint = (
  collectionPoint: NewAdvertCollectionPoint,
  easyAccessVariant: boolean,
): collectionPoint is ValidNewAdvertCollectionPoint =>
  isNotNull(collectionPoint.easyAccess) &&
  (collectionPoint.easyAccess || easyAccessVariant || isPresent(collectionPoint.easyAccessReason))

export const isValidNewAdvertFixedTimeSlot = (
  fixedTimeSlot: NewAdvertFixedTimeSlot,
): fixedTimeSlot is ValidNewAdvertFixedTimeSlot => isNotNull(fixedTimeSlot.date) && isNotNull(fixedTimeSlot.timeSlot)

export const isNullifiedDateTimeSlotPayload = (
  payload: NullifiedTimeSlotPayload,
): payload is NullifiedDateTimeSlotPayload => isDefined((payload as NullifiedDateTimeSlotPayload).timeSlot)

export const isDateTimeSlotPayload = (payload: TimeSlotPayload): payload is DateTimeSlotPayload => isDate(payload.date)

export const isValidDateTimeSlotPayload = (payload: NullifiedDateTimeSlotPayload): payload is DateTimeSlotPayload =>
  isNotNull(payload.date) && isNotNull(payload.timeSlot)

export const isValidTimeSlotPayload = (payload: NullifiedTimeSlotPayload): payload is TimeSlotPayload =>
  isNullifiedDateTimeSlotPayload(payload) ? isValidDateTimeSlotPayload(payload) : isDefined(payload.date)

export const isValidNewAdvertDetails = (details: NewAdvertDetails): details is ValidNewAdvertDetails => {
  const { builderWaste, residential, size } = details

  return isNotNull(builderWaste) && isNotNull(residential) && isNotNull(size)
}

export const isValidNewAdvertAddress: Transform<NewAdvertAddress, boolean> = ({ address1, city, postcode }) =>
  areAllPresent(address1, city, postcode)

export const fromGetAddressIo: Transform<Address, NewAdvertAddress> = ({
  line_1: address1,
  line_2: address2,
  line_3: address3,
  postcode,
  town_or_city: city,
}) => ({ address1, address2, address3, city, postcode })

const fromAdvertAsset: Transform<AdvertAsset, Asset> = ({ fullUrl }) => pathToAsset(fullUrl)

export const mapFromAdvertAsset = map(fromAdvertAsset)

export const assetsFromAdvertAssets = (images: AdvertAsset[], videos: AdvertAsset[] = []) =>
  mapFromAdvertAsset([...images, ...videos])

export const advertDetailsToEditableAdvert: Transform<AdvertBase, EditableNewAdvert> = ({
  locationDetails: { address },
  junkDetails: {
    builderWaste,
    easyAccess,
    easyAccessReason,
    isLightweight,
    isReusable,
    isReuseOnly,
    residential,
    size,
  },
  id,
  metaDetails: { reference },
  publicDetails: { images, price, summary, title, videos },
}) => ({
  address: {
    ...address,
    address1: toString(address.address1),
    address2: toString(address.address2),
    address3: toString(address.address3),
    postcode: retrievePostcode(address),
  },
  assets: assetsFromAdvertAssets(images, videos),
  collectionPoint: {
    easyAccess,
    easyAccessReason: toString(easyAccessReason),
  },
  details: {
    builderWaste,
    isLightweight,
    residential,
    size,
  },
  fixedTimeSlot: null,
  id,
  isReuseOnly: nullifyUndefined(isReuseOnly),
  manualAddressEntry: true,
  partnerDetails: null,
  price: nullifyUndefined(price?.amount),
  reference,
  reusable: isReusable,
  selectedCollectors: [],
  suggestedPriceRange: null,
  summary: toString(summary),
  timeSlot: null,
  title,
})

export const isEditableAdvert = (model: NewAdvertBase): model is EditableAdvert => {
  const { id, reference } = model as EditableAdvert

  return isDefined(id) && isDefined(reference)
}

export const editableAdvertToData: Transform<EditableNewAdvert, Data> = ({
  address,
  collectionPoint,
  details,
  reference,
  ...rest
}) => ({
  ...rest,
  ...address,
  addressId: null,
  advertReference: reference,
  ...collectionPoint,
  confirmed: true,
  ...details,
  manualAddressEntry: false,
  selectedDate: null,
  selectedTimeSlot: null,
  url: null,
})

const toTshirtSize: Record<Size, TshirtSize> = {
  small: 'S',
  medium: 'M',
  large: 'L',
  extralarge: 'XL',
}

export const toPublishListingParams: Transform<
  PublishListingPayload,
  Transform<AdvertBaseResource, PublishListingParams>
> =
  ({
    customerRef,
    suggestedPriceRange: {
      start: { amount: min },
      end: { amount: max },
    },
  }) =>
  ({ junkDetails: { isTrade, size }, metaDetails: { reference }, publicDetails: { price, title } }) => ({
    advertRef: reference,
    advertTitle: title,
    min,
    max,
    trade: isTrade ? 'trade' : 'non-trade',
    size: size ? toTshirtSize[size] : 'S',
    customerRef,
    ...price,
  })

export function ensureScheme(scheme: string) {
  return function validatePath(path: string): string {
    return parseUri(path).scheme ? path : serializeUri({ path, scheme })
  }
}

export const ensureFileScheme = ensureScheme('file')

export const bookSelectedCollector: Format<NewAdvertFavouriteCollector> = ({ name }) => `Book ${upperFirst(name)}`

export const hasHazardousWasteType: Validation<Error> = ({ message }) => {
  try {
    const { type } = JSON.parse(message)

    return type === 'hazardouswaste'
  } catch {
    return false
  }
}
