import { styled } from 'styled'
import { device } from 'utils/css'

import { Props } from './Text'

export default styled.h2<Props>`
  color: ${({ color = 'contrast0', theme: { colors } }) => colors[color]};
  font-size: ${({ size = 1 }) => size}em;
  font-weight: normal;
  margin: 1.5em 0;

  @media ${device.mobile} {
    padding: 0 1rem;
  }
`
