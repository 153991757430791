import { NoOp } from '@lovejunk/core'
import React, { FC } from 'react'
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md'

import IconButton from './IconButton'

interface Props {
  onPress: NoOp
  selected: boolean
}

const RadioButton: FC<Props> = ({ onPress, selected }) => (
  <IconButton
    ReactIcon={selected ? MdRadioButtonChecked : MdRadioButtonUnchecked}
    color="black"
    onPress={onPress}
    size={2}
  />
)

export default RadioButton
