import { flow, isEqual } from 'lodash/fp'

import { isDefined } from '../../helpers'
import { Validation } from '../../types'
import { AttachmentPayload, AttachmentType, BookNowPayload, ShowBookNowPayload } from './types'

export const isAttachmentPayload = flow<[AttachmentType], Validation<AttachmentType>, Validation<AttachmentPayload>>(
  isEqual,
  equals =>
    ({ type }) =>
      equals(type),
)

export const showBookNow = (payload: ShowBookNowPayload): payload is BookNowPayload => isDefined(payload.favouriteId)
