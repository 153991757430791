import { flow } from 'lodash/fp'

import Reducer from '../../types/reducer'
import {
  advertDetailsLens as lens,
  advertUrlLens,
  backToLens,
  collectionAgreedLens,
  confirmReusableCollectionIntentLens,
  lastOpenedAdvertLens,
  setAdvertNeedsFetchLens,
} from './lens'
import marshaller from './marshaller'
import State, { advertDefaultState as defaultState } from './state'
import {
  CONFIRM_REUSABLE_COLLECTION_INTENT,
  FETCH_ADVERT_SUCCESS,
  SET_ADVERT_NEEDS_FETCH,
  SET_ADVERT_URL,
  SET_BACK_TO,
  SET_COLLECTION_AGREED_PAYLOAD,
} from './types/actions'

const reducer: Reducer<State> = (state = defaultState, action) => {
  switch (action.type) {
    case CONFIRM_REUSABLE_COLLECTION_INTENT:
      return confirmReusableCollectionIntentLens(state).set(action.payload)
    case FETCH_ADVERT_SUCCESS:
      return flow(
        lens,
        l => l.set(action.payload),
        lastOpenedAdvertLens,
        l => l.set(marshaller.unmarshal(action.payload.body)),
      )(state)
    case SET_ADVERT_NEEDS_FETCH:
      return setAdvertNeedsFetchLens(state)(action.payload)
    case SET_ADVERT_URL:
      return advertUrlLens(state).set(action.payload)
    case SET_BACK_TO:
      return backToLens(state).set(action.payload)
    case SET_COLLECTION_AGREED_PAYLOAD:
      return collectionAgreedLens(state).set(action.payload)
    default:
      return state
  }
}

export default reducer
