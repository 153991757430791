import { toUpper } from 'lodash/fp'
import React from 'react'

import { CollectorProfile } from '../../entities/collector-profile/types'
import { Color } from '../../theme'
import { Format } from '../../types/core'
import { ComponentFactory, ToElements } from '../../types/ui'
import { createImgixParams, formatRating } from '../../utils'
import { Props as AvatarProps } from '../SupplierInfo/UserCard/Avatar'

type Props = CollectorProfile

interface RatingProps {
  color: Color
  rating: number
}

export interface ElementsProps {
  Avatar: AvatarProps
  AvatarContainer: unknown
  Check: unknown
  CheckContainer: unknown
  CheckText: unknown
  Collections: unknown
  Content: unknown
  HumanizedRating: unknown
  Name: unknown
  Rating: RatingProps
  Root: unknown
}

type Elements = ToElements<ElementsProps>

const humanizeRating: Format<number> = rating => (rating < 4.5 ? 'Great' : 'Excellent')
const formatCollections: Format<number> = collections =>
  collections > 1 ? `, ${collections} collections` : ' (new collector)'

const imgixParams = createImgixParams(120)

const factory: ComponentFactory<Elements, Props> = ({
  Avatar,
  AvatarContainer,
  Check,
  CheckContainer,
  CheckText,
  Collections,
  Content,
  HumanizedRating,
  Name,
  Rating,
  Root,
}) =>
  function Card({ averageRating: rating, numberOfCollections: collections, name, selfieUrl, ...props }) {
    return (
      <Root {...props}>
        <AvatarContainer>
          <Avatar shadow={false} size={1.5} src={imgixParams(selfieUrl)} />
        </AvatarContainer>
        <Content>
          <Name>{toUpper(name)}</Name>
          <Rating color="tertiary" rating={rating} />
          <Collections>
            <HumanizedRating>{humanizeRating(rating)}</HumanizedRating> {formatRating(rating, 1)} stars
            {formatCollections(collections)}
          </Collections>
          <CheckContainer>
            <Check />
            <CheckText>licensed waste carrier</CheckText>
          </CheckContainer>
          <CheckContainer>
            <Check />
            <CheckText>public liability insurance</CheckText>
          </CheckContainer>
        </Content>
      </Root>
    )
  }

export default factory
