import { isDefined, NoOp, PRIVACY_URL, TERMS_URL } from '@lovejunk/core'
import React, { FC } from 'react'
import { styled } from 'styled'
import { withError, withSpacing } from 'utils/css'

import Column from './containers/Column'
import ExternalLink from './ExternalLink'
import FormFieldError from './FormFieldError'
import CheckBox from './input/CheckBox'

interface Props {
  isChecked: boolean
  onPress: NoOp

  error?: string
}

const TermsAndConditions: FC<Props> = ({ error, isChecked, onPress }) => (
  <Root hasError={isDefined(error)}>
    <CheckBox checked={isChecked} onPress={onPress}>
      I agree to your <ExternalLink href={TERMS_URL}>Terms of Service</ExternalLink> and{' '}
      <ExternalLink href={PRIVACY_URL}>Privacy Policy</ExternalLink>
    </CheckBox>
    {isDefined(error) ? <FormFieldError>{error}</FormFieldError> : null}
  </Root>
)

const Root = styled(Column)`
  ${withSpacing(0.5, true)}
  ${withError}
`

export default TermsAndConditions
