import {
  Dispatch,
  toggleButtonBackgroundColor,
  toggleButtonBorderBottomColor,
  toggleButtonBorderColor,
  toggleButtonFontColor,
  toggleButtonFontWeight,
  ToggleButtonProps,
} from '@lovejunk/core'
import { noop } from 'lodash/fp'
import React, { FC } from 'react'
import { styled, ThemeProps } from 'styled'
import { withTheme } from 'styled-components'

import TextButton from '../button/TextButton'

export interface Props extends ToggleButtonProps {
  onValueChange: Dispatch<boolean>
}

const onPress = (onValueChange: Props['onValueChange']) => (state: boolean) => () => onValueChange(state)

const Button: FC<Props & ThemeProps> = ({ disabled, onValueChange, state, error, theme: { colors }, value }) => {
  const toggleButtonStyleProps: ToggleButtonProps = { disabled, error, state, value }

  return (
    <Root
      disabled={disabled}
      hoverColor="selected"
      onPress={disabled ? noop : onPress(onValueChange)(state)}
      shapeStyle={{
        borderTopWidth: 1,
        borderRightWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: Number(state),
        borderStyle: 'solid',
        borderRadius: state ? '6px 0 0 6px' : '0 6px 6px 0',
        borderColor: colors[toggleButtonBorderColor(toggleButtonStyleProps)],
        borderBottomColor: colors[toggleButtonBorderBottomColor(toggleButtonStyleProps)],
        backgroundColor: colors[toggleButtonBackgroundColor(toggleButtonStyleProps)],
        padding: '1em',
        transition: '0.1s background-color linear',
      }}
      color={toggleButtonFontColor(toggleButtonStyleProps)}
      weight={toggleButtonFontWeight(toggleButtonStyleProps)}
    >
      {state ? 'Yes' : 'No'}
    </Root>
  )
}

const Root = styled(TextButton)`
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
  min-width: 4.25em;
`

export default withTheme(Button)
