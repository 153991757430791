import { Action } from 'redux'

import { FailureAction, Nullable, PayloadAction, Route } from '../../../types/core'
import {
  AdvertId,
  AdvertPayloadWithRef,
  AdvertResponse,
  CollectionAgreedPayload,
  CollectJunkPayload,
  ConfirmReusableCollectionPayload,
  FetchWtnResponse,
} from '.'

export const FETCH_ADVERT_START = 'FETCH_ADVERT_START'
export const FETCH_ADVERT_SUCCESS = 'FETCH_ADVERT_SUCCESS'
export const FETCH_ADVERT_FAILURE = 'FETCH_ADVERT_FAILURE'

export type FetchAdvertStart = Action<typeof FETCH_ADVERT_START>
export type FetchAdvertSuccess = PayloadAction<typeof FETCH_ADVERT_SUCCESS, AdvertResponse>
export type FetchAdvertFailure = FailureAction<typeof FETCH_ADVERT_FAILURE>

export type FetchAdvertResult = FetchAdvertSuccess | FetchAdvertFailure
export type FetchAdvert = FetchAdvertStart | FetchAdvertResult

export const COLLECT_JUNK_FLOW_START = 'COLLECT_JUNK_FLOW_START'
export const COLLECT_JUNK_FLOW_SUCCESS = 'COLLECT_JUNK_FLOW_SUCCESS'
export const COLLECT_JUNK_FLOW_FAILURE = 'COLLECT_JUNK_FLOW_FAILURE'
export const COLLECT_JUNK_START = 'COLLECT_JUNK_START'
export const COLLECT_JUNK_SUCCESS = 'COLLECT_JUNK_SUCCESS'
export const COLLECT_JUNK_FAILURE = 'COLLECT_JUNK_FAILURE'

export type CollectJunkFlowStart = PayloadAction<typeof COLLECT_JUNK_FLOW_START, CollectJunkPayload>
export type CollectJunkFlowSuccess = Action<typeof COLLECT_JUNK_FLOW_SUCCESS>
export type CollectJunkFlowFailure = FailureAction<typeof COLLECT_JUNK_FLOW_FAILURE>
export type CollectJunkStart = Action<typeof COLLECT_JUNK_START>
export type CollectJunkSuccess = Action<typeof COLLECT_JUNK_SUCCESS>
export type CollectJunkFailure = FailureAction<typeof COLLECT_JUNK_FAILURE>

export type CollectJunkFlowResult = CollectJunkFlowSuccess | CollectJunkFlowFailure
export type CollectJunkFlow = CollectJunkFlowStart | CollectJunkFlowResult
export type CollectJunkResult = CollectJunkSuccess | CollectJunkFailure
export type CollectJunk = CollectJunkStart | CollectJunkResult

export const COLLECT_FOR_FREE_FLOW_START = 'COLLECT_FOR_FREE_FLOW_START'
export const COLLECT_FOR_FREE_FLOW_SUCCESS = 'COLLECT_FOR_FREE_FLOW_SUCCESS'
export const COLLECT_FOR_FREE_FLOW_FAILURE = 'COLLECT_FOR_FREE_FLOW_FAILURE'
export const COLLECT_FOR_FREE_START = 'COLLECT_FOR_FREE_START'
export const COLLECT_FOR_FREE_SUCCESS = 'COLLECT_FOR_FREE_SUCCESS'
export const COLLECT_FOR_FREE_FAILURE = 'COLLECT_FOR_FREE_FAILURE'

export type CollectForFreeFlowStart = PayloadAction<typeof COLLECT_FOR_FREE_FLOW_START, CollectJunkPayload>
export type CollectForFreeFlowSuccess = Action<typeof COLLECT_FOR_FREE_FLOW_SUCCESS>
export type CollectForFreeFlowFailure = FailureAction<typeof COLLECT_FOR_FREE_FLOW_FAILURE>
export type CollectForFreeStart = Action<typeof COLLECT_FOR_FREE_START>
export type CollectForFreeSuccess = Action<typeof COLLECT_FOR_FREE_SUCCESS>
export type CollectForFreeFailure = FailureAction<typeof COLLECT_FOR_FREE_FAILURE>

export type CollectForFreeFlowResult = CollectForFreeFlowSuccess | CollectForFreeFlowFailure
export type CollectForFreeFlow = CollectForFreeFlowStart | CollectForFreeFlowResult
export type CollectForFreeResult = CollectForFreeSuccess | CollectForFreeFailure
export type CollectForFree = CollectForFreeStart | CollectForFreeResult

export const SET_COLLECTION_AGREED_PAYLOAD = 'SET_COLLECTION_AGREED_PAYLOAD'
export type SetCollectionAgreedPayload = PayloadAction<typeof SET_COLLECTION_AGREED_PAYLOAD, CollectionAgreedPayload>

export const CONFIRM_REUSABLE_COLLECTION_INTENT = 'CONFIRM_REUSABLE_COLLECTION_INTENT'

export type ConfirmReusableCollectionIntent = PayloadAction<
  typeof CONFIRM_REUSABLE_COLLECTION_INTENT,
  Nullable<ConfirmReusableCollectionPayload>
>

export const CONFIRM_REUSABLE_COLLECTION_START = 'CONFIRM_REUSABLE_COLLECTION_START'
export const CONFIRM_REUSABLE_COLLECTION_SUCCESS = 'CONFIRM_REUSABLE_COLLECTION_SUCCESS'
export const CONFIRM_REUSABLE_COLLECTION_FAILURE = 'CONFIRM_REUSABLE_COLLECTION_FAILURE'

export type ConfirmReusableCollectionStart = Action<typeof CONFIRM_REUSABLE_COLLECTION_START>
export type ConfirmReusableCollectionSuccess = Action<typeof CONFIRM_REUSABLE_COLLECTION_SUCCESS>
export type ConfirmReusableCollectionFailure = FailureAction<typeof CONFIRM_REUSABLE_COLLECTION_FAILURE>
export type ConfirmReusableCollectionResult = ConfirmReusableCollectionSuccess | ConfirmReusableCollectionFailure
export type ConfirmReusableCollection = ConfirmReusableCollectionStart | ConfirmReusableCollectionResult

export const SET_ADVERT_URL = 'SET_ADVERT_URL'

export type SetAdvertUrl = PayloadAction<typeof SET_ADVERT_URL, AdvertPayloadWithRef>

export const SET_ADVERT_NEEDS_FETCH = 'SET_ADVERT_NEEDS_FETCH'

export type SetAdvertNeedsFetch = PayloadAction<typeof SET_ADVERT_NEEDS_FETCH, AdvertId>

export const SET_BACK_TO = 'SET_BACK_TO'

export type SetBackTo = PayloadAction<typeof SET_BACK_TO, Nullable<Route>>

export const FETCH_WTN_START = 'FETCH_WTN_START'
export const FETCH_WTN_SUCCESS = 'FETCH_WTN_SUCCESS'
export const FETCH_WTN_FAILURE = 'FETCH_WTN_FAILURE'

export type FetchWtnStart = Action<typeof FETCH_WTN_START>
export type FetchWtnSuccess = PayloadAction<typeof FETCH_WTN_SUCCESS, FetchWtnResponse>
export type FetchWtnFailure = FailureAction<typeof FETCH_WTN_FAILURE>

export type FetchWtnResult = FetchWtnSuccess | FetchWtnFailure
export type FetchWtn = FetchWtnStart | FetchWtnResult

export type AdvertAction =
  | CollectForFreeFlow
  | CollectForFree
  | CollectJunk
  | CollectJunkFlow
  | ConfirmReusableCollection
  | ConfirmReusableCollectionIntent
  | FetchAdvert
  | FetchWtn
  | SetAdvertNeedsFetch
  | SetAdvertUrl
  | SetBackTo
  | SetCollectionAgreedPayload
