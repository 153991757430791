import { map, property } from 'lodash/fp'
import React, { useCallback, useMemo } from 'react'

import { FAVOURITE_COLLECTOR_SELECT_TEXT } from '../../constants'
import {
  NewAdvertFavouriteCollector as FavouriteCollector,
  NewAdvertFavouriteCollectors as FavouriteCollectors,
} from '../../entities/new-advert/types'
import { Dispatch, FavouriteId, NoOp, Transform } from '../../types/core'
import { ComponentFactory, DropdownOption, ToElements } from '../../types/ui'

interface DispatchProps {
  setSelectedCollectors: Dispatch<FavouriteId[]>
}

export interface StateProps {
  selectedCollectors: FavouriteId[]
}

export interface OwnProps {
  favouriteCollectors: FavouriteCollectors
}

type Props = DispatchProps & OwnProps & StateProps

type Option = DropdownOption<FavouriteId>

const toFavouriteCollectorOption: Transform<FavouriteCollector, Option> = ({ id: value, name, postcodeDistrict }) => ({
  label: `${name}, ${postcodeDistrict}`,
  value,
})

const mapToFavouriteCollectorOption = map(toFavouriteCollectorOption)

interface SelectMultipleProps {
  data: Option[]
  onChange: Dispatch<FavouriteId[]>
  onClear: NoOp
  onSelectAll: NoOp
  value: FavouriteId[]
}

export interface ElementsProps {
  Root: unknown
  SelectMultiple: SelectMultipleProps
  Title: unknown
}

type Elements = ToElements<ElementsProps>

const factory: ComponentFactory<Elements, Props> =
  ({ Root, SelectMultiple, Title }) =>
  ({ favouriteCollectors, selectedCollectors, setSelectedCollectors }) => {
    const data = useMemo<Option[]>(() => mapToFavouriteCollectorOption(favouriteCollectors), [favouriteCollectors])
    const onSelectAll = useCallback(
      () => setSelectedCollectors(map(property('id'))(favouriteCollectors)),
      [favouriteCollectors, setSelectedCollectors],
    )
    const onClear = useCallback(() => setSelectedCollectors([]), [setSelectedCollectors])

    return (
      <Root>
        <Title>{FAVOURITE_COLLECTOR_SELECT_TEXT}</Title>
        <SelectMultiple
          data={data}
          onChange={setSelectedCollectors}
          onClear={onClear}
          onSelectAll={onSelectAll}
          value={selectedCollectors}
        />
      </Root>
    )
  }

export default factory
