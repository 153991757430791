import { flow, isNull, map, unionBy } from 'lodash/fp'

import { lensFactory } from '../../helpers'
import RootState from '../../state'
import { GetLens, Lens, Optional, SetLens } from '../../types/core'
import marshaller from './marshaller'
import State from './state'
import { AdvertItem, AdvertItemResource, Filter } from './types'

const lens = lensFactory<State>()

export const filterLens = lens('filter')
export const isOutsideSupportedAreaLens = lens('isOutsideSupportedArea')
export const loadingLens = lens('loading')
export const metaLens = lens('meta')
export const needsFetchLens = lens('needsFetch')

export const advertsLens: Lens<State, AdvertItem[], AdvertItemResource[]> = state => ({
  get: () => state.items,
  set: resources => ({
    ...state,
    items: map(marshaller.unmarshal)(resources),
  }),
})

export const advertsCurrentPageLens: Lens<State, number> = state => ({
  get: () => state.pagination.currentPage,
  set: currentPage => ({ ...state, pagination: { ...state.pagination, currentPage } }),
})

export const mergeAdvertLens: SetLens<State, AdvertItemResource[]> = state => resources => ({
  ...state,
  items: unionBy<AdvertItem>('reference')(state.items)(map(marshaller.unmarshal)(resources)),
})

export const getNextPageUrlLens: GetLens<State, Optional<string>> =
  ({ meta }) =>
  () =>
    isNull(meta) ? undefined : meta.next_url

const filterSimpleLens = lensFactory<State['filter']>()

const filterKeyLens =
  <K extends keyof Filter>(key: K): Lens<State, Filter[K]> =>
  state => {
    const { get, set } = filterSimpleLens(key)(state.filter)

    return {
      get,
      set: flow(set, filter => ({ ...state, filter })),
    }
  }

export const builderWasteFilterLens = filterKeyLens('builderWaste')
export const collectedFilterLens = filterKeyLens('collected')
export const distanceLens = filterKeyLens('distance')
export const locationCentreLens = filterKeyLens('locationCentre')
export const locationLens = filterKeyLens('location')
export const matchedFilterLens = filterKeyLens('matched')
export const pageSizeLens = filterKeyLens('pageSize')
export const residentialFilterLens = filterKeyLens('residential')
export const reusableFilterLens = filterKeyLens('reusable')
export const orderByLens = filterKeyLens('orderBy')
export const showAllLens = filterKeyLens('showAll')
export const tagFilterLens = filterKeyLens('currentTag')

export const advertsRootLens: GetLens<RootState, State> = state => () => state.adverts
