import { configureLogin, LoginConfig } from '@lovejunk/core'

import { dispatch } from '../core/store'
import { navigate } from '../entities/app'
import NavigationPath from '../navigation/paths'
import { setUserId } from '../utils/firebase/analytics'

export const loginConfig: LoginConfig = {
  resetToHome: () => dispatch(navigate({ to: NavigationPath.Home })),
  setUserId,
}

export default () => configureLogin(loginConfig)
