import { action, failureAction, payloadAction } from '../../action-factory'
import { setApiConfigUrl } from '../../api'
import apiAction, { ActionBundle } from '../../api-action'
import { API_URL_KEY } from '../../constants'
import {
  ActionFactory,
  Dispatch,
  FailureActionFactory,
  Get,
  Nullable,
  PayloadActionFactory,
  Transform,
} from '../../types/core'
import { StatelessPayloadThunk, StatelessThunk } from '../../types/thunk'
import { getConfigurationApi } from './api'
import {
  CLEAR_CONFIGURATION,
  ClearConfiguration,
  Configuration,
  DISMISS_ERROR,
  DismissError,
  GET_CONFIGURATION_FAILURE,
  GET_CONFIGURATION_START,
  GET_CONFIGURATION_SUCCESS,
  GetConfigurationFailure,
  GetConfigurationResult,
  GetConfigurationStart,
  GetConfigurationSuccess,
  SET_API_URL,
  SET_API_URL_FETCHED,
  SET_DEVICE_ID,
  SET_SELECTED_ACTIVITY,
  SetApiUrl,
  SetApiUrlFetched,
  SetApiUrlPayload,
  SetDeviceId,
  SetSelectedActivity,
  UNSUPPORTED_POSTCODE,
  UnsupportedPostcode,
} from './types'

export const clearConfiguration: ActionFactory<ClearConfiguration> = action(CLEAR_CONFIGURATION)

export const getConfigActionBundle: ActionBundle<
  GetConfigurationStart,
  GetConfigurationSuccess,
  GetConfigurationFailure,
  Configuration
> = [
  action(GET_CONFIGURATION_START),
  payloadAction(GET_CONFIGURATION_SUCCESS),
  failureAction(GET_CONFIGURATION_FAILURE),
]

export const getConfiguration: StatelessThunk<GetConfigurationResult> = () => dispatch =>
  dispatch(apiAction(getConfigActionBundle)(getConfigurationApi()))

export const dismissError: ActionFactory<DismissError> = action(DISMISS_ERROR)

export const setApiUrl: PayloadActionFactory<SetApiUrl, SetApiUrlPayload> = payloadAction(SET_API_URL)
export const setApiUrlFetched: ActionFactory<SetApiUrlFetched> = action(SET_API_URL_FETCHED)

export type ClearStorage = Get<Promise<void>>
export type GetStorageItem = Transform<string, Promise<Nullable<string>>>
export type SetStorageItem = Transform<string, Promise<void>>
export type CreateSetStorageItem = Transform<string, SetStorageItem>

export interface FetchApiUrlPayload {
  defaultUrl: string
  getItem: GetStorageItem
  preserveStore: boolean
}

export interface ChangeApiUrlPayload extends FetchApiUrlPayload {
  clearStorage: ClearStorage
  setItem: SetStorageItem
}

export const fetchApiUrl: StatelessPayloadThunk<FetchApiUrlPayload, SetApiUrlFetched> =
  ({ defaultUrl, getItem, preserveStore }) =>
  dispatch =>
    getItem(API_URL_KEY)
      .then(storedApiUrl => storedApiUrl || defaultUrl)
      .then(url => ({ url, preserveStore }))
      .then(setApiUrl)
      .then(action => dispatch(action))
      .then(({ payload: { url } }) => setApiConfigUrl(url))
      .then(setApiUrlFetched)
      .then(action => dispatch(action))

export const changeApiUrl: StatelessPayloadThunk<ChangeApiUrlPayload, SetApiUrlFetched> =
  ({ clearStorage, setItem, ...fetchApiUrlPayload }) =>
  dispatch =>
    clearStorage()
      .then(() => setItem(API_URL_KEY))
      .then(() => fetchApiUrl(fetchApiUrlPayload))
      .then(action => dispatch(action))

interface OnConfigurationPayload {
  apiUrl: string
  isProduction: boolean
  reset: Dispatch<string>
  stagingUrl: string
}

export const onConfiguration: Transform<
  OnConfigurationPayload,
  Transform<GetConfigurationResult, GetConfigurationResult>
> =
  ({ apiUrl, reset, stagingUrl, isProduction }) =>
  action => {
    if (action.type === GET_CONFIGURATION_FAILURE && apiUrl !== stagingUrl && !isProduction) reset(stagingUrl)

    return action
  }

export const setDeviceId: PayloadActionFactory<SetDeviceId, SetDeviceId['payload']> = payloadAction(SET_DEVICE_ID)
export const unsupportedPostcode: FailureActionFactory<UnsupportedPostcode> = payloadAction(UNSUPPORTED_POSTCODE)
export const setSelectedActivity: PayloadActionFactory<SetSelectedActivity, SetSelectedActivity['payload']> =
  payloadAction(SET_SELECTED_ACTIVITY)
