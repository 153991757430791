import { flow } from 'lodash/fp'

import { falsifyNull } from '../../helpers'
import { Capability, Identity, Transform } from '../../types/core'
import { hasJunkLoverCapability, hasSoleCustomerCapability, hasSupplierCapability } from '../meta/lens'
import { AdvertItemPriceVisibilityPayload, Filter, Order } from './types'

type FilterTransform = Identity<Filter>

export const roleBasedFilter: Transform<Capability[], FilterTransform> = capabilities =>
  flow(
    filter =>
      !hasJunkLoverCapability(capabilities) && filter.reusable ? { ...filter, orderBy: 'status' as Order } : filter,
    filter => {
      if (hasSupplierCapability(capabilities)) {
        const { showAll: originalShowAll, ...filterRest } = filter
        const showAll = falsifyNull(originalShowAll)
        const filterWithShowAll = { ...filterRest, showAll }

        return hasJunkLoverCapability(capabilities) && filter.reusable
          ? { ...filterWithShowAll, disposed: false, withoutCollectorJobs: true }
          : filterWithShowAll
      }

      return filter.reusable ? { ...filter, disposed: false, withoutCollectorJobs: true } : filter
    },
    filter => (hasSupplierCapability(capabilities) ? filter : { ...filter, distance: null }),
    filter => (hasSoleCustomerCapability(capabilities) && !filter.reusable ? { ...filter, matched: true } : filter),
  )

export const isAdvertItemPriceVisible: Transform<AdvertItemPriceVisibilityPayload, boolean> = ({
  isCollector,
  isJunkLover,
  isReusable,
  isReusableFilterOn = false,
  status,
}) => isCollector || !(isReusableFilterOn || status === 'active' || (isReusable && isJunkLover))
