import { capitalizeSentences, ChatFooterElementsProps, chatFooterFactory as factory } from '@lovejunk/core'
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg'
import { useEnterKey } from 'hooks'
import React, { FC, KeyboardEventHandler, useCallback, useEffect, useRef, useState } from 'react'
import { isMacOs } from 'react-device-detect'
import { styled } from 'styled'
import { Styles } from 'types/ui'
import { isMobile } from 'utils/environment'

import Assets from '../AssetsRow/Assets'
import IconButton from '../button/IconButton'
import MainButton from '../button/MainButton'
import Column from '../containers/Column'
import Row from '../containers/Row'
import TextArea from '../input/TextArea'
import AssetButton from './AssetButton'

const optionKey = isMacOs ? 'metaKey' : 'ctrlKey'

const styles: Styles = {
  button: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonShape: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 0.5em',
    minHeight: '4em',
  },
  phoneIcon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '0.5em',
  },
}

const StyledTextArea = styled(TextArea)`
  flex: 1;
`

const TextAreaElement: FC<ChatFooterElementsProps['TextArea']> = ({
  onSubmit,
  placeholder,
  resetHeight,
  setMessage,
  value,
  ...props
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const [textAreaHeight] = useState<number>(4)

  const onInput = useCallback(() => {
    const textarea = textAreaRef.current
    const maxHeight = 150
    if (textarea) {
      const value = textarea.value
      const style = textarea.style

      style.height = 'auto'
      const newHeight = Math.min(textarea.scrollHeight, maxHeight)
      style.height = `${newHeight}px`

      const capitalized = capitalizeSentences(value)
      setMessage(capitalized)
    }
  }, [setMessage])

  useEffect(() => {
    if (resetHeight) {
      const textarea = textAreaRef.current
      if (textarea) {
        textarea.style.height = `${textAreaHeight}em`
      }
    }
  }, [resetHeight, textAreaHeight, setMessage])

  const onSend = useCallback(() => {
    setMessage('')
    onSubmit()
  }, [onSubmit, setMessage])

  const onEnterKey = useCallback<KeyboardEventHandler>(
    event => {
      if (event[optionKey]) onSend()
    },
    [onSend],
  )
  const onKeyDown = useEnterKey(onEnterKey)

  return (
    <StyledTextArea
      {...props}
      ref={textAreaRef}
      height={textAreaHeight}
      onKeyDown={onKeyDown}
      onChange={onInput}
      placeholder={value?.length > 0 ? undefined : placeholder}
      value={value}
      onSubmit={onSend}
    />
  )
}

const ChatFooter = factory({
  AssetButton: styled(AssetButton)<ChatFooterElementsProps['AssetButton']>``,
  Assets: styled(Assets).attrs({ size: 5 })`
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.grey};
    padding: 1em !important;
    overflow-x: auto;
  `,
  Controls: styled(Row)`
    gap: 0.5em;
    padding: 0.5em;
  `,
  PhoneButton: styled(IconButton).attrs({
    size: isMobile ? 1.5 : 2,
    style: styles.phoneIcon,
    SvgIcon: PhoneIcon,
  })<ChatFooterElementsProps['PhoneButton']>``,
  Root: Column,
  SubmitButton: styled(MainButton).attrs({
    style: styles.button,
    shapeStyle: { ...styles.buttonShape, minWidth: isMobile ? '5em' : '6em' },
  })<ChatFooterElementsProps['SubmitButton']>``,
  TextArea: TextAreaElement,
})

export default ChatFooter
