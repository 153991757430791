import { DeviceId, Nullable } from '@lovejunk/core'

interface State {
  deviceId: Nullable<DeviceId>
}

export const defaultState = {
  deviceId: null,
}

export default State
