import { GetLens, lensFactory } from '@lovejunk/core'
import RootState from 'core/state'

import State from './state'

export const customerAdvertRootLens: GetLens<RootState, State> = state => () => state.customerAdvert

const lens = lensFactory<State>()

export const cancelAdvertPayloadLens = lens('cancelAdvertPayload')
export const relistOrCancelPayloadLens = lens('relistOrCancelPayload')

export default customerAdvertRootLens
