import { Configuration, Constants, Endpoints } from '../../types'

const constants: Constants = {
  cancellationReasons: [],
  conditionalCounterOfferDuration: 900,
  enableCaptcha: false,
  junkCollectorRadiusRange: {
    start: {
      value: 5.0,
      unit: 'kilometre',
    },
    end: {
      value: 50.0,
      unit: 'kilometre',
    },
  },
  junkLoverOfferPrice: {
    amount: 10,
    currency: 'GBP',
  },
  reusableOfferDuration: 600,
  maximumVideoDuration: 5000,
}

const endpoints: Endpoints = {
  advertNavigation: 'https://elmer/advert-navigation',
  adverts: 'https://elmer/adverts',
  chatNavigation: 'https://elmer/chat-navigation',
  clientSupport: 'https://elmer/client-support,',
  customers: 'https://elmer/customers',
  firebaseConfiguration: 'https://elmer/firebase-configuration',
  freegleListings: 'https://elmer/freegle-listings',
  galleryVersion: 'https://elmer/gallery-version',
  junkCollectors: 'https://elmer/junk-collectors',
  junkLovers: 'https://elmer/junk-lovers',
  oauthToken: 'https://elmer/oauth-token',
  otp: {
    generation: 'https://elmer/otp/generation',
    signUp: 'https://elmer/otp/sign-up',
    login: 'https://elmer/otp/login',
  },
  passwordSession: 'https://elmer/password-session',
  postcodeCheck: 'https://elmer/postcode-check',
  postcodeValidation: 'https://elmer/postcode-validation',
  priceSuggestion: 'https://elmer/price-suggestion',
  referrals: {
    userReferralCodes: 'https://elmer/referrals/user-referral-codes',
    otpReferrals: 'https://elmer/referrals/otp-referrals',
  },
  stripe: {
    customers: 'https://elmer/stripe/customers',
    paymentMethods: 'https://elmer/stripe/payment-methods',
    setupIntents: 'https://elmer/stripe/setup-intents',
  },
  supportRequests: 'https://elmer/support-requests',
  tags: 'https://elmer/tags',
  timeSlots: 'https://elmer/time-slots',
  tokenStatuses: 'https://elmer/token-statuses',
  tradeEnquiries: 'https://elmer/tradeEnquiries',
  videoUpload: 'https://elmer/video-upload',
  whoami: 'https://elmer/whoami',
  wtnEnquiries: 'https://elmer/wtnEnquiries',
}

const configuration: Configuration = {
  constants,
  endpoints,
}

export default configuration
