import {
  ChatItemContainerProps,
  chatItemFactory as factory,
  ChatItemMessageProps,
  ChatItemRootProps,
  ChatItemSentAtProps,
  Size,
} from '@lovejunk/core'
import { styled, ThemeProps } from 'styled'

import CenteredRow from '../containers/CenteredRow'
import Column from '../containers/Column'
import Spinner from '../Spinner'
import BaseText from '../text/BaseText'
import Attachments from './Attachments'

const BORDER_RADIUS = 1
const ITEM_MARGIN: Size = { width: 10, height: 0.5 }

export default factory({
  Attachments,
  Container: styled(Column)<ChatItemContainerProps>`
    align-items: ${({ isAuthor, isServiceMessage }) =>
      isServiceMessage ? 'center' : isAuthor ? 'flex-end' : 'flex-start'};
  `,
  Message: styled(BaseText)<ChatItemMessageProps>`
    color: ${({ isAuthor, isServiceMessage, theme: { colors } }) =>
      isServiceMessage ? colors.secondary : isAuthor ? colors.white : colors.contrast1};
    font-size: ${({ isServiceMessage }) => (isServiceMessage ? '0.875' : '1')}em;
    text-align: ${({ isServiceMessage }) => (isServiceMessage ? 'center' : 'left')};
  `,
  Pending: styled(Spinner).attrs<ThemeProps>(({ theme: { colors } }) => ({ color: colors.lightGrey }))`
    margin-right: 0.5em;
  `,
  Root: styled(CenteredRow)<ChatItemRootProps>`
    align-self: ${({ isAuthor, isServiceMessage }) =>
      isServiceMessage ? 'stretch' : isAuthor ? 'flex-end' : 'flex-start'};
    background-color: ${({ isAuthor, isServiceMessage, theme: { colors } }) =>
      isServiceMessage ? colors.light : isAuthor ? colors.secondary : colors.lightGrey};
    border-radius: ${BORDER_RADIUS}em;
    border-bottom-right-radius: ${({ isAuthor }) => (isAuthor ? 0 : BORDER_RADIUS)}em;
    border-bottom-left-radius: ${({ isAuthor, isServiceMessage }) =>
      isAuthor || isServiceMessage ? BORDER_RADIUS : 0}em;
    justify-content: ${({ isServiceMessage }) => (isServiceMessage ? 'center' : 'space-between')};
    margin: ${ITEM_MARGIN.height}em 0;
    max-width: ${({ isServiceMessage }) => (isServiceMessage ? '100%' : '80%')};
    padding: 0.625em;
  `,
  SentAt: styled(BaseText)<ChatItemSentAtProps>`
    color: ${({ isAuthor, theme: { colors } }) => (isAuthor ? colors.light : colors.contrast2)};
    font-size: 0.75em;
  `,
})
