import { Action } from 'redux'

import { FailureAction, Nullable, PayloadAction } from '../../../types/core'
import { CollectorBankAccount, SignupCollectorDetails, SignupCollectorResponseBody } from '.'

export const SAVE_COLLECTOR_DETAILS = 'SAVE_COLLECTOR_DETAILS'
export const SAVE_COLLECTOR_SELFIE = 'SAVE_COLLECTOR_SELFIE'
export const SAVE_COLLECTOR_BANK_ACCOUNT = 'SAVE_BANK_ACCOUNT'
export const SAVE_ACCOUNT_LINK = 'SAVE_ACCOUNT_LINK'
export const SET_COLLECTOR_SIGNUP_FEEDBACK = 'SET_COLLECTOR_SIGNUP_FEEDBACK'
export const CREATE_COLLECTOR_ACCOUNT = 'CREATE_COLLECTOR_ACCOUNT'
export const CANCEL_COLLECTOR_SIGNUP = 'CANCEL_COLLECTOR_SIGNUP'

export type SaveCollectorDetails = PayloadAction<typeof SAVE_COLLECTOR_DETAILS, SignupCollectorDetails>
export type SaveCollectorSelfie = PayloadAction<typeof SAVE_COLLECTOR_SELFIE, Nullable<string>>
export type SaveCollectorBankAccount = PayloadAction<typeof SAVE_COLLECTOR_BANK_ACCOUNT, CollectorBankAccount>
export type SaveAccountLink = PayloadAction<typeof SAVE_ACCOUNT_LINK, string>
export type SetCollectorSignupFeedback = PayloadAction<typeof SET_COLLECTOR_SIGNUP_FEEDBACK, boolean>
export type CreateCollectorAccount = Action<typeof CREATE_COLLECTOR_ACCOUNT>
export type CancelCollectorSignup = Action<typeof CANCEL_COLLECTOR_SIGNUP>

export const SIGNUP_COLLECTOR_START = 'SIGNUP_COLLECTOR_START'
export const SIGNUP_COLLECTOR_SUCCESS = 'SIGNUP_COLLECTOR_SUCCESS'
export const SIGNUP_COLLECTOR_FAILURE = 'SIGNUP_COLLECTOR_FAILURE'

export type SignupCollectorStart = Action<typeof SIGNUP_COLLECTOR_START>
export type SignupCollectorSuccess = PayloadAction<typeof SIGNUP_COLLECTOR_SUCCESS, SignupCollectorResponseBody>
export type SignupCollectorFailure = FailureAction<typeof SIGNUP_COLLECTOR_FAILURE>

export type SignupCollectorResult = SignupCollectorSuccess | SignupCollectorFailure
export type SignupCollector = SignupCollectorStart | SignupCollectorResult

export type SignupCollectorAction =
  | SignupCollector
  | SaveCollectorDetails
  | SaveCollectorSelfie
  | SaveCollectorBankAccount
  | SaveAccountLink
  | SetCollectorSignupFeedback
  | CreateCollectorAccount
  | CancelCollectorSignup
