import { flow } from 'lodash/fp'

import { get } from '../../api'
import { CompoundApi, SimpleApi } from '../../api-action'
import { responseToBody } from '../../helpers'
import { Transform } from '../../types/core'
import {
  AdvertItemResource,
  CheckGalleryVersionResponse,
  FetchAdvertItemParams,
  FetchAdvertItemResponse,
  FetchAdvertsResponse,
  FilterParams,
  GalleryVersion,
} from './types'

export const galleryVersionApi: Transform<string, CompoundApi<CheckGalleryVersionResponse, GalleryVersion>> = url => [
  get(url),
  flow(responseToBody, body => body.version),
]

export const fetchAdvertItemApi = (
  url: string,
  params: FetchAdvertItemParams,
): CompoundApi<FetchAdvertItemResponse, AdvertItemResource> => [get(url, { params }), responseToBody]

export const fetchAdvertsApi = (url: string, params: FilterParams): SimpleApi<FetchAdvertsResponse> =>
  get(url, { params })

export const fetchNextAdvertsApi: Transform<string, SimpleApi<FetchAdvertsResponse>> = get
