import { chatAttachmentFactory as factory } from '@lovejunk/core'
import { styled } from 'styled'

import Image from './attachments/image/Attachment'
import Video from './attachments/video/Attachment'

export default factory({
  Image,
  Video,
  Root: styled.div`
    padding: 0.25em;
  `,
})
