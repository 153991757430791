import { minutesInHour, secondsInMinute } from 'date-fns/fp'
import { isEmpty, isUndefined } from 'lodash/fp'
import { useCallback, useEffect, useReducer, useState } from 'react'

import {
  FETCH_CHAT_MESSAGES_UPDATES_SUCCESS,
  FetchChatMessagesUpdatesPayload,
  FetchChatMessagesUpdatesResult,
} from '../entities/chat/types'
import { isDefined, isHttpError, isNotFound } from '../helpers'
import { Dispatch, NoOp, Transform } from '../types/core'
import { GenericReducer } from '../types/reducer'

type EmptyMessagesCounterState = number
type EmptyMessagesCounterAction = boolean
type Seconds = number
type OnFetchChatMessagesUpdates = Dispatch<FetchChatMessagesUpdatesResult>

const DEFAULT_INTERVAL: Seconds = 10

const calculateInterval: Transform<EmptyMessagesCounterState, Seconds> = emptyMessagesCounter => {
  // after 2 hours change from 1 minute to 1 hour
  if (emptyMessagesCounter > 120) return secondsInMinute * minutesInHour

  // after 1 minute change from 10 seconds to 1 minute
  if (emptyMessagesCounter > 6) return secondsInMinute

  return DEFAULT_INTERVAL
}
const emptyMessagesCounterReducer: GenericReducer<EmptyMessagesCounterState, EmptyMessagesCounterAction> = (
  state,
  action,
) => (action ? state + 1 : 0)

interface Params {
  fetchChatMessagesUpdates: Dispatch<FetchChatMessagesUpdatesPayload, Promise<FetchChatMessagesUpdatesResult>>
  version: number

  viewThreadUpdatesUrl?: string
}

interface Result {
  onViewThreadUpdatesInterval: NoOp
}

const useDelayedChat: Dispatch<Params, Result> = ({ fetchChatMessagesUpdates, version, viewThreadUpdatesUrl }) => {
  const [emptyMessagesCounter, setEmptyMessagesCounter] = useReducer(emptyMessagesCounterReducer, 0)
  const [viewThreadUpdatesInterval, setViewThreadUpdatesInterval] = useState<Seconds>(DEFAULT_INTERVAL)
  const onFetchChatMessagesUpdates = useCallback<OnFetchChatMessagesUpdates>(action => {
    if (action.type === FETCH_CHAT_MESSAGES_UPDATES_SUCCESS) {
      setEmptyMessagesCounter(isEmpty(action.payload.messages))
    } else if (isHttpError(action.payload) && isNotFound(action.payload)) {
      setEmptyMessagesCounter(true)
    }
  }, [])

  useEffect(() => {
    setViewThreadUpdatesInterval(calculateInterval(emptyMessagesCounter))
  }, [emptyMessagesCounter])

  useEffect(() => {
    if (isDefined(viewThreadUpdatesUrl)) setViewThreadUpdatesInterval(DEFAULT_INTERVAL)
  }, [viewThreadUpdatesUrl])

  const onViewThreadUpdatesInterval = useCallback(() => {
    if (isDefined(viewThreadUpdatesUrl))
      fetchChatMessagesUpdates({ url: viewThreadUpdatesUrl, version }).then(onFetchChatMessagesUpdates)
  }, [fetchChatMessagesUpdates, onFetchChatMessagesUpdates, version, viewThreadUpdatesUrl])

  useEffect(() => {
    if (isUndefined(viewThreadUpdatesUrl)) return

    const interval = setInterval(onViewThreadUpdatesInterval, 1000 * viewThreadUpdatesInterval)

    return () => clearInterval(interval)
  }, [onViewThreadUpdatesInterval, viewThreadUpdatesInterval, viewThreadUpdatesUrl])

  return { onViewThreadUpdatesInterval }
}

export default useDelayedChat
