import { Dispatch } from '@lovejunk/core'
import { KeyboardEvent, KeyboardEventHandler, useCallback } from 'react'

const useEnterKey = <T extends HTMLElement>(callback: Dispatch<KeyboardEvent<T>>) =>
  useCallback<KeyboardEventHandler<T>>(
    event => {
      if (event.key === 'Enter') callback(event)
    },
    [callback],
  )

export default useEnterKey
