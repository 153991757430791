import { UserCardAvatarProps as Props } from '@lovejunk/core'
import { multiply } from 'lodash/fp'
import React, { FC } from 'react'
import { styled } from 'styled'

const getSize = multiply(4.5)

const Avatar: FC<Props> = ({ size = 1, ...props }) => <Root {...props} size={getSize(size)} />

interface RootProps {
  size: number

  shadow?: boolean
  src?: string
}

const Root = styled.img<RootProps>`
  border-radius: 50%;
  border-color: ${({ theme: { colors } }) => colors.greyBorder};
  border-style: ${({ src }) => (src ? 'none' : 'dashed')};
  box-shadow: ${({ shadow = true }) => (shadow ? '0px 0px 10px 5px rgba(0, 0, 0, 0.3)' : 'none')};
  height: ${({ size }) => size}em;
  width: ${({ size }) => size}em;
  object-fit: cover;
`

export default Avatar
