import { get, put } from '../../api'
import { CompoundApi, SimpleApi } from '../../api-action'
import { responseToBody } from '../../helpers'
import { PostcodeCheckBody, PostcodeCheckResponse } from './types'

export const postcodeCheckApi = (
  url: string,
  postcode: string,
): CompoundApi<PostcodeCheckResponse, PostcodeCheckBody> => [
  get(url, { params: { postcode }, timeoutAlert: true }),
  responseToBody,
]

export const postcodeValidationApi = (url: string, postcode: string): SimpleApi =>
  put(url, undefined, { params: { postcode } })
