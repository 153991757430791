import { AdvertRatingElementsProps as ElementsProps, Color, ratingFactory as factory, Transform } from '@lovejunk/core'
import { ReactComponent as StarComponent } from 'assets/icons/star.svg'
import React, { FC } from 'react'
import { styled, ThemeProps } from 'styled'

import CenteredRow from './containers/CenteredRow'
import FormFieldError from './FormFieldError'

const Root = styled(CenteredRow)<ElementsProps['Root']>`
  border: 1px solid ${({ error, theme: { colors } }) => (error ? colors.error : 'transparent')};
  margin-bottom: ${({ error }) => Number(error)}em;
`

interface StarComponentProps {
  $color: Color
  $error: boolean
  $selected: boolean
  $size: number
}

const getColor: Transform<StarComponentProps & ThemeProps, string> = ({
  $color,
  $error,
  $selected,
  theme: { colors },
}) => colors[$error ? 'required' : $selected ? $color : 'grey']

const StyledStarComponent = styled(StarComponent)<StarComponentProps>`
  fill: ${getColor};
  stroke: ${getColor};
  stroke-width: 4;
  margin: 0 ${({ $size }) => 0.125 * $size}em;
  cursor: pointer;
  width: ${({ $size }) => $size}em;
  height: ${({ $size }) => $size}em;
`

const Star: FC<ElementsProps['Star']> = ({ color = 'primary', error, onPress, selected, size = 1 }) => (
  <StyledStarComponent $color={color} $error={error} onClick={onPress} $selected={selected} $size={size} />
)

const RatingComponent = factory({
  Error: FormFieldError,
  Root,
  Star,
})

export default RatingComponent
