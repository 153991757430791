import { Nullable } from '../../types/core'
import { QrCodePresignedUrl, TippingSiteArrangement, ViewTippingSiteArrangements } from './types'

export type AccountState = {
  tippingSiteArrangements: Nullable<ViewTippingSiteArrangements[]>
  fetchingTippingSites: boolean
  tippingSiteArrangement: Nullable<TippingSiteArrangement>
  qrCodeUrl: Nullable<QrCodePresignedUrl>
  fetchingQrCodeUrl: boolean
}

export const accountDefaultState: AccountState = {
  tippingSiteArrangements: null,
  fetchingTippingSites: false,
  tippingSiteArrangement: null,
  qrCodeUrl: null,
  fetchingQrCodeUrl: false,
}

export default AccountState
