import { isUndefined, toString, truncate, unionBy } from 'lodash/fp'
import React, {
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useReducer,
  useState,
} from 'react'
import { Action } from 'redux'

import { action, payloadAction } from '../../action-factory'
import { APP_NAME } from '../../constants'
import { AdvertReference } from '../../entities/advert/types'
import {
  CollectorProfile as Model,
  CollectorReview,
  CollectorReviews,
  GET_COLLECTOR_PROFILE_SUCCESS,
  GET_COLLECTOR_REVIEWS_SUCCESS,
  GetCollectorProfileResult,
  GetCollectorReviewsPayload,
  GetCollectorReviewsResult,
} from '../../entities/collector-profile/types'
import LocationMarshaller from '../../marshallers/location'
import {
  ActionFactory,
  Dispatch,
  LatLng,
  NoOp,
  Nullable,
  Optional,
  PayloadAction,
  PayloadActionFactory,
  Transform,
} from '../../types/core'
import { GenericReducer } from '../../types/reducer'
import { KeyExtractor, RenderItem, ToElements } from '../../types/ui'
import { Props as ReviewItemProps } from './ReviewItem'

const keyExtractor: KeyExtractor<CollectorReview> = ([{ advertReference }]) => advertReference

const initialReviewsState: CollectorReviews = []

const CLEAR_REVIEWS = 'CLEAR_REVIEWS'
const SET_REVIEWS = 'SET_REVIEWS'
const PUSH_REVIEWS = 'PUSH_REVIEWS'

type ClearReviews = Action<typeof CLEAR_REVIEWS>
type SetReviews = PayloadAction<typeof SET_REVIEWS, CollectorReviews>
type PushReviews = PayloadAction<typeof PUSH_REVIEWS, CollectorReviews>
type ReviewsAction = ClearReviews | SetReviews | PushReviews

const clearReviewsAction: ActionFactory<ClearReviews> = action(CLEAR_REVIEWS)
const pushReviewsAction: PayloadActionFactory<PushReviews, PushReviews['payload']> = payloadAction(PUSH_REVIEWS)
const setReviewsAction: PayloadActionFactory<SetReviews, SetReviews['payload']> = payloadAction(SET_REVIEWS)

const reviewsReducer: GenericReducer<CollectorReviews, ReviewsAction> = (state = initialReviewsState, action) => {
  switch (action.type) {
    case CLEAR_REVIEWS:
      return initialReviewsState
    case SET_REVIEWS:
      return action.payload
    case PUSH_REVIEWS:
      return unionBy<CollectorReview>('advertReference')(state)(action.payload)
    default:
      return state
  }
}

interface DispatchProps {
  getCollectorProfile: Transform<string, Promise<GetCollectorProfileResult>>
  getCollectorReviews: Transform<GetCollectorReviewsPayload, Promise<GetCollectorReviewsResult>>
}

interface OwnProps {
  navigateToAdvert: Dispatch<AdvertReference>
  url: string

  pageSize?: number
}

export type Props = DispatchProps & OwnProps

export interface ListProps {
  children: Nullable<ReactElement>
  data: CollectorReviews
  keyExtractor: KeyExtractor<CollectorReview>
  renderItem: RenderItem<CollectorReview>
}

interface ButtonProps {
  children: string
  onPress: NoOp
}

interface MapProps {
  latLng: LatLng

  radius?: number
}

export interface ElementsProps {
  About: unknown
  Card: Model
  Map: MapProps
  Reviews: ListProps
  ReviewsPlaceholder: unknown
  ReviewsPlaceholderItem: unknown
  ReviewItem: ReviewItemProps
  Root: unknown
  SectionTitle: unknown
  ShowFullAbout: ButtonProps

  LoadMore?: ButtonProps
}

type Elements = ToElements<ElementsProps>

export interface Handle {
  onEnterScreen: NoOp
}

const factory = ({
  About,
  Card,
  Reviews,
  ReviewsPlaceholder,
  ReviewsPlaceholderItem,
  LoadMore,
  Map,
  ReviewItem,
  Root,
  SectionTitle,
  ShowFullAbout,
}: Elements) =>
  forwardRef<Handle, Props>(function CollectorProfile(
    { getCollectorProfile, getCollectorReviews, navigateToAdvert, pageSize, url },
    ref,
  ) {
    const [model, setModel] = useState<Model>()
    const [reviews, dispatchReviews] = useReducer(reviewsReducer, initialReviewsState)
    const [nextUrl, setNextUrl] = useState<string>()
    const [reviewsLoaded, setReviewsLoaded] = useState<boolean>(false)
    const { actions, blurb, location, radiusInKm } = model || {}
    const [showFullAbout, setShowFullAbout] = useState<boolean>(false)
    const onShowFullAbout = useCallback(() => setShowFullAbout(true), [])
    const about = useMemo<Optional<string>>(
      () => (showFullAbout ? blurb : truncate({ length: 100 })(toString(blurb))),
      [blurb, showFullAbout],
    )
    const viewCollectorReviewsUrl = actions?.viewCollectorReviews?.url
    const renderReviewItem = useCallback<RenderItem<CollectorReview>>(
      ([item]) => <ReviewItem navigateToAdvert={navigateToAdvert} {...item} />,
      [navigateToAdvert],
    )
    const onEnterScreen = useCallback(() => setShowFullAbout(false), [])

    useImperativeHandle(ref, () => ({ onEnterScreen }))

    const refreshReviews = useCallback(() => {
      if (isUndefined(viewCollectorReviewsUrl)) return

      setReviewsLoaded(false)
      dispatchReviews(clearReviewsAction())

      getCollectorReviews({ pageSize, url: viewCollectorReviewsUrl }).then(action => {
        setReviewsLoaded(true)

        if (action.type === GET_COLLECTOR_REVIEWS_SUCCESS) {
          dispatchReviews(setReviewsAction(action.payload.items))
          setNextUrl(action.payload.nextUrl)
        }
      })
    }, [getCollectorReviews, pageSize, viewCollectorReviewsUrl])

    const loadMore = useCallback<Dispatch<string>>(
      url =>
        getCollectorReviews({ url }).then(action => {
          if (action.type === GET_COLLECTOR_REVIEWS_SUCCESS) {
            dispatchReviews(pushReviewsAction(action.payload.items))
            setNextUrl(action.payload.nextUrl)
          }
        }),
      [getCollectorReviews],
    )

    const createOnLoadMore = useCallback<Transform<string, NoOp>>(url => () => loadMore(url), [loadMore])

    useEffect(() => {
      getCollectorProfile(url).then(action => {
        if (action.type === GET_COLLECTOR_PROFILE_SUCCESS) setModel(action.payload)
      })
    }, [getCollectorProfile, url])

    useEffect(() => {
      refreshReviews()
    }, [refreshReviews])

    return (
      <Root>
        {model ? <Card {...model} /> : null}
        {about ? (
          <>
            <SectionTitle>About</SectionTitle>
            <About>{about}</About>
            {showFullAbout ? null : <ShowFullAbout onPress={onShowFullAbout}>Show more</ShowFullAbout>}
          </>
        ) : null}
        {location && radiusInKm ? (
          <>
            <SectionTitle>Work area</SectionTitle>
            <Map latLng={LocationMarshaller.unmarshal(location)} radius={radiusInKm} />
          </>
        ) : null}
        <SectionTitle>Reviews & ratings</SectionTitle>
        <Reviews data={reviews} keyExtractor={keyExtractor} renderItem={renderReviewItem}>
          {reviewsLoaded ? (
            <ReviewsPlaceholder>
              <ReviewsPlaceholderItem>
                This collector recently joined and has not yet received any customer ratings on {APP_NAME}.
              </ReviewsPlaceholderItem>
              <ReviewsPlaceholderItem>
                Their star rating is based on external reviews and reference checks.
              </ReviewsPlaceholderItem>
              <ReviewsPlaceholderItem>
                Help them build their profile on {APP_NAME}, by hiring them for the job! Everyone deserves a chance.
              </ReviewsPlaceholderItem>
            </ReviewsPlaceholder>
          ) : null}
        </Reviews>
        {LoadMore && nextUrl ? <LoadMore onPress={createOnLoadMore(nextUrl)}>Load more</LoadMore> : null}
      </Root>
    )
  })

export default factory
