import { attempt, flow, isError } from 'lodash/fp'

import { isConflict, isHttpError } from '../../helpers'
import { Identity } from '../../types/core'
import Reducer from '../../types/reducer'
import { COLLECT_FOR_FREE_FLOW_SUCCESS, COLLECT_JUNK_FLOW_SUCCESS } from '../advert/types/actions'
import {
  CANCEL_LOGIN,
  LOGIN_COLLECTOR,
  LOGIN_CUSTOMER,
  LOGIN_JUNK_LOVER,
  LOGIN_OTP_START,
  LOGIN_OTP_SUCCESS,
  LOGOUT_SUCCESS,
} from '../login/types'
import { PUBLISH_ADVERT_FLOW_START, PUBLISH_ADVERT_FLOW_SUCCESS } from '../new-advert/types'
import { CANCEL_COLLECTOR_SIGNUP, CREATE_COLLECTOR_ACCOUNT } from '../signup-collector/types/actions'
import { CANCEL_CUSTOMER_SIGNUP } from '../signup-customer/types'
import { CANCEL_JUNK_LOVER_SIGNUP, CREATE_JUNK_LOVER_ACCOUNT } from '../signup-junk-reuser/types/actions'
import { createCollectorConfig, createCustomerConfig, createJunkReuserConfig, getSignupConfig } from './config'
import { identifierLens, isSigningUpLens, signupDataLens, signupErrorLens, signupRoleConfigLens } from './lens'
import State, { signupDefaultState as defaultState } from './state'
import {
  AUTHORISED,
  CANCEL_SIGNUP,
  CLEAR_SIGNUP_ERROR,
  SET_SIGNUP_DATA,
  SIGNUP_OTP_FAILURE,
  SIGNUP_OTP_START,
  SIGNUP_OTP_SUCCESS,
} from './types'

const onPublishAdvertFlowStart: Identity<State> = state => {
  const config = attempt(getSignupConfig)

  if (isError(config)) return state

  return signupRoleConfigLens(state).set(createCustomerConfig(config.triggerScreens.publish))
}

const reducer: Reducer<State> = (state = defaultState(), action) => {
  switch (action.type) {
    case CREATE_COLLECTOR_ACCOUNT:
    case LOGIN_COLLECTOR:
      return signupRoleConfigLens(state).set(createCollectorConfig())
    case CREATE_JUNK_LOVER_ACCOUNT:
    case LOGIN_JUNK_LOVER:
      return signupRoleConfigLens(state).set(createJunkReuserConfig())
    case LOGIN_CUSTOMER:
      return signupRoleConfigLens(state).set(createCustomerConfig())
    case SET_SIGNUP_DATA:
      return signupDataLens(state).set(action.payload)
    case SIGNUP_OTP_START:
      return flow(
        isSigningUpLens,
        l => l.set(true),
        signupDataLens,
        l => l.set(action.payload),
      )(state)
    case SIGNUP_OTP_SUCCESS:
      return isSigningUpLens(state).set(false)
    case SIGNUP_OTP_FAILURE:
      return flow(
        isSigningUpLens,
        l => l.set(false),
        s => (isHttpError(action.payload) && isConflict(action.payload) ? signupErrorLens(s).set(true) : s),
      )(state)
    case CLEAR_SIGNUP_ERROR:
      return signupErrorLens(state).set(false)
    case PUBLISH_ADVERT_FLOW_START:
      return onPublishAdvertFlowStart(state)
    case LOGIN_OTP_START:
      return identifierLens(state).set(action.payload)
    case LOGIN_OTP_SUCCESS:
      return identifierLens(state).set('')
    case CANCEL_SIGNUP:
      return flow(
        signupRoleConfigLens,
        l => l.set(null),
        identifierLens,
        l => l.set(''),
      )(state)
    case AUTHORISED:
    case CANCEL_COLLECTOR_SIGNUP:
    case CANCEL_CUSTOMER_SIGNUP:
    case CANCEL_JUNK_LOVER_SIGNUP:
    case CANCEL_LOGIN:
    case COLLECT_FOR_FREE_FLOW_SUCCESS:
    case COLLECT_JUNK_FLOW_SUCCESS:
    case LOGOUT_SUCCESS:
    case PUBLISH_ADVERT_FLOW_SUCCESS:
      return signupRoleConfigLens(state).set(null)
    default:
      return state
  }
}

export default reducer
