import { scale } from '../helpers'
import { FileItem, ProcessImage, ProcessImageOptions, Transform } from '../types/core'

interface ResizeImagePayload {
  fileItem: FileItem
  processImageOptions: ProcessImageOptions
}

interface ResizeImageResult {
  content: string
  size: number
}

export type ResizeImage = Transform<ResizeImagePayload, Promise<ResizeImageResult>>

interface ReduceSizeOrReturnPayload {
  fileItem: FileItem
  processImage: ProcessImage
  processImageOptions: ProcessImageOptions
}

const MAX_FILE_SIZE = 0.4 * 1024 * 1024 // 0.4MB
const SIZE_REDUCING_FACTOR = 0.9

export const reduceSize: Transform<ReduceSizeOrReturnPayload, Transform<ResizeImageResult, Promise<string>>> =
  ({ fileItem, processImage, processImageOptions: { format, quality, width, height } }) =>
  ({ content, size }) =>
    size > MAX_FILE_SIZE
      ? processImage({ format, quality, ...scale(SIZE_REDUCING_FACTOR)({ width, height }) })(fileItem)
      : Promise.resolve(content)
