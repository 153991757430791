import { css, styled } from 'styled'
import { device } from 'utils/css'

import Link from '../Link'

export const linkCss = css`
  color: ${({ theme: { colors } }) => colors.regularText};

  @media ${device.mobile} {
    font-size: 1.5em;
  }
`

export default styled(Link)`
  ${linkCss}
`
