import { AddressConfig, configureAddress } from '@lovejunk/core'
import { toString } from 'lodash'

const addressConfig: AddressConfig = {
  apiKey: toString(process.env.REACT_APP_GET_ADDRESS_API_KEY),
  fetch,
}

export default () => {
  configureAddress(addressConfig)
}
