import React from 'react'

import { Color } from '../theme'
import { ComponentFactory, ToElements } from '../types/ui'

interface Props {
  size?: number
}

interface RootProps {
  color: Color
  size: number
}

export interface ElementsProps {
  Root: RootProps
}

type Elements = ToElements<ElementsProps>

const factory: ComponentFactory<Elements, Props> =
  ({ Root }) =>
  ({ size = 0.5, ...props }) =>
    <Root {...props} color="required" size={size} />

export default factory
