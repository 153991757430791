import { browserTracingIntegration, init as initSentry } from '@sentry/react'
import { VERSION } from 'utils/constants'
import { environment, isProductionBuild, isProductionEnv } from 'utils/environment'

export default () =>
  isProductionBuild &&
  initSentry({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment,
    integrations: [browserTracingIntegration()],
    normalizeDepth: 10,
    release: VERSION,
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: isProductionEnv ? 0.2 : 1.0,
  })
