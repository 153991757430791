import { SET_DEVICE_ID } from '@lovejunk/core'
import Reducer from 'types/reducer'

import { deviceIdLens } from './lens'
import State, { defaultState } from './state'

const reducer: Reducer<State> = (state = defaultState, action) => {
  switch (action.type) {
    case SET_DEVICE_ID:
      return deviceIdLens(state).set(action.payload)
    default:
      return state
  }
}

export default reducer
