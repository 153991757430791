import { ChatBannerProps } from '@lovejunk/core'
import { CenteredRow } from 'components/containers'
import React, { FC } from 'react'
import { styled } from 'styled'

const Banner: FC<ChatBannerProps> = ({ text }) => <Root>{text}</Root>

export default Banner

const Root = styled(CenteredRow)`
  background-color: ${({ theme: { colors } }) => colors.orange};
  padding: 1em;
  text-align: center;
`
