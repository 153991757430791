import { Color, isZeroPrice, JobStatus, Price, Status as AdvertStatus, Transform } from '@lovejunk/core'
import { isUndefined } from 'lodash/fp'
import React, { FC, PropsWithChildren } from 'react'
import { styled, ThemeProps } from 'styled'

interface Props {
  advertStatus: AdvertStatus

  jobStatus?: JobStatus
  price?: Price
}

// TODO: extract common logic with `apps/mobile/src/components/adverts/ActivitiesItem.tsx`.
const Status: FC<Props> = ({ advertStatus, jobStatus, price }) => {
  switch (advertStatus) {
    case 'active':
      return <Primary>Live</Primary>
    case 'helpunderway':
      switch (jobStatus) {
        case 'awaitingcollection':
          return <Primary>Underway</Primary>
        case 'collectorconfirmed':
          return <Red>Collected</Red>
      }
      break
    case 'collected':
      switch (jobStatus) {
        case 'complete':
          return <Black>{isUndefined(price) || isZeroPrice(price) ? 'Collected' : 'Collected & paid'}</Black>
      }
      break
    case 'cancelled':
      return <Black>Cancelled</Black>
    case 'expired':
      return <Black>Expired</Black>
  }

  return null
}

interface LabelProps extends ThemeProps {
  color: Color
}

const Label = styled.label<LabelProps>`
  align-self: flex-start;
  background: ${({ theme: { colors } }) => colors.white};
  border: 1px solid ${({ color, theme: { colors } }) => colors[color]};
  border-radius: 0.5rem;
  border-style: solid;
  color: ${({ color, theme: { colors } }) => colors[color]};
  font-size: 0.875rem;
  margin: 0.25rem;
  padding: 0.25rem 0.5rem;
`

const createCell: Transform<Color, FC<PropsWithChildren>> = color => styled(Label).attrs(() => ({ color }))``

const Black = createCell('black')
const Primary = createCell('primary')
const Red = createCell('error')

export default Status
