import { theme } from '@lovejunk/core'
import { QueryClientProvider } from '@tanstack/react-query'
import { GlobalStyle, Loading } from 'components'
import { ScrollToTop } from 'components/effects'
import persistor from 'core/persistor'
import queryClient from 'core/query-client'
import store from 'core/store'
import { useStoreGoogleAnalytic } from 'hooks/useStoreGoogleAnalytic'
import { ConfigurationCheck, DataCheck, OauthCheck, StoreCheck } from 'navigation/checks'
import NavigationHandler from 'navigation/Handler'
import Navigator from 'navigation/Navigator'
import ReCaptcha from 'navigation/ReCaptcha'
import React, { FC, Suspense } from 'react'
import { Provider as StoreProvider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import setup from 'setup'
import { ThemeProvider } from 'styled-components'

setup()

const App: FC = () => {
  useStoreGoogleAnalytic()

  return (
    <QueryClientProvider client={queryClient}>
      <StoreProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <StoreCheck>
              <ConfigurationCheck>
                <ReCaptcha>
                  <OauthCheck>
                    <DataCheck>
                      <Router>
                        <ScrollToTop />
                        <NavigationHandler />
                        <Suspense fallback={<Loading />}>
                          <Navigator />
                        </Suspense>
                      </Router>
                    </DataCheck>
                  </OauthCheck>
                </ReCaptcha>
              </ConfigurationCheck>
            </StoreCheck>
          </ThemeProvider>
        </PersistGate>
      </StoreProvider>
    </QueryClientProvider>
  )
}

export default App
