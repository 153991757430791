import { flow } from 'lodash/fp'

import Reducer from '../../types/reducer'
import { currentActivityLens as currentLens, historyActivityLens as historyLens } from '../activities/lens'
import State, { defaultState } from '../activities/state'
import { RELOAD_ACTIVITY_HAS_UNREAD_MESSAGES_SUCCESS } from '../chat/types'
import { DELETE_ADVERT_SUCCESS } from '../customer-advert/types/actions'
import { PUBLISH_ADVERT_SUCCESS } from '../new-advert/types'
import {
  FETCH_CUSTOMER_ADVERTS_NEXT_PAGE_SUCCESS,
  FETCH_CUSTOMER_ADVERTS_SUCCESS,
  FETCH_CUSTOMER_HISTORY_ADVERTS_NEXT_PAGE_SUCCESS,
  FETCH_CUSTOMER_HISTORY_ADVERTS_SUCCESS,
} from './types'

const reducer: Reducer<State> = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_ADVERTS_SUCCESS:
      return flow(
        currentLens.itemsLens,
        l => l.set(action.payload.body.entities),
        currentLens.metaLens,
        l => l.set(action.payload.meta),
        currentLens.loadingLens,
        l => l.set(false),
      )(state)
    case FETCH_CUSTOMER_ADVERTS_NEXT_PAGE_SUCCESS:
      return flow(
        currentLens.mergeItemsLens,
        l => l(action.payload.body.entities),
        currentLens.metaLens,
        l => l.set(action.payload.meta),
        currentLens.loadingNextLens,
        l => l.set(false),
      )(state)
    case FETCH_CUSTOMER_HISTORY_ADVERTS_SUCCESS:
      return flow(
        historyLens.itemsLens,
        l => l.set(action.payload.body.entities),
        historyLens.metaLens,
        l => l.set(action.payload.meta),
        historyLens.loadingLens,
        l => l.set(false),
      )(state)
    case FETCH_CUSTOMER_HISTORY_ADVERTS_NEXT_PAGE_SUCCESS:
      return flow(
        historyLens.mergeItemsLens,
        l => l(action.payload.body.entities),
        historyLens.metaLens,
        l => l.set(action.payload.meta),
        historyLens.loadingNextLens,
        l => l.set(false),
      )(state)
    case RELOAD_ACTIVITY_HAS_UNREAD_MESSAGES_SUCCESS:
      return currentLens.hasUnreadMessagesLens(state).set(action.payload)
    case PUBLISH_ADVERT_SUCCESS:
    case DELETE_ADVERT_SUCCESS:
      return currentLens.clearLens(state)
    default:
      return state
  }
}

export default reducer
