enum NavigationPath {
  Account = '/account',
  AddAuthIdentifier = '/account/:type/add',
  Advert = '/adverts/:seoReference',
  BecomeJunkReuser = '/junk-reuser/become',
  Chat = '/chat/:ref',
  ChatWithoutAdvert = '/chat',
  CollectorProfile = '/collector-profile',
  CollectorsOffers = '/collectors-offers',
  Dashboard = '/dashboard',
  DevSettings = '/dev',
  FavouriteCollectors = '/favourite-collectors',
  Freegle = '/freegle/:freegleId',
  Gallery = '/rubbish-removal-gallery',
  GalleryReusables = '/gallery/reusables',
  GallerySeo = '/gallery/:seoUrl',
  GalleryTag = '/gallery/tags/:tagId', // For backward compatibility only.
  Home = '/',
  JunkReuserOffer = '/junk-reuser/offer',
  JunkReusersOffers = '/junk-reusers-offers',
  Login = '/login',
  NewAdvert = '/adverts/new',
  NewAdvertSummary = '/adverts/new/summary',
  NewCard = '/cards/new/',
  PaymentMethods = '/account/payment-methods',
  Referrals = '/referrals/:referral',
  Reusables = '/reusables',
  Signup = '/signup',
  SignupJunkReuser = '/junk-reuser/signup',
  TradeCustomerJobs = '/trade-customer-jobs',
  TradeEnquiryForm = '/trade-enquiry-form',
  VerifyAuthIdentifier = '/account/:type/verify',
  VerifyOtp = '/otp/verify/:type',
  NotFound = '*',
}

export default NavigationPath
