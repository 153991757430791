import { escapeRegExp, flow, isEqual, map, over, size, some } from 'lodash/fp'

import { testRegExp } from './helpers'
import PhoneMarshaller from './marshallers/phone'
import { Validation } from './types/core'
import { defaultCountry } from './utils'

const EMAIL_REGEX =
  /^[-a-zA-Z0-9~!$%^&*_=+}{'?]+(\.[-a-zA-Z0-9~!$%^&*_=+}{'?]+)*@([a-zA-Z0-9_][-a-zA-Z0-9_]*(\.[-a-zA-Z0-9_]+)*\.([a-zA-Z]+)|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i
const POSTCODE_REGEX =
  /^(GIR ?0AA|[A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]([0-9ABEHMNPRV-Y])?)|[0-9][A-HJKPS-UW]) ?[0-9][ABD-HJLNP-UW-Z]{2})$/i
const BRITISH_VAT_NUMBER_REGEXP = /^(GB)?(\d{9}(\d{3})?|[A-Z]{2}\d{3})$/i
const IRISH_VAT_NUMBER_REGEXP = /^IE(\d{7}[A-Z]{1,2}|\d[A-Z]\d{5}[A-Z])$/i
const ISO_8601_REGEX = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/

export const isEmail: Validation = testRegExp(EMAIL_REGEX)
export const isPostcode: Validation = testRegExp(POSTCODE_REGEX)

export const isVatNumber: Validation = flow(
  over(map(testRegExp)([BRITISH_VAT_NUMBER_REGEXP, IRISH_VAT_NUMBER_REGEXP])),
  some(Boolean),
)
export const isIso8601: Validation = testRegExp(ISO_8601_REGEX)

export const isPhoneNumberValid: Validation = flow(size, isEqual(10))

export const isPhoneNumber: Validation = flow(
  PhoneMarshaller.marshal,
  value => value.replace(new RegExp(`^${escapeRegExp(defaultCountry.dialCode)}?(\\d*)`), '$1'),
  isPhoneNumberValid,
)
