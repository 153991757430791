import { flow } from 'lodash/fp'

import { del, get, post, put } from '../../api'
import { CompoundApi, SimpleApi } from '../../api-action'
import { responseToBody } from '../../helpers'
import { Price, Transform } from '../../types/core'
import {
  CancelAdvertParams,
  CollectorOffers,
  CustomerReceipt,
  CustomerReceiptResponse,
  FetchCollectorsOffersResponse,
  FetchJunkReusersOffersResponse,
  InitiatePaymentParams,
  InitiatePaymentResponse,
  InitiatePaymentResponseBody,
  JunkReusersOffers,
  ReviewCollectionParams,
} from './types'
import { PaymentStatus, PaymentStatusResponse } from './types/actions'

export const acceptCounterOfferApi: Transform<string, SimpleApi> = put

export const acceptJunkLoverOfferApi: Transform<string, SimpleApi> = put

export const adjustPriceApi = (url: string, newPrice: Price): SimpleApi => post(url, { newPrice })

export const cancelAdvertApi = (url: string, { notes, reasonId: reason }: CancelAdvertParams) =>
  put(url, { notes, reason })

export const relistAdvertApi = (url: string, { notes, reasonId: reason }: CancelAdvertParams) =>
  put(url, { notes, reason })

export const fetchCollectorsOffersApi = (url: string): CompoundApi<FetchCollectorsOffersResponse, CollectorOffers> => [
  get(url),
  flow(responseToBody, ({ entities }) => entities),
]

export const fetchJunkReusersOffersApi = (
  url: string,
): CompoundApi<FetchJunkReusersOffersResponse, JunkReusersOffers> => [
  get(url),
  flow(responseToBody, ({ entities }) => entities),
]

export const getCustomerReceiptApi: Transform<string, CompoundApi<CustomerReceiptResponse, CustomerReceipt>> = url => [
  get(url),
  responseToBody,
]

export const increasePriceApi = (url: string, price: Price): SimpleApi => post(url, { price })

export const initiatePaymentApi = (
  url: string,
  params: InitiatePaymentParams,
): CompoundApi<InitiatePaymentResponse, InitiatePaymentResponseBody> => [put(url, params), responseToBody]

export const reviewCollectionApi: Transform<ReviewCollectionParams, SimpleApi> = ({ url, rating, comments }) =>
  post(url, { rating, comments })

export const checkPaymentStatusApi: Transform<string, CompoundApi<PaymentStatusResponse, PaymentStatus>> = url => [
  get(url),
  flow(responseToBody, body => body.status),
]

export const addFavouriteCollectorApi: Transform<string, SimpleApi> = put

export const deleteAdvertApi: Transform<string, SimpleApi> = del
