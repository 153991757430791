import {
  COLLECT_FOR_FREE_FLOW_START,
  COLLECT_JUNK_FLOW_START,
  createCollectorConfig,
  createJunkReuserConfig,
  Reducer,
  signupDefaultState as defaultState,
  signupRoleConfigLens,
  SignupState as State,
} from '@lovejunk/core'
import NavigationPath from 'navigation/paths'
import { generatePath } from 'react-router-dom'

const reducer: Reducer<State> = (state = defaultState(), action) => {
  switch (action.type) {
    case COLLECT_JUNK_FLOW_START:
      return signupRoleConfigLens(state).set(
        createCollectorConfig(generatePath(NavigationPath.Advert, { seoReference: action.payload.advertRef })),
      )
    case COLLECT_FOR_FREE_FLOW_START:
      return signupRoleConfigLens(state).set(
        createJunkReuserConfig(generatePath(NavigationPath.Advert, { seoReference: action.payload.advertRef })),
      )
    default:
      return state
  }
}

export default reducer
