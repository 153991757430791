import { AdvertItem, AdvertItemResource, advertsMarshaller, GetLens, Lens } from '@lovejunk/core'
import RootState from 'core/state'
import { map } from 'lodash/fp'

import State from './state'

export const recentAdvertsLens: Lens<State, AdvertItem[], AdvertItemResource[]> = state => ({
  get: () => state.items,
  set: items => ({ ...state, items: map(advertsMarshaller.unmarshal)(items) }),
})

export const recentAdvertsRootLens: GetLens<RootState, State> = state => () => state.recentAdverts

export default recentAdvertsRootLens
