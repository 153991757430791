import { FailureAction, FetchAdvertsResponse, PayloadAction } from '@lovejunk/core'
import { Action } from 'redux'

export const FETCH_RECENT_ADVERTS_START = 'FETCH_RECENT_ADVERTS_START'
export const FETCH_RECENT_ADVERTS_SUCCESS = 'FETCH_RECENT_ADVERTS_SUCCESS'
export const FETCH_RECENT_ADVERTS_FAILURE = 'FETCH_RECENT_ADVERTS_FAILURE'
export const SET_RECENT_ADVERTS_PAGE_SIZE = 'SET_RECENT_ADVERTS_PAGE_SIZE'

export type FetchRecentAdvertsStart = Action<typeof FETCH_RECENT_ADVERTS_START>
export type FetchRecentAdvertsSuccess = PayloadAction<typeof FETCH_RECENT_ADVERTS_SUCCESS, FetchAdvertsResponse>
export type FetchRecentAdvertsFailure = FailureAction<typeof FETCH_RECENT_ADVERTS_FAILURE>

export type FetchRecentAdverts = FetchRecentAdvertsStart | FetchRecentAdvertsSuccess | FetchRecentAdvertsFailure
export type SetRecentAdvertsPageSize = PayloadAction<typeof SET_RECENT_ADVERTS_PAGE_SIZE, number>

export type RecentAdvertsAction = FetchRecentAdverts | SetRecentAdvertsPageSize
