import { find, flow, join, map, negate, property, reverse, sortBy, toString, uniqBy, upperFirst } from 'lodash/fp'
import React, { FC, useCallback, useMemo } from 'react'

import { formatVatRegistered, isMultipleRoleVat } from '../../entities/account/helpers'
import { AddAuthIdentifierPayload, MultipleRoleVat, RoleVat, VerifyIdentityPayload } from '../../entities/account/types'
import { hasUnreadFavouriteCollectorMessages, userActionsToFavouritesParams } from '../../entities/favourites/helpers'
import { FavouritesParams } from '../../entities/favourites/types'
import { LogoutSuccess } from '../../entities/login/types'
import { roleInfo, roleReference } from '../../entities/meta/helpers'
import { hasCollectorCapability, hasCustomerCapability, hasJunkLoverCapability } from '../../entities/meta/lens'
import { AuthIdentifier, Meta, SupplierStats } from '../../entities/meta/types'
import { compactAndJoin, isDefined, isNotNull, returnNull } from '../../helpers'
import { AuthIdentifierType, Dispatch, GetAsync, NoOp, Nullable, Optional, Price, Transform } from '../../types/core'
import { ComponentFactory, Render, ToElements } from '../../types/ui'
import { formatPrice } from '../../utils'
import { ListItemProps } from '../ListItem'

export interface DispatchProps {
  fetchMeta: NoOp
  logout: GetAsync<LogoutSuccess>
  setDeleteAccountIntent: Dispatch<boolean>
  setDeleteCollectorAccountIntent: Dispatch<boolean>
}

export interface OwnProps {
  onAddIdentity: Dispatch<AddAuthIdentifierPayload>
  onBankAccount?: NoOp
  onFavouriteCollectors?: Dispatch<FavouritesParams>
  onLogout: NoOp
  onNotifications?: NoOp
  onPaymentMethods: NoOp
  onPublicProfile: Dispatch<string>
  onVatStatus?: Dispatch<MultipleRoleVat>
  onVerifyIdentity: Dispatch<VerifyIdentityPayload>
  onWasteCarrier?: NoOp
}

export interface StateProps extends RoleVat {
  collectorStats?: SupplierStats
  creditBalance?: Price
  email?: string
  favouritesParams?: FavouritesParams
  firstName?: string
  hasUnreadFavouriteMessages?: boolean
  identifiers?: AuthIdentifier[]
  info: string
  isCollector: boolean
  isCustomer: boolean
  isJunkLover: boolean
  junkLoverStats?: SupplierStats
  lastName?: string
  publicProfileUrl?: string
  selfieUrl?: string
  tippingSiteArrangementsUrl?: string
}

type Props = DispatchProps & OwnProps & StateProps

interface AvatarProps {
  uri: string
}

interface LogoutButtonProps {
  onPress: NoOp
  text: string
}

interface IdentifierData {
  identifier?: string
  type: AuthIdentifierType
  verified?: boolean
}

interface FindIdentifierDataCriteria {
  type: AuthIdentifierType
  verified?: boolean
}

interface ElementsProps {
  Avatar: AvatarProps
  AvatarContainer: unknown
  Collections: unknown
  ContentContainer: unknown
  DeleteAccountButton: unknown
  FirstListItem: ListItemProps
  FullName: unknown
  IconNotVerified: unknown
  IconVerified: unknown
  IdentifierListItem: ListItemProps
  Info: unknown
  List: unknown
  ListItem: ListItemProps
  LogoutButton: LogoutButtonProps
  Rating: unknown
  Reliability: unknown
  Root: unknown
  Stats: unknown
  User: unknown
  UserInfoContainer: unknown
  Value: unknown
}

type Elements = ToElements<ElementsProps>

interface IdentifierProps extends IdentifierData {
  IconNotVerified: Elements['IconNotVerified']
  IconVerified: Elements['IconVerified']
  ListItem: Elements['ListItem']
  onAddIdentity: Dispatch<AddAuthIdentifierPayload>
  onVerifyIdentity: Dispatch<VerifyIdentityPayload>
  Value: Elements['Value']
}

interface CreateOnFavouriteCollectorsPayload {
  onFavouriteCollectors: Dispatch<FavouritesParams>
  favouritesParams: FavouritesParams
}

const Identifier: FC<IdentifierProps> = ({
  IconNotVerified,
  IconVerified,
  identifier,
  ListItem,
  onAddIdentity,
  onVerifyIdentity,
  type,
  Value,
  verified,
}) => {
  const Icon = useMemo(
    () => (isDefined(identifier) ? (verified ? IconVerified : IconNotVerified) : returnNull),
    [IconNotVerified, IconVerified, identifier, verified],
  )
  const onPressCallback = useCallback(
    () => (isDefined(identifier) ? onVerifyIdentity({ identifier, type }) : onAddIdentity({ type })),
    [identifier, onAddIdentity, onVerifyIdentity, type],
  )
  const onPress = useMemo(() => (verified ? undefined : onPressCallback), [onPressCallback, verified])

  return (
    <ListItem label={upperFirst(type)} onPress={onPress}>
      <Icon />
      <Value>{identifier || 'Add'}</Value>
    </ListItem>
  )
}

const factory: ComponentFactory<Elements, Props> = ({
  Avatar,
  AvatarContainer,
  Collections,
  ContentContainer,
  DeleteAccountButton,
  FirstListItem,
  FullName,
  IconNotVerified,
  IconVerified,
  IdentifierListItem,
  Info,
  List,
  ListItem,
  LogoutButton,
  Rating,
  Reliability,
  Root,
  Stats,
  User,
  UserInfoContainer,
  Value,
}) =>
  function Account({
    collectorStats,
    creditBalance,
    email,
    favouritesParams,
    firstName,
    junkLoverStats,
    hasUnreadFavouriteMessages,
    identifiers,
    info,
    isCustomer,
    isCollectorVatRegistered,
    isCollector,
    isCustomerVatRegistered,
    isJunkLover,
    lastName,
    onAddIdentity,
    onBankAccount,
    onFavouriteCollectors,
    onLogout,
    onNotifications,
    onPaymentMethods,
    onPublicProfile,
    onWasteCarrier,
    onVatStatus,
    onVerifyIdentity,
    publicProfileUrl,
    selfieUrl,
    setDeleteAccountIntent,
    setDeleteCollectorAccountIntent,
  }) {
    const renderCreditBalance = useMemo(
      () => (creditBalance ? <ListItem label="Credit Balance">{formatPrice(creditBalance)}</ListItem> : null),
      [creditBalance],
    )

    const createOnFavouriteCollectors = useCallback<Transform<CreateOnFavouriteCollectorsPayload, NoOp>>(
      ({ favouritesParams, onFavouriteCollectors }) =>
        () =>
          onFavouriteCollectors(favouritesParams),
      [],
    )

    const createOnPublicProfilePress = useCallback<Transform<string, NoOp>>(
      url => () => onPublicProfile(url),
      [onPublicProfile],
    )

    const createOnVatStatus = useCallback<
      Transform<NonNullable<Props['onVatStatus']>, Dispatch<MultipleRoleVat, NoOp>>
    >(onVatStatus => roleVat => () => onVatStatus(roleVat), [])

    const onDeleteAccount = useCallback(
      () => (isCollector ? setDeleteCollectorAccountIntent : setDeleteAccountIntent)(true),
      [isCollector, setDeleteAccountIntent, setDeleteCollectorAccountIntent],
    )

    const fullName = compactAndJoin(' ')([firstName, lastName])
    const roleVat: RoleVat = { isCollectorVatRegistered, isCustomerVatRegistered }

    const renderIdentifier = useCallback<Render<IdentifierData>>(
      props => (
        <Identifier
          {...props}
          IconVerified={IconVerified}
          IconNotVerified={IconNotVerified}
          key={props.type}
          ListItem={IdentifierListItem}
          onAddIdentity={onAddIdentity}
          onVerifyIdentity={onVerifyIdentity}
          Value={Value}
        />
      ),
      [onAddIdentity, onVerifyIdentity],
    )

    const uniqueIdentifiers = useMemo<AuthIdentifier[]>(
      () =>
        flow(
          reverse,
          sortBy<AuthIdentifier>(negate(property('verified'))),
          uniqBy<AuthIdentifier>('type'),
        )(identifiers),
      [identifiers],
    )

    const findIdentifierData = useCallback<Transform<FindIdentifierDataCriteria, Optional<IdentifierData>>>(
      criteria => find<AuthIdentifier>(criteria)(uniqueIdentifiers),
      [uniqueIdentifiers],
    )

    const identifierData = useMemo<IdentifierData[]>(
      () => [
        findIdentifierData({ type: 'phone' }) || { type: 'phone' },
        findIdentifierData({ type: 'email', verified: true }) || { identifier: email, type: 'email' },
      ],
      [email, findIdentifierData],
    )

    return (
      <Root>
        <ContentContainer>
          <AvatarContainer>{selfieUrl ? <Avatar uri={selfieUrl} /> : <User />}</AvatarContainer>
          <UserInfoContainer>
            <FullName>{fullName}</FullName>
            {collectorStats ? (
              <Stats>
                <Rating>{collectorStats.averageRating}</Rating>
                <Collections>{collectorStats.jobCompletions}</Collections>
                <Reliability>{collectorStats.reliability}</Reliability>
              </Stats>
            ) : junkLoverStats ? (
              <Stats>
                <Rating>{junkLoverStats.averageRating}</Rating>
                <Collections>{junkLoverStats.jobCompletions}</Collections>
                <Reliability>{junkLoverStats.reliability}</Reliability>
              </Stats>
            ) : null}
            {info ? <Info>{info}</Info> : null}
          </UserInfoContainer>
        </ContentContainer>
        <List>
          <FirstListItem label="First name">{toString(firstName)}</FirstListItem>
          <ListItem label="Last name">{toString(lastName)}</ListItem>
          {map(renderIdentifier)(identifierData)}
          {isCollector && isDefined(publicProfileUrl) ? (
            <ListItem label="Public profile & reviews" onPress={createOnPublicProfilePress(publicProfileUrl)} />
          ) : null}
          {isCustomer && onPaymentMethods ? <ListItem label="Payment methods" onPress={onPaymentMethods} /> : null}
          {isMultipleRoleVat(roleVat) && onVatStatus ? (
            <ListItem label="VAT status" onPress={createOnVatStatus(onVatStatus)(roleVat)} />
          ) : (
            <ListItem label="VAT status">
              {formatVatRegistered(roleVat.isCustomerVatRegistered || roleVat.isCollectorVatRegistered)}
            </ListItem>
          )}
          {isJunkLover && onNotifications ? <ListItem label="Notifications" onPress={onNotifications} /> : null}
          {isCollector ? (
            <>
              {onBankAccount ? <ListItem label="Bank account" onPress={onBankAccount} /> : null}
              {onNotifications ? <ListItem label="Notifications" onPress={onNotifications} /> : null}
              {onWasteCarrier ? <ListItem label="Waster carrier info" onPress={onWasteCarrier} /> : null}
            </>
          ) : null}
          {favouritesParams && onFavouriteCollectors ? (
            <ListItem
              dot={hasUnreadFavouriteMessages}
              label="Favourite collectors"
              onPress={createOnFavouriteCollectors({ favouritesParams, onFavouriteCollectors })}
            />
          ) : null}
          {renderCreditBalance}
        </List>
        <LogoutButton onPress={onLogout} text="Log out" />
        <DeleteAccountButton onPress={onDeleteAccount}>Delete account</DeleteAccountButton>
      </Root>
    )
  }

export const metaToStateProps: Transform<Nullable<Meta>, StateProps> = meta => {
  const {
    actions: userActions,
    creditBalance,
    email,
    firstName,
    identifiers,
    lastName,
  } = isNotNull(meta)
    ? meta
    : {
        actions: undefined,
        creditBalance: undefined,
        email: undefined,
        firstName: undefined,
        identifiers: undefined,
        lastName: undefined,
      }
  const { collectorInfo, customerInfo, junkLoverInfo } = roleInfo(meta)
  const isCollectorVatRegistered = collectorInfo?.isVatRegistered
  const isCustomerVatRegistered = customerInfo?.isVatRegistered
  const capabilities = meta?.capabilities || []
  const isCustomer = hasCustomerCapability(capabilities)
  const isCollector = hasCollectorCapability(capabilities)
  const isJunkLover = hasJunkLoverCapability(capabilities)
  const publicProfileUrl = meta?.actions.viewCollectorProfile?.url
  const selfieUrl = collectorInfo ? collectorInfo.selfieUrl : junkLoverInfo ? junkLoverInfo.selfieUrl : undefined
  const info = flow(
    roleReference,
    map(([role, reference]) => `${role} (${reference})`),
    join('\n'),
  )({ collectorInfo, customerInfo, junkLoverInfo })
  const collectorStats = collectorInfo?.stats
  const junkLoverStats = junkLoverInfo?.stats
  const favouritesParams = userActionsToFavouritesParams(userActions)
  const hasUnreadFavouriteMessages = hasUnreadFavouriteCollectorMessages(userActions)
  const tippingSiteArrangementsUrl = meta?.actions.viewTippingSiteArrangements?.url

  return {
    collectorStats,
    creditBalance,
    email,
    favouritesParams,
    firstName,
    junkLoverStats,
    hasUnreadFavouriteMessages,
    identifiers,
    info,
    isCollector,
    isCollectorVatRegistered,
    isCustomer,
    isCustomerVatRegistered,
    isJunkLover,
    lastName,
    publicProfileUrl,
    selfieUrl,
    tippingSiteArrangementsUrl,
  }
}

export default factory
