import { Nullable, stateProps } from '@lovejunk/core'
import RootState from 'core/state'
import { Navigation, navigationLens, rootLens as appRootLens } from 'entities/app'
import { isNumber } from 'lodash/fp'
import { FC, useEffect } from 'react'
import { connect, MapStateToProps } from 'react-redux'
import { useNavigate } from 'react-router-dom'

interface StateProps {
  navigation: Nullable<Navigation>
}

type Props = StateProps

const NavigationHandler: FC<Props> = ({ navigation }) => {
  const navigate = useNavigate()

  useEffect(
    () => {
      if (navigation) {
        const { to, options } = navigation

        isNumber(to) ? navigate(to) : navigate(to, options)
      }
    },
    // Reason: `navigate` is not being memoized automatically.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigation],
  )

  return null
}

const mapStateToProps: MapStateToProps<StateProps, unknown, RootState> = stateProps(appRootLens)(state => ({
  navigation: navigationLens(state).get(),
}))

export default connect(mapStateToProps)(NavigationHandler)
