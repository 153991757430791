import { NoOp } from '@lovejunk/core'
import React, { FC } from 'react'

import ImageTile from '../../../ImageTile'

interface Props {
  onPress: NoOp
  src: string
}

const Tile: FC<Props> = ({ onPress: onClick, src }) => <ImageTile onClick={onClick} size={5} src={src} />

export default Tile
