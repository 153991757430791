import { compact, flow, join } from 'lodash/fp'
import React from 'react'

import { Nullable } from '../../types/core'
import { ComponentFactory, ToElements } from '../../types/ui'

export interface OwnProps {
  easyAccess: Nullable<boolean>

  easyAccessReason?: string
  summary?: string
}

type Props = OwnProps

interface ElementsProps {
  Root: unknown
}

type Elements = ToElements<ElementsProps>

const factory: ComponentFactory<Elements, Props> = ({ Root }) =>
  function PriceRow({ easyAccess, easyAccessReason, summary }) {
    return (
      <Root>
        {flow(compact, join('\n'))([summary, easyAccess === false && easyAccessReason]) ||
          'no additional details provided'}
      </Root>
    )
  }

export default factory
