import { Dispatch, isNotNull, Nullable } from '@lovejunk/core'
import { Utm } from 'entities/utm'
import { isEmpty, pickBy } from 'lodash/fp'
import { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

type ParamValue = string

type Params = {
  [Property in keyof Utm]: Nullable<ParamValue>
}

export default (saveUtmParams: Dispatch<Partial<Utm>>) => {
  const [searchParams] = useSearchParams()

  const params = useMemo<Params>(
    () => ({
      utm_campaign: searchParams.get('utm_campaign'),
      utm_medium: searchParams.get('utm_medium'),
      utm_source: searchParams.get('utm_source'),
    }),
    [searchParams],
  )

  useEffect(() => {
    const payload = pickBy<Nullable<ParamValue>, ParamValue>(isNotNull)(params)

    if (!isEmpty(payload)) saveUtmParams(payload)
  }, [params, saveUtmParams])
}
