import { Action } from 'redux'

import { FailureAction, FavouriteId, PayloadAction, RemoteUrlAction, Response } from '../../types/core'
import { SearchParams } from '../../types/navigation'
import { FavouriteInteractions, SupplierStats } from '../meta/types'

export interface FavouritesParams extends SearchParams {
  favouriteInteractions: FavouriteInteractions
  url: string
}

interface FavouriteUserActions {
  viewMessages: RemoteUrlAction
}

interface FavouriteCollectorActions extends FavouriteUserActions {
  createChatThread: RemoteUrlAction
}

export interface FavouriteBase<A> {
  actions: Partial<A>
  hasUnreadMessages: boolean
  id: FavouriteId
  name: string
  reference: string
}

export interface FavouriteUser extends FavouriteBase<FavouriteUserActions> {
  lastCollectionPostcodeDistrict?: string
}

export interface FavouriteCollector extends FavouriteBase<FavouriteCollectorActions> {
  stats: SupplierStats
  selfieUrl: string

  county?: string
  postcodeDistrict?: string
}

export type Favourite = FavouriteCollector | FavouriteUser

interface FavouritesBody {
  entities: Favourite[]
}

export type FavouritesResponse = Response<FavouritesBody>

export const FAVOURITES_START = 'FAVOURITES_START'
export const FAVOURITES_SUCCESS = 'FAVOURITES_SUCCESS'
export const FAVOURITES_FAILURE = 'FAVOURITES_FAILURE'

export type FavouritesStart = Action<typeof FAVOURITES_START>
export type FavouritesSuccess = PayloadAction<typeof FAVOURITES_SUCCESS, Favourite[]>
export type FavouritesFailure = FailureAction<typeof FAVOURITES_FAILURE>
export type FavouritesResult = FavouritesSuccess | FavouritesFailure
export type Favourites = FavouritesStart | FavouritesResult

export type FavouritesAction = Favourites
