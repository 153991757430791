import { format, parseISO } from 'date-fns'
import {
  compact,
  constant,
  every,
  first,
  flow,
  gt,
  gte,
  isEmpty,
  isError,
  isNull,
  isNumber,
  isUndefined,
  join,
  lt,
  lte,
  map,
  size,
  slice,
  some,
  spread,
  trim,
  values,
} from 'lodash/fp'
import { Action } from 'redux'
import HttpError from 'standard-http-error'
import { serialize as serializeUri } from 'uri-js'

import {
  BodyItem,
  Dispatch,
  Distance,
  DistanceUnit,
  FailureAction,
  Get,
  GetLens,
  Identity,
  Layout,
  Lens,
  LensResult,
  Nullable,
  Optional,
  PayloadAction,
  Position,
  Range,
  Response as ApiResponse,
  Size,
  Span,
  Transform,
  TransformOptional,
  Validation,
} from './types/core'

export const isHttpError = (error: Error): error is HttpError => isNumber((error as HttpError).code)
export const hasCode = (code: number) => (error: HttpError) => error.code === code
export const isBadRequest = hasCode(HttpError.BAD_REQUEST)
export const isNotFound = hasCode(HttpError.NOT_FOUND)
export const isNotFoundError: Validation<Error> = error => isHttpError(error) && isNotFound(error)
export const isTooManyRequestsError: Validation<Error> = error => isHttpError(error) && isTooManyRequests(error)
export const isConflict = hasCode(HttpError.CONFLICT)
export const isConflictError: Validation<Error> = error => isHttpError(error) && isConflict(error)
export const isForbidden = hasCode(HttpError.FORBIDDEN)
export const isUnprocessable = hasCode(HttpError.UNPROCESSABLE_ENTITY)
export const isTooManyRequests = hasCode(HttpError.TOO_MANY_REQUESTS)
export const promiseAll = <T>(promises: Promise<T>[]) => Promise.all(promises)
export const then =
  <T, R>(callback: Transform<T, R>) =>
  (promise: Promise<T>) =>
    promise.then(callback)
export const resolve = <T>(value: T) => Promise.resolve(value)
export const extract =
  <T, K extends keyof T>(k: K) =>
  (obj: T) =>
    obj[k]
export const returnNull = constant(null)
export const returnObject = constant({})
export const returnPromise = flow(resolve, constant)
export const returnUndefined = constant(undefined)

export const responseToBody = <B>({ body }: ApiResponse<B>) => body
export const responseToJson = (response: Response) => response.json()

export const call = <T>(f: Get<T>) => f()
export const callWith =
  <T>(value: T) =>
  <R>(f: Transform<T, R>) =>
    f(value)
export const toggle = (value: boolean) => !value
export const defaultOrNull = <T>(defaultValue: T, value?: boolean) => (value ? defaultValue : null)
export const nullDefaultsTo =
  <T>(defaultValue: T) =>
  (value: Nullable<T>) =>
    isNull(value) ? defaultValue : value
export const nullifyFalse = (value: boolean) => value || null
export const nullifyUndefined = <T>(value?: T) => (isUndefined(value) ? null : value)
export const falsifyNull = (value: boolean | null) => !isNull(value) && value
export const falsifyUndefined = (value: Optional<boolean>) => isDefined(value) && value
export const truifyNull = (value: boolean | null) => isNull(value) || value
export const truifyUndefined = (value: Optional<boolean>) => isUndefined(value) || value
export const undefineFalse = (value: boolean) => value || undefined
export const undefineNullOrFalse = (value: boolean | null) => (isNull(value) || !value ? undefined : value)
export const undefineNull = <T>(value: T | null) => (isNull(value) ? undefined : value)
export const undefineNullOrZero = (value: number | null) => (isNull(value) || isZero(value) ? undefined : value)
export const isDefined = <T>(value: T | undefined): value is T => !isUndefined(value)
export const isNotEmpty = (value: string) => !isEmpty(value)
export const isPresent = (value: string) => isNotEmpty(trim(value))
export const isShorterThan = (length: number) => (value: string) => size(value) < length
export const isLongerThan = (length: number) => (value: string) => size(value) > length
export const isZero = (value: number) => value === 0
export const hasEntries = <K extends string, V>(record: Record<K, V>) => !isEmpty(record)
export const hasItems = <T>(array: T[]) => !isEmpty(array)
export const hasSize =
  (n: number) =>
  <T>(array: T[]) =>
    size(array) === n
export const hasValue = <T>(value: T | null | undefined): value is T => isNotNull(value) && isDefined(value)
export const isNotNull = <T>(value: T | null): value is T => !isNull(value)
export const areAllPresent = (...values: string[]) => every(isPresent)(values)
export const areAllDefined = (...values: any[]) => every(isDefined)(values)
export const areAnyDefined = (...values: any[]) => some(isDefined)(values)
export const push =
  <T>(...values: T[]) =>
  (array: T[]) =>
    [...array, ...values]
export const pushTo =
  <T>(array: T[]) =>
  (...values: T[]) =>
    [...array, ...values]
export const unshift =
  <T>(...values: T[]) =>
  (array: T[]) =>
    [...values, ...array]
export const unshiftIn =
  <T>(array: T[]) =>
  (...values: T[]) =>
    [...values, ...array]
export const replaceAt =
  (index: number) =>
  <T>(array: T[]) =>
  (item: T) =>
    [...slice(0)(index)(array), item, ...slice(index + 1, size(array))(array)]

export const deleteAt =
  (index: number) =>
  <T>(array: T[]) =>
    [...slice(0)(index)(array), ...slice(index + 1, size(array))(array)]

export const range = <T>(min: T, max: T): Range<T> => ({ min, max })
export const spanToRange = <T>({ start, end }: Span<T>): Range<T> => ({ min: start, max: end })
export const formatDistanceUnit: Record<DistanceUnit, string> = {
  metre: 'm',
  kilometre: 'km',
}
export const distanceValue: Transform<Distance, Distance['value']> = ({ value }) => value
export const formatDistance: Transform<Distance, string> = ({ unit, value }) => value + formatDistanceUnit[unit]

export const formatSpan =
  <T>(formatFragment: Transform<T, string>): Transform<Span<T>, string> =>
  ({ start, end }) =>
    flow(map(formatFragment), join(' - '))([start, end])

export const isInRange =
  <T>(useGte = false, useLte = false): Transform<Range<T>, Transform<T, boolean>> =>
  range =>
  value =>
    (useGte ? gte : gt)(value)(range.min) && (useLte ? lte : lt)(value)(range.max)

export const formatDate = format
export const parseDate = parseISO

export const compactAndJoin = (separator: string) => flow(compact, join(separator))
export const firstPresent = flow(compact, first)
export const testRegExp: Transform<RegExp, Validation> = regExp => value => regExp.test(value)

export const scale: Transform<number, Identity<Size>> =
  factor =>
  ({ width, height }) => ({
    width: factor * width,
    height: factor * height,
  })

export const addUnitToSize: Transform<string, Transform<Size, Size<string>>> =
  unit =>
  ({ width, height }) => ({
    width: width + unit,
    height: height + unit,
  })

export const addEmUnitToSize = addUnitToSize('em')

export const zeroPosition: Position = { x: 0, y: 0 }
export const squareSize: Transform<number, Size> = length => ({ width: length, height: length })

export const alignToBottom: Transform<Layout, Transform<Layout, number>> = referenceLayout => layout =>
  referenceLayout.y + referenceLayout.height - layout.height

export const alignToCenter: Transform<Layout, Transform<Layout, number>> = referenceLayout => layout =>
  referenceLayout.x + referenceLayout.width / 2 - layout.width / 2

export const timeout = (delay: number) => new Promise(resolve => setTimeout(resolve, delay))
export const timeoutGetter = (delay: number) => () => timeout(delay)
export const defer = timeoutGetter(0)

// ACTIONS

export const dispatchStep =
  <R>(step: Get<R>) =>
  <T, A extends Action<T>>(action: A) =>
  (dispatch: Dispatch<R>) => {
    dispatch(step())

    return action
  }

export const actionStep =
  <R>(step: Get<R>) =>
  <T, A extends Action<T>>(actionType: A['type']) =>
  (action: A) =>
  <D>(dispatch: Transform<R, Promise<D>>) =>
    action.type === actionType ? dispatch(step()).then(() => action) : Promise.resolve(action)

export const payloadActionStep =
  <P, R>(step: Transform<P, R>) =>
  <T, A extends Action<T>>(actionType: A['type'], payload: P) =>
  (action: A) =>
  <D>(dispatch: Transform<R, Promise<D>>) =>
    action.type === actionType ? dispatch(step(payload)).then(() => action) : Promise.resolve(action)

export const toPayload = <T, P>({ payload }: PayloadAction<T, P>) => payload

export const toErrorMessage: Transform<Error, string> = ({ message }) => message

export const isPayloadAction = <T, P>(action: Action<T>): action is PayloadAction<T, P> =>
  isDefined((action as PayloadAction<T, P>).payload)

export const isFailureAction = <T>(action: Action<T>): action is FailureAction<T> =>
  isPayloadAction(action) && isError(action.payload)

// LENS

/**
 * R - root state
 * S - slice state
 * P - own props
 * T - state props
 */
export const stateProps =
  <R, S>(rootLens: GetLens<R, S>) =>
  <P, T>(mapper: (state: S, props: P) => T) =>
  (rootState: R, props: P) =>
    mapper(rootLens(rootState)(), props)

export const toLocalState = <R, S>(rootLens: GetLens<R, S>) => flow(rootLens, getter => getter())

export const localLensGetter = <S, M, R>(lens: Lens<S, M, R>) => flow(lens, l => l.get())

export const buildLens = <S, R, M>(rootLens: GetLens<R, S>, localLens: Lens<S, M>): Transform<R, M> =>
  flow(toLocalState(rootLens), localLensGetter(localLens))

export const lensFactory =
  <T>() =>
  <K extends keyof T>(key: K) =>
  <S extends T>(state: S): LensResult<S, S[K]> => ({
    get: () => state[key],
    set: value => ({ ...state, [key]: value }),
  })

export const undefineIfAllValuesUndefined = flow(r => (flow(values, spread(areAnyDefined))(r) ? r : undefined))

export const buildBodyItem = (name: string, value: object): BodyItem => ({ name, value: JSON.stringify(value) })

export const runSafely =
  <V, R>(callback: Transform<V, R>): TransformOptional<V, Optional<R>> =>
  value =>
    value && callback(value)

export const buildUrl = (host?: string) => (path?: string) => serializeUri({ host, path, scheme: 'https' })

export const capitalizeSentences = (input: string) => {
  return input.replace(/(^|[.?!]\s+|\n\s*)(\w)/g, match => match.toUpperCase())
}

export const capitalize = (input: string) => {
  return input.charAt(0).toUpperCase() + input.slice(1)
}
