import { NoOp } from '@lovejunk/core'
import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { ClassNameProps } from 'types/ui'

import CenteredRow from './containers/CenteredRow'
import Column from './containers/Column'
import Text from './text/Text'

type MainProps = ClassNameProps

interface RootProps extends MainProps {
  children?: ReactNode
  inline?: boolean
  onClick?: NoOp
  small?: boolean
}

interface Props extends RootProps {
  title: string

  bold?: boolean
  description?: string
  required?: boolean
}

const RootComponent = ({ inline, ...props }: RootProps) => {
  const Component = inline ? CenteredRow : Column

  return <Component {...props} />
}

const Root = styled(RootComponent)<RootProps>`
  font-size: 1.125em;
  margin-bottom: ${({ inline }) => 0.25 * Number(!inline)}em;
`

const Label: FC<Props> = ({ bold = true, className, description, inline, onClick, required, small, title }) => (
  <Root className={className} inline={inline} onClick={onClick} small={small}>
    <Title bold={bold} description={description} inline={inline}>
      <Text>{title}</Text>
      {required ? <Required>{' *'}</Required> : null}
    </Title>

    {description ? <Description inline={inline}>{description}</Description> : null}
  </Root>
)

interface TitleProps {
  bold: boolean

  description?: string
  inline?: boolean
}

const Title = styled(CenteredRow)<TitleProps>`
  font-family: ${({ bold }) => (bold ? 'Lato-Bold' : 'Lato-Regular')};
  margin-bottom: ${({ description, inline }) => (description && !inline ? '0.125' : '0')}em;
  margin-right: ${({ inline }) => (inline ? '0.25' : '0')}em;
`

interface DescriptionProps {
  inline?: boolean
}

const Description = styled(Text).attrs<DescriptionProps>(({ inline }) => ({
  color: 'greyText',
  size: inline ? 1 : 0.75,
}))<DescriptionProps>``

const Required = styled(Text).attrs({ color: 'required' })``

export default Label
