import { FC, ReactElement } from 'react'

import { Color } from '../../theme'
import { Transform } from '../../types/core'
import { Status } from '../advert/types'

export type StatusTextRenderer = Transform<string, ReactElement>

export interface StatusCellProps {
  color?: Color
}

const defaultStatusText: Record<Status, string> = {
  active: 'live',
  cancelled: 'cancelled',
  collected: 'matched',
  expired: 'expired',
  helpunderway: 'matched',
}

const collectorStatusText: Record<Status, string> = {
  ...defaultStatusText,
  collected: 'collected',
  helpunderway: 'underway',
}

interface Props {
  status: Status
  isCollector: boolean
}

export const createStatusIndicator: Transform<Record<Status, StatusTextRenderer>, FC<Props>> =
  mapping =>
  ({ isCollector, status }) =>
    mapping[status]((isCollector ? collectorStatusText : defaultStatusText)[status])
