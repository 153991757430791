import React, { useCallback } from 'react'

import { AttachmentAssetProps } from '../../entities/chat/types'
import { NoOp, Transform } from '../../types/core'
import { ComponentFactory, ToElements } from '../../types/ui'

interface MockProps {
  isAuthor: boolean
  onPress: NoOp
}

interface TileProps {
  onPress: NoOp
  src: string
}

interface ElementsProps {
  Mock: MockProps
  Tile: TileProps
}

type Elements = ToElements<ElementsProps>

const factory: ComponentFactory<Elements, AttachmentAssetProps> = ({ Mock, Tile }) =>
  function Attachment({ isAuthor, onPress, onTap, url }) {
    const createOnPress = useCallback<Transform<string, NoOp>>(url => () => onPress(url), [onPress])

    return url ? (
      <Tile onPress={createOnPress(url)} src={url} />
    ) : onTap ? (
      <Mock isAuthor={isAuthor} onPress={onTap} />
    ) : null
  }

export default factory
