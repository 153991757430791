import {
  advertsDefaultState,
  apiUrlLens,
  DELETE_ACCOUNT_SUCCESS,
  isRehydrated,
  isRehydratedLens,
  LOGOUT_SUCCESS,
  metaDefaultState,
  needsFetchLens,
  oauthDefaultState,
  pageSizeLens,
  reducer,
  SET_API_URL,
} from '@lovejunk/core'
import address from 'entities/address/reducer'
import appRootLens, { accountDeletedLens } from 'entities/app/lens'
import app from 'entities/app/reducer'
import { appDefaultState } from 'entities/app/state'
import customerAdvert from 'entities/customer-advert/reducer'
import login from 'entities/login/reducer'
import { loginDefaultState } from 'entities/login/state'
import persisted from 'entities/persisted/reducer'
import recentAdverts from 'entities/recent-adverts/reducer'
import signup from 'entities/signup/reducer'
import tradeJobs from 'entities/trade-jobs/reducer'
import utm from 'entities/utm/reducer'
import { entries, flow, keys, map } from 'lodash/fp'
import { combineReducers } from 'redux'
import { PersistConfig, persistReducer } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'
// @ts-ignore
import expireReducer from 'redux-persist-expire'
import Reducer from 'types/reducer'

import RootState from './state'

const signupReducer: typeof signup = (state, action) => signup(reducer.signup(state, action), action)

const appReducer = combineReducers({
  ...reducer,
  signup: signupReducer,
  address,
  app,
  customerAdvert,
  login,
  persisted,
  recentAdverts,
  tradeJobs,
  utm,
})

const rootReducer: Reducer<RootState> = (state, action) => {
  if (action.type === LOGOUT_SUCCESS || action.type === DELETE_ACCOUNT_SUCCESS) {
    if (state !== undefined) {
      const { account, adverts, app: oldApp, oauth, tags, tradeJobs } = state

      // Setting this here instead of app reducer to avoid race condition.
      const app = action.type === DELETE_ACCOUNT_SUCCESS ? accountDeletedLens(oldApp).set(true) : oldApp

      return {
        ...appReducer(undefined, action),
        adverts: flow(
          pageSizeLens,
          l => l.set(pageSizeLens(adverts).get()),
          needsFetchLens,
          l => l.set(true),
        )(advertsDefaultState),
        account,
        app,
        oauth,
        tags,
        tradeJobs,
      }
    }
  }

  // TODO: extract duplicated code once we figure out state sharing.
  if (action.type === SET_API_URL) {
    if (state !== undefined) {
      if (action.payload.preserveStore) {
        const app = flow(appRootLens(state), apiUrlLens, l => l.set(action.payload.url))()

        return { ...appReducer(state, action), app }
      } else {
        const rehydrated = isRehydrated(state)
        const app = flow(
          isRehydratedLens,
          l => l.set(rehydrated),
          apiUrlLens,
          l => l.set(action.payload.url),
        )(appDefaultState)

        return { ...appReducer(undefined, action), app }
      }
    }
  }

  return appReducer(state, action)
}

const autoExpire = true
const expireSeconds = 86400 // 1 day
const whitelistConfig = {
  login: { autoExpire, expireSeconds, expiredState: loginDefaultState() },
  meta: { autoExpire, expireSeconds, expiredState: metaDefaultState },
  oauth: { expiredState: oauthDefaultState() },
  persisted: {},
}
const whitelist = keys(whitelistConfig)
const transforms = flow(
  entries,
  map(([field, options]) => expireReducer(field, options)),
)(whitelistConfig)

export const PERSIST_CONFIG_KEY = 'root'

const persistConfig: PersistConfig<RootState> = {
  key: PERSIST_CONFIG_KEY,
  storage,
  debug: false,
  transforms,
  whitelist,
  stateReconciler: autoMergeLevel2,
}

export const persistedReducer = persistReducer(persistConfig, rootReducer)

export default rootReducer
