import { get } from '../../api'
import { CompoundApi } from '../../api-action'
import { responseToBody } from '../../helpers'
import { Get } from '../../types/core'
import { Configuration, GetConfigurationResponse } from './types'

export const getConfigurationApi: Get<CompoundApi<GetConfigurationResponse, Configuration>> = () => [
  get('configuration', { skipAuthHeaders: true, timeoutAlert: true }),
  responseToBody,
]
