import { isUndefined, map } from 'lodash/fp'
import React, { ComponentType, useCallback, useMemo, useState } from 'react'

import { sortCancellationReasons } from '../../../entities/advert/helpers'
import { AdvertActionPayload, CancellationReasonPayload, Relist } from '../../../entities/advert/types'
import { CancellationReason as Reason } from '../../../entities/app/types'
import { CancelAdvertParams, CancelAdvertPayload } from '../../../entities/customer-advert/types'
import {
  CANCEL_ADVERT_SUCCESS,
  CancelAdvertResult,
  RELIST_ADVERT_SUCCESS,
  RelistAdvertResult,
} from '../../../entities/customer-advert/types/actions'
import { Dispatch, Get, NoOp, Transform, Voidable } from '../../../types/core'
import { ComponentFactory, Render, ToElements } from '../../../types/ui'
import { OnItemPress } from './Item'

interface DispatchProps {
  cancelAdvert: Dispatch<CancelAdvertPayload, Promise<CancelAdvertResult>>
  relistAdvert: Dispatch<CancelAdvertPayload, Promise<RelistAdvertResult>>
}

export interface OwnProps {
  cancellationReasonPayload: CancellationReasonPayload
  isCancelling: boolean

  onDismiss?: NoOp
  reasons?: Reason[]
}

type Props = DispatchProps & OwnProps

interface DismissButtonProps {
  onPress: NoOp
}

interface ItemProps {
  onPress: OnItemPress
  setNotes: Dispatch<string>
  value: string

  selectedValue?: string
}

interface RelistButtonProps {
  onPress: NoOp
  text: string

  description?: string
}

interface CancelButtonProps {
  onPress: NoOp
  text: string

  cancelOnly?: boolean
  destructive?: boolean
  progress?: boolean
}

export interface ElementsProps {
  Buttons: unknown
  CancelOnlyButton: CancelButtonProps
  CancelTextButton: CancelButtonProps
  ErrorText: unknown
  Item: ItemProps
  Reasons: unknown
  RelistButton: RelistButtonProps
  Root: unknown

  DismissButton?: DismissButtonProps
  Title?: unknown
}

type Elements = ToElements<ElementsProps>

interface PerformActionPayload {
  action: Dispatch<CancelAdvertPayload, Promise<CancelAdvertResult | RelistAdvertResult>>
  payload: AdvertActionPayload
}

const factory: ComponentFactory<Elements, Props> = ({
  Buttons,
  CancelOnlyButton,
  CancelTextButton,
  DismissButton,
  ErrorText,
  Item,
  Reasons,
  RelistButton,
  Root,
  Title,
}) =>
  function CancellationReason({
    cancelAdvert,
    cancellationReasonPayload: { relist, ...advertActionPayload },
    isCancelling,
    onDismiss,
    reasons: reasonsProp,
    relistAdvert,
  }) {
    const { advertUrl } = advertActionPayload
    const [error, setError] = useState<string>()
    const [reasonId, setReasonId] = useState<string>()
    const [notes, setNotes] = useState<string>()
    const onPress = useCallback<OnItemPress>(({ notes, value }) => {
      setReasonId(value)
      setNotes(notes)
      setError(undefined)
    }, [])
    const cancelAdvertData = useMemo(() => ({ notes, reasonId }), [notes, reasonId])

    const validateCancelAdvertData = useCallback<Get<Voidable<CancelAdvertParams>>>(() => {
      const { notes, reasonId } = cancelAdvertData

      if (!reasonId) return setError('Please select the reason')
      if (reasonId === 'other' && !notes) return setError('Please provide a reason')

      return { notes, reasonId }
    }, [cancelAdvertData])

    const performAction = useCallback<Dispatch<PerformActionPayload>>(
      ({ action, payload }) => {
        const params = validateCancelAdvertData()

        if (params) {
          action({ ...payload, ...params }).then(action => {
            if (action.type === CANCEL_ADVERT_SUCCESS || action.type === RELIST_ADVERT_SUCCESS) {
              setReasonId(undefined)
              setNotes(undefined)
              setError(undefined)
            }
          })
          onDismiss?.()
        }
      },
      [onDismiss, validateCancelAdvertData],
    )

    const onCancel = useCallback(
      () => performAction({ action: cancelAdvert, payload: advertActionPayload }),
      [advertActionPayload, cancelAdvert, performAction],
    )

    const createOnRelist = useCallback<Transform<Relist, NoOp>>(
      relist => () => performAction({ action: relistAdvert, payload: { ...relist, advertUrl } }),
      [advertUrl, performAction, relistAdvert],
    )
    const renderReason = useCallback<Render<Reason>>(
      ({ label, value }) => (
        <Item key={value} onPress={onPress} selectedValue={reasonId} setNotes={setNotes} value={value}>
          {label}
        </Item>
      ),
      [onPress, reasonId],
    )
    const reasons = useMemo<Reason[]>(
      () => sortCancellationReasons(reasonsProp),
      // We want to reshuffle reasons for different advert.
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [advertActionPayload.url, reasonsProp],
    )

    const cancelButtonProps = useMemo<CancelButtonProps>(
      () => ({
        destructive: true,
        onPress: onCancel,
        progress: isCancelling,
        text: 'Cancel listing',
      }),
      [isCancelling, onCancel],
    )

    const CancelButton = useMemo<ComponentType<CancelButtonProps>>(
      () => (isUndefined(relist) ? CancelOnlyButton : CancelTextButton),
      [relist],
    )

    return (
      <Root>
        {Title ? <Title>Where did it go wrong</Title> : null}
        <Reasons>{map(renderReason)(reasons)}</Reasons>
        {error ? <ErrorText>{error}</ErrorText> : null}
        <Buttons>
          {relist ? <RelistButton onPress={createOnRelist(relist)} text="Relist (recover existing offers)" /> : null}
          <CancelButton {...cancelButtonProps} />
          {DismissButton && onDismiss ? <DismissButton onPress={onDismiss}>dismiss</DismissButton> : null}
        </Buttons>
      </Root>
    )
  }

export default factory
