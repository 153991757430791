import { findKey } from 'lodash/fp'

import { lensFactory } from '../../helpers'
import RootState from '../../state'
import { GetLens, Lens, Optional, SetLens } from '../../types/core'
import marshaller from './marshaller'
import State, { DataItem } from './state'
import { AdvertBase, AdvertId, AdvertPayloadWithRef, AdvertReference, AdvertResponse } from './types'

export const advertLens: GetLens<State, Optional<DataItem>, AdvertReference> = state => ref => state.data[ref]

export const setAdvertNeedsFetchLens: SetLens<State, AdvertId> = state => advertId => {
  const { data } = state
  const reference = findKey<DataItem>(({ body }) => body?.id === advertId)(data)

  if (!reference) return state

  return { ...state, data: { ...state.data, [reference]: { ...data[reference], needsFetch: true } } }
}

export const advertUrlLens: Lens<State, Optional<string>, AdvertPayloadWithRef, AdvertReference> = state => ({
  get: ref => advertLens(state)(ref)?.meta.url,
  set: ({ reference, url }) => ({
    ...state,
    data: { ...state.data, [reference]: { ...advertLens(state)(reference), meta: { url }, needsFetch: true } },
  }),
})

export const advertDetailsLens: Lens<State, Optional<AdvertBase>, AdvertResponse, AdvertReference> = state => ({
  get: ref => advertLens(state)(ref)?.body,
  set: ({ body, meta }) => ({
    ...state,
    data: {
      ...state.data,
      [body.metaDetails.reference]: { body: marshaller.unmarshal(body), meta, needsFetch: false },
    },
  }),
})

const lens = lensFactory<State>()

export const backToLens = lens('backTo')
export const confirmReusableCollectionIntentLens = lens('confirmReusableCollectionIntent')
export const collectionAgreedLens = lens('collectionAgreed')
export const lastOpenedAdvertLens = lens('lastOpenedAdvert')

export const advertRootLens: GetLens<RootState, State> = state => () => state.advert

export default advertRootLens
