import { buildLens, GetLens, lensFactory } from '@lovejunk/core'
import RootState from 'core/state'

import State from './state'

export const rootLens: GetLens<RootState, State> = state => () => state.persisted

const lens = lensFactory<State>()

export const deviceIdLens = lens('deviceId')
export const deviceId = buildLens(rootLens, deviceIdLens)

export default rootLens
