import React from 'react'

import { NoOp } from '../types/core'
import { ComponentFactory, ToElements } from '../types/ui'

interface ButtonProps {
  onPress: NoOp
}

export interface ElementsProps {
  Button: ButtonProps
  Dot: unknown
  Root: unknown
}

type Elements = ToElements<ElementsProps>

export interface Props {
  onPress: NoOp

  dot?: boolean
}

const factory: ComponentFactory<Elements, Props> =
  ({ Button, Dot, Root }) =>
  ({ dot = false, onPress, ...props }) =>
    (
      <Root {...props}>
        <Button onPress={onPress} />
        {dot ? <Dot /> : null}
      </Root>
    )

export default factory
