import { action, failureAction, payloadAction } from '../../action-factory'
import apiAction, { ActionBundle } from '../../api-action'
import { NotCustomerError } from '../../errors'
import { ActionFactory, FailureActionFactory, Nullable, PaginationMeta, PayloadActionFactory } from '../../types/core'
import { StatelessPayloadThunk } from '../../types/thunk'
import { fetchApi } from '../activities/api'
import { FetchActivityItemsResponse } from '../activities/types'
import { isCustomerMetaPresent } from '../meta/helpers'
import { Meta } from '../meta/types'
import {
  FETCH_CUSTOMER_ADVERTS_FAILURE,
  FETCH_CUSTOMER_ADVERTS_NEXT_PAGE_FAILURE,
  FETCH_CUSTOMER_ADVERTS_NEXT_PAGE_START,
  FETCH_CUSTOMER_ADVERTS_NEXT_PAGE_SUCCESS,
  FETCH_CUSTOMER_ADVERTS_START,
  FETCH_CUSTOMER_ADVERTS_SUCCESS,
  FETCH_CUSTOMER_HISTORY_ADVERTS_FAILURE,
  FETCH_CUSTOMER_HISTORY_ADVERTS_NEXT_PAGE_FAILURE,
  FETCH_CUSTOMER_HISTORY_ADVERTS_NEXT_PAGE_START,
  FETCH_CUSTOMER_HISTORY_ADVERTS_NEXT_PAGE_SUCCESS,
  FETCH_CUSTOMER_HISTORY_ADVERTS_START,
  FETCH_CUSTOMER_HISTORY_ADVERTS_SUCCESS,
  FetchCustomerAdvertsFailure,
  FetchCustomerAdvertsNextPageFailure,
  FetchCustomerAdvertsNextPageResult,
  FetchCustomerAdvertsNextPageStart,
  FetchCustomerAdvertsNextPageSuccess,
  FetchCustomerAdvertsResult,
  FetchCustomerAdvertsStart,
  FetchCustomerAdvertsSuccess,
  FetchCustomerHistoryAdvertsFailure,
  FetchCustomerHistoryAdvertsNextPageFailure,
  FetchCustomerHistoryAdvertsNextPageResult,
  FetchCustomerHistoryAdvertsNextPageStart,
  FetchCustomerHistoryAdvertsNextPageSuccess,
  FetchCustomerHistoryAdvertsResult,
  FetchCustomerHistoryAdvertsStart,
  FetchCustomerHistoryAdvertsSuccess,
} from './types'

export const fetchFailure: FailureActionFactory<FetchCustomerAdvertsFailure> =
  failureAction(FETCH_CUSTOMER_ADVERTS_FAILURE)

export const fetchStart: ActionFactory<FetchCustomerAdvertsStart> = action(FETCH_CUSTOMER_ADVERTS_START)
export const fetchSuccess: PayloadActionFactory<FetchCustomerAdvertsSuccess, FetchActivityItemsResponse> =
  payloadAction(FETCH_CUSTOMER_ADVERTS_SUCCESS)

export const fetchActionBundle: ActionBundle<
  FetchCustomerAdvertsStart,
  FetchCustomerAdvertsSuccess,
  FetchCustomerAdvertsFailure,
  FetchActivityItemsResponse
> = [fetchStart, fetchSuccess, fetchFailure]

export const fetch: StatelessPayloadThunk<Nullable<Meta>, FetchCustomerAdvertsResult> = meta => dispatch =>
  isCustomerMetaPresent(meta)
    ? dispatch(apiAction(fetchActionBundle)(fetchApi(meta.customerActivitiesUrl)))
    : Promise.resolve(dispatch(fetchFailure(new NotCustomerError())))

const fetchNextPageFailure: FailureActionFactory<FetchCustomerAdvertsNextPageFailure> = failureAction(
  FETCH_CUSTOMER_ADVERTS_NEXT_PAGE_FAILURE,
)

export const fetchNextPageActionBundle: ActionBundle<
  FetchCustomerAdvertsNextPageStart,
  FetchCustomerAdvertsNextPageSuccess,
  FetchCustomerAdvertsNextPageFailure,
  FetchActivityItemsResponse
> = [
  action(FETCH_CUSTOMER_ADVERTS_NEXT_PAGE_START),
  payloadAction(FETCH_CUSTOMER_ADVERTS_NEXT_PAGE_SUCCESS),
  fetchNextPageFailure,
]

export const fetchNextPage: StatelessPayloadThunk<Nullable<PaginationMeta>, FetchCustomerAdvertsNextPageResult> =
  paginationMeta => dispatch => {
    if (!paginationMeta) return Promise.resolve(dispatch(fetchNextPageFailure(new Error('no meta in activities'))))

    const { next_url: nextUrl } = paginationMeta

    if (!nextUrl)
      return Promise.resolve(dispatch(fetchNextPageFailure(new Error('no next url to fetch in activities meta'))))

    return dispatch(apiAction(fetchNextPageActionBundle)(fetchApi(nextUrl)))
  }

const fetchHistoryFailure: FailureActionFactory<FetchCustomerHistoryAdvertsFailure> = failureAction(
  FETCH_CUSTOMER_HISTORY_ADVERTS_FAILURE,
)
export const fetchHistoryActionBundle: ActionBundle<
  FetchCustomerHistoryAdvertsStart,
  FetchCustomerHistoryAdvertsSuccess,
  FetchCustomerHistoryAdvertsFailure,
  FetchActivityItemsResponse
> = [
  action(FETCH_CUSTOMER_HISTORY_ADVERTS_START),
  payloadAction(FETCH_CUSTOMER_HISTORY_ADVERTS_SUCCESS),
  fetchHistoryFailure,
]

export const fetchHistory: StatelessPayloadThunk<Nullable<Meta>, FetchCustomerHistoryAdvertsResult> =
  meta => dispatch =>
    isCustomerMetaPresent(meta)
      ? dispatch(apiAction(fetchHistoryActionBundle)(fetchApi(meta.customerActivitiesUrl, { isHistory: true })))
      : Promise.resolve(dispatch(fetchHistoryFailure(new NotCustomerError())))

export const fetchHistoryNextPageFailure: FailureActionFactory<FetchCustomerHistoryAdvertsNextPageFailure> =
  failureAction(FETCH_CUSTOMER_HISTORY_ADVERTS_NEXT_PAGE_FAILURE)

export const fetchHistoryNextPageActionBundle: ActionBundle<
  FetchCustomerHistoryAdvertsNextPageStart,
  FetchCustomerHistoryAdvertsNextPageSuccess,
  FetchCustomerHistoryAdvertsNextPageFailure,
  FetchActivityItemsResponse
> = [
  action(FETCH_CUSTOMER_HISTORY_ADVERTS_NEXT_PAGE_START),
  payloadAction(FETCH_CUSTOMER_HISTORY_ADVERTS_NEXT_PAGE_SUCCESS),
  fetchHistoryNextPageFailure,
]

export const fetchHistoryNextPage: StatelessPayloadThunk<
  Nullable<PaginationMeta>,
  FetchCustomerHistoryAdvertsNextPageResult
> = paginationMeta => dispatch => {
  if (!paginationMeta) return Promise.resolve(dispatch(fetchHistoryNextPageFailure(new Error('no meta in activities'))))

  const { next_url: nextUrl } = paginationMeta

  if (!nextUrl)
    return Promise.resolve(dispatch(fetchHistoryNextPageFailure(new Error('no next url to fetch in activities meta'))))

  return dispatch(apiAction(fetchHistoryNextPageActionBundle)(fetchApi(nextUrl)))
}
