import { ChatAttachmentMockElementsProps, chatAttachmentMockFactory as factory } from '@lovejunk/core'
import { IoVideocam } from 'react-icons/io5'
import { styled } from 'styled'

import ReactIcon from '../../../ReactIcon'
import Text from '../../../text/Text'
import Root from '../Root'

export default factory({
  Icon: styled(ReactIcon).attrs({ Icon: IoVideocam, size: 2 })<ChatAttachmentMockElementsProps['Icon']>``,
  Root,
  Text: styled(Text).attrs({ size: 0.75 })<ChatAttachmentMockElementsProps['Text']>``,
})
