import { lensFactory } from '../../helpers'
import RootState from '../../state'
import { GetLens } from '../../types/core'
import State from './state'

export const supportRequestRootLens: GetLens<RootState, State> = state => () => state.supportRequest

const lens = lensFactory<State>()

export const supportRequestIsModalOpenLens = lens('isModalOpen')

export default supportRequestRootLens
