import { Color, Transform } from '@lovejunk/core'
import { ScreenReaderOnly } from 'components/ScreenReaderOnly'
import React, { FC } from 'react'
import { IconType } from 'react-icons'
import { ThemeProps } from 'styled'
import { withTheme } from 'styled-components'
import { SvgIcon } from 'types/ui'

import GenericButton, { ExternalProps } from './GenericButton'

export interface OwnProps {
  SvgIcon?: SvgIcon
  ReactIcon?: IconType

  color?: Color
  disabledColor?: Color
  size?: number
  hiddenLabel?: string
}

export type Props = ExternalProps<OwnProps> & ThemeProps

const toLength: Transform<number, string> = size => size + 'em'

const IconButton: FC<Props> = ({
  color: colorProp,
  disabledColor,
  SvgIcon,
  size = 1,
  ReactIcon,
  theme: { colors },
  hiddenLabel,
  ...props
}) => {
  const length = toLength(size)

  return (
    <GenericButton {...props}>
      {({ disabled, style }) => {
        const color = (disabled && disabledColor && colors[disabledColor]) || (colorProp && colors[colorProp])

        return (
          <>
            {(SvgIcon && <SvgIcon height={length} width={length} style={{ color, display: 'block', ...style }} />) ||
              (ReactIcon && <ReactIcon color={color} size={length} style={{ display: 'block' }} />) ||
              null}
            {hiddenLabel && <ScreenReaderOnly>{hiddenLabel}</ScreenReaderOnly>}
          </>
        )
      }}
    </GenericButton>
  )
}

export default withTheme(IconButton)
