import { PriceRange } from '../../../types/core'
import { NewAdvertAddress, ValidNewAdvert, ValidNewAdvertCollectionPoint, ValidNewAdvertDetails } from '../types'

const address: NewAdvertAddress = {
  address1: 'address1',
  address2: 'address2',
  address3: 'address3',
  city: 'city',
  postcode: 'postcode',
}

const collectionPoint: ValidNewAdvertCollectionPoint = {
  easyAccess: true,
  easyAccessReason: '',
}

const details: ValidNewAdvertDetails = {
  builderWaste: true,
  isLightweight: false,
  residential: false,
  size: 'extralarge',
}

const suggestedPriceRange: PriceRange = {
  start: { amount: 10, currency: 'GBP' },
  end: { amount: 30, currency: 'GBP' },
}

export const validNewAdvertFixture: ValidNewAdvert = {
  address,
  assets: [],
  collectionPoint,
  details,
  fixedTimeSlot: { date: new Date('2020-07-23'), timeSlot: 'pm' },
  id: null,
  isReuseOnly: null,
  manualAddressEntry: false,
  partnerDetails: null,
  reference: null,
  reusable: false,
  selectedCollectors: [],
  suggestedPriceRange,
  summary: '',
  timeSlot: null,
  title: 'Title',
}
