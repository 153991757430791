import { lensFactory } from '../../helpers'
import RootState from '../../state'
import { GetLens } from '../../types/core'
import State from './state'

const rootLens: GetLens<RootState, State> = state => () => state.signupJunkReuser

const lens = lensFactory<State>()

export const advertIdLens = lens('advertId')
export const advertRefLens = lens('advertRef')
export const advertUrlLens = lens('advertUrl')
export const detailsLens = lens('details')
export const isNotApprovedLens = lens('isNotApproved')
export const isSigningUpLens = lens('isSigningUp')
export const selfieLens = lens('selfie')
export const showFeedbackLens = lens('showFeedback')

export default rootLens
