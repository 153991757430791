import React from 'react'

import { PRIVACY_URL, TERMS_URL } from '../../constants'
import { Color } from '../../theme'
import { ComponentFactory, ToElements } from '../../types/ui'

export interface LinkProps {
  color: Color
  url: string
}

interface ElementsProps {
  Link: LinkProps
  Root: unknown
  Text: unknown
}

type Elements = ToElements<ElementsProps>

type Props = {}

const factory: ComponentFactory<Elements, Props> = ({ Link, Root, Text }) =>
  function WindowFooter() {
    return (
      <Root>
        <Text>
          To deter and identify potential fraud, spam or suspicious behaviour, we reserve the right to monitor
          conversations. By sending the message you agree to our{' '}
          <Link color="primary" url={TERMS_URL}>
            Terms of Use
          </Link>{' '}
          and{' '}
          <Link color="primary" url={PRIVACY_URL}>
            Privacy Notice
          </Link>
          .
        </Text>
      </Root>
    )
  }

export default factory
