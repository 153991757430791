import { Action } from 'redux'

import {
  AuthIdentifierType,
  AuthIdentifierValue,
  AuthParams,
  FailureAction,
  PayloadAction,
  Response,
  SessionToken,
} from '../../types/core'
import { BackToChat } from '../account/types'
import { AddIdentifierData } from '../login/types'
import { SignupPayload } from '../signup/types'

export const AUTH_IDENTIFIER_VERIFIED = 'AUTH_IDENTIFIER_VERIFIED'
export type AuthIdentifierVerified = PayloadAction<typeof AUTH_IDENTIFIER_VERIFIED, AuthIdentifierVerifiedPayload>

export const AUTH_IDENTIFIER_NOT_VERIFIED = 'AUTH_IDENTIFIER_NOT_VERIFIED'
export type AuthIdentifierNotVerified = PayloadAction<
  typeof AUTH_IDENTIFIER_NOT_VERIFIED,
  AuthIdentifierNotVerifiedPayload
>

export const DISMISS_AUTH_IDENTIFIER_VERIFICATION = 'DISMISS_AUTH_IDENTIFIER_VERIFICATION'
export type DismissAuthIdentifierVerification = Action<typeof DISMISS_AUTH_IDENTIFIER_VERIFICATION>

export const DISMISS_OTP_RESENT_MODAL = 'DISMISS_OTP_RESENT_MODAL'
export type DismissOtpResentModal = Action<typeof DISMISS_OTP_RESENT_MODAL>

export const DISMISS_OTP_VERIFY_FAILED_MODAL = 'DISMISS_OTP_VERIFY_FAILED_MODAL'
export type DismissOtpVerifyFailedModal = Action<typeof DISMISS_OTP_VERIFY_FAILED_MODAL>

export const VERIFY_OTP_START = 'VERIFY_OTP_START'
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS'
export const VERIFY_OTP_FAILURE = 'VERIFY_OTP_FAILURE'
export type VerifyOtpStart = Action<typeof VERIFY_OTP_START>
export type VerifyOtpSuccess = PayloadAction<typeof VERIFY_OTP_SUCCESS, SessionToken>
export type VerifyOtpFailure = FailureAction<typeof VERIFY_OTP_FAILURE>

export type VerifyOtpResult = VerifyOtpFailure | VerifyOtpSuccess
export type VerifyOtp = VerifyOtpStart | VerifyOtpResult

export const VERIFY_AUTH_IDENTITY_OTP_START = 'VERIFY_AUTH_IDENTITY_OTP_START'
export const VERIFY_AUTH_IDENTITY_OTP_SUCCESS = 'VERIFY_AUTH_IDENTITY_OTP_SUCCESS'
export const VERIFY_AUTH_IDENTITY_OTP_FAILURE = 'VERIFY_AUTH_IDENTITY_OTP_FAILURE'
export type VerifyAuthIdentityOtpStart = Action<typeof VERIFY_AUTH_IDENTITY_OTP_START>
export type VerifyAuthIdentityOtpSuccess = Action<typeof VERIFY_AUTH_IDENTITY_OTP_SUCCESS>
export type VerifyAuthIdentityOtpFailure = FailureAction<typeof VERIFY_AUTH_IDENTITY_OTP_FAILURE>

export type VerifyAuthIdentityOtpResult = VerifyAuthIdentityOtpFailure | VerifyAuthIdentityOtpSuccess
export type VerifyAuthIdentityOtp = VerifyAuthIdentityOtpStart | VerifyAuthIdentityOtpResult

export const RESEND_OTP = 'RESEND_OTP'
export type ResendOtp = PayloadAction<typeof RESEND_OTP, AddIdentifierData>

export type OtpAction =
  | AuthIdentifierNotVerified
  | AuthIdentifierVerified
  | DismissAuthIdentifierVerification
  | DismissOtpResentModal
  | DismissOtpVerifyFailedModal
  | ResendOtp
  | VerifyAuthIdentityOtp
  | VerifyOtp

export interface AuthIdentifierVerifiedPayload {
  type: AuthIdentifierType

  backToChat?: BackToChat
  locked?: boolean
}

export interface AuthIdentifierNotVerifiedPayload extends AuthIdentifierVerifiedPayload {
  error: Error
}

export type AuthIdentifierVerificationPayload = AuthIdentifierVerifiedPayload | AuthIdentifierNotVerifiedPayload

export interface ResendOtpPayload extends Partial<SignupPayload> {
  identifier: AuthIdentifierValue
  type: AuthIdentifierType

  goBack?: boolean
}

export interface VerifyOtpPayload {
  identifier: AuthIdentifierValue
  otp: string
}

export interface VerifyAuthIdentityOtpPayload extends VerifyOtpPayload {
  url: string
}

export interface VerifyOtpParams extends AuthParams {
  otp: string
}

export interface VerifyOtpResponseBody {
  token: SessionToken
}

export type VerifyOtpResponse = Response<VerifyOtpResponseBody>

export type Otp = string[]

export interface VerifyOtpScreenParams {
  identifier: AuthIdentifierValue
  length: number
  url: string
  type: AuthIdentifierType

  addAuthIdentifierUrl?: string
  backToChat?: BackToChat
  locked?: boolean
  isSupplierSignup?: boolean
  isVerification?: boolean
}
