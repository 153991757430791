import { Action } from 'redux'

import { FailureAction } from '../../types/core'
import { AdvertActionPayload, CollectJunkPayload } from '../advert/types'

export interface CollectForFreePayload extends CollectJunkPayload {
  url: string
}

export interface MakeJunkLoverOfferParams {
  conditions?: string
}

export interface MakeJunkLoverOfferPayloadBase extends AdvertActionPayload {
  text?: string
}

export interface MakeJunkLoverOfferPayload extends MakeJunkLoverOfferPayloadBase {
  isApproved: boolean
  isLoggedIn: boolean
}

export const MAKE_JUNK_LOVER_OFFER_START = 'MAKE_JUNK_LOVER_OFFER_START'
export const MAKE_JUNK_LOVER_OFFER_SUCCESS = 'MAKE_JUNK_LOVER_OFFER_SUCCESS'
export const MAKE_JUNK_LOVER_OFFER_FAILURE = 'MAKE_JUNK_LOVER_OFFER_FAILURE'

export type MakeJunkLoverOfferStart = Action<typeof MAKE_JUNK_LOVER_OFFER_START>
export type MakeJunkLoverOfferSuccess = Action<typeof MAKE_JUNK_LOVER_OFFER_SUCCESS>
export type MakeJunkLoverOfferFailure = FailureAction<typeof MAKE_JUNK_LOVER_OFFER_FAILURE>
export type MakeJunkLoverOfferResult = MakeJunkLoverOfferSuccess | MakeJunkLoverOfferFailure
export type MakeJunkLoverOffer = MakeJunkLoverOfferStart | MakeJunkLoverOfferResult

export type JunkReuserAdvertAction = MakeJunkLoverOffer
