import { ChatAttachmentMockElementsProps, chatAttachmentMockFactory as factory, componentAdapter } from '@lovejunk/core'
import { IoImageSharp } from 'react-icons/io5'

import ReactIcon from '../../../ReactIcon'
import Text from '../../../text/Text'
import Root from '../Root'

export default factory({
  Icon: componentAdapter(ReactIcon)<ChatAttachmentMockElementsProps['Icon']>(() => ({ Icon: IoImageSharp, size: 2 })),
  Root,
  Text: componentAdapter(Text)<ChatAttachmentMockElementsProps['Text']>(() => ({ size: 0.75 })),
})
