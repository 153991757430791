import { includes } from 'lodash/fp'

import { isDefined } from '../../helpers'
import { Status } from '../advert/types'
import { ActivityItem, EditableActivityItem, OfferItem, SupplierAdvertItem } from './types'

const editableAdvertStatus: Status[] = ['cancelled', 'expired']

export const isEditableActivityItem = (model: ActivityItem): model is EditableActivityItem =>
  includes(model.advertStatus)(editableAdvertStatus)

export const isOfferItem = (item: SupplierAdvertItem): item is OfferItem => isDefined((item as OfferItem).postedAt)
