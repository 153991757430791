import React from 'react'
import { styled } from 'styled'

const Loading = () => <Root>Loading...</Root>

const Root = styled.p`
  align-self: center;
  color: ${({ theme: { colors } }) => colors.primary};
`

export default Loading
