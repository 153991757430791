import { FetchTradeJobsMeta, TradeJobItem } from './types'

type TradeJobsState = {
  items: TradeJobItem[]
  loading: boolean
  meta: FetchTradeJobsMeta | null
  updatingClientReference: boolean
  successfulClientReferenceUpdateModal: boolean
}

export const tradeJobsDefaultState = (): TradeJobsState => ({
  items: [],
  loading: false,
  meta: null,
  updatingClientReference: false,
  successfulClientReferenceUpdateModal: false,
})

export default TradeJobsState
