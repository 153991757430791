import { lensFactory } from '../../helpers'
import RootState from '../../state'
import { GetLens } from '../../types/core'
import State from './state'

export const favouritesRootLens: GetLens<RootState, State> = state => () => state.favourites

const lens = lensFactory<State>()

export const favouritesItemsLens = lens('items')

export default favouritesRootLens
