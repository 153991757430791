import { Get, Theme } from '@lovejunk/core'
import scStyled, {
  css as scCss,
  StyledInterface,
  ThemedCssFunction,
  ThemeProps as ScThemeProps,
  useTheme as scUseTheme,
} from 'styled-components'

const styled: StyledInterface = scStyled
const css: ThemedCssFunction<Theme> = scCss
const useTheme = scUseTheme as Get<Theme>

export type ThemeProps = ScThemeProps<Theme>

export { css, styled, useTheme }
