import { Color, NoOp } from '@lovejunk/core'
import React, { ComponentPropsWithoutRef, FC } from 'react'
import { styled } from 'styled'

interface Props extends ComponentPropsWithoutRef<'a'> {
  color?: Color
  onPress?: NoOp
  underline?: boolean
}

const ExternalLink: FC<Props> = ({ children, onPress, target = '_blank', ...props }) => (
  <Root onClick={onPress} target={target} {...props}>
    {children}
  </Root>
)

const Root = styled.a<Props>`
  color: ${({ color, theme: { colors } }) => colors[color || 'selected']};
  text-decoration: ${({ underline = false }) => (underline ? 'underline' : 'none')};
`

export default ExternalLink
