import { flow } from 'lodash/fp'

import { DeleteAccountError } from '../../errors'
import { Transform } from '../../types/core'
import Reducer from '../../types/reducer'
import { RELOAD_GLOBAL_HAS_UNREAD_MESSAGES_SUCCESS, RELOAD_HAS_UNREAD_MESSAGES_SUCCESS } from '../chat/types'
import {
  accountDeletionErrorLens,
  customerCardInfoLens,
  deleteAccountIntentLens,
  deleteCollectorAccountIntentLens,
  hasGlobalUnreadMessagesLens,
  hasUnreadMessagesLens,
  isDeletingAccountLens,
  metaDataLens,
} from './lens'
import State, { metaDefaultState } from './state'
import {
  DELETE_ACCOUNT_FAILURE,
  DELETE_ACCOUNT_START,
  DELETE_ACCOUNT_SUCCESS,
  GET_CUSTOMER_CARD_INFO_SUCCESS,
  GET_META_SUCCESS,
  SET_DELETE_ACCOUNT_INTENT,
  SET_DELETE_COLLECTOR_ACCOUNT_INTENT,
} from './types'

const DEFAULT_DELETE_ACCOUNT_ERROR = 'Unexpected error. Please contact support.'

export const toDeleteAccountErrorMessage: Transform<Error, string> = error =>
  error instanceof DeleteAccountError ? error.message : DEFAULT_DELETE_ACCOUNT_ERROR

const reducer: Reducer<State> = (state = metaDefaultState, action) => {
  switch (action.type) {
    case GET_CUSTOMER_CARD_INFO_SUCCESS:
      return customerCardInfoLens(state).set(action.payload)
    case GET_META_SUCCESS:
      return metaDataLens(state).set(action.payload)
    case RELOAD_HAS_UNREAD_MESSAGES_SUCCESS:
      return hasUnreadMessagesLens(state).set(action.payload)
    case RELOAD_GLOBAL_HAS_UNREAD_MESSAGES_SUCCESS:
      return hasGlobalUnreadMessagesLens(state).set(action.payload)
    case SET_DELETE_ACCOUNT_INTENT:
      return flow(
        deleteAccountIntentLens,
        l => l.set(action.payload),
        accountDeletionErrorLens,
        l => l.set(null),
      )(state)
    case SET_DELETE_COLLECTOR_ACCOUNT_INTENT:
      return deleteCollectorAccountIntentLens(state).set(action.payload)
    case DELETE_ACCOUNT_START:
      return isDeletingAccountLens(state).set(true)
    case DELETE_ACCOUNT_SUCCESS:
      return flow(
        isDeletingAccountLens,
        l => l.set(false),
        deleteAccountIntentLens,
        l => l.set(false),
      )(state)
    case DELETE_ACCOUNT_FAILURE:
      return flow(
        isDeletingAccountLens,
        l => l.set(false),
        accountDeletionErrorLens,
        l => l.set(toDeleteAccountErrorMessage(action.payload)),
      )(state)
    default:
      return state
  }
}

export default reducer
