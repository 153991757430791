import { Action } from 'redux'

import { FailureAction, Nullable, PayloadAction, Price, Response } from '../../../types/core'
import { AdvertBase } from '../../advert/types'
import {
  AddFavouriteCollectorIntentPayload,
  AdjustPricePayload,
  CollectorOffer,
  ConfirmationPayload,
  CustomerReceipt,
  DeleteAdvertPayload,
  InitiatePaymentResponseBody,
  JunkReuserOffer,
  ShowAddCardModalPayload,
} from '.'

export const ADJUST_PRICE_START = 'ADJUST_PRICE_START'
export const ADJUST_PRICE_SUCCESS = 'ADJUST_PRICE_SUCCESS'
export const ADJUST_PRICE_FAILURE = 'ADJUST_PRICE_FAILURE'

export type AdjustPriceStart = Action<typeof ADJUST_PRICE_START>
export type AdjustPriceSuccess = Action<typeof ADJUST_PRICE_SUCCESS>
export type AdjustPriceFailure = FailureAction<typeof ADJUST_PRICE_FAILURE>

export type AdjustPriceResult = AdjustPriceSuccess | AdjustPriceFailure
export type AdjustPrice = AdjustPriceStart | AdjustPriceResult

export const CANCEL_ADVERT_START = 'CANCEL_ADVERT_START'
export const CANCEL_ADVERT_SUCCESS = 'CANCEL_ADVERT_SUCCESS'
export const CANCEL_ADVERT_FAILURE = 'CANCEL_ADVERT_FAILURE'

export type CancelAdvertStart = Action<typeof CANCEL_ADVERT_START>
export type CancelAdvertSuccess = Action<typeof CANCEL_ADVERT_SUCCESS>
export type CancelAdvertFailure = FailureAction<typeof CANCEL_ADVERT_FAILURE>
export type CancelAdvertResult = CancelAdvertSuccess | CancelAdvertFailure
export type CancelAdvert = CancelAdvertStart | CancelAdvertResult

export const INCREASE_PRICE_START = 'INCREASE_PRICE_START'
export const INCREASE_PRICE_SUCCESS = 'INCREASE_PRICE_SUCCESS'
export const INCREASE_PRICE_FAILURE = 'INCREASE_PRICE_FAILURE'

export type IncreasePriceStart = Action<typeof INCREASE_PRICE_START>
export type IncreasePriceSuccess = Action<typeof INCREASE_PRICE_SUCCESS>
export type IncreasePriceFailure = FailureAction<typeof INCREASE_PRICE_FAILURE>

export type IncreasePriceResult = IncreasePriceSuccess | IncreasePriceFailure
export type IncreasePrice = IncreasePriceStart | IncreasePriceResult

export const RELIST_ADVERT_START = 'RELIST_ADVERT_START'
export const RELIST_ADVERT_SUCCESS = 'RELIST_ADVERT_SUCCESS'
export const RELIST_ADVERT_FAILURE = 'RELIST_ADVERT_FAILURE'

export type RelistAdvertStart = Action<typeof RELIST_ADVERT_START>
export type RelistAdvertSuccess = Action<typeof RELIST_ADVERT_SUCCESS>
export type RelistAdvertFailure = FailureAction<typeof RELIST_ADVERT_FAILURE>

export type RelistAdvertResult = RelistAdvertSuccess | RelistAdvertFailure
export type RelistAdvert = RelistAdvertStart | RelistAdvertResult

export const ACCEPT_COUNTER_OFFER_START = 'ACCEPT_COUNTER_OFFER_START'
export const ACCEPT_COUNTER_OFFER_SUCCESS = 'ACCEPT_COUNTER_OFFER_SUCCESS'
export const ACCEPT_COUNTER_OFFER_FAILURE = 'ACCEPT_COUNTER_OFFER_FAILURE'

export type AcceptCounterOfferStart = PayloadAction<typeof ACCEPT_COUNTER_OFFER_START, string>
export type AcceptCounterOfferSuccess = Action<typeof ACCEPT_COUNTER_OFFER_SUCCESS>
export type AcceptCounterOfferFailure = FailureAction<typeof ACCEPT_COUNTER_OFFER_FAILURE>

export type AcceptCounterOfferResult = AcceptCounterOfferSuccess | AcceptCounterOfferFailure
export type AcceptCounterOffer = AcceptCounterOfferStart | AcceptCounterOfferResult

export const ACCEPT_JUNK_LOVER_OFFER_START = 'ACCEPT_JUNK_LOVER_OFFER_START'
export const ACCEPT_JUNK_LOVER_OFFER_SUCCESS = 'ACCEPT_JUNK_LOVER_OFFER_SUCCESS'
export const ACCEPT_JUNK_LOVER_OFFER_FAILURE = 'ACCEPT_JUNK_LOVER_OFFER_FAILURE'

export type AcceptJunkLoverOfferStart = Action<typeof ACCEPT_JUNK_LOVER_OFFER_START>
export type AcceptJunkLoverOfferSuccess = Action<typeof ACCEPT_JUNK_LOVER_OFFER_SUCCESS>
export type AcceptJunkLoverOfferFailure = FailureAction<typeof ACCEPT_JUNK_LOVER_OFFER_FAILURE>

export type AcceptJunkLoverOfferResult = AcceptJunkLoverOfferSuccess | AcceptJunkLoverOfferFailure
export type AcceptJunkLoverOffer = AcceptJunkLoverOfferStart | AcceptJunkLoverOfferResult

export const INITIATE_PAYMENT_START = 'INITIATE_PAYMENT_START'
export const INITIATE_PAYMENT_SUCCESS = 'INITIATE_PAYMENT_SUCCESS'
export const INITIATE_PAYMENT_FAILURE = 'INITIATE_PAYMENT_FAILURE'

export type InitiatePaymentStart = Action<typeof INITIATE_PAYMENT_START>
export type InitiatePaymentSuccess = PayloadAction<typeof INITIATE_PAYMENT_SUCCESS, InitiatePaymentResponseBody>
export type InitiatePaymentFailure = FailureAction<typeof INITIATE_PAYMENT_FAILURE>
export type InitiatePaymentResult = InitiatePaymentSuccess | InitiatePaymentFailure
export type InitiatePayment = InitiatePaymentStart | InitiatePaymentResult

export const COLLECTION_PAYMENT_START = 'COLLECTION_PAYMENT_START'
export const COLLECTION_PAYMENT_SUCCESS = 'COLLECTION_PAYMENT_SUCCESS'
export const COLLECTION_PAYMENT_FAILURE = 'COLLECTION_PAYMENT_FAILURE'

export type CollectionPaymentStart = Action<typeof COLLECTION_PAYMENT_START>
export type CollectionPaymentSuccess = PayloadAction<typeof COLLECTION_PAYMENT_SUCCESS, string>
export type CollectionPaymentFailure = FailureAction<typeof COLLECTION_PAYMENT_FAILURE>
export type CollectionPaymentResult = CollectionPaymentSuccess | CollectionPaymentFailure
export type CollectionPayment = CollectionPaymentStart | CollectionPaymentResult

export const COLLECTION_REVIEW_START = 'COLLECTION_REVIEW_START'
export const COLLECTION_REVIEW_SUCCESS = 'COLLECTION_REVIEW_SUCCESS'
export const COLLECTION_REVIEW_FAILURE = 'COLLECTION_REVIEW_FAILURE'

export const FETCH_COLLECTORS_OFFERS_START = 'FETCH_COLLECTORS_OFFERS_START'
export const FETCH_COLLECTORS_OFFERS_SUCCESS = 'FETCH_COLLECTORS_OFFERS_SUCCESS'
export const FETCH_COLLECTORS_OFFERS_FAILURE = 'FETCH_COLLECTORS_OFFERS_FAILURE'

export type FetchCollectorsOffersStart = Action<typeof FETCH_COLLECTORS_OFFERS_START>
export type FetchCollectorsOffersSuccess = PayloadAction<typeof FETCH_COLLECTORS_OFFERS_SUCCESS, CollectorOffer[]>
export type FetchCollectorsOffersFailure = FailureAction<typeof FETCH_COLLECTORS_OFFERS_FAILURE>
export type FetchCollectorsOffersResult = FetchCollectorsOffersSuccess | FetchCollectorsOffersFailure
export type FetchCollectorsOffers = FetchCollectorsOffersStart | FetchCollectorsOffersResult

export const FETCH_JUNK_REUSERS_OFFERS_START = 'FETCH_JUNK_REUSERS_OFFERS_START'
export const FETCH_JUNK_REUSERS_OFFERS_SUCCESS = 'FETCH_JUNK_REUSERS_OFFERS_SUCCESS'
export const FETCH_JUNK_REUSERS_OFFERS_FAILURE = 'FETCH_JUNK_REUSERS_OFFERS_FAILURE'

export type FetchJunkReusersOffersStart = Action<typeof FETCH_JUNK_REUSERS_OFFERS_START>
export type FetchJunkReusersOffersSuccess = PayloadAction<typeof FETCH_JUNK_REUSERS_OFFERS_SUCCESS, JunkReuserOffer[]>
export type FetchJunkReusersOffersFailure = FailureAction<typeof FETCH_JUNK_REUSERS_OFFERS_FAILURE>
export type FetchJunkReusersOffersResult = FetchJunkReusersOffersSuccess | FetchJunkReusersOffersFailure
export type FetchJunkReusersOffers = FetchJunkReusersOffersStart | FetchJunkReusersOffersResult

export const CLEAR_JUNK_REUSERS_OFFERS = 'CLEAR_JUNK_REUSERS_OFFERS'

export const CLEAR_COLLECTORS_OFFERS = 'CLEAR_COLLECTORS_OFFERS'

export type ClearCollectorsOffers = Action<typeof CLEAR_COLLECTORS_OFFERS>
export type ClearJunkReusersOffers = Action<typeof CLEAR_JUNK_REUSERS_OFFERS>

export type CollectionReviewStart = Action<typeof COLLECTION_REVIEW_START>
export type CollectionReviewSuccess = Action<typeof COLLECTION_REVIEW_SUCCESS>
export type CollectionReviewFailure = FailureAction<typeof COLLECTION_REVIEW_FAILURE>
export type CollectionReviewResult = CollectionReviewSuccess | CollectionReviewFailure
export type CollectionReview = CollectionReviewStart | CollectionReviewResult

export const SET_CONFIRMATION_PAYLOAD = 'SET_CONFIRMATION_PAYLOAD'

export type SetConfirmationPayload = PayloadAction<typeof SET_CONFIRMATION_PAYLOAD, Nullable<ConfirmationPayload>>

export const CLOSE_ADD_CARD_MODAL = 'CLOSE_ADD_CARD_MODAL'
export const SHOW_ADD_CARD_MODAL = 'SHOW_ADD_CARD_MODAL'

export type CloseAddCardModal = Action<typeof CLOSE_ADD_CARD_MODAL>
export type ShowAddCardModal = PayloadAction<typeof SHOW_ADD_CARD_MODAL, ShowAddCardModalPayload>

export const SET_ADJUST_PRICE_PAYLOAD = 'SET_ADJUST_PRICE_PAYLOAD'

export type SetAdjustPricePayload = PayloadAction<typeof SET_ADJUST_PRICE_PAYLOAD, Nullable<AdjustPricePayload>>

export interface OnIncreasePricePayload {
  increment: Price
  url: string
}

export const DELETE_ADVERT_INTENT = 'DELETE_ADVERT_INTENT'
export const DELETE_ADVERT_START = 'DELETE_ADVERT_START'
export const DELETE_ADVERT_SUCCESS = 'DELETE_ADVERT_SUCCESS'
export const DELETE_ADVERT_FAILURE = 'DELETE_ADVERT_FAILURE'
export const DISMISS_DELETE_ADVERT_INTENT = 'DISMISS_DELETE_ADVERT_INTENT'

export type DeleteAdvertIntent = PayloadAction<typeof DELETE_ADVERT_INTENT, DeleteAdvertPayload>
export type DeleteAdvertStart = Action<typeof DELETE_ADVERT_START>
export type DeleteAdvertSuccess = Action<typeof DELETE_ADVERT_SUCCESS>
export type DeleteAdvertFailure = FailureAction<typeof DELETE_ADVERT_FAILURE>
export type DeleteAdvertResult = DeleteAdvertSuccess | DeleteAdvertFailure
export type DismissDeleteAdvertIntent = Action<typeof DISMISS_DELETE_ADVERT_INTENT>
export type DeleteAdvert = DeleteAdvertIntent | DeleteAdvertStart | DeleteAdvertResult | DismissDeleteAdvertIntent

export const EDIT_ADVERT = 'EDIT_ADVERT'

export type EditAdvert = PayloadAction<typeof EDIT_ADVERT, AdvertBase>

export const GET_CUSTOMER_RECEIPT_START = 'GET_CUSTOMER_RECEIPT_START'
export const GET_CUSTOMER_RECEIPT_SUCCESS = 'GET_CUSTOMER_RECEIPT_SUCCESS'
export const GET_CUSTOMER_RECEIPT_FAILURE = 'GET_CUSTOMER_RECEIPT_FAILURE'

export type GetCustomerReceiptStart = Action<typeof GET_CUSTOMER_RECEIPT_START>
export type GetCustomerReceiptSuccess = PayloadAction<typeof GET_CUSTOMER_RECEIPT_SUCCESS, CustomerReceipt>
export type GetCustomerReceiptFailure = FailureAction<typeof GET_CUSTOMER_RECEIPT_FAILURE>
export type GetCustomerReceiptResult = GetCustomerReceiptSuccess | GetCustomerReceiptFailure
export type GetCustomerReceipt = GetCustomerReceiptStart | GetCustomerReceiptResult

export const PAYMENT_POLLING_START = 'PAYMENT_POLLING_START'
export const PAYMENT_POLLING_END = 'PAYMENT_POLLING_END'

export type PaymentPollingStart = Action<typeof PAYMENT_POLLING_START>
export type PaymentPollingEnd = Action<typeof PAYMENT_POLLING_END>
export type PaymentPolling = PaymentPollingStart | PaymentPollingEnd

export const CHECK_PAYMENT_STATUS_START = 'CHECK_PAYMENT_STATUS_START'
export const CHECK_PAYMENT_STATUS_SUCCESS = 'CHECK_PAYMENT_STATUS_SUCCESS'
export const CHECK_PAYMENT_STATUS_FAILURE = 'CHECK_PAYMENT_STATUS_FAILURE'

export type PaymentStatus = 'collectorconfirmed' | 'complete' | 'paymentinprogress'

interface PaymentStatusBody {
  status: PaymentStatus
}

export type PaymentStatusResponse = Response<PaymentStatusBody>

export type CheckPaymentStatusStart = Action<typeof CHECK_PAYMENT_STATUS_START>
export type CheckPaymentStatusSuccess = PayloadAction<typeof CHECK_PAYMENT_STATUS_SUCCESS, PaymentStatus>
export type CheckPaymentStatusFailure = FailureAction<typeof CHECK_PAYMENT_STATUS_FAILURE>
export type CheckPaymentStatusResult = CheckPaymentStatusSuccess | CheckPaymentStatusFailure
export type CheckPaymentStatus = CheckPaymentStatusStart | CheckPaymentStatusResult

export const COLLECTION_PAYMENT_COMPLETED = 'COLLECTION_PAYMENT_COMPLETED'

export type CollectionPaymentCompleted = Action<typeof COLLECTION_PAYMENT_COMPLETED>

export const SET_ADD_FAVOURITE_COLLECTOR_INTENT = 'SET_ADD_FAVOURITE_COLLECTOR_INTENT'

export type SetAddFavouriteCollectorIntent = PayloadAction<
  typeof SET_ADD_FAVOURITE_COLLECTOR_INTENT,
  Nullable<AddFavouriteCollectorIntentPayload>
>

export const ADD_FAVOURITE_COLLECTOR_START = 'ADD_FAVOURITE_COLLECTOR_START'
export const ADD_FAVOURITE_COLLECTOR_SUCCESS = 'ADD_FAVOURITE_COLLECTOR_SUCCESS'
export const ADD_FAVOURITE_COLLECTOR_FAILURE = 'ADD_FAVOURITE_COLLECTOR_FAILURE'

export type AddFavouriteCollectorStart = Action<typeof ADD_FAVOURITE_COLLECTOR_START>
export type AddFavouriteCollectorSuccess = Action<typeof ADD_FAVOURITE_COLLECTOR_SUCCESS>
export type AddFavouriteCollectorFailure = FailureAction<typeof ADD_FAVOURITE_COLLECTOR_FAILURE>
export type AddFavouriteCollectorResult = AddFavouriteCollectorSuccess | AddFavouriteCollectorFailure
export type AddFavouriteCollector = AddFavouriteCollectorStart | AddFavouriteCollectorResult

export type CustomerAdvertAction =
  | AcceptCounterOffer
  | AcceptJunkLoverOffer
  | AddFavouriteCollector
  | AdjustPrice
  | CancelAdvert
  | CheckPaymentStatus
  | ClearCollectorsOffers
  | ClearJunkReusersOffers
  | CloseAddCardModal
  | CollectionPayment
  | CollectionPaymentCompleted
  | CollectionReview
  | DeleteAdvert
  | EditAdvert
  | FetchCollectorsOffers
  | FetchJunkReusersOffers
  | GetCustomerReceipt
  | IncreasePrice
  | InitiatePayment
  | PaymentPolling
  | RelistAdvert
  | SetAddFavouriteCollectorIntent
  | SetAdjustPricePayload
  | SetConfirmationPayload
  | ShowAddCardModal
