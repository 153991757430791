import { truifyNull, undefineNull, undefineNullOrFalse } from '../../helpers'
import DateMarshaller from '../../marshallers/date'
import LocationMarshaller from '../../marshallers/location'
import { Marshal, Marshaller, Nullable } from '../../types/core'
import { AdvertItem, AdvertItemResource, Filter, FilterParams, Order, OrderParam } from './types'

export const advertsMarshaller: Marshaller<AdvertItem, AdvertItemResource> = {
  unmarshal: ({ offeredAt, ...rest }) => ({
    ...rest,
    offeredAt: DateMarshaller.unmarshal(offeredAt),
  }),

  marshal: ({ offeredAt, ...rest }) => ({
    ...rest,
    offeredAt: DateMarshaller.marshal(offeredAt),
  }),
}

const orderToOrderParamMapping: Record<Order, OrderParam> = {
  latest: 'createdat.desc',
  nearest: 'default',
  status: 'status',
  urgency: 'urgent.asc',
}

const marshalSorting: Marshal<Nullable<Order>, OrderParam> = order => orderToOrderParamMapping[order || 'latest']

export const marshalFilter: Marshal<Filter, FilterParams> = ({
  builderWaste,
  currentTag,
  collected,
  disposed,
  distance,
  includePageCount,
  location,
  matched,
  residential,
  reusable,
  showAll,
  orderBy,
  pageSize,
  withoutCollectorJobs,
}) => ({
  'include-page-count': truifyNull(includePageCount),
  'builder-waste': undefineNull(builderWaste),
  collected: reusable ? undefined : undefineNull(collected),
  disposed: undefineNull(disposed),
  'include-inactive': truifyNull(showAll),
  matched: reusable ? undefined : matched || undefineNullOrFalse(builderWaste || residential || Boolean(currentTag)),
  residential: undefineNull(residential),
  reusable: undefineNull(reusable),
  sorting: marshalSorting(orderBy),
  page_size: pageSize,
  tag: undefineNull(currentTag),
  ...(distance && { distance: distance.value }),
  ...(location && LocationMarshaller.marshal(location)),
  withoutCollectorJobs: undefineNull(withoutCollectorJobs),
})

export default advertsMarshaller
