import { toUpper } from 'lodash/fp'
import React, { useCallback, useMemo } from 'react'

import { isFavouriteCollector } from '../entities/favourites/helpers'
import { Favourite } from '../entities/favourites/types'
import { compactAndJoin, isDefined } from '../helpers'
import { Dispatch, NoOp } from '../types/core'
import { ComponentFactory, ToElements } from '../types/ui'
import { formatRating } from '../utils/intl'
import { Props as AvatarProps } from './SupplierInfo/UserCard/Avatar'
import { IconProps, Props as InfoProps } from './SupplierInfo/UserCard/Info'

interface ChatIconProps {
  onPress: NoOp

  dot?: boolean
}

interface ChatPayload {
  title: string

  createChatThreadUrl?: string
  viewMessagesUrl?: string
}

interface OwnProps {
  onChat: Dispatch<ChatPayload>
}

export type Props = Favourite & OwnProps

interface ElementsProps {
  Avatar: AvatarProps
  ChatIcon: ChatIconProps
  CollectionsIcon: IconProps
  Content: unknown
  Info: InfoProps
  InfoContainer: unknown
  Name: unknown
  Postcode: unknown
  RatingIcon: IconProps
  Reference: unknown
  Root: unknown
}

type Elements = ToElements<ElementsProps>

const factory: ComponentFactory<Elements, Props> = ({
  Avatar,
  ChatIcon,
  CollectionsIcon,
  Content,
  Info,
  InfoContainer,
  Name,
  Postcode,
  RatingIcon,
  Reference,
  Root,
}) =>
  function FavouriteItem({ onChat, ...favourite }) {
    const {
      actions: { viewMessages },
      averageRating,
      county,
      createChatThread,
      hasUnreadMessages,
      jobCompletions,
      lastCollectionPostcodeDistrict,
      name,
      postcodeDistrict,
      reference,
      selfieUrl,
    } = {
      ...favourite,
      ...(isFavouriteCollector(favourite)
        ? {
            averageRating: favourite.stats.averageRating,
            county: favourite.county,
            createChatThread: favourite.actions.createChatThread,
            jobCompletions: favourite.stats.jobCompletions,
            lastCollectionPostcodeDistrict: undefined,
            postcodeDistrict: favourite.postcodeDistrict,
            selfieUrl: favourite.selfieUrl,
          }
        : {
            averageRating: undefined,
            county: undefined,
            createChatThread: undefined,
            jobCompletions: undefined,
            lastCollectionPostcodeDistrict: favourite.lastCollectionPostcodeDistrict,
            postcodeDistrict: undefined,
            selfieUrl: undefined,
          }),
    }

    const chatPayload = useMemo<ChatPayload>(
      () => ({
        createChatThreadUrl: createChatThread?.url,
        title: name,
        viewMessagesUrl: viewMessages?.url,
      }),
      [createChatThread, name, viewMessages],
    )

    const onChatPress = useCallback(() => onChat(chatPayload), [chatPayload, onChat])
    const postcodeDistrictAndCounty = useMemo(
      () => compactAndJoin(', ')([postcodeDistrict, county]),
      [county, postcodeDistrict],
    )

    return (
      <Root>
        <Avatar src={selfieUrl} />
        <Content>
          <Name>
            {name} <Reference>({toUpper(reference)})</Reference>
          </Name>
          <InfoContainer>
            {isDefined(averageRating) && <Info Icon={RatingIcon}>{formatRating(averageRating, 1)}</Info>}
            {isDefined(jobCompletions) && <Info Icon={CollectionsIcon}>{jobCompletions}</Info>}
          </InfoContainer>
          {postcodeDistrictAndCounty && <Postcode>{postcodeDistrictAndCounty}</Postcode>}
          {lastCollectionPostcodeDistrict && <Postcode>Last collection in {lastCollectionPostcodeDistrict}</Postcode>}
        </Content>
        {(isDefined(chatPayload.createChatThreadUrl) || isDefined(chatPayload.viewMessagesUrl)) && (
          <ChatIcon dot={hasUnreadMessages} onPress={onChatPress} />
        )}
      </Root>
    )
  }

export default factory
