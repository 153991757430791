import React, { useCallback, useMemo } from 'react'

import { pricesOfferArray, pricesPropsToCounterOffer } from '../entities/advert/helpers/prices'
import { truncateLicense } from '../entities/collector-advert/helpers'
import { formatOfferDuration } from '../entities/customer-advert/helpers'
import { CollectorOffer, OnAcceptCollectorOfferPayload } from '../entities/customer-advert/types'
import { Meta } from '../entities/meta/types'
import { isDefined } from '../helpers'
import { Dispatch, NoOp, Nullable, Optional, Transform } from '../types/core'
import { ComponentFactory, ToElements } from '../types/ui'
import { RowContent } from '../utils/css'
import { CollectionsStatProps, RatingStatProps } from './UserStat'

interface ChatPayload {
  title: string

  createChatThreadUrl?: string
  viewMessagesUrl?: string
}

interface ConditionsPayload {
  conditions: string
}

interface NameProps {
  onPress?: NoOp
}

interface BookNowButtonProps {
  flex: boolean
  onPress: NoOp
  progress: boolean
  text: string
}

interface ChatButtonProps {
  hasUnread: boolean
  onPress: NoOp
  text: string
}

interface RootProps {
  isFavourite: boolean
}

export interface ElementsProps {
  Bold: unknown
  BookNowButton: BookNowButtonProps
  Buttons: unknown
  ChatButton: ChatButtonProps
  Collections: CollectionsStatProps
  Conditions: unknown
  Expiry: unknown
  Favourite: unknown
  Footer: RowContent
  Heading: unknown
  License: unknown
  Left: unknown
  Name: NameProps
  Price: unknown
  Rating: RatingStatProps
  Right: unknown
  Root: RootProps
  Row: unknown
  Stats: unknown
  Vat: unknown
}

type Elements = ToElements<ElementsProps>

export interface Props extends CollectorOffer {
  acceptingCounterOfferUrl: Nullable<string>
  customerIsVatRegistered: boolean
  meta: Nullable<Meta>
  onBook: Dispatch<OnAcceptCollectorOfferPayload>
  onCollectorProfile: Dispatch<string>
  onChat: Dispatch<ChatPayload>
}

const factory: ComponentFactory<Elements, Props> = ({
  Bold,
  BookNowButton,
  Buttons,
  ChatButton,
  Collections,
  Conditions,
  Expiry,
  Favourite,
  Footer,
  Heading,
  License,
  Left,
  Name,
  Price,
  Rating,
  Right,
  Root,
  Row,
  Stats,
  Vat,
}) =>
  function CollectorOfferItem({
    acceptingCounterOfferUrl,
    actions: {
      accept: { prerequisites, trackingEvents, url },
      createChatThread,
      viewCollectorProfile,
      viewMessages,
    },
    amount: price,
    collectorInfo: { averageRating, firstName, isFavourite, license, numberOfCollections },
    conditions,
    customerIsVatRegistered,
    expiry,
    hasUnread,
    meta,
    onBook,
    onChat,
    onCollectorProfile,
  }) {
    const onBookPress = useCallback(
      () => onBook({ prerequisites, trackingEvents, url }),
      [onBook, prerequisites, trackingEvents, url],
    )
    const pricesOffer = pricesPropsToCounterOffer({
      bestCounterOffer: price,
      customerIsVatRegistered,
      isOwner: true,
      offersMode: true,
      meta,
    })
    const [formattedPrice, formattedVat] =
      (pricesOffer && pricesOfferArray({ ...pricesOffer, retainDecimals: customerIsVatRegistered })) || []

    const createOnNamePress = useCallback<Transform<string, NoOp>>(
      url => () => onCollectorProfile(url),
      [onCollectorProfile],
    )

    const chatPayload = useMemo<Optional<ChatPayload>>(
      () =>
        (createChatThread || viewMessages) && {
          createChatThreadUrl: createChatThread?.url,
          title: firstName,
          viewMessagesUrl: viewMessages?.url,
          interlocutorName: firstName,
        },
      [createChatThread, firstName, viewMessages],
    )

    const createOnChatPress = useCallback<Transform<ChatPayload, NoOp>>(payload => () => onChat(payload), [onChat])
    const onChatPress = useMemo(() => chatPayload && createOnChatPress(chatPayload), [chatPayload, createOnChatPress])
    const conditionsPayload = useMemo<Optional<ConditionsPayload>>(
      () => (!onChatPress && conditions ? { conditions } : undefined),
      [conditions, onChatPress],
    )
    const showChatButtonDot = useMemo(
      () => hasUnread || (isDefined(createChatThread) && isDefined(conditions)),
      [conditions, createChatThread, hasUnread],
    )

    return (
      <Root isFavourite={isFavourite}>
        <Row>
          <Left>
            <Heading>
              <Name onPress={viewCollectorProfile && createOnNamePress(viewCollectorProfile.url)}>{firstName}</Name>
              <Stats>
                <Rating fractionDigits={1}>{averageRating}</Rating>
                <Collections>{numberOfCollections}</Collections>
              </Stats>
            </Heading>
            <License>(waste carrier license {truncateLicense(license)})</License>
            {conditionsPayload && (
              <Conditions>
                <Bold>Conditions:</Bold> {conditionsPayload.conditions}
              </Conditions>
            )}
          </Left>
          <Right>
            <Price>{formattedPrice}</Price>
            {formattedVat ? <Vat>{formattedVat}</Vat> : null}
          </Right>
        </Row>
        <Buttons>
          {onChatPress && <ChatButton hasUnread={showChatButtonDot} onPress={onChatPress} text="View messages" />}
          <BookNowButton
            flex={isDefined(onChatPress)}
            onPress={onBookPress}
            progress={url === acceptingCounterOfferUrl}
            text="Book now"
          />
        </Buttons>
        <Footer leftSide={isFavourite} rightSide={isDefined(expiry)}>
          {isFavourite ? <Favourite>Favourite collector</Favourite> : null}
          {isDefined(expiry) ? <Expiry>Offer expires in {formatOfferDuration(expiry)}</Expiry> : null}
        </Footer>
      </Root>
    )
  }

export default factory
