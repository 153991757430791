import {
  configurationCheckFactory as factory,
  ConfigurationCheckStateProps as StateProps,
  hasConfiguration,
} from '@lovejunk/core'
import RootState from 'core/state'
import { getConfiguration } from 'entities/app'
import { connect, MapStateToProps } from 'react-redux'

import Check from './Check'

const mapStateToProps: MapStateToProps<StateProps, unknown, RootState> = rootState => ({
  hasConfiguration: hasConfiguration(rootState),
})

const mapDispatchToProps = {
  getConfiguration,
}

export default connect(mapStateToProps, mapDispatchToProps)(factory({ Check }))
