import { AuthIdentifierType, AuthIdentifierValue, Get, Nullable } from '../../types/core'
import { RoleConfig } from './types'

export interface SignupStateBase<C> {
  config: Nullable<C>
  error: boolean
  identifier: AuthIdentifierValue
  identifierType: AuthIdentifierType
  isSigningUp: boolean
}

interface State extends SignupStateBase<RoleConfig> {
  countryCode: string
  email: string
  firstName: string
  lastName: string
  phoneNumber: string
  receiveMarketingOffers: boolean
}

export const signupDefaultStateBase = <C>(): SignupStateBase<C> => ({
  config: null,
  error: false,
  identifier: '',
  identifierType: 'email',
  isSigningUp: false,
})

export const signupDefaultState: Get<State> = () => ({
  ...signupDefaultStateBase(),
  countryCode: '',
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  receiveMarketingOffers: false,
})

export default State
