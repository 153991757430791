import { call, DeviceId, DispatchAsync, returnPromise } from '@lovejunk/core'
import RootState from 'core/state'
import { getIdentifier } from 'utils/environment'

import { deviceId } from '../persisted/lens'

export const getDeviceId: DispatchAsync<RootState, DeviceId> = rootState => {
  const stored = deviceId(rootState)

  return call(stored ? returnPromise(stored) : getIdentifier)
}
