import { Nullable } from '../../types/core'
import { CustomerCardInfo, Meta } from './types'

export interface MetaState {
  accountDeletionError: Nullable<string>
  customerCardInfo: Nullable<CustomerCardInfo>
  data: Nullable<Meta>
  deleteAccountIntent: boolean
  deleteCollectorAccountIntent: boolean
  isDeletingAccount: boolean
}

export const metaDefaultState: MetaState = {
  accountDeletionError: null,
  customerCardInfo: null,
  data: null,
  deleteAccountIntent: false,
  deleteCollectorAccountIntent: false,
  isDeletingAccount: false,
}

export default MetaState
