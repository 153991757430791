import { dataCheckFactory as factory, DataCheckStateProps as StateProps, fetchTags, tagsLoaded } from '@lovejunk/core'
import RootState from 'core/state'
import { noop } from 'lodash/fp'
import { connect, MapStateToProps } from 'react-redux'

import Check from './Check'

const mapStateToProps: MapStateToProps<StateProps, unknown, RootState> = rootState => ({
  hasRemoteConfig: true,
  tagsLoaded: tagsLoaded(rootState),
})

const mapDispatchToProps = {
  fetchTags,
  getRemoteConfig: noop,
}

export default connect(mapStateToProps, mapDispatchToProps)(factory({ Check }))
