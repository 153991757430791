import { Get, Nullable } from '../../types/core'
import {
  AddFavouriteCollectorIntentPayload,
  AdjustPricePayload,
  CollectorOffer,
  ConfirmationPayload,
  DeleteAdvertPayload,
  JunkReuserOffer,
  ShowAddCardModalPayload,
} from './types'

export interface CustomerAdvertState {
  acceptingCounterOfferUrl: Nullable<string>
  addFavouriteCollectorError: boolean
  addFavouriteCollectorIntent: Nullable<AddFavouriteCollectorIntentPayload>
  adjustPricePayload: Nullable<AdjustPricePayload>
  collectorsOffers: CollectorOffer[]
  confirmationPayload: Nullable<ConfirmationPayload>
  isAddingCollectorToFavourites: boolean
  isCancelling: boolean
  isConfirming: boolean
  isIncreasing: boolean
  isPaymentProcessing: boolean
  isReviewingLens: boolean
  junkReusersOffers: JunkReuserOffer[]
  showAddCardModal: Nullable<ShowAddCardModalPayload>
  showDeleteAdvertModal: Nullable<DeleteAdvertPayload>
}

export const customerAdvertDefaultState: Get<CustomerAdvertState> = () => ({
  acceptingCounterOfferUrl: null,
  addFavouriteCollectorError: false,
  addFavouriteCollectorIntent: null,
  adjustPricePayload: null,
  collectorsOffers: [],
  confirmationPayload: null,
  isAddingCollectorToFavourites: false,
  isCancelling: false,
  isConfirming: false,
  isIncreasing: false,
  isPaymentProcessing: false,
  isReviewingLens: false,
  junkReusersOffers: [],
  showAddCardModal: null,
  showDeleteAdvertModal: null,
})

export default CustomerAdvertState
