import { get, post, put } from '../../api'
import { SimpleApi } from '../../api-action'
import { PaymentMethod } from '../../types/core'
import { GetTippingSiteResponse, GetTippingSitesResponse, PostTippingQrCodeUrlResponse } from './types'

export const updateAccountEmail = (url: string, email: string): SimpleApi => put(url, { email })

export const updatePreferredPaymentMethod = (url: string, preferredPaymentMethod: PaymentMethod) =>
  put(url, { preferredPaymentMethod })

export const getTippingSites = (url: string): SimpleApi<GetTippingSitesResponse> => get(url)

export const getTippingSite = (url: string): SimpleApi<GetTippingSiteResponse> => get(url)

export const postTippingSiteQrCode = (url: string): SimpleApi<PostTippingQrCodeUrlResponse> => post(url)
