import { NoOp } from '@lovejunk/core'
import React, { FC } from 'react'

import VideoTile from '../../../VideoTile'

interface Props {
  onPress: NoOp
  src: string
}

const Tile: FC<Props> = ({ onPress: onClick, src }) => <VideoTile onClick={onClick} size={5} src={src} />

export default Tile
