import { EmailRequestType, EmailRequestViewData } from './types'

export const requestTypeToViewData: Record<EmailRequestType, EmailRequestViewData> = {
  receipt: {
    modalTitle: 'Receipt sent',
    emailTitle: 'receipt',
    emailSubtitle: 'get a receipt',
  },
  wtn: {
    modalTitle: 'Waste doc sent',
    emailTitle: 'waste documentation',
    emailSubtitle: 'receive waste doc',
  },
}
