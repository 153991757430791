import { map, sortBy } from 'lodash/fp'
import React, { forwardRef, useCallback, useMemo } from 'react'

import { ChatMessage, OnShowAttachment } from '../../entities/chat/types'
import { Render, ToElements } from '../../types/ui'

export interface Props {
  messages: ChatMessage[]
  onShowAttachment: OnShowAttachment
}

interface ItemProps extends Omit<ChatMessage, 'message'> {
  key: string
  onShowAttachment: OnShowAttachment
}

interface ElementsProps {
  Content: unknown
  Footer: unknown
  Item: ItemProps
  Items: unknown
  Root: unknown
}

type Elements = ToElements<ElementsProps>

const factory = <T,>({ Content, Footer, Item, Items, Root }: Elements) =>
  forwardRef<T, Props>(function ChatWindow({ messages, onShowAttachment }, ref) {
    const renderItem = useCallback<Render<ChatMessage>>(
      ({ message, ...props }) => (
        <Item {...props} key={'chat-message-' + props.id} onShowAttachment={onShowAttachment}>
          {message}
        </Item>
      ),
      [onShowAttachment],
    )
    const data = useMemo<ChatMessage[]>(() => sortBy<ChatMessage>('sentAt')(messages), [messages])

    return (
      <Root ref={ref}>
        <Content>
          <Items>{map(renderItem)(data)}</Items>
          <Footer />
        </Content>
      </Root>
    )
  })

export default factory
