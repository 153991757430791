import { userCardInfoFactory as factory, UserCardInfoProps as Props } from '@lovejunk/core'
import { FC, PropsWithChildren } from 'react'
import { styled } from 'styled'

import CenteredRow from '../../containers/CenteredRow'
import BaseText from '../../text/BaseText'

const Root = styled(CenteredRow)`
  gap: 0.3125em;
`

const Text = styled(BaseText).attrs({ color: 'greyText' })``

const Info: FC<PropsWithChildren<Props>> = ({ children, Icon }) =>
  factory({
    Icon,
    Root,
    Text,
  })({ children })

export default Info
