import { chatWindowFooterFactory as factory, ChatWindowFooterLinkProps } from '@lovejunk/core'
import { styled, ThemeProps } from 'styled'
import { device } from 'utils/css'

import CenteredColumn from '../containers/CenteredColumn'
import ExternalLink from '../ExternalLink'
import BaseText from '../text/BaseText'

export default factory({
  Link: styled(ExternalLink).attrs<ChatWindowFooterLinkProps>(({ url: href }) => ({
    href,
  }))<ChatWindowFooterLinkProps>``,
  Root: styled(CenteredColumn)`
    margin: 0 4em;
    padding: 1em 0;

    @media ${device.mobile} {
      margin: 0 2em;
    }
  `,
  Text: styled(BaseText)<ThemeProps>`
    color: ${({ theme: { colors } }) => colors.contrast2};
    font-size: 12px;
    text-align: center;
  `,
})
