import {
  addSeconds,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInWeeks,
  format,
  intervalToDuration,
} from 'date-fns'
import { find, flow, gt, inRange, join, map, partition, shuffle, toSafeInteger, toString } from 'lodash/fp'

import { buildUrl, compactAndJoin, formatDate, isDefined, parseDate, pushTo } from '../../../helpers'
import {
  Format,
  Optional,
  PostcodePayload,
  Price,
  PriceRange,
  Transform,
  TransformOptional,
  Validation,
  VatPrice,
} from '../../../types'
import { formatPrice } from '../../../utils/intl'
import { CancellationReason as Reason } from '../../app/types'
import { hasDataCapability } from '../../meta/lens'
import { formatOpenTimeSlot, formatTimeSlotFixedName } from '../../time-slots/helpers'
import { TimeSlotFixedName } from '../../time-slots/types'
import {
  AdvertBase,
  AdvertIdAndUrl,
  AdvertPayload,
  AdvertPriceVisibilityPayload,
  AdvertReference,
  AdvertResponse,
  AdvertWithCounterOffer,
  JobStatus,
  OwnerAdvertWithCounterOffer,
  SupplierType,
  TimeDetails,
  UserName,
} from '../types'

export const advertCreation = (date: Date) => formatDate(date, 'd MMMM yyyy HH:mm')

// This is to prevent `map` in formatValidRange from leaking the index into `formatPrice`.
// Apparently lodash/fp `map` doesn't always pass capped argument, but detects callbacks arity and may pass additional
// arguments too.
const formatRangePrice: Format<Price> = price => formatPrice(price)

export const formatValidRange: Format<PriceRange> = ({ start, end }) => join(' - ')(map(formatRangePrice)([start, end]))

export const isAdvertWithCounterOffer = (model: AdvertBase): model is AdvertWithCounterOffer => {
  const { counterOfferDetails, status } = model as AdvertWithCounterOffer

  return isDefined(counterOfferDetails) && isDefined(counterOfferDetails.bestCounterOffer) && status === 'active'
}

export const getBestCounterOffer: Transform<AdvertBase, Optional<VatPrice>> = advert =>
  isAdvertWithCounterOffer(advert) ? advert.counterOfferDetails.bestCounterOffer : undefined

export const isOwnerAdvertWithCounterOffer = (model: AdvertBase): model is OwnerAdvertWithCounterOffer =>
  isAdvertWithCounterOffer(model) && model.view === 'owner'

export const formatCollectionDate = (date: Date, weekDay: boolean, short: boolean, skipDateIfFollowingWeek: boolean) =>
  formatDate(
    date,
    weekDay
      ? skipDateIfFollowingWeek && inRange(0, 7)(differenceInDays(date, new Date()))
        ? 'EEE'
        : short
        ? 'EEE, d MMM'
        : 'EEE, dd MMMM'
      : 'dd MMM',
  )

const formatCollectionTime = (startDate: Date, endDate: Date, short: boolean) => {
  const format = short ? 'H' : 'HH:mm'

  return join(' - ')([formatDate(startDate, format), formatDate(endDate, format)])
}

const formatFixedTimeSlotChoice = (
  startAt: string,
  fixedTimeSlotChoice: TimeSlotFixedName,
  weekDay = false,
  short = false,
  skipDateIfFollowingWeek = false,
) => {
  if (startAt === '') return ''

  const startAtDate = parseDate(startAt)
  const collectionDate = formatCollectionDate(startAtDate, weekDay, short, skipDateIfFollowingWeek)

  return `${collectionDate} ${formatTimeSlotFixedName(fixedTimeSlotChoice)}`
}

const formatCollectionDateAndTime = (
  startAt: string,
  endAt: string,
  weekDay = false,
  short = false,
  skipDateIfFollowingWeek = false,
) => {
  if (startAt === '' || endAt === '') return ''

  const startAtDate = parseDate(startAt)
  const endAtDate = parseDate(endAt)
  const collectionDate = formatCollectionDate(startAtDate, weekDay, short, skipDateIfFollowingWeek)
  const collectionTime = formatCollectionTime(startAtDate, endAtDate, short)

  return `${collectionDate} ${collectionTime}`
}

interface FormatCollectionTimeSlotOptions {
  short: boolean
  skipDateIfFollowingWeek: boolean
  weekDay: boolean
}

const defaultFormatCollectionTimeSlotOptions: FormatCollectionTimeSlotOptions = {
  weekDay: false,
  short: false,
  skipDateIfFollowingWeek: false,
}

export const formatCollectionTimeSlot = (
  { startAt, endAt, fixedTimeSlotChoice, openTimeSlotChoice }: TimeDetails,
  {
    weekDay = defaultFormatCollectionTimeSlotOptions.weekDay,
    short = defaultFormatCollectionTimeSlotOptions.short,
    skipDateIfFollowingWeek = defaultFormatCollectionTimeSlotOptions.skipDateIfFollowingWeek,
  }: Optional<Partial<FormatCollectionTimeSlotOptions>> = defaultFormatCollectionTimeSlotOptions,
) =>
  fixedTimeSlotChoice
    ? formatFixedTimeSlotChoice(startAt, fixedTimeSlotChoice, weekDay, short, skipDateIfFollowingWeek)
    : openTimeSlotChoice
    ? formatOpenTimeSlot(openTimeSlotChoice)
    : formatCollectionDateAndTime(startAt, endAt, weekDay, short, skipDateIfFollowingWeek)

// [check, singular, plural]
type DurationCheck = [number, string, string]
type MatchedTimeMapping = [keyof Duration, string, string]
// [key, singular, plural]
const formatMatchedTimeMapping: MatchedTimeMapping[] = [
  ['years', 'year', 'years'],
  ['months', 'month', 'months'],
  ['weeks', 'week', 'weeks'],
  ['days', 'day', 'days'],
  ['hours', 'hour', 'hours'],
  ['minutes', 'min', 'mins'],
  ['seconds', 'sec', 'secs'],
]

export const formatDurationInSeconds = (value: number) => {
  // We use any date as a reference point to calculate `duration`, because in `date-fns` it's not possible
  // to get `Duration` object with proper values automatically assigned based on seconds or any other unit.
  const d = new Date()
  const duration = intervalToDuration({ start: d, end: addSeconds(d, value) })
  const checks = map<MatchedTimeMapping, DurationCheck>(([key, singular, plural]) => [
    toSafeInteger(duration[key]),
    singular,
    plural,
  ])(formatMatchedTimeMapping)

  return formatDuration(checks)
}

export const statusText = (advertStatus: string, jobStatus: JobStatus) => {
  if (advertStatus === 'helpunderway' && jobStatus === 'collectorconfirmed') {
    return 'Customer to confirm'
  }

  if (advertStatus === 'helpunderway' && jobStatus === 'awaitingcollection') {
    return 'Collection due'
  }

  if (advertStatus === 'active') {
    return 'No collector yet'
  }

  return null
}

export const formatDuration: Format<DurationCheck[]> = checks => {
  const result = find<DurationCheck>(([difference]) => gt(difference)(0))(checks)

  if (!result) return ''

  const [difference, singular, plural] = result

  return join(' ')([difference, difference > 1 ? plural : singular])
}

export function advertDuration(date: Date) {
  const now = new Date()
  const checks: DurationCheck[] = [
    [differenceInMonths(now, date), 'month', 'months'],
    [differenceInWeeks(now, date), 'week', 'weeks'],
    [differenceInDays(now, date), 'day', 'days'],
    [differenceInHours(now, date), 'hour', 'hours'],
    [differenceInMinutes(now, date, { roundingMethod: 'floor' }), 'min', 'mins'],
  ]

  return formatDuration(checks)
}

export const formatCompletedDateAndTime = (collectedAt: string) => {
  if (collectedAt !== '') {
    const parsedCollectedAt = parseDate(collectedAt)
    const collectionDate = `${format(parsedCollectedAt, 'EEE, dd MMMM')}`
    const collectionTime = `${format(parsedCollectedAt, 'HH:mm')}`

    return `${collectionDate} ${collectionTime}`
  }

  return ''
}

export const getCollectionJobTimeAndDay = (stringDate: string) => {
  if (stringDate !== '') {
    const date = parseDate(stringDate)
    const collectionDate = format(date, 'dd MMM')
    const collectionTime = format(date, 'HH:mm')

    return `${collectionTime}, ${collectionDate}`
  }

  return ''
}

export const getDateAndTimeFromString = (dateTimeString: string) => {
  if (dateTimeString !== '') {
    const dateTime = parseDate(dateTimeString)
    const date = `${format(dateTime, 'dd MMMM yyyy')}`
    const time = `${format(dateTime, 'HH:mm')}`

    return { date, time }
  }

  return { date: '', time: '' }
}

export const buildAdvertUrl: TransformOptional<AdvertReference, Transform<string, string>> = flow(buildUrl, urlToPath =>
  flow(pushTo(['adverts']), join('/'), urlToPath),
)

export const retrievePostcode: Transform<PostcodePayload, string> = ({ postcode, postcodeDistrict }) =>
  toString(postcode || postcodeDistrict)

export const userName: Transform<UserName, string> = ({ firstName, lastName }) =>
  compactAndJoin(' ')([firstName, lastName])

export const getPrice: Transform<AdvertBase, Optional<Price>> = ({ jobDetails, publicDetails }) =>
  jobDetails?.price || publicDetails.price

export const isPriceVisible: Transform<AdvertPriceVisibilityPayload, boolean> = ({
  advert: {
    junkDetails: { isReusable },
    status,
    view,
  },
  meta,
}) =>
  view === 'owner' ||
  hasDataCapability('collector')(meta) ||
  !(status === 'active' || (isReusable && hasDataCapability('junklover')(meta)))

const supplierTypeToCollectionDone: Record<SupplierType, string> = {
  collector: 'waste collector',
  junklover: 'Junk Reuser',
}

export const collectionDoneCaption: TransformOptional<SupplierType, string> = supplierType =>
  'Collection done' + (supplierType ? ` by ${supplierTypeToCollectionDone[supplierType]}` : '')

export const toAdvertPayload: Transform<AdvertIdAndUrl, AdvertPayload> = ({ advertId: id, advertUrl: url }) => ({
  id,
  url,
})

export const sortCancellationReasons = flow(partition<Reason>({ value: 'other' }), ([otherReason, restReasons]) => [
  ...shuffle(restReasons),
  ...otherReason,
])

export const advertResponseToAdvertPayload: Transform<AdvertResponse, AdvertPayload> = ({
  body: { id },
  meta: { url },
}) => ({
  id,
  url,
})

const advertReferenceRegExpString = '[A-Z]-[0-9A-Z]{8,20}'

export const isAdvertReference: Validation = value => new RegExp(`^${advertReferenceRegExpString}$`, 'i').test(value)

export const parseAdvertReference: Transform<string, Optional<string>> = seoReference =>
  new RegExp(`^${advertReferenceRegExpString}`, 'i').exec(seoReference)?.[0]
