import { checkOrGetToken as coreCheckOrGetToken, getCredentials, getSessionToken, OAuthResult } from '@lovejunk/core'
import { Thunk } from 'types/thunk'

export const checkOrGetToken: Thunk<OAuthResult> = () => (dispatch, getState) => {
  const rootState = getState()
  const credentials = getCredentials(rootState)
  const sessionToken = getSessionToken(rootState)

  return dispatch(coreCheckOrGetToken({ credentials, sessionToken })())
}
