import { FailureAction, Nullable, PayloadAction, Postcode, Suggestion } from '@lovejunk/core'
import { Action } from 'redux'

export const SET_POSTCODE = 'SET_POSTCODE'
export const SET_POSTCODE_CHECKED = 'SET_POSTCODE_CHECKED'
export const SUBMIT_POSTCODE = 'SUBMIT_POSTCODE'

export type SetPostcode = PayloadAction<typeof SET_POSTCODE, Nullable<Postcode>>
export type SetPostcodeChecked = Action<typeof SET_POSTCODE_CHECKED>
export type SubmitPostcode = Action<typeof SUBMIT_POSTCODE>

export const GET_ADDRESSES_START = 'GET_ADDRESSES_START'
export const GET_ADDRESSES_SUCCESS = 'GET_ADDRESSES_SUCCESS'
export const GET_ADDRESSES_FAILURE = 'GET_ADDRESSES_FAILURE'

export type GetAddressesStart = Action<typeof GET_ADDRESSES_START>
export type GetAddressesSuccess = PayloadAction<typeof GET_ADDRESSES_SUCCESS, Suggestion[]>
export type GetAddressesFailure = FailureAction<typeof GET_ADDRESSES_FAILURE>

export const SET_POSTCODE_SUPPORTED = 'SET_POSTCODE_SUPPORTED'

export type SetPostcodeSupported = PayloadAction<typeof SET_POSTCODE_SUPPORTED, boolean>

export const SET_POSTCODE_VALID = 'SET_POSTCODE_VALID'

export type SetPostcodeValid = PayloadAction<typeof SET_POSTCODE_VALID, boolean>

export type GetAddressesResult = GetAddressesSuccess | GetAddressesFailure
export type GetAddresses = GetAddressesStart | GetAddressesSuccess | GetAddressesFailure

export type AddressAction =
  | GetAddresses
  | SetPostcode
  | SetPostcodeChecked
  | SetPostcodeSupported
  | SetPostcodeValid
  | SubmitPostcode
