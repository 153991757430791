import { FormEventHandler, useCallback } from 'react'

type OnSubmit = FormEventHandler<HTMLFormElement>

const useFormSubmit = (onSubmit: OnSubmit) =>
  useCallback<OnSubmit>(
    event => {
      event.preventDefault()

      onSubmit(event)
    },
    [onSubmit],
  )

export default useFormSubmit
