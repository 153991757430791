import { constant, times } from 'lodash/fp'
import React, { useCallback, useMemo, useState } from 'react'

import { BackToChat } from '../entities/account/types'
import { AddIdentifierData } from '../entities/login/types'
import {
  AuthIdentifierNotVerifiedPayload,
  AuthIdentifierVerifiedPayload,
  Otp,
  ResendOtpPayload,
  VERIFY_AUTH_IDENTITY_OTP_SUCCESS,
  VERIFY_OTP_SUCCESS,
  VerifyAuthIdentityOtpPayload,
  VerifyAuthIdentityOtpResult,
  VerifyOtpPayload,
  VerifyOtpResult,
} from '../entities/otp/types'
import { Color } from '../theme'
import { AuthIdentifierType, Dispatch, NoOp } from '../types/core'
import { ComponentFactory, ToElements } from '../types/ui'
import { formatAuthIdentifierType, formatAuthIdentifierValue } from '../utils/intl'

interface InputProps {
  onChange: Dispatch<Otp>
  onEnter: Dispatch<string>
  value: Otp
}

interface ResendButtonProps {
  onPress: NoOp
}

interface ChangeIdentifierButtonProps {
  onPress: NoOp
}

interface SupportLinkProps {
  subject: string

  color?: Color
}

interface ElementsProps {
  ResendButton: ResendButtonProps
  ChangeIdentifierButton: ChangeIdentifierButtonProps
  Group: unknown
  Help: unknown
  Input: InputProps
  Root: unknown
  SupportLink: SupportLinkProps
  Title: unknown
}

export interface Props {
  identifier: string
  length: number
  onChange: NoOp
  resend: Dispatch<ResendOtpPayload>
  resendAuthIdentityVerification: Dispatch<AddIdentifierData>
  type: AuthIdentifierType
  url: string
  verify: Dispatch<VerifyOtpPayload, Promise<VerifyOtpResult>>
  verifyAuthIdentity: Dispatch<VerifyAuthIdentityOtpPayload, Promise<VerifyAuthIdentityOtpResult>>

  backToChat?: BackToChat
  isVerification?: boolean
  locked?: boolean
  onAuthIdentityVerificationFailure?: Dispatch<AuthIdentifierNotVerifiedPayload>
  onAuthIdentityVerificationSuccess?: Dispatch<AuthIdentifierVerifiedPayload>
  onFailure?: Dispatch<AuthIdentifierNotVerifiedPayload>
  onSuccess?: Dispatch<AuthIdentifierVerifiedPayload>
}

type Elements = ToElements<ElementsProps>

const receivedType: Record<AuthIdentifierType, string> = {
  email: 'emails',
  phone: 'text messages',
}

const supportSubject: Record<AuthIdentifierType, string> = {
  email: 'email messages',
  phone: 'text messages',
}

const factory: ComponentFactory<Elements, Props> = ({
  ChangeIdentifierButton,
  Group,
  Help,
  Input,
  ResendButton,
  Root,
  SupportLink,
  Title,
}) =>
  function VerifyOtp({
    backToChat,
    identifier,
    isVerification,
    length,
    locked,
    onChange,
    onAuthIdentityVerificationFailure,
    onAuthIdentityVerificationSuccess,
    onFailure,
    onSuccess,
    resend,
    resendAuthIdentityVerification,
    type,
    url,
    verify,
    verifyAuthIdentity,
  }) {
    const onSuccessPayload = useMemo<AuthIdentifierVerifiedPayload>(
      () => ({ backToChat, locked, type }),
      [backToChat, locked, type],
    )
    const defaultOtp = useMemo<Otp>(() => times(constant(''))(length), [length])
    const [value, setValue] = useState<Otp>(defaultOtp)
    const clearOtp = useCallback(() => setValue(defaultOtp), [defaultOtp])
    const onVerify = useCallback<Dispatch<string>>(
      otp =>
        verify({ identifier, otp }).then(action => {
          clearOtp()

          action.type === VERIFY_OTP_SUCCESS
            ? onSuccess?.(onSuccessPayload)
            : onFailure?.({ ...onSuccessPayload, error: action.payload })
        }),
      [clearOtp, identifier, onFailure, onSuccess, onSuccessPayload, verify],
    )

    const onVerifyAuthIdentity = useCallback<Dispatch<string>>(
      otp =>
        verifyAuthIdentity({ identifier, otp, url }).then(action => {
          clearOtp()

          action.type === VERIFY_AUTH_IDENTITY_OTP_SUCCESS
            ? onAuthIdentityVerificationSuccess?.(onSuccessPayload)
            : onAuthIdentityVerificationFailure?.({ ...onSuccessPayload, error: action.payload })
        }),
      [
        clearOtp,
        identifier,
        onAuthIdentityVerificationFailure,
        onAuthIdentityVerificationSuccess,
        onSuccessPayload,
        url,
        verifyAuthIdentity,
      ],
    )

    const onSend = useMemo(
      () => (isVerification ? onVerifyAuthIdentity : onVerify),
      [isVerification, onVerify, onVerifyAuthIdentity],
    )

    const onResend = useCallback<NoOp>(
      () => (isVerification ? resendAuthIdentityVerification : resend)({ backToChat, identifier, type }),
      [backToChat, identifier, isVerification, resend, resendAuthIdentityVerification, type],
    )

    return (
      <Root>
        <Title>Enter code sent to your {type} so we know you are a real person</Title>
        <Input onChange={setValue} onEnter={onSend} value={value} />
        <Group>
          <ResendButton onPress={onResend}>Resend to {formatAuthIdentifierValue(identifier)}</ResendButton>
          <ChangeIdentifierButton onPress={onChange}>Change {formatAuthIdentifierType(type)}</ChangeIdentifierButton>
          <Help>
            If you do not receive any {receivedType[type]}, please contact us at{' '}
            <SupportLink color="black" subject={supportSubject[type]} />
          </Help>
        </Group>
      </Root>
    )
  }

export default factory
