import React, { CSSProperties, FC } from 'react'
import { css, styled } from 'styled'

import Column from '../containers/Column'
import MainButton, { Props as MainButtonProps } from './MainButton'

interface Props extends MainButtonProps {
  containerSpacing?: number
  containerStyle?: CSSProperties
  shrink?: boolean
}

// We wrap in `Root` because `SubmitButton` acts as Form child and gets margin applied
// so in order to add extra space we add it to `MainButton`.
const SubmitButton: FC<Props> = ({ containerStyle, shrink = true, ...props }) => (
  <Root shrink={shrink} style={containerStyle}>
    <MainButton {...props} />
  </Root>
)

const shrinkCss = css`
  align-items: center;
`

interface RootProps {
  shrink?: boolean
}

const Root = styled(Column)<RootProps>`
  margin: 2em 0;
  ${({ shrink }) => (shrink ? shrinkCss : undefined)}
`

export default SubmitButton
