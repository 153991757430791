import { useCallback, useEffect, useRef, useState } from 'react'

const useHover = <T extends HTMLButtonElement>() => {
  const ref = useRef<T>(null)
  const { current } = ref
  const [isHover, setHover] = useState<boolean>(false)

  const onMouseEnter = useCallback(() => setHover(true), [setHover])
  const onMouseLeave = useCallback(() => setHover(false), [setHover])

  useEffect(() => {
    current?.addEventListener('mouseenter', onMouseEnter)
    current?.addEventListener('mouseleave', onMouseLeave)
  }, [current, onMouseEnter, onMouseLeave])

  return { isHover, ref }
}

export default useHover
