import { Action } from 'redux'

import { Nullable, PayloadAction } from '../../types/core'

export const CANCEL_CUSTOMER_SIGNUP = 'CANCEL_CUSTOMER_SIGNUP'

export type CancelCustomerSignup = Action<typeof CANCEL_CUSTOMER_SIGNUP>

export const SET_IS_CUSTOMER_VAT_REGISTERED = 'SET_IS_CUSTOMER_VAT_REGISTERED'

export type SetIsCustomerVatRegistered = PayloadAction<typeof SET_IS_CUSTOMER_VAT_REGISTERED, Nullable<boolean>>

export type SignupCustomerAction = CancelCustomerSignup | SetIsCustomerVatRegistered
