import { ApiActionConfig, configureApiAction } from '@lovejunk/core'
import RootState from 'core/state'
import { checkOrGetToken } from 'entities/oauth'
import SupportedAction from 'types/supported-action'

const apiActionConfig: ApiActionConfig<RootState, SupportedAction> = {
  checkOrGetToken,
}

export default () => configureApiAction(apiActionConfig)
