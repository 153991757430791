import { flow } from 'lodash'

import Reducer from '../../types/reducer'
import { tagsLens, tagsLoadedLens } from './lens'
import TagsState, { tagsDefaultState } from './state'
import { FETCH_TAGS_FAILURE, FETCH_TAGS_SUCCESS } from './types'

const reducer: Reducer<TagsState> = (state = tagsDefaultState(), action) => {
  switch (action.type) {
    case FETCH_TAGS_FAILURE:
      return tagsLoadedLens(state).set(true)
    case FETCH_TAGS_SUCCESS:
      return flow(
        tagsLens,
        l => l.set(action.payload),
        tagsLoadedLens,
        l => l.set(true),
      )(state)
    default:
      return state
  }
}

export default reducer
