import { AdvertItem, AdvertItemResource } from '../types'

export const advertsFixture: AdvertItemResource[] = [
  {
    counterOfferBelongsToViewer: false,
    createdAt: '2020-05-27T13:55:22.562Z',
    distance: {
      unit: 'metre',
      value: 50,
    },
    endAt: '',
    hasJunkLoverOffer: false,
    id: 1,
    imageUrl: 'https://picsum.photos/500/700',
    isReusable: false,
    isTrade: false,
    isUlez: false,
    latitude: 0,
    longitude: 0,
    meta: {
      selfUrl: 'selfUrl',
    },
    offeredAt: '2020-05-27T13:55:22.562Z',
    price: {
      amount: 10,
      currency: 'GBP',
    },
    reference: 'reference-1',
    startAt: '',
    status: 'collected',
    title: 'Cardboard Box',
    url: '',
    viewerIsPreferredCollector: false,
  },
  {
    counterOfferBelongsToViewer: false,
    createdAt: '2020-05-27T13:55:22.562Z',
    distance: {
      unit: 'metre',
      value: 500,
    },
    endAt: '',
    hasJunkLoverOffer: false,
    id: 2,
    imageUrl: 'https://picsum.photos/400/700',
    isReusable: false,
    isTrade: true,
    isUlez: false,
    latitude: 0,
    longitude: 0,
    meta: {
      selfUrl: 'selfUrl',
    },
    offeredAt: '2020-05-27T10:53:34.718Z',
    price: {
      amount: 25,
      currency: 'GBP',
    },
    reference: 'reference-2',
    startAt: '',
    status: 'active',
    title: 'Metal Drawer Tools',
    url: '',
    viewerIsPreferredCollector: false,
  },
]

export const newAdvertsFixture: AdvertItemResource[] = [
  {
    counterOfferBelongsToViewer: false,
    createdAt: '2020-05-27T13:55:22.562Z',
    distance: {
      value: 1000,
      unit: 'metre',
    },
    endAt: '',
    hasJunkLoverOffer: false,
    id: 3,
    imageUrl: 'https://picsum.photos/300/700',
    isReusable: false,
    isTrade: true,
    isUlez: false,
    latitude: 0,
    longitude: 0,
    meta: {
      selfUrl: 'selfUrl',
    },
    offeredAt: '2020-05-22T11:30:21.958Z',
    price: {
      currency: 'GBP',
      amount: 10,
    },
    reference: 'reference-3',
    startAt: '',
    status: 'helpunderway',
    title: 'Item 1 from page 2',
    url: 'https://staging-elmer.anyjunk.co.uk/v1/elmer/customer-adverts/2791',
    viewerIsPreferredCollector: false,
  },
  {
    counterOfferBelongsToViewer: false,
    createdAt: '2020-05-27T13:55:22.562Z',
    distance: {
      value: 1000,
      unit: 'metre',
    },
    endAt: '',
    hasJunkLoverOffer: false,
    id: 4,
    imageUrl: 'https://picsum.photos/300/700',
    isReusable: false,
    isTrade: true,
    isUlez: false,
    latitude: 0,
    longitude: 0,
    meta: {
      selfUrl: 'selfUrl',
    },
    offeredAt: '2020-05-20T09:07:08.662Z',
    price: {
      currency: 'GBP',
      amount: 10,
    },
    reference: 'reference-4',
    startAt: '',
    status: 'helpunderway',
    title: 'Item 2 from page 2',
    url: 'https://staging-elmer.anyjunk.co.uk/v1/elmer/customer-adverts/2791',
    viewerIsPreferredCollector: false,
  },
]

export const advertItemFixture: AdvertItem = {
  counterOfferBelongsToViewer: false,
  createdAt: '2020-05-27T13:55:22.562Z',
  distance: {
    unit: 'metre',
    value: 50,
  },
  endAt: '',
  hasJunkLoverOffer: false,
  id: 1,
  imageUrl: 'https://picsum.photos/500/700',
  isReusable: false,
  isTrade: false,
  isUlez: false,
  latitude: 0,
  longitude: 0,
  meta: {
    selfUrl: 'selfUrl',
  },
  offeredAt: new Date('2020-05-27T13:55:22.562Z'),
  price: {
    amount: 10,
    currency: 'GBP',
  },
  reference: 'reference-1',
  startAt: '',
  status: 'collected',
  title: 'Cardboard Box',
  url: '',
  viewerIsPreferredCollector: false,
}

export const updatedItemsFixture: AdvertItem[] = [
  {
    counterOfferBelongsToViewer: false,
    createdAt: '2020-05-27T13:55:22.562Z',
    distance: {
      unit: 'metre',
      value: 50,
    },
    endAt: '',
    hasJunkLoverOffer: false,
    id: 1,
    imageUrl: 'https://picsum.photos/500/700',
    isReusable: false,
    isTrade: false,
    isUlez: false,
    latitude: 0,
    longitude: 0,
    meta: {
      selfUrl: 'selfUrl',
    },
    offeredAt: new Date('2020-05-27T13:55:22.562Z'),
    price: {
      amount: 10,
      currency: 'GBP',
    },
    reference: 'reference-1',
    startAt: '',
    status: 'collected',
    title: 'Cardboard Box',
    url: '',
    viewerIsPreferredCollector: false,
  },
  {
    counterOfferBelongsToViewer: false,
    createdAt: '2020-05-27T13:55:22.562Z',
    distance: {
      unit: 'metre',
      value: 500,
    },
    endAt: '',
    hasJunkLoverOffer: false,
    id: 2,
    imageUrl: 'https://picsum.photos/400/700',
    isReusable: false,
    isTrade: true,
    isUlez: false,
    latitude: 0,
    longitude: 0,
    meta: {
      selfUrl: 'selfUrl',
    },
    offeredAt: new Date('2020-05-27T10:53:34.718Z'),
    price: {
      amount: 25,
      currency: 'GBP',
    },
    reference: 'reference-2',
    startAt: '',
    status: 'active',
    title: 'Metal Drawer Tools',
    url: '',
    viewerIsPreferredCollector: false,
  },
  {
    counterOfferBelongsToViewer: false,
    createdAt: '2020-05-27T13:55:22.562Z',
    distance: {
      value: 1000,
      unit: 'metre',
    },
    endAt: '',
    hasJunkLoverOffer: false,
    id: 3,
    imageUrl: 'https://picsum.photos/300/700',
    isReusable: false,
    isTrade: true,
    isUlez: false,
    latitude: 0,
    longitude: 0,
    meta: {
      selfUrl: 'selfUrl',
    },
    offeredAt: new Date('2020-05-22T11:30:21.958Z'),
    price: {
      currency: 'GBP',
      amount: 10,
    },
    reference: 'reference-3',
    startAt: '',
    status: 'helpunderway',
    title: 'Item 1 from page 2',
    url: 'https://staging-elmer.anyjunk.co.uk/v1/elmer/customer-adverts/2791',
    viewerIsPreferredCollector: false,
  },
  {
    counterOfferBelongsToViewer: false,
    createdAt: '2020-05-27T13:55:22.562Z',
    distance: {
      value: 1000,
      unit: 'metre',
    },
    endAt: '',
    hasJunkLoverOffer: false,
    id: 4,
    imageUrl: 'https://picsum.photos/300/700',
    isReusable: false,
    isTrade: true,
    isUlez: false,
    latitude: 0,
    longitude: 0,
    meta: {
      selfUrl: 'selfUrl',
    },
    offeredAt: new Date('2020-05-20T09:07:08.662Z'),
    price: {
      currency: 'GBP',
      amount: 10,
    },
    reference: 'reference-4',
    startAt: '',
    status: 'helpunderway',
    title: 'Item 2 from page 2',
    url: 'https://staging-elmer.anyjunk.co.uk/v1/elmer/customer-adverts/2791',
    viewerIsPreferredCollector: false,
  },
]
