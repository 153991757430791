import Reducer from '../../types/reducer'
import { utmLens } from './lens'
import State, { defaultState } from './state'
import { SAVE_UTM } from './types'

const reducer: Reducer<State> = (state = defaultState(), action) => {
  switch (action.type) {
    case SAVE_UTM:
      return utmLens(state).set(action.payload)
    default:
      return state
  }
}

export default reducer
