import { Check as CheckIcon } from 'assets/icons'
import React, { FC } from 'react'
import { styled, ThemeProps } from 'styled'

import CenteredColumn from '../../containers/CenteredColumn'

interface CheckProps {
  disabled: boolean
  size: number
}

const Check: FC<CheckProps> = props => (
  <Root {...props}>
    <Image src={CheckIcon} alt="check" />
  </Root>
)

const Image = styled.img`
  width: 100%;
  height: 100%;
`
interface RootProps extends ThemeProps {
  disabled: boolean
  size: number
}

const Root = styled(CenteredColumn)<RootProps>`
  background-color: ${({ disabled, theme: { colors } }) => (disabled ? colors.grey : colors.selected)};
  border-radius: ${({ size }) => (3 * size) / 16}em;
  justify-content: center;
`

export default Check
