import 'react-image-gallery/styles/css/image-gallery.css'
import './index.css'

import { AdvertAsset, createImgixParams } from '@lovejunk/core'
import React, { FC, useMemo } from 'react'
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery'

type Props = {
  id: string
  images: AdvertAsset[]
  local?: boolean
  videos?: AdvertAsset[]
}

const addParams = createImgixParams(400)

const Carousel: FC<Props> = ({ id, images, local = false, videos }) => {
  const items = useMemo<ReactImageGalleryItem[]>(() => {
    const result: ReactImageGalleryItem[] = []

    images.forEach((img, index) => {
      const alt = `${id} - ${index + 1}`

      result.push({
        original: local ? img.fullUrl : addParams(img.fullUrl),
        originalAlt: alt,
        thumbnailAlt: alt,
      })
    })

    videos?.forEach(img => {
      result.push({
        renderItem: ({ original }) => <video className="image-gallery-image" controls src={original} />,
        original: img.fullUrl,
      })
    })

    return result
  }, [id, images, local, videos])

  return (
    <ImageGallery
      items={items}
      showPlayButton={false}
      showBullets={items.length > 1}
      showThumbnails={false}
      showFullscreenButton={false}
    />
  )
}

export default Carousel
