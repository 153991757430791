import React, { ComponentPropsWithoutRef, FC } from 'react'
import { styled } from 'styled'
import { device } from 'utils/css'

interface Props extends ComponentPropsWithoutRef<'form'> {
  compact?: boolean
}

const Form: FC<Props> = props => <Root {...props} />

const Root = styled.form<Props>`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding: ${({ compact }) => (compact ? '2em' : '4em 6em')};

  @media ${device.mobile} {
    padding: 0 1em 1em;
  }
`

export default Form
