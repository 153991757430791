import { buildLens, lensFactory } from '../../helpers'
import RootState from '../../state'
import { GetLens } from '../../types/core'
import State from './state'

export const referralsRootLens: GetLens<RootState, State> = state => () => state.referrals

const lens = lensFactory<State>()

export const referralIsAutoLens = lens('isAuto')
export const referralCodeLens = lens('code')
export const referralErrorLens = lens('error')
export const referralsDataLens = lens('codeData')
export const referralInfoLens = lens('info')
export const showCreditInfoLens = lens('showCreditInfo')
export const referralsViewDataContentLens = lens('viewDataContent')

export const referralsData = buildLens(referralsRootLens, referralsDataLens)
export const referralsViewDataContent = buildLens(referralsRootLens, referralsViewDataContentLens)

export default referralsRootLens
