import { flow } from 'lodash/fp'

import { Get } from '../../types/core'
import SupportedAction from '../../types/supported-action'
import { GenericThunkAction, GenericThunkDispatch } from '../../types/thunk'

interface Payload<P, Q> {
  result: Get<Q>

  start?: Get<P>
}

export const createThunkMock =
  <A extends SupportedAction, P extends A, Q extends A, S>({
    result,
    start,
  }: Payload<P, Q>): GenericThunkAction<Q, S, A> =>
  async (dispatch: GenericThunkDispatch<S, A>) => {
    if (start) await dispatch(start())

    return dispatch(result())
  }

export default flow(createThunkMock, thunkMock => jest.fn().mockReturnValue(thunkMock))
