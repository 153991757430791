import React from 'react'

import { ComponentFactory, ToElements } from '../types/ui'

export interface ElementsProps {
  Bold: unknown
}

type Elements = ToElements<ElementsProps>

const factory: ComponentFactory<Elements> = ({ Bold }) =>
  function PostSetupSubtitle() {
    return (
      <>
        Enter a valid payment method to secure your booking. <Bold>No payment is taken at this time.</Bold> You pay
        after collection once you confirm you are happy with the job.
      </>
    )
  }

export default factory
