import { flow } from 'lodash/fp'

import { buildLens, isNotNull, lensFactory } from '../../helpers'
import RootState from '../../state'
import { GetLens } from '../../types/core'
import State from './state'

const tagsRootLens: GetLens<RootState, State> = state => () => state.tags

const lens = lensFactory<State>()

export const tagsLens = lens('items')
export const tagsLoadedLens = lens('loaded')

export const getTags = buildLens(tagsRootLens, tagsLens)
export const hasTags = flow(getTags, isNotNull)
export const tagsLoaded = buildLens(tagsRootLens, tagsLoadedLens)

export default tagsRootLens
