import { ThemeProps } from 'styled'
import { createGlobalStyle } from 'styled-components'
import { device } from 'utils/css'

const GlobalStyle = createGlobalStyle<ThemeProps>`
  * {
    box-sizing: border-box;
  }
  
  body {
    overflow-x: clip;
  }

  body {
    margin: 0;
    background-color: ${({ theme: { colors } }) => colors.white};
    font-family: Lato-Regular, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media ${device.mobile} {
      background-color: white;
    }
  }

  #root {
    display: flex;
    flex-direction: column;
  }

  .ReactModal__Overlay--Overrides {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    // Should be on very top. "react-image-gallery" elements have z-index of 4 and 5.
    z-index: 10;
    background-color: ${({ theme: { colors } }) => colors.contrast2}cc;
    display: none;
  }

  .ReactModal__Overlay--after-open {
    display: flex;
  }

  .ReactModal__Content--Overrides {
    width: 40em;
    min-height: 22em;
    background-color: ${({ theme: { colors } }) => colors.white};
    border-width: 1px;
    border-color: ${({ theme: { colors } }) => colors.light};
    border-radius: 8px;
    box-shadow: 0 6px 10px 0 rgba(0,0,0,0.12);
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${device.mobile} {
      flex: 1;
      width: 100%;
      border-color: none;
      border-radius: 0;
      border-width: 0;
      box-shadow: none;
    }
  }

  .ReactModal__Content--AutoWidth {
    width: auto;
  }
`

export default GlobalStyle
