import { flow, toString, trim } from 'lodash/fp'
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'

import { LoginPayload } from '../entities/login/types'
import { isPresent } from '../helpers'
import { AuthIdentifierType, AuthIdentifierValue, Dispatch, NoOp, Optional } from '../types/core'
import { ToElements } from '../types/ui'
import { defaultCountry } from '../utils'
import { isEmail, isPhoneNumber, isPhoneNumberValid } from '../validations'

interface InputProps {
  label: string
  onChange: Dispatch<AuthIdentifierValue>
  value: AuthIdentifierValue

  error?: string
}

interface PhoneInputProps {
  onChange: Dispatch<AuthIdentifierValue>
  label: string
  error?: string
}

interface SubmitButtonProps {
  onPress: NoOp
  text: string

  progress?: boolean
  progressText?: string
}

interface ElementsProps {
  CountryCodeText: unknown
  FormField: unknown
  Input: InputProps
  Label: unknown
  PhoneInput: PhoneInputProps
  Note: unknown
  SubmitButton: SubmitButtonProps
}

export interface OwnProps {
  onSubmit: Dispatch<LoginPayload>

  beforeOnSubmit?: NoOp
  phoneMode?: boolean
}

export interface StateProps {
  isSigningIn: boolean

  identifier?: AuthIdentifierValue
}

type Props = StateProps & OwnProps

type Elements = ToElements<ElementsProps>

export interface Handle {
  handleSubmit: NoOp
}

const factory = ({ CountryCodeText, FormField, Input, Label, Note, PhoneInput, SubmitButton }: Elements) =>
  forwardRef<Handle, Props>(function Login(
    { beforeOnSubmit, identifier: defaultIdentifier, isSigningIn, onSubmit, phoneMode },
    ref,
  ) {
    const [identifier, setIdentifier] = useState<AuthIdentifierValue>('')
    const [isDirty, setIsDirty] = useState<boolean>(false)
    const type = useMemo<Optional<AuthIdentifierType>>(() => {
      if (phoneMode) return 'phone'
      if (isEmail(identifier)) return 'email'
      if (isPhoneNumber(identifier)) return 'phone'
    }, [identifier, phoneMode])
    const isValid = useMemo(() => !phoneMode || isPhoneNumberValid(identifier), [identifier, phoneMode])
    const identifierError = useMemo(
      () => (isPresent(identifier) ? (type ? undefined : 'Please enter email or phone') : 'Required'),
      [identifier, type],
    )
    const phoneNumberError = useMemo(
      () => (isDirty && !isValid ? 'Invalid phone number' : undefined),
      [isDirty, isValid],
    )
    const handleSubmit = useCallback(() => {
      beforeOnSubmit?.()

      setIsDirty(true)

      if (!type || !isValid) return

      onSubmit({ identifier, type })
    }, [beforeOnSubmit, identifier, isValid, onSubmit, type])

    const onIdentifierChange = useMemo(() => flow(trim, setIdentifier), [])

    useImperativeHandle(ref, () => ({ handleSubmit }), [handleSubmit])

    useEffect(() => {
      setIdentifier(toString(defaultIdentifier))
    }, [defaultIdentifier])

    return (
      <>
        {phoneMode ? (
          <FormField>
            <Label>Country code</Label>
            <CountryCodeText>United Kingdom ({defaultCountry.dialCode})</CountryCodeText>
          </FormField>
        ) : null}
        <FormField>
          {phoneMode ? (
            <PhoneInput error={phoneNumberError} label="Mobile" onChange={setIdentifier} />
          ) : (
            <Input
              error={isDirty ? identifierError : undefined}
              label="Phone or Email"
              onChange={onIdentifierChange}
              value={identifier}
            />
          )}
          {!phoneMode && (
            <Note>Note: unless you used your email to sign up or verified it, please use your phone.</Note>
          )}
        </FormField>
        <SubmitButton
          onPress={handleSubmit}
          progress={isSigningIn}
          progressText="Logging in..."
          text="Login"
        ></SubmitButton>
      </>
    )
  })

export default factory
