import { clone, isEmpty, join, set, size, some, split, times } from 'lodash/fp'
import React, {
  createRef,
  FC,
  ForwardedRef,
  ForwardRefExoticComponent,
  PropsWithoutRef,
  RefAttributes,
  RefObject as Ref,
  useCallback,
  useMemo,
} from 'react'

import { Otp } from '../entities/otp/types'
import { Dispatch, NoOp, Transform } from '../types/core'
import { ToElements } from '../types/ui'

type Refs<T> = Ref<T>[]

interface InputProps<T> {
  autoFocus: boolean
  key: number
  maxLength: number
  onChange: Dispatch<string>
  onKeyPress: Dispatch<string>
  ref: ForwardedRef<T>
  value: string
}

interface ElementsProps {
  Root: unknown
}

// TODO: `ReturnType<typeof forwardRef>` is causing prettier to fail. Check after upgrade.
interface Elements<T> extends ToElements<ElementsProps> {
  Input: ForwardRefExoticComponent<PropsWithoutRef<InputProps<T>> & RefAttributes<T>>
}

interface Props<T> {
  beforeOnEnter: Dispatch<Refs<T>>
  onChange: Dispatch<Otp>
  onEnter: Dispatch<string>
  value: Otp
}

interface InputElement {
  focus: NoOp
}

export interface Types<T> {
  input: T
  props: Omit<Props<T>, 'beforeOnEnter'>
  inputProps: InputProps<T>
  inputRef: Ref<T>
}

const factory = <T extends InputElement>({ Input, Root }: Elements<T>): FC<Props<T>> =>
  function OtpInput({ beforeOnEnter, onChange, onEnter, value, ...props }) {
    const maxLength = size(value)
    const inputRefs = useMemo<Refs<T>>(() => times(() => createRef<T>())(maxLength), [maxLength])

    const onCodeUpdated = useCallback<Dispatch<Otp>>(
      code => {
        onChange(code)

        if (!some(isEmpty)(code)) {
          beforeOnEnter(inputRefs)
          onEnter(join('')(code))
        }
      },
      [beforeOnEnter, inputRefs, onChange, onEnter],
    )

    const createOnKeyPress = useCallback<Transform<number, Dispatch<string>>>(
      index => key => {
        if (key === 'Backspace' && isEmpty(value[index])) {
          const prevIndex = index - 1
          const prevRef = inputRefs[prevIndex]
          const oldCode = clone(value)
          const code = set(prevIndex)('')(oldCode)

          prevRef?.current?.focus()
          onCodeUpdated(code)
        }
      },
      [inputRefs, onCodeUpdated, value],
    )

    // NOTE: This will produce 3 SAVE_OTP actions (for length 0, 1 and SIZE) when performing SMS autofill.
    // This is because autofill will call this handler 5 times with following values: '', 'a', 'ab', 'abc', 'abcd'.
    const createOnInputChange = useCallback<Transform<number, Dispatch<string>>>(
      index => text => {
        const textSize = size(text)

        // This handles deletion or single character
        if (textSize === 0 || textSize === 1) {
          const oldCode = clone(value)
          const code = set(index)(text)(oldCode)
          const nextRef = inputRefs[index + 1]

          if (textSize === 1) nextRef?.current?.focus()

          onCodeUpdated(code)
        }
        // This handles autofill
        else if (textSize === maxLength) {
          const code = split('')(text) as Otp

          onCodeUpdated(code)
        }
      },
      [inputRefs, maxLength, onCodeUpdated, value],
    )

    const renderInput = useCallback(
      (value: string, index: number) => (
        <Input
          autoFocus={index === 0 && isEmpty(value)}
          key={index}
          maxLength={maxLength}
          onChange={createOnInputChange(index)}
          onKeyPress={createOnKeyPress(index)}
          ref={inputRefs[index]}
          value={value}
        />
      ),
      [createOnInputChange, createOnKeyPress, inputRefs, maxLength],
    )

    return <Root {...props}>{value.map(renderInput)}</Root>
  }

export default factory
