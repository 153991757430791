import { Favourite } from './types'

interface State {
  items: Favourite[]
}

export const favouritesDefaultState: State = {
  items: [],
}

export type FavouritesState = State

export default State
