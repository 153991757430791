import { formatAmount, Price } from '@lovejunk/core'
import { compact, join } from 'lodash'
import React, { FC } from 'react'
import { styled } from 'styled'

import BaseText from '../../text/BaseText'

interface Props {
  title: string

  price?: Price
}

const ActivityItemTitle: FC<Props> = ({ price, title }) => (
  <Root>{join(compact([price && formatAmount(price.amount), title]), ' ')}</Root>
)

const Root = styled(BaseText)`
  font-family: Lato-Bold;
  font-size: 1.25em;
`

export default ActivityItemTitle
