import { lensFactory } from '../../helpers'
import RootState from '../../state'
import { GetLens } from '../../types/core'
import State from './state'

export const customerAdvertRootLens: GetLens<RootState, State> = state => () => state.customerAdvert

const lens = lensFactory<State>()

export const acceptingCounterOfferUrlLens = lens('acceptingCounterOfferUrl')
export const adjustPricePayloadLens = lens('adjustPricePayload')
export const addFavouriteCollectorIntentLens = lens('addFavouriteCollectorIntent')
export const addFavouriteCollectorErrorLens = lens('addFavouriteCollectorError')
export const collectorsOffersLens = lens('collectorsOffers')
export const confirmationPayloadLens = lens('confirmationPayload')
export const isAddingCollectorToFavouritesLens = lens('isAddingCollectorToFavourites')
export const isCancellingLens = lens('isCancelling')
export const isConfirmingLens = lens('isConfirming')
export const isIncreasingLens = lens('isIncreasing')
export const isPaymentProcessingLens = lens('isPaymentProcessing')
export const isReviewingLens = lens('isReviewingLens')
export const junkReusersOffersLens = lens('junkReusersOffers')
export const showAddCardModalLens = lens('showAddCardModal')
export const showDeleteAdvertModalLens = lens('showDeleteAdvertModal')

export default customerAdvertRootLens
