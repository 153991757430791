import { Nullable } from '../../types/core'

export interface SignupCustomerState {
  isVatRegistered: Nullable<boolean>
}

export const signupCustomerDefaultState = (): SignupCustomerState => ({
  isVatRegistered: null,
})

export default SignupCustomerState
