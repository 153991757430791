import { flow } from 'lodash'

import Reducer from '../../types/reducer'
import { credentialsLens, isCheckingTokenLens, isGettingTokenLens } from './lens'
import State, { oauthDefaultState as defaultState } from './state'
import {
  CHECK_TOKEN_FAILURE,
  CHECK_TOKEN_START,
  CHECK_TOKEN_SUCCESS,
  GET_TOKEN_FAILURE,
  GET_TOKEN_START,
  GET_TOKEN_SUCCESS,
} from './types'

const reducer: Reducer<State> = (state = defaultState(), action) => {
  switch (action.type) {
    case GET_TOKEN_START:
      return flow(isGettingTokenLens, l => l.set(true))(state)

    case GET_TOKEN_SUCCESS:
      return flow(
        isGettingTokenLens,
        l => l.set(false),
        credentialsLens,
        l => l.set(action.payload),
      )(state)

    case GET_TOKEN_FAILURE:
      return flow(
        isGettingTokenLens,
        l => l.set(false),
        credentialsLens,
        l => l.set(null),
      )(state)

    case CHECK_TOKEN_START:
      return flow(isCheckingTokenLens, l => l.set(true))(state)

    case CHECK_TOKEN_SUCCESS:
      return flow(isCheckingTokenLens, l => l.set(false))(state)

    case CHECK_TOKEN_FAILURE:
      return flow(isCheckingTokenLens, l => l.set(false))(state)

    default:
      return state
  }
}

export default reducer
