import React, { forwardRef, ForwardRefRenderFunction, useCallback } from 'react'

import MainButton, { Props as MainButtonProps } from './MainButton'

interface Props extends Omit<MainButtonProps, 'onPress'> {
  url: string

  target?: '_blank' | '_self'
}

const ExternalLinkButton: ForwardRefRenderFunction<HTMLButtonElement, Props> = (
  { target = '_self', url, ...props },
  ref,
) => {
  const onPress = useCallback(() => window.open(url, target), [target, url])

  return <MainButton onPress={onPress} ref={ref} {...props} />
}

export default forwardRef(ExternalLinkButton)
