import { Action } from 'redux'

import { FailureAction, PayloadAction } from '../../../types/core'
import { GetTippingSiteResponse, GetTippingSitesResponse, PostTippingQrCodeUrlResponse } from '.'

export const UPDATE_ACCOUNT_EMAIL_START = 'UPDATE_ACCOUNT_EMAIL_START'
export const UPDATE_ACCOUNT_EMAIL_SUCCESS = 'UPDATE_ACCOUNT_EMAIL_SUCCESS'
export const UPDATE_ACCOUNT_EMAIL_FAILURE = 'UPDATE_ACCOUNT_EMAIL_FAILURE'

export const UPDATE_PREFERRED_PAYMENT_METHOD_START = 'UPDATE_PREFERRED_PAYMENT_METHOD_START'
export const UPDATE_PREFERRED_PAYMENT_METHOD_SUCCESS = 'UPDATE_PREFERRED_PAYMENT_METHOD_SUCCESS'
export const UPDATE_PREFERRED_PAYMENT_METHOD_FAILURE = 'UPDATE_PREFERRED_PAYMENT_METHOD_FAILURE'

export type UpdateAccountEmailStart = Action<typeof UPDATE_ACCOUNT_EMAIL_START>
export type UpdateAccountEmailSuccess = Action<typeof UPDATE_ACCOUNT_EMAIL_SUCCESS>
export type UpdateAccountEmailFailure = FailureAction<typeof UPDATE_ACCOUNT_EMAIL_FAILURE>
export type UpdateAccountEmailResult = UpdateAccountEmailSuccess | UpdateAccountEmailFailure
export type UpdateAccountEmail = UpdateAccountEmailStart | UpdateAccountEmailResult

export type UpdatePreferredPaymentMethodStart = Action<typeof UPDATE_PREFERRED_PAYMENT_METHOD_START>
export type UpdatePreferredPaymentMethodSuccess = Action<typeof UPDATE_PREFERRED_PAYMENT_METHOD_SUCCESS>
export type UpdatePreferredPaymentMethodFailure = FailureAction<typeof UPDATE_PREFERRED_PAYMENT_METHOD_FAILURE>
export type UpdatePreferredPaymentMethodResult =
  | UpdatePreferredPaymentMethodSuccess
  | UpdatePreferredPaymentMethodFailure
export type UpdatePreferredPaymentMethod = UpdatePreferredPaymentMethodStart | UpdatePreferredPaymentMethodResult

export const GET_TIPPING_SITES_START = 'GET_TIPPING_SITES_START'
export const GET_TIPPING_SITES_SUCCESS = 'GET_TIPPING_SITES_SUCCESS'
export const GET_TIPPING_SITES_FAILURE = 'GET_TIPPING_SITES_FAILURE'
export type GetTippingSitesStart = Action<typeof GET_TIPPING_SITES_START>
export type GetTippingSitesSuccess = PayloadAction<typeof GET_TIPPING_SITES_SUCCESS, GetTippingSitesResponse>
export type GetTippingSitesFailure = FailureAction<typeof GET_TIPPING_SITES_FAILURE>
export type GetTippingSitesResult = GetTippingSitesSuccess | GetTippingSitesFailure
export type GetTippingSites = GetTippingSitesStart | GetTippingSitesResult

export const GET_TIPPING_SITE_START = 'GET_TIPPING_SITE_START'
export const GET_TIPPING_SITE_SUCCESS = 'GET_TIPPING_SITE_SUCCESS'
export const GET_TIPPING_SITE_FAILURE = 'GET_TIPPING_SITE_FAILURE'
export type GetTippingSiteStart = Action<typeof GET_TIPPING_SITE_START>
export type GetTippingSiteSuccess = PayloadAction<typeof GET_TIPPING_SITE_SUCCESS, GetTippingSiteResponse>
export type GetTippingSiteFailure = FailureAction<typeof GET_TIPPING_SITE_FAILURE>
export type GetTippingSiteResult = GetTippingSiteSuccess | GetTippingSiteFailure
export type GetTippingSite = GetTippingSiteStart | GetTippingSiteResult

export const POST_TIPPING_QR_CODE_URL_START = 'POST_TIPPING_QR_CODE_URL_START'
export const POST_TIPPING_QR_CODE_URL_SUCCESS = 'POST_TIPPING_QR_CODE_URL_SUCCESS'
export const POST_TIPPING_QR_CODE_URL_FAILURE = 'POST_TIPPING_QR_CODE_URL_FAILURE'
export type PostTippingQrCodeUrlStart = Action<typeof POST_TIPPING_QR_CODE_URL_START>
export type PostTippingQrCodeUrlSuccess = PayloadAction<
  typeof POST_TIPPING_QR_CODE_URL_SUCCESS,
  PostTippingQrCodeUrlResponse
>
export type PostTippingQrCodeUrlFailure = FailureAction<typeof POST_TIPPING_QR_CODE_URL_FAILURE>
export type PostTippingQrCodeUrlResult = PostTippingQrCodeUrlSuccess | PostTippingQrCodeUrlFailure
export type PostTippingQrCodeUrl = PostTippingQrCodeUrlStart | PostTippingQrCodeUrlResult

export type AccountAction =
  | GetTippingSite
  | GetTippingSites
  | PostTippingQrCodeUrl
  | UpdateAccountEmail
  | UpdatePreferredPaymentMethod
