import {
  AdvertReference,
  AssetType,
  assetTypeToMimeType,
  isDefined,
  MediaType,
  Nullable,
  nullDefaultsTo,
  Optional,
  parseDecimalInt,
  pushTo,
  SearchParams,
  Transform,
  TransformOptional,
} from '@lovejunk/core'
import { concat, escapeRegExp, flow, join, kebabCase, map, pickBy, replace, toNumber, toString } from 'lodash/fp'
import { createSearchParams } from 'react-router-dom'
import { Filter, SeoContent } from 'types'

export const searchParamsToString: TransformOptional<SearchParams, Optional<string>> = params =>
  params && '?' + createSearchParams(pickBy<Optional<string>, string>(isDefined)(params)).toString()

export const seoAdvertReference: Transform<AdvertReference, string> = reference => reference.slice(7)

export const addLeading: Transform<string, Transform<string, string>> = flow(escapeRegExp, prefix =>
  replace(new RegExp(`^${prefix}?(\\d*)`), `${prefix}$1`),
)

export const removeLeading: Transform<string, Transform<string, string>> = flow(escapeRegExp, prefix =>
  replace(new RegExp(`^${prefix}?(\\d*)`), '$1'),
)

export const numericTransform: Transform<string, string> = replace(new RegExp('\\D', 'g'), '')

type ParamTo<T> = Transform<Nullable<string>, T>
type ToParam<T> = Transform<T, string>

export const numberToParam: ToParam<number> = toString
export const paramToNumber: ParamTo<number> = flow(nullDefaultsTo('0'), parseDecimalInt)
export const paramToDecimal = paramToNumber
export const paramToFloat: ParamTo<number> = flow(nullDefaultsTo('0'), parseFloat)
export const booleanToParam: ToParam<boolean> = flow(toNumber, numberToParam)
export const paramToBoolean: ParamTo<boolean> = flow(paramToDecimal, Boolean)

export const toSeoReference = (reference: string, title: string): string =>
  join('-')([reference, kebabCase(title), 'disposal'])

export const residentialSeoContent: SeoContent = {
  description: 'Great value rubbish collection happening on LoveJunk every day!',
  guideUrl: 'https://www.lovejunk.com/blog/guides-advice/rubbish-collection-ultimate-guide/',
  guideUrlText: 'guide to rubbish collection',
  textContent:
    'Here are the latest rubbish collection and household junk removal jobs completed on the LoveJunk marketplace. Click any photo to see more information about the collection, including amount and type of rubbish, collection date and pickup window, and region. You can also see extra photos and any video of the rubbish. The time showing in the left hand bottom corner of the collection is the time it took for that listing to find a collector match at a price acceptable to the customer. A green heart means that the rubbish collected was either reused or taken to a disposal site that diverts 100% of waste from landfill (through recycling or waste from energy). For more background on this subject, read our guide to rubbish collection.',
  textTitle: 'Rubbish collection',
  title: 'Rubbish collection - find your cheapest collector',
  url: 'rubbish-collection',
}

export const tradeSeoContent: SeoContent = {
  description: 'Great value builder waste removal happening on LoveJunk every day!',
  guideUrl:
    'https://www.lovejunk.com/blog/trash-talk/guide-prices-and-example-photos-for-diy-waste-disposal-in-london/',
  guideUrlText: 'this guide',
  textContent:
    'These are the latest builder waste removal and DIY rubbish collection jobs completed through the LoveJunk marketplace.  Tap on one to view more information about the type of builder waste removed, including additional photos and a more detailed description, quantity of waste, and when and where the removal took place.  The time shown in the left hand bottom corner depicts how long it took for a collector match to happen. As a general rule, builder waste removal jobs normally find a match faster than any other type of waste on LoveJunk, provided users offere a reasonable price. A green heart showing on any listings denotes that the waste was taken to a disposal facility that diverts 100% of waste from landfill. For more background on prices for builder waste and DIY waste disposal, check out this guide.',
  textTitle: 'Builder waste removal',
  title: 'Builder waste removal - find your cheapest collector',
  url: 'builder-waste-removal',
}

export const filterToSeoContent: Partial<Record<Filter, SeoContent>> = {
  residential: residentialSeoContent,
  trade: tradeSeoContent,
}

const buildAcceptType: Transform<AssetType, string[]> = type =>
  map<string, string>(flow(pushTo<string>([type]), join('/')))(assetTypeToMimeType[type])

export const buildAccept: Transform<MediaType, string> = mediaType => {
  const [image, video] = map(buildAcceptType)(['image', 'video'])
  let types: string[]

  switch (mediaType) {
    case 'image':
      types = image
      break
    case 'video':
      types = video
      break
    case 'mixed':
      types = concat(image)(video)
  }

  return join(',')(types)
}
