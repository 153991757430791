import { Action } from 'redux'

import { FailureAction, Nullable, PayloadAction } from '../../../types/core'
import {
  ConfirmSetupIntentResponse,
  CreateCustomerResponseBody,
  CreateElmerCustomerResponseBody,
  CreatePaymentMethodTokenResponse,
  CreateSetupIntentResponseBody,
  PaymentConfig,
  PaymentIntentBody,
  PostSetup,
  UpdateStripeCustomerResponseBody,
} from '../types'

export const INIT_SETUP_PAYMENT_FLOW = 'INIT_SETUP_PAYMENT_FLOW'
export const COMPLETE_SETUP_PAYMENT_FLOW = 'COMPLETE_SETUP_PAYMENT_FLOW'

export type InitSetupPaymentFlow = PayloadAction<typeof INIT_SETUP_PAYMENT_FLOW, PaymentConfig>
export type CompleteSetupPaymentFlow = Action<typeof COMPLETE_SETUP_PAYMENT_FLOW>

export type PaymentFlow = InitSetupPaymentFlow | CompleteSetupPaymentFlow

export const PAYMENT_INTENT_START = 'PAYMENT_INTENT_START'
export const PAYMENT_INTENT_SUCCESS = 'PAYMENT_INTENT_SUCCESS'
export const PAYMENT_INTENT_FAILURE = 'PAYMENT_INTENT_FAILURE'

export type PaymentIntentStart = Action<typeof PAYMENT_INTENT_START>
export type PaymentIntentSuccess = PayloadAction<typeof PAYMENT_INTENT_SUCCESS, PaymentIntentBody>
export type PaymentIntentFailure = FailureAction<typeof PAYMENT_INTENT_FAILURE>

export type PaymentIntent = PaymentIntentStart | PaymentIntentSuccess | PaymentIntentFailure

export const CONFIRMATION_RESULT_START = 'CONFIRMATION_RESULT_START'
export const CONFIRMATION_RESULT_SUCCESS = 'CONFIRMATION_RESULT_SUCCESS'
export const CONFIRMATION_RESULT_FAILURE = 'CONFIRMATION_RESULT_FAILURE'

export type ConfirmationResultStart = Action<typeof CONFIRMATION_RESULT_START>
export type ConfirmationResultSuccess = Action<typeof CONFIRMATION_RESULT_SUCCESS>
export type ConfirmationResultFailure = FailureAction<typeof CONFIRMATION_RESULT_FAILURE>

export type ConfirmationResult = ConfirmationResultStart | ConfirmationResultSuccess | ConfirmationResultFailure

export const CREATE_ELMER_CUSTOMER_START = 'CREATE_ELMER_CUSTOMER_START'
export const CREATE_ELMER_CUSTOMER_SUCCESS = 'CREATE_ELMER_CUSTOMER_SUCCESS'
export const CREATE_ELMER_CUSTOMER_FAILURE = 'CREATE_ELMER_CUSTOMER_FAILURE'

export type CreateElmerCustomerStart = Action<typeof CREATE_ELMER_CUSTOMER_START>
export type CreateElmerCustomerSuccess = PayloadAction<
  typeof CREATE_ELMER_CUSTOMER_SUCCESS,
  CreateElmerCustomerResponseBody
>
export type CreateElmerCustomerFailure = FailureAction<typeof CREATE_ELMER_CUSTOMER_FAILURE>

export type CreateElmerCustomer = CreateElmerCustomerStart | CreateElmerCustomerSuccess | CreateElmerCustomerFailure

export const CONFIRM_SETUP_INTENT_START = 'CONFIRM_SETUP_INTENT_START'
export const CONFIRM_SETUP_INTENT_SUCCESS = 'CONFIRM_SETUP_INTENT_SUCCESS'
export const CONFIRM_SETUP_INTENT_FAILURE = 'CONFIRM_SETUP_INTENT_FAILURE'

export type ConfirmSetupIntentStart = Action<typeof CONFIRM_SETUP_INTENT_START>
export type ConfirmSetupIntentSuccess = PayloadAction<typeof CONFIRM_SETUP_INTENT_SUCCESS, ConfirmSetupIntentResponse>
export type ConfirmSetupIntentFailure = FailureAction<typeof CONFIRM_SETUP_INTENT_FAILURE>

export type ConfirmSetupIntentResult = ConfirmSetupIntentSuccess | ConfirmSetupIntentFailure
export type ConfirmSetupIntent = ConfirmSetupIntentStart | ConfirmSetupIntentSuccess | ConfirmSetupIntentFailure

export const CREATE_PAYMENT_METHOD_TOKEN_START = 'CREATE_PAYMENT_METHOD_TOKEN_START'
export const CREATE_PAYMENT_METHOD_TOKEN_SUCCESS = 'CREATE_PAYMENT_METHOD_TOKEN_SUCCESS'
export const CREATE_PAYMENT_METHOD_TOKEN_FAILURE = 'CREATE_PAYMENT_METHOD_TOKEN_FAILURE'

export type CreatePaymentMethodTokenStart = Action<typeof CREATE_PAYMENT_METHOD_TOKEN_START>
export type CreatePaymentMethodTokenSuccess = PayloadAction<
  typeof CREATE_PAYMENT_METHOD_TOKEN_SUCCESS,
  CreatePaymentMethodTokenResponse
>
export type CreatePaymentMethodTokenFailure = FailureAction<typeof CREATE_PAYMENT_METHOD_TOKEN_FAILURE>

export type CreatePaymentMethodTokenResult = CreatePaymentMethodTokenSuccess | CreatePaymentMethodTokenFailure
export type CreatePaymentMethodToken = CreatePaymentMethodTokenStart | CreatePaymentMethodTokenResult

export const CREATE_CUSTOMER_START = 'CREATE_CUSTOMER_START'
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS'
export const CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE'

export type CreateCustomerStart = Action<typeof CREATE_CUSTOMER_START>
export type CreateCustomerSuccess = PayloadAction<typeof CREATE_CUSTOMER_SUCCESS, CreateCustomerResponseBody>
export type CreateCustomerFailure = FailureAction<typeof CREATE_CUSTOMER_FAILURE>

export type CreateCustomer = CreateCustomerStart | CreateCustomerSuccess | CreateCustomerFailure

export const CREATE_SETUP_INTENT_START = 'CREATE_SETUP_INTENT_START'
export const CREATE_SETUP_INTENT_SUCCESS = 'CREATE_SETUP_INTENT_SUCCESS'
export const CREATE_SETUP_INTENT_FAILURE = 'CREATE_SETUP_INTENT_FAILURE'

export type CreateSetupIntentStart = Action<typeof CREATE_SETUP_INTENT_START>
export type CreateSetupIntentSuccess = PayloadAction<typeof CREATE_SETUP_INTENT_SUCCESS, CreateSetupIntentResponseBody>
export type CreateSetupIntentFailure = FailureAction<typeof CREATE_SETUP_INTENT_FAILURE>

export type CreateSetupIntent = CreateSetupIntentStart | CreateSetupIntentSuccess | CreateSetupIntentFailure

export const CUSTOMER_CARD_SETUP_START = 'CUSTOMER_CARD_SETUP_START'
export const CUSTOMER_CARD_SETUP_SUCCESS = 'CUSTOMER_CARD_SETUP_SUCCESS'
export const CUSTOMER_CARD_SETUP_FAILURE = 'CUSTOMER_CARD_SETUP_FAILURE'

export type CustomerCardSetupStart = Action<typeof CUSTOMER_CARD_SETUP_START>
export type CustomerCardSetupSuccess = Action<typeof CUSTOMER_CARD_SETUP_SUCCESS>
export type CustomerCardSetupFailure = FailureAction<typeof CUSTOMER_CARD_SETUP_FAILURE>

export type CustomerCardSetupResult = CustomerCardSetupSuccess | CustomerCardSetupFailure
export type CustomerCardSetup = CustomerCardSetupStart | CustomerCardSetupSuccess | CustomerCardSetupFailure

export type AddPaymentThunkResult = CustomerCardSetupResult
export type AddPaymentSuccess = CustomerCardSetupSuccess

export const CONFIRM_CARD_SUCCESS = 'CONFIRM_CARD_SUCCESS'
export const CONFIRM_CARD_FAILURE = 'CONFIRM_CARD_FAILURE'

export type ConfirmCardSuccess = Action<typeof CONFIRM_CARD_SUCCESS>
export type ConfirmCardFailure = FailureAction<typeof CONFIRM_CARD_FAILURE>

export type ConfirmCardResult = ConfirmCardSuccess | ConfirmCardFailure

export const CLEAR_ADD_CARD_ERROR = 'CLEAR_ADD_CARD_ERROR'

export type ClearAddCardError = Action<typeof CLEAR_ADD_CARD_ERROR>

export const CUSTOMER_CARD_UPDATE_START = 'CUSTOMER_CARD_UPDATE_START'
export const CUSTOMER_CARD_UPDATE_SUCCESS = 'CUSTOMER_CARD_UPDATE_SUCCESS'
export const CUSTOMER_CARD_UPDATE_FAILURE = 'CUSTOMER_CARD_UPDATE_FAILURE'

export type CustomerCardUpdateStart = Action<typeof CUSTOMER_CARD_UPDATE_START>
export type CustomerCardUpdateSuccess = PayloadAction<
  typeof CUSTOMER_CARD_UPDATE_SUCCESS,
  CreatePaymentMethodTokenResponse
>
export type CustomerCardUpdateFailure = FailureAction<typeof CUSTOMER_CARD_UPDATE_FAILURE>
export type CustomerCardUpdateResult = CustomerCardUpdateSuccess | CustomerCardUpdateFailure
export type CustomerCardUpdate = CustomerCardUpdateStart | CustomerCardUpdateSuccess | CustomerCardUpdateFailure

export const UPDATE_STRIPE_CUSTOMER_START = 'UPDATE_STRIPE_CUSTOMER_START'
export const UPDATE_STRIPE_CUSTOMER_SUCCESS = 'UPDATE_STRIPE_CUSTOMER_SUCCESS'
export const UPDATE_STRIPE_CUSTOMER_FAILURE = 'UPDATE_STRIPE_CUSTOMER_FAILURE'

export const SET_POST_SETUP = 'SET_POST_SETUP'

export type SetPostSetup = PayloadAction<typeof SET_POST_SETUP, Nullable<PostSetup>>

export type UpdateStripeCustomerStart = Action<typeof UPDATE_STRIPE_CUSTOMER_START>
export type UpdateStripeCustomerSuccess = PayloadAction<
  typeof UPDATE_STRIPE_CUSTOMER_SUCCESS,
  UpdateStripeCustomerResponseBody
>
export type UpdateStripeCustomerFailure = FailureAction<typeof UPDATE_STRIPE_CUSTOMER_FAILURE>

export type UpdateStripeCustomer = UpdateStripeCustomerStart | UpdateStripeCustomerSuccess | UpdateStripeCustomerFailure

export type PaymentAction =
  | ClearAddCardError
  | ConfirmationResult
  | ConfirmCardResult
  | ConfirmSetupIntent
  | CreateCustomer
  | CreateElmerCustomer
  | CreatePaymentMethodToken
  | CreateSetupIntent
  | CustomerCardSetup
  | CustomerCardUpdate
  | PaymentFlow
  | PaymentIntent
  | SetPostSetup
  | UpdateStripeCustomer
