import React, { useEffect } from 'react'

import { NoOp } from '../../types/core'
import { Factory } from './factory'

interface DispatchProps {
  checkOrGetToken: NoOp
}

export interface StateProps {
  hadSuccessfulOauthCheck: boolean
}

const factory: Factory<DispatchProps & StateProps> = ({ Check }) =>
  function StoreCheck({ checkOrGetToken, children, hadSuccessfulOauthCheck }) {
    useEffect(() => {
      if (!hadSuccessfulOauthCheck) checkOrGetToken()
    }, [checkOrGetToken, hadSuccessfulOauthCheck])

    return <Check value={hadSuccessfulOauthCheck}>{children}</Check>
  }

export default factory
