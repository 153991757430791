import DateMarshaller from '../../marshallers/date'
import { Marshaller } from '../../types/core'
import { AdvertBase, AdvertBaseResource } from './types'

export const advertMarshaller: Marshaller<AdvertBase, AdvertBaseResource> = {
  unmarshal: ({ metaDetails, ...rest }) => ({
    ...rest,
    metaDetails: {
      ...metaDetails,
      createdAt: DateMarshaller.unmarshal(metaDetails.createdAt),
      offeredAt: DateMarshaller.unmarshal(metaDetails.offeredAt),
    },
  }),

  marshal: ({ metaDetails, ...rest }) => ({
    ...rest,
    metaDetails: {
      ...metaDetails,
      createdAt: DateMarshaller.marshal(metaDetails.createdAt),
      offeredAt: DateMarshaller.marshal(metaDetails.offeredAt),
    },
  }),
}

export default advertMarshaller
