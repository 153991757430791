import { post, put } from '../../api'
import { CompoundApi, SimpleApi } from '../../api-action'
import { responseToBody } from '../../helpers'
import { AddIdentifierParams, AddIdentifierResponse, AddIdentifierResponseBody, LoginParams } from './types'

export const addIdentifierApi = (
  url: string,
  params: AddIdentifierParams,
): CompoundApi<AddIdentifierResponse, AddIdentifierResponseBody> => [put(url, params), responseToBody]

export const loginApi = (url: string, params: LoginParams): SimpleApi => post(url, params)
