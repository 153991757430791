import { flow } from 'lodash/fp'

import { HazardousWasteError, InvalidTimeSlotError, TimeoutError } from '../../errors'
import { isHttpError, isTooManyRequests } from '../../helpers'
import Reducer from '../../types/reducer'
import { EDIT_ADVERT } from '../customer-advert/types/actions'
import { imageMarshaller } from '../freegle/marshaller'
import { FETCH_FREEGLE_ADVERT_SUCCESS } from '../freegle/types'
import { advertDetailsToEditableAdvert, editableAdvertToData, hasHazardousWasteType } from '../new-advert/helpers'
import { POSTCODE_SUPPORTED_FAILURE, POSTCODE_SUPPORTED_START, POSTCODE_SUPPORTED_SUCCESS } from '../postcode/types'
import {
  addressIdLens,
  addressLens,
  assetsLens,
  clearDataLens,
  collectionPointLens,
  detailsLens,
  editAdvertLens,
  favouriteCollectorsLens,
  fixedTimeSlotLens,
  hazardousWasteErrorLens,
  isConfirmingPublishingLens,
  isFailureModalDismissedLens,
  isInvalidAddressModalVisibleLens,
  isInvalidTimeSlotModalVisibleLens,
  isManualAddressLens,
  isPublishingCompletedLens,
  isPublishingLens,
  isReuseOnlyLens,
  isSelectedCollectorsLockedLens,
  isSuccessModalDismissedLens,
  manualAddressEntryLens,
  newAdvertReferenceLens,
  partnerDetailsLens,
  publishingErrorLens,
  residentialLens,
  reusableLens,
  selectedCollectorsLens,
  selectedDateLens,
  selectedTimeSlotLens,
  setAdvertCreationTimeoutLens,
  setAdvertCreationTooManyLens,
  showPublishIntentFeedbackLens,
  suggestedPriceLens,
  summaryLens,
  timeSlotLens,
  titleLens,
} from './lens'
import State, { defaultState } from './state'
import {
  CLEAR_NEW_ADVERT,
  CLOSE_INVALID_ADDRESS_MODAL,
  CLOSE_INVALID_TIME_SLOT_MODAL,
  CONFIRM_PUBLISH_ADVERT_FLOW_FAILURE,
  CONFIRM_PUBLISH_ADVERT_FLOW_START,
  CONFIRM_PUBLISH_ADVERT_FLOW_SUCCESS,
  DISMISS_PUBLISH_FAILURE_MODAL,
  DISMISS_PUBLISH_INTENT_FEEDBACK,
  DISMISS_PUBLISH_SUCCESS_MODAL,
  NEW_ADVERT_FAVOURITE_COLLECTORS_SUCCESS,
  PRICE_SUGGESTION_SUCCESS,
  PUBLISH_ADVERT_FAILURE,
  PUBLISH_ADVERT_FLOW_FAILURE,
  PUBLISH_ADVERT_FLOW_START,
  PUBLISH_ADVERT_FLOW_SUCCESS,
  PUBLISH_ADVERT_SUCCESS,
  SAVE_NEW_ADVERT_ADDRESS,
  SAVE_NEW_ADVERT_ADDRESS_ID,
  SAVE_NEW_ADVERT_COLLECTION_POINT,
  SAVE_NEW_ADVERT_DETAILS,
  SAVE_NEW_ADVERT_FIXED_TIME_SLOT,
  SAVE_NEW_ADVERT_IMAGES,
  SAVE_NEW_ADVERT_IS_REUSE_ONLY,
  SAVE_NEW_ADVERT_REUSABLE,
  SAVE_NEW_ADVERT_SELECTED_DATE,
  SAVE_NEW_ADVERT_SELECTED_TIME_SLOT,
  SAVE_NEW_ADVERT_SUMMARY,
  SAVE_NEW_ADVERT_TIME_SLOT,
  SAVE_NEW_ADVERT_TITLE,
  SET_ADVERT_CREATION_TIMEOUT,
  SET_ADVERT_CREATION_TOO_MANY,
  SET_HAZARDOUS_WASTE_ERROR,
  SET_MANUAL_ADDRESS,
  SET_PARTNER,
  SET_SELECTED_COLLECTORS,
  SET_SELECTED_COLLECTORS_LOCKED,
} from './types'

const reducer: Reducer<State> = (state = defaultState(), action) => {
  switch (action.type) {
    case EDIT_ADVERT:
      return flow(
        flow(advertDetailsToEditableAdvert, editableAdvertToData, editAdvertLens(state)),
        isManualAddressLens,
        l => l.set(true),
      )(action.payload)
    case DISMISS_PUBLISH_FAILURE_MODAL:
      return isFailureModalDismissedLens(state).set(true)
    case DISMISS_PUBLISH_SUCCESS_MODAL:
      return isSuccessModalDismissedLens(state).set(true)
    case CLEAR_NEW_ADVERT:
      return flow(
        isPublishingCompletedLens,
        l => l.set(false),
        clearDataLens,
        isManualAddressLens,
        l => l.set(false),
        isSelectedCollectorsLockedLens,
        l => l.set(false),
        selectedCollectorsLens,
        l => l.set([]),
        hazardousWasteErrorLens,
        l => l.set(null),
      )(state)
    case SET_SELECTED_COLLECTORS_LOCKED:
      return isSelectedCollectorsLockedLens(state).set(action.payload)
    case SET_PARTNER:
      return partnerDetailsLens(state).set({ partner: action.payload })
    case FETCH_FREEGLE_ADVERT_SUCCESS:
      return flow(
        partnerDetailsLens,
        l => l.set({ partner: 'freegle', partnerId: action.payload.freegleId }),
        titleLens,
        l => l.set(action.payload.title ? action.payload.title : ''),
        reusableLens,
        l => l.set(action.payload.isReusable),
        residentialLens,
        l => l.set(action.payload.isResidential),
        assetsLens,
        l => l.set(imageMarshaller(action.payload.images)),
      )(state)
    case SAVE_NEW_ADVERT_IMAGES:
      return assetsLens(state).set(action.payload)
    case SAVE_NEW_ADVERT_ADDRESS:
      return addressLens(state).set(action.payload)
    case SAVE_NEW_ADVERT_ADDRESS_ID:
      return addressIdLens(state).set(action.payload)
    case SAVE_NEW_ADVERT_COLLECTION_POINT:
      return collectionPointLens(state).set(action.payload)
    case SAVE_NEW_ADVERT_DETAILS:
      return detailsLens(state).set(action.payload)
    case SAVE_NEW_ADVERT_FIXED_TIME_SLOT:
      return fixedTimeSlotLens(state).set(action.payload)
    case SAVE_NEW_ADVERT_IS_REUSE_ONLY:
      return isReuseOnlyLens(state).set(action.payload)
    case SAVE_NEW_ADVERT_REUSABLE:
      return reusableLens(state).set(action.payload)
    case SAVE_NEW_ADVERT_SELECTED_DATE:
      return selectedDateLens(state).set(action.payload)
    case SAVE_NEW_ADVERT_SELECTED_TIME_SLOT:
      return selectedTimeSlotLens(state).set(action.payload)
    case SAVE_NEW_ADVERT_SUMMARY:
      return summaryLens(state).set(action.payload)
    case SAVE_NEW_ADVERT_TIME_SLOT:
      return timeSlotLens(state).set(action.payload)
    case SAVE_NEW_ADVERT_TITLE:
      return titleLens(state).set(action.payload)
    case PUBLISH_ADVERT_FLOW_START:
      return flow(
        isPublishingLens,
        l => l.set(true),
        isPublishingCompletedLens,
        l => l.set(false),
        publishingErrorLens,
        l => l.set(null),
        hazardousWasteErrorLens,
        l => l.set(null),
        isFailureModalDismissedLens,
        l => l.set(false),
        isSuccessModalDismissedLens,
        l => l.set(false),
      )(state)
    case PUBLISH_ADVERT_FLOW_SUCCESS:
      return flow(isPublishingLens, l => l.set(false))(state)
    case PUBLISH_ADVERT_FLOW_FAILURE:
      return flow(
        isPublishingLens,
        l => l.set(false),
        s => hazardousWasteErrorLens(s).set(action.payload instanceof HazardousWasteError ? action.payload : null),
      )(state)
    case CONFIRM_PUBLISH_ADVERT_FLOW_START:
      return isConfirmingPublishingLens(state).set(true)
    case CONFIRM_PUBLISH_ADVERT_FLOW_SUCCESS:
      return isConfirmingPublishingLens(state).set(false)
    case CONFIRM_PUBLISH_ADVERT_FLOW_FAILURE:
      return flow(
        isConfirmingPublishingLens,
        l => l.set(false),
        s =>
          action.payload instanceof InvalidTimeSlotError
            ? flow(
                isInvalidTimeSlotModalVisibleLens,
                l => l.set(true),
                fixedTimeSlotLens,
                l => l.set(null),
                timeSlotLens,
                l => l.set(null),
              )(s)
            : s,
      )(state)
    case PUBLISH_ADVERT_SUCCESS:
      return flow(
        isPublishingCompletedLens,
        l => l.set(true),
        newAdvertReferenceLens,
        l => l.set(action.payload.reference),
      )(state)
    case PUBLISH_ADVERT_FAILURE:
      return flow(
        isPublishingCompletedLens,
        l => l.set(true),
        s => {
          const {
            payload: { originalError: error },
          } = action

          if (error instanceof TimeoutError) return setAdvertCreationTimeoutLens(state).set(true)

          if (isHttpError(error) && isTooManyRequests(error)) return setAdvertCreationTooManyLens(state).set(true)

          if (hasHazardousWasteType(error)) return hazardousWasteErrorLens(s).set(new HazardousWasteError())

          return publishingErrorLens(s).set(error.message)
        },
      )(state)
    case SET_MANUAL_ADDRESS:
      return flow(
        isManualAddressLens,
        l => l.set(action.payload),
        manualAddressEntryLens,
        l => l.set(action.payload),
      )(state)
    case PRICE_SUGGESTION_SUCCESS:
      return suggestedPriceLens(state).set(action.payload)
    case POSTCODE_SUPPORTED_START:
    case DISMISS_PUBLISH_INTENT_FEEDBACK:
      return showPublishIntentFeedbackLens(state).set(false)
    case POSTCODE_SUPPORTED_FAILURE:
      return isInvalidAddressModalVisibleLens(state).set(true)
    case POSTCODE_SUPPORTED_SUCCESS:
      return showPublishIntentFeedbackLens(state).set(!action.payload)
    case CLOSE_INVALID_ADDRESS_MODAL:
      return isInvalidAddressModalVisibleLens(state).set(false)
    case CLOSE_INVALID_TIME_SLOT_MODAL:
      return isInvalidTimeSlotModalVisibleLens(state).set(false)
    case SET_SELECTED_COLLECTORS:
      return selectedCollectorsLens(state).set(action.payload)
    case NEW_ADVERT_FAVOURITE_COLLECTORS_SUCCESS:
      return favouriteCollectorsLens(state).set(action.payload)
    case SET_ADVERT_CREATION_TIMEOUT:
      return setAdvertCreationTimeoutLens(state).set(action.payload)
    case SET_ADVERT_CREATION_TOO_MANY:
      return setAdvertCreationTooManyLens(state).set(action.payload)
    case SET_HAZARDOUS_WASTE_ERROR:
      return hazardousWasteErrorLens(state).set(action.payload)
    default:
      return state
  }
}

export default reducer
