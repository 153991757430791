import { Nullable } from '../../types/core'
import { EmailRequestData } from './types'

export interface EmailRequestState {
  isEmailRequestInProgress: boolean
  emailRequestModal: Nullable<EmailRequestData>
}

export const emailRequestDefaultState = () => ({
  isEmailRequestInProgress: false,
  emailRequestModal: null,
})

export default EmailRequestState
