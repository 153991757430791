import { Action } from 'redux'

import { BaseError, HazardousWasteError } from '../../errors'
import {
  Asset,
  AssetPath,
  BodyItem,
  Currency,
  Dispatch,
  FailureAction,
  FavouriteId,
  Interval,
  NoOp,
  Nullable,
  Nullify,
  Optional,
  PayloadAction,
  Price,
  PriceRange,
  Response,
  Transform,
} from '../../types/core'
import { AdvertBase, AdvertId, AdvertPayload, AdvertReference, LoadSize } from '../advert/types'
import { Meta } from '../meta/types'
import { OpenTimeSlot, TimeSlot, TimeSlotFixedName, TimeSlotResource } from '../time-slots/types'

export type CollectionDate = OpenTimeSlot | Date

export interface CarPropsWithoutIcon {
  size: LoadSize
  description: string
  title: string
}

export interface CarProps<C> extends CarPropsWithoutIcon {
  Icon: C
}

export type ValidationField =
  | 'address'
  | 'address1'
  | 'assets'
  | 'builderWaste'
  | 'city'
  | 'date'
  | 'easyAccess'
  | 'easyAccessReason'
  | 'loadSize'
  | 'postcode'
  | 'residential'
  | 'reusable'
  | 'summary'
  | 'timeSlot'
  | 'title'

export interface PriceSuggestionPayload {
  title: string

  builderWaste?: boolean
  easyAccess?: boolean
  reusable?: boolean
  size?: LoadSize
}

export interface NewAdvertAddress {
  address1: string
  address2: string
  address3: string
  city: string
  postcode: string
}

interface NewAdvertFixedTimeSlotBase<D> {
  date: Nullable<D>
  timeSlot: Nullable<TimeSlotFixedName>
}

export type NewAdvertResourceFixedTimeSlot = NewAdvertFixedTimeSlotBase<string>
export type NewAdvertFixedTimeSlot = NewAdvertFixedTimeSlotBase<Date>

export interface ValidNewAdvertFixedTimeSlot extends NewAdvertFixedTimeSlot {
  date: Date
  timeSlot: TimeSlotFixedName
}

export interface DateTimeSlotPayload {
  date: Date
  timeSlot: TimeSlot
}

export interface OpenTimeSlotPayload {
  date: OpenTimeSlot
}

export type TimeSlotPayload = DateTimeSlotPayload | OpenTimeSlotPayload
export type NullifiedDateTimeSlotPayload = Nullify<DateTimeSlotPayload>
export type NullifiedOpenTimeSlotPayload = Nullify<OpenTimeSlotPayload>
export type NullifiedTimeSlotPayload = NullifiedDateTimeSlotPayload | NullifiedOpenTimeSlotPayload

export interface NewAdvertResourceAddress {
  address1: string
  address2: string
  address3: string
  city: string
  postcode: string
}

export interface NewAdvertResourceCollectionPoint {
  easyAccess: boolean
  easyAccessReason?: string
}

export interface NewAdvertResourceDetails {
  builderWaste: boolean
  isLightweight: boolean
  residential: boolean
  size: LoadSize
}

export interface NewAdvertResource {
  address: NewAdvertResourceAddress
  collectionPointDetails: NewAdvertResourceCollectionPoint
  details: NewAdvertResourceDetails
  manualAddressEntry: boolean
  reusable: boolean
  suggestedPriceRange: PriceRange
  summary: string
  title: string

  fixedTimeSlot?: NewAdvertResourceFixedTimeSlot
  isReuseOnly?: boolean
  partner?: string
  partnerId?: string
  preferredCollectorIds?: FavouriteId[]
  previousAdvertId?: AdvertId
  price?: Price
  timeSlot?: TimeSlotResource
}

export interface NewAdvertDetails {
  builderWaste: Nullable<boolean>
  residential: Nullable<boolean>
  size: Nullable<LoadSize>

  isLightweight?: Nullable<boolean>
}

export interface ValidNewAdvertDetails {
  builderWaste: boolean
  isLightweight: boolean
  residential: boolean
  size: LoadSize
}

export interface NewAdvertCollectionPoint {
  easyAccess: Nullable<boolean>
  easyAccessReason: string
}

export interface ValidNewAdvertCollectionPoint {
  easyAccess: boolean
  easyAccessReason: string
}

export interface NewAdvertBase {
  address: NewAdvertAddress
  assets: Asset[]
  fixedTimeSlot: Nullable<NewAdvertFixedTimeSlot>
  id: Nullable<AdvertId>
  isReuseOnly: Nullable<boolean>
  manualAddressEntry: boolean
  partnerDetails: Nullable<PartnerDetails>
  reference: Nullable<AdvertReference>
  selectedCollectors: FavouriteId[]
  summary: string
  timeSlot: Nullable<NullifiedTimeSlotPayload>
  title: string
}

export interface NewAdvert extends NewAdvertBase {
  collectionPoint: NewAdvertCollectionPoint
  details: NewAdvertDetails
  reusable: Nullable<boolean>
  suggestedPriceRange: Nullable<PriceRange>
}

export interface EditableNewAdvert extends NewAdvert {
  id: AdvertId
  reference: AdvertReference
}

export interface ValidNewAdvert extends NewAdvertBase {
  collectionPoint: ValidNewAdvertCollectionPoint
  details: ValidNewAdvertDetails
  fixedTimeSlot: Nullable<ValidNewAdvertFixedTimeSlot>
  reusable: boolean
  suggestedPriceRange: PriceRange
  timeSlot: Nullable<TimeSlotPayload>
}

export interface EditableValidNewAdvert extends ValidNewAdvert {
  id: AdvertId
  reference: AdvertReference
}

export type EditableAdvert = EditableNewAdvert | EditableValidNewAdvert

export interface ConfirmPublishPayload {
  firstCall?: boolean
}

export interface PublishConfig {
  isLoggedIn: boolean
  logVisitSummary: NoOp
  onError: NoOp
  onSessionError: NoOp
  onSuccess: NoOp
  validNewAdvert: Promise<ValidNewAdvert>
}

export interface ConfirmPublishConfig {
  getAdvert: Transform<EditableAdvert, Optional<AdvertBase>>
  meta: Nullable<Meta>
  onInvalidTimeSlot: Dispatch<ValidNewAdvert>
  reconfirm: Transform<ConfirmPublishPayload, Promise<ConfirmPublishAdvertFlowResult>>
  sendPublish: Transform<SendPublishPayload, Promise<PublishAdvertResult>>
  validatedAdvert: Promise<ValidNewAdvert>

  extraLogData?: ExtraLogData
  hasPaymentMethod?: boolean
}

type ExtraLogData = Record<string, string>

export interface SendPublishPayload {
  advert: ValidNewAdvert
  customerRef: string
  url: string

  hasPaymentMethod?: boolean
  extraLogData?: ExtraLogData
}

export interface PublishAdvertParams {
  customerRef: string
  url: string
  userAgent?: boolean
}

export interface PublishedAdvert {
  id: AdvertId
  reference: AdvertReference
  url: string
}

export interface PartnerDetails {
  partner: string

  partnerId?: string
}

export type SendPublishResponse = Response<PublishedAdvert>

export const CLEAR_NEW_ADVERT = 'CLEAR_NEW_ADVERT'
export type ClearNewAdvert = Action<typeof CLEAR_NEW_ADVERT>

export const REMOVE_NEW_ADVERT_ASSET = 'REMOVE_NEW_ADVERT_ASSET'
export const SET_MANUAL_ADDRESS = 'SET_MANUAL_ADDRESS'
export const SAVE_NEW_ADVERT_ADDRESS = 'SAVE_NEW_ADVERT_ADDRESS'
export const SAVE_NEW_ADVERT_ADDRESS_ID = 'SAVE_NEW_ADVERT_ADDRESS_ID'
export const SAVE_NEW_ADVERT_COLLECTION_POINT = 'SAVE_NEW_ADVERT_COLLECTION_POINT'
export const SAVE_NEW_ADVERT_FIXED_TIME_SLOT = 'SAVE_NEW_ADVERT_FIXED_TIME_SLOT'
export const SAVE_NEW_ADVERT_DETAILS = 'SAVE_NEW_ADVERT_DETAILS'
export const SAVE_NEW_ADVERT_IMAGE = 'SAVE_NEW_ADVERT_IMAGE'
export const SAVE_NEW_ADVERT_IMAGES = 'SAVE_NEW_ADVERT_IMAGES'
export const SAVE_NEW_ADVERT_IS_REUSE_ONLY = 'SAVE_NEW_ADVERT_IS_REUSE_ONLY'
export const SAVE_NEW_ADVERT_REUSABLE = 'SAVE_NEW_ADVERT_REUSABLE'
export const SAVE_NEW_ADVERT_SELECTED_DATE = 'SAVE_NEW_ADVERT_SELECTED_DATE'
export const SAVE_NEW_ADVERT_SELECTED_TIME_SLOT = 'SAVE_NEW_ADVERT_SELECTED_TIME_SLOT'
export const SAVE_NEW_ADVERT_SUMMARY = 'SAVE_NEW_ADVERT_SUMMARY'
export const SAVE_NEW_ADVERT_TIME_SLOT = 'SAVE_NEW_ADVERT_TIME_SLOT'
export const SAVE_NEW_ADVERT_TITLE = 'SAVE_NEW_ADVERT_TITLE'
export const SAVE_NEW_ADVERT_VIDEO = 'SAVE_NEW_ADVERT_VIDEO'

export type RemoveNewAdvertAsset = PayloadAction<typeof REMOVE_NEW_ADVERT_ASSET, AssetPath>
export type SaveNewAdvertAddress = PayloadAction<typeof SAVE_NEW_ADVERT_ADDRESS, NewAdvertAddress>
export type SaveNewAdvertAddressId = PayloadAction<typeof SAVE_NEW_ADVERT_ADDRESS_ID, string>
export type SaveNewAdvertCollectionPoint = PayloadAction<
  typeof SAVE_NEW_ADVERT_COLLECTION_POINT,
  NewAdvertCollectionPoint
>
export type SaveNewAdvertDetails = PayloadAction<typeof SAVE_NEW_ADVERT_DETAILS, NewAdvertDetails>
export type SaveNewAdvertFixedTimeSlot = PayloadAction<
  typeof SAVE_NEW_ADVERT_FIXED_TIME_SLOT,
  Nullable<NewAdvertFixedTimeSlot>
>
export type SaveNewAdvertImage = PayloadAction<typeof SAVE_NEW_ADVERT_IMAGE, Asset>
export type SaveNewAdvertImages = PayloadAction<typeof SAVE_NEW_ADVERT_IMAGES, Asset[]>
export type SaveNewAdvertIsReuseOnly = PayloadAction<typeof SAVE_NEW_ADVERT_IS_REUSE_ONLY, Nullable<boolean>>
export type SaveNewAdvertReusable = PayloadAction<typeof SAVE_NEW_ADVERT_REUSABLE, Nullable<boolean>>
export type SaveNewAdvertSelectedDate = PayloadAction<typeof SAVE_NEW_ADVERT_SELECTED_DATE, Nullable<CollectionDate>>
export type SaveNewAdvertSelectedTimeSlot = PayloadAction<typeof SAVE_NEW_ADVERT_SELECTED_TIME_SLOT, TimeSlot>
export type SaveNewAdvertSummary = PayloadAction<typeof SAVE_NEW_ADVERT_SUMMARY, string>
export type SaveNewAdvertTimeSlot = PayloadAction<typeof SAVE_NEW_ADVERT_TIME_SLOT, Nullable<NullifiedTimeSlotPayload>>
export type SaveNewAdvertTitle = PayloadAction<typeof SAVE_NEW_ADVERT_TITLE, string>
export type SaveNewAdvertVideo = PayloadAction<typeof SAVE_NEW_ADVERT_VIDEO, Asset>

export const PRICE_SUGGESTION_START = 'PRICE_SUGGESTION_START'
export const PRICE_SUGGESTION_SUCCESS = 'PRICE_SUGGESTION_SUCCESS'
export const PRICE_SUGGESTION_FAILURE = 'PRICE_SUGGESTION_FAILURE'

export type PriceSuggestionStart = Action<typeof PRICE_SUGGESTION_START>
export type PriceSuggestionSuccess = PayloadAction<typeof PRICE_SUGGESTION_SUCCESS, Nullable<PriceRange>>
export type PriceSuggestionFailure = FailureAction<typeof PRICE_SUGGESTION_FAILURE>
export type PriceSuggestionResult = PriceSuggestionSuccess | PriceSuggestionFailure
export type PriceSuggestion = PriceSuggestionStart | PriceSuggestionResult

export const PUBLISH_ADVERT_FLOW_START = 'PUBLISH_ADVERT_FLOW_START'
export const PUBLISH_ADVERT_FLOW_SUCCESS = 'PUBLISH_ADVERT_FLOW_SUCCESS'
export const PUBLISH_ADVERT_FLOW_FAILURE = 'PUBLISH_ADVERT_FLOW_FAILURE'

export type PublishAdvertFlowStart = Action<typeof PUBLISH_ADVERT_FLOW_START>
export type PublishAdvertFlowSuccess = Action<typeof PUBLISH_ADVERT_FLOW_SUCCESS>
export type PublishAdvertFlowFailure = FailureAction<typeof PUBLISH_ADVERT_FLOW_FAILURE>

export type PublishAdvertFlowResult = PublishAdvertFlowSuccess | PublishAdvertFlowFailure
export type PublishAdvertFlow = PublishAdvertFlowStart | PublishAdvertFlowResult

export const CONFIRM_PUBLISH_ADVERT_FLOW_START = 'CONFIRM_PUBLISH_ADVERT_FLOW_START'
export const CONFIRM_PUBLISH_ADVERT_FLOW_SUCCESS = 'CONFIRM_PUBLISH_ADVERT_FLOW_SUCCESS'
export const CONFIRM_PUBLISH_ADVERT_FLOW_FAILURE = 'CONFIRM_PUBLISH_ADVERT_FLOW_FAILURE'

export type ConfirmPublishAdvertFlowStart = Action<typeof CONFIRM_PUBLISH_ADVERT_FLOW_START>
export type ConfirmPublishAdvertFlowSuccess = Action<typeof CONFIRM_PUBLISH_ADVERT_FLOW_SUCCESS>
export type ConfirmPublishAdvertFlowFailure = FailureAction<typeof CONFIRM_PUBLISH_ADVERT_FLOW_FAILURE>

export type ConfirmPublishAdvertFlowResult = ConfirmPublishAdvertFlowSuccess | ConfirmPublishAdvertFlowFailure
export type ConfirmPublishAdvertFlow = ConfirmPublishAdvertFlowStart | ConfirmPublishAdvertFlowResult

export const PUBLISH_ADVERT_START = 'PUBLISH_ADVERT_START'
export const PUBLISH_ADVERT_SUCCESS = 'PUBLISH_ADVERT_SUCCESS'
export const PUBLISH_ADVERT_FAILURE = 'PUBLISH_ADVERT_FAILURE'

interface PublishAdvertStartPayload extends NewAdvertResource {
  customerRef: string
}

export class PublishAdvertFailureError extends BaseError {
  constructor(readonly originalError: Error, readonly resource?: NewAdvertResource) {
    super('PublishAdvertFailureError')
  }
}

export type PublishAdvertStart = PayloadAction<typeof PUBLISH_ADVERT_START, Optional<PublishAdvertStartPayload>>
export type PublishAdvertSuccess = PayloadAction<typeof PUBLISH_ADVERT_SUCCESS, PublishedAdvert>
export type PublishAdvertFailure = FailureAction<typeof PUBLISH_ADVERT_FAILURE, PublishAdvertFailureError>

export type PublishAdvertResult = PublishAdvertSuccess | PublishAdvertFailure
export type PublishAdvert = PublishAdvertStart | PublishAdvertSuccess | PublishAdvertFailure

export const CLOSE_INVALID_ADDRESS_MODAL = 'CLOSE_INVALID_ADDRESS_MODAL'

export type CloseInvalidAddressModal = Action<typeof CLOSE_INVALID_ADDRESS_MODAL>

export const CLOSE_INVALID_TIME_SLOT_MODAL = 'CLOSE_INVALID_TIME_SLOT_MODAL'

export type CloseInvalidTimeSlotModal = Action<typeof CLOSE_INVALID_TIME_SLOT_MODAL>

export const DISMISS_PUBLISH_SUCCESS_MODAL = 'DISMISS_PUBLISH_SUCCESS_MODAL'
export const DISMISS_PUBLISH_FAILURE_MODAL = 'DISMISS_PUBLISH_FAILURE_MODAL'

export type DismissPublishSuccessModal = Action<typeof DISMISS_PUBLISH_SUCCESS_MODAL>
export type DismissPublishFailureModal = Action<typeof DISMISS_PUBLISH_FAILURE_MODAL>

export const DISMISS_PUBLISH_INTENT_FEEDBACK = 'DISMISS_PUBLISH_INTENT_FEEDBACK'

export type DismissPublishIntentFeedback = Action<typeof DISMISS_PUBLISH_INTENT_FEEDBACK>

export type SetManualAddress = PayloadAction<typeof SET_MANUAL_ADDRESS, boolean>

export const SET_PARTNER = 'SET_PARTNER'

export type SetPartner = PayloadAction<typeof SET_PARTNER, string>

export const SET_SELECTED_COLLECTORS_LOCKED = 'SET_SELECTED_COLLECTORS_LOCKED'

export type SetSelectedCollectorsLocked = PayloadAction<typeof SET_SELECTED_COLLECTORS_LOCKED, boolean>

export const VIDEO_UPLOAD_START = 'VIDEO_UPLOAD_START'
export const VIDEO_UPLOAD_SUCCESS = 'VIDEO_UPLOAD_SUCCESS'
export const VIDEO_UPLOAD_FAILURE = 'VIDEO_UPLOAD_FAILURE'

export type VideoUploadStart = Action<typeof VIDEO_UPLOAD_START>
export type VideoUploadSuccess = PayloadAction<typeof VIDEO_UPLOAD_SUCCESS, VideoId>
export type VideoUploadFailure = FailureAction<typeof VIDEO_UPLOAD_FAILURE>
export type VideoUploadResult = VideoUploadSuccess | VideoUploadFailure
export type VideoUpload = VideoUploadStart | VideoUploadResult

export const VIDEOS_UPLOAD_SUCCESS = 'VIDEOS_UPLOAD_SUCCESS'
export const VIDEOS_UPLOAD_FAILURE = 'VIDEOS_UPLOAD_FAILURE'
export type VideosUploadSuccess = PayloadAction<typeof VIDEOS_UPLOAD_SUCCESS, VideosPayload>
export type VideosUploadFailure = FailureAction<typeof VIDEOS_UPLOAD_FAILURE>
export type VideosUploadResult = VideosUploadSuccess | VideosUploadFailure
export type VideosUpload = VideosUploadResult

export const NEW_ADVERT_FAVOURITE_COLLECTORS_START = 'NEW_ADVERT_FAVOURITE_COLLECTORS_START'
export const NEW_ADVERT_FAVOURITE_COLLECTORS_SUCCESS = 'NEW_ADVERT_FAVOURITE_COLLECTORS_SUCCESS'
export const NEW_ADVERT_FAVOURITE_COLLECTORS_FAILURE = 'NEW_ADVERT_FAVOURITE_COLLECTORS_FAILURE'

export type NewAdvertFavouriteCollectorsStart = Action<typeof NEW_ADVERT_FAVOURITE_COLLECTORS_START>
export type NewAdvertFavouriteCollectorsSuccess = PayloadAction<
  typeof NEW_ADVERT_FAVOURITE_COLLECTORS_SUCCESS,
  NewAdvertFavouriteCollectors
>
export type NewAdvertFavouriteCollectorsFailure = FailureAction<typeof NEW_ADVERT_FAVOURITE_COLLECTORS_FAILURE>
export type NewAdvertFavouriteCollectorsResult =
  | NewAdvertFavouriteCollectorsSuccess
  | NewAdvertFavouriteCollectorsFailure
export type NewAdvertFavouriteCollectorsAction = NewAdvertFavouriteCollectorsStart | NewAdvertFavouriteCollectorsResult

export const SET_SELECTED_COLLECTORS = 'SET_SELECTED_COLLECTORS'

export type SetSelectedCollectors = PayloadAction<typeof SET_SELECTED_COLLECTORS, FavouriteId[]>

export const SET_ADVERT_CREATION_TIMEOUT = 'SET_ADVERT_CREATION_TIMEOUT'

export type SetAdvertCreationTimeout = PayloadAction<typeof SET_ADVERT_CREATION_TIMEOUT, boolean>

export const SET_ADVERT_CREATION_TOO_MANY = 'SET_ADVERT_CREATION_TOO_MANY'

export type SetAdvertCreationTooMany = PayloadAction<typeof SET_ADVERT_CREATION_TOO_MANY, boolean>

export const SAVE_ADVERT_ID_AND_URL = 'SAVE_ADVERT_ID_AND_URL'

export type SaveAdvertIdAndUrlData = PayloadAction<typeof SAVE_ADVERT_ID_AND_URL, AdvertPayload>

export const SAVE_NEW_ADVERT_ADDRESS_VALUE = 'SAVE_NEW_ADVERT_ADDRESS_VALUE'

export type SaveNewAdvertAddressValue = PayloadAction<typeof SAVE_NEW_ADVERT_ADDRESS_VALUE, string>

export const SET_HAZARDOUS_WASTE_ERROR = 'SET_HAZARDOUS_WASTE_ERROR'

export type SetHazardousWasteError = PayloadAction<typeof SET_HAZARDOUS_WASTE_ERROR, Nullable<HazardousWasteError>>

export type NewAdvertAction =
  | ClearNewAdvert
  | CloseInvalidAddressModal
  | CloseInvalidTimeSlotModal
  | ConfirmPublishAdvertFlow
  | DismissPublishFailureModal
  | DismissPublishIntentFeedback
  | DismissPublishSuccessModal
  | NewAdvertFavouriteCollectorsAction
  | PriceSuggestion
  | PublishAdvert
  | PublishAdvertFlow
  | RemoveNewAdvertAsset
  | SaveAdvertIdAndUrlData
  | SaveNewAdvertAddress
  | SaveNewAdvertAddressId
  | SaveNewAdvertAddressValue
  | SaveNewAdvertCollectionPoint
  | SaveNewAdvertDetails
  | SaveNewAdvertFixedTimeSlot
  | SaveNewAdvertImage
  | SaveNewAdvertImages
  | SaveNewAdvertIsReuseOnly
  | SaveNewAdvertReusable
  | SaveNewAdvertSelectedDate
  | SaveNewAdvertSelectedTimeSlot
  | SaveNewAdvertSummary
  | SaveNewAdvertTimeSlot
  | SaveNewAdvertTitle
  | SaveNewAdvertVideo
  | SetAdvertCreationTimeout
  | SetAdvertCreationTooMany
  | SetHazardousWasteError
  | SetManualAddress
  | SetPartner
  | SetSelectedCollectors
  | SetSelectedCollectorsLocked
  | VideoUpload
  | VideosUpload

export interface LogPublishPayload {
  advertUrl: string
  customerRef: string
  suggestedPriceRange: PriceRange

  extraLogData?: ExtraLogData
}

export interface PublishListingPayload {
  customerRef: string
  suggestedPriceRange: PriceRange
}

export type Trade = 'trade' | 'non-trade'
export type TshirtSize = 'S' | 'M' | 'L' | 'XL'

export interface PublishListingParams {
  advertRef: string
  advertTitle: string
  customerRef: string
  max: number
  min: number
  size: TshirtSize
  trade: Trade

  amount?: number
  currency?: Currency
}

export type LogPublish = Dispatch<PublishListingParams>

export interface PriceSuggestionParams {
  isBuilderWaste: boolean
  isReusable: boolean
  size: LoadSize
  title: string

  isEasyAccess?: boolean
}

export interface PriceSuggestionQueryParams {
  'is-builder-waste': boolean
  'is-reusable': boolean
  size: LoadSize
  title: string

  'is-easy-access'?: boolean
}

export type SuggestedPriceRange = Interval<Price>

export type PriceSuggestionResponse = Response<SuggestedPriceRange>

export type VideoId = string
export type VideoSecret = string

export interface VideoUploadBody {
  data: {
    secret: VideoSecret
  }
  video: string
}

interface VideoUploadResponseBody {
  id: VideoId
}

export type VideoUploadResponse = Response<VideoUploadResponseBody>

export interface VideosPayload {
  ids: VideoId[]
  secret: VideoSecret
}

export interface NewAdvertFavouriteCollector {
  id: FavouriteId
  name: string
  postcodeDistrict: string
}

export type NewAdvertFavouriteCollectors = NewAdvertFavouriteCollector[]

interface NewAdvertFavouriteCollectorsResponseBody {
  entities: NewAdvertFavouriteCollectors
}

export type NewAdvertFavouriteCollectorsResponse = Response<NewAdvertFavouriteCollectorsResponseBody>

export interface PublishAdvertStartPayloadData {
  body: BodyItem[]
  customerRef: string
}

export interface RetrievePublishUrlPayload {
  getAdvert: Transform<EditableAdvert, Optional<AdvertBase>>
  newAdvert: ValidNewAdvert
  advertCreationUrl: string
}
