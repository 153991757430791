import Reducer from '../../types/reducer'
import { CREATE_ELMER_CUSTOMER_SUCCESS } from '../payment/types/actions'
import { isCustomerVatRegisteredLens } from './lens'
import State, { signupCustomerDefaultState as defaultState } from './state'
import { SET_IS_CUSTOMER_VAT_REGISTERED } from './types'

const reducer: Reducer<State> = (state = defaultState(), action) => {
  switch (action.type) {
    case CREATE_ELMER_CUSTOMER_SUCCESS:
      return isCustomerVatRegisteredLens(state).set(null)
    case SET_IS_CUSTOMER_VAT_REGISTERED:
      return isCustomerVatRegisteredLens(state).set(action.payload)
    default:
      return state
  }
}

export default reducer
