import { Get, Nullable } from '../../types/core'
import { Tag } from './types'

export interface TagsState {
  items: Nullable<Tag[]>
  loaded: boolean
}

export const tagsDefaultState: Get<TagsState> = () => ({
  items: null,
  loaded: false,
})

export default TagsState
