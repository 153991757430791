// eslint-disable camelcase

import {
  Get,
  LogAnalyticsEvent,
  Optional,
  PUBLISH_LISTING,
  PublishListingParams,
  PurchaseListingParams,
  SetUserId,
  Trade,
  Transform,
  TshirtSize,
} from '@lovejunk/core'
import { Utm, UTM_PARAMS } from 'entities/utm'
import {
  Analytics,
  getAnalytics,
  isSupported,
  logEvent as logAnalyticsEvent,
  setUserId as setAnalyticsUserId,
} from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import firebase from 'firebase/compat'
import { delay, flow, identity, isNull, pick } from 'lodash/fp'

import config from './config'

let analytics: Optional<Analytics>

// Required to prevent `analytics/indexeddb-unavailable` warning.
isSupported().then(supported => {
  if (supported) analytics = getAnalytics(initializeApp(config))
})

const DELAY_LONG = 5000
const DELAY_DEFAULT = 2500
const DELAY_SET_SCREEN = 1000

export const setUserId: SetUserId = userId =>
  analytics && setAnalyticsUserId(analytics, isNull(userId) ? '' : userId.toString())

export const logEvent: LogAnalyticsEvent = name => params =>
  Promise.resolve(analytics && logAnalyticsEvent(analytics, name, params))

export const setCurrentScreenName = (name: string) =>
  delay(DELAY_SET_SCREEN)(() => logEvent('screen_view')({ firebase_screen: name }))

// TODO: find better way of dynamic return type based on `transformer` type
const log =
  <P>(event: string, transformer: Get<void> | Transform<P, EventParams> = identity, delayValue = DELAY_DEFAULT) =>
  (params?: P) =>
    delay(delayValue)(() => flow(transformer, logEvent(event))(params))

export const logClickOnCreateAccount = log('click_on_create_account')
export const logClickOnGetJunkCollected = log('click_on_get_junk_collected')
export const logClickOnListMyJunk = log('click_on_list_my_junk')
export const logClickOnLogin = log('click_on_login')
export const logClickOnLogout = log('click_on_logout')
export const logClickOnShare = log('click_on_share')
export const logErrorPublishListing = log('error_publish_listing')
export const logVisitSummary = log('visit_summary_page')

type EventParams = firebase.analytics.EventParams
type PurchaseEventParams = EventParams

const purchaseToEventParams: Transform<PurchaseListingParams, PurchaseEventParams> = ({
  advertRef: item_list_id,
  advertTitle: item_list_name,
  amount: value,
  currency,
}) => ({
  event_label: 'purchase',
  event_category: 'purchase',
  currency,
  value,
  items: [{ item_list_id, item_list_name }],
})

interface PublishEventParams extends firebase.analytics.EventParams {
  customerRef: string
  max: number
  min: number
  size: TshirtSize
  trade: Trade
}

const publishListingToEventParams: Transform<PublishListingParams & Partial<Utm>, PublishEventParams> = ({
  customerRef,
  max,
  min,
  size,
  trade,
  advertRef: item_list_id,
  advertTitle: item_list_name,
  amount: value,
  currency,
  ...rest
}) => ({
  event_label: PUBLISH_LISTING,
  event_category: 'publish',
  currency,
  customerRef,
  max,
  min,
  size,
  trade,
  value,
  items: [{ item_list_id, item_list_name }],
  ...pick(UTM_PARAMS)(rest),
})

export const logPublishListing = log(PUBLISH_LISTING, publishListingToEventParams, DELAY_LONG)
export const logPurchaseListing = log('purchase', purchaseToEventParams, DELAY_LONG)
