import { Dispatch, isDefined } from '@lovejunk/core'
import { isEmpty, isNumber, size, toString } from 'lodash/fp'
import React, {
  ChangeEventHandler,
  ComponentPropsWithRef,
  FocusEventHandler,
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { styled } from 'styled'
import { isMobile } from 'utils/environment'

import { ClassNameProps } from '../../types/ui'
import Column from '../containers/Column'
import Title from '../Label'
import { Error, inputCss, InputProps as InputPropsBase, Label, MaxLength } from './TextInput'

export interface Props extends Omit<ComponentPropsWithRef<'textarea'>, 'onChange'> {
  error?: string
  errorOutside?: boolean
  height?: number
  hideMaxLength?: boolean
  onChange?: Dispatch<string>
  small?: boolean
  subtitle?: string
  title?: string
}

const TextArea: ForwardRefRenderFunction<HTMLTextAreaElement, Props & ClassNameProps> = (
  {
    className,
    error,
    errorOutside = false,
    height,
    hideMaxLength = false,
    placeholder,
    small,
    title,
    subtitle,
    ...props
  },
  ref,
) => {
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const { onBlur: onBlurProp, onChange: onChangeProp, onFocus: onFocusProp, ...inputProps } = props
  const { maxLength, value } = inputProps
  const isMaxLengthHidden = !maxLength || hideMaxLength

  const renderError = useMemo(() => error && <Error outside={errorOutside}>{error}</Error>, [error, errorOutside])

  const renderTitle = useMemo(
    () => title && <Title small={small} title={title} description={subtitle} />,
    [small, title, subtitle],
  )

  const renderPlaceholder = useMemo(
    () => placeholder && <Label floating={isFocused || !isEmpty(value)}>{placeholder}</Label>,
    [isFocused, placeholder, value],
  )

  const renderMaxLength = useMemo(
    () =>
      !isMaxLengthHidden && (
        <MaxLength>
          {size(isNumber(value) ? toString(value) : value)}/{maxLength}
        </MaxLength>
      ),
    [isMaxLengthHidden, maxLength, value],
  )

  const onChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(
    event => {
      onChangeProp?.(event.currentTarget.value)
    },
    [onChangeProp],
  )

  const onBlur = useCallback<FocusEventHandler<HTMLTextAreaElement>>(
    event => {
      setIsFocused(false)
      onBlurProp?.(event)
    },
    [onBlurProp, setIsFocused],
  )

  const onFocus = useCallback<FocusEventHandler<HTMLTextAreaElement>>(
    event => {
      setIsFocused(true)
      onFocusProp?.(event)
    },
    [onFocusProp, setIsFocused],
  )

  return (
    <Root className={className}>
      {renderTitle}
      <Container>
        {renderPlaceholder}
        {renderMaxLength}
        {renderError}
        <Input
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          ref={ref}
          hasPlaceholder={!isEmpty(placeholder)}
          hasError={isDefined(error)}
          $height={height}
          $small={small}
          {...inputProps}
        />
      </Container>
    </Root>
  )
}

const Root = Column

const Container = styled(Column)`
  position: relative;
`

interface InputProps extends InputPropsBase {
  $height?: number
}

const Input = styled.textarea<InputProps>`
  ${inputCss}

  height: ${({ $height }) => $height || (isMobile ? 6 : 5)}em;
  resize: none;
`

export default forwardRef(TextArea)
