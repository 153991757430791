import { AppAction as CoreAppAction, Nullable, PayloadAction } from '@lovejunk/core'
import { NavigateOptions, To } from 'react-router-dom'
import { Action } from 'redux'

export interface Navigation {
  to: number | To

  options?: NavigateOptions
}

export const ADVERTS_VISITED = 'ADVERTS_VISITED'
export type AdvertsVisited = Action<typeof ADVERTS_VISITED>

export const NAVIGATE = 'NAVIGATE'
export type Navigate = PayloadAction<typeof NAVIGATE, Nullable<Navigation>>

export const SET_MENU_VISIBLE = 'SET_MENU_VISIBLE'
export type SetMenuVisible = PayloadAction<typeof SET_MENU_VISIBLE, boolean>

export type AppAction = CoreAppAction | AdvertsVisited | Navigate | SetMenuVisible
