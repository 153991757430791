import { NoOp, squareSize } from '@lovejunk/core'
import { ReactComponent as PlayIcon } from 'assets/icons/play-circle.svg'
import React, { FC } from 'react'
import { styled } from 'styled'

import SvgIcon from './SvgIcon'

interface Props {
  size: number
  src: string

  onClick?: NoOp
}

const VideoTile: FC<Props> = ({ onClick, size, src }) => (
  <Root onClick={onClick}>
    <Video size={size} src={src} />
    <Play />
  </Root>
)

interface RootProps {
  onClick?: NoOp
}

const Root = styled.div<RootProps>`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
  position: relative;
`

const Play = styled(SvgIcon).attrs({ color: 'transparent', Icon: PlayIcon, size: squareSize(2), strokeColor: 'white' })`
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

interface VideoProps {
  size: number
}

const Video = styled.video<VideoProps>`
  border-radius: 0.5em;
  background-color: ${({ theme: { colors } }) => colors.black};
  display: block;
  object-fit: cover;
  width: ${({ size }) => size}em;
  height: ${({ size }) => size}em;
`

export default VideoTile
