import { buildBodyItem, hasItems, isDefined, isNotNull, undefineNull } from '../../helpers'
import { ImagesToBodyItems, marshallFixedTimeSlot, marshallTimeSlot } from '../../marshallers'
import { BodyItem, Marshal, PriceRange, Transform } from '../../types/core'
import { filterAssets } from '../../utils/assets'
import { isDateTimeSlotPayload } from './helpers'
import {
  NewAdvertResource,
  PriceSuggestionParams,
  PriceSuggestionQueryParams,
  SuggestedPriceRange,
  ValidNewAdvert,
  VideosPayload,
} from './types'

export const validNewAdvertToResource: Marshal<ValidNewAdvert, NewAdvertResource> = ({
  address,
  collectionPoint: collectionPointDetails,
  details,
  fixedTimeSlot,
  id,
  isReuseOnly,
  manualAddressEntry,
  partnerDetails,
  reusable,
  selectedCollectors: preferredCollectorIds,
  suggestedPriceRange,
  summary,
  timeSlot,
  title,
}) => ({
  address,
  collectionPointDetails,
  details,
  isReuseOnly: undefineNull(reusable ? isReuseOnly : null),
  manualAddressEntry,
  preferredCollectorIds,
  previousAdvertId: undefineNull(id),
  reusable,
  suggestedPriceRange,
  summary,
  title,
  ...partnerDetails,
  ...(isNotNull(fixedTimeSlot)
    ? { fixedTimeSlot: marshallFixedTimeSlot(fixedTimeSlot) }
    : isNotNull(timeSlot)
    ? isDateTimeSlotPayload(timeSlot)
      ? { timeSlot: marshallTimeSlot(timeSlot) }
      : { openTimeSlot: timeSlot.date }
    : null),
})

interface AdvertMarshalPayload {
  advert: ValidNewAdvert

  hasPaymentMethod?: boolean
  videos?: VideosPayload
}

export const marshalNewAdvert: Transform<ImagesToBodyItems, Marshal<AdvertMarshalPayload, Promise<BodyItem[]>>> =
  imagesToBodyItems =>
  ({ advert, hasPaymentMethod, videos }) =>
    imagesToBodyItems(filterAssets('image')(advert.assets)).then(imageBodyItems => [
      buildBodyItem('advertData', {
        ...validNewAdvertToResource(advert),
        ...(isDefined(videos) && hasItems(videos.ids) ? { videos } : null),
        ...(isDefined(hasPaymentMethod) ? { hasPaymentMethod } : null),
      }),
      ...imageBodyItems,
    ])

export const marshalPriceSuggestionParams: Marshal<PriceSuggestionParams, PriceSuggestionQueryParams> = ({
  isBuilderWaste,
  isEasyAccess,
  isReusable,
  ...rest
}) => ({
  'is-builder-waste': isBuilderWaste,
  'is-easy-access': isEasyAccess,
  'is-reusable': isReusable,
  ...rest,
})

export const unmarshalPriceSuggestionRange: Marshal<SuggestedPriceRange, PriceRange> = ({ from: start, to: end }) => ({
  start,
  end,
})
