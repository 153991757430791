import { DividerElementsProps as ElementsProps, dividerFactory as factory } from '@lovejunk/core'
import { styled } from 'styled'

export default factory({
  Root: styled.hr<ElementsProps['Root']>`
    margin: ${({ spacing }) => `${spacing}em 0`};
    background-color: ${({ color, theme: { colors } }) => colors[color]};
    border: none;
    height: ${({ thickness }) => thickness}px;
    width: 100%;
  `,
})
