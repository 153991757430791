import { chatAttachmentsFactory as factory } from '@lovejunk/core'
import { styled } from 'styled'

import Row from '../containers/Row'
import Attachment from './Attachment'

export default factory({
  Attachment,
  Root: styled(Row)`
    flex-wrap: wrap;
    margin-bottom: 0.5em;
  `,
})
