import { HAZARDOUS_WASTE_URL, TERMS_URL } from '@lovejunk/core'
import React from 'react'
import { styled } from 'styled'
import { GALLERY_URL } from 'utils/constants'

import ExternalLinkBase from '../ExternalLink'

const Bold = styled.span`
  font-weight: bold;
`

const ExternalLink = styled(ExternalLinkBase)`
  text-decoration: none;
`

export type ItemId =
  | 'CanICancel'
  | 'CanIEdit'
  | 'CollectorOffer'
  | 'ExpectedPrice'
  | 'HowDoIGetMatched'
  | 'HowDoIGetTheCheapest'
  | 'HowDoIPay'
  | 'HowQuickly'
  | 'ProhibitedWaste'
  | 'PriceVat'
  | 'Redeem'
  | 'WhatIfIDontAccept'

export type Item = {
  title: React.ReactNode
  description: React.ReactNode
}

const items: Record<ItemId, Item> = {
  ExpectedPrice: {
    title: 'What price should I expect to pay?',
    description: (
      <>
        Cost of collection depends on amount and type of junk, ease of removal, and urgency. Visit our{' '}
        <ExternalLink href={`${GALLERY_URL}/gallery/rubbish-removal`}>Job Gallery</ExternalLink> (filter by junk type)
        to see collection prices of previous jobs similar to yours.
      </>
    ),
  },
  CollectorOffer: {
    title: 'What is a Collector Offer?',
    description: (
      <>
        A Collector Offer is an offer from a collector to pick up your junk for a specified charge. Accept or ignore
        Collector Offers as you like, but please don't take offence if an early one seems high. Just wait for a better
        one to follow.
      </>
    ),
  },
  HowQuickly: {
    title: 'How quickly is a new listing responded to?',
    description: (
      <>
        Listings normally start to receive Collector Offers <Bold>within seconds</Bold>. No response is usually because
        photos or description are unclear or your pickup time is very challenging (eg. Sun 22:00–00:00 and only listed
        Sunday afternoon).
      </>
    ),
  },
  HowDoIGetMatched: {
    title: 'How do I get Matched?',
    description: <>A match happens when you accept a Collector Offer.</>,
  },
  HowDoIGetTheCheapest: {
    title: 'How do I get the cheapest price possible?',
    description: (
      <ul>
        <li>
          Choose a weekday pickup and flexible timeslot (AM, PM or Anytime), use good photos, and make the junk as
          easily accessible as possible
        </li>
        <li>Publish your listing between 08:00 – 17:00 Mon-Fri so as many collectors as possible see it</li>
      </ul>
    ),
  },
  CanIEdit: {
    title: 'Can I edit my listing after it is live?',
    description: (
      <>
        Yes. Click the 'Cancel or edit' in your listing to make edits. Note that any active collector offers will be
        automatically withdrawn when you publish an edited listing.
      </>
    ),
  },
  CanICancel: {
    title: 'Can I cancel my listing after it is live?',
    description: (
      <>
        You can cancel your listing any time before it is matched to a collector. If you need to rearrange a collection
        after the job has been matched, please contact your collector directly. Under our{' '}
        <ExternalLink href={TERMS_URL}>Terms</ExternalLink>, you can be charged a wasted journey fee (£20) if you cancel
        a collection once a collector is already on their way or at site.
      </>
    ),
  },
  WhatIfIDontAccept: {
    title: "What if I don't accept a collector offer?",
    description: (
      <>
        If you don't accept any collector offer, the listing will lapse at the start of your specified collection time
        slot. You will not be charged and can easily repost, perhaps offering a clearer description or more flexible
        timeslot?
      </>
    ),
  },
  HowDoIPay: {
    title: 'How do I pay for my collection?',
    description: (
      <>
        Payment is made online via LoveJunk after the collection is complete. LoveJunk does not support cash payments.
      </>
    ),
  },
  PriceVat: {
    title: 'Does the waste collection price include VAT?',
    description: (
      <>
        The price includes VAT if the waste collector of your junk is VAT registered. Most collectors on LoveJunk are
        VAT registered, so most of the time the price will include VAT. If applicable, VAT will show in Collector Offer
        notifications and your final receipt.
      </>
    ),
  },
  Redeem: {
    title: 'How do I redeem a referral credit?',
    description: (
      <>
        A referral credit is redeemed automatically when you pay for your first collection, reducing the amount you have
        to pay. It will also show on your receipt.
      </>
    ),
  },
  ProhibitedWaste: {
    title: 'Prohibited waste?',
    description: (
      <>
        LoveJunk is for non-hazardous waste only. Click <ExternalLink href={HAZARDOUS_WASTE_URL}>here</ExternalLink> to
        see which materials cannot be disposed of through this marketplace.
      </>
    ),
  },
}

export default items
