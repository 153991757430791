import { LoginPayload as CoreLoginPayload, Nullable, PayloadAction } from '@lovejunk/core'

export interface LoginPayload extends CoreLoginPayload {
  recaptchaToken: string
}

export const SET_RETURN_TO = 'SET_RETURN_TO'

export type SetReturnTo = PayloadAction<typeof SET_RETURN_TO, Nullable<string>>

export type LoginAction = SetReturnTo
