import buildConfig from '../../utils/build-config'
import { CollectorConfig, CustomerConfig, JunkReuserConfig, RoleConfig, SignupConfig, SupplierConfig } from './types'

export const isCustomerConfig = (config: RoleConfig): config is CustomerConfig =>
  (config as CustomerConfig).requiredCapability === 'customer'

export const isCollectorConfig = (config: RoleConfig): config is CollectorConfig =>
  (config as CollectorConfig).requiredCapability === 'collector'

export const isJunkReuserConfig = (config: RoleConfig): config is JunkReuserConfig =>
  (config as JunkReuserConfig).requiredCapability === 'junklover'

export const isSupplierConfig = (config: RoleConfig): config is SupplierConfig =>
  isCollectorConfig(config) || isJunkReuserConfig(config)

export const createCustomerConfig = (returnTo?: string): CustomerConfig => ({
  requiredCapability: 'customer',
  returnTo,
})

export const createCollectorConfig = (returnTo?: string): CollectorConfig => ({
  requiredCapability: 'collector',
  returnTo,
})

export const createJunkReuserConfig = (returnTo?: string): JunkReuserConfig => ({
  requiredCapability: 'junklover',
  returnTo,
})

const { getConfig, setConfig } = buildConfig<SignupConfig>('signup')

export { getConfig as getSignupConfig, setConfig as configureSignup }
