import { isDefined, isNotNull } from '../../helpers'
import { Color } from '../../theme'
import { Nullable, Transform } from '../../types/core'
import { FontWeight } from '../../types/ui'

export interface ToggleButtonProps {
  state: boolean
  value: Nullable<boolean>

  disabled?: boolean
  error?: string
  mini?: boolean
  width?: number
}

export const toggleButtonFontWeight: Transform<ToggleButtonProps, FontWeight> = ({ state, value }) =>
  state === value ? 600 : 'normal'

export const toggleButtonFontColor: Transform<ToggleButtonProps, Color> = ({ disabled, state, value }) =>
  state === value ? 'white' : disabled ? 'contrast3' : isNotNull(value) ? 'contrast2' : 'black'

export const toggleButtonBackgroundColor: Transform<ToggleButtonProps, Color> = ({ disabled, state, value }) =>
  state === value ? (disabled ? 'grey' : 'selected') : 'white'

export const toggleButtonBorderColor: Transform<ToggleButtonProps, Color> = ({ disabled, state, value }) =>
  state === value && !disabled ? 'selected' : 'greyBorder'

export const toggleButtonBorderBottomColor: Transform<ToggleButtonProps, Color> = props =>
  isDefined(props.error) ? 'error' : toggleButtonBorderColor(props)
