import { action, failureAction, payloadAction } from '../../action-factory'
import apiAction, { ActionBundle } from '../../api-action'
import { StatelessPayloadThunk } from '../../types/thunk'
import { favouritesApi } from './api'
import {
  FAVOURITES_FAILURE,
  FAVOURITES_START,
  FAVOURITES_SUCCESS,
  FavouritesFailure,
  FavouritesResult,
  FavouritesStart,
  FavouritesSuccess,
} from './types'

const favouritesActionBundle: ActionBundle<
  FavouritesStart,
  FavouritesSuccess,
  FavouritesFailure,
  FavouritesSuccess['payload']
> = [action(FAVOURITES_START), payloadAction(FAVOURITES_SUCCESS), failureAction(FAVOURITES_FAILURE)]

export const fetchFavourites: StatelessPayloadThunk<string, FavouritesResult> = url => dispatch =>
  dispatch(apiAction(favouritesActionBundle)(favouritesApi(url)))
