// @ts-ignore
// eslint-disable-next-line
import Intl from 'intl'
// Locale must be imported after 'intl
import 'intl/locale-data/jsonp/en-GB'
import { find, flow, identity, includes, indexOf, isInteger, nth, size, startsWith } from 'lodash/fp'

import { DEFAULT_CURRENCY } from '../constants'
import { AuthIdentifierType, AuthIdentifierValue, Currency, Format, Identity, Price, Range } from '../types/core'
import { defaultCountry } from './countries'
import { priceFromAmount } from './price'
import { prependWith } from './string'

export const currencySymbol = (currency: Currency) => {
  const nf = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency,
  })

  return find<Intl.NumberFormatPart>({ type: 'currency' })(nf.formatToParts())?.value
}

export const defaultCurrencySymbol = currencySymbol(DEFAULT_CURRENCY)

export const formatAmount = (amount: number, currency?: Currency) =>
  flow(priceFromAmount(amount), formatPrice)(currency)

export const formatPriceRange = ({ min, max }: Range<number>, currency = DEFAULT_CURRENCY) =>
  `${formatAmount(min, currency)} - ${formatAmount(max, currency)}`

export const formatPrice = ({ amount, currency }: Price, retainDecimals = false) =>
  new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency,
    minimumFractionDigits: isInteger(amount) && !retainDecimals ? 0 : 2,
    maximumFractionDigits: 2,
  }).format(amount)

export const formatRating = (rating: number, fractionDigits = 2) =>
  new Intl.NumberFormat('en-GB', {
    style: 'decimal',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(rating)

export const formatReliability = (rating: number) =>
  new Intl.NumberFormat('en-GB', { style: 'decimal', maximumFractionDigits: 0 }).format(rating)

export const formatEmail: Format<string> = identity

export const formatPhoneNumber: Format<string> = phoneNumber => {
  const { dialCode } = defaultCountry
  const dialCodeWithoutPlus = dialCode.replace(/^\+?(\d*)/, '$1')

  return (
    startsWith(dialCode)(phoneNumber)
      ? prependWith('')
      : startsWith(dialCodeWithoutPlus)(phoneNumber)
      ? prependWith('+')
      : prependWith(defaultCountry.dialCode)
  )(phoneNumber)
}

export const formatAuthIdentifierValue: Format<AuthIdentifierValue> = identifier =>
  (includes('@')(identifier) ? formatEmail : formatPhoneNumber)(identifier)

const authIdentifierTypes: AuthIdentifierType[] = ['email', 'phone']

const authIdentifierTypeToString: Record<AuthIdentifierType, string> = {
  email: 'email address',
  phone: 'phone number',
}
export const formatAuthIdentifierType: Format<AuthIdentifierType> = type => authIdentifierTypeToString[type]

export const isAuthIdentifierType = (type: string): type is AuthIdentifierType => includes(type)(authIdentifierTypes)

export const alternativeAuthIdentifierType: Identity<AuthIdentifierType> = type =>
  nth((indexOf(type)(authIdentifierTypes) + 1) % size(authIdentifierTypes))(authIdentifierTypes) as AuthIdentifierType
