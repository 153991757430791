import { Dispatch, Nullable } from '@lovejunk/core'
import { ReactComponent as Arrow } from 'assets/icons/down-arrow.svg'
import React, { useCallback } from 'react'
import { styled, ThemeProps } from 'styled'
import { withTheme } from 'styled-components'
import { device, withSpacing } from 'utils/css'

import Row from '../containers/Row'
import { Item as ItemProps, ItemId } from './items'

type Props = {
  index: ItemId
  isOpen: boolean
  onChange: Dispatch<Nullable<ItemId>>
} & ItemProps

const Item = ({ description, index, isOpen, onChange, title }: Props & ThemeProps) => {
  const onClick = useCallback(() => {
    if (onChange) {
      onChange(isOpen ? null : index)
    }
  }, [onChange, index, isOpen])

  return (
    <Root onClick={onClick}>
      <Container>
        <Title $isOpen={isOpen}>{title}</Title>
        <Icon $isOpen={isOpen} />
      </Container>
      <Description $isOpen={isOpen}>{description}</Description>
    </Root>
  )
}

interface StateProps {
  $isOpen: boolean
}

const Title = styled.div<StateProps>`
  font-size: 16px;
  color: ${({ $isOpen, theme: { colors } }) => ($isOpen ? colors.selected : colors.text)};
`

const Icon = styled(Arrow)<StateProps>`
  max-height: 1.25em;
  max-width: 1.25em;
  min-height: 1.25em;
  min-width: 1.25em;
  transition: transform 0.4s ease-in-out;
  transform: ${({ $isOpen }) => ($isOpen ? 'rotateZ(180deg)' : 'rotateZ(360deg)')};
  fill: ${({ $isOpen, theme: { colors } }) => ($isOpen ? colors.selected : colors.black)};
`

const Container = styled(Row)`
  ${withSpacing()}

  justify-content: space-between;
`

const Description = styled.div<StateProps>`
  font-size: 0.875em;
  margin-top: 0.625em;
  transition: max-height 0.4s ease-in-out;
  overflow: hidden;
  color: ${({ theme: { colors } }) => colors.text};
  max-height: ${({ $isOpen }) => ($isOpen ? '400px' : '0')};
`

const Root = styled.div`
  cursor: pointer;
  padding: 1em 2em;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme: { colors } }) => colors.grey};

  &:last-child {
    border-bottom-color: transparent;
  }

  @media ${device.mobile} {
    padding: 1em;
  }
`

export default withTheme(Item)
