import { isDefined, isNotEmpty } from '@lovejunk/core'
import React, { useMemo } from 'react'
import ReactSelect, {
  defaultTheme,
  GroupBase,
  Props as ReactSelectProps,
  StylesConfig,
  ThemeConfig,
} from 'react-select'
import { styled, ThemeProps, useTheme } from 'styled'

import Column from '../containers/Column'
import Label from '../Label'

export interface Props {
  className?: string
  disabled?: boolean
  error?: string
  title?: string
  hideErrorMessage?: boolean
}

const SelectInput = function <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  className,
  error,
  disabled,
  hideErrorMessage,
  onChange,
  options,
  placeholder,
  title,
  value,
}: Props & Omit<ReactSelectProps<Option, IsMulti, Group>, 'theme'>) {
  const {
    colors: { error: requiredColor, greyBorder: greyBorderColor, secondary: secondaryColor },
  } = useTheme()
  const borderColor = useMemo<string>(
    () => (isDefined(error) ? requiredColor : greyBorderColor),
    [error, requiredColor, greyBorderColor],
  )
  const styles = useMemo<StylesConfig<Option, IsMulti, Group>>(
    () => ({ control: provided => ({ ...provided, borderColor, '&:hover': borderColor }) }),
    [borderColor],
  )

  const reactSelectTheme = useMemo<ThemeConfig>(
    () => ({ ...defaultTheme, colors: { ...defaultTheme.colors, primary: secondaryColor } }),
    [secondaryColor],
  )

  return (
    <Root className={className}>
      {isDefined(title) && isNotEmpty(title) ? <Label title={title} /> : null}
      <Container>
        <ReactSelect<Option, IsMulti, Group>
          isDisabled={disabled}
          onChange={onChange}
          options={options}
          placeholder={placeholder}
          styles={styles}
          theme={reactSelectTheme}
          value={value}
        />
        {isDefined(error) && !hideErrorMessage ? <Error>{error}</Error> : null}
      </Container>
    </Root>
  )
}

const Root = styled(Column)`
  flex: 1;
  position: relative;
  // TextInput's input has z-index: 1.
  z-index: 2;
`

const Container = styled.div`
  position: relative;
`

const Error = styled.p<ThemeProps>`
  display: block;
  margin: 0;
  position: absolute;
  right: 0px;
  top: -20px;
  color: ${({ theme: { colors } }) => colors.error};
  font-weight: bold;
  text-align: left;
  font-size: 12px;
`

export default SelectInput
