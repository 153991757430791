import { includes, isError, toString, union, without } from 'lodash/fp'
import { parse as parseQuery, stringify as stringifyQuery } from 'query-string'
import HttpError from 'standard-http-error'
import { parse as parseUri, serialize as serializeUri } from 'uri-js'

import { NetworkError, OAuthTokenError, TimeoutError } from '../errors'
import { hasItems, isDefined } from '../helpers'
import { Dispatch, Transform } from '../types/core'
import buildConfig from '../utils/build-config'
import {
  ApiConfig,
  ApiResponse,
  DelOptions,
  Feature,
  GetOptions,
  Headers as ApiHeaders,
  MultipartFetchOptions,
  MultipartOptions,
  MultipartRequestOptions,
  PatchOptions,
  PostOptions,
  PutOptions,
  RegularFetchOptions,
  RegularRequestOptions,
  RequestOptions,
  RequestResult,
  Timeout,
} from './types'

const { getConfig, setConfig, setConfigKey } = buildConfig<ApiConfig>('api')

export { setConfig as configureApi }

export const setApiConfigUrl: Dispatch<string> = apiUrl => setConfigKey('url', () => apiUrl)

export const setMultipleCollectorOffers: Dispatch<boolean> = active =>
  setConfigKey('features', features => (active ? union : without)<Feature>(['MultipleCollectorOffers'])(features))

const includesJson = includes('json')

export const request = async <B>(requestOptions: RequestOptions): Promise<RequestResult<B>> => {
  const config = getConfig()
  const { fetch, fetchMultipart, url: baseUrl } = config
  const { retry = true, timeout, timeoutAlert = false } = requestOptions
  const url = getUrl(baseUrl, requestOptions)
  const response = await withTimeout(
    timeout,
    url,
    timeoutAlert,
  )(
    isMultipartRequestOptions(requestOptions)
      ? fetchMultipart({ url, ...(await createMultipartOptions(requestOptions)) })
      : fetch({ url, ...(await createRegularOptions(requestOptions)) }),
  ).catch(e => {
    if (isError(e) && e.message === 'Network request failed') throw new NetworkError(url, timeoutAlert)
    else throw e
  })

  const { headers, status } = response
  const contentType = headers[HTTP_CONTENT_TYPE_V2] || headers[HTTP_CONTENT_TYPE]

  if (status === 401 && retry) {
    throw new OAuthTokenError(requestOptions)
  }

  if (status >= 400) {
    const message = await getErrorMessageSafely(response)

    throw new HttpError(status, message)
  }

  return {
    status,
    headers,
    body: includesJson(contentType) ? await response.json() : {},
  }
}

interface GetRequestHeadersPayload {
  skipAuthHeaders?: boolean
  skipAuthorizationHeader?: boolean
  skipSessionHeader?: boolean
  userAgent?: boolean
}

const getRequestHeaders: Transform<GetRequestHeadersPayload, Promise<ApiHeaders>> = async ({
  skipAuthHeaders = false,
  skipAuthorizationHeader = false,
  skipSessionHeader = false,
  userAgent = false,
}) => {
  const { features, getCredentials, getIdentifier, getSessionToken, getUserAgent, platform, version } = getConfig()
  const credentials = getCredentials()
  const sessionToken = getSessionToken()

  const headers: ApiHeaders = {
    Accept: 'application/json',
    AdvertDetailsFormat: '2',
    ...(isDefined(features) && hasItems(features) ? { features: JSON.stringify(features) } : undefined),
    // Must be specified because of https://github.com/facebook/react-native/issues/25244#issuecomment-693201838
    ...(platform === 'app' ? { 'Content-Type': 'multipart/form-data' } : undefined),
    DeviceSession: await getIdentifier(),
    Platform: platform,
    ...(isDefined(version) ? { AppVersion: version } : undefined),
    ...(userAgent ? { 'User-Agent': await getUserAgent() } : undefined),
  }

  if (!skipAuthHeaders) {
    if (credentials) {
      const { accessToken, tokenType } = credentials

      if (accessToken && tokenType && !skipAuthorizationHeader) {
        headers.Authorization = `${tokenType} ${accessToken}`
      }
    }

    if (sessionToken && !skipSessionHeader) {
      headers.Session = sessionToken
    }
  }

  return headers
}

interface GenericGetOptionsPayload<R, F> {
  requestOptions: R
  fetchOptions: F
}

type RegularGetOptionsPayload = GenericGetOptionsPayload<RegularRequestOptions, RegularFetchOptions>
type MultipartGetOptionsPayload = GenericGetOptionsPayload<MultipartRequestOptions, MultipartFetchOptions>
type GetOptionsPayload = RegularGetOptionsPayload | MultipartGetOptionsPayload

type CreateOptions<T extends GetOptionsPayload> = Transform<
  T['requestOptions'],
  Promise<Omit<T['fetchOptions'], 'url'>>
>

const createRegularOptions: CreateOptions<RegularGetOptionsPayload> = async ({ body, method = 'get', ...rest }) => ({
  headers: await getRequestHeaders(rest),
  method,
  ...(body && method !== 'get' ? { body: JSON.stringify(body) } : null),
})

const createMultipartOptions: CreateOptions<MultipartGetOptionsPayload> = async ({
  multipartBody: body,
  method = 'post',
  ...rest
}) => ({ body, headers: await getRequestHeaders(rest), method })

const HTTP_CONTENT_TYPE = 'Content-Type'
const HTTP_CONTENT_TYPE_V2 = 'content-type'

const isMultipartRequestOptions = (options: RequestOptions): options is MultipartRequestOptions =>
  isDefined((options as MultipartRequestOptions).multipartBody)

type Body = RequestOptions['body']
type Uri = RequestOptions['uri']
type MultipartBody = MultipartRequestOptions['multipartBody']

export const toBody = <B>({ body }: { body: B }) => body

export const get = <B>(uri: Uri, options?: GetOptions) => request<B>({ ...options, method: 'get', uri }).then(toBody)

export const post = <B>(uri: Uri, body?: Body, options?: PostOptions) =>
  request<B>({ ...options, method: 'post', uri, body }).then(toBody)

export const put = <B>(uri: Uri, body?: Body, options?: PutOptions) =>
  request<B>({ ...options, method: 'put', uri, body }).then(toBody)

export const patch = <B>(uri: Uri, body: Body, options?: PatchOptions) =>
  request<B>({ ...options, method: 'patch', uri, body }).then(toBody)

export const del = <B>(uri: Uri, options?: DelOptions) => request<B>({ ...options, method: 'delete', uri }).then(toBody)

export const multipart = <B>(uri: Uri, multipartBody: MultipartBody, options?: MultipartOptions) =>
  request<B>({ ...options, uri, multipartBody }).then(toBody)

const getUrl = (url: string, { params, uri }: RequestOptions) => {
  const uriComponents = parseUri(uri)
  const apiComponents = parseUri(url)

  // Concatenate paths only if `uri` is not a full URL
  if (!uriComponents.host) {
    uriComponents.host = apiComponents.host
    uriComponents.path = toString(apiComponents.path) + toString(uriComponents.path)
  }

  if (!uriComponents.scheme) {
    uriComponents.scheme = apiComponents.scheme
  }

  if (!uriComponents.port) {
    uriComponents.port = apiComponents.port
  }

  if (params) {
    uriComponents.query = stringifyQuery({ ...parseQuery(toString(uriComponents.query)), ...params })
  }

  return serializeUri(uriComponents)
}

function getErrorMessageSafely(response: ApiResponse) {
  try {
    return response.text()
  } catch (err) {
    return `[HTTP error] status: ${response.status}` + (err instanceof Error ? `, message: ${err.message}` : '')
  }
}

const timeoutToValue: Record<Timeout, number> = {
  default: 10,
  extended: 30,
}

const withTimeout =
  (timeout: Timeout = 'default', url: string, alert: boolean) =>
  <T>(promise: Promise<T>): Promise<T> =>
    new Promise((resolve, reject) => {
      const value = timeoutToValue[timeout]

      setTimeout(() => reject(new TimeoutError(value, url, alert)), 1000 * value)

      promise.then(resolve).catch(reject)
    })

const toHeadersObject: Transform<Headers, ApiHeaders> = headers => {
  const result: ApiHeaders = {}

  headers.forEach((value: string, key: string) => {
    result[key] = value
  })

  return result
}

export const toApiResponse: Transform<Response, ApiResponse> = response => ({
  headers: toHeadersObject(response.headers),
  json: () => response.json(),
  status: response.status,
  text: () => response.text(),
})
