import { Nullable } from '../../types/core'
import { Configuration, SelectedActivity } from './types'

export interface AppState {
  apiUrl: string
  configuration: Nullable<Configuration>
  error: Nullable<Error>
  hadSuccessfulOauthCheck: boolean
  hasRemoteConfig: boolean
  isApiUrlFetched: boolean
  isRehydrated: boolean
  selectedActivity: Nullable<SelectedActivity>
}

export const appDefaultState: AppState = {
  apiUrl: '',
  configuration: null,
  error: null,
  hadSuccessfulOauthCheck: false,
  hasRemoteConfig: false,
  isApiUrlFetched: false,
  isRehydrated: false,
  selectedActivity: null,
}

export default AppState
