import {
  CANCEL_ADVERT_FAILURE,
  CANCEL_ADVERT_START,
  CANCEL_ADVERT_SUCCESS,
  customerAdvertReducer,
  isCancellingLens,
  RELIST_ADVERT_SUCCESS,
  SupportedAction,
} from '@lovejunk/core'
import { flow } from 'lodash/fp'
import Reducer from 'types/reducer'

import { cancelAdvertPayloadLens, relistOrCancelPayloadLens } from './lens'
import State, { defaultState } from './state'
import { RELIST_OR_CANCEL, SET_CANCEL_ADVERT_PAYLOAD } from './types'

export const reducer: Reducer<State> = (newState = defaultState(), action) => {
  const state = customerAdvertReducer(newState, action as SupportedAction) as State

  switch (action.type) {
    case RELIST_OR_CANCEL:
      return relistOrCancelPayloadLens(state).set(action.payload)
    case RELIST_ADVERT_SUCCESS:
      return relistOrCancelPayloadLens(state).set(null)
    case SET_CANCEL_ADVERT_PAYLOAD:
      return cancelAdvertPayloadLens(state).set(action.payload)
    case CANCEL_ADVERT_START:
      return isCancellingLens(state).set(true)
    case CANCEL_ADVERT_FAILURE:
      return isCancellingLens(state).set(false)
    case CANCEL_ADVERT_SUCCESS:
      return flow(
        isCancellingLens,
        l => l.set(false),
        cancelAdvertPayloadLens,
        l => l.set(null),
      )(state)
    default:
      return state
  }
}

export default reducer
