import { chunk, compact, find, flow, groupBy, last, map } from 'lodash/fp'

import { action, failureAction, payloadAction } from '../../action-factory'
import apiAction, { ActionBundle } from '../../api-action'
import { NoActivityToReloadHasUnreadMessagesError, NoReloadHasUnreadMessagesActionError } from '../../errors'
import { buildBodyItem, isDefined, isPresent, promiseAll } from '../../helpers'
import {
  ActionFactory,
  Asset,
  BodyItem,
  FailureActionFactory,
  FileItem,
  Optional,
  PayloadActionFactory,
  Transform,
  TransformOptional,
} from '../../types/core'
import { StatelessOptionalPayloadThunk, StatelessPayloadThunk } from '../../types/thunk'
import { assetToMimeType, mimeToAssetType } from '../../utils'
import { ActivityItem } from '../activities/types'
import { setAdvertNeedsFetch } from '../advert/actions'
import { withEndpoint } from '../app/helpers'
import { getReloadFavouriteMessagesData } from '../favourites/helpers'
import {
  chatNavigationApi,
  createChatThreadApi,
  fetchChatMessagesApi,
  fetchChatMessagesUpdatesApi,
  reloadActivityHasUnreadMessagesApi,
  reloadFavouritesHasUnreadMessagesApi,
  reloadGlobalHasUnreadMessagesApi,
  reloadHasUnreadMessagesApi,
  sendChatMessageApi,
  sendChatMessageWithAssetsApi,
  startConferenceCallApi,
  viewPhoneApi,
} from './api'
import {
  ADD_PHONE_REQUESTED,
  AddPhoneRequested,
  CHAT_NAVIGATION_FAILURE,
  CHAT_NAVIGATION_START,
  CHAT_NAVIGATION_SUCCESS,
  ChatId,
  ChatNavigationBody,
  ChatNavigationFailure,
  ChatNavigationResult,
  ChatNavigationStart,
  ChatNavigationSuccess,
  CLOSE_CHAT,
  CLOSE_CONFERENCE_CALL_MODAL,
  CloseChat,
  CloseConferenceCallModal,
  CREATE_CHAT_THREAD_FAILURE,
  CREATE_CHAT_THREAD_START,
  CREATE_CHAT_THREAD_SUCCESS,
  CreateChatThreadBody,
  CreateChatThreadFailure,
  CreateChatThreadPayload,
  CreateChatThreadResult,
  CreateChatThreadStart,
  CreateChatThreadSuccess,
  FETCH_CHAT_MESSAGES_FAILURE,
  FETCH_CHAT_MESSAGES_START,
  FETCH_CHAT_MESSAGES_SUCCESS,
  FETCH_CHAT_MESSAGES_UPDATES_FAILURE,
  FETCH_CHAT_MESSAGES_UPDATES_START,
  FETCH_CHAT_MESSAGES_UPDATES_SUCCESS,
  FetchChatMessagesBody,
  FetchChatMessagesFailure,
  FetchChatMessagesPayload,
  FetchChatMessagesResult,
  FetchChatMessagesStart,
  FetchChatMessagesSuccess,
  FetchChatMessagesUpdatesBody,
  FetchChatMessagesUpdatesFailure,
  FetchChatMessagesUpdatesPayload,
  FetchChatMessagesUpdatesResult,
  FetchChatMessagesUpdatesStart,
  FetchChatMessagesUpdatesSuccess,
  MessageJson,
  PUSH_CONTACT_MESSAGE,
  PushContactMessage,
  RELOAD_ACTIVITY_HAS_UNREAD_MESSAGES_FAILURE,
  RELOAD_ACTIVITY_HAS_UNREAD_MESSAGES_START,
  RELOAD_ACTIVITY_HAS_UNREAD_MESSAGES_SUCCESS,
  RELOAD_FAVOURITES_HAS_UNREAD_MESSAGES_FAILURE,
  RELOAD_FAVOURITES_HAS_UNREAD_MESSAGES_START,
  RELOAD_FAVOURITES_HAS_UNREAD_MESSAGES_SUCCESS,
  RELOAD_GLOBAL_HAS_UNREAD_MESSAGES_FAILURE,
  RELOAD_GLOBAL_HAS_UNREAD_MESSAGES_START,
  RELOAD_GLOBAL_HAS_UNREAD_MESSAGES_SUCCESS,
  RELOAD_HAS_UNREAD_MESSAGES_FAILURE,
  RELOAD_HAS_UNREAD_MESSAGES_START,
  RELOAD_HAS_UNREAD_MESSAGES_SUCCESS,
  ReloadActivityHasUnreadMessagesFailure,
  ReloadActivityHasUnreadMessagesPayload,
  ReloadActivityHasUnreadMessagesResult,
  ReloadActivityHasUnreadMessagesStart,
  ReloadActivityHasUnreadMessagesSuccess,
  ReloadActivityHasUnreadMessagesSuccessPayload,
  ReloadFavouritesHasUnreadMessagesFailure,
  ReloadFavouritesHasUnreadMessagesPayload,
  ReloadFavouritesHasUnreadMessagesResult,
  ReloadFavouritesHasUnreadMessagesStart,
  ReloadFavouritesHasUnreadMessagesSuccess,
  ReloadFavouritesHasUnreadMessagesSuccessPayload,
  ReloadGlobalHasUnreadMessagesFailure,
  ReloadGlobalHasUnreadMessagesResult,
  ReloadGlobalHasUnreadMessagesStart,
  ReloadGlobalHasUnreadMessagesSuccess,
  ReloadHasUnreadMessagesFailure,
  ReloadHasUnreadMessagesResult,
  ReloadHasUnreadMessagesStart,
  ReloadHasUnreadMessagesSuccess,
  SEND_CHAT_MESSAGE_BUNDLE_FAILURE,
  SEND_CHAT_MESSAGE_BUNDLE_SUCCESS,
  SEND_CHAT_MESSAGE_FAILURE,
  SEND_CHAT_MESSAGE_START,
  SEND_CHAT_MESSAGE_SUCCESS,
  SendChatMessageBundleFailure,
  SendChatMessageBundlePayload,
  SendChatMessageBundleResult,
  SendChatMessageBundleSuccess,
  SendChatMessageFailure,
  SendChatMessageResult,
  SendChatMessageStart,
  SendChatMessageSuccess,
  SentChatMessageBody,
  SET_CONFERENCE_CALL_PAYLOAD,
  SetConferenceCallPayload,
  SHOW_CONFERENCE_CALL_MODAL,
  ShowConferenceCallModal,
  START_CONFERENCE_CALL_FAILURE,
  START_CONFERENCE_CALL_START,
  START_CONFERENCE_CALL_SUCCESS,
  StartConferenceCallFailure,
  StartConferenceCallPayload,
  StartConferenceCallResult,
  StartConferenceCallStart,
  StartConferenceCallSuccess,
  SubmitChatMessagePayload,
  VIEW_PHONE_FAILURE,
  VIEW_PHONE_START,
  VIEW_PHONE_SUCCESS,
  ViewPhoneFailure,
  ViewPhoneResult,
  ViewPhoneStart,
  ViewPhoneSuccess,
} from './types'

const fetchChatFailure: FailureActionFactory<ChatNavigationFailure> = failureAction(CHAT_NAVIGATION_FAILURE)

const chatNavigationActionBundle: ActionBundle<
  ChatNavigationStart,
  ChatNavigationSuccess,
  ChatNavigationFailure,
  ChatNavigationBody
> = [action(CHAT_NAVIGATION_START), payloadAction(CHAT_NAVIGATION_SUCCESS), fetchChatFailure]

export const chatNavigation: StatelessPayloadThunk<ChatId, ChatNavigationResult> = chatId =>
  withEndpoint(
    'chatNavigation',
    chatNavigationUrl => dispatch =>
      dispatch(apiAction(chatNavigationActionBundle)(chatNavigationApi(chatNavigationUrl)(chatId))),
    fetchChatFailure,
  )

const createChatThreadActionBundle: ActionBundle<
  CreateChatThreadStart,
  CreateChatThreadSuccess,
  CreateChatThreadFailure,
  CreateChatThreadBody
> = [
  action(CREATE_CHAT_THREAD_START),
  payloadAction(CREATE_CHAT_THREAD_SUCCESS),
  failureAction(CREATE_CHAT_THREAD_FAILURE),
]

export const createChatThread: StatelessPayloadThunk<CreateChatThreadPayload, CreateChatThreadResult> =
  ({ url }) =>
  dispatch =>
    dispatch(apiAction(createChatThreadActionBundle)(createChatThreadApi(url)))

const fetchChatMessagesActionBundle: ActionBundle<
  FetchChatMessagesStart,
  FetchChatMessagesSuccess,
  FetchChatMessagesFailure,
  FetchChatMessagesBody
> = [
  action(FETCH_CHAT_MESSAGES_START),
  payloadAction(FETCH_CHAT_MESSAGES_SUCCESS),
  failureAction(FETCH_CHAT_MESSAGES_FAILURE),
]

const reloadGlobalHasUnreadMessagesFailure: FailureActionFactory<ReloadGlobalHasUnreadMessagesFailure> = failureAction(
  RELOAD_GLOBAL_HAS_UNREAD_MESSAGES_FAILURE,
)
const reloadGlobalHasUnreadMessagesActionBundle: ActionBundle<
  ReloadGlobalHasUnreadMessagesStart,
  ReloadGlobalHasUnreadMessagesSuccess,
  ReloadGlobalHasUnreadMessagesFailure,
  boolean
> = [
  action(RELOAD_GLOBAL_HAS_UNREAD_MESSAGES_START),
  payloadAction(RELOAD_GLOBAL_HAS_UNREAD_MESSAGES_SUCCESS),
  reloadGlobalHasUnreadMessagesFailure,
]

export const reloadGlobalHasUnreadMessages: StatelessOptionalPayloadThunk<
  string,
  ReloadGlobalHasUnreadMessagesResult
> = url => dispatch =>
  url
    ? dispatch(apiAction(reloadGlobalHasUnreadMessagesActionBundle)(reloadGlobalHasUnreadMessagesApi(url)))
    : Promise.resolve(
        dispatch(
          reloadGlobalHasUnreadMessagesFailure(new Error('`reloadGlobalHasUnreadMessagesUrl` not present in meta')),
        ),
      )

const reloadHasUnreadMessagesFailure: FailureActionFactory<ReloadHasUnreadMessagesFailure> = failureAction(
  RELOAD_HAS_UNREAD_MESSAGES_FAILURE,
)
const reloadHasUnreadMessagesActionBundle: ActionBundle<
  ReloadHasUnreadMessagesStart,
  ReloadHasUnreadMessagesSuccess,
  ReloadHasUnreadMessagesFailure,
  boolean
> = [
  action(RELOAD_HAS_UNREAD_MESSAGES_START),
  payloadAction(RELOAD_HAS_UNREAD_MESSAGES_SUCCESS),
  reloadHasUnreadMessagesFailure,
]

export const reloadHasUnreadMessages: StatelessOptionalPayloadThunk<string, ReloadHasUnreadMessagesResult> =
  url => dispatch =>
    url
      ? dispatch(apiAction(reloadHasUnreadMessagesActionBundle)(reloadHasUnreadMessagesApi(url)))
      : Promise.resolve(
          dispatch(reloadHasUnreadMessagesFailure(new Error('`reloadHasUnreadMessagesUrl` not present in meta'))),
        )

const reloadActivityHasUnreadMessagesFailure: FailureActionFactory<ReloadActivityHasUnreadMessagesFailure> =
  failureAction(RELOAD_ACTIVITY_HAS_UNREAD_MESSAGES_FAILURE)

const reloadActivityHasUnreadMessagesActionBundle: ActionBundle<
  ReloadActivityHasUnreadMessagesStart,
  ReloadActivityHasUnreadMessagesSuccess,
  ReloadActivityHasUnreadMessagesFailure,
  ReloadActivityHasUnreadMessagesSuccessPayload
> = [
  action(RELOAD_ACTIVITY_HAS_UNREAD_MESSAGES_START),
  payloadAction(RELOAD_ACTIVITY_HAS_UNREAD_MESSAGES_SUCCESS),
  reloadActivityHasUnreadMessagesFailure,
]

const reloadActivityHasUnreadMessages: StatelessPayloadThunk<
  ReloadActivityHasUnreadMessagesPayload,
  ReloadActivityHasUnreadMessagesResult
> =
  ({ advertId, activities }) =>
  dispatch => {
    const activity = find<ActivityItem>({ advertId })(activities)

    if (!activity)
      return Promise.resolve(
        dispatch(reloadActivityHasUnreadMessagesFailure(new NoActivityToReloadHasUnreadMessagesError(advertId))),
      )

    const {
      actions: { reloadHasUnreadMessages },
    } = activity

    if (!reloadHasUnreadMessages)
      return Promise.resolve(
        dispatch(reloadActivityHasUnreadMessagesFailure(new NoReloadHasUnreadMessagesActionError(advertId))),
      )

    const { url } = reloadHasUnreadMessages

    return dispatch(
      apiAction(reloadActivityHasUnreadMessagesActionBundle)(reloadActivityHasUnreadMessagesApi({ advertId, url })),
    )
  }

const reloadFavouritesHasUnreadMessagesFailure: FailureActionFactory<ReloadFavouritesHasUnreadMessagesFailure> =
  failureAction(RELOAD_FAVOURITES_HAS_UNREAD_MESSAGES_FAILURE)

const reloadFavouritesHasUnreadMessagesActionBundle: ActionBundle<
  ReloadFavouritesHasUnreadMessagesStart,
  ReloadFavouritesHasUnreadMessagesSuccess,
  ReloadFavouritesHasUnreadMessagesFailure,
  ReloadFavouritesHasUnreadMessagesSuccessPayload
> = [
  action(RELOAD_FAVOURITES_HAS_UNREAD_MESSAGES_START),
  payloadAction(RELOAD_FAVOURITES_HAS_UNREAD_MESSAGES_SUCCESS),
  reloadFavouritesHasUnreadMessagesFailure,
]

export const reloadFavouritesHasUnreadMessages: StatelessPayloadThunk<
  ReloadFavouritesHasUnreadMessagesPayload,
  ReloadFavouritesHasUnreadMessagesResult
> = payload => dispatch =>
  dispatch(apiAction(reloadFavouritesHasUnreadMessagesActionBundle)(reloadFavouritesHasUnreadMessagesApi(payload)))

export const fetchChatMessages: StatelessPayloadThunk<FetchChatMessagesPayload, FetchChatMessagesResult> =
  ({ activities, advertId, reloadHasUnreadMessagesUrl, url, userActions }) =>
  async dispatch => {
    const action = await dispatch(apiAction(fetchChatMessagesActionBundle)(fetchChatMessagesApi(url)))

    if (action.type === FETCH_CHAT_MESSAGES_SUCCESS) {
      if (advertId) {
        dispatch(setAdvertNeedsFetch(advertId))
        await dispatch(reloadActivityHasUnreadMessages({ advertId, activities }))
      }

      await Promise.all(
        map(flow(reloadFavouritesHasUnreadMessages, dispatch))(getReloadFavouriteMessagesData(userActions)),
      )

      await dispatch(reloadHasUnreadMessages(reloadHasUnreadMessagesUrl))
    }

    return action
  }

const fetchChatMessagesUpdatesActionBundle: ActionBundle<
  FetchChatMessagesUpdatesStart,
  FetchChatMessagesUpdatesSuccess,
  FetchChatMessagesUpdatesFailure,
  FetchChatMessagesUpdatesBody
> = [
  action(FETCH_CHAT_MESSAGES_UPDATES_START),
  payloadAction(FETCH_CHAT_MESSAGES_UPDATES_SUCCESS),
  failureAction(FETCH_CHAT_MESSAGES_UPDATES_FAILURE),
]

export const fetchChatMessagesUpdates: StatelessPayloadThunk<
  FetchChatMessagesUpdatesPayload,
  FetchChatMessagesUpdatesResult
> =
  ({ url, version }) =>
  dispatch =>
    dispatch(apiAction(fetchChatMessagesUpdatesActionBundle)(fetchChatMessagesUpdatesApi(url, version)))

const sendChatMessageStart: PayloadActionFactory<SendChatMessageStart, Optional<string>> = payloadAction(
  SEND_CHAT_MESSAGE_START,
)

const createSendChatMessageActionBundle: TransformOptional<
  string,
  ActionBundle<SendChatMessageStart, SendChatMessageSuccess, SendChatMessageFailure, SentChatMessageBody>
> = message => [
  () => sendChatMessageStart(message),
  payloadAction(SEND_CHAT_MESSAGE_SUCCESS),
  failureAction(SEND_CHAT_MESSAGE_FAILURE),
]

const sendChatMessageBundleSuccess: ActionFactory<SendChatMessageBundleSuccess> = action(
  SEND_CHAT_MESSAGE_BUNDLE_SUCCESS,
)
const sendChatMessageBundleFailure: FailureActionFactory<SendChatMessageBundleFailure> = failureAction(
  SEND_CHAT_MESSAGE_BUNDLE_FAILURE,
)

export const sendChatMessage: StatelessPayloadThunk<SubmitChatMessagePayload, SendChatMessageResult> =
  ({ message, submitMessageUrl: url }) =>
  dispatch =>
    dispatch(apiAction(createSendChatMessageActionBundle(message))(sendChatMessageApi(url, message)))

const sendChatMessageWithAssets: Transform<string, StatelessPayloadThunk<BodyItem[], SendChatMessageResult>> =
  url => bodyItems => dispatch => {
    const messageBodyItem = find<BodyItem>({ name: 'message' })(bodyItems)
    const message = messageBodyItem && (JSON.parse(messageBodyItem.value) as MessageJson).message

    return dispatch(apiAction(createSendChatMessageActionBundle(message))(sendChatMessageWithAssetsApi(url)(bodyItems)))
  }

export const sendChatMessageBundle: StatelessPayloadThunk<SendChatMessageBundlePayload, SendChatMessageBundleResult> =
  ({ assets, message, imagesToBodyItems, videoToBodyItem, submitImageMessageUrl, submitVideoMessageUrl }) =>
  async dispatch => {
    const { image: images, video: videos } = groupBy<Asset>(({ mimeType }) => mimeToAssetType(mimeType))(assets)
    const messageJson: Optional<MessageJson> = isPresent(message) ? { message } : undefined
    const messageBodyItem = messageJson && buildBodyItem('message', messageJson)
    const imageRequests: BodyItem[][] = await flow(chunk(5), map(imagesToBodyItems), promiseAll)(images)
    const videoBodyItems: BodyItem[] = flow(
      flow(
        map<Asset, Optional<FileItem>>(asset => {
          const mimeType = assetToMimeType(asset)

          return isDefined(mimeType) ? { ...asset, index: 0, mimeType } : undefined
        }),
        compact,
      ),
      map(videoToBodyItem),
    )(videos)
    const videoRequests = map<BodyItem, BodyItem[]>(bodyItem => [bodyItem])(videoBodyItems)

    if (messageBodyItem) {
      const lastVideoRequest = last(videoRequests)
      let request: BodyItem[]

      if (lastVideoRequest) {
        request = lastVideoRequest
      } else {
        const lastImageRequests = last(imageRequests)

        if (lastImageRequests) {
          request = lastImageRequests
        } else {
          request = []
          imageRequests.push(request)
        }
      }

      request.push(messageBodyItem)
    }

    return Promise.all(
      map(dispatch)([
        ...map(sendChatMessageWithAssets(submitImageMessageUrl))(imageRequests),
        ...map(sendChatMessageWithAssets(submitVideoMessageUrl))(videoRequests),
      ]),
    )
      .then(sendChatMessageBundleSuccess)
      .catch(sendChatMessageBundleFailure)
      .then<SendChatMessageBundleResult>(dispatch)
  }

export const pushContactMessage: PayloadActionFactory<PushContactMessage, string> = payloadAction(PUSH_CONTACT_MESSAGE)

const startConferenceCallActionBundle: ActionBundle<
  StartConferenceCallStart,
  StartConferenceCallSuccess,
  StartConferenceCallFailure
> = [
  action(START_CONFERENCE_CALL_START),
  action(START_CONFERENCE_CALL_SUCCESS),
  failureAction(START_CONFERENCE_CALL_FAILURE),
]

export const startConferenceCall: StatelessPayloadThunk<string, StartConferenceCallResult> = url => dispatch =>
  dispatch(apiAction(startConferenceCallActionBundle)(startConferenceCallApi(url)))

const viewPhoneActionBundle: ActionBundle<ViewPhoneStart, ViewPhoneSuccess, ViewPhoneFailure, string> = [
  action(VIEW_PHONE_START),
  payloadAction(VIEW_PHONE_SUCCESS),
  failureAction(VIEW_PHONE_FAILURE),
]

export const closeChat: ActionFactory<CloseChat> = action(CLOSE_CHAT)

export const viewPhone: StatelessPayloadThunk<string, ViewPhoneResult> = url => dispatch =>
  dispatch(apiAction(viewPhoneActionBundle)(viewPhoneApi(url)))

export const addPhoneRequested: PayloadActionFactory<AddPhoneRequested, AddPhoneRequested['payload']> =
  payloadAction(ADD_PHONE_REQUESTED)

export const showConferenceCallModal: ActionFactory<ShowConferenceCallModal> = action(SHOW_CONFERENCE_CALL_MODAL)
export const setConferenceCallPayload: PayloadActionFactory<SetConferenceCallPayload, StartConferenceCallPayload> =
  payloadAction(SET_CONFERENCE_CALL_PAYLOAD)
export const closeConferenceCallModal: ActionFactory<CloseConferenceCallModal> = action(CLOSE_CONFERENCE_CALL_MODAL)
