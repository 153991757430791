import { Action } from 'redux'

import { FailureAction, PayloadAction } from '../../types/core'
import { FetchActivityItemsResponse, FetchSupplierOffersResponse } from '../activities/types'

export const FETCH_JUNK_LOVER_ADVERTS_START = 'FETCH_JUNK_LOVER_ADVERTS_START'
export const FETCH_JUNK_LOVER_ADVERTS_SUCCESS = 'FETCH_JUNK_LOVER_ADVERTS_SUCCESS'
export const FETCH_JUNK_LOVER_ADVERTS_FAILURE = 'FETCH_JUNK_LOVER_ADVERTS_FAILURE'

export const FETCH_JUNK_LOVER_HISTORY_ADVERTS_START = 'FETCH_JUNK_LOVER_HISTORY_ADVERTS_START'
export const FETCH_JUNK_LOVER_HISTORY_ADVERTS_SUCCESS = 'FETCH_JUNK_LOVER_HISTORY_ADVERTS_SUCCESS'
export const FETCH_JUNK_LOVER_HISTORY_ADVERTS_FAILURE = 'FETCH_JUNK_LOVER_HISTORY_ADVERTS_FAILURE'

export const FETCH_JUNK_LOVER_ADVERTS_NEXT_PAGE_START = 'FETCH_JUNK_LOVER_ADVERTS_NEXT_PAGE_START'
export const FETCH_JUNK_LOVER_ADVERTS_NEXT_PAGE_SUCCESS = 'FETCH_JUNK_LOVER_ADVERTS_NEXT_PAGE_SUCCESS'
export const FETCH_JUNK_LOVER_ADVERTS_NEXT_PAGE_FAILURE = 'FETCH_JUNK_LOVER_ADVERTS_NEXT_PAGE_FAILURE'

export const FETCH_JUNK_LOVER_HISTORY_ADVERTS_NEXT_PAGE_START = 'FETCH_JUNK_LOVER_HISTORY_ADVERTS_NEXT_PAGE_START'
export const FETCH_JUNK_LOVER_HISTORY_ADVERTS_NEXT_PAGE_SUCCESS = 'FETCH_JUNK_LOVER_HISTORY_ADVERTS_NEXT_PAGE_SUCCESS'
export const FETCH_JUNK_LOVER_HISTORY_ADVERTS_NEXT_PAGE_FAILURE = 'FETCH_JUNK_LOVER_HISTORY_ADVERTS_NEXT_PAGE_FAILURE'

export type FetchJunkLoverAdvertsStart = Action<typeof FETCH_JUNK_LOVER_ADVERTS_START>
export type FetchJunkLoverAdvertsSuccess = PayloadAction<
  typeof FETCH_JUNK_LOVER_ADVERTS_SUCCESS,
  FetchActivityItemsResponse
>
export type FetchJunkLoverAdvertsFailure = FailureAction<typeof FETCH_JUNK_LOVER_ADVERTS_FAILURE>
export type FetchJunkLoverAdvertsResult = FetchJunkLoverAdvertsSuccess | FetchJunkLoverAdvertsFailure

export type FetchJunkLoverAdverts = FetchJunkLoverAdvertsStart | FetchJunkLoverAdvertsResult

export type FetchJunkLoverHistoryAdvertsStart = Action<typeof FETCH_JUNK_LOVER_HISTORY_ADVERTS_START>
export type FetchJunkLoverHistoryAdvertsSuccess = PayloadAction<
  typeof FETCH_JUNK_LOVER_HISTORY_ADVERTS_SUCCESS,
  FetchActivityItemsResponse
>
export type FetchJunkLoverHistoryAdvertsFailure = FailureAction<typeof FETCH_JUNK_LOVER_HISTORY_ADVERTS_FAILURE>

export type FetchJunkLoverHistoryAdvertsResult =
  | FetchJunkLoverHistoryAdvertsSuccess
  | FetchJunkLoverHistoryAdvertsFailure
export type FetchJunkLoverHistoryAdverts = FetchJunkLoverHistoryAdvertsStart | FetchJunkLoverHistoryAdvertsResult

export type FetchJunkLoverAdvertsNextPageStart = Action<typeof FETCH_JUNK_LOVER_ADVERTS_NEXT_PAGE_START>
export type FetchJunkLoverAdvertsNextPageSuccess = PayloadAction<
  typeof FETCH_JUNK_LOVER_ADVERTS_NEXT_PAGE_SUCCESS,
  FetchActivityItemsResponse
>
export type FetchJunkLoverAdvertsNextPageFailure = FailureAction<typeof FETCH_JUNK_LOVER_ADVERTS_NEXT_PAGE_FAILURE>

export type FetchJunkLoverAdvertsNextPageResult =
  | FetchJunkLoverAdvertsNextPageSuccess
  | FetchJunkLoverAdvertsNextPageFailure
export type FetchJunkLoverAdvertsNextPage = FetchJunkLoverAdvertsNextPageStart | FetchJunkLoverAdvertsNextPageResult

export type FetchJunkLoverHistoryAdvertsNextPageStart = Action<typeof FETCH_JUNK_LOVER_HISTORY_ADVERTS_NEXT_PAGE_START>
export type FetchJunkLoverHistoryAdvertsNextPageSuccess = PayloadAction<
  typeof FETCH_JUNK_LOVER_HISTORY_ADVERTS_NEXT_PAGE_SUCCESS,
  FetchActivityItemsResponse
>
export type FetchJunkLoverHistoryAdvertsNextPageFailure = FailureAction<
  typeof FETCH_JUNK_LOVER_HISTORY_ADVERTS_NEXT_PAGE_FAILURE
>

export type FetchJunkLoverHistoryAdvertsNextPageResult =
  | FetchJunkLoverHistoryAdvertsNextPageSuccess
  | FetchJunkLoverHistoryAdvertsNextPageFailure
export type FetchJunkLoverHistoryAdvertsNextPage =
  | FetchJunkLoverHistoryAdvertsNextPageStart
  | FetchJunkLoverHistoryAdvertsNextPageResult

export const FETCH_JUNK_REUSER_OFFERS_START = 'FETCH_JUNK_REUSER_OFFERS_START'
export const FETCH_JUNK_REUSER_OFFERS_SUCCESS = 'FETCH_JUNK_REUSER_OFFERS_SUCCESS'
export const FETCH_JUNK_REUSER_OFFERS_FAILURE = 'FETCH_JUNK_REUSER_OFFERS_FAILURE'

export type FetchJunkReuserOffersStart = Action<typeof FETCH_JUNK_REUSER_OFFERS_START>
export type FetchJunkReuserOffersSuccess = PayloadAction<
  typeof FETCH_JUNK_REUSER_OFFERS_SUCCESS,
  FetchSupplierOffersResponse
>
export type FetchJunkReuserOffersFailure = FailureAction<typeof FETCH_JUNK_REUSER_OFFERS_FAILURE>
export type FetchJunkReuserOffersResult = FetchJunkReuserOffersSuccess | FetchJunkReuserOffersFailure
export type FetchJunkReuserOffers = FetchJunkReuserOffersStart | FetchJunkReuserOffersResult

export const FETCH_JUNK_REUSER_OFFERS_NEXT_PAGE_START = 'FETCH_JUNK_REUSER_OFFERS_NEXT_PAGE_START'
export const FETCH_JUNK_REUSER_OFFERS_NEXT_PAGE_SUCCESS = 'FETCH_JUNK_REUSER_OFFERS_NEXT_PAGE_SUCCESS'
export const FETCH_JUNK_REUSER_OFFERS_NEXT_PAGE_FAILURE = 'FETCH_JUNK_REUSER_OFFERS_NEXT_PAGE_FAILURE'

export type FetchJunkReuserOffersNextPageStart = Action<typeof FETCH_JUNK_REUSER_OFFERS_NEXT_PAGE_START>
export type FetchJunkReuserOffersNextPageSuccess = PayloadAction<
  typeof FETCH_JUNK_REUSER_OFFERS_NEXT_PAGE_SUCCESS,
  FetchSupplierOffersResponse
>
export type FetchJunkReuserOffersNextPageFailure = FailureAction<typeof FETCH_JUNK_REUSER_OFFERS_NEXT_PAGE_FAILURE>
export type FetchJunkReuserOffersNextPageResult =
  | FetchJunkReuserOffersNextPageSuccess
  | FetchJunkReuserOffersNextPageFailure
export type FetchJunkReuserOffersNextPage = FetchJunkReuserOffersNextPageStart | FetchJunkReuserOffersNextPageResult

export type JunkReuserAdvertsAction =
  | FetchJunkLoverAdverts
  | FetchJunkLoverAdvertsNextPage
  | FetchJunkLoverHistoryAdverts
  | FetchJunkLoverHistoryAdvertsNextPage
  | FetchJunkReuserOffers
  | FetchJunkReuserOffersNextPage
