import { constant, flow } from 'lodash/fp'

import { Get } from '../../types/core'
import Reducer from '../../types/reducer'
import { LOGOUT_SUCCESS } from '../login/types'
import {
  advertsCurrentPageLens,
  advertsLens,
  builderWasteFilterLens,
  isOutsideSupportedAreaLens,
  loadingLens,
  matchedFilterLens,
  mergeAdvertLens,
  metaLens,
  needsFetchLens,
  pageSizeLens,
  residentialFilterLens,
  reusableFilterLens,
  tagFilterLens,
} from './lens'
import State, { advertsDefaultState } from './state'
import {
  CLEAR_ADVERTS,
  FETCH_ADVERTS_FAILURE,
  FETCH_ADVERTS_NEXT_PAGE_FAILURE,
  FETCH_ADVERTS_NEXT_PAGE_START,
  FETCH_ADVERTS_NEXT_PAGE_SUCCESS,
  FETCH_ADVERTS_START,
  FETCH_ADVERTS_SUCCESS,
  SET_ADVERTS_CURRENT_PAGE,
  SET_ADVERTS_PAGE_SIZE,
  SET_BUILDER_WASTE_FILTER,
  SET_MATCHED_FILTER,
  SET_NEEDS_FETCH,
  SET_RESIDENTIAL_FILTER,
  SET_REUSABLE_FILTER,
  SET_TAG_FILTER,
} from './types'

export const createResetReusableFilter =
  (condition: Get<boolean>) =>
  <S extends State>(state: S) =>
    condition() ? state : reusableFilterLens(state).set(null)

const resetReusableFilter = createResetReusableFilter(constant(true))

const reducer: Reducer<State> = (state = advertsDefaultState, action) => {
  switch (action.type) {
    case FETCH_ADVERTS_SUCCESS:
      return flow(
        mergeAdvertLens,
        l => l(action.payload.body.entities),
        metaLens,
        l => l.set(action.payload.meta),
        loadingLens,
        l => l.set(false),
        isOutsideSupportedAreaLens,
        l => l.set(action.payload.body.outsideSupportedArea),
      )(state)
    case FETCH_ADVERTS_NEXT_PAGE_SUCCESS:
      return flow(
        mergeAdvertLens,
        l => l(action.payload.body.entities),
        metaLens,
        l => l.set(action.payload.meta),
        loadingLens,
        l => l.set(false),
      )(state)
    case CLEAR_ADVERTS:
      return advertsLens(state).set([])
    case SET_BUILDER_WASTE_FILTER:
      return flow(
        builderWasteFilterLens,
        l => l.set(action.payload),
        resetReusableFilter,
        residentialFilterLens,
        l => l.set(null),
        tagFilterLens,
        l => l.set(null),
      )(state)
    case SET_MATCHED_FILTER:
      return matchedFilterLens(state).set(action.payload)
    case SET_RESIDENTIAL_FILTER:
      return flow(
        builderWasteFilterLens,
        l => l.set(null),
        resetReusableFilter,
        residentialFilterLens,
        l => l.set(action.payload),
        tagFilterLens,
        l => l.set(null),
      )(state)
    case SET_REUSABLE_FILTER:
      return flow(
        builderWasteFilterLens,
        l => l.set(null),
        reusableFilterLens,
        l => l.set(action.payload),
        residentialFilterLens,
        l => l.set(null),
        tagFilterLens,
        l => l.set(null),
      )(state)
    case FETCH_ADVERTS_START:
    case FETCH_ADVERTS_NEXT_PAGE_START:
      return loadingLens(state).set(true)
    case FETCH_ADVERTS_FAILURE:
    case FETCH_ADVERTS_NEXT_PAGE_FAILURE:
      return loadingLens(state).set(false)
    case SET_ADVERTS_CURRENT_PAGE:
      return advertsCurrentPageLens(state).set(action.payload)
    case SET_ADVERTS_PAGE_SIZE:
      return pageSizeLens(state).set(action.payload)
    case SET_TAG_FILTER:
      return flow(
        builderWasteFilterLens,
        l => l.set(null),
        resetReusableFilter,
        residentialFilterLens,
        l => l.set(null),
        tagFilterLens,
        l => l.set(action.payload),
      )(state)
    case LOGOUT_SUCCESS:
      return needsFetchLens(state).set(true)
    case SET_NEEDS_FETCH:
      return needsFetchLens(state).set(action.payload)
    default:
      return state
  }
}

export default reducer
