import account from './entities/account/reducer'
import advert from './entities/advert/reducer'
import adverts from './entities/adverts/reducer'
import app from './entities/app/reducer'
import chat from './entities/chat/reducer'
import customerAdvert from './entities/customer-advert/reducer'
import customerAdverts from './entities/customer-adverts/reducer'
import emailRequest from './entities/email-request/reducer'
import favourites from './entities/favourites/reducer'
import freegle from './entities/freegle/reducer'
import junkReuserAdverts from './entities/junk-reuser-adverts/reducer'
import login from './entities/login/reducer'
import meta from './entities/meta/reducer'
import newAdvert from './entities/new-advert/reducer'
import oauth from './entities/oauth/reducer'
import otp from './entities/otp/reducer'
import payment from './entities/payment/reducer'
import referrals from './entities/referrals/reducer'
import signup from './entities/signup/reducer'
import signupCustomer from './entities/signup-customer/reducer'
import signupJunkReuser from './entities/signup-junk-reuser/reducer'
import supportRequest from './entities/support-request/reducer'
import tags from './entities/tags/reducer'
import timeSlots from './entities/time-slots/reducer'

export default {
  account,
  advert,
  adverts,
  app,
  chat,
  customerAdvert,
  customerAdverts,
  emailRequest,
  favourites,
  freegle,
  junkReuserAdverts,
  login,
  meta,
  newAdvert,
  oauth,
  otp,
  payment,
  referrals,
  signup,
  signupCustomer,
  signupJunkReuser,
  supportRequest,
  tags,
  timeSlots,
}
