import { styled } from 'styled'

import BaseText from './text/BaseText'

const FormFieldError = styled(BaseText)`
  color: ${({ theme: { colors } }) => colors.error};
  font-family: Lato-Bold;
  font-size: 0.75em;
  text-align: right;
`

export default FormFieldError
