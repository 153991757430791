import { DEFAULT_CURRENCY } from '../constants'
import { isDefined } from '../helpers'
import { Currency, OfferPrice, OptionalTransform, Price, Transform, VatPrice } from '../types/core'

const assertSameCurrency = (currency1: Currency, currency2: Currency) => {
  if (currency1 !== currency2) throw new Error('price currencies are different')
}

export const addPrice = (
  { amount: amount1, currency: currency1 }: Price,
  { amount: amount2, currency: currency2 }: Price,
): Price => {
  assertSameCurrency(currency1, currency2)

  return { amount: amount1 + amount2, currency: currency1 }
}

export const arePricesEqual = (
  { amount: amount1, currency: currency1 }: Price,
  { amount: amount2, currency: currency2 }: Price,
): boolean => {
  assertSameCurrency(currency1, currency2)

  return amount1 === amount2
}

export const commissionPrice =
  (commissionPercentage: number) =>
  ({ amount, currency }: Price) => ({
    amount: amount * (1 - commissionPercentage / 100),
    currency,
  })

export const negatePrice = ({ amount, currency }: Price) => ({ amount: -amount, currency })

export const subtractPrice = (price1: Price, price2: Price) => addPrice(price1, negatePrice(price2))

export const isZeroPrice = ({ amount }: Price) => amount === 0

export const increasePriceBy: Transform<number, Transform<number, Price>> = by => priceValue =>
  priceFromAmount(priceValue + by)()

export const priceFromAmount: Transform<number, OptionalTransform<Currency, Price>> =
  amount =>
  (currency = DEFAULT_CURRENCY) => ({ amount, currency })

export const vatPriceToPrice: Transform<VatPrice, Price> = ({ amount, currency }) => ({ amount, currency })
export const vatPriceToExVatPrice: Transform<VatPrice, Price> = ({ amountExVat: amount, currency }) => ({
  amount,
  currency,
})

export const isVatPrice = (price: OfferPrice): price is VatPrice => isDefined((price as VatPrice).hasVat)
