import { AssetPath, Color, Dispatch } from '@lovejunk/core'
import { flow, isEqual, property, reject } from 'lodash/fp'
import React, { ComponentType, FC, useCallback } from 'react'
import { styled } from 'styled'

import CenteredRow from '../containers/CenteredRow'
import Column from '../containers/Column'
import UploadInput, { Props as UploadInputProps } from '../input/UploadInput'
import Label from '../Label'
import Assets from './Assets'

export interface IconProps {
  disabled?: boolean
  disabledColor?: Color
}

interface Props extends UploadInputProps {
  Icon: ComponentType<IconProps>

  className?: string
  disabledColor?: Color
  hideAssets?: boolean
  title?: string
}

const AssetsRow: FC<Props> = ({ className, disabledColor, hideAssets = false, Icon, title, ...uploadInputProps }) => {
  const { assets, disabled, limit, onSelect } = uploadInputProps
  const onRemove = useCallback<Dispatch<AssetPath>>(
    assetPath => flow(reject(flow(property('path'), isEqual(assetPath))), onSelect)(assets),
    [assets, onSelect],
  )

  return (
    <Root className={className}>
      {title && <Label title={title} />}
      <Row>
        <UploadInput {...uploadInputProps}>
          <Icon disabled={disabled} disabledColor={disabledColor} />
        </UploadInput>
        {!hideAssets && <Assets limit={limit} items={assets} onRemove={onRemove} size={4.25} />}
      </Row>
    </Root>
  )
}

const Root = Column
const Row = styled(CenteredRow)`
  flex: 1;
  gap: 0.5em;
`

export default AssetsRow
