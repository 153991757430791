import { favouriteItemFactory as factory } from '@lovejunk/core'
import ReactIcon from 'components/ReactIcon'
import React from 'react'
import { BsTruck } from 'react-icons/bs'
import { MdStar } from 'react-icons/md'
import { styled } from 'styled'

import ChatIcon from './ChatIcon'
import CenteredRow from './containers/CenteredRow'
import Column from './containers/Column'
import Avatar from './SupplierInfo/UserCard/Avatar'
import Info from './SupplierInfo/UserCard/Info'
import Text from './text/Text'

const CollectionsIcon = styled(ReactIcon).attrs({ Icon: BsTruck, size: 1.25 })``
const RatingIcon = styled(ReactIcon).attrs({ Icon: MdStar, size: 1.25 })``

export default factory({
  Avatar: styled(Avatar).attrs({ shadow: false })``,
  ChatIcon,
  CollectionsIcon: ({ color }) => <CollectionsIcon color={color} />,
  Content: styled(Column)`
    margin-left: 0.5em;
    flex: 1;
  `,
  Info,
  InfoContainer: styled(CenteredRow)`
    justify-content: flex-start;
    padding: 0.25em 0;
    gap: 0.5em;
  `,
  Name: styled(Text).attrs({ size: 1.25 })`
    flex-wrap: wrap;
  `,
  Postcode: Text,
  RatingIcon: ({ color }) => <RatingIcon color={color} />,
  Reference: styled(Text).attrs({ color: 'regularText', size: 0.75 })``,
  Root: styled(CenteredRow)`
    padding: 0.5em 0;
  `,
})
