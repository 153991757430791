import { Nullable, PaginationMeta } from '../../types/core'
import { AdvertItem, Filter, Pagination } from './types'

export interface AdvertsState {
  filter: Filter
  isOutsideSupportedArea: Nullable<boolean>
  items: AdvertItem[]
  loading: boolean
  meta: Nullable<PaginationMeta>
  pagination: Pagination
  needsFetch: boolean
}

export const initialPagination: Pagination = {
  currentPage: 0,
}

export const initialFilter: Filter = {
  builderWaste: null,
  collected: null,
  currentTag: null,
  disposed: null,
  distance: null,
  location: null,
  locationCentre: null,
  includePageCount: null,
  matched: true,
  orderBy: null,
  pageSize: 25,
  residential: null,
  reusable: null,
  showAll: null,
  withoutCollectorJobs: null,
}

export const advertsDefaultState: AdvertsState = {
  filter: initialFilter,
  isOutsideSupportedArea: null,
  items: [],
  loading: false,
  meta: null,
  needsFetch: false,
  pagination: initialPagination,
}

export default AdvertsState
