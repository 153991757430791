import { Action } from 'redux'

import { BaseError } from '../../errors'
import {
  Distance,
  FailureAction,
  LatLng,
  Location,
  Nullable,
  PaginationMeta,
  PayloadAction,
  Price,
  Response,
  TagId,
} from '../../types/core'
import { AddressResource, AdvertId, CollectionPoint, Details, Status, SupplierType } from '../advert/types'
import { OpenTimeSlot, TimeSlotFixedName } from '../time-slots/types'

export const CHECK_GALLERY_VERSION_START = 'CHECK_GALLERY_VERSION_START'
export const CHECK_GALLERY_VERSION_SUCCESS = 'CHECK_GALLERY_VERSION_SUCCESS'
export const CHECK_GALLERY_VERSION_FAILURE = 'CHECK_GALLERY_VERSION_FAILURE'

export const FETCH_ADVERT_ITEM_START = 'FETCH_ADVERT_ITEM_START'
export const FETCH_ADVERT_ITEM_SUCCESS = 'FETCH_ADVERT_ITEM_SUCCESS'
export const FETCH_ADVERT_ITEM_FAILURE = 'FETCH_ADVERT_ITEM_FAILURE'

export const FETCH_ADVERTS_START = 'FETCH_ADVERTS_START'
export const FETCH_ADVERTS_SUCCESS = 'FETCH_ADVERTS_SUCCESS'
export const FETCH_ADVERTS_FAILURE = 'FETCH_ADVERTS_FAILURE'
export const FETCH_ADVERTS_NEXT_PAGE_START = 'FETCH_ADVERTS_NEXT_PAGE_START'
export const FETCH_ADVERTS_NEXT_PAGE_SUCCESS = 'FETCH_ADVERTS_NEXT_PAGE_SUCCESS'
export const FETCH_ADVERTS_NEXT_PAGE_FAILURE = 'FETCH_ADVERTS_NEXT_PAGE_FAILURE'
export const FETCH_ADVERTS_NEXT_PAGE_CHECK_FAILURE = 'FETCH_ADVERTS_NEXT_PAGE_CHECK_FAILURE'
export const SET_ADVERTS_PAGE_SIZE = 'SET_ADVERTS_PAGE_SIZE'
export const SET_ADVERTS_CURRENT_PAGE = 'SET_ADVERTS_CURRENT_PAGE'
export const SET_BUILDER_WASTE_FILTER = 'SET_BUILDER_WASTE_FILTER'
export const SET_COLLECTED_FILTER = 'SET_COLLECTED_FILTER'
export const SET_MATCHED_FILTER = 'SET_MATCHED_FILTER'
export const SET_RESIDENTIAL_FILTER = 'SET_RESIDENTIAL_FILTER'
export const SET_REUSABLE_FILTER = 'SET_REUSABLE_FILTER'
export const SET_TAG_FILTER = 'SET_TAG_FILTER'
export const CLEAR_ADVERTS = 'CLEAR_ADVERTS'
export const SET_NEEDS_FETCH = 'SET_NEEDS_FETCH'

export type CheckGalleryVersionStart = Action<typeof CHECK_GALLERY_VERSION_START>
export type CheckGalleryVersionSuccess = PayloadAction<typeof CHECK_GALLERY_VERSION_SUCCESS, GalleryVersion>
export type CheckGalleryVersionFailure = FailureAction<typeof CHECK_GALLERY_VERSION_FAILURE>
export type CheckGalleryVersionResult = CheckGalleryVersionSuccess | CheckGalleryVersionFailure
export type CheckGalleryVersion = CheckGalleryVersionStart | CheckGalleryVersionResult

export type FetchAdvertItemStart = Action<typeof FETCH_ADVERT_ITEM_START>
export type FetchAdvertItemSuccess = PayloadAction<typeof FETCH_ADVERT_ITEM_SUCCESS, AdvertItemResource>
export type FetchAdvertItemFailure = FailureAction<typeof FETCH_ADVERT_ITEM_FAILURE>
export type FetchAdvertItemResult = FetchAdvertItemSuccess | FetchAdvertItemFailure
export type FetchAdvertItem = FetchAdvertItemStart | FetchAdvertItemResult

export type FetchAdvertsStart = Action<typeof FETCH_ADVERTS_START>
export type FetchAdvertsSuccess = PayloadAction<typeof FETCH_ADVERTS_SUCCESS, FetchAdvertsResponse>
export type FetchAdvertsFailure = FailureAction<typeof FETCH_ADVERTS_FAILURE>
export type FetchAdvertsResult = FetchAdvertsSuccess | FetchAdvertsFailure

export type FetchAdvertsNextPageStart = Action<typeof FETCH_ADVERTS_NEXT_PAGE_START>
export type FetchAdvertsNextPageSuccess = PayloadAction<typeof FETCH_ADVERTS_NEXT_PAGE_SUCCESS, FetchAdvertsResponse>
export type FetchAdvertsNextPageFailure = FailureAction<typeof FETCH_ADVERTS_NEXT_PAGE_FAILURE>
export type FetchAdvertsNextPageCheckFailure = FailureAction<typeof FETCH_ADVERTS_NEXT_PAGE_CHECK_FAILURE>
export type FetchAdvertsNextPageResult = FetchAdvertsNextPageSuccess | FetchAdvertsNextPageFailure

export type FetchAdverts = FetchAdvertsStart | FetchAdvertsResult
export type FetchAdvertsNextPage = FetchAdvertsNextPageStart | FetchAdvertsNextPageResult
export type SetAdvertsPageSize = PayloadAction<typeof SET_ADVERTS_PAGE_SIZE, number>
export type SetAdvertsCurrentPage = PayloadAction<typeof SET_ADVERTS_CURRENT_PAGE, number>
export type SetBuilderWasteFilter = PayloadAction<typeof SET_BUILDER_WASTE_FILTER, Nullable<boolean>>
export type SetCollectedFilter = PayloadAction<typeof SET_COLLECTED_FILTER, Nullable<boolean>>
export type SetMatchedFilter = PayloadAction<typeof SET_MATCHED_FILTER, Nullable<boolean>>
export type SetResidentialFilter = PayloadAction<typeof SET_RESIDENTIAL_FILTER, Nullable<boolean>>
export type SetReusableFilter = PayloadAction<typeof SET_REUSABLE_FILTER, Nullable<boolean>>
export type SetTagFilter = PayloadAction<typeof SET_TAG_FILTER, Nullable<TagId>>
export type ClearAdverts = Action<typeof CLEAR_ADVERTS>
export type SetNeedsFetch = PayloadAction<typeof SET_NEEDS_FETCH, boolean>

export type AdvertsAction =
  | CheckGalleryVersion
  | ClearAdverts
  | FetchAdvertItem
  | FetchAdverts
  | FetchAdvertsNextPage
  | FetchAdvertsNextPageCheckFailure
  | SetAdvertsCurrentPage
  | SetAdvertsPageSize
  | SetBuilderWasteFilter
  | SetCollectedFilter
  | SetMatchedFilter
  | SetNeedsFetch
  | SetResidentialFilter
  | SetReusableFilter
  | SetTagFilter

export interface FetchAdvertsResponseBody {
  entities: AdvertItemResource[]
  outsideSupportedArea: boolean
}

export type GalleryVersion = number

export interface CheckGalleryVersionBody {
  version: GalleryVersion
}

export type CheckGalleryVersionResponse = Response<CheckGalleryVersionBody>
export type FetchAdvertsResponse = Response<FetchAdvertsResponseBody, Nullable<PaginationMeta>>

export type FetchAdvertItemResponse = Response<AdvertItemResource>

export type Order = 'latest' | 'nearest' | 'status' | 'urgency'
export type OrderParam = 'createdat.desc' | 'default' | 'status' | 'urgent.asc'
export type LocationCentre = 'current' | 'postcode'

/* eslint-disable camelcase */
export interface FilterParams extends Partial<Location> {
  'include-inactive': boolean
  sorting: OrderParam
  page_size: number
  page_number?: number
  'builder-waste'?: boolean
  'include-page-count'?: boolean
  collected?: boolean
  distance?: number
  matched?: boolean
  residential?: boolean
  reusable?: boolean
  tag?: TagId
  withoutCollectorJobs?: boolean
}
/* eslint-enable camelcase */

export interface Filter {
  builderWaste: Nullable<boolean>
  collected: Nullable<boolean>
  currentTag: Nullable<TagId>
  disposed: Nullable<boolean>
  distance: Nullable<Distance>
  includePageCount: Nullable<boolean>
  location: Nullable<LatLng>
  locationCentre: Nullable<LocationCentre>
  matched: Nullable<boolean>
  orderBy: Nullable<Order>
  pageSize: number
  residential: Nullable<boolean>
  reusable: Nullable<boolean>
  showAll: Nullable<boolean>
  withoutCollectorJobs: Nullable<boolean>
}

export interface FetchAdvertItemParams {
  lat?: number
  lon?: number
}

interface AdvertItemMeta {
  selfUrl: string
}

interface AdvertItemBase<O> extends Location {
  counterOfferBelongsToViewer: boolean
  createdAt: string
  endAt: string
  hasJunkLoverOffer: boolean
  id: AdvertId
  imageUrl: string
  isReusable: boolean
  isTrade: boolean
  meta: AdvertItemMeta
  offeredAt: O
  reference: string
  startAt: string
  title: string
  url: string
  viewerIsPreferredCollector: boolean

  acceptingNewCounterOffers?: boolean
  address?: AddressResource
  bestCounterOffer?: Price
  collectionPointDetails?: CollectionPoint
  details?: Details
  distance?: Distance
  fixedTimeSlotChoice?: TimeSlotFixedName
  isUlez?: boolean
  isJunkReused?: boolean
  isJunkRecycled?: boolean
  isTradeCustomer?: boolean
  matchedTimeInSeconds?: number
  openTimeSlotChoice?: OpenTimeSlot
  postcodeDistrict?: string
  price?: Price
  selfCounterOffer?: Price
  status?: Status
  summary?: string
  supplierType?: SupplierType
}

export type AdvertItemResource = AdvertItemBase<string>

export type AdvertItem = AdvertItemBase<Date>

export interface Pagination {
  currentPage: number
}

export interface AdvertItemPriceVisibilityPayload {
  isCollector: boolean
  isJunkLover: boolean
  isReusable: boolean
  status: Status

  isReusableFilterOn?: boolean
}

export class AdvertItemNotFoundError extends BaseError {
  constructor(readonly advertId: AdvertId) {
    super('AdvertItemNotFound', `Advert item ${advertId} not found`)
  }
}
