import { flow } from 'lodash/fp'
import { Action } from 'redux'

import { FailureActionFactory, Transform } from '../../types/core'
import { GenericThunkAction } from '../../types/thunk'
import getConfig from './config'
import { Configuration, Endpoints } from './types'

export const withConfiguration =
  <A extends Action, F extends A, R extends A, S>(
    callback: Transform<Configuration, GenericThunkAction<R, S, A>>,
    failureAction: FailureActionFactory<F>,
  ): GenericThunkAction<F | R, S, A> =>
  (dispatch, getState) =>
    flow(getState, getConfig().getConfiguration, configuration =>
      configuration
        ? callback(configuration)(dispatch, getState)
        : Promise.resolve(dispatch(failureAction(new Error('configuration not loaded yet')))),
    )()

export const withEndpoints = <A extends Action, F extends A, R extends A, S>(
  callback: Transform<Endpoints, GenericThunkAction<R, S, A>>,
  failureAction: FailureActionFactory<F>,
): GenericThunkAction<F | R, S, A> =>
  withConfiguration<A, F, R, S>(({ endpoints }) => callback(endpoints), failureAction)

export const withEndpoint = <E extends keyof Endpoints, A extends Action, F extends A, R extends A, S>(
  endpoint: E,
  callback: Transform<Endpoints[E], GenericThunkAction<R, S, A>>,
  failureAction: FailureActionFactory<F>,
): GenericThunkAction<F | R, S, A> =>
  withEndpoints<A, F, R, S>(endpoints => callback(endpoints[endpoint]), failureAction)
