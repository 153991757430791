import { flow } from 'lodash/fp'

import Reducer from '../../types/reducer'
import {
  chatActionUrlsLens,
  chatAdvertInfoLens,
  chatMessagesLens,
  chatMessagesVersionLens,
  chatOtherParticipantsLens,
  cleanupLocalMessagesLens,
  clearPendingMessagesLens,
  isConferenceCallModalVisibleLens,
  isPhoneRequestedModalVisibleLens,
  mergeChatMessagesLens,
  pushChatMessageLens,
  pushContactMessageLens,
  startConferenceCallPayloadLens,
  viewPhoneInProgressLens,
} from './lens'
import State, { chatDefaultState as defaultState } from './state'
import {
  ADD_PHONE_REQUESTED,
  CLOSE_CONFERENCE_CALL_MODAL,
  CREATE_CHAT_THREAD_SUCCESS,
  FETCH_CHAT_MESSAGES_SUCCESS,
  FETCH_CHAT_MESSAGES_UPDATES_SUCCESS,
  PUSH_CONTACT_MESSAGE,
  SEND_CHAT_MESSAGE_START,
  SEND_CHAT_MESSAGE_SUCCESS,
  SET_CONFERENCE_CALL_PAYLOAD,
  SHOW_CONFERENCE_CALL_MODAL,
  VIEW_PHONE_FAILURE,
  VIEW_PHONE_START,
  VIEW_PHONE_SUCCESS,
} from './types'

const reducer: Reducer<State> = (state = defaultState, action) => {
  switch (action.type) {
    case CREATE_CHAT_THREAD_SUCCESS:
    case FETCH_CHAT_MESSAGES_SUCCESS:
      return flow(
        chatMessagesLens,
        l => l.set(action.payload.messages),
        chatMessagesVersionLens,
        l => l.set(action.payload.version),
        chatActionUrlsLens,
        l => l.set(action.payload.actions),
        chatAdvertInfoLens,
        l => l.set(action.payload.advertInfo),
        chatOtherParticipantsLens,
        l => l.set(action.payload.otherParticipantNames),
      )(state)
    case FETCH_CHAT_MESSAGES_UPDATES_SUCCESS:
      return flow(
        cleanupLocalMessagesLens,
        mergeChatMessagesLens,
        l => l(action.payload.messages),
        chatMessagesVersionLens,
        l => l.set(action.payload.version),
      )(state)
    case CLOSE_CONFERENCE_CALL_MODAL:
      return isConferenceCallModalVisibleLens(state).set(false)
    case PUSH_CONTACT_MESSAGE:
      return pushContactMessageLens(state)(action.payload)
    case SET_CONFERENCE_CALL_PAYLOAD:
      return startConferenceCallPayloadLens(state).set(action.payload)
    case SHOW_CONFERENCE_CALL_MODAL:
      return isConferenceCallModalVisibleLens(state).set(true)
    case SEND_CHAT_MESSAGE_START:
      return action.payload ? pushChatMessageLens(state)(action.payload) : state
    case SEND_CHAT_MESSAGE_SUCCESS:
      return clearPendingMessagesLens(state)
    case VIEW_PHONE_START:
      return viewPhoneInProgressLens(state).set(true)
    case VIEW_PHONE_SUCCESS:
    case VIEW_PHONE_FAILURE:
      return viewPhoneInProgressLens(state).set(false)
    case ADD_PHONE_REQUESTED:
      return isPhoneRequestedModalVisibleLens(state).set(action.payload)
    default:
      return state
  }
}

export default reducer
