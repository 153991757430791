import { chatWindowFactory as factory } from '@lovejunk/core'
import { styled } from 'styled'

import Column from '../containers/Column'
import Item from './Item'
import Footer from './WindowFooter'

export type Element = HTMLDivElement

const ChatWindow = factory<Element>({
  Content: styled(Column)`
    flex: 1;
    justify-content: flex-end;
    padding: 0 1em;
  `,
  Footer,
  Item,
  Items: Column,
  Root: styled(Column)`
    background-color: ${({ theme: { colors } }) => colors.grey};
    height: 32em;
    overflow-y: scroll;
  `,
})

export default ChatWindow
